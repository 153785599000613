/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelTenant.ts                        *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "auth/tenant";

export class AutogenModelTenant<RowType extends ModelRow> extends Endpoint<RowType, RowTenantFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowTenant extends ModelRow<RowTenantFields> {
    constructor(values?: Partial<RowTenantFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowTenantFields {
    client_name: string;
    company_name: string;
    customer_id: string;
    home_url: string;
    id: string;
    name: string;
    scac: string;
}

