import { CommonDialogs } from "@mcleod/common";
import {
    Button, ButtonVariant, Component, ComponentTypes, Container, DataSource, DataSourceProps, DesignableObject,
    DesignerToolAcceptor, KeyEvent, KeyHandler,
    Layout, LayoutProps,
    ResourceFileProps, Snackbar,
    Splitter, Table, TableCell, TableColumn, Textbox, serializeComponents
} from "@mcleod/components";
import {
    Api, AuthType, Block, ExtendedDataType, GeneralSettings,
    Keys, LogManager, MetadataField,
    Model, ModelRow, Navigation, NavigationEvent, ScrollBehavior, StringUtil
} from "@mcleod/core";
import { AbstractLayoutDesigner } from "../common/AbstractLayoutDesigner";
import { CodeEditor } from "../common/CodeEditor";
import { DesignerProps } from "../common/ResourceDesigner";
import { DesignerActionExecutor } from "./DesignerActionExecutor";
import { DesignerDataSource } from "./DesignerDataSource";
import { DesignerTool } from "./DesignerTool";
import { DesignerToolsPanel } from "./DesignerToolsPanel";
import { DragAndDropHandler } from "./DragAndDropHandler";
import { LayoutDesignerTab } from "./LayoutDesignerTab";
import { PanelOpenLayout } from "./PanelOpenLayout";
import { PropertiesTable } from "./PropertiesTable";
import { PropertiesTablePanel } from "./PropertiesTablePanel";
import { UIDesignerKeyHandlers } from "./UIDesignerKeyHandlers";
import { UIDesignerUtil } from "./UIDesignerUtil";
import { UILayoutVersions } from "./UILayoutVersions";
import { ActionAddComponent } from "./actions/ActionAddComponent";
import { ActionAddTableColumn } from "./actions/ActionAddTableColumn";
import { ActionChangeComponentProperty } from "./actions/ActionChangeComponentProperty";
import { ActionDeleteComponent } from "./actions/ActionDeleteComponent";
import { ActionInsertIntoNewPanel } from "./actions/ActionInsertIntoNewPanel";
import { DesignerStyles } from "./DesignerStyles";
import { DesignerAction } from "./actions/DesignerAction";

const log = LogManager.getLogger("designer.ui.AbstractUIDesigner");

export interface DesignerContainer extends Container {
    acceptsTool(tool: DesignerTool): boolean;
}

const deserializeProps: Partial<LayoutProps> = {
    applyFieldLevelPermissions: false,
    applyFieldLevelLicensing: false,
    applyFieldLevelCompanyType: false,
    applyFieldLevelLtlType: false,
    applyFieldLevelExperiment: false,
    applyFieldLevelMultiCurrency: false
};

export abstract class AbstractUIDesigner extends AbstractLayoutDesigner<LayoutDesignerTab> {
    copiedComponents: Component[];
    inputTableSearch: Textbox;
    inputFieldSearch: Textbox;
    buttonViewCode: Button;
    buttonViewLayout: Button;
    buttonRun: Button;
    buttonOpen: Button;
    buttonSave: Button;
    buttonManageVersions: Button;
    buttonSaveNewVersion: Button;
    buttonNew: Button;
    toolsPanel: DesignerToolsPanel;
    lastChangedProp: string;
    splitterProps: Splitter;
    splitterTools: Splitter;
    dragDropHandler: DragAndDropHandler;
    private _designerTools: Map<string,DesignerTool>;
    abstract allowedDesignerTools: string[]; //array of component serialization names that are available in a designer

    constructor(props: DesignerProps) {
        super(props);
        this.dragDropHandler = new DragAndDropHandler(this);
        log.debug("Render designer");
        this.copiedComponents = [];
        this.setProps({ auth: [AuthType.ANY], fillRow: true, fillHeight: true, padding: 0 });
        this.toolsPanel = new DesignerToolsPanel(this, { fillRow: true });
        this.splitterTools = new Splitter({ id: "splitterTools", position: 240, fillRow: true, fillHeight: true, expandButtonsVisible: false });
        this.splitterProps = new Splitter({ id: "splitterProps", position: "80%", fillRow: true, fillHeight: true, expandButtonsVisible: false });
        this.splitterTools.add(this.toolsPanel);
        this.splitterTools.add(this.splitterProps);
        this.add(this.splitterTools);
        this.splitterProps.add(this.tabset);
        this.addTabsetToolbar({ variant: ButtonVariant.round, color: "subtle.darker", dropped: false });

        Navigation.addNavigationListener({
            onNavigate: async (event: NavigationEvent) => {
                const isNewTab = event.navOptions != null && event.navOptions.newTab === true;
                if (this.isModified() && !isNewTab) {
                    if (event.navOptions?.hardRefresh || !await CommonDialogs.showYesNo("Are you sure you want to leave this page without saving?", "Close Without Saving?"))
                        event.preventDefault();
                }
            }
        });
    }

    get notifyActionHistory(): boolean {
        return this.actionExecutor.actionListener?.enabled;
    }

    set notifyActionHistory(value: boolean) {
        if (this.actionExecutor?.actionListener != null) {
            this.actionExecutor.actionListener.enabled = value;
        }
    }

    async executeAction (action: DesignerAction) {
        this.actionExecutor?.doDesignerAction(action);
    }

    get actionExecutor(): DesignerActionExecutor {
        return this.getActiveTab().actionExecutor;
    }

    get propsPanel(): PropertiesTablePanel {
        return this.getActiveTab()?.propsPanel;
    }

    get tableProps(): PropertiesTable {
        return this.propsPanel?.tableProps;
    }

    override createTab(props: ResourceFileProps): LayoutDesignerTab {
        return new LayoutDesignerTab(this, props);
    }

    filterProps(props: any, selectedComponent: Component): void {
        if (this.selectedComponents.length > 1) {
            delete props.contextMenuItems;
        }
    }

    disablePropertyEditors(prop: any, editorComponents: Component[], selectedComponent: Component): void {
    }

    addTabsetToolbar(toolsProps: any) {
        this.buttonViewCode = new Button({ ...toolsProps, tooltip: "View code for this layout", imageName: "curlyBrackets" });
        this.buttonViewCode.addClickListener(event => this.showCode());
        this.buttonViewLayout = new Button({ ...toolsProps, tooltip: "View raw layout file", imageName: "codeTags" });
        this.buttonViewLayout.addClickListener(event => this.showJson());
        this.buttonRun = new Button({ ...toolsProps, tooltip: "Preview this layout", imageName: "run" });
        this.buttonRun.addClickListener(event => this.showTest());
        this.buttonOpen = new Button({ ...toolsProps, tooltip: "Open a layout for editing", imageName: "folder" });
        this.buttonOpen.addClickListener(event => this.showOpen());
        this.buttonSave = new Button({ ...toolsProps, tooltip: "Save this layout", imageName: "disk" });
        this.buttonSave.addClickListener(event => this.showSave());
        this.buttonManageVersions = new Button({ ...toolsProps, tooltip: "Manage the versions of this layout", imageName: "formPencil" });
        this.buttonManageVersions.addClickListener(event => UILayoutVersions.showSlideout(this));
        this.buttonSaveNewVersion = new Button({ ...toolsProps, tooltip: "Save a new copy of this custom layout", imageName: "duplicateDisk" });
        this.buttonSaveNewVersion.addClickListener(event => this.saveCustomLayout(this.getActiveTab(), true));
        this.buttonNew = new Button({ ...toolsProps, tooltip: "Create a new layout", imageName: "add" });
        this.buttonNew.addClickListener(event => this.addNewTab());
        this.tabset.tools = this.tabsetTools;
    }

    get tabsetTools(): Button[] {
        return [
            this.buttonViewCode,
            this.buttonViewLayout,
            this.buttonRun,
            this.buttonOpen,
            this.buttonSave,
            this.buttonNew,
        ]
    }

    private get designerTools(): Map<string, DesignerTool> {
        if (this._designerTools == null)
            this.initializeDesignerTools();
        return this._designerTools;
    }

    private initializeDesignerTools() {
        const map: Map<string, DesignerTool> = new Map();
        map.set("label", new DesignerTool(this, "label", "Label"));
        map.set("textbox", new DesignerTool(this, "textbox", "Textbox"));
        map.set("button", new DesignerTool(this, "button", "Button"));
        map.set("checkbox", new DesignerTool(this, "checkbox", "Checkbox"));
        map.set("radio", new DesignerTool(this, "radio", "Radio"));
        map.set("switch", new DesignerTool(this, "switch", "Switch"));
        map.set("table", new DesignerTool(this, "table", "Table"));
        map.set("image", new DesignerTool(this, "image", "Image"));
        map.set("citystate", new DesignerTool(this, "citystate", "City/State"));
        map.set("splitter", new DesignerTool(this, "splitter", "Splitter"));
        map.set("tree", new DesignerTool(this, "tree", "Tree"));
        map.set("map", new DesignerTool(this, "map", "Map"));
        map.set("panel", new DesignerTool(this, "panel", "Panel"));
        map.set("stepper", new DesignerTool(this, "stepper", "Stepper"));
        map.set("tabset", new DesignerTool(this, "tabset", "Tabset"));
        map.set("list", new DesignerTool(this, "list", "List"));
        map.set("repeater", new DesignerTool(this, "repeater", "Repeater"));
        map.set("attachment", new DesignerTool(this, "attachment", "Attachment"));
        map.set("layout", new DesignerTool(this, "layout", "Layout", null, { isNested: true }));
        map.set("horizontalspacer", new DesignerTool(this, "horizontalspacer", "Horizontal Spacer"));
        map.set("searchbutton", new DesignerTool(this, "searchbutton", "Search Button"));
        map.set("savebutton", new DesignerTool(this, "savebutton", "Save Button"));
        map.set("dataheader", new DesignerTool(this, "dataheader", "Data Header"));
        map.set("numbereditor", new DesignerTool(this, "numbereditor", "Number Editor"));
        map.set("starrating", new DesignerTool(this, "starrating", "Star Rating"));
        map.set("progressbar", new DesignerTool(this, "progressbar", "Progress Bar"));
        map.set("chart", new DesignerTool(this, "chart", "Chart"));
        map.set("location", new DesignerTool(this, "location", "Location", null, null, "citystate"));
        map.set("iframe", new DesignerTool(this, "iframe", "IFrame"));
        map.set("tailorextensionbutton", new DesignerTool(this, "tailorextensionbutton", "Tailor Extension Button", null, null, "button"));
        map.set("multiswitch", new DesignerTool(this, "multiswitch", "Multi Switch"));
        map.set("excludabletextbox", new DesignerTool(this, "excludabletextbox", "Excludable Textbox", null, null, "textbox"));
        this._designerTools = map;
    }

    async getResourceInfo(): Promise<ModelRow> {
        const response = await Model.search("resource/info/layout", { resource_name: this.getActiveTab().path });
        return response.getSingleModelRow();
    }

    async getCodeFileName(): Promise<string> {
        return (await this.getResourceInfo()).get("typescript_path");
    }

    addEventHandlerFunction(component: DesignableObject, eventName: string): void {
        const prop = component.getPropertyDefinitions()[eventName];
        if (prop == null)
            return;
        if (component instanceof DesignerDataSource)
            component = component.designerDataSource
        let signature = prop.eventSignature;
        if (signature == null)
            signature = eventName[0].toUpperCase() + eventName.substring(1) + "(event)";
        component[eventName] = component.id + StringUtil.stringBefore(signature, "(");
        this.redisplayProp(eventName, component[eventName]);
        this.showSave(true, false).then(async obj => {
            const fileName = await this.getCodeFileName();
            CodeEditor.addCodeFunction(fileName, component.id + signature, {
                contentsIfEmpty: this.getCodeSkeleton(),
                comment: "/** This is an event handler for the " + eventName + " event of " + component.id + ". */\n"
            });
        });
    }

    displayProperties() {
        this.syncPropsTable();
        this.tableProps.displayProperties(this.selectedComponents);
    }

    syncPropsTable() {
        const propsPanel = this.propsPanel;
        if (this.splitterProps.secondComponent instanceof PropertiesTablePanel
            && this.splitterProps.secondComponent !== propsPanel) {
                this.splitterProps.secondComponent["_scrollPosition"] = propsPanel["_scrollPosition"];
        }
        this.splitterProps.secondComponent = propsPanel;
    }

    showCode() {
        this.showSave(true).then(async () => {
            const fileName = await this.getCodeFileName();
            CodeEditor.openCodeEditor(fileName, { contentsIfEmpty: this.getCodeSkeleton() })
        });
    }

    getCodeSkeleton() {
        const className = StringUtil.stringAfterLast(this.getActiveTab().path, "/");
        return `import { AutogenLayout${className} } from "./autogen/AutogenLayout${className}";

export class ${className} extends AutogenLayout${className} {
}
`;
    }

    async showJson() {
        const path = (await this.getResourceInfo()).get("resource_path");
        CodeEditor.openCodeEditor(path);
    }

    override getKeyHandlers(): KeyHandler[] {
        const result = [...UIDesignerKeyHandlers.getDesignerKeyListeners(this)];
        result.push({ key: Keys.DELETE, listener: () => this.deleteComponents(this.selectedComponents) });
        result.push({
            key: Keys.P, modifiers: { ctrlKey: true }, listener: () => {
                if (this.selectedComponents != null && this.selectedComponents.length === 1 && !(this.firstSelected.parent instanceof LayoutDesignerTab))
                    this.selectComponent(this.firstSelected.parent);
            }
        });
        result.push({
            key: Keys.I, modifiers: { ctrlKey: true }, listener: () => {
                if (this.selectedComponents != null)
                    this.tableProps.applyKeyToProp(null, "id");
            }
        });
        result.push({
            key: Keys.D, modifiers: { ctrlKey: true }, listener: () => {
                if (this.selectedComponents != null)
                    this.tableProps.applyKeyToProp(null, "dataSource");
            }
        });
        result.push({
            key: Keys.S, modifiers: { altKey: true }, listener: () => {
                this.showSave();
            }
        });
        result.push({
            key: Keys.N, modifiers: { altKey: true }, listener: () => {
                this.addNewTab();
            }
        });
        result.push({
            key: Keys.O, modifiers: { altKey: true }, listener: () => {
                this.showOpen();
            }
        });
        result.push({
            key: Keys.W, modifiers: { altKey: true }, listener: () => {
                this.selectWidthProp();
            }
        });
        result.push({
            key: Keys.P, modifiers: { shiftKey: true, ctrlKey: true }, listener: () => {
                this.addToolToSelectedContainer(new DesignerTool(this, "panel", "Panel"));
            }
        });
        result.push({
            key: Keys.P, modifiers: { altKey: true, ctrlKey: true }, listener: () => {
                this.insertIntoNewPanel(this.selectedLayoutComponents);
            }
        });
        result.push({ key: "ALL", listener: event => this.handleOtherKeys(event) });
        return result;
    }

    tabChanged(tab: LayoutDesignerTab) {
        this.firstSelectedLayoutComponent?.scrollIntoView({ behavior: ScrollBehavior.INSTANT, block: Block.CENTER });
        super.tabChanged(tab);
        this.toolsPanel.displayDataSourceTools();
        this.displayProperties();
        this.syncTabsetTools(tab);
    }

    selectWidthProp() {
        this.lastChangedProp = "width";
    }

    handleOtherKeys(event: KeyEvent): boolean {
        if (document.activeElement === document.body && !event.ctrlKey && !event.altKey) {
            const key = event.key;
            if (typeof key === "string" && key.length === 1 && key != "+" && key != "-") {
                this.tableProps.applyKeyToProp(key, this.lastChangedProp);
                return;
            }
        }
        event.shouldAutomaticallyStopPropagation = false;
    }

    getToolPropsForField(field: MetadataField) {
        const result: any = {};
        if (field != null) {
            result.field = field.name;
            result.name = field.name;
            if (field.dataType === "bool")
                result.toolType = "Checkbox";
            else if (field.dataType === "list")
                result.toolType = "Table";
            else if (field.extDataType === ExtendedDataType.CITY)
                result.toolType = "CityState";

            result.dataSource = this.getActiveTab().lastSelectedDataSource;
            result.caption = field.caption;
            result.toolDropped = false;
        }
        return result;
    }

    getActiveLayout(): Layout {
        return this.getActiveTab().designerPanel;
    }

    showSave(createBaseTS: boolean = false, baseTsOnly: boolean = false) {
        const tab = this.getActiveTab();
        if (tab.path == null) {
            tab.promptForResourceName("layout/list").then((fullPath) => {
                if (fullPath != null) {
                    //service project will be at the front of the path, we don't need it
                    tab.path = fullPath == null ? null : StringUtil.stringAfter(fullPath, "/");
                    this.localStorageManager.storeLastSelectedTab(tab);
                    this.saveActiveTab(createBaseTS, baseTsOnly);
                }
            });
        }
        else {
            if (GeneralSettings.getSingleton().isRunningInIDE() !== true && tab.baseVersion === true)
                return this.saveCustomLayout(tab);
            else
                return this.saveActiveTab(createBaseTS, baseTsOnly);
        }
    }

    async saveCustomLayout(tab: LayoutDesignerTab, savingACopy?: boolean) {
        if (!await tab.validateSave())
            return;
        let descr:string  = null;
        if (tab.baseVersion === true || savingACopy === true) {
           descr = await tab.promptForVersionDescription();
           if (descr == null)
               return;
        }
        if (savingACopy !== true)
            return this.saveActiveTab(false, false, descr);
        else
            this.saveAndOpenCopy(descr);
    }

    private showTest() {
        const activeTab = this.getActiveTab();
        let path = activeTab?.path;
        if (StringUtil.isEmptyString(path) === true) {
            Snackbar.showWarningSnackbar("Unable to run layout; layout has no defined path.");
            return;
        }
        let layoutId = null;
        if (StringUtil.isEmptyString(activeTab.customId) !== true)
            layoutId = activeTab.customId;
        else if (activeTab.baseVersion === true)
            layoutId = "base";
        if (StringUtil.isEmptyString(layoutId) === false)
            path += "?" + new URLSearchParams({ layoutId: layoutId });
        Navigation.navigateTo(path, { newTab: true });
    }

    showOpen() {
        this.showOpenDialog(new PanelOpenLayout(), "Open Layout");
    }

    async saveActiveTab(createBaseTS: boolean = false, baseTSOnly: boolean = false, descr?: string) {
        const tab = this.getActiveTab();
        if (!await tab.validateSave())
            return;
        const body = { path: tab.path, require_base_version: tab.baseVersion, id: tab.customId, create_base_ts: createBaseTS, base_ts_only: baseTSOnly, definition: serializeComponents(tab.designerPanel, tab.dataSources) };
        if (StringUtil.isEmptyString(descr) !== true)
            body["descr"] = descr;
        return Api.post(this.endpointPath, body).then((response) => {
            this.doAfterActiveTabSave(tab, baseTSOnly, response);
        }).catch(error => {
            this.doOnActiveTabSaveError(tab, error);
        });
    }

    doAfterActiveTabSave(tab: LayoutDesignerTab, baseTSOnly: boolean, response: any) {
        if (baseTSOnly !== true) {
            const oldLastOpenEntry = tab.descriptor;
            tab.modified = false;
            const saveResult = response.data[0];
            tab.updateFromServerResponse(saveResult);
            this.localStorageManager.updateTabInLastOpen(oldLastOpenEntry, tab.descriptor, true);
            Snackbar.showSnackbar(saveResult.message);
            this.updateToolbarButtonVisibility(tab);
        }
    }

    doOnActiveTabSaveError(tab: LayoutDesignerTab, error: any) {
        CommonDialogs.showError(error);
    }

    async saveAndOpenCopy(descr?: string) {
        const tab = this.getActiveTab();
        const body = { path: tab.path, require_base_version: tab.baseVersion, create_base_ts: false, base_ts_only: false, definition: serializeComponents(tab.designerPanel, tab.dataSources) };
        if (StringUtil.isEmptyString(descr) !== true)
            body["descr"] = descr;
        return Api.post(this.endpointPath, body).then((response) => {
            this.doAfterSaveACopy(response, tab.path);
        }).catch(reason => CommonDialogs.showError(reason));
    }

    private doAfterSaveACopy(response: any, path: string) {
        const saveResult = response.data[0];
        this.openTab({path, customId: saveResult.id}, true).then((tab) => {
            this.selectComponent(tab.designerPanel);
            this.displayProperties();
            tab.modified = false;
            tab.updateFromServerResponse(saveResult);
            this.localStorageManager.addLastOpenTab(tab, true);
            Snackbar.showSnackbar("You are now editing the copy of the custom layout.  It has already been saved to the database.");
            this.updateToolbarButtonVisibility(tab);
        })
    }

    applyChangeToSelectedComponents(data, newValue) {
        this.modified();
        UIDesignerUtil.designerApplyChangeToSelectedComponents(this.selectedComponents, this.getActiveTab(), data, newValue, this.tableProps);
    }

    override async doAfterTabDefintionLoaded(tab: LayoutDesignerTab, def: any) {
        try {
            this.localStorageManager.addLastOpenTab(tab, true);
            if (def.dataSources != null)
                this.createDataSourcesFromDef(tab, def.dataSources, tab.path);
            await this.deserializeLayout(tab, { defaultPropValues: deserializeProps, dataSources: tab.dataSources});
        } catch (error) {
            tab.addErrorLabel(error.toString());
        }
    }

    setPropsForDeserialization(componentType: string, props: any) {
        if (componentType === "layout")
            return { ...props, ...deserializeProps, _designer: this };
        return props;
    }

    override doAfterTabAdded(tab: LayoutDesignerTab) {
        if (this.getActiveTab() == tab) {
            this.displayProperties();
            this.updateToolbarButtonVisibility(tab);
        }
    }

    createDataSourcesFromDef(tab: LayoutDesignerTab, sources: DataSourceProps[], layoutName: string): void {
        for (let i = 0; sources != null && i < sources.length; i++) {
            const dataSource = new DataSource({ ...sources[i] }, null, this, tab);
            dataSource.layoutName = layoutName;
            tab.dataSources[dataSource.id] = dataSource;
            tab.dataSourcePanel.addDesignerDataSource(new DesignerDataSource(this, dataSource));
        }
    }

    async closeTab(tab: LayoutDesignerTab) {
        if (!tab.modified || await CommonDialogs.showYesNo("Are you sure you want to close this tab without saving your changes?", "Close Without Saving?")) {
            tab.parent.remove(tab);
            if (this.tabset.getComponentCount() === 0)
                this.addNewTab();
        }
    }

    private getNextNumber(toolName: string, owner: LayoutDesignerTab = this.getActiveTab()) {
        for (let i = 1; i < 1000; i++)
            if (document.getElementById(toolName + i) == null && owner[toolName + i] == null)
                return i;
        throw new Error("Could not get the next available component number.");
    }

    redisplayProp(propName: string, value: string) {
        this.tableProps.redisplayProp(propName, value);
        if (propName === "id")
            this.getActiveTab().dataSourcePanel.updateIds();
    }

    selectComponent(object: DesignableObject, add: boolean = false) {
        this.handleComponentSelection(object, add, this.propsPanel);
        if (this.selectedComponents.length === 1 && this.selectedComponents[0] instanceof DesignerDataSource && this.tabset.getActiveTab() != null) {
            this.getActiveTab().lastSelectedDataSource = this.selectedComponents[0].designerDataSource;
            this.toolsPanel.displayDataSourceTools();
            this.getActiveTab().dataSourcePanel.enableButtonModelView(true);
        } else {
            if (this.tabset.getActiveTab() != null && this.selectedComponents.length === 1)
                this.getActiveTab().dataSourcePanel.enableButtonModelView(false);
            if (this.allowsDrop)
                this.dragDropHandler.componentSelected();
        }
    }

    openModelDesigner(url: string) {
        Navigation.navigateTo("designer/model/ModelDesigner?open=" + url, { newTab: true, windowDecorators: false });
    }

    /**
     * Called by UIDesignerActionHistory when an action happens
     */
    notifyAction(result: any) {
        if (result != null)
            this.notifyComponentsAdded(result.componentsAdded, result.container)
    }

    notifyComponentsAdded(components: Component[], container: Container) {
        components?.forEach(comp => {
            if (comp._designer != this) {
                log.debug("Skipping notifyComponentAdded for component " + comp.id + " because it doesn't have it's _designer prop set.");
            } else {
                this.notifyComponentAdded(comp, container);
                if (comp instanceof Container)
                    this.notifyComponentsAdded(comp.components, container);
            }
        });
    }

    notifyComponentAdded(component: any, container: Container) {
        // the deserialized is a prop we use to distinguish a component defined in the layout json
        // vs components added programatically; those components may already know they were not deserialized
        if (component.deserialized !== false)
            component.deserialized = true;
    }

    addToolToSelectedContainer(tool: DesignerTool): Component {
        const selectedComponent = this.firstSelected;
        if (this.isActiveLayoutComponent(selectedComponent)) {
            let container = null;
            if ((selectedComponent as any).acceptsTool != null && (selectedComponent as any).acceptsTool(tool))
                container = selectedComponent as Container
            else
                container = selectedComponent.parent;
            return this.addToolToContainer(tool, container);
        } else {
            Snackbar.showSnackbar("A parent component must be selected to add this tool to.");
            return null;
        }
    }

    // Currently used by Stepper and Tabset
    override addTool(serializationName: string, displayName: string, container: Container): Component {
        const tool = new DesignerTool(this, serializationName, displayName);
        return this.addToolToContainer(tool, container, null, false);
    }

    addToolToContainer(tool: DesignerTool, container: Container, index?: number, validateContainer: boolean = true): Component {
        if (validateContainer && !this.canAddComponentToContainer(tool, container))
            return null;
        this.modified();
        const componentType = tool.componentType;
        const nextNumber = this.getNextNumber(componentType);
        const componentId = componentType + nextNumber;
        const component = ComponentTypes.createComponentOfType(componentType, { id: componentId });
        component._designer = this;
        component._initialDropInDesigner();
        this.getActiveTab()[componentId] = component;
        if ("caption" in component)
            component.caption = componentId;
        if (container instanceof TableCell && container.col != null) {
            if (StringUtil.isEmptyString(tool.componentCaption) !== null &&
                container.col.headingCell.caption?.startsWith("Column ")) {
                container.col.headingCell.caption = tool.componentCaption;
            }
            const serialized = serializeComponents(container.components, null);
            const componentDefs = JSON.parse(serialized);
            container.col.cellDef = { cellProps: { table: container._table } };
            container.col.cellDef.def = { type: "cell", components: componentDefs };
        }
        this.addComponentToContainer(component, container, index);
        component.setProps(tool.componentProps);
        this.selectComponent(component, false);
        return component;

    }

    addComponentToContainer(component: any, container: Container, index?: number) {
        if (component != null && container != null) {
            this.executeAction(new ActionAddComponent(component, container, index));
            this.notifyComponentAdded(component, container);
        }
    }

    addDesignerContainerProperties(container: Component, minWidth: number, minHeight: number, width?: number,
        allowDropAcceptor?: DesignerToolAcceptor) {
        container.setClassIncluded(DesignerStyles.designerContainer);
        if (container.width === undefined) {
                if (container.minWidth === undefined)
                    container.element.style.minWidth = minWidth + "px";
                if (width != null)
                    container.element.style.width = width + "px";
        }
        if (container.minHeight === undefined)
            container.element.style.minHeight = minHeight + "px";
        (container as DesignerContainer).acceptsTool = (tool: DesignerTool) => {
            return tool != null && this.evaluateDropAcceptor(allowDropAcceptor, tool);
        };
        this.addDragAndDropListeners(container);
    }

    private evaluateDropAcceptor(dropAcceptor: DesignerToolAcceptor, tool: DesignerTool) {
        return dropAcceptor == null ||
            (typeof dropAcceptor === "boolean" && dropAcceptor === true) ||
            (typeof dropAcceptor === "function" && dropAcceptor(tool) === true)
    }

    addDragAndDropListeners(comp: Component) {
        if (this.allowsDrop)
            this.dragDropHandler.addListeners(comp);
    }

    displayDataSourceTools() {
        this.toolsPanel.displayDataSourceTools();
    }

    componentDropped(comp: Component) {
    }

    get allowsDrop(): boolean {
        return true;
    }

    isDesignerContainer(comp: Component): boolean {
        return comp instanceof Container && comp._element.classList.contains(DesignerStyles.designerContainer);
    }

    canAddComponentToContainer(comp: Component, container: any) {
        return comp != null &&
            this.isActiveLayoutComponent(container) &&
            (this.isDesignerContainer(container)) &&
            container.isNested != true &&
            container.acceptsTool(comp);
    }

    insertIntoNewPanel(components: Component[]) {
        if (components?.length == 1 && components[0] == this.getActiveLayout()) {
            this.executeAction(new ActionInsertIntoNewPanel(this.getActiveLayout().components));
        } else if (components?.length > 0) {
            const conatiner = components[0].parent;
            if (components.every(comp => comp.parent == conatiner)) {
                this.executeAction(new ActionInsertIntoNewPanel(this.selectedLayoutComponents));
            } else {
                Snackbar.showSnackbar("Only components within the same container can be selected for this action.");
            }
        } else {
            Snackbar.showSnackbar("A component must be selected to create new Panel.");
        }
    }

    syncTabsetTools(tab: LayoutDesignerTab) {
        const tools = this.tabsetTools;
        if (this.actionExecutor != null) {
            tools.push(this.actionExecutor?.buttonUndo);
            tools.push(this.actionExecutor?.buttonRedo);
        }
        this.tabset.tools = tools;
        this.updateToolbarButtonVisibility(tab);
    }

    updateToolbarButtonVisibility(tab: LayoutDesignerTab) {
        const activeTabPresent = this.getActiveTab() != null;
        this.buttonRun.visible = activeTabPresent;
        this.buttonSave.visible = activeTabPresent;
        this.buttonSaveNewVersion.visible = tab != null ? tab.isCustom : false
        this.buttonManageVersions.visible = activeTabPresent;
    }

    deleteComponents(components: DesignableObject[]) {
        if (components == null)
            return;
        for (const component of components)
            this.executeAction(new ActionDeleteComponent(component));
        this.selectComponent(null, false);
    }

    addTableColumn(table: Table): TableColumn {
        const action = new ActionAddTableColumn(table);
        this.executeAction(action);
        return action.tableColumn;
    }

    executeChangePropAction(comp: Component, propName: string, newValue: any, oldValue: any, redisplayProp: boolean = false) {
        this.executeAction(new ActionChangeComponentProperty(comp, propName, newValue, oldValue, redisplayProp));
    }

    doBeforePropChanged(component: Component, propName: string, propsSeen: string[] = []) {
        // Use the lines below if this method ever defines any logic.
        // Also, include these lines in any overriding method.

        // log.debug("Invoked doBeforePropChanged for property: %o", propName);
        // if (propsSeen.includes(propName)) {
        //     log.debug("Property %o already seen in doBeforePropChanged", propName);
        //     return;
        // }
        // propsSeen.push(propName);

        // LOGIC FOR THIS METHOD WOULD GO HERE

        // const affectsProps: string[] = component.getPropertyDefinitions()[propName]?.affectsProps;
        // log.debug("Property %o affects other properties: %o", propName, affectsProps);
        // affectsProps?.forEach(affect => this.doBeforePropChanged(component, affect, propsSeen));
    }

    doAfterPropChanged(component: Component, propName: string, oldValue: any, newValue: any, redisplayProp?: boolean, propsSeen: string[] = []) {
        UIDesignerUtil.doAfterPropChanged(this, component, propName, oldValue, newValue, redisplayProp, propsSeen);
    }

    syncPropChanged(component: Component, propName: string, oldValue: any, newValue: any, redisplayProp?: boolean) { }

    canModifyProp(propName: string, component: Component): boolean { return true; }

    hasDesignerToolAccessForComponent(component: Component) {
        if (component == null)
            return false;
        return this.allowedDesignerTools.includes(component.serializationName);
    }

    getDesignerToolByName(serializationName: string): DesignerTool {
        return this.designerTools.get(serializationName);
    }

    async validateComponentId(component: Component) {
        this.getActiveTab().validateComponentId(component);
    }
}
