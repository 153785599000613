import { Collection } from "../Collection";
import { ExecutionLog } from "./ExecutionLog";
import { Logger } from "./Logger";
import { LogLevel } from "./LogLevel";
import { LogManager } from "./LogManager";

export class LogConfig {
    private static log: Logger;
    private static singleton: LogConfig = {
        defaultLogLevel: LogLevel.INFO,
        logFormat: null,
        bufferSize: 0,
        logLevels: {},
        enableConsoleLog: true
    };

    defaultLogLevel: LogLevel.INFO;
    bufferSize: number;
    logFormat: string;
    logLevels: Collection<LogLevel>;
    enableConsoleLog: boolean;

    public static get(): LogConfig {
        return LogConfig.singleton;
    }

    public static set(value: LogConfig) {
        if (value != null) {
            LogConfig.singleton = value;
            this.getLog().debug("New log config: %o", value);
            LogManager.resetAllLoggers();
            ExecutionLog.get().resetFromConfig();
        }
        else
            this.getLog().debug("Not updating log config; provided config was empty");
    }

    private static getLog(): Logger {
        if (this.log == null)
            this.log = LogManager.getLogger("core.logging.LogConfig");
        return this.log;
    }
}
