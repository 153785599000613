import { Styles } from "@mcleod/core";

export const PanelStyles = Styles.make("pnl", () => {
    return {
        panelRow: { display: "flex", flexDirection: "row", scrollBehavior: "smooth", alignItems: "center" },
        panel: { display: "flex", flexDirection: "column" },
        noSelect: { WebkitTouchCallout: "none", WebkitUserSelect: "none", KhtmlUserSelect: "none", MozUserSelect: "none", MsUserSelect: "none", userSelect: "none" },
        rowFillHeight: { flex: "1", overflow: "hidden", alignItems: "unset" }
    }
});
