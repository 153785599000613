import { Label, Panel, Tree } from "@mcleod/components";
import { Collection, HorizontalAlignment, LogManager } from "@mcleod/core";
import { Instance } from "./AnalyzerTypes";
import { InstanceNode } from "./InstanceNode";

const log = LogManager.getLogger("InstanceTree");

export class InstanceTree extends Panel {
    public instanceMap: Collection<Instance>;
    public totalHeapSize: number;
    private _instances: any[];
    public tree: Tree;
    public panelHeader: Panel;
    showPrimitives: boolean;
    showReferences: boolean;

    constructor(props?: any) {
        super(props);
        this.tree = new Tree({ fillHeight: true, fillRow: true, borderWidth: 0 });
        this.panelHeader = new Panel({ fillRow: true, marginLeft: 12, borderBottomWidth: 1, borderBottomColor: "subtle.light" });
        const labelInstance = new Label({ text: "Instance", fillRow: true, rowBreak: false });
        const labelProps = { width: 100, rowBreak: false, align: HorizontalAlignment.RIGHT };
        const labelShallowSize = new Label({ text: "Shallow", ...labelProps });
        const labelRetainedSize = new Label({ text: "Retained", ...labelProps });
        const labelPercentage = new Label({ text: "Percent", marginRight: 16, ...labelProps });
        this.panelHeader.add(labelInstance, labelShallowSize, labelRetainedSize, labelPercentage);
        this.add(this.panelHeader, this.tree);
    }

    public get instances(): any[] {
        return this._instances;
    }

    public set instances(value: Instance[]) {
        this._instances = value;
        this.tree.getRootNode().removeAllChildren();
        if (value == null)
            return;
        for (const instance of value) {
            if (this.showPrimitives || instance.a?.startsWith("0x")) {
                const node = this.createInstanceNode(null, instance.a);
                this.tree.getRootNode().addChild(node);
                if (value.length === 1)
                    node.expanded = true;
            }
        }
    }

    createInstanceNode(fieldName: string, instanceAddress: string): InstanceNode {
        const instance = this.instanceMap[instanceAddress];
        const result = new InstanceNode(instance || instanceAddress, fieldName, this);
        return result;
    }
}
