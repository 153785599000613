import { DomEvent } from "./DomEvent";
import { Event, EventListener } from "./Event";
import { KeyModifiers, ModifierKeys } from "./KeyModifiers";

export interface ClickListener extends EventListener {
    (event: ClickEvent): void;
}

export class ClickEvent extends Event implements ModifierKeys {
    public get altKey(): boolean {
        return this.domEvent.altKey;
    }

    public get ctrlKey(): boolean {
        return this.domEvent.ctrlKey;
    }

    public get shiftKey(): boolean {
        return this.domEvent.shiftKey;
    }

    public get domEvent(): MouseEvent {
        return super.domEvent as MouseEvent;
    }

    public hasModifiers(modifiers: ModifierKeys) {
        return KeyModifiers.hasModifiers(this, modifiers);
    }

    public static eventIsFromUserClick(event: Event | DomEvent) {
        if (event instanceof Event)
            return (event?.domEvent instanceof PointerEvent || event?.domEvent?.type === "click"); //can be click event in Firefox
        return event != null && (event instanceof PointerEvent || event?.type === "click");
    }
}
