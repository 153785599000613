import { CircularArray } from "../CircularArray";
import { LogConfig } from "./LogConfig";
import { Logger } from "./Logger";
import { LogManager } from "./LogManager";

export class ExecutionLog {
    private static log: Logger;
    private static singleton: ExecutionLog;
    private buffer = new CircularArray(0);

    static initSingleton() {
        this.singleton = new ExecutionLog();
    }

    public static get(): ExecutionLog {
        return this.singleton;
    }

    public static getAsString(): string {
        return this.singleton?.formatBuffer();
    }

    resetFromConfig() {
        this.buffer = new CircularArray(LogConfig.get().bufferSize);
        ExecutionLog.getLog().debug("Execution log updated from log config");
    }

    private formatBuffer(): string {
        return this.buffer.getFlattened().join("\n");
    }

    public append(value: any) {
        this.buffer.push(value); // need to push args as well but don't feel like doing that right now
    }

    private static getLog(): Logger {
        if (this.log == null)
            this.log = LogManager.getLogger("core.logging.ExecutionLog");
        return this.log;
    }
}

ExecutionLog.initSingleton();
