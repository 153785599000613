import { Color } from "@mcleod/core";
import { ComponentPropDefinitions, ComponentProps, ComponentPropDefinitionUtil } from "../../base/ComponentProps";
import { PropType } from "../../base/PropType";

export interface ProgressBarProps extends ComponentProps {
    barColor: Color;
    barColorGradient: Color;
    caption: string;
    maxValue: number;
    value: number;
}

let progressBarPropDefs: ComponentPropDefinitions;

export class ProgressBarPropDefinitions {
    public static getDefinitions(): ComponentPropDefinitions {
        if (progressBarPropDefs == null) {
            progressBarPropDefs = {
                barColor: { type: PropType.color, defaultValue: "primary", description: "This is the color of the ProgressBar." },
                barColorGradient: { type: PropType.color, defaultValue: "primary.light", description: "This is the color of the right side of the ProgressBar.  Set this to make a gradient." },
                caption: { type: PropType.string, defaultValue: "$percent%", description: "This is the text that will be displayed in the ProgressBar.  The keywords $percent, $value, and $maxValue can be included in this text." },
                maxValue: { type: PropType.number, defaultValue: 100, description: "This is the maximum value of the ProgressBar." },
                value: { type: PropType.number, defaultValue: 0, description: "This is the current value of the ProgressBar." },
                ...ComponentPropDefinitionUtil.getComponentPropertyDefinitions(),
            };
        }
        return progressBarPropDefs;
    }
}
