export class SuccessFail {
    private _success: boolean;
    private _reason: string;
    private _onFail: () => void;

    constructor(success: boolean, reason?: string, onFail?: () => void) {
        this._success = success;
        this._reason = reason;
        this._onFail = onFail;
    }

    get success(): boolean {
        return this._success;
    }

    get reason(): string {
        return this._reason;
    }

    get onFail(): () => void {
        return this._onFail;
    }
}

export const SUCCESS = new SuccessFail(true);
