import { Alignment, ArrayUtil, Color } from "@mcleod/core";
import { ComponentCreator, ComponentFactory } from "../../page/ComponentFactory";
import { Panel } from "../panel/Panel";
import { ClickEvent } from "../../events/ClickEvent";
import { LabelProps } from "../label/LabelProps";
import { Component } from "../../base/Component";
import { Label } from "../label/Label";
import { Overlay } from "../../page/Overlay";
import { List, ListItemType } from "./List";
import { SelectionListener } from "../../events/SelectionEvent";
import { Cursor } from "../../base/Cursor";
import { SelectionMode } from "../../base/SelectionMode";

export class ListItem {
    private _suppliedInput: ComponentCreator;
    private _renderedComponent: Component;
    private _children?: ListItemType[];
    private _list: List;
    private _onChildSelect?: SelectionListener;
    private _selectable: boolean;
    private _originalColor: Color;
    private _originalBackgroundColor: Color;

    protected defaultLabelProps: Partial<LabelProps> = {
        cursor: Cursor.POINTER,
        paddingLeft: 8,
        paddingRight: 12,
        allowSelect: false
    };

    constructor(input: ComponentCreator, children?: ListItemType[], onChildSelect?: SelectionListener) {
        this._suppliedInput = input;
        this._children = children;
        this._onChildSelect = onChildSelect;
    }

    public get suppliedInput(): ComponentCreator {
        return this._suppliedInput;
    }

    public get renderedComponent(): Component {
        if (this._renderedComponent == null) {
            if (this._suppliedInput === List.SEPARATOR) {
                this._renderedComponent = List.createSeparatorPanel();
                this.selectable = false;
            }
            else if (this._suppliedInput != null) {
                this._renderedComponent = this.createRenderedComponent(this.defaultLabelProps);
            }
        }
        return this._renderedComponent;
    }

    public get children(): ListItemType[] {
        return this._children;
    }

    public set children(value: ListItemType[]) {
        this._children = value;
    }

    public hasChildren(): boolean {
        return ArrayUtil.isEmptyArray(this.children) === false;
    }

    public addChild(value: ListItemType) {
        this._children.push(value);
    }

    get list(): List {
        return this._list;
    }

    set list(value: List) {
        this._list = value;
    }

    get selectable(): boolean {
        return (this.list?.selectionMode !== SelectionMode.NONE && (this._selectable ?? true))
            && this._renderedComponent?.enabled !== false;
    }

    set selectable(value: boolean) {
        this._selectable = value;
    }

    get originalColor(): Color {
        return this._originalColor ?? this.list?.color;
    }

    setOriginalColor() {
        this._originalColor ??= this.renderedComponent.color;
    }

    get originalBackgroundColor(): Color {
        return this._originalBackgroundColor ?? this.list?.backgroundColor;
    }

    setOriginalBackgroundColor() {
        this._originalBackgroundColor ??= this.renderedComponent.backgroundColor;
    }

    protected createRenderedComponent(defaultLabelProps: Partial<LabelProps>): Component {
        const mainComp = ComponentFactory.createCommon(this.suppliedInput, defaultLabelProps);
        if (ArrayUtil.isEmptyArray(this.children) === true) {
            return mainComp;
        } else {
            mainComp.rowBreak = false;
            const panel = new Panel({ fillRow: true, padding: 0 })
            panel.addClickListener((event: ClickEvent) => {
                event.stopImmediatePropagation();
                Overlay.showDropdown(panel, this.children, this._onChildSelect, { height: null }, { position: Alignment.RIGHT },
                    null, this._list);
            });
            const labelChevron = new Label({ imageName: "chevron", imageRotation: 270 });
            panel.add(mainComp, labelChevron);
            return panel;
        }
    }
}
