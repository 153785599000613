/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelAuthUsers.ts                     *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "auth/auth-users";

export class AutogenModelAuthUsers<RowType extends ModelRow> extends Endpoint<RowType, RowAuthUsersFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowAuthUsers extends ModelRow<RowAuthUsersFields> {
    constructor(values?: Partial<RowAuthUsersFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowAuthUsersFields {
    email_address: string;
    id: string;
    idp_id: string;
    login_id: string;
    name: string;
    password: string;
    token_key: number;
    user_type: string;
}

