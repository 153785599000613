import { ModelRow, StringUtil } from "@mcleod/core";
import { ComponentSearcher } from "./ComponentSearcher"
import { ComponentSearchResult } from "./ComponentSearchResult";

/**
 * Searcher class that facilitates the searching of values related to switches.
 *
 * Always search the switch's field in the modelRow, as well as the caption of the current selection (if present).
 */
export class SwitchSearcher extends ComponentSearcher {
    private switchDef: any;

    constructor(switchDef: any, alias: string) {
        super(switchDef.field, alias);
        this.switchDef = switchDef;
    }

    protected addValuesToResult(searchResult: ComponentSearchResult, row: ModelRow) {
        const fieldValue = row.get(this.fieldName, null)?.toString()?.toLowerCase();
        if (StringUtil.isEmptyString(fieldValue) === false) {
            if (this.switchDef.leftValue?.toLowerCase() === fieldValue)
                searchResult.addValue(this.switchDef.leftCaption);
            else if (this.switchDef.rightValue?.toLowerCase() === fieldValue)
                searchResult.addValue(this.switchDef.rightCaption);
        }        
    }

    
}
