import { Collection, LogManager } from "@mcleod/core";
import { Layout } from "..";
import { IdProps } from "./ComponentProps";

interface RegisteredComponentType {
    constructor: any;
    isCompound: boolean;
    constructorProps: string[];
}

const registeredTypes: Collection<RegisteredComponentType> = {};

const log = LogManager.getLogger("components/base/ComponentTypes");

export class ComponentTypes {
    public static registerComponentType(type: string, constructor: any, isCompound: boolean = false, constructorProps?: string[]): void {
        registeredTypes[type] = { constructor, isCompound, constructorProps };
    }

    public static getComponentType(type: string): RegisteredComponentType {
        return registeredTypes[type];
    }

    public static createComponentOfType(type: string, props: IdProps): any {
        if (type === "layout")
            return Layout.getLayout(props?.["layoutName"], props);
        const c = registeredTypes[type]?.constructor;
        if (c == null)
            throw new Error("Could not find a constructor for " + type + " / " + (props == null ? "no id" : props.id));
        return new c(props);
    }
}
