import { DomMouseEvent } from "./DomEvent";
import { Event, EventListener } from "./Event";
import { KeyModifiers, ModifierKeys } from "./KeyModifiers";

export interface MouseListener extends EventListener {
    (event: MouseEvent): void;
}

export class MouseEvent extends Event implements ModifierKeys {
    public get altKey(): boolean {
        return this.domEvent.altKey;
    }

    public get ctrlKey(): boolean {
        return this.domEvent.ctrlKey;
    }

    public get shiftKey(): boolean {
        return this.domEvent.shiftKey;
    }

    public get domEvent(): DomMouseEvent {
        return super.domEvent as DomMouseEvent;
    }

    public hasModifiers(modifiers: ModifierKeys) {
        return KeyModifiers.hasModifiers(this, modifiers);
    }
}
