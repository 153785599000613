import { getThemeColor, Styles } from "@mcleod/core";

export const DesignerStyles = Styles.make("dsg", {
    designerSelected: {
        border: "2px dashed #444 !important"
    },
    designerContainer: {
        border: "1px dotted #999"
    },
    designerComponentIdError: {
        boxShadow: "inset 0px 0px 2px 2px " + getThemeColor("error")
    },
    designerComponentIdWarning: {
        boxShadow: "inset 0px 0px 2px 2px " + getThemeColor("warning")
    },
    permed: {
        border: "1px solid " + getThemeColor("error.light"),
        borderColor: getThemeColor("error.light") + " !important"
    },
});
