export class Styles {
    private static readonly themedStyles = {};
    private static readonly nonPixelStyles = ["font-weight", "z-index", "tabindex", "flex-grow"];

    /**
     * This function creates a <style> tag in the document's head.  These styles can then be used by
     * DOM HTML elements in the class property.
     * @param prefix a string that will be used as the prefix for all the styles' names
     * @param styles an object with string keys that will be used as the class names.  The values of the
     *  object will be used as the actual styles.  If the styles will change based on values in the theme,
     *  the 'styles' parameter should be a function that returns styles.  When the theme changes (or is
     *  set initially), this function will be called to recompute the styles.
     *
     * @returns An object that is keyed by the class name (including the prefix) that can be used
     *   as the class name for an HTMLElement
     */
    public static make(prefix: string, styles: any | (() => any)): any {
        let isType = false;
        if (prefix == null) {
            isType = true;
            prefix = "";
        }
        if (prefix.length > 0)
            prefix += "-"
        const result = {};
        let styleElements;
        if (typeof styles === "function") {
            styleElements = [];
            this.themedStyles[prefix] = { fn: styles, styleElements: styleElements };
            styles = styles();
        }
        for (const key in styles) {
            const element = this.createClass(prefix + key, styles[key], isType);
            if (styleElements != null)
                styleElements.push(element);
            result[key] = prefix + key;
        }
        return result;
    }

    public static createClass(className: string, style: any, isType: boolean): HTMLStyleElement {
        const styleElement = document.createElement("style");
        styleElement.type = "text/css";
        if (!className.startsWith(".") && !isType)
            className = "." + className;
        if (typeof style === "object")
            styleElement.innerHTML = this.unnest(className, style);
        else
            styleElement.innerHTML += className + '{ ' + style + ' } \n';
        document.getElementsByTagName('head')[0].appendChild(styleElement);
        return styleElement;
    }

    public static recomputeThemedStyles() {
        for (const prefix in this.themedStyles) {
            const styles = this.themedStyles[prefix].fn();
            for (const key in styles) {
                // HACK: TODO: instead of just adding new elements to the DOM, replace the existing ones
                this.createClass(prefix + key, styles[key], false);
            }
        }
    }

    private static unnest(className: string, style: any) {
        let other = "";
        const thisStyle = {};
        for (const key in style) {
            if (key.startsWith("&"))
                other += " " + this.unnest(className + key.substring(1), style[key]);
            else
                thisStyle[key] = style[key];
        }
        return className + " {" + this.convertToStyleString(thisStyle) + "}" + other;
    }
    
    private static convertToStyleString(style: any): string {
        let result = "";
        for (const prop in style) {
            const styleName = this.propNameToCSS(prop);
            let value = style[prop];
            if (typeof value === "number" && this.isPixelStyle(styleName))
                value = value + "px"
            result += styleName + ":" + value + ";";
        }
        return result;
    }

    private static isPixelStyle(styleName: string): boolean {
        return Styles.nonPixelStyles.indexOf(styleName) < 0;
    }
    
    public static propNameToCSS(propName: string): string {
        return propName.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`);
    }

    /** This function takes hex color and convert to RGB. From there using a formula
     * (https://www.w3.org/TR/AERT/#color-contrast) to calculate brightness.
     * Default brightness is white
     */
    public static calculateTextColor(color: any, brightness: number = 255): string {
        if (color === null)
            return "default";
        if (color.slice(0, 1) === '#')
            color = color.slice(1);
        const calculatedBrightness = ((parseInt(color.substr(0,2),16) * 299) + (parseInt(color.substr(2,2),16) * 587) 
            + (parseInt(color.substr(4,2),16) * 114)) / 1000;
        return (calculatedBrightness >= brightness) ? 'default' : 'default.reverse';
    }
}
