import { DynamicLoader } from "@mcleod/core";
import { ComponentCreator } from "../page/ComponentFactory";

/**
 * This class allows classes at this level (@mcleod/components) to show dialogs and avoid circular dependencies.  For examples, a Label
 * might want to show a Dialog for its "Read more" (this is a sore subject for some) functionality, but a dialog is a more complex component
 * that has Labels and Buttons and such.  Some parameter types are simplified so that we don't bring in the dependencies on those types.
 * The original method signatures are documented here, but will not be enforced at compile-time, so use this carefully.
 */
export class ReflectiveDialogs {
    private static _dialogClass: any;

    static get dialogClass() {
        if (ReflectiveDialogs._dialogClass == null)
            ReflectiveDialogs._dialogClass = DynamicLoader.getClassForPath("common/CommonDialogs");
        return ReflectiveDialogs._dialogClass;
    }

    /**
     * Signature of the reflected method:
     * public static createDialog(components: ComponentCreator | ComponentCreator[], dialogProps: Partial<DialogProps>): Dialog
     */
    public static createDialog(components: any, dialogProps: any): any {
        return ReflectiveDialogs.dialogClass.createDialog(components, dialogProps);
    }

    /**
     * Signature of the reflected method:
     * public static async showError(error: ComponentCreator | ServerError | Error | ErrorMessage, title: string = "Error"): Promise<Dialog>
     */
    public static showError(error: any, title?: string) {
        return ReflectiveDialogs.dialogClass.showError(error, title);
    }

    /**
    * Signature of the reflected method:
    * public static async showDialog(components: ComponentCreator | ComponentCreator[], dialogProps?: Partial<DialogProps>): Promise<Dialog>
    */
    public static async showDialog(components: any, dialogProps?: any): Promise<any> {
        return ReflectiveDialogs.dialogClass.showDialog(components, dialogProps);
    }

    /**
     * Signature of the reflected method:
     * public static showYesNo(content: ComponentCreator, title?: string, props?: Partial<YesNoDialogProps>, showIcons?: boolean, buttonWidth?: number): Promise<boolean>
     */
    public static showYesNo(content: ComponentCreator, title?: string, props?: any, showIcons?: boolean, buttonWidth?: number): Promise<boolean> {
        return ReflectiveDialogs.dialogClass.showYesNo(content, title, props, showIcons, buttonWidth);
    }

    /**
      * Signature of the reflected method:
      * public static showDestructiveDialog(content: ComponentCreator, title?: string, props?: Partial<YesNoDialogProps>, showIcons?: boolean, buttonWidth?: number): Promise<boolean>
    */
    public static showDestructive(content: ComponentCreator, title?: string, props?: any, showIcons?: boolean, buttonWidth?: number): Promise<boolean> {
        return ReflectiveDialogs.dialogClass.showDestructive(content, title, props, showIcons, buttonWidth);
    }

    /**
     * Signature of the reflected method:
     * public static async showMessage(cacontentption: ComponentCreator, title?: string, dialogProps?: Partial<DialogProps>): Promise<Dialog>
     */
    public static showMessage(content: string, title?: string, dialogProps?: any): Promise<any> {
        return ReflectiveDialogs.dialogClass.showMessage(content, title, dialogProps);
    }

    /**
     * Signature of the reflected method:
     * public static async showInputDialog(content: ComponentCreator, title?: string, textboxProps?: Partial<TextboxProps>, dialogProps?: Partial<DialogProps>): Promise<string>
    */
    static showInputDialog(content: ComponentCreator, title?: string, textboxProps?: any, dialogProps?: any): Promise<string> {
        return ReflectiveDialogs.dialogClass.showInputDialog(content, title, textboxProps, dialogProps);
    }
}
