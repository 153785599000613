import { DecimalFormat } from "./DecimalFormat";
import { StringUtil } from "./StringUtil";

export class NumberUtil {
    public static removeFormatting(value: string): string {
        return value.replace(/,/g, '');
    }

    public static formatInteger(value: number, format?: string): string {
        if (value != null && !isNaN(value)) {
            if (format == null)
                return new Intl.NumberFormat("en-US", { useGrouping: false }).format(value);
            else
                return new DecimalFormat(format).format(value);
        }
        return null;
    }

    public static formatDecimal(value: number, format?: string): string {
        if (value != null && !isNaN(value)) {
            if (format == null)
                return new Intl.NumberFormat("en-US", { style: "decimal", minimumFractionDigits: 1 }).format(value);
            else
                return new DecimalFormat(format).format(value);
        }
        return null;
    }

    public static areEqual(value1: any, value2: any): boolean {
        if (value1 == value2)
            return true;
        else if (value1 == null || value2 == null)
            return false;
        else
            return NumberUtil.getFloat(value1) == NumberUtil.getFloat(value2);
    }

    public static getFloat(value: any): number {
        if (StringUtil.isEmptyString(value))
            return null;
        return parseFloat(this.removeFormatting(value.toString()));
    }
}
