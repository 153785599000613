import { Button, ButtonProps, ButtonVariant, Component, ContextMenuItemCreator, ContextMenuLabel, DesignableObject, HorizontalSpacer, KeyHandler, Label, LabelProps, Panel, Splitter, ValidationResult } from "@mcleod/components";
import { ArrayUtil, getThemeColor, HorizontalAlignment, Keys, LogManager, StringUtil } from "@mcleod/core";
import { CodeEditor } from "../common/CodeEditor";
import { AbstractUIDesigner } from "./AbstractUIDesigner";
import { ActionAddComponent } from "./actions/ActionAddComponent";
import { ActionDeleteComponent } from "./actions/ActionDeleteComponent";
import { DesignerAction } from "./actions/DesignerAction";
import { DesignerActionExecutor } from "./DesignerActionExecutor";
import { DesignerAdapter } from "./DesignerAdapter";
import { LayoutDesignerTab } from "./LayoutDesignerTab";
import { PropertiesTable } from "./PropertiesTable";
import { UIDesignerKeyHandlers } from "./UIDesignerKeyHandlers";
import { UIDesignerUtil } from "./UIDesignerUtil";

const log = LogManager.getLogger("designer.ui.ContextMenuDesigner");
export class ContextMenuDesigner extends DesignerAdapter {
    private sourceComponent: Component;
    private sourceDesigner: AbstractUIDesigner;

    private listPanel = Panel.createNoMarginNoPaddingPanel({
        backgroundColor: getThemeColor("backgroundSubtle"),
        borderShadow: true,
        borderRadius: 4,
        borderWidth: 1,
        width: 300,
        rowBreak: false,
        borderColor: getThemeColor("subtle.light"),
        padding: 0,
    });

    private emptyLabel = new Label({
        text: "No menu items",
        fillRow: true,
        fontSize: "large",
        color: "subtle.light",
        align: HorizontalAlignment.CENTER,
        paddingTop: 12,
        paddingBottom: 12
    });

    private buttonAdd: Button;
    private buttonRemove: Button;
    private mainPanel = new Panel({ fillHeight: true, fillRow: true});
    private _designerPanel = new Panel({ fillHeight: true, fillRow: true, borderWidth: 1, borderColor: "subtle" });
    protected actionPanel = new Panel({ height: 50, fillRow: true, borderWidth: 1, borderColor: "subtle", align: HorizontalAlignment.RIGHT });
    protected splitter = new Splitter({ id: "splitterProps", position: "80%", fillRow: true, fillHeight: true, expandButtonsVisible: false  });
    selectedComponents: Component[] = [];
    private activeLayoutTab: LayoutDesignerTab;

    private clickEventMap: Map<Component, string> = new Map();

    constructor(sourceComponent: Component, sourceDesigner: AbstractUIDesigner) {
        super({ fillHeight: true, fillRow: true, needsServerLayout: false });
        this.mainPanel.add(this.actionPanel);
        this.mainPanel.add(this.getActiveTab().designerPanel);
        this.splitter.add(this.mainPanel);
        this.add(this.splitter);
        this.splitter.add(this.getActiveTab().propsPanel);
        this.addActionButtons();
        this.splitter.position = "50%";
        this.sourceComponent = sourceComponent;
        this.sourceDesigner = sourceDesigner;
        this.designerPanel.add(new HorizontalSpacer(), this.listPanel, new HorizontalSpacer());

        const itemProps = ContextMenuItemCreator.createListItems({sourceComponent, designer: this});
        if (!ArrayUtil.isEmptyArray(itemProps)) {
            itemProps.forEach(item => {
                const comp = item.renderedComponent;
                this.listPanel.add(comp);
                if (comp instanceof ContextMenuLabel && comp["onClick"]) {
                    this.clickEventMap.set(comp, comp["onClick"]);
                }
                if (this.listPanel.getComponentCount() === 1) {
                    this.selectComponent(comp);
                }
            });
        }
        this.syncEmptyLabel();
    }

    get actionExecutor() : DesignerActionExecutor {
        return this.getActiveTab().actionExecutor;
    }

    public get designerPanel(): Panel {
        return this._designerPanel;
    }

    get tableProps(): PropertiesTable {
        return this.getActiveTab().propsPanel.tableProps;
    }

    getActiveTab() {
        if (this.activeLayoutTab == null) {
            this.activeLayoutTab = new LayoutDesignerTab(this, {path: ""});
            this.activeLayoutTab.designerPanel.add(this._designerPanel);
        }
        return this.activeLayoutTab;
    }

    override getKeyHandlers(): KeyHandler[] {
        return [
            { key: Keys.DELETE, listener: () => this.deleteSelectedComponents()},
            ...UIDesignerKeyHandlers.getDesignerSelectSiblingKeyListeners(this),
            ...UIDesignerKeyHandlers.moveSelectedKeyListeners(this),
            ...UIDesignerKeyHandlers.undoRedoKeyListeners(this)
        ];
    }

    deleteSelectedComponents() {
        this.deleteComponents(this.selectedComponents);
    }

    deleteComponents(components: DesignableObject[]) {
        if (ArrayUtil.isEmptyArray(components))
            return;
        for (const component of components)
            this.executeAction(new ActionDeleteComponent(component));
        this.selectComponent(this.listPanel.components.find(comp => comp instanceof ContextMenuLabel));
    }

    syncSeparator(menuItem: ContextMenuLabel) {
        const separatorPanel = menuItem.separatorListItem.renderedComponent;
        if (menuItem.separatorAfter) {
            separatorPanel.isRow = true;
            const index = this.listPanel.indexOf(menuItem);
            this.listPanel.insert(separatorPanel, index + 1);
        } else {
            this.listPanel.remove(separatorPanel);
        }
    }

    private syncEmptyLabel() {
        if (this.listPanel.isEmpty()) {
            this.listPanel.add(this.emptyLabel);
        } else if (this.listPanel.contains(this.emptyLabel) && this.listPanel.getComponentCount() > 1) {
            this.listPanel.remove(this.emptyLabel);
        }
    }

    selectComponent(object: DesignableObject, add: boolean = false) {
        if (object == this.getActiveTab().designerPanel)
            return;
        UIDesignerUtil.designerHandleComponentSelection(this.selectedComponents, object as Component, add, this.getActiveTab().propsPanel);
        this.buttonRemove.enabled = this.selectedComponents.length > 0;
    }

    private addActionButtons() {
        this.actionPanel.align = HorizontalAlignment.LEFT;
        this.addActionButton({
            imageName: "arrow",
            imageRotation: 180,
            disabledTooltip: "Select a menu item to move",
            onClick: () => UIDesignerKeyHandlers.moveSelected(this, -1)
        });

        this.addActionButton({
            imageName: "arrow",
            imageColor: "success",
            disabledTooltip: "Select a menu item move",
            onClick: () => UIDesignerKeyHandlers.moveSelected(this, 1)
        });

        this.buttonAdd = this.addActionButton({
            imageName: "add",
            tooltip: "Add new menu item",
            onClick: () => this.addNewMenuItem()
        });

        this.buttonRemove = this.addActionButton({
            imageName: "x",
            tooltip: "Remove selected menu item",
            enabled: false,
            disabledTooltip: "Select a menu item to remove",
            onClick: () =>  {
                this.deleteSelectedComponents();
                this.syncEmptyLabel();
            }
        });

        this.actionPanel.add(new HorizontalSpacer());
        this.actionPanel.add(this.actionExecutor.buttonUndo);
        this.actionPanel.add(this.actionExecutor.buttonRedo);
    }

    addActionButton(buttonProps: Partial<ButtonProps>): Button {
        const button = new Button({variant: ButtonVariant.round, color: "subtle.darker", rowBreak: false, ...buttonProps });
        this.actionPanel.add(button);
        return button;
    }

    addNewMenuItem() {
        this.addMenuItem({ text: this.getNewItemtext() },
            label => this.executeAction(new ActionAddComponent(label, this.listPanel)),
            true
        );
    }

    addMenuItem(props: Partial<LabelProps>, addFunction: (label: Label) => void,  select = this.listPanel.isEmpty()): ContextMenuLabel {
        const menuItem = new ContextMenuLabel(this.sourceComponent, { _designer: this, ...props });
        menuItem.deserialized = true;
        addFunction(menuItem);
        if (select) {
            this.selectComponent(menuItem);
        }
        this.syncEmptyLabel();
        return menuItem;
    }

    private getNewItemtext(): string {
        let i = 1;
        let newItemText = `New Item ${i}`;
        while (this.listPanel.components.find(label => label instanceof Label && label.text === newItemText)) {
            i++;
            newItemText = `New Item ${i}`;
        }
        return newItemText;
    }

    getListItems(): ContextMenuLabel[] {
        return this.listPanel.findComponentsByType(ContextMenuLabel);
    }

    addEventHandlerFunction(menuLabel: DesignableObject, eventName: string): void {
        if (!(menuLabel instanceof ContextMenuLabel))
            return;

        const prop = menuLabel.getPropertyDefinitions()[eventName];
        if (prop == null)
            return;

        let signature = prop.eventSignature;
        if (signature == null)
            signature = eventName[0].toUpperCase() + eventName.substring(1) + "(event)";
        menuLabel[eventName] = menuLabel.getId(false) + StringUtil.stringBefore(signature, "(");
        this.redisplayProp(eventName, menuLabel[eventName]);
    }

    addEventHandlersAfterSave() {
        const eventName = "onClick";
        const itemsWithOnClick = this.getComponentsWithOnClick();
        if (ArrayUtil.isEmptyArray(itemsWithOnClick))
            return;

        this.sourceDesigner.showSave(true, false).then(async obj => {
            const fileName = await this.sourceDesigner.getCodeFileName();
            itemsWithOnClick.forEach(async item => {
                const functionName = item["onClick"] + item.getPropertyDefinitions().onClick.eventSignature;
                await CodeEditor.addCodeFunction(fileName, functionName, {
                    contentsIfEmpty: this.sourceDesigner.getCodeSkeleton(),
                    comment: "/** This is an event handler for the " + eventName + " event of " + item.id + ". */\n"
                });
            });
        });
    }

    private getComponentsWithOnClick(): Component[] {
        const result = [];
        for (const item of this.getListItems()) {
            const onclick = item["onClick"];
            if (onclick != null && this.clickEventMap.get(item) !== onclick) {
                result.push(item);
            }
        }
        return result;
    }

    redisplayProp(propName: string, value: string) {
        this.tableProps.redisplayProp(propName, value);
    }

    disablePropertyEditors(prop: any, editorComponents: Component[], selectedComponent: Component): void {
        if (prop.name == "id") {
            editorComponents.forEach(component => component.enabled = false);
        }
    }

   doAfterPropChanged(component: Component, propName: string, oldValue: any, newValue: any, redisplayProp?: boolean, propsSeen: string[] = []) {
        UIDesignerUtil.doAfterPropChanged(this, component, propName, oldValue, newValue, redisplayProp, propsSeen);
        if (component instanceof ContextMenuLabel && propName === "separatorAfter") {
            this.syncSeparator(component);
        }
    }

    applyChangeToSelectedComponents(data, newValue) {
        this.modified();
        UIDesignerUtil.designerApplyChangeToSelectedComponents(this.selectedComponents, this.getActiveTab(), data, newValue, this.tableProps);
    }

    get firstSelected(): Component {
        if (this.selectedComponents.length === 0)
            return null;
        return this.selectedComponents[0];
    }

    filterProps(props: any, selectedComponent: Component): void {
        for (const key of Object.keys(props)) {
            let visibleInDesigner = props[key].visibleInDesigner;
            if (typeof visibleInDesigner === "function")
                visibleInDesigner = visibleInDesigner();
            if (visibleInDesigner === false) {
                delete props[key];
            }
        }
    }

    async executeAction (action: DesignerAction, notifyActionHistory = true) {
        this.actionExecutor?.doDesignerAction(action);
    }

    override validate(checkRequired: boolean, showErrors: boolean = true): ValidationResult[] {
        const result = super.validate(checkRequired, showErrors);
        if (ArrayUtil.isEmptyArray(result)) {
           return this.validateCaptions();
        }
        result.push(...this.validateCaptions());
        return result;
    }

    // The id cannot be modified in the designer but it's based off the caption. So we need to make sure the caption is unique.
    validateCaptions(): ValidationResult[] {
        const result = [];
        const captionsSeen = new Set();
        this.getListItems().forEach(item => {
            const caption = item.text;
            if (StringUtil.isEmptyString(caption)) {
                result.push({isValid: false, component: item, validationMessage: "Caption is required"});
            } else if (captionsSeen.has(caption)) {
                result.push({isValid: false, component: item, validationMessage: `Duplicate caption: ${caption}`});
            } else {
                captionsSeen.add(caption);
            }
        });
        return result;
    }
}
