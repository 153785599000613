/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelTenantUser.ts                   *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "auth/tenant-user";

export class AutogenModelTenantUser<RowType extends ModelRow> extends Endpoint<RowType, RowTenantUserFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowTenantUser extends ModelRow<RowTenantUserFields> {
    constructor(values?: Partial<RowTenantUserFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowTenantUserFields {
    company_name: string;
    customer_id: string;
    email_address: string;
    home_url: string;
    id: string;
    login_id: string;
    login_index: number;
    name: string;
    password: string;
    tenant_id: string;
    token_key: number;
    user_type: string;
}

