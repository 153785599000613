import { addDays, addHours, addMilliseconds, addMinutes, addMonths, addSeconds, addWeeks, addYears, differenceInDays, differenceInHours, differenceInMilliseconds, differenceInMinutes, differenceInMonths, differenceInSeconds, differenceInWeeks, differenceInYears, format, isValid, parse } from "date-fns";
import { DateRange } from "./DateRange";
import { StringUtil } from "./StringUtil";
import { Timezone } from "./Timezone";
import { DisplayType, GeneralSettings } from ".";

let userDateFormat = "MM/dd/yy";
let userTimeFormat = "HH:mm";
let userDateTimeFormat = userDateFormat + " " + userTimeFormat;
let upcomingHolidays: string[];
const _clientTzOffset = new Date().getTimezoneOffset() * 60 * 1000;

interface CaptionProps {
    displayValue: string;
}

Date.prototype.toJSON = function () {
    if (this.hasTime === false)
        return DateUtil.formatDateTime(this, "yyyy-MM-dd");
    else
        return DateUtil.formatDateTime(this, "yyyy-MM-dd HH:mm:ss");
}

Date.prototype.toString = function () {
    return this.toJSON();
}

const dateKeyWords = ["T", "N", "ME", "WE", "QE", "YE", "M", "W", "Q", "Y"];

export enum DatePart {
    YEAR = "year",
    MONTH = "month",
    WEEK = "week",
    DAY = "day",
    HOUR = "hour",
    MINUTE = "minute",
    SECOND = "second",
    MILLI = "milli"
}
export enum DateFormat {
    DATE = "date",
    DATE_TIME = "datetime",
    TIME = "time"
}

export enum ExtendedDateFormat {
    LONG = "long",
    RELATIVE = "relative",
}

const dateFunctions = {
    year: { add: addYears, diff: differenceInYears },
    month: { add: addMonths, diff: differenceInMonths },
    week: { add: addWeeks, diff: differenceInWeeks },
    day: { add: addDays, diff: differenceInDays },
    hour: { add: addHours, diff: differenceInHours },
    minute: { add: addMinutes, diff: differenceInMinutes },
    second: { add: addSeconds, diff: differenceInSeconds },
    millis: { add: addMilliseconds, diff: differenceInMilliseconds },
}

// can't use a Logger in this class because Logger uses Date!  Not sure if it's worth breaking the dependency.
const parseContextDate = new Date();

export class DateUtil {
    public static justDate(date: Date): Date {
        if (date == null) {
            return null;
        }
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }

    public static justTime(date: Date | string): Date {
        if (StringUtil.isEmptyString(date))
            return null;
        if (typeof date === "string")
            date = new Date(date);
        return new Date(1970, 0, 1, date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
    }

    public static timesEqual(value1: Date | string, value2: Date | string) {
        const date1 = DateUtil.justTime(value1);
        const date2 = DateUtil.justTime(value2)
        return DateUtil.areEqual(date1, date2);
    }

    public static dateTimesEqual(value1: Date | string, value2: Date | string) {
        const date1 = DateUtil.parseDateTime(value1);
        const date2 = DateUtil.parseDateTime(value2)
        return DateUtil.areEqual(date1, date2);
    }

    public static datesEqual(value1: Date | string, value2: Date | string) {
        const date1 = DateUtil.parseDate(value1);
        const date2 = DateUtil.parseDate(value2)
        return DateUtil.areEqual(date1, date2);
    }

    private static areEqual(date1: Date, date2: Date): boolean {
        if (date1 == null && date2 == null)
            return true;
        else if (date1 == null || date2 == null)
            return false;
        return date1?.getTime() == date2?.getTime();
    }

    public static dateAdd(whichPart: DatePart, date: Date, amount: number): Date {
        const part = dateFunctions[whichPart];
        if (part == null)
            throw new Error("Unrecognized date part " + whichPart);
        return part.add(date, amount);
    }

    public static dateDiff(whichPart: DatePart, dateLeft: Date, dateRight: Date): number {
        const part = dateFunctions[whichPart];
        if (part == null)
            throw new Error("Unrecognized date part " + whichPart);
        return part.diff(dateLeft, dateRight);
    }

    public static msToTime(milli: number): {
        milliseconds: number;
        seconds: number;
        minutes: number;
        hours: number;
    } {
        const ms = milli % 1000;
        milli = (milli - ms) / 1000;
        const secs = milli % 60;
        milli = (milli - secs) / 60;
        const mins = milli % 60;
        const hrs = (milli - mins) / 60;

        return {
            milliseconds: ms,
            seconds: secs,
            minutes: mins,
            hours: hrs
        }
    }

    public static parseDateTime(value: string | Date): Date {
        if (StringUtil.isEmptyString(value))
            return null;
        if (value instanceof Date)
            return value;
        const dateFormats = ["yyyy-MM-dd HH:mm:ss", "yyyy-MM-dd HH:mm:ss.SSS", "MM/dd/yyyy HHmm"];
        let parsedDate = null;
        dateFormats.find(format => {
            parsedDate = parse(value, format, parseContextDate);
            if (parsedDate.toDateString() !== DateUtil.INVALID_DATE_STRING) {
                return parsedDate;
            }
        })
        return parsedDate;
    }

    public static parseDate(value: string | Date): Date {
        if (StringUtil.isEmptyString(value))
            return null;
        if (value instanceof Date)
            return value;
        const spacePos = value.indexOf(" ");
        if (spacePos > 0)
            value = value.substring(0, spacePos);
        return parse(value, "yyyy-MM-dd", parseContextDate);
    }

    public static formatDate(value: Date, formatString?: string): string {
        return DateUtil.formatDateTime(value, formatString || DateUtil.getUserDateFormat());
    }

    public static formatTime(value: Date, formatString?: string): string {
        return DateUtil.formatDateTime(value, formatString || DateUtil.getUserTimeFormat());
    }

    public static formatDateTime(value: Date, formatString?: string): string {
        if (value == null)
            return null;
        if (isNaN(value.getTime()))
            return null;
        try {
            return format(value, formatString || DateUtil.getUserDateTimeFormat());
        } catch (reason) {
            return null;
        }
    }

    public static formatDateRange(value: DateRange, formatString?: string): string {
        if (value == null)
            return null;
        return value.getFormattedString(formatString);
    }

    public static getDateFormat(which: DateFormat, extendedFormat?: ExtendedDateFormat) {
        if (which === DateFormat.DATE_TIME) {
            if (extendedFormat === ExtendedDateFormat.LONG)
                return "iiii MMMM dd, yyyy hh:mma";
            else
                return DateUtil.getUserDateTimeFormat();
        } else if (which === DateFormat.DATE) {
            if (extendedFormat === ExtendedDateFormat.LONG)
                return "iiii MMMM dd, yyyy";
            else
                return DateUtil.getUserDateFormat();
        } else if (which === DateFormat.TIME) {
            if (extendedFormat === ExtendedDateFormat.LONG)
                return "hh:mma";
            else
                return DateUtil.getUserTimeFormat();
        } else throw new Error("Unrecognized parameter to getDateFormat " + which);
    }

    public static setUpcomingHolidays(value: string[]) {
        upcomingHolidays = value;
    }

    public static setUserSettings(dateFormat: string, timeFormat: string, dateTimeFormat?: string) {
        if (!StringUtil.isEmptyString(dateFormat))
            userDateFormat = dateFormat;
        if (!StringUtil.isEmptyString(timeFormat))
            userTimeFormat = timeFormat;
        if (!StringUtil.isEmptyString(dateTimeFormat))
            userDateTimeFormat = dateTimeFormat;
        else
            userDateTimeFormat = userDateFormat + " " + userTimeFormat;
    }

    public static getUserDateFormat(): string {
        return userDateFormat;
    }

    public static getUserTimeFormat(): string {
        return userTimeFormat;
    }

    public static getUserDateTimeFormat(): string {
        return userDateTimeFormat;
    }

    public static parseDateByDisplayType(value: string, displayType: DisplayType, timeZone?: Timezone): Date {
        if (value == null || displayType == null)
            return null;
        switch (displayType) {
            case DisplayType.DATE:
                return DateUtil.parseDateWithKeywords(value, true, false, timeZone);
            case DisplayType.TIME:
                return DateUtil.parseDateWithKeywords(value, false, true, timeZone);
            case DisplayType.DATETIME:
                return DateUtil.parseDateWithKeywords(value, true, true, timeZone);
            case DisplayType.DATERANGE:
                return  DateRange.parseDateRange(value);
            default:
                return null;
        }
    }

    public static parseDateWithKeywords(value: string, hasDate: boolean = true, hasTime: boolean = true, timeZone?: Timezone): Date {
        if (value == null)
            return null;
        value = value.trim().toUpperCase();
        const spacePos = value.indexOf(" ");
        let timePortion = null;
        if (spacePos > 0) {
            timePortion = DateUtil.parseTime(value.substring(spacePos + 1));
            value = value.substring(0, spacePos);
        }
        let result = DateUtil.parseKeywords(value, timeZone);
        if (result == null && !hasDate && hasTime)
            result = DateUtil.parseTime(value);
        if (result == null)
            result = DateUtil.parseNumericDate(DateUtil.convertDateToNumeric(value));
        if (result == null)
            result = DateUtil.parseDateTime(value);
        if (hasDate && result != null && value.substring(spacePos + 1) !== "N") {
            if (timePortion == null || !hasTime)
                result.setHours(0, 0, 0, 0);
            else
                result.setHours(timePortion.getHours(), timePortion.getMinutes());
        }
        return result;
    }

    public static convertDateToNumeric(value: string): string {
        const tokens = value.split(/[/-]/);
        for (let i = 0; i < tokens.length; i++)
            if (tokens[i].length === 1)
                tokens[i] = "0" + tokens[i]
        return tokens.join("");
    }

    public static parseNumericDate(value: string): Date {
        if (!isNaN(new Number(value).valueOf())) {
            const now = new Date();
            if (value.length === 2)
                return new Date(now.getFullYear(), now.getMonth(), parseInt(value));
            else if (value.length === 4)
                return new Date(now.getFullYear(), parseInt(value.substring(0, 2)) - 1, parseInt(value.substring(2, 4)));
            else if (value.length === 6) {
                let year = parseInt(value.substring(4, 6));
                year += DateUtil.getCenturyForTwoDigitYear(year);
                return new Date(year, parseInt(value.substring(0, 2)) - 1, parseInt(value.substring(2, 4)));
            }
            else if (value.length === 8)
                return new Date(parseInt(value.substring(4, 8)), parseInt(value.substring(0, 2)) - 1, parseInt(value.substring(2, 4)));
        }
        return null;
    }

    private static getCenturyForTwoDigitYear(twoDigitYear: number): number {
        const CENTURY_WINDOW = 25;
        const now = new Date();
        const year = now.getFullYear()
        const currCent = Math.floor(now.getFullYear() / 100) * 100;
        const currYear = year - currCent;
        if (currYear - CENTURY_WINDOW > twoDigitYear)
            return currCent + 100;
        else if (currYear + CENTURY_WINDOW < twoDigitYear)
            return currCent - 100;
        else
            return currCent;
    }

    public static parseTime(value: string | CaptionProps | Date, origDate?: Date): Date {
        if (value == null)
            return null;
        if (value instanceof Date)
            return value;
        if (typeof value === "object" && value.displayValue)
            value = value.displayValue;
        else if (typeof value === "object") {
            value = JSON.stringify(value);
            const spacePos = value.lastIndexOf(" ");
            if (spacePos >= 0)
                value = value.substring(spacePos + 1);
        }

        const pmIdx = value.indexOf("P");
        const amIdx = value.indexOf("A");
        const pmEntered = pmIdx > 0;
        const amEntered = amIdx > 0;
        if (pmEntered) {
            value = value.substring(0, pmIdx);
        } else if (amEntered) {
            value = value.substring(0, amIdx);
        }

        const spacePos = value.indexOf(" "); // handles the case where the passed value was a date time
        if (spacePos > 0)
            value = value.substring(spacePos + 1);
        const firstColon = value.indexOf(":");
        const lastColon = value.lastIndexOf(":");
        if (firstColon !== lastColon)
            value = value.substring(0, lastColon);
        value = value.replace(":", "").toUpperCase();
        const numericPortion = parseInt(value);
        let hours: number, minutes: number;
        if (value.length < 4 && numericPortion < 25) {
            hours = numericPortion;
            minutes = 0;
        }  else {
            minutes = numericPortion % 100;
            hours = Math.floor((numericPortion - minutes) / 100);
        }

        if (pmEntered && hours < 12) {
            hours += 12;
        } else if (amEntered && hours == 12) {
            hours = 0;
        }

        let result = new Date();
        if (origDate) {
            result = new Date(origDate);
        }
        result.setHours(hours, minutes, 0, 0);
        return result;
    }

    private static parseKeywords(value: string, timezone?: Timezone): Date {
        for (let i = 0; i < dateKeyWords.length; i++) {
            if (value.indexOf(dateKeyWords[i]) === 0) {
                const result = DateUtil.offsetDateToTimezone(new Date(), timezone);
                const aftKeyword = value.substring(dateKeyWords[i].length, value.length).trim();
                let valueToAdd = 0;
                if (!StringUtil.isEmptyString(aftKeyword))
                    valueToAdd = parseInt(aftKeyword);
                if (i === 2) { // ME
                    result.setMonth(result.getMonth() + valueToAdd);
                    result.setDate(DateUtil.daysInMonth(result.getMonth(), result.getFullYear()));
                }
                else if (i === 3) // WE
                    result.setDate(result.getDate() + (6 - result.getDay() + (7 * valueToAdd)));
                else if (i === 4) { //QE
                    let quarterEndMonth = Math.floor(result.getMonth() / 3) * 3 + 2;
                    quarterEndMonth += valueToAdd * 3;
                    result.setMonth(quarterEndMonth);
                    result.setDate(DateUtil.daysInMonth(quarterEndMonth, result.getFullYear()));
                } else if (i === 5) { // YE
                    result.setMonth(11);
                    result.setDate(31);
                    result.setFullYear(result.getFullYear() + valueToAdd);
                } else if (i === 6) {// M
                    result.setMonth(result.getMonth() + valueToAdd)
                    result.setDate(1);
                }
                else if (i === 7) // W
                    result.setDate(result.getDate() - result.getDay() + (7 * valueToAdd));
                else if (i === 8) { // Q
                    let quarterStartMonth = Math.floor(result.getMonth() / 3) * 3;
                    quarterStartMonth += valueToAdd * 3;
                    result.setMonth(quarterStartMonth);
                    result.setDate(1);
                } else if (i === 9) { // Y
                    result.setDate(1);
                    result.setMonth(0);
                    result.setFullYear(result.getFullYear() + valueToAdd);
                }
                else if (valueToAdd !== 0)
                    result.setDate(result.getDate() + valueToAdd);
                return result;
            }
        }
        return null;
    }

    /**
     * This is private because it's almost never ok to add/subtract minutes from a
     * Date to adjust it to another timezone.  It works in this module because it
     * is only involved in parsing.
     */
    private static offsetDateToTimezone(date: Date, timezone: Timezone): Date {
        if (date == null || timezone == null)
            return date;
        const thisOffset = date.getTimezoneOffset();
        const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
        const tzDate = new Date(date.toLocaleString("en-US", { timeZone: timezone.ianaIdentifier }));
        const otherOffset = (utcDate.getTime() - tzDate.getTime()) / 60000;
        const diffMinutes = thisOffset - otherOffset;
        return DateUtil.dateAdd(DatePart.MINUTE, date, diffMinutes);
    }

    public static daysInMonth(month: number, year: number): number {
        return new Date(year, month + 1, 0).getDate();
    }

    public static isDateValid(date: any): boolean {
        return isValid(date);
    }

    public static getDayOfWeek(date: Date): string {
        const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        return days[date.getDay()];
    }

    public static getMonthOfYear(date: Date): string {
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        return months[date.getMonth()];
    }

    public static isHoliday(dateTime: Date): boolean {
        if (upcomingHolidays == null)
            return false;
        for (const holidayStr of upcomingHolidays) {
            const holiday = DateUtil.parseDateTime(holidayStr);
            if (holiday.getFullYear() === dateTime.getFullYear() &&
                holiday.getMonth() === dateTime.getMonth() &&
                holiday.getDate() === dateTime.getDate()) {
                return true;
            }
        }
        return false;
    }

    public static convertServerDateToClientTZ(serverDate: Date): Date {
        const serverTzOffset = GeneralSettings.get().server_tz_offset * -1;
        const clientServerOffsetMillis = serverTzOffset - _clientTzOffset;
        return new Date(serverDate.getTime() + clientServerOffsetMillis);
    }

    public static convertClientDateToServerTZ(clientDate: Date): Date {
        const serverTzOffset = GeneralSettings.get().server_tz_offset * -1;
        const clientServerOffsetMillis = serverTzOffset - _clientTzOffset;
        return new Date(clientDate.getTime() - clientServerOffsetMillis);
    }

    public static getMillisInPart(part: DatePart) {
        switch (part) {
            case DatePart.MILLI: return 1;
            case DatePart.SECOND: return 1000;
            case DatePart.MINUTE: return 60 * 1000;
            case DatePart.HOUR: return 60 * 60 * 1000;
            case DatePart.DAY: return 24 * 60 * 60 * 1000;
        }
        throw new Error("Unsupported date part: " + part);
    }

    public static INVALID_DATE_STRING = "Invalid Date";
}
