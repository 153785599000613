import { format } from "date-fns";
import { DatePart, DateUtil } from "./Date";

export class DateRange extends Date {
    private _beginningDate: Date;
    private _endDate: Date;

    constructor(beginningDate?: Date, endDate?: Date) {
        super();
        this._beginningDate = beginningDate;
        this._endDate = endDate;
    }

    get beginningDate(): Date {
        return this._beginningDate;
    }

    set beginningDate(value: Date) {
        this._beginningDate = value;
    }

    get endDate(): Date {
        if (this._endDate == null && this.hasValidDates()) {
            this._endDate = new Date(this._beginningDate);
        }
        return this._endDate;
    }

    set endDate(value: Date) {
        this._endDate = value;
        if (DateUtil.isDateValid(this._endDate)) {
            this._endDate.setHours(23, 59, 59, 999);
        }
    }

    hasValidDates(): boolean {
        if (!DateUtil.isDateValid(this._beginningDate))
            return false;

        if (this._endDate != null) {
            if (!DateUtil.isDateValid(this._endDate))
                return false;
            return this._beginningDate <= this._endDate;
        }

        return true;
    }

    private isSingleDay(): boolean {
        return this._endDate != null && DateUtil.dateDiff(DatePart.DAY, this._beginningDate, this._endDate) === 0;
    }

    private getFormattedBeginningDate(formatString: string): string {
        return this._beginningDate != null ? format(this._beginningDate, formatString) : null;
    }

    private getFormattedEndDate(formatString: string): string {
        if (this._endDate == null || this.isSingleDay()) {
            return null;
        }
        return format(this._endDate, formatString);
    }

    getFormattedString(formatString: string = DateUtil.getUserDateFormat()): string {
        const formatBegin = this.getFormattedBeginningDate(formatString);
        const formatEnd = formatBegin ? this.getFormattedEndDate(formatString) : null;
        return formatEnd ? `${formatBegin} - ${formatEnd}` : formatBegin;
    }

    public static parseDateRange(value: string | DateRange): DateRange {
        if (value instanceof DateRange) {
            return value;
        } else if (value == null) {
            return new DateRange();
        } else if (value.length < 10 || value.includes(",")) {
            // length < 10 assumes that a single MM/DD/YYYY date wasn't entered
            // users cant enter expressions like : "t-1", "t-1,t" or "MM/DD/YYY, t"
            return DateRange.parseDateRangeWithKeywords(value);
        }
        return DateRange.parseNumericDateRange(value);
    }

    public static parseNumericDateRange(dateRangeString: string): DateRange {
        const dateRange = new DateRange();
        if (dateRangeString != null) {
            dateRangeString = dateRangeString.replace(/\s/g, '');
            if (dateRangeString.toString().indexOf('-') > -1) {
                dateRange.beginningDate = DateUtil.parseNumericDate(DateUtil.convertDateToNumeric(dateRangeString.split('-')[0]));
                dateRange.endDate = DateUtil.parseNumericDate(DateUtil.convertDateToNumeric(dateRangeString.split('-')[1]));
            }
            else
                dateRange.beginningDate = DateUtil.parseNumericDate(DateUtil.convertDateToNumeric(dateRangeString.split('-')[0]));
        }
        return dateRange;
    }

    public static parseDateRangeWithKeywords(dateRangeString: string): DateRange {
        const dateRange = new DateRange();

        if (dateRangeString) {
            const [beginningDateString, endDateString] = dateRangeString.replace(/\s/g, '').split(",");

            dateRange.beginningDate = DateUtil.parseDateWithKeywords(beginningDateString, true, false);

            if (endDateString) {
                dateRange.endDate = DateUtil.parseDateWithKeywords(endDateString, true, false);
            }
        }
        return dateRange;
    }

    public getDifferenceInDates(increment: DatePart = DatePart.DAY): number {
        return Math.abs(DateUtil.dateDiff(increment, this.beginningDate, this.endDate));
    }
}
