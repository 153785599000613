import { Image, ImageType, Label, Layout, Panel } from "@mcleod/components";
import { Api, AuthType, HorizontalAlignment, LogManager, UUID, getForwardDestination } from "@mcleod/core";
import { TenantCompanySettings } from "./settings/TenantCompanySettings";

const log = LogManager.getLogger("common.Login");

export class DecoratedUnauthPage extends Layout {
    forwardDest: string;
    protected panelMain: Panel;
    protected panelHeader: Panel;
    protected panelLogo: Panel;
    protected panelContentLogin: Panel;
    protected panelPage: Panel;
    // protected buttonSignIn: Button;
    protected labelWelcome: Label;
    protected labelInstructions: Label;
    // protected textSelectedTenant: Textbox;
    // private buttonNavigateToTenant: Button;
    // private idp_token: string;

    constructor(props?: any) {
        super({
            auth: AuthType.UNAUTH,
            title: "McLeod Sofware Portal",
            fillRow: true,
            needsServerLayout: false,
            ...props
        });
        if (localStorage.getItem("deviceId") == null)
            localStorage.setItem("deviceId", UUID.randomUUID());
        this.forwardDest = getForwardDestination();
        this.createElements();
        const settings = TenantCompanySettings.get();
        const backgroundColor = "backgroundSubtle";
        this.panelContentLogin.backgroundColor = backgroundColor;
        if (settings != null) {
            if (settings.header_bg_color != null)
                this.panelHeader.backgroundColor = "success.darker";
            if (settings.header_logo != null)
                this.panelLogo.add(new Image({ imageType: ImageType.IMG, imageBytes: settings.header_logo, height: 60, width: "auto", maxWidth: 380 }))
        }
        this.setBackgroundImage();
    }

    private createElements() {
        this.panelPage = new Panel({ align: HorizontalAlignment.CENTER, fillHeight: true, fillRow: true });
        this.panelMain = new Panel({ borderShadow: true, borderWidth: 1, width: 450, padding: 0 });
        this.panelHeader = new Panel({ backgroundColor: "subtle.darkest", fillRow: true, padding: 24 });
        this.panelLogo = new Panel();
        this.panelHeader.add(this.panelLogo);
        this.panelContentLogin = new Panel({ fillRow: true, paddingTop: 24, paddingRight: 50, paddingBottom: 32, paddingLeft: 50 });
        this.labelWelcome = new Label({ fontSize: "xxxlarge", marginBottom: 24, text: "Welcome" });
        this.labelInstructions = new Label({ marginBottom: 24, text: "Please sign in to continue" });
        this.panelPage.add(this.panelMain);
        this.panelMain.add(this.panelHeader, this.panelContentLogin);
        this.panelContentLogin.add(this.labelWelcome, this.labelInstructions);
        this.add(this.panelPage);
        // HACK!  (because I don't have a way or know how to affect the parent row height)
        this.panelMain._element.parentElement.style.height = "100%";
    }

    setBackgroundImage() {
        const bytes = localStorage.getItem("loginBackground");
        if (bytes != null)
            this.panelPage.backgroundImageBytes = bytes;
        Api.search("lib/login-background", { cached_length: bytes?.length || -1 }).then(response => {
            const data = response?.login_background;
            if (data != null) {
                this.panelPage.backgroundImageBytes = data;
                localStorage.setItem("loginBackground", data);
            }
        });
    }

    getRouterProps() {
        return { padding: 0 };
    }

    isDisallowedForward(path: string) {
        return (path === "common/SessionExpired" ||
            path === "common/SignInNeeded" ||
            path === "common/Login" ||
            path === "common/NoLicensesAvailable");
    }
}
