import { AuthTypeUtil, ModelRow, UserType } from "..";
import { Color } from "../Color";
import { DateUtil } from "../Date";
import { AbstractSettings, AbstractSettingsDef } from "./AbstractSettings";
import { SettingsManager } from "./SettingsManager";

export interface UserSettingsDef extends AbstractSettingsDef {
    //auth
    company_id: string;
    company_name: string;
    company_scac: string;
    date_format: string;
    default_table_configs: any[];
    google_map_layer: string;
    google_map_type: string;
    responsibility_codes: string[];
    sel_text_on_focus: boolean;
    table_even_row_color: string;
    table_odd_row_color: string;
    time_format: string;
    user_company_name: string;
    user_extension: string;
    user_id: string;
    user_login_id: string;
    user_name: string;
    user_phone: string;
    user_settings_page_access: boolean;
    user_type: string;
    user_save_screen_sizes: boolean;
}

export class UserSettings extends AbstractSettings {
    constructor() {
        super("user_settings");
    }

    public static getSingleton(): UserSettings {
        return SettingsManager.getSingleton("user_settings") as UserSettings;
    }

    public static get(): UserSettingsDef {
        return UserSettings.getSingleton().values as UserSettingsDef;
    }

    public override get values(): UserSettingsDef {
        return super.values as UserSettingsDef;
    }

    protected getValuesInternal(): UserSettingsDef {
        return this._values as UserSettingsDef;
    }

    _doAfterSet(newValues: UserSettingsDef, isAuthSet?: boolean) {
        super._doAfterSet(newValues, isAuthSet);
        if (isAuthSet === true) {
            if (newValues != null)
                DateUtil.setUserSettings(newValues.date_format, newValues.time_format);
        }
    }

    public static getUserAuthType(): string {
        return AuthTypeUtil.fromUserType(UserSettings.getUserType());
    }

    public static getUserId(): string {
        return UserSettings.get()?.user_id;
    }

    public static getUserName(): string {
        return UserSettings.get()?.user_name;
    }

    public static getUserType(): string {
        return UserSettings.get()?.user_type;
    }

    public getTimeFormat(): string {
        return this.values?.time_format;
    }

    public getCompanyId(): string {
        return this.values?.company_id;
    }

    public getCompanyName(): string {
        return this.values?.company_name;
    }

    public getCompanySCAC(): string {
        return this.values?.company_scac;
    }

    public getDefaultTableConfigRow(layoutPath: string, tableId: string): any {
        const defaultConfigs = this.getValuesInternal()?.default_table_configs;
        if (defaultConfigs != null) {
            for (const config of defaultConfigs) {
                if (config.layout_path === layoutPath && config.table_id === tableId)
                    return new ModelRow("common/table-config", false, { ...config });
            }
        }
        return null;
    }

    public updateDefaultTableConfig(row: ModelRow) {
        const layoutPath = row.get("layout_path", "");
        const tableId = row.get("table_id", "");
        const indexToRemove = this.indexOfDefaultTableConfig(layoutPath, tableId);
        const internalValues = this.getValuesInternal();
        if (indexToRemove !== -1)
            internalValues?.default_table_configs?.splice(indexToRemove, 1, { ...row.data });
        else {
            if (internalValues?.default_table_configs == null)
                internalValues.default_table_configs = [];
            internalValues?.default_table_configs?.push({ ...row.data });
        }
    }

    public removeDefaultTableConfig(row: ModelRow) {
        const layoutPath = row.get("layout_path", "");
        const tableId = row.get("table_id", "");
        const indexToRemove = this.indexOfDefaultTableConfig(layoutPath, tableId);
        if (indexToRemove !== -1)
            this.getValuesInternal()?.default_table_configs?.splice(indexToRemove, 1);
    }

    private indexOfDefaultTableConfig(layoutPath: string, tableId: string): number {
        const cachedConfigs = this.getValuesInternal()?.default_table_configs;
        if (cachedConfigs != null) {
            for (let x = 0; x < cachedConfigs.length; x++) {
                const cachedConfig = cachedConfigs[x];
                if (cachedConfig.layout_path === layoutPath && cachedConfig.table_id === tableId)
                    return x;
            }
        }
        return -1;
    }

    public updateEvenOddRowColors(evenColor: Color, oddColor: Color) {
        const values = this.getValuesInternal();
        if (values != null) {
            values.table_even_row_color = evenColor;
            values.table_odd_row_color = oddColor;
        }
    }

    public static isUserTypeCustomer(): boolean {
        return UserSettings.getUserType() === UserType.CUSTOMER;
    }

    public static isUserTypeCarrier(): boolean {
        return UserSettings.getUserType() === UserType.CARRIER;
    }

    public static isUserTypeLocation(): boolean {
        return UserSettings.getUserType() === UserType.LOCATION;
    }

    public static isUserTypeFactoringCompany(): boolean {
        return UserSettings.getUserType() === UserType.FACTORING_COMPANY;
    }

    public static isUserTypeDriver(): boolean {
        return UserSettings.getUserType() === UserType.DRIVER;
    }

    public static isUserTypeLME(): boolean {
        return UserSettings.getUserType() === UserType.LME;
    }

    public static isUserTypeDataFusion(): boolean {
        return UserSettings.getUserType() === UserType.DATAFUSION;
    }

    public static isUserTypeMcLeod(): boolean {
        return UserSettings.getUserType() === UserType.MCLEOD;
    }
}
