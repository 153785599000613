import { Table } from "../components/table/Table";
import { TableRow } from "../components/table/TableRow";
import { DomEvent } from "./DomEvent";
import { Event, EventListener } from "./Event";

export interface TableSelectionListener extends EventListener {
    (event: TableSelectionEvent): void;
}

export class TableSelectionEvent extends Event {
    private _oldIndexes: number[];
    private _newIndexes: number[];
    private _oldRows: TableRow[];
    private _newRows: TableRow[];

    constructor(table: Table, oldIndexes: number[], oldRows: TableRow[], newIndexes: number[], newRows: TableRow[], domEvent?: DomEvent) {
        super(table, domEvent);
        this._oldIndexes = oldIndexes;
        this._oldRows = oldRows;
        this._newIndexes = newIndexes;
        this._newRows = newRows;
    }

    get oldIndex(): number | undefined {
        if (this._oldIndexes != null && this._oldIndexes.length > 0)
            return this._oldIndexes[0];
        else
            return undefined;
    }

    get newIndex(): number | undefined {
        if (this._newIndexes != null && this._newIndexes.length > 0)
            return this._newIndexes[0];
        else
            return undefined;
    }

    get oldRow(): TableRow | undefined {
        if (this._oldRows != null && this._oldRows.length > 0)
            return this._oldRows[0];
        else
            return undefined;
    }

    get newRow(): TableRow | undefined {
        if (this._newRows != null && this._newRows.length > 0)
            return this._newRows[0];
        else
            return undefined;
    }

    get oldIndexes(): number[] | undefined {
        return this._oldIndexes;
    }

    get newIndexes(): number[] | undefined {
        return this._newIndexes;
    }

    get oldRows(): TableRow[] | undefined {
        return this._oldRows;
    }

    get newRows(): TableRow[] | undefined {
        return this._newRows;
    }
}
