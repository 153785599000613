import { Component, Container, Table, Textbox } from "../..";

export class ScrollToError {

    public scrollToFirstProblemField(component: Component): boolean {
        let done = false;
        if (component instanceof Container) {
            component.components.forEach(element => {
                if (!done)
                    done = this.scrollToFirstProblemField(element);
            })
        }
        else if (component instanceof Table) {
            component.rows.forEach(row => {
                row.cells.forEach(cell => {
                    cell.components.forEach(element => {
                        if (!done)
                            done = this.scrollToFirstProblemField(element);
                    });
                });
            });
        }
        else if (component instanceof Textbox &&
            (component as Textbox).validationWarning != null) {
            component.scrollIntoView();
            return true;
        }
        return done;
    }

}
