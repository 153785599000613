import { Layout } from "../components/layout/Layout";
import { Event, EventListener } from "./Event";

export interface LayoutLoadErrorListener extends EventListener {
    (event: LayoutLoadErrorEvent): void;
}

export class LayoutLoadErrorEvent extends Event {
    private _errorMessage: string;
    constructor(target: Layout, errorMessage: string) {
        super(target);
        this._errorMessage = errorMessage;
    }

    public override get target(): Layout {
        return super.target as Layout;
    }

    public get errorMessage(): string {
        return `Unable to access ${this.target.title || this.target.layoutName}.\n\n${this._errorMessage}`;
    }
}
