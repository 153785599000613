import { DesignableObject, DesignerInterface, Label, Panel, PanelProps } from "@mcleod/components";
import { PropertiesTable } from "./PropertiesTable";
import { ArrayUtil, HorizontalAlignment } from "@mcleod/core";

export class PropertiesTablePanel extends Panel {
    tableProps: PropertiesTable;
    labelProperties: Label;
    panelPropsHeader: Panel;
    private designer: DesignerInterface;

    constructor(designer: DesignerInterface, props?: Partial<PanelProps>) {
        super({ fillRow: true, height: "100%", padding: 0, borderLeftWidth: 1, borderLeftColor: "strokeSecondary", ...props});
        this.designer = designer;
        this.tableProps = new PropertiesTable(designer, { id: "tableProps" });
        this.panelPropsHeader = new Panel({ backgroundColor: "primary", padding: 0, align: HorizontalAlignment.LEFT, fillRow: true });
        this.labelProperties = new Label({ id: "labelProperties", text: "Properties", color: "primary.reverse", align: HorizontalAlignment.CENTER, fillRow: true, fillHeight: true, rowBreak: false })
        this.panelPropsHeader.add(this.labelProperties)
        this.add(this.panelPropsHeader);
        this.add(this.tableProps);
    }

    syncHeaderText(selectedObject: DesignableObject) {
        if (this.designer.selectedComponents.length === 0) {
            this.labelProperties.text = "Properties";
        } else {
            const uniqueTypes = new Set<string>();

            for (const component of this.designer.selectedComponents) {
                uniqueTypes.add(component.serializationName);
            }

            const selectedComponents = this.designer.selectedComponents;
            if (uniqueTypes.size === 1) {
                const properName = selectedComponents[0].properName;
                if (selectedComponents.length === 1) {
                    const componentId = ArrayUtil.getFirstElement(selectedComponents).id;
                    this.labelProperties.text = `${properName} Properties${componentId ? ` [${componentId}]` : ''}`;
                } else {
                    this.labelProperties.text = `${properName} Properties [${selectedComponents.length} selected]`;
                }
            } else {
                this.labelProperties.text = `Component Properties [${selectedComponents.length} selected]`;
            }
        }
    }
}
