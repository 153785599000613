import {
    Component, ComponentDeserializer, Container, DesignableObject, DesignerInterface, Layout, TabCloseEvent, Table,
    TableColumn, Tabset
} from "@mcleod/components";
import { DesignerToolAcceptor } from "@mcleod/components/src/base/DesignerInterface";
import { DeserializeProps } from "@mcleod/components/src/serializer/ComponentDeserializer";
import { ArrayUtil, DOMUtil, DynamicLoader } from "@mcleod/core";
import { PropertiesTablePanel } from "../ui/PropertiesTablePanel";
import { UIDesignerUtil } from "../ui/UIDesignerUtil";
import { DesignerProps, ResourceDesigner } from "./ResourceDesigner";
import { ResourceDesignerTab } from "./ResourceDesignerTab";

export abstract class AbstractLayoutDesigner<T extends ResourceDesignerTab> extends ResourceDesigner<T> implements DesignerInterface {
    tabset: Tabset;

    constructor(props: DesignerProps) {
        super(props);
        this.tabset = new Tabset({ fillRow: true, fillHeight: true, rowBreak: false, allowStyleChange: false });
        this.tabset.addAfterTabSelectionListener(event => this.tabChanged(event.newSelection));
        this.tabset.addBeforeTabCloseListener(event => this.tabClosed(event));
    }

    abstract addEventHandlerFunction(component: DesignableObject, eventPropName: string): void;

    abstract selectComponent(component: DesignableObject, addToSelection?: boolean): void;

    abstract redisplayProp(propName: string, propValue: any): void;

    abstract addDesignerContainerProperties(component: Component, minWidth: number, minHeight: number, width?: number, allowDropAcceptor?: DesignerToolAcceptor): void;

    abstract componentDropped(comp: Component): void;

    abstract applyChangeToSelectedComponents(data: any, newValue: any): void;

    abstract displayDataSourceTools(): void;

    abstract get allowsDrop(): boolean;

    abstract addDragAndDropListeners(component: Component): void;

    abstract filterProps(props: any, selectedComponent: Component): void;

    abstract disablePropertyEditors(prop: any, editorComponents: Component[], selectedComponent: Component): void;

    abstract addTableColumn(table: Table): TableColumn;

    abstract canModifyProp(propName: string, component: Component): boolean;

    abstract setPropsForDeserialization(componentType: string, props: any): any;

    addTool(_serializationName: string, _displayName: string, _container: Container): Component {
        // Return null here just to satisfy compilation of Permissions Manager, where this method isn't needed/used
        return null;
    }

    abstract doAfterPropChanged(component: Component, propName: string, oldValue: any, newValue: any, redisplayProp?: boolean): void;

    abstract doBeforePropChanged(component: Component, propName: string): void;

    tabChanged(tab: ResourceDesignerTab) {
        if (this.finishedLoading === true) {
            if (tab == null)
                this.selectComponent(null);
            else
                this.localStorageManager?.storeLastSelectedTab(tab);
        }
    }

    isActiveLayoutComponent(comp: DesignableObject): boolean {
        return this.isLayoutComponent(comp, this.getActiveLayout())
    }

    isLayoutComponent(comp: DesignableObject, layout: Layout): boolean {
        const layoutElement = layout?._element;
        const compElement = comp instanceof Component ? comp._element : null;
        return layoutElement != null && compElement != null && DOMUtil.isOrContains(layoutElement, compElement);
    }

    get selectedLayoutComponents(): Component[] {
        return this.selectedComponents.filter(comp => this.isActiveLayoutComponent(comp));
    }

    get firstSelectedLayoutComponent(): Component {
        return this.selectedLayoutComponents?.[0];
    }

    getActiveLayout(): Layout {
        return this.getActiveTab()?.designerPanel as Layout;
    }

    handleComponentSelection(object: DesignableObject, add: boolean = false, propsPanel?: PropertiesTablePanel) {
        let component: DesignableObject;
        if (object?.alternateDesignerTarget == null) {
            component = object;
            if (component != null && !this.canSelectObject(component))
                return;
        }
        else
            component = object.alternateDesignerTarget;
        if (component instanceof Component) {
            let parent = component.parent;
            while (parent != null) {
                if (parent instanceof Layout && parent.isNested) {
                    component = parent;
                    break;
                }
                parent = parent.parent;
            }
        }
        UIDesignerUtil.designerHandleComponentSelection(this.selectedComponents, component as Component, add, propsPanel);
    }


    private canSelectObject(object: DesignableObject): boolean {
        return object == this.getActiveLayout() || object?.selectableInDesigner === true;
    }


    private tabClosed(event: TabCloseEvent) {
        this.localStorageManager?.removeTabFromLastOpen(event.tab as ResourceDesignerTab);
    }

    protected async deserializeLayout(tab: ResourceDesignerTab, deserializeProps?: Partial<DeserializeProps>) {
        if (!(tab.designerPanel instanceof Layout) || tab.definition == null)
            return null;
        const tabLayout = tab.designerPanel as Layout;
        const def = tab.definition;
        const props: DeserializeProps = {owner: this.createOwnerForPath(tab.path), def, designer: this, ...deserializeProps };
        const deserializedLayout = await new ComponentDeserializer(props).deserializeSingleComponent() as Layout;
        for (const prop in tabLayout.getPropertyDefinitions()) {
            if (def[prop] !== undefined && prop !== "mainDataSource") // Layout._deserializeSpecialProps handles mainDataSource
                tabLayout[prop] = def[prop];
        }
        tabLayout.mainDataSource = deserializedLayout.mainDataSource; // this is embarassing
        for (const component of deserializedLayout.components)
            tabLayout.add(component);

        if (this.getActiveLayout() === tabLayout)
            this.selectComponent(tabLayout);
    }

    protected createOwnerForPath(path: string): any {
        let owner = DynamicLoader.getClassForPath(path);
        if (owner != null)
            owner = new owner({ designer: this });
        return owner;
    }
}
