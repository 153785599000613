export const ThemeCommonFont = {
    font: {
        sizes: {
            xxxsmall: 6,
            xxsmall: 8,
            xsmall: 10,
            small: 12,
            medium: 14,
            large: 16,
            xlarge: 18,
            xxlarge: 20,
            xxxlarge: 24,
            skyline: 32,
            billboard: 48
        },
        defaultSize: "medium",
        boldWeight: 500
    }
}
