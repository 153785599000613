import { DataDisplayEvent, Label, TableSelectionEvent } from "@mcleod/components";
import { Collection, DateUtil, DurationFormat, HorizontalAlignment, ModelRow } from "@mcleod/core";
import { AutogenLayoutThreadDumpAnalyzer } from "./autogen/AutogenLayoutThreadDumpAnalyzer";
import { ThreadDump, ThreadDumpParser, ThreadInfo, ThreadState } from "./ThreadDumpParser";
import { SourceLink } from "./SourceLink";


export class ThreadDumpAnalyzer extends AutogenLayoutThreadDumpAnalyzer {
    labelNoThreadDump: Label;
    _contents: string;
    dump: ThreadDump;
    cpuTimeFormat = new DurationFormat()
        .dayFormat({ suffix: "d", separator: " " })
        .hourFormat({ suffix: "h", separator: " " })
        .minuteFormat({ suffix: "m", separator: " " })
        .secondFormat({ suffix: "s", separator: " " })
        .milliFormat({ suffix: "ms", separator: " " });

    onLoad() {
        this.labelNoThreadDump = new Label({ 
            text: "Paste a thread dump above to analyze it", 
            color: "default.lightest", 
            fillRow: true, 
            align: HorizontalAlignment.CENTER,
            marginTop: 24 });
        this.add(this.labelNoThreadDump);
        this.labelTableDetail.element.style.whiteSpace = "pre";
        if (this._contents != null)
            this.analyze(this._contents);
    }

    get contents(): string {
        return this._contents;
    }

    set contents(contents: string) {
        this._contents = contents;
        if (this.labelNoThreadDump != null)
            this.analyze(contents);
    }

    private analyze(threadDump: string) {
        this.labelSelectThread.visible = true;
        this.labelTableDetail.visible = false;
        const dump = new ThreadDumpParser().parse(threadDump);
        this.dump = dump;
        if (dump.threads.length === 0) {
            this.remove(this.panelAnalysis);
            if (!this.contains(this.labelNoThreadDump))
                this.add(this.labelNoThreadDump);
            this.labelNoThreadDump.text = "The input does not appear to be a thread dump";
            return;
        }
        this.remove(this.labelNoThreadDump);

        this.sortThreads(dump.threads);

        const groupedThreads: Collection<ThreadInfo[]> = this.groupThreads(dump.threads);
        this.tableSummary.clearRows();
        for (const threadsForGroup of Object.values(groupedThreads)) {
            const thread = threadsForGroup[0];
            const name = threadsForGroup.length === 1 ? thread.name : thread.commonName;
            let totalCpu = 0;
            threadsForGroup.forEach(t => totalCpu += t.cpuMillis);

            const row = new ModelRow(null, false, { 
                state: thread.state,
                name: name,
                count: threadsForGroup.length,
                cpu: totalCpu,
                threads: threadsForGroup
            });
            this.tableSummary.addRow(row, null, { display: true, addToData: false });
        }
        this.panelDumpInfo.visible = dump.dumpTime != null;
        if (dump.dumpTime != null) {
            this.labelDumpTakenDate.text = DateUtil.formatDateTime(dump.dumpTime);
            this.labelHeading2.visible = dump.processStart != null;
            this.labelProcessStartDate.visible = dump.processStart != null;
            if (dump.processStart != null)
                this.labelProcessStartDate.text = DateUtil.formatDateTime(dump.processStart);
            this.labelHeading3.visible = dump.uptimeSeconds > 0;
            this.labelUptime.visible = dump.uptimeSeconds > 0;
            if (dump.uptimeSeconds > 0)
                this.labelUptime.text = this.cpuTimeFormat.format(dump.uptimeSeconds * 1000);
        }
        this.labelThreadInfo.text = "Threads: " + dump.threads.length + " (Active: " + dump.activeThreads + ", Idle: " + dump.idleThreads + ", Blocked: " + dump.blockedThreads + ")";
         
        if (!this.contains(this.panelAnalysis))
            this.add(this.panelAnalysis);

    }

    /** Groups the threads by their full stack so that threads with identical stacks appear together */
    private groupThreads(threads: ThreadInfo[]): Collection<ThreadInfo[]> {
        const result: Collection<ThreadInfo[]> = {};
        for (const thread of threads) { 
            const array = result[thread.key];
            if (array == null) {
                result[thread.key] = [thread];
            } else {
                array.push(thread);
            }
        }
        return result;
    }


    private labelStateOnDataDisplay(event: DataDisplayEvent) {
        const cell = event.target as Label;
        if (event.rowData.get("state") === ThreadState.IDLE) {
            cell.setProps({ text: "Idle", backgroundColor: "default.lightest",  });
        } else if (event.rowData.get("state") === ThreadState.IDLE_AWT) {
            cell.setProps({ text: "Idle AWT", backgroundColor: "default.lightest" });
        } else if (event.rowData.get("state") === ThreadState.IDLE_TMS) {
            cell.setProps({ text: "Idle TMS", backgroundColor: "default.lighter", color: "default.reverse" });
        } else if (event.rowData.get("state") === ThreadState.SPECIAL) {
            cell.setProps({ text: "Special", backgroundColor: "default.lighter", color: "default.reverse" });
        } else if (event.rowData.get("state") === ThreadState.ACTIVE) {
            cell.setProps({ text: "Active", backgroundColor: "primary", color: "primary.reverse" });
        } else if (event.rowData.get("state") === ThreadState.BLOCKED) {
            cell.setProps({ text: "Blocked", backgroundColor: "caution", color: "caution.reverse" });
        } else if (event.rowData.get("state") === ThreadState.BLOCKER) {
            cell.setProps({ text: "Blocker", backgroundColor: "warning", color: "warning.reverse" });
        } else if (event.rowData.get("state") === ThreadState.DEADLOCK) {
            cell.setProps({ text: "Deadlock", backgroundColor: "error", color: "error.reverse" });
        }
        
    }

    private tableSummaryOnSelect(event: TableSelectionEvent) {
        const sel = this.tableSummary.selectedRow?.data?.get("threads") as ThreadInfo[];
        if (sel == null) {
            this.labelSelectThread.visible = true;
            this.labelTableDetail.visible = false;
            return;
        }
        let text = "";
        let count = 0;
        for (const thread of sel) {
            for (const line of thread.lines) {
                // only highlight the elements in the first four threads to improve performance of add elements to the DOM
                // this will probably go away once we have a nice markdown component (that has built-in intersection observers)
                if (count < 4 && line.trim().startsWith("at com.tms.")) {
                    const linkedLine = SourceLink.replaceSourceLink(line, this.dump.commitId);
                    text += "<span style=\"background:#ffff005c\">" + linkedLine + "</span>\n";
                }
                else
                    text += line + "\n";
            }
            text += "\n\n";
            count++;
        }
        this.labelSelectThread.visible = false;
        this.labelTableDetail.visible = true;
        this.labelTableDetail.element.style.display = "block";
        this.labelTableDetail.element.innerHTML = text;

    }

    private sortThreads(threads: ThreadInfo[]) {
        threads.sort((a, b) => {
            if (a.state != b.state)
                return b.state - a.state;
            return a.name.localeCompare(b.name);
        });
    }

    private labelThreadCountOnDataDisplay(event: DataDisplayEvent) {
        const cell = event.target as Label;
        const count = event.rowData.get("count");
        if (count > 1) {
            cell.setProps({ fontBold: true, color: "primary" });
        }        
    }

    private labelThreadCPUOnDataDisplay(event: DataDisplayEvent) {
        const cell = event.target as Label;
        const cpu = event.rowData.get("cpu");
        // cell.text = this.cpuTimeFormat.format(cpu.toFixed(2));
        if (cpu < 10) 
            cell.text = cpu.toFixed(2) + "ms"
        else if (cpu < 10000)
            cell.text = cpu.toFixed(0) + "ms"
        else if (cpu < 60000)
            cell.text = (cpu / 1000).toFixed(0) + "s " + ((cpu % 1000) / 100).toFixed(0) + "ms";
        else if (cpu < 3600000)
            cell.text = (cpu / 60000).toFixed(0) + "m " + ((cpu % 60000) / 1000).toFixed(0) + "s";
        else
            cell.text = (cpu / 3600000).toFixed(0) + "h " + ((cpu % 3600000) / 60000).toFixed(0) + "m " + ((cpu % 60000) / 1000).toFixed(0) + "s";
    }
}