import { AutogenModelTenantUser, AutogenRowTenantUser } from "./autogen/AutogenModelTenantUser";

export class ModelTenantUser extends AutogenModelTenantUser<RowTenantUser> {
    constructor() {
        super(RowTenantUser);
    }
}

export class RowTenantUser extends AutogenRowTenantUser {
}
