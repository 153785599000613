import { Alignment, ArrayUtil, Collection, Keys, LogManager, StringUtil, VerticalAlignment, getThemeColor } from "@mcleod/core";
import {
    Component, ComponentProps, DataSource, DesignableObject, DesignerInterface, KeyEvent, Panel, Table, TableCell,
    TableCellProps, TableRow, Textbox
} from "@mcleod/components";
import { ComponentPropDefinition, ComponentPropDefinitions } from "@mcleod/components/src/base/ComponentProps";
import { DesignerDataSource } from "./DesignerDataSource";
import { McLeodTailor } from "./custom/McLeodTailor";
import { PropertyValuePanel } from "./PropertyValuePanel";

const log = LogManager.getLogger("designer/ui/PropertiesTable");

const captionPropsDefault: Partial<TableCellProps> = { fontBold: false, color: null };
const captionPropsNotDefault: Partial<TableCellProps> = { fontBold: true, color: "primary" };
const captionPropsModified: Partial<TableCellProps> = { fontBold: true, color: "#ad12b0" };

export class PropertiesTable extends Table {
    designer: DesignerInterface;
    expandedSections: Collection<boolean>;
    _selectedTableRow: TableRow;
    _selectedComponents: Component[];
    clearing: boolean;
    private _lastScrollTop: number;

    get selectedComponents(): Component[] {
        return this._selectedComponents;
    }

    set selectedComponents(value: Component[]) {
        this._selectedComponents = value;
    }

    constructor(designer: DesignerInterface, props?) {
        super({
            id: "tableProps",
            fillRow: true,
            fillHeight: true,
            padding: 0,
            headerVisible: false,
            rowAlign: VerticalAlignment.CENTER,
            expanderAlignment: Alignment.LEFT,
            rowSpacing: 0,
            columns: [
                {
                    headingDef: { caption: "Property", paddingLeft: 4 },
                    cell: {
                        field: "prop_name",
                        height: 34,
                        paddingLeft: 4,
                        tooltip: (cell: TableCell) => {
                            if (cell.row.data.prop != null)
                                return cell.row.data.prop.description;
                        }
                    }
                },
                {
                    headingDef: { caption: "Value", paddingLeft: 8 },
                    cell: (row: TableRow) => {
                        return this.createPropValueCell(row, "default");
                    }
                }
            ],
            printableToggleEnabled: false,
            ...props
        });
        this.addRowCreateListener(rowCreationEvent => this.onPropRowCreate(rowCreationEvent.getTableRow()));
        this.addRowDisplayListener(rowDisplayEvent => this.onPropRowDisplay(rowDisplayEvent.getTableRow()));
        this.addRowExpandListener(rowExpansionEvent => this._trackExpandedSections(rowExpansionEvent.getTableRow(), true));
        this.addRowCollapseListener(rowExpansionEvent => this._trackExpandedSections(rowExpansionEvent.getTableRow(), false));
        this.designer = designer;
        this.expandedSections = {};
    }

    /**
     * This is a hack to include the spacer cell in the header row, so that the header row captions line up with the
     * cells below.  The normal logic that would handle this in Table.ts doesn't work due to the fact that this table
     * uses the expandComponent in TableRow, and it's not worth rewriting all that at the moment since this is the only
     * affected table.
     */
    private syncExpanderHeaderSpacer(singleRowExpandable: boolean) {
        this.createExpanderHeaderSpacer();
        const expanderHeaderSpacer = this["_expanderHeaderSpacer"];
        const headingRowElement = this["headingRow"]._element;
        const headingRowContainsSpacer = headingRowElement.contains(expanderHeaderSpacer);
        if (singleRowExpandable === true || this.expandableRowsPresent() === true) {
            if (headingRowContainsSpacer === false) {
                headingRowElement.insertBefore(expanderHeaderSpacer, headingRowElement.children[0]);
            }
        }
        else if (headingRowContainsSpacer === true)
            headingRowElement.removeChild(expanderHeaderSpacer);
    }

    private expandableRowsPresent(): boolean {
        for (const row of this.allRows) {
            if (this.rowIsExpandable(row) === true)
                return true;
        }
        return false;
    }

    private createPropValueCell(row: TableRow, filterCategory: string): Panel {
        return new PropertyValuePanel(row.data.prop_name, row.data.prop, filterCategory, this, row)
    }

    set selectedTableRow(row: TableRow) {
        if (this._selectedTableRow != row) {
            if (this._selectedTableRow) {
                this._selectedTableRow.selected = false;
                this.setRowEditorProps(this._selectedTableRow, { borderWidth: 0 });
            }
            this._selectedTableRow = row;
            if (this._selectedTableRow) {
                this._selectedTableRow.selected = true;
                this.setRowEditorProps(this._selectedTableRow, { borderWidth: 0 });
            }
        }
    }

    get selectedTableRow(): TableRow {
        return this._selectedTableRow;
    }

    private rowIsExpandable(row: TableRow): boolean {
        return row.data.subRows != null;
    }

    private onPropRowCreate(row) {
        this.cleanRowData(row.data);
        row.expandable = this.rowIsExpandable(row);
        row.expandComponent = (table, row, expandComponent, expanding) => { return this.onPropRowExpand(table, row, expandComponent, expanding) };
        this.syncExpanderHeaderSpacer(row.expandable);
    }

    cleanRowData(data: any) {
        if (data?.value instanceof DataSource) {
            data.value = data.value.id;
        }
    }

    private _trackExpandedSections(row: TableRow, isExpanding: boolean) {
        if (!this.clearing)
            this.expandedSections[row.data.prop_name] = isExpanding;
    }

    private onPropRowExpand(table: Table, row: TableRow, expandComponent: Component, expanding: boolean) {
        if (!expanding)
            return;
        const result = new Table({
            id: "tableProps",
            printableToggleEnabled: false,
            fillRow: true, padding: 0, headerVisible: false, columnHeadingsVisible: false,
            rowAlign: VerticalAlignment.CENTER, rowSpacing: 0, virtualized: false,
            columns: [
                {
                    headingDef: { caption: "Property" },
                    cell: {
                        field: "prop_display",
                        height: 34,
                        paddingLeft: 4,
                        tooltip: (cell: TableCell) => {
                            if (cell.row.data.prop != null)
                                return cell.row.data.prop.description;
                        }
                    }
                },
                {
                    headingDef: { caption: "Value" },
                    cell: (row: TableRow) => {
                        return this.createPropValueCell(row, null);
                    }
                }
            ]
        });
        result.addRowDisplayListener(rowDisplayEvent => this.onPropRowDisplay(rowDisplayEvent.getTableRow()));
        for (const subRow of row.data.subRows) {
            const subTableRow = result.addRow(subRow, null, { display: true }).row as any;
            subTableRow.parentRow = row;
        }
        return result;
    }

    private categorizeProps(keys: string[], props) {
        const result = {} as any;
        for (let i = 0; i < keys.length; i++) {
            const prop = props[keys[i]];
            if (prop != null) {
                if (prop.category == null)
                    prop.category = "default";
                if (result[prop.category] == null)
                    result[prop.category] = {};
                result[prop.category][keys[i]] = prop;
            }
        }
        return result;
    }

    /**
     * Returns the property names that all the selected components have in common.
     * @param {*} props
     * @returns
     */
    private getCommonProperties(props: ComponentPropDefinitions) {
        const keys = Object.keys(props);
        for (let compIndex = 1; compIndex < this.selectedComponents.length; compIndex++)
            for (let i = keys.length - 1; i >= 0; i--)
                if (!(keys[i] in this.getSelectedComponent(compIndex)))
                    keys.splice(i, 1);
        return keys;
    }

    /**
     * Returns the values that all the selected components have in common.
     * @param {*} keys
     * @returns
     */
    private getCommonValues(keys: string[]) {
        const values = {};
        for (const key of keys) {
            values[key] = this.getSelectedComponent(0)[key];
            for (let compIndex = 1; compIndex < this.selectedComponents.length; compIndex++)
                for (const inner of keys)
                    if (values[inner] !== this.getSelectedComponent(compIndex)[inner])
                        values[inner] = null;
        }
        return values;
    }

    displayProperties(selectedComponents) {

        if (ArrayUtil.equals(this.selectedComponents, selectedComponents) && this.rows.length > 0) {
            this.applyLastScrollTop();
            return;
        }

        this.selectedComponents = [...selectedComponents];
        try {
            this.clearing = true; // set this flag to short circuit the expansion listener
            this.clearRows();
        } finally {
            this.clearing = false;
        }
        if (this.selectedComponents.length === 0 || this.designer.getActiveTab() == null)
            return;
        const firstSel = this.getSelectedComponent(0);
        if (firstSel.getPropertyDefinitions == null) {
            log.info("The selected component", firstSel, "doesn't have a getPropertyDefinitions() function.");
            throw new Error("The selected component doesn't have a getPropertyDefinitions() function.  Check the console for more detailed info.");
        }
        const props = { ...firstSel.getPropertyDefinitions() };
        if (this.selectedComponents.length > 1 && props.defaultDataValue) {
            delete props.defaultDataValue;
        }
        const keys = this.getCommonProperties(props);
        const values = this.getCommonValues(keys);
        if (this.selectedComponents.length == 1 && firstSel !== this.designer.getActiveTab().designerPanel)
            keys.unshift("id");
        this.designer.filterProps(props, selectedComponents[0]);
        const cats = this.categorizeProps(keys, props);
        if (cats.default != null && Object.keys(cats.default).length > 0) {
            for (const key of Object.keys(cats.default).sort(this.idFirstSort)) {
                this.addRow({ prop_name: key, value: values[key], prop: cats.default[key] }, null, { display: true });
            }
        }
        for (const key of Object.keys(cats).sort()) {
            if (key !== "default") {
                const subRows = [];
                const subProps = cats[key];
                for (const subKey of Object.keys(subProps))
                    subRows.push({ prop_name: subKey, prop_display: props[subKey].displayName || subKey, value: values[subKey], prop: props[subKey] });
                this.addRow({ prop_name: key, value: values[key], subRows: subRows }, null, { display: true });
            }
        }
        for (const sectionName of Object.keys(this.expandedSections)) {
            if (this.expandedSections[sectionName] !== true)
                continue;
            this.setRowsExpanded(true, row => row.data["prop_name"] === sectionName);
        }
    }

    redisplayProp(propName: string, value: any): Promise<TableRow> {
        return this.getTableRow(propName).then(selRow => {
            const editorComponent = this.getPropertyValuePanel(selRow)?.primaryComponent;
            if (selRow != null)
                selRow.data.value = value;
            if (editorComponent)
                editorComponent?.displayData(selRow.data, null, null);
            return selRow;
        });
    }

    getSelectedComponent(index: number): DesignableObject {
        let result = this.selectedComponents[index] as DesignableObject;
        if (result instanceof DesignerDataSource)
            result = result.designerDataSource;
        return result;
    }

    private idFirstSort(item1: string, item2: string): number {
        if (item1 === "id")
            return -1;
        else if (item2 === "id")
            return 1;
        else
            return item1.localeCompare(item2)
    }

    private onPropRowDisplay(row: TableRow) {
        const subRows = row.data.subRows;
        if (subRows != null) {
            row._element.style.backgroundColor = getThemeColor("table.headingRowBackgroundColor");
            row._element.style.color = getThemeColor("table.headingRowColor");
        }
        if (row.data.prop != null)
            this.setPropertyCaptionProps(row);
        else
            this.setExpandRowCaptionProps(row);
    }

    async syncRowCaptionStyle(propName: string, tableRow?: TableRow) {
        if (tableRow == null)
            tableRow = await this.getTableRow(propName);
        const parentRow = (tableRow as any)?.parentRow ?? this.getParentRow(propName);
        if (tableRow?.populatedDOM)
            this.setPropertyCaptionProps(tableRow);
        if (parentRow?.populatedDOM)
            this.setExpandRowCaptionProps(parentRow);
    }

    private getParentRow(propName: string): TableRow {
        return this.rows.find(row => row.data.subRows?.find(sub => sub.prop_name === propName));
    }

    private setPropertyCaptionProps(row: TableRow) {
        let props = captionPropsDefault;
        if (!this.isDefaultValue(row.data.value, row.data.prop))
            props = captionPropsNotDefault;
        if (this.hasBaseVersionProp(row.data.prop_name))
            props = captionPropsModified;
        row.cells.find(cell => cell.caption)?.setProps(props);
    }

    private setExpandRowCaptionProps(row: TableRow) {
        let props: Partial<TableCellProps> = captionPropsDefault;
        const subRows = row.data.subRows;
        if (subRows?.some(sub => !this.isDefaultValue(sub.value, sub.prop)))
            props = captionPropsNotDefault;
        if (subRows?.some(sub => this.hasBaseVersionProp(sub.prop_name)))
            props = captionPropsModified;
        row.cells.find(cell => cell.caption)?.setProps(props);
    }

    private hasBaseVersionProp(propName: string) {
        return this.designer instanceof McLeodTailor
            && this.getSelectedComponent(0).baseVersionProps != null
            && propName in this.getSelectedComponent(0).baseVersionProps;
    }

    private isDefaultValue(value, prop: ComponentPropDefinition) {
        if (value === undefined || StringUtil.isEmptyString(value))
            return true;
        const defaultValue = this.selectedComponents[0].getPropertyDefaultValue(prop);
        if (value === false && defaultValue === undefined)
            return true;
        return value === defaultValue;
    }

    async applyKeyToProp(key: string, prop: string) {
        const updRow = await this.getTableRow(prop);
        const propertyValuePanel = this.getPropertyValuePanel(updRow);
        if (updRow != null && propertyValuePanel != null)
            propertyValuePanel.processKey(key);
        else
            log.info("Null editor - trying to find this property", this, prop);
    }

    private async getTableRow(propName: string, rows: TableRow[] = this.rows): Promise<TableRow> {
        if (this.selectedTableRow?.data?.prop_name === propName)
            return this.selectedTableRow;

        for (const row of rows) {
            await row.populateDOM();
            const expandComp = await row.getExpansionComponentLoaded();
            if (expandComp instanceof Table)
                return this.getTableRow(propName, expandComp.rows);
            else if (row.data.prop_name === propName)
                return row;
        }
    }

    getRowData(propName: string): any {
        if (this.selectedTableRow?.data?.prop_name === propName)
            return this.selectedTableRow.data;

        for (const row of this.rows) {
            if (row.data.prop_name === propName)
                return row.data;
            if (row.data.subRows != null)
                for (const sub of row.data.subRows)
                    if (sub.prop_name === propName)
                        return sub;
        }
    }

    private setRowEditorProps(row: TableRow, props: Partial<ComponentProps>) {
        const comp = this.getRowEditorComponent(row);
        if (comp)
            comp.setProps({ ...props });
    }

    private getRowEditorTextbox(row: TableRow): Textbox {
        const editComp = this.getRowEditorComponent(row);
        if (editComp instanceof Textbox)
            return editComp;
    }

    private getRowEditorComponent(row: TableRow): Component {
        return this.getPropertyValuePanel(row)?.primaryComponent;
    }

    private getPropertyValuePanel(row: TableRow): PropertyValuePanel {
        if (row != null) {
            for (const cell of row.cells) {
                for (const component of cell.components) {
                    if (component instanceof PropertyValuePanel)
                        return component;
                }
            }
        }
        return null;
    }

    keyUp(event: KeyEvent) {
        const editor = this.getRowEditorTextbox(this.selectedTableRow);
        if (event.key === Keys.ESCAPE && editor != null) {
            this.designer.applyChangeToSelectedComponents(this.selectedTableRow.data, this.selectedTableRow.data.value);
            if (this.selectedTableRow.data.value != null)
                editor.text = this.selectedTableRow.data.value;
            else
                editor.clear();
        }
    }

    applyLastScrollTop() {
        this._tbody.scrollTop = this._lastScrollTop ?? 0;
    }

    override bodyScrolled(event: any) {
        super.bodyScrolled(event);
        if (this._lastScrollTop !== this._tbody.scrollTop) {
            this._lastScrollTop = this._tbody.scrollTop;
        }
    }

    openSpecialtyEditor(propName: string) {
        this.getTableRow(propName).then(row => {
            this.getPropertyValuePanel(row)?.specialtyEditorButton?.clicked()
        });
    }
}
