import { Collection, StringUtil } from "@mcleod/core";

export enum ThreadState {
    IDLE = 0,
    IDLE_AWT = 1,
    IDLE_TMS = 2,
    SPECIAL = 3,
    ACTIVE = 4,
    BLOCKED = 5,
    BLOCKER = 6,
    DEADLOCK = 7
}

export class ThreadDump {
    processStart: Date;
    dumpTime: Date;
    commitId: string;
    uptimeSeconds: number;
    idleThreads: number;
    activeThreads: number;
    blockedThreads: number;
    threads: ThreadInfo[] = [];
}

export class ThreadInfo {
    lines: string[] = [];
    id: string;
    name: string;
    commonName: string;
    key: string;
    state: ThreadState = ThreadState.IDLE;
    cpuMillis: number = 0;
    elapsedSeconds: number = 0;
    locks: Collection<string> = {};
    waitingOn: Collection<string> = {};
}

export enum ThreadDumpType {
    INVALID = 0,
    JAVA = 1,
    CLIENT_ADMIN = 2
}

export class ThreadDumpParser {
    // If one of these strings is found in the first line of a thread, it is considered idle
    private idleThreadPatterns = [
        "java.lang.Thread.sleep",
        "java.lang.Object.wait",
        "WToolkit.eventLoop",
        "WEPoll.wait",
        "Reference.waitForReferencePendingList",
        "ServerSocketChannelImpl.accept0",
        "WindowsSelectorImpl$SubSelector.poll0",
        "PlainSocketImpl.accept0",
        "WindowsNativeDispatcher.GetQueuedCompletionStatus",
        "Net.accept"
    ];

    public static getThreadDumpType(content: string): ThreadDumpType {
        if (content.startsWith("Thread dump at ")) {
            return ThreadDumpType.CLIENT_ADMIN;
        } else if (content.substring(0, 100).indexOf("Full thread dump ") >= 0) {
            return ThreadDumpType.JAVA;
        } else {
            return ThreadDumpType.INVALID;
        }
    }

    public parse(text: string): ThreadDump {
        const clientAdminFormat = text.startsWith("Thread dump");
        const result = clientAdminFormat ? this.parseClientAdminFormat(text) : this.parseJavaFormat(text);
        this.calculateThreadFields(result.threads);
        this.calculateLocks(result.threads);
        this.calculateDumpFields(result);
        return result;
    }

    private calculateLocks(threads: ThreadInfo[]) {
        for (const thread of threads) {
            if (thread.state === ThreadState.BLOCKED) {
                const lastWait = Object.keys(thread.waitingOn)[0];
                const blockerThread = this.findThreadLocking(threads, lastWait);
                if (blockerThread != null) {
                    for (const key of Object.keys(blockerThread.waitingOn)) {
                        if (thread.locks[key] != null) {
                            thread.state = ThreadState.DEADLOCK;
                            blockerThread.state = ThreadState.DEADLOCK;
                            break;
                        }
                    }
                    if (blockerThread.state === ThreadState.DEADLOCK)
                        break;
                    else
                        blockerThread.state = ThreadState.BLOCKER;
                }
            }
        }
    }

    private findThreadLocking(threads: ThreadInfo[], address: string): ThreadInfo {
        for (const thread of threads) {
            if (thread.locks[address] != null)
                return thread;
        }
        return null;
    }

    private calculateDumpFields(dump: ThreadDump) {
        dump.idleThreads = 0;
        dump.activeThreads = 0;
        dump.blockedThreads = 0;
        let maxElapsedSeconds = 0;
        for (const thread of dump.threads) {
            if (thread.state === ThreadState.IDLE || thread.state === ThreadState.IDLE_AWT || thread.state === ThreadState.IDLE_TMS || thread.state === ThreadState.SPECIAL)
                dump.idleThreads++;
            else if (thread.state === ThreadState.BLOCKED || thread.state === ThreadState.DEADLOCK)
                dump.blockedThreads++;
            else
                dump.activeThreads++;
            if (thread.elapsedSeconds > maxElapsedSeconds)
                maxElapsedSeconds = thread.elapsedSeconds;
        }
        if (dump.dumpTime !== null && maxElapsedSeconds > 0) {
            dump.uptimeSeconds = maxElapsedSeconds;
            dump.processStart = new Date(dump.dumpTime.getTime() - dump.uptimeSeconds * 1000);
        }
    }

    private calculateThreadFields(result: ThreadInfo[]) {
        for (const info of result) {
            info.name = StringUtil.stringBetween(info.lines[0], "\"", "\"");
            info.commonName = info.name.replace(/[0-9]+/g, "*");
            const cpuString = StringUtil.stringBetween(info.lines[0], "cpu=", "ms", null);
            if (cpuString != null)
                info.cpuMillis = parseFloat(cpuString);
            const elapsedString = StringUtil.stringBetween(info.lines[0], "elapsed=", "s", null);
            if (elapsedString != null) {
                info.elapsedSeconds = parseFloat(elapsedString);
            }

            if (info.lines[info.lines.length - 1].trim().length == 0) {
                info.lines.pop();
            }
            let firstLine = null;
            let key = info.commonName + ":";
            const blocked = info.lines.length > 1 && info.lines[1].indexOf("State: BLOCKED") >= 0;
            for (let i = 2; i < info.lines.length; i++) {
                const line = info.lines[i].trim();
                if (line.startsWith("at ")) {
                    key += line + "\n";
                    if (firstLine == null)
                        firstLine = info.lines[i];
                }
            }
            info.state = this.getThreadState(firstLine, info, blocked);
            if (info.state === ThreadState.ACTIVE && this.doesAnyLineStartWith(info.lines, "at com.tms.common.lib.ThreadDump.threadDump"))
                info.state = ThreadState.SPECIAL;
            else if (info.state === ThreadState.IDLE && this.doesAnyLineStartWith(info.lines, "at com.tms.common.lib.TimeoutRunner"))
                info.state = ThreadState.ACTIVE;
            else if (info.state === ThreadState.IDLE && this.doesAnyLineStartWith(info.lines, "at com.tms"))
                info.state = ThreadState.IDLE_TMS;
            info.key = info.state + " - " + key;
        }
    }

    private doesAnyLineStartWith(lines: string[], prefix: string): boolean {
        for (const line of lines) {
            if (line.trim().startsWith(prefix))
                return true;
        }
        return false;
    }

    private getThreadState(line: string, thread: ThreadInfo, blocked: boolean): ThreadState {
        if (blocked)
            return ThreadState.BLOCKED;
        for (const pattern of this.idleThreadPatterns) {
            if (line?.indexOf(pattern) >= 0)
                return ThreadState.IDLE;
        }
        if (line == null || thread?.lines.length <= 4 || (thread?.name?.indexOf("CompilerThread") >= 0 && thread.lines?.length <= 5))
            return ThreadState.IDLE;
        else if (line?.indexOf("Unsafe.park") > 0)
            return thread?.name?.startsWith("AWT-EventQueue") ? ThreadState.IDLE_AWT : ThreadState.IDLE;
        else
            return ThreadState.ACTIVE;
    }

    private parseJavaFormat(text: string): ThreadDump {
        const result = new ThreadDump();
        const lines = text.split("\n");
        let currentThread;
        result.dumpTime = new Date(lines[0]);
        for (const rawLine of lines) {
            const line = StringUtil.rtrim(rawLine);
            if (line.startsWith("\"")) {
                currentThread = new ThreadInfo();
                result.threads.push(currentThread);
            }
            if (line.startsWith("Commit ID: ")) {
                result.commitId = line.substring("Commit ID: ".length);
            }
            currentThread?.lines.push(line);
            this.handleLocks(currentThread, line);
            if (line.startsWith("JNI global ref")) { // don't continue because deadlocks will apear below this line
                break;
            }
        }
        return result;
    }

    private parseClientAdminFormat(text: string): ThreadDump {
        const result = new ThreadDump();
        const lines = text.split("\n");
        let currentThread;
        for (let line of lines) {
            line = StringUtil.rtrim(line);
            if (line.startsWith("Thread dump at ")) {
                result.dumpTime = new Date(line.substring("Thread dump at ".length));
            }
            if (line.startsWith("Commit ID: ")) {
                result.commitId = line.substring("Commit ID: ".length);
            }
            if (line.startsWith("Thread: ")) {
                currentThread = new ThreadInfo();
                result.threads.push(currentThread);
            }
            this.handleLocks(currentThread, line);
            currentThread?.lines.push(line);
        }
        return result;
    }

    private handleLocks(thread: ThreadInfo, line: string) {
        if (line.trim().startsWith("- locked <")) {
            const address = StringUtil.stringBetween(line, "<", ">", null);
            thread.locks[address] = StringUtil.stringBetween(line, "(a ", ")", null);
        }
        if (line.trim().startsWith("- waiting to lock <")) {
            const address = StringUtil.stringBetween(line, "<", ">", null);
            thread.waitingOn[address] = StringUtil.stringBetween(line, "(a ", ")", null);
        }
    }
}
