import { ObjectUtil } from "@mcleod/core";
import { ComponentPropDefinition, ComponentPropDefinitions, ComponentPropDefinitionUtil } from "../../base/ComponentProps";
import { PropType } from "../../base/PropType";
import { LabelPropDefinitions, LabelProps } from "../label/LabelProps";
import { ContextMenuLabel } from "./ContextMenuLabel";

export interface ContextMenuLabelProps extends LabelProps {
    separatorAfter: boolean;
}

export type ContextMenuItemsProvider =
    ContextMenuLabel[]
    | Partial<ContextMenuLabelProps>[]
    | (() => Partial<ContextMenuLabelProps>[]);


let itemPropDefs: ComponentPropDefinitions;
export class ContextMenuLabelPropDefinitions {
    private static validCategories = ["Image", "Enabled / visible", "Appearance"];
    private static validProps = [ "id", "text", "isCustom"];
    public static getDefinitions(): ComponentPropDefinitions {
        if (itemPropDefs == null) {
            itemPropDefs = {};
            const props = ObjectUtil.deepCopy(LabelPropDefinitions.getDefinitions()) as ComponentPropDefinitions;
            Object.entries(props).forEach(([key, value]) => {
                const resolvedProp = ContextMenuLabelPropDefinitions.resolveComponentPropDefinition(key, value);
                if (resolvedProp) {
                    itemPropDefs[key] = resolvedProp;
                }
            });

            itemPropDefs = {
                ...itemPropDefs,
                separatorAfter: {
                    type: PropType.bool,
                    defaultValue: false,
                    description: "When true, a separator will be added after this item."
                },
                requiredLayout: {
                    type: PropType.layout,
                    category: "Enabled / visible",
                    description: "The layout required for this component. If the user doesn't have access to the layout, the component will not be visible."},

            }
            itemPropDefs.text.affectsProps = ["id"];

            ComponentPropDefinitionUtil.populateComponentPropNames(itemPropDefs);
        }
        return itemPropDefs;
    }

    static overrideDefaultValue(resolvedProp: ComponentPropDefinition) {
        switch(resolvedProp.name) {
            case "padding": return 12;
            case "margin": return 0;
            case "fillRow": return true;
            case "allowSelect": return false;
        }
    }

    static resolveComponentPropDefinition(key: string, value: ComponentPropDefinition): ComponentPropDefinition {

        const overrideDefault = ContextMenuLabelPropDefinitions.overrideDefaultValue(value);
        if (overrideDefault != null) {
            value.visibleInDesigner = false;
            value.defaultValue = overrideDefault;
            return value;
        }

        if (key === "onClick") {
            delete value.category;
            return value;
        }

        const isValidProp = ContextMenuLabelPropDefinitions.validProps.includes(key);
        const isValidCategory = value.category && ContextMenuLabelPropDefinitions.validCategories.includes(value.category);

        if (isValidProp || isValidCategory) {
            return value;
        }

        return null;
    }
}
