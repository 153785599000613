export interface ModifierKeys {
    altKey?: boolean;
    ctrlKey?: boolean;
    shiftKey?: boolean;
}

export class KeyModifiers {
    public static hasModifiers(event: ModifierKeys, modifiers: ModifierKeys): boolean {
        return KeyModifiers.modifiersEqual(event, modifiers);
    }

    private static modifiersEqual(mod1: ModifierKeys, mod2: ModifierKeys): boolean {
        return (mod1?.altKey || false) === (mod2.altKey || false)
            && (mod1.ctrlKey || false) === (mod2.ctrlKey || false)
            && (mod1.shiftKey || false) === (mod2.shiftKey || false);
    }
}
