import { Component, DesignerInterface, PanelProps, serializeContextMenuItems, SlideoutDecoratorProps, ValidationResult } from "@mcleod/components";
import { ArrayUtil, StringUtil } from "@mcleod/core";
import { AbstractUIDesigner } from "./AbstractUIDesigner";
import { ContextMenuDesigner } from "./ContextMenuDesigner";
import { SlideoutPropertyEditor } from "./SlideoutPropertyEditor";

export class ContextMenuEditor extends SlideoutPropertyEditor {
    private enclosingDesigner: AbstractUIDesigner;
    private sourceComponent: Component;
    private contextMenuDesigner: ContextMenuDesigner;

    constructor(enclosingDesigner: DesignerInterface, props?: Partial<PanelProps>) {
        super({ fillRow: true, fillHeight: true, borderWidth: 1, borderColor: "subtle", ...props, });

        if (enclosingDesigner instanceof AbstractUIDesigner) {
            this.enclosingDesigner = enclosingDesigner;
            this.sourceComponent = enclosingDesigner.firstSelected;
            this.contextMenuDesigner = new ContextMenuDesigner(this.sourceComponent, enclosingDesigner);
        } else {
            this.errorMsg = "Invalid Designer Type";
        }
    }

    override getSlideoutProps(): Partial<SlideoutDecoratorProps> {
        return {
            title: this.getSlideoutTitle(),
            minWidth: 900,
            width: "40%",
            layout: this.contextMenuDesigner
        };
    }

    private getSlideoutTitle(): string {
        let suffix = null;
        if (this.sourceComponent == this.enclosingDesigner.getActiveTab().designerPanel) {
            const tabTitle = StringUtil.removeTrailingString(this.enclosingDesigner.getActiveTab().caption, "*");

            suffix = `- ${tabTitle}`;
        } else {
            suffix = `- ${this.sourceComponent.id}`
        }
        return `Context Menu Editor ${suffix}`;
    }

    public override getResultValue() {
        const items = this.contextMenuDesigner.getListItems();
        if (ArrayUtil.isEmptyArray(items)) {
            return null;
        }
        const result = serializeContextMenuItems(this.sourceComponent, this.contextMenuDesigner.getListItems());
        return JSON.parse(result);
    }

    public doAfterResultApplied(): void {
        this.contextMenuDesigner.addEventHandlersAfterSave();
    }

    override validate(checkRequired: boolean, showErrors: boolean = true): ValidationResult[] {
        const result = super.validate(checkRequired, showErrors) ?? [];
        result.push(...this.contextMenuDesigner.validate(checkRequired, showErrors));
        return result;
    }

}
