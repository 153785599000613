export enum UserType {
    CUSTOMER = "C",
    CARRIER = "R",
    LOCATION = "L",
    FACTORING_COMPANY = "5",
    DRIVER = "D",
    LME = "U",
    MCLEOD = "MCLEOD",
    DATAFUSION = "E"
}
