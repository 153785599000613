import {
    ChangeEvent, ClickEvent, Component, DialogProps, KeyEvent, Panel, PanelProps, Textbox, ValidationResult
} from "@mcleod/components";
import { Alignment, HorizontalAlignment, Keys } from "@mcleod/core";
import { SpecialtyPropertyEditor } from "./SpecialtyPropertyEditor";

interface SingleItem {
    name: string;
    displayPanel: Panel;
}

export abstract class AbstractDisplayPanelSelector extends SpecialtyPropertyEditor {
    displayedItems: Panel;
    search: Textbox;
    allItems: SingleItem[] = [];
    _selectedItem: SingleItem;
    private itemsPerRow: number;

    protected abstract getValidationMessage(): string;
    protected abstract discoverItemNames(): string[];
    protected abstract getSingleItemPanelComponents(name: string): Component[];

    constructor(props?: Partial<PanelProps>) {
        super(props);
        this.scrollY = false;
        const panelProps = {fillRow: true, fillHeight: true, padding:0, margin: 0};
        this.setProps({...panelProps});
        this.displayedItems = new Panel({...panelProps, scrollY: true});
        this.createSearch();
        this.loadItems();
        this.add(this.search, this.displayedItems);
    }

    override getDialogProps(): Partial<DialogProps> {
        return {
            ...super.getDialogProps(),
            scrollY: false,
            width: "70%",
            height: "70%",
            cursor: null,
            resizable: true,
            localStorageKey: "displayPanelSelector",
            minHeight: 350
        };
    }

    private createSearch() {
        this.search = new Textbox({
            width: 260,
            captionVisible: false,
            marginLeft: 8,
            marginBottom: 12,
            fillRow: true,
            placeholder: "Search",
        });
        this.search.addKeyDownListener((event: KeyEvent) => this.searchKeyDown(event));
        this.search.addChangeListener((event: ChangeEvent) => this.displayItems());
    }

    private loadItems() {
        const itemNames = this.discoverItemNames();
        for (const name of itemNames) {
            this.allItems.push(this.createSingleItem(name));
        }
        this.displayItems();
    }

    protected createSingleItemPanel(): Panel {
        return new Panel({
            rowBreak: false,
            width: 170,
            margin: 8,
            borderRadius: 8,
            align: HorizontalAlignment.CENTER
        });
    }

    private createSingleItem(name: string): SingleItem {
        const singleItemPanel = this.createSingleItemPanel();
        singleItemPanel.add(...this.getSingleItemPanelComponents(name));
        const singleItem: SingleItem = { name: name, displayPanel: singleItemPanel };
        singleItemPanel.addClickListener((event: ClickEvent) => this.selectedItem = singleItem);
        return singleItem;
    }

    protected displayItems() {
        this.displayedItems.removeAll();
        this.selectedItem = null;
        this.itemsPerRow = null;
        for (const singleItem of this.allItems) {
            if (this.search.isEmpty() === true ||
                singleItem.name.toLowerCase().includes(this.search.text.toLowerCase())) {
                if (this.selectedItem == null)
                    this.selectedItem = singleItem;
                this.displayedItems.add(singleItem.displayPanel);
            }
        }
    }

    protected getItemsPerRow(): number {
        return this.itemsPerRow ??= this.calculateItemsPerRow();
    }

    // Calculate the number of items per row by getting the top position of the first component.
    // Iterate through the remaining components until a component with a different top position is found.
    private calculateItemsPerRow(): number {
        const firstItemTop = this.displayedItems.components[0]?.bounds?.top;
        if (firstItemTop == null)
            return 0;

        const threshold = Math.max(this.getSelectedBorderWidth() ?? 0, this.getUnselectedBorderWidth() ?? 0);
        let itemsPerRow = 1;

        for (let i = 1; i < this.displayedItems.components.length; i++) {
            const itemTop = this.displayedItems.components[i].bounds?.top;
            if (Math.abs(itemTop - firstItemTop) > threshold) {
                break;
            }
            itemsPerRow++;
        }

        return itemsPerRow;
    }

    private searchKeyDown(event: KeyEvent) {
        const key = event.key;
        const itemsPerRow = this.getItemsPerRow();
        if (this.selectedItem == null)
            return;
        let curr = this.displayedItems.indexOf(this.selectedItem.displayPanel);
        if (key === Keys.ARROW_UP)
            curr -= itemsPerRow;
        else if (key === Keys.ARROW_DOWN)
            curr += itemsPerRow;
        else if (key === Keys.ARROW_LEFT)
            curr--;
        else if (key === Keys.ARROW_RIGHT)
            curr++;
        if (curr < 0)
            curr = 0;
        if (curr >= this.displayedItems.getComponentCount())
            curr = this.displayedItems.getComponentCount() - 1;
        this.selectedItem = this.findSingleItem(this.displayedItems.getComponent(curr) as Panel);
        if (this.selectedItem != null)
            this.selectedItem.displayPanel.scrollIntoView();
    }

    private findSingleItem(displayPanel: Panel): SingleItem {
        for (const singleItem of this.allItems) {
            if (displayPanel === singleItem.displayPanel)
                return singleItem;
        }
        return null;
    }

    private get selectedItem(): SingleItem {
        return this._selectedItem;
    }

    private set selectedItem(value: SingleItem) {
        if (this._selectedItem != null) {
            this._selectedItem.displayPanel.backgroundColor = this.getUnselectedBackgroundColor();
            this._selectedItem.displayPanel.color = this.getUnselectedColor();
            this._selectedItem.displayPanel.borderColor = this.getUnselectedBorderColor();
            this._selectedItem.displayPanel.borderWidth = this.getUnselectedBorderWidth();
        }
        this._selectedItem = value;
        if (value != null) {
            value.displayPanel.backgroundColor = this.getSelectedBackgroundColor();
            value.displayPanel.color = this.getSelectedColor();
            value.displayPanel.borderColor = this.getSelectedBorderColor();
            value.displayPanel.borderWidth = this.getSelectedBorderWidth();
        }
    }

    protected getUnselectedColor(): string {
        return "unset";
    }

    protected getUnselectedBackgroundColor(): string {
        return "unset";
    }

    protected getUnselectedBorderColor(): string {
        return "unset";
    }

    protected getUnselectedBorderWidth(): number {
        return null;
    }

    protected getSelectedColor(): string {
        return "unset";
    }

    protected getSelectedBackgroundColor(): string {
        return "unset";
    }

    protected getSelectedBorderColor(): string {
        return "primary.light";
    }

    protected getSelectedBorderWidth(): number {
        return 1;
    }

    override validate(): ValidationResult[] {
        if (this.selectedItem == null) {
            const message = this.getValidationMessage();
            this.displayedItems.showTooltip(message, { position: Alignment.RIGHT, shaking: true });
            return [{ component: this, isValid: false, validationMessage: message }];
        }
        return [];
    }

    getResultValue(): string {
        return this.selectedItem.name;
    }

    focus() {
        this.search.focus();
    }
}
