import { DataSource, Label, Layout, Panel, SaveButton, Switch, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutSendUserInvitation extends Layout {
    buttonSendInvite: SaveButton;
    labelLabel1: Label;
    panelButtons: Panel;
    panelContent: Panel;
    panelHeader: Panel;
    sourceSendUserInvitation: DataSource;
    switchSendFrom: Switch;
    textboxEmailAddress: Textbox;
    textboxUserId: Textbox;
}
