import { Alignment } from "@mcleod/core";
import { Component } from "../../base/Component";
import { ButtonProps } from "../../components/button/ButtonProps";
import { Panel } from "../../components/panel/Panel";
import { PanelProps } from "../../components/panel/PanelProps";
import { PanelOkCancel } from "../PanelOkCancel";
import { Decorator } from "./Decorator";
import { DecoratorProps } from "./DecoratorProps";

export interface OkCancelDecoratorProps extends DecoratorProps {
    components: Component[];
    okButtonProps: Partial<ButtonProps>;
    cancelButtonProps: Partial<ButtonProps>;
    buttonPanelProps: Partial<PanelProps>;
    contentPanelProps: Partial<PanelProps>;
    onOk: () => void,
    onCancel: () => void;
}

export class OkCancelDecorator extends Decorator {
    contentPanel: Panel;
    buttonPanel: PanelOkCancel;

    constructor(props: Partial<OkCancelDecoratorProps>) {
        super(props);
        if (props == null)
            props = {};
        delete props.components;
    }

    override setupPanels(props: Partial<OkCancelDecoratorProps>) {
        this.contentPanel = new Panel({ id: "ok-cancel-content", fillRow: true, padding: 0, fillHeight: true, ...props.contentPanelProps });
        this.buttonPanel = new PanelOkCancel({
            fillRow: true,
            okButtonProps: props.okButtonProps,
            cancelButtonProps: props.cancelButtonProps,
            onOk: props.onOk,
            onCancel: props.onCancel,
            align: Alignment.RIGHT,
            ...props.buttonPanelProps
        });
        this.add(this.contentPanel);
        this.add(this.buttonPanel);
    }

    override addLayout() {
        //do nothing since we aren't adding the provided layout to the panel shown
    }

    override layoutLoaded() {
        for (const component of this.layout.components)
            this.contentPanel.add(component);
        // if (layout != null && layout.title != null) {
        //   const labelTitle = new Label({ text: layout.title, fontBold: true, fontSize: "xlarge", color: "subtle.darker", marginBottom: 16});
        //   this.add(labelTitle)
        // }
    }
}
