import { SettingsManager, UserSettings, UserSettingsDef } from "@mcleod/core";

export interface TenantUserSettingsDef extends UserSettingsDef {
    user_name: string;
    allow_switching_tenants: boolean;
}

export class TenantUserSettings extends UserSettings {
    public static getSingleton(): TenantUserSettings {
        return SettingsManager.getSingleton("user_settings") as TenantUserSettings;
    }

    public static get(): TenantUserSettingsDef {
        return TenantUserSettings.getSingleton().values as TenantUserSettingsDef;
    }

    public override get values(): TenantUserSettingsDef {
        return super.values as TenantUserSettingsDef;
    }

    protected getValuesInternal(): TenantUserSettingsDef {
        return this._values as TenantUserSettingsDef;
    }
}
