import { Button, Label, Layout, Panel } from "@mcleod/components";

export abstract class AutogenLayoutNewDevice extends Layout {
    buttonApproveThisDevice: Button;
    labelApprovalDescription: Label;
    labelNewDevice: Label;
    panel1: Panel;
    panel2: Panel;
    panel3: Panel;
}
