import { Button, ButtonVariant, Label, Panel, Splitter, SplitterOrientation, TreeNode } from "@mcleod/components";
import { Instance } from "./AnalyzerTypes";
import { InstanceTree } from "./InstanceTree";
import { HeapClassTypes } from "./HeapClassTypes";
import { HorizontalAlignment } from "@mcleod/core";
import { AnalyzerUtil } from "./AnalyzerUtil";
import { CommonDialogs } from "@mcleod/common";

export class InstanceNode extends TreeNode {
    private instance: Instance; 
    private instanceTree: InstanceTree;

    constructor(instance: Instance | any, fieldName: string, tree: InstanceTree) {
        super({ paddingRight: 0, indent: 20 });
        this.tree = tree.tree;
        this.instance = instance;
        this.instanceTree = tree;
        const nodePanel = new Panel({ fillRow: true, padding: 0 });
        this.setLabelProps({ fillRow: false });
        const labelInstance = new Label({ rowBreak: false });
        if (fieldName != null) {
            const labelFieldName = new Label({ text: fieldName, rowBreak: false, fontBold: true });
            nodePanel.add(labelFieldName);
        }
        const detailButton = new Button({ 
            tooltip: "Show detail for this instance",
            variant: ButtonVariant.round, 
            imageName: "listSearch", 
            padding: 4, 
            margin: 0, 
            rowBreak: false, 
            marginLeft: 12 
        });
        detailButton.addClickListener(() => this.showInstanceDetail());
        detailButton.hiddenUnlessMouseOver = nodePanel;
        const spacer = new Panel({ fillRow: true, rowBreak: false });
        nodePanel.add(labelInstance, detailButton, spacer);
        const briefInfo = HeapClassTypes.getInstanceBriefText(this.instanceTree.instanceMap, instance);
        labelInstance.text = briefInfo.text;
        labelInstance.tooltip = briefInfo.tooltip;
        const labelProps = { width: 100, rowBreak: false, align: HorizontalAlignment.RIGHT };
        const labelShallowSize = new Label({ text: AnalyzerUtil.getSizeString(instance?.ss), ...labelProps });
        const labelRetainedSize = new Label({ text: AnalyzerUtil.getSizeString(instance?.s), ...labelProps });
        const labelPercentage = new Label({ text: AnalyzerUtil.getSizePercentage(instance?.s, tree.totalHeapSize), ...labelProps });
        nodePanel.add(labelShallowSize, labelRetainedSize, labelPercentage);
        if (HeapClassTypes.hasClassHandler(instance?.c)) {
            labelInstance.color = "primary.dark";
            detailButton.tooltip += " (this instance has a custom handler to show contents in a more readable format)";
        }
        this.expansionCallback = () => this.addChildren();
        this.rightComponent = nodePanel;
    }

    private getFieldNameInReferer(instanceAddress: string, refererAddress: string): string {
        const referer = this.instanceTree.instanceMap[refererAddress];
        if (referer == null)
            return null;
        for (const fieldName in referer.f) {
            if (referer.f[fieldName] === instanceAddress)
                return fieldName;
        }
        return null;
    }

    public addChildren(): TreeNode[] {
        Error.stackTraceLimit = 100;
        const result = [];
        const instance = this.instance;
        if (instance == null)
            return result;
        if (this.instanceTree.showReferences) {
            if (instance.r != null) {
                const fieldName = this.getFieldNameInReferer(instance.a, instance.r)
                const node = this.instanceTree.createInstanceNode(fieldName, this.instance.r);
                if (node.instance != null)
                    result.push(node);
            }
        }
        else if (instance.f != null) {
            for (const fieldName in instance.f) {
                const value = instance.f[fieldName];
                if (this.instanceTree.showPrimitives || (value?.startsWith("0x") && !this.includedInHierarchy(value))) {
                    const node = this.instanceTree.createInstanceNode(fieldName, value);
                    if (node != null) {
                        result.push(node);
                    }
                }
            }
        }
        this.removeAllChildren();
        // if (result.length === 1)
        //     result[0].expanded = true;
        if (result.length > 0)
            this.addChild(...result);
    }

    private includedInHierarchy(instanceAddress: string): boolean {
        let node:InstanceNode = this;
        while (node instanceof InstanceNode) {
            if (node.instance?.a === instanceAddress)
                return true;
            node = node.parent as InstanceNode;
        }
        return false;
    }

    private showInstanceDetail() {
        const splitMain = new Splitter({ fillHeight: true, fillRow: true, orientation: SplitterOrientation.vertical});
        const panelRefs = new Panel({ fillHeight: true, fillRow: true });
        const labelRefs = new Label({ text: "Reference Path", fillRow: true, fontBold: true, color: "primary.reverse", backgroundColor: "primary", fontSize: "xlarge" });
        const treeRefs = new InstanceTree({ fillHeight: true, fillRow: true, showReferences: true });
        treeRefs.panelHeader.visible = false;
        treeRefs.instanceMap = this.instanceTree.instanceMap;
        treeRefs.totalHeapSize = this.instanceTree.totalHeapSize;
        treeRefs.instances = [this.instance];
        panelRefs.add(labelRefs, treeRefs);

        const text = HeapClassTypes.getInstanceText(this.instanceTree.instanceMap, this.instance?.a);
        const panelMain = new Panel({ fillHeight: true, fillRow: true });
        const splitDetail = new Splitter({ fillHeight: true, fillRow: true, orientation: SplitterOrientation.vertical});
        const treeDetail = new InstanceTree({ fillHeight: true, fillRow: true, showPrimitives: true });
        const labelDetail = new Label({ text: text});
        const numNewLines = text.split("\n").length;
        if (numNewLines < 5)
            splitDetail.position = "85%";
        treeDetail.instanceMap = this.instanceTree.instanceMap;
        treeDetail.totalHeapSize = this.instanceTree.totalHeapSize;
        treeDetail.instances = [this.instance];
        splitDetail.firstComponent = treeDetail;
        splitDetail.secondComponent = labelDetail;

        splitMain.firstComponent = splitDetail;
        splitMain.secondComponent = panelRefs;
        splitMain.position = "75%";
        panelMain.add(splitMain);
        const briefInfo = HeapClassTypes.getInstanceBriefText(this.instanceTree.instanceMap, this.instance);
        CommonDialogs.showDialog(panelMain, { title: "Instance Detail for " + briefInfo.text, width: "90%", height: "80%", resizable: true });
    }
}
