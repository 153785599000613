import { Button, ResourceFileProps } from "@mcleod/components";
import { LocalStorageManager } from "../common/LocalStorageManager";
import { AbstractModelDesigner } from "./AbstractModelDesigner";
import { ModelDesignerTab } from "./ModelDesignerTab";

export class ModelDesigner extends AbstractModelDesigner {
    constructor(props?) {
        super({...props, localStorageManager: new LocalStorageManager("designer.model.open", "designer.model.selected")});
    }

    get tabsetTools(): Button[] {
        return [
            this.buttonViewCode,
            this.buttonViewJson,
            this.buttonTestModel,
            this.buttonOpen,
            this.buttonSave,
            this.buttonNew
        ];
    }

    override doAfterActiveTabSave(tab: ModelDesignerTab, response: any) {
        const oldLastOpenEntry = tab.descriptor;
        super.doAfterActiveTabSave(tab, response);
        this.localStorageManager.updateTabInLastOpen(oldLastOpenEntry, tab.descriptor);
    }

    tabChanged(tab: ModelDesignerTab) {
        this.localStorageManager.storeLastSelectedTab(tab);
    }

    beforeTabClosed(tab: ModelDesignerTab) {
        this.localStorageManager.removeTabFromLastOpen(tab);
    }

    override async openTab( props: ResourceFileProps, selectTab: boolean = true): Promise<ModelDesignerTab> {
        const tab = await super.openTab(props, selectTab);
        if (tab != null)
            this.localStorageManager.addLastOpenTab(tab);
        return tab;
    }
}

