import {
    ArrayUtil, Collection, DOMUtil, HorizontalAlignment, ModelRow, Navigation, StringUtil, VerticalAlignment,
    WindowTitle
} from "@mcleod/core";
import { Button } from "../button/Button";
import { ButtonVariant } from "../button/ButtonVariant";
import { Component } from "../../base/Component";
import { ComponentDeserializer, DeserializeProps } from "../../serializer/ComponentDeserializer";
import { ComponentPropDefinition, ComponentPropDefinitions } from "../../base/ComponentProps";
import { ComponentTypes } from "../../base/ComponentTypes";
import { CrudDecoratorCloseEvent, CrudDecoratorCloseListener } from "../../events/CrudDecoratorCloseEvent";
import { DataHeaderCloseAction } from "./DataHeaderCloseAction";
import { DataHeaderPropDefinitions, DataHeaderProps } from "./DataHeaderProps";
import { DataSource, DataSourceMode } from "../../databinding/DataSource";
import { DataSourceModeChangeEvent, DataSourceModeChangeListener } from "../../events/DataSourceModeChangeEvent";
import { DesignableObjectLogManager } from "../../logging/DesignableObjectLogManager";
import { DesignerInterface } from "../../base/DesignerInterface";
import { HorizontalSpacer } from "../../page/HorizontalSpacer";
import { Label } from "../label/Label";
import { Layout } from "../layout/Layout";
import { ListenerListDef } from "../../base/ListenerListDef";
import { Panel } from "../panel/Panel";
import { ReflectiveDialogs } from "../../base/ReflectiveDialogs";
import { refreshKeyListeners } from "../../events/GlobalKeyListener";
import { ClickEvent, ContextMenu, SaveButton, Toast, ToastOptions } from "../.."; // Direct import causes circular reference
import { ScreenStack } from "../../components/panel/ScreenStack";
import { SearchButton } from "../../page/SearchButton";
import { serializeComponents } from "../../serializer/ComponentSerializer";

const log = DesignableObjectLogManager.getLogger("components/DataHeader");
const _afterCloseListenerDef: ListenerListDef = { listName: "_afterCloseListenerDef" };
const _beforeCloseListenerDef: ListenerListDef = { listName: "_beforeCloseListenerDef" };

export class DataHeader extends Panel implements DataHeaderProps {
    private _allowRowDelete: (row: ModelRow) => boolean;
    private initialMode: DataSourceMode;
    private labelTitle: Label;
    private _panelTitle: Panel;
    private _addlPanelLeft: Panel;
    private _addlPanelRight: Panel;
    private _designerAddlPanelLeftComponents: Component[];
    private _designerAddlPanelRightComponents: Component[];
    private panelTools: Panel;
    private _title: string;
    private _layout: Layout;
    private saveButton: SaveButton;
    private deleteButton: Button;
    private closeButton: Button;
    private searchButton: SearchButton;
    private _showSave: boolean;
    private _showClose: boolean;
    private _showSaveAndClose: boolean;
    private _showExecute: boolean;
    private _showDelete: boolean;
    public onExecute: (row: ModelRow<any>) => void;
    private promptToSave: boolean;
    public navigateOnClose: (closeAction: DataHeaderCloseAction) => Promise<void>;
    public afterClosePath: string;
    private _dataSourceModeChangeListener: DataSourceModeChangeListener;
    private _ellipsisButton: Button;

    constructor(props?: Partial<DataHeaderProps>) {
        super(props, false);
        this.contextMenuClickType = null;
        const title = this.title;
        this._panelTitle = new Panel({ align: HorizontalAlignment.LEFT, verticalAlign: VerticalAlignment.CENTER, rowBreak: false, paddingLeft: 0 });
        this.labelTitle = new Label({
            themeKey: "label.pageTitle",
            id: "labelTitle",
            text: title,
            rowBreak: false
        });
        this._panelTitle.add(this.labelTitle);
        this._addlPanelLeft = new Panel({ id: "panelDataHeaderAddlLeft", align: HorizontalAlignment.LEFT, verticalAlign: VerticalAlignment.CENTER, rowBreak: false, margin: 0, padding: 0, _designer: this._designer });
        this._addlPanelRight = new Panel({ id: "panelDataHeaderAddlRight", align: HorizontalAlignment.RIGHT, verticalAlign: VerticalAlignment.CENTER, rowBreak: false, margin: 0, padding: 0, _designer: this._designer });
        this.closeButton = this.createCloseButton();
        this.panelTools = new Panel({ align: HorizontalAlignment.RIGHT, verticalAlign: VerticalAlignment.CENTER, paddingRight: 0 });
        this.add(this._panelTitle, this._addlPanelLeft, new HorizontalSpacer(), this._addlPanelRight, this.panelTools);
        this.forEveryChildComponent((component: Component) => component.deserialized = false);
        this._dataSourceModeChangeListener = (event: DataSourceModeChangeEvent) => this.onDataSourceModeChange(event);
        this.addMountListener(() => this.syncTitle());
        this.setProps({ fillRow: true, marginBottom: 12, ...props });
        this.updateComponentsForDesigner();
    }

    private onDataSourceModeChange(event: DataSourceModeChangeEvent) {
        // We have to track the initial mode of the DataSource so that we know if we started out as an ADD or an
        // UPDATE.  By the time we post a row and onExecute() is called, the DataSource will be in UPDATE mode, even if
        // we just added a record.
        if (this.initialMode == null && event.newMode !== DataSourceMode.NONE)
            this.initialMode = event.newMode;
        this.syncTitle();
        this.addTools(event.newMode);
    }

    public get showTitle(): boolean {
        return this.layout?.hideTitle != null ? !this.layout?.hideTitle : true;
    }

    private _showHideTitle() {
        if (this.showTitle !== false) {
            if (!this.contains(this._panelTitle))
                this.insert(this._panelTitle, 0);
        }
        else
            this.remove(this._panelTitle);
    }

    public get title(): string {
        return this._title || this.getLayoutTitle(this.dataSource?.mode);
    }

    public set title(value: string) {
        this._title = value;
        this.syncTitle();
    }

    public get layout(): Layout {
        return this._layout || this.findParentLayout();
    }

    public set layout(value: Layout) {
        this._layout = value;
        this._showHideTitle();
        this.syncTitle();
    }

    private findParentLayout() {
        let result = this.parent;
        while (result != null) {
            if (result instanceof Layout)
                return result;
            result = result.parent;
        }
        return null;
    }

    private getLayoutTitle(mode: DataSourceMode): string {
        const layout = this.layout;
        return layout == null ? null : layout.getTitleForMode(mode);
    }

    public get dataSource(): DataSource {
        return super.dataSource;
    }

    public set dataSource(value: DataSource) {
        if (this.dataSource != null)
            this.dataSource.removeAfterModeChangeListener(this._dataSourceModeChangeListener);
        super.dataSource = value;
        if (this.saveButton != null)
            this.saveButton.dataSource = value;
        this.syncTitle();
        if (value != null)
            value.addAfterModeChangeListener(this._dataSourceModeChangeListener);
    }

    public syncTitle(mode?: DataSourceMode) {
        mode = mode || this?.layout?.mainDataSource?.mode;
        const title = this.__designer == null ? (this._title || this.getLayoutTitle(mode)) : "Dynamic Title";
        this.labelTitle.text = title;
        if (this.showTitle !== false && StringUtil.isEmptyString(title) !== true && this.__designer == null)
            WindowTitle.set(title);
        this.addTools(mode);
    }

    private addTools(mode: DataSourceMode) {
        this.panelTools.removeAll();
        if (this.showExecute !== false) {
            if (mode === DataSourceMode.SEARCH)
                this.configureToolsForSearch();
            else
                this.configureToolsForAddOrUpdate();
        }
        if (this.showClose === true)
            this.panelTools.add(this.closeButton);
        refreshKeyListeners(this);
    }

    private configureToolsForSearch() {
        if (this.saveButton != null && this.saveButton.primaryButton != null)
            this.saveButton.primaryButton.default = true;
        this.buildAddlLeftComponents();
        this.buildAddlRightComponents();
        this.searchButton = new SearchButton({
            default: true,
            backgroundColor: "primary",
            color: "primary.reverse",
            width: 100,
            marginRight: 0,
            rowBreak: false
        });
        this.searchButton.addClickListener(() => {
            const values = this.layout.mainDataSource.getSearchFilter(true);
            if (this.layout.mainDataSource.validate(true)) {
                if (this.onExecute == null)
                    this.dataSource.search(values);
                else
                    this.onExecute(values);
                this.internalNavigateOnClose(DataHeaderCloseAction.EXECUTE);
            }
        });
        this.panelTools.add(this.searchButton);
        this.syncContextMenu();
    }

    private configureToolsForAddOrUpdate() {
        if (this.searchButton != null)
            this.searchButton.default = false;
        this.buildAddlLeftComponents();
        this.buildAddlRightComponents();
        this.buildSaveButton();
        this.syncContextMenu();
        this.buildDeleteButton();
        this.updateComponentsForDesigner();
    }

    private buildSaveButton() {
        this.saveButton?.removeFromHasChangedComponents();
        const additionalSaveOptions: Label[] = this.saveButton?.addlActions;
        this.saveButton = new SaveButton({
            id: "dhSaveCloseButton",
            backgroundColor: "primary",
            color: "primary.reverse",
            minWidth: 128,
            borderWidth: 0,
            allowSave: this.showSave,
            allowSaveAndClose: this.showSaveAndClose,
            rowBreak: false,
            dataSource: this.dataSource,
        });
        this.setSaveButtonAddlActions(additionalSaveOptions);
        this.saveButton.primaryButton.default = true;
        if (this.onExecute != null)
            this.saveButton.onSave = (postedRow: ModelRow) => this.onExecute(postedRow);
        this.saveButton.onClose = () => {
            this.internalNavigateOnClose(DataHeaderCloseAction.EXECUTE);
        };
        this.panelTools.add(this.saveButton);
    }

    private buildDeleteButton() {
        if (this.showDelete === true &&
            this.dataSource?.mode === DataSourceMode.UPDATE &&
            this.internalAllowRowDelete() === true) {
            this.deleteButton = new Button({
                id: "dhDeleteButton",
                imageProps: {
                    color: "error",
                    name: "delete",
                    height: 32,
                    width: 32
                },
                variant: ButtonVariant.round,
                borderWidth: 0,
                rowBreak: false
            });
            this.deleteButton.addClickListener(() => this.deleteRecord());
            this.panelTools.add(this.deleteButton);
        }
    }

    private internalAllowRowDelete(): boolean {
        const row = this.dataSource?.activeRow;
        if (row == null)
            return false;
        if (this._allowRowDelete != null)
            return this._allowRowDelete(row);
        return true;
    }

    /**
     * Set this variable within a layout's doAfterDataHeaderSetup() to allow each row that is loaded to be tested to see
     * if it can be deleted.
     */
    public set allowRowDelete(value: (row: ModelRow) => boolean) {
        this._allowRowDelete = value;
    }

    private async deleteRecord() {
        const message = "Are you sure you want to delete this record?";
        const confirmation = await ReflectiveDialogs.showDestructive(message, "Delete Confirmation");
        if (confirmation === true) {
            await this.dataSource.activeRow?.delete();
            const toastOptions: Partial<ToastOptions> = { targetPanel: ScreenStack.getPreviousToastTarget() };
            Toast.showSuccessToast("Record Deleted", "The record was successfully deleted.", "delete", toastOptions);
            this.internalNavigateOnClose(DataHeaderCloseAction.DELETE);
        }
    }

    private createCloseButton(): Button {
        return new Button({
            id: "dhCloseButton",
            cancel: true,
            rowBreak: false,
            borderWidth: 0,
            padding: 2,
            variant: ButtonVariant.round,
            imageProps: { name: "x", height: 32, width: 32, color: "primary" },
            onClick: () => this.close()
        });
    }

    private async close() {
        if (this.promptToSave === true &&
            (this.initialMode === DataSourceMode.ADD || this.initialMode === DataSourceMode.UPDATE) &&
            this.dataSource.hasChanged(true)) {
                const message = "Unsaved changes are present.  Do you wish to leave without saving changes?";
                const dialogProps = {
                    yesButtonCaption: "Leave",
                    noButtonCaption: "Stay"
                };
                const leave = await ReflectiveDialogs.showYesNo(message, "Leave Without Saving?", dialogProps);
                if (leave !== true)
                    return;
        }
        this.internalNavigateOnClose(DataHeaderCloseAction.CANCEL)
    }

    public promptToSaveChanges() {
        this.promptToSave = true;
    }

    private async internalNavigateOnClose(closeAction: DataHeaderCloseAction) {
        const beforeCloseEvent = new CrudDecoratorCloseEvent(this, this.initialMode, closeAction, true);
        this.fireBeforeCloseListener(beforeCloseEvent);
        if (beforeCloseEvent.defaultPrevented === true) {
            log.debug(this, "Close of DataHeader cancelled: %o", this);
            return;
        }
        if (this.navigateOnClose != null)
            await this.navigateOnClose(closeAction);
        else {
            const destinationPath = this.afterClosePath ?? "";
            await Navigation.navigateTo(destinationPath);
        }
        const afterCloseEvent = new CrudDecoratorCloseEvent(this, this.initialMode, closeAction, false);
        this.fireAfterCloseListener(afterCloseEvent);
    }

    public get showClose(): boolean {
        return this._showClose == null ? DataHeaderPropDefinitions.getDefinitions().showClose.defaultValue : this._showClose;
    }

    public set showClose(value: boolean) {
        this._showClose = value;
        this.addTools(this.layout?.mainDataSource?.mode);
    }

    public get showSave(): boolean {
        return this._showSave == null ? DataHeaderPropDefinitions.getDefinitions().showSave.defaultValue : this._showSave;
    }

    public set showSave(value: boolean) {
        this._showSave = value;
        this.addTools(this.layout?.mainDataSource?.mode);
    }

    public get showSaveAndClose(): boolean {
        return this._showSaveAndClose == null ? DataHeaderPropDefinitions.getDefinitions().showSaveAndClose.defaultValue : this._showSaveAndClose;
    }

    public set showSaveAndClose(value: boolean) {
        this._showSaveAndClose = value;
        this.addTools(this.layout?.mainDataSource?.mode);
    }

    public get showExecute(): boolean {
        return this._showExecute == null ? DataHeaderPropDefinitions.getDefinitions().showExecute.defaultValue : this._showExecute;
    }

    public set showExecute(value: boolean) {
        this._showExecute = value;
        this.addTools(this.layout?.mainDataSource?.mode);
    }

    public get showDelete(): boolean {
        return this._showDelete ?? DataHeaderPropDefinitions.getDefinitions().showDelete.defaultValue;
    }

    public set showDelete(value: boolean) {
        this._showDelete = value;
        this.addTools(this.layout?.mainDataSource?.mode);
    }

    private buildAddlLeftComponents() {
        this._addlPanelLeft.removeAll();
        this._designerAddlPanelLeftComponents?.forEach(c => this._addlPanelLeft.add(c));
    }

    private buildAddlRightComponents() {
        this._addlPanelRight.removeAll();
        this._designerAddlPanelRightComponents?.forEach(c => this._addlPanelRight.add(c));
    }

    private syncContextMenu() {
        if (this._designer != null && this._designer.showComponentContextMenu == null)
            return;

        const contextMenu = new ContextMenu({ sourceComponent: this });
        if (!contextMenu.hasVisibleContextMenuLabels())
            return;

        this._ellipsisButton = new Button({
            focusable: false,
            padding: 6,
            height: DOMUtil.getElementHeight(this.saveButton?._element),
            borderWidth: 0,
            rowBreak: false,
            color: "primary",
            imageProps: {
                name: "ellipsis",
                height: 18,
                width: 18
            }
        });
        this.panelTools.add(this._ellipsisButton);

        this._ellipsisButton.addClickListener((event: ClickEvent) => {
            if (this._designer != null) {
                this._designer.showComponentContextMenu?.(this, event);
            } else {
                contextMenu.showInOverlay(event)
            }
        });
    }

    protected override determinePropertyDefaultValue(prop: ComponentPropDefinition) {
        if (prop.name === "title")
            return this.getLayoutTitle(DataSourceMode.NONE);
        return super.determinePropertyDefaultValue(prop);
    }

    override getPropertyDefinitions(): ComponentPropDefinitions {
        return DataHeaderPropDefinitions.getDefinitions();
    }

    override get serializationName() {
        return "dataheader";
    }

    override get properName(): string {
        return "Data Header";
    }

    setSaveButtonAddlActions(labels: Label[]) {
        if (this.saveButton != null) {
            log.debug(this, "Adding additional actions to save button: %o", labels);
            this.saveButton.addlActions = labels;
        }
        else
            log.debug(this, "Not adding additional actions to save button, as button is configured to not be present %o", labels);
    }

    get _designer(): DesignerInterface {
        return super._designer;
    }

    set _designer(value: DesignerInterface) {
        this._shouldAddDesignerContainerProperties = false;
        super._designer = value;
        if (value != null) {
            this.labelTitle.text = "Dynamic Title";
            this._addlPanelLeft._designer = value;
            this._addlPanelRight._designer = value;
        }
        value?.addDesignerContainerProperties(this, 80, 34, null, false);
        this.updateComponentsForDesigner();
    }

    private updateComponentsForDesigner() {
        const interactionEnabled = this._designer == null;
        this.labelTitle._interactionEnabled = interactionEnabled;
        if (this.saveButton != null)
            this.saveButton._interactionEnabled = interactionEnabled;
        if (this.closeButton != null)
            this.closeButton._interactionEnabled = interactionEnabled;
        if (this.deleteButton != null)
            this.deleteButton._interactionEnabled = interactionEnabled;
    }

    _serializeNonProps(): string {
        let result = "";
        let addlPanelLeft = "";
        let addlPanelRight = "";
        if (ArrayUtil.isEmptyArray(this._addlPanelLeft?.components) !== true)
            addlPanelLeft += serializeComponents(this._addlPanelLeft, null);
        if (ArrayUtil.isEmptyArray(this._addlPanelRight?.components) !== true)
            addlPanelRight += serializeComponents(this._addlPanelRight, null);
        if (StringUtil.isEmptyString(addlPanelLeft) !== true || StringUtil.isEmptyString(addlPanelRight) !== true) {
            result += "\"components\": [\n";
            if (StringUtil.isEmptyString(addlPanelLeft) !== true)
                result += addlPanelLeft + ",\n";
            if (StringUtil.isEmptyString(addlPanelRight) !== true)
                result += addlPanelRight + ",\n";
            result = result.substring(0, result.length - 2);
            result += "\n],\n";
        }
        return result;
    }

    async _deserializeSpecialProps(props: DeserializeProps):  Promise<string[]> {
        this.setDataSourceFromDeserializeProps(props);

        const components = await new ComponentDeserializer({
            ...props,
            def: props.def.components,
            designer: this.__designer,
            defaultPropValues: null
        }).deserialize();

        if (components != null) {
            for (const component of components) {
                if (component.id === "panelDataHeaderAddlLeft" && component instanceof Panel) {
                    component.deserialized = false;
                    this.replace(this._addlPanelLeft, component);
                    this._addlPanelLeft = component;
                    this._designerAddlPanelLeftComponents = [...this._addlPanelLeft.components];
                    if (this.__designer != null)
                        this._addlPanelLeft.minWidth = 25;
                }
                if (component.id === "panelDataHeaderAddlRight" && component instanceof Panel) {
                    component.deserialized = false;
                    this.replace(this._addlPanelRight, component);
                    this._addlPanelRight = component;
                    this._designerAddlPanelRightComponents = [...this._addlPanelRight.components];
                    if (this.__designer != null)
                        this._addlPanelRight.minWidth = 25;
                }
            }
        }
        return ["dataSource", "components"];
    }

    public override discoverIncludedComponents(): Component[] {
        return [this._addlPanelLeft, this._addlPanelRight];
    }

    set doBeforeClose(value: CrudDecoratorCloseListener) {
        this.addBeforeCloseListener(value);
    }

    addBeforeCloseListener(value: CrudDecoratorCloseListener) {
        this.addEventListener(_beforeCloseListenerDef, value);
    }

    removeBeforeCloseListener(value: CrudDecoratorCloseListener) {
        this.removeEventListener(_beforeCloseListenerDef, value);
    }

    fireBeforeCloseListener(closeEvent: CrudDecoratorCloseEvent) {
        this.fireListeners(_beforeCloseListenerDef, closeEvent);
    }

    set doAfterClose(value: CrudDecoratorCloseListener) {
        this.addAfterCloseListener(value);
    }

    addAfterCloseListener(value: CrudDecoratorCloseListener) {
        this.addEventListener(_afterCloseListenerDef, value);
    }

    removeAfterCloseListener(value: CrudDecoratorCloseListener) {
        this.removeEventListener(_afterCloseListenerDef, value);
    }

    fireAfterCloseListener(closeEvent: CrudDecoratorCloseEvent) {
        this.fireListeners(_afterCloseListenerDef, closeEvent);
    }

    override getListenerDefs(): Collection<ListenerListDef> {
        return {
            ...super.getListenerDefs(),
            "afterClose": { ..._afterCloseListenerDef },
            "beforeClose": { ..._beforeCloseListenerDef },
        };
    }
}

ComponentTypes.registerComponentType("dataheader", DataHeader.prototype.constructor);
