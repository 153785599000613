import { Collection, Color, JSUtil, getThemeForKey } from "@mcleod/core";
import { ComponentCreator, ComponentFactory, ComponentTypes } from "../..";
import { ListenerListDef } from "../../base/ListenerListDef";
import { ChangeEvent, ChangeListener } from "../../events/ChangeEvent";
import { Event } from "../../events/Event";
import { Label } from "../label/Label";
import { Panel } from "../panel/Panel";
import { MultiSwitchPropDefinitions, MultiSwitchProps } from "./MultiSwitchProps";
import { Captioned } from "../../base/CaptionedComponent";
import { Printable } from "../../base/PrintableComponent";
import { CompoundComponent } from "../compound/CompoundComponent";

const _changeListenerDef: ListenerListDef = { listName: "_changeListeners" };
const printableListenerDef: ListenerListDef = { listName: "_printableListeners"};

export class MultiSwitch extends CompoundComponent implements MultiSwitchProps {
    _items: ComponentCreator[] = [];
    _selectedIndex: number = 0;
    private _caption: Label;
    private _panelSwitch: Panel;
    public backgroundColorSelected: Color = getThemeForKey("multiSwitch.backgroundColorSelected");
    public backgroundColorUnselected: Color = getThemeForKey("multiSwitch.backgroundColorUnselected");
    public colorSelected: Color = getThemeForKey("multiSwitch.colorSelected");
    public colorUnselected: Color = getThemeForKey("multiSwitch.colorUnselected");

    constructor(props?: Partial<MultiSwitchProps>) {
        super({ padding: 0, ...props }, false);
        this._panelSwitch = new Panel({ 
            rowBreakDefault: false, 
            ...getThemeForKey("multiSwitch.panel"),
            ...props 
        });
        this._caption = new Label({ ...getThemeForKey("multiSwitch.caption") });
        this.add(this._caption);
        this.add(this._panelSwitch);
        const superProps = { ...props };
        delete superProps.backgroundColor, superProps.color;
        this.setProps(superProps);
        if (props?.selectedIndex === undefined)
            this.selectedIndex = 0;
    }

    get items(): ComponentCreator[] {
        return this._items;
    }

    set items(value: ComponentCreator[]) {
        this._items = value;
        this._panelSwitch.removeAll();
        for (let i = 0; i < value.length; i++) {
            const comp = ComponentFactory.createCommon(value[i], { ...getThemeForKey("multiSwitch.item") });
            comp.addClickListener(event => this._internalSetSelectedIndex(i, event));
            this._panelSwitch.add(comp);
        }
    }

    get selectedIndex(): number {
        return this._selectedIndex;
    }

    set selectedIndex(value: number) {
        this._internalSetSelectedIndex(value, null);
    }

    private _internalSetSelectedIndex(value: number, originatingEvent: Event) {
        const oldValue = this._selectedIndex;
        this._selectedIndex = value;
        for (let i = 0; i < this._panelSwitch.components.length; i++) {
            this._panelSwitch.components[i].backgroundColor = i === value ? this.backgroundColorSelected : this.backgroundColorUnselected;
            this._panelSwitch.components[i].color = i === value ? this.colorSelected : this.colorUnselected;
        }
        if (oldValue !== value) {
            const changeEvent = new ChangeEvent(this, !value, value, originatingEvent == null ? null : originatingEvent.domEvent);
            this.fireListeners(_changeListenerDef, changeEvent);
        }
    }

    addItem(item: ComponentCreator) {
        this._items.push(item);
        this.items = this._items;
    }

    set caption(value: string) {
        const oldValue = this._caption.text;
        this._caption.text = value;
        this._matchIdToValue(oldValue, value, "multi");
    }

    get caption(): string {
        return this._caption.text;
    }

    addChangeListener(value: ChangeListener) {
        this.addEventListener(_changeListenerDef, value);
    }

    removeChangeListener(value: ChangeListener) {
        this.removeEventListener(_changeListenerDef, value);
    }

    override getListenerDefs(): Collection<ListenerListDef> {
        return {
            ...super.getListenerDefs(),
            "change": { ..._changeListenerDef },
            "printable": { ...printableListenerDef}
        };
    }

    protected _initialDropInDesigner() {
        this.items = ["Option 1", "Option 2", "Option 3"];
        this.selectedIndex = 0;
    }

    _serializeNonProps(): string {
        return "";
    }

    override getBasicValue(): any {
        if (this.selectedIndex === -1 || this.items == null || this.items.length === 0)
            return null;
        return this.items[this.selectedIndex];
    }

    override get serializationName() {
        return "multiswitch";
    }

    override get properName(): string {
        return "MultiSwitch";
    }

    override getPropertyDefinitions() {
        return MultiSwitchPropDefinitions.getDefinitions();
    }
}

JSUtil.applyMixins(MultiSwitch, [Captioned, Printable]);
ComponentTypes.registerComponentType("multiswitch", MultiSwitch.prototype.constructor);
