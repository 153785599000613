import { HorizontalAlignment, StringUtil } from "@mcleod/core";
import { Image } from "../../components/image/Image";
import { Label } from "../../components/label/Label";
import { Panel } from "../../components/panel/Panel";
import { PanelProps } from "../../components/panel/PanelProps";
import { Spinner } from "../../components/spinner/Spinner";
import { ImageType } from "../../components/image/ImageType";
import { Component } from "../../base/Component";

export class ReportViewer extends Panel {
    private labelEmpty: Label;
    private spinnerBusy: Spinner;
    private imagePreview: Image;
    private _searching: boolean = false;

    constructor(panelProps?: Partial<PanelProps>) {
        super({
            id: "panelReportViewer",
            fillRow: true,
            fillHeight: true,
            padding: 8,
            ...panelProps
        });
        this.createEmptyLabel();
        this.createBusySpinner();
        this.createPreviewImage();
        this.add(this.labelEmpty);
    }

    public set imageBytes(value: string) {
        this.imagePreview.imageBytes = value;
        this.sync();
    }

    public get searching(): boolean {
        return this._searching;
    }

    public set searching(value: boolean) {
        this._searching = value ?? false;
        this.sync();
    }

    private createEmptyLabel() {
        this.labelEmpty = new Label({
            id: "labelPreview",
            text: "Report Preview Will Appear Here",
            align: HorizontalAlignment.CENTER,
            backgroundColor: "backgroundSubtle",
            color: "subtle.darker",
            fillHeight: true,
            fillRow: true,
            fontSize: "xlarge"
        });
    }

    private createBusySpinner() {
        this.spinnerBusy = new Spinner();
        this.spinnerBusy.spinnerImage.height = 75;
        this.spinnerBusy.spinnerImage.width = 75;
    }

    private createPreviewImage() {
        this.imagePreview = new Image({
            id: "imageReportPreview",
            imageType: ImageType.PDF,
            fillHeight: true,
            fillRow: true,
        });
    }

    private sync() {
        if (this.searching === true)
            this.displaySingleComponentInViewerPanel(this.spinnerBusy);
        else if (StringUtil.isEmptyString(this.imagePreview.imageBytes) === true)
            this.displaySingleComponentInViewerPanel(this.labelEmpty);
        else
            this.displaySingleComponentInViewerPanel(this.imagePreview);
    }

    private displaySingleComponentInViewerPanel(component: Component) {
        if (this.components.length === 1 && component === this.components[0])
            return;
        this.removeAll();
        this.add(component);
    }
}
