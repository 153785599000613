export const props = { fill: "none", viewBox: "0 0 23 24" };
export const innerHTML = `
<svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1899 19.2442C15.1899 17.4488 14.1954 15.8858 12.7268 15.0765L12.7316 14.8097V2.31206C12.7316 1.03515 11.6964 0 10.4195 0C9.1426 0 8.10712 1.03515 8.10712 2.31206V14.8097L8.1078 15.0951C6.65785 15.9098 5.67824 17.4627 5.67824 19.2442C5.67824 21.8708 7.80745 24 10.4341 24C13.0607 24 15.1899 21.8708 15.1899 19.2442ZM6.48413 19.244C6.48413 17.6613 7.41329 16.2963 8.75691 15.6658C8.86493 15.6154 8.93333 15.5057 8.93367 15.3865V2.31494C8.93367 1.49448 9.59871 0.829102 10.4195 0.829102C11.2403 0.829102 11.9057 1.49414 11.906 2.31494V15.3679C11.906 15.4894 11.9751 15.6012 12.0855 15.652C13.4433 16.277 14.3844 17.6511 14.3844 19.244C14.3844 21.4237 12.6195 23.1909 10.4408 23.1943C8.26084 23.1977 6.48413 21.424 6.48413 19.244Z" fill="currentColor"/>
    <path d="M10.4344 21.4737C11.6659 21.4737 12.6642 20.4754 12.6642 19.2439C12.6642 18.0125 11.6659 17.0142 10.4344 17.0142C9.20294 17.0142 8.20464 18.0125 8.20464 19.2439C8.20464 20.4754 9.20294 21.4737 10.4344 21.4737Z" fill="currentColor"/>
    <path d="M11.0703 2.68744C11.0703 2.328 10.7789 2.03662 10.4195 2.03662C10.0601 2.03662 9.76869 2.328 9.76869 2.68744V18.1242C9.76869 18.4836 10.0601 18.775 10.4195 18.775C10.7789 18.775 11.0703 18.4836 11.0703 18.1242V2.68744Z" fill="currentColor"/>
    <path d="M19.0417 8.72334C19.0417 6.47223 17.2761 4.61459 14.9952 4.34505C14.6905 4.30916 14.4216 4.53603 14.4216 4.82927V12.6177C14.4216 12.911 14.6905 13.1379 14.9952 13.102C17.2761 12.8321 19.0417 10.9748 19.0417 8.72368V8.72334Z" fill="currentColor"/>
    <path d="M22.034 8.10889H20.8952C20.561 8.10889 20.2901 8.3798 20.2901 8.71399V8.72652C20.2901 9.06071 20.561 9.33162 20.8952 9.33162H22.034C22.3682 9.33162 22.6391 9.06071 22.6391 8.72652V8.71399C22.6391 8.3798 22.3682 8.10889 22.034 8.10889Z" fill="currentColor"/>
    <path d="M5.2211 13.8474L5.2211 3.61112C5.2211 3.33752 4.99931 3.11572 4.72571 3.11572H4.72537C4.45177 3.11572 4.22997 3.33752 4.22997 3.61112L4.22997 13.8474C4.22997 14.121 4.45177 14.3428 4.72537 14.3428H4.72571C4.99931 14.3428 5.2211 14.121 5.2211 13.8474Z" fill="currentColor"/>
    <path d="M20.7559 4.4536L19.7959 5.06612C19.5142 5.24588 19.4315 5.61999 19.6113 5.90171L19.6138 5.90571C19.7936 6.18744 20.1677 6.2701 20.4494 6.09035L21.4094 5.47782C21.6911 5.29806 21.7738 4.92395 21.594 4.64223L21.5915 4.63823C21.4117 4.3565 21.0376 4.27384 20.7559 4.4536Z" fill="currentColor"/>
    <path d="M5.50793 3.99497L4.54947 4.95343C4.35547 5.14743 4.35547 5.46195 4.54947 5.65594C4.74346 5.84993 5.05798 5.84993 5.25197 5.65594L6.21044 4.69748C6.40443 4.50348 6.40443 4.18896 6.21044 3.99497C6.01645 3.80098 5.70192 3.80098 5.50793 3.99497Z" fill="currentColor"/>
    <path d="M4.84245 4.94433L3.89308 3.99496C3.69658 3.79846 3.37803 3.79841 3.18159 3.99484C2.98516 4.19128 2.98521 4.50983 3.18171 4.70633L4.13108 5.6557C4.32758 5.8522 4.64613 5.85225 4.84257 5.65582C5.03901 5.45938 5.03895 5.14083 4.84245 4.94433Z" fill="currentColor"/>
    <path d="M3.88396 13.4162L4.84243 12.4577C5.03642 12.2637 5.03642 11.9492 4.84243 11.7552C4.64843 11.5612 4.33391 11.5612 4.13992 11.7552L3.18145 12.7137C2.98746 12.9076 2.98746 13.2222 3.18145 13.4162C3.37545 13.6102 3.68997 13.6102 3.88396 13.4162Z" fill="currentColor"/>
    <path d="M4.54929 12.4557L5.50919 13.4156C5.70279 13.6092 6.01667 13.6092 6.21026 13.4156C6.40386 13.222 6.40386 12.9081 6.21026 12.7145L5.25036 11.7546C5.05676 11.561 4.74288 11.561 4.54929 11.7546C4.35569 11.9482 4.35569 12.2621 4.54929 12.4557Z" fill="currentColor"/>
    <path d="M0.835277 12.0885L4.91156 9.20769C5.13606 9.04903 5.18943 8.73842 5.03077 8.51392C4.87211 8.28942 4.5615 8.23604 4.337 8.3947L0.260713 11.2755C0.0362123 11.4342 -0.0171608 11.7448 0.141501 11.9693C0.300162 12.1938 0.610776 12.2472 0.835277 12.0885Z" fill="currentColor"/>
    <path d="M0.418128 10.7549L1.74068 10.9821C2.01475 11.0292 2.2751 10.8452 2.32219 10.5711C2.36929 10.2971 2.18529 10.0367 1.91122 9.98962L0.58867 9.76236C0.3146 9.71527 0.0542459 9.89927 0.00715204 10.1733C-0.0399418 10.4474 0.144058 10.7078 0.418128 10.7549Z" fill="currentColor"/>
    <path d="M1.56969 10.7434L1.343 12.0626C1.29575 12.3376 1.48037 12.5989 1.75536 12.6461C2.03035 12.6934 2.29158 12.5087 2.33884 12.2337L2.56552 10.9145C2.61277 10.6395 2.42815 10.3783 2.15316 10.3311C1.87817 10.2838 1.61694 10.4684 1.56969 10.7434Z" fill="currentColor"/>
    <path d="M0.266381 6.17026L4.33782 9.06261C4.56132 9.22138 4.87122 9.16891 5.03 8.94541C5.18877 8.72191 5.1363 8.41201 4.9128 8.25323L0.841361 5.36088C0.617858 5.20211 0.30796 5.25458 0.149184 5.47808C-0.00959256 5.70158 0.0428784 6.01148 0.266381 6.17026Z" fill="currentColor"/>
    <path d="M1.38451 5.34019L1.60751 6.65728C1.65422 6.93312 1.91562 7.11889 2.19137 7.0722C2.46712 7.02551 2.6528 6.76405 2.60609 6.4882L2.38309 5.17111C2.33638 4.89527 2.07498 4.7095 1.79923 4.75619C1.52348 4.80288 1.3378 5.06435 1.38451 5.34019Z" fill="currentColor"/>
    <path d="M1.78015 6.41963L0.459049 6.64331C0.184312 6.68983 -0.000696398 6.95026 0.0458213 7.22499L0.0458778 7.22533C0.0923955 7.50007 0.352824 7.68507 0.627561 7.63856L1.94866 7.41487C2.22339 7.36836 2.4084 7.10793 2.36189 6.83319L2.36183 6.83286C2.31531 6.55812 2.05488 6.37311 1.78015 6.41963Z" fill="currentColor"/>
    <path d="M17.5548 1.86792L17.0318 2.87948C16.8783 3.17634 16.9945 3.54142 17.2914 3.6949L17.3064 3.70267C17.6033 3.85616 17.9684 3.73993 18.1219 3.44307L18.6449 2.43151C18.7983 2.13465 18.6821 1.76957 18.3853 1.61609L18.3702 1.60831C18.0734 1.45483 17.7083 1.57106 17.5548 1.86792Z" fill="currentColor"/>
    <path d="M21.4083 11.9917L20.4483 11.3792C20.1666 11.1994 19.7925 11.2821 19.6127 11.5638L19.6125 11.5641C19.4328 11.8458 19.5154 12.2199 19.7972 12.3997L20.7572 13.0122C21.0389 13.192 21.413 13.1093 21.5928 12.8276L21.5929 12.8273C21.7727 12.5456 21.69 12.1715 21.4083 11.9917Z" fill="currentColor"/>
    <path d="M18.6398 15.0393L18.1168 14.0277C17.9633 13.7309 17.5983 13.6147 17.3014 13.7681L17.2969 13.7705C17 13.924 16.8838 14.289 17.0373 14.5859L17.5603 15.5975C17.7138 15.8943 18.0788 16.0105 18.3757 15.8571L18.3802 15.8547C18.6771 15.7012 18.7933 15.3362 18.6398 15.0393Z" fill="currentColor"/>
  </g>
</svg>`
