import { CommonDialogs } from "@mcleod/common";
import { ClickEvent, Layout, Toast } from "@mcleod/components";
import { SendAccountInvitation } from "./SendAccountInvitation";
import { AutogenLayoutAccountList } from "./autogen/AutogenLayoutAccountList";

export class AccountList extends AutogenLayoutAccountList {
    /** This is an event handler for the onClick event of buttonCreateAccount.  */
    buttonCreateAccountOnClick(event: ClickEvent) {
        const layout = Layout.getLayout("auth/SendAccountInvitation") as SendAccountInvitation;
        const dialog = CommonDialogs.createDialog(layout, { height: 460, width: 480, title: "Create Account", okVisible: false });
        layout.afterInviteSent = () => {
            Toast.showSuccessToast("Account created and invitation sent.");
            dialog.close();
        }
        dialog.show();
    }
}
