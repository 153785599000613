import { AuthToken, Logger, LogManager } from "..";
import { Collection } from "../Collection";
import { AbstractSettings, AbstractSettingsDef } from "./AbstractSettings";

interface RegisteredSettings {
    key: string;
    singleton: AbstractSettings;
}

const _registeredSettingsClasses: Collection<RegisteredSettings> = {};

export class SettingsManager {
    private static log: Logger;

    public static register(singleton: AbstractSettings) {
        _registeredSettingsClasses[singleton.key] = { key: singleton.key, singleton };
    }

    public static isRegistered(key: string): boolean {
        return _registeredSettingsClasses[key] != null;
    }

    public static getSingleton(key: string): AbstractSettings {
        return _registeredSettingsClasses[key]?.singleton;
    }

    //it's very unlikely that an authenticated user is getting a live update for some unauthenticated setting,
    //so if we don't know if we are setting unauth or auth settings we will go off the presence of an auth token
    public static setSettings(key: string, newValues: AbstractSettingsDef, isAuthSet: boolean = AuthToken.isAuthenticated()) {
        this.getLog().debug("Updating settings of type [%o] with data %o", key, newValues);
        const registered = _registeredSettingsClasses[key];
        if (registered == null) {
            this.getLog().debug("Not updating settings, settings object is not registered");
            return;
        }
        const singleton = registered.singleton;
        if (singleton == null) {
            this.getLog().debug("Not updating settings, registered settings singleton is null");
            return;
        }
        singleton.set(newValues, isAuthSet);
        this.getLog().debug("Finished replacing settings value for key [%s]", key);
    }

    private static getLog(): Logger {
        if (this.log == null)
            this.log = LogManager.getLogger("core/settings/SettingsManager");
        return this.log;
    }
}
