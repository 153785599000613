import { DropdownItem, PanelProps } from "@mcleod/components";
import { AbstractValueDisplayValueEditor } from "./AbstractValueDisplayValueEditor";

export class DropdownItemEditor extends AbstractValueDisplayValueEditor {
    constructor(currentValueJson: string, props?: Partial<PanelProps>) {
        super(currentValueJson, props);
    }

    get valueCaption(): string {
        return "Value";
    }

    get valueName(): string {
        return "value";
    }

    get displayValueCaption(): string {
        return "Display Value";
    }

    get displayValueName(): string {
        return "displayValue";
    }

    get emptyCaption(): string {
        return "Database / Display pairs can be defined in this table";
    }

    getResultValue(): any[] {
        const result: any[] = [];
        for (const row of this.tableRows) {
            const item = new DropdownItem(row.data.get(this.valueName, null), row.data.get(this.displayValueName, null));
            result.push(item);
        }
        return result;
    }
}
