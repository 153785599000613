import { Collection } from "./Collection";

const formatCache: Collection<Config> = {};
const fmtReg = /[0#.,]/;

interface Config {
    prefix: string;
    suffix: string;
    thousandSeparate: number;
    maxScale?: number;
    minScale?: number;
    length?: number;
    radixPoint?: number;
    withSign: boolean;
}

export enum RoundingMode {
    UP,
    DOWN,
    CEILING,
    FLOOR,
    HALF_UP,
    HALF_DOWN,
    HALF_EVEN,
    UNNECESSARY,
}

/**
 * This class performs numeric formatting with specifiers like Java's DecimalFormat class.
 * This was swiped from https://github.com/leemotive/decimal-format and (kind-of) converted to typescript.
 */

export class DecimalFormat {
    roundingMode: RoundingMode;
    config: any;

    constructor(format = "", roundingMode: RoundingMode = RoundingMode.HALF_UP) {
        this.config = { ...resolveFormat(format) };
        this.roundingMode = roundingMode;
    }

    format(n: number) {
        const { maxScale, minScale, length, thousandSeparate, prefix, suffix, radixPoint, withSign } = this.config;
        let number: any = +n;
        if (isNaN(number))
            throw Error("not a valid number");
        if (maxScale !== void 0)
            number = (+round(+number, maxScale, this.roundingMode)).toFixed(maxScale);
        else {
            number = number.toString();
        }
        let [intPortion, decimalPortion] = number.split(".");
        if (length) {
            const intMatch = intPortion.match(/([+-]?)(\d*)/);
            intPortion = intMatch[1] + intMatch[2].padStart(length, 0);
        } else if (intPortion === "0")
            intPortion = "";
        if (thousandSeparate && thousandSeparate < intPortion.length)
            intPortion = intPortion.replace(new RegExp(`(\\d{1,${thousandSeparate}})(?=(?:\\d{${thousandSeparate}})+$)`, "g"), "$1,");

        if (decimalPortion)
            decimalPortion = decimalPortion.replace(/0+$/, "").padEnd(minScale, 0);

        number = [intPortion, decimalPortion].join(".");
        if (!radixPoint)
            number = number.replace(/\.$/, "");
        if (withSign && !number.startsWith("-"))
            number = `+${number}`;
        if (number === "")
            number = 0;
        return `${prefix}${number}${suffix}`;
    }
}

function resolveFormat(pattern) {
    if (formatCache[pattern])
        return formatCache[pattern];
    let prefix: any = [];
    let suffix: any = [];
    let withSign = false;
    let fmt: any = [];
    let ch = "";
    let state = "PREFIX";
    let escape = false;

    let temp: any;

    function append(c) {
        if ("PREFIX" === state)
            temp = prefix;
        else if ("FMT" === state)
            temp = fmt;
        else
            temp = suffix;
        temp.push(c);
        if (escape)
            return;
        if ("PREFIX" === state && ["+", "-"].includes(c) && fmtReg.test(pattern[i + 1])) {
            temp.pop();
            withSign = true;
        }
    }

    function setState(c) {
        if (state === "PREFIX" && fmtReg.test(c))
            state = "FMT";
        else if (state === "FMT" && !fmtReg.test(c))
            state = "SUFFIX";
    }

    let i = 0;
    for (; i < pattern.length; i++) {
        ch = pattern[i];
        if (escape) {
            append(ch);
            escape = false;
            continue;
        }

        setState(ch);

        if (ch === "\\")
            escape = true;
        else
            append(ch);
    }

    prefix = prefix.join("");
    fmt = fmt.join("");
    suffix = suffix.join("");

    if (!fmt) {
        return (formatCache[pattern] = {
            suffix,
            prefix,
            thousandSeparate: 3,
            withSign,
        });
    }

    if (/\..*\./.test(fmt))
        throw Error(`Multiple decimal separators in pattern "${pattern}"`);

    const [intFmt, decimalFmt = ""] = fmt.split(".");
    if (/[^0#]/.test(decimalFmt))
        throw Error(`Malformed pattern "${pattern}"`);
    if (decimalFmt.includes("#0"))
        throw Error(`Unexpected "0" in pattern "${pattern}"`);
    if (intFmt.endsWith(","))
        throw Error(`Malformed pattern "${pattern}"`);
    if (/0.*#/.test(intFmt))
        throw Error(`Unexpected "0" in pattern "${pattern}"`);

    let thousandSeparate = 0;
    const lastIndexOfSeperator = intFmt.lastIndexOf(",");
    if (lastIndexOfSeperator !== -1)
        thousandSeparate = intFmt.length - lastIndexOfSeperator - 1;

    const length = intFmt.replace(/,/g, "").match(/0*$/)[0].length;
    const maxScale = decimalFmt.length;
    const minScale = decimalFmt.match(/^0*/)[0].length;
    const radixPoint = fmt.endsWith(".");

    const config = {
        prefix,
        suffix,
        thousandSeparate,
        maxScale,
        minScale,
        length,
        radixPoint,
        withSign,
    };
    formatCache[pattern] = config;
    return config;
}

function enlarge(n: number, multi: number) {
    if (multi < 0)
        return shrink(n, -multi);
    const nStr = toString(n);
    if (!multi)
        return nStr;
    const num = `${nStr}${"0".repeat(multi)}`;
    return num.replace(new RegExp(`\\.(\\d{${multi}})`), "$1.");
}

function shrink(n: number, multi: number) {
    if (multi < 0)
        return enlarge(n, -multi);
    const nStr = toString(n);
    if (!multi)
        return nStr;
    return `${nStr}`.replace(/^-?/, `$&${"0".repeat(multi)}`).replace(new RegExp(`(\\d{${multi}})(\\.|$)`), ".$1");
}

function adjust(n: number, scale: number) {
    const num = `${toString(n)}`;
    if (num.includes(".")) {
        const arr = num.split(".");
        arr[1] = `${arr[1].padEnd(scale, 0 as any)}1`;
        return +arr.join(".");
    } else
        return n;
}

function round(n: number, scale: number, roundingMode: RoundingMode) {
    const portions = `${toString(n)}`.split(".");
    const int = portions[0];
    let decimal = portions[1];
    const sign = n > 0 ? "" : "-";
    if (!decimal)
        return n.toFixed(scale);
    else {
        decimal = decimal.padEnd(scale + 1, 0 as any);
        if (roundingMode === RoundingMode.CEILING)
            return shrink(Math.ceil(+enlarge(n, scale)), scale);
        else if (roundingMode === RoundingMode.FLOOR)
            return shrink(Math.floor(+enlarge(n, scale)), scale);
        else if (roundingMode === RoundingMode.UP)
            return `${sign}${shrink(Math.ceil(+enlarge(Math.abs(n), scale)), scale)}`;
        else if (roundingMode === RoundingMode.DOWN)
            return `${sign}${shrink(Math.floor(+enlarge(Math.abs(n), scale)), scale)}`;
        else if (roundingMode === RoundingMode.HALF_UP)
            return (+adjust(n, scale)).toFixed(scale);
        else if (roundingMode === RoundingMode.HALF_DOWN) {
            const decimalArr: any = decimal.split("");
            if (decimalArr[scale] == 5)
                decimalArr[scale] = 1;
            return (+[int, decimalArr.join("")].join(".")).toFixed(scale);
        } else if (roundingMode === RoundingMode.HALF_EVEN) {
            const decimalArr: any = decimal.split("");
            if (decimalArr[scale] == 5) {
                const lastNum = decimalArr[scale - 1] || int.slice(-1)[0];
                if (+lastNum % 2 === 0)
                    decimalArr.splice(scale);
                else
                    decimalArr[scale] = 9;
            }
            return (+[int, decimalArr.join("")].join(".")).toFixed(scale);
        } else if (roundingMode === RoundingMode.UNNECESSARY) {
            if (+shrink(Math.ceil(+enlarge(n, scale)), scale) === n)
                return n;
            else
                throw Error("ArithmeticException: Rounding needed with the rounding mode being set to RoundingMode.UNNECESSARY");
        }
    }
}

function toString(n: number) {
    const nStr = `${n}`;
    if (nStr.includes("e")) {
        const nArr = nStr.split("e");
        return enlarge(+nArr[0], +nArr[1]);
    }
    return nStr;
}
