import { ArrayUtil, DOMUtil, HorizontalAlignment, SortDirection, StringUtil } from "@mcleod/core";
import { ImageNames } from "@mcleod/images";
import { Label } from "../label/Label";
import { LabelProps } from "../label/LabelProps";
import { Panel } from "../panel/Panel";
import { ColumnComponent } from "./TableColumn";

export class SortSelector extends Panel {
    private _columnComponent: ColumnComponent; //will be null for parent selectors
    private fieldLabel: Label;
    private imageLabel: Label;
    private orderLabel: Label;
    private parentSelector: SortSelector;
    private childSelectors: SortSelector[] = [];

    constructor(columnComponent: ColumnComponent, labelProps: Partial<LabelProps>, parentSelector?: SortSelector) {
        super({ id: "sortSelector", padding: 0, margin: 0, fillRow: true }, true);
        this._columnComponent = columnComponent;
        this.createFieldLabel(labelProps);
        this.createImageLabel();
        this.createOrderLabel();
        this.add(this.fieldLabel);
        this.add(this.imageLabel);
        this.add(this.orderLabel);
        this.parentSelector = parentSelector;
        this.parentSelector?.addChildSelector(this);
    }

    override set align(value: HorizontalAlignment) {
        if (value === HorizontalAlignment.RIGHT)
            this.fieldLabel.fillRow = true;
        this.fieldLabel.align = value;
        this.imageLabel.align = value;
    }

    override get align(): HorizontalAlignment {
        return this.fieldLabel.align;
    }

    get fieldName(): string {
        return this._columnComponent?.field;
    }

    get columnComponent(): ColumnComponent {
        return this._columnComponent;
    }

    set columnComponent(value: ColumnComponent) {
        this._columnComponent = value;
    }

    getChildSelector(fieldName: string): SortSelector {
        if (ArrayUtil.isEmptyArray(this.childSelectors))
            return null;
        for (const childSelector of this.childSelectors) {
            if (childSelector.fieldName === fieldName)
                return childSelector;
        }
        return null;
    }

    private createFieldLabel(props?: Partial<LabelProps>) {
        this.fieldLabel = new Label({
            themeKey: "table.sortSelectorFieldLabel",
            rowBreak: false,
            wrap: false,
            ...props,
        });
        if (this.fieldLabel.tooltip == null)
            this.fieldLabel.tooltip = this.fieldLabel.text;
    }

    private createImageLabel(props?: Partial<LabelProps>) {
        this.imageLabel = new Label({
            themeKey: "table.sortSelectorImageLabel",
            rowBreak: false,
            ...props,
        });
    }

    private createOrderLabel(props?: Partial<LabelProps>) {
        this.orderLabel = new Label({
            themeKey: "table.sortSelectorOrderLabel",
            marginTop: this.fieldLabel.marginTop,
            rowBreak: false,
            ...props
        });
    }

    public update(sort: SortDirection, order: number, displayOrder: boolean) {
        this.updateImageAndFontColor(sort);
        this.updateOrder(order, displayOrder);
        this.updateParentSelector(sort, order, displayOrder);
    }

    private updateImageAndFontColor(sort: SortDirection) {
        if (sort === "asc") {
            this.imageLabel.imageName = ImageNames.ascending;
            this.setFieldImageLabelProps({ color: "McLeodLinkSpecial" });
        }
        else if (sort === "desc") {
            this.imageLabel.imageName = ImageNames.descending;
            this.setFieldImageLabelProps({ color: "McLeodLinkSpecial" });
        }
        else if (StringUtil.isEmptyString(sort)) {
            this.imageLabel.imageName = null;
            this.setFieldImageLabelProps({ color: "McLeodSecondary" });
        }
        this.evaluateFieldLabelLength();
    }

    private evaluateFieldLabelLength() {
        if (this.sortInEffect() === true) {
            const availableWidth = DOMUtil.getElementWidth(this._element, false);
            const fieldLabelWidth = DOMUtil.getElementWidth(this.fieldLabel._element, true, true);
            const imageLabelWidth = DOMUtil.getElementWidth(this.imageLabel._element, true, true);
            const orderLabelWidth = DOMUtil.getElementWidth(this.orderLabel._element, true, true);
            const totalWidth = fieldLabelWidth + imageLabelWidth + orderLabelWidth;
            if (totalWidth > availableWidth) {
                this.fieldLabel.maxHeight = DOMUtil.getElementHeight(this.fieldLabel._element);
                this.fieldLabel.maxWidth = availableWidth - imageLabelWidth - orderLabelWidth;
            }
        }
        else {
            this.fieldLabel.maxWidth = null;
            this.fieldLabel.maxHeight = null;
        }
    }

    private setFieldImageLabelProps(props: Partial<LabelProps>) {
        this.fieldLabel.setProps(props);
        this.imageLabel.setProps(props);
    }

    private updateOrder(order: number, displayOrder: boolean) {
        if (displayOrder !== true)
            this.orderLabel.text = "";
        else
            this.orderLabel.text = order.toString();
    }

    private addChildSelector(childSelector: SortSelector) {
        if (this.childSelectors == null)
            this.childSelectors = [];
        this.childSelectors.push(childSelector);
    }

    private updateParentSelector(sort: SortDirection, order: number, displayOrder: boolean) {
        if (this.parentSelector == null)
            return;
        const sortedChildren = this.parentSelector.getSortedChildren();
        if (sortedChildren.length > 1) {
            this.parentSelector.imageLabel.imageName = ImageNames.sorting_off;
            this.parentSelector.orderLabel.text = "M";
        }
        else
            this.parentSelector.update(sort, order, displayOrder);
    }

    private getSortedChildren(): SortSelector[] {
        const result = [];
        for (const child of this.childSelectors) {
            if (child.sortInEffect() === true)
                result.push(child);
        }
        return result;
    }

    private sortInEffect(): boolean {
        return this.imageLabel.imageName != null;
    }

    public getFieldLabel(): Label {
        return this.fieldLabel;
    }
}
