import { getThemeForKey, Styles } from "@mcleod/core";

export const CheckboxStyles = Styles.make("chk", () => {
    return {
        checkBase: { display: "flex", alignItems: "center", userSelect: "none" },
        checked: {}, //exists solely so we can tell if the box is checked from the CSS styles (for test automation)
        image: {
            display: "flex", alignItems: "center", outline: "none",
            "&:focus-within": getThemeForKey("checkbox.styleHoverCircle"),
            "&:hover": getThemeForKey("checkbox.styleHoverCircle")
        },
        imageDisabled: {
            display: "flex", alignItems: "center", outline: "none",
        },
        indeterminate: {}, //exists solely so we can tell if the box is checked from the CSS styles (for test automation)
        unchecked: {} //exists solely so we can tell if the box is checked from the CSS styles (for test automation)
    };
});
