export interface Size {
    width: number;
    height: number;
}

export interface Point {
    x: number;
    y: number;
}

export interface Bounds {
    left: number;
    top: number;
    width: number;
    height: number;
}

export interface ScrollOptions {
    behavior: ScrollBehavior;
    center: true;
    block: Block
}

export enum ScrollBehavior {
    AUTO = "auto",
    SMOOTH = "smooth",
    INSTANT = "instant"
}

export enum Block {
    START = "start",
    CENTER = "center",
    END = "end",
    NEAREST = "nearest"
}
