import { DataSource, Layout, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutCitySuggestionQuickInfo extends Layout {
    sourceCity: DataSource;
    textboxCounty: Textbox;
    textboxId: Textbox;
    textboxName: Textbox;
    textboxStateId: Textbox;
    textboxZipCode: Textbox;
}
