import { Collection, Color, JSUtil, ModelRow, StringUtil, getThemeForKey } from "@mcleod/core";
import { Component, Container, DataSourceMode, PrintableEvent, PrintableListener } from "../..";
import { Captioned } from "../../base/CaptionedComponent";
import { getCurrentDataSourceMode, getRelevantModelRow } from "../../base/ComponentDataLink";
import { ComponentTypes } from "../../base/ComponentTypes";
import { ListenerListDef } from "../../base/ListenerListDef";
import { Printable, printableListenerDef } from "../../base/PrintableComponent";
import { Image } from "../../components/image/Image";
import { ChangeEvent, ChangeListener } from "../../events/ChangeEvent";
import { Event } from "../../events/Event";
import { KeyEvent } from "../../events/KeyEvent";
import { Label } from "../label/Label";
import { RadioPropDefinitions, RadioProps } from "./RadioProps";
import { RadioStyles } from "./RadioStyles";

const _changeListenerDef: ListenerListDef = { listName: "_changeListeners" };

export class Radio extends Component implements RadioProps {
    private _image: Image;
    private _captionLabel: Label;
    private _printLabel: Label;
    private _radioColor: Color;
    public valueSelected: any;
    private _selected: boolean;
    private _enabledRadioColor: Color;
    private _focusable: boolean;
    private _defaultDataValue: boolean;
    private _allowIndeterminate: boolean;

    constructor(props?: Partial<RadioProps>) {
        super("div", props);
        this._captionLabel = new Label({ themeKey: "radio.caption", paddingLeft: 0, marginLeft: 3 });
        this._captionLabel.addClickListener(event => {
            this.userClicked(event);
            if (this.focusable && !this.printable) {
                this.focus();
            }
            if (this._designer != null)
                event.shouldAutomaticallyStopPropagation = false;
        });
        this._captionLabel.setClassIncluded(RadioStyles.captionLabel);
        this._element.classList.add(RadioStyles.radioBase);
        this._element.appendChild(this._captionLabel._element);
        this._addChild();
        this.selected = false;
        this.setProps(props);
        this.supportsEditSecurity = true;
    }

    override get defaultDataValue(): boolean {
        return this._defaultDataValue;
    }

    public set defaultDataValue(value: boolean) {
        this._defaultDataValue = value;
    }

    override setProps(props: Partial<RadioProps>) {
        super.setProps(props);
    }

    override getFocusTarget(): HTMLElement {
        return this._image?._element;
    }

    override displayComponentData(data: ModelRow, allData: ModelRow[], rowIndex: number) {
        if (data == null || this.field == null || this.valueSelected == null)
            this.selected = false;
        else {
            const value = (data instanceof ModelRow) ? data.get(this.field) : data[this.field];
            this.selected = value === this.valueSelected;
        }
    }

    get caption(): string {
        return this["_mixin-Captioned-caption"];
    }

    set caption(value: string) {
        if (this["captionValueMatches"](value) === true) {
            return;
        }
        this["_mixin-Captioned-caption"] = value;
        this.syncCaption();
    }

    syncCaption() {
        this._captionLabel.visible = !StringUtil.isEmptyString(this.caption);
        this._captionLabel.text = this.printable === true ? "" : this["getPrefixedCaption"]();
    }


    private userClicked(event: Event) {
        if (this.enabled && this._designer == null)
            this.userToggled(event);
    }

    private userToggled(event: Event) {
        if (this.allowIndeterminate && this.selected) { // for Radio's where we allow indeterminate state, we toggle between selected and unselected
            this.selected = false;
            return;
        } else if (!this.selected) {
            this.getGroup().forEach(radio => radio._internalSetSelected(radio === this, event));
        }
    }

    get selected() {
        return this._selected;
    }

    set selected(value: boolean) {
        this._internalSetSelected(value, null);
    }

    get radioSize(): number {
        return this._image?.minHeight as number;
    }

    set radioSize(value: number) {
        this._image.minHeight = value;
        this._image.minWidth = value;
    }

    get valueAsString(): string {
        return this.selected ? this.valueSelected : null;
    }

    set valueAsString(value: string) {
        this.selected = value === this.valueSelected;
    }

    private _internalSetSelected(value: boolean, originatingEvent: Event) {
        if (this._selected === value)
            return;
        this._selected = value;
        this._internalUpdateBoundData();
        this.storeUserChoiceIfRemembered();
        const changeEvent = new ChangeEvent(this, !value, value, originatingEvent == null ? null : originatingEvent.domEvent);
        this.fireListeners(_changeListenerDef, changeEvent);
        this._displayValue();
    }

    get radioColor() {
        return this._radioColor;
    }

    set radioColor(value) {
        this._radioColor = value;
        this._addChild();
        if (this._image != null)
            this._image.color = value || "primary";
    }

    get focusable(): boolean {
        return this._focusable != null ? this._focusable : true;
    }

    set focusable(value: boolean) {
        this._focusable = value;
        this._image._element.setAttribute("tabindex", value === true ? "0" : "-1");
    }

    override _applyEnabled(value: boolean): void {
        if (this._enabledRadioColor == null &&
            (this._image != null && this._image._element.classList.contains(RadioStyles.imageDisabled) !== true)) {
            this._enabledRadioColor = this.radioColor;
        }
        if (value && this._image != null) {
            this._image._element.removeAttribute("disabled");
            this._image._element.setAttribute("tabindex", this.focusable === true ? "0" : "-1");
            this._image._element.classList.remove(RadioStyles.imageDisabled);
            this._image._element.classList.add(RadioStyles.image);
            this.radioColor = this._enabledRadioColor;
        }
        else {
            if (this._image != null) {
                this._image._element.setAttribute("disabled", "true");
                this._image._element.setAttribute("tabindex", "-1");
                this._image._element.classList.remove(RadioStyles.image);
                this._image._element.classList.add(RadioStyles.imageDisabled);
                this.setProps({ ...getThemeForKey("radio.disabled") });
            }
        }
    }

    get printable(): boolean {
        return this["_mixin-Printable-printable"];
    }

    set printable(value: boolean) {
        this["_mixin-Printable-printable"] = value;
    }

    get printableDuringAdd(): boolean {
        return this["_mixin-Printable-printableDuringAdd"];
    }

    set printableDuringAdd(value: boolean) {
        this["_mixin-Printable-printableDuringAdd"] = value;
    }

    get printableDuringSearch(): boolean {
        return this["_mixin-Printable-printableDuringSearch"];
    }

    set printableDuringSearch(value: boolean) {
        this["_mixin-Printable-printableDuringSearch"] = value;
    }

    get printableDuringUpdate(): boolean {
        return this["_mixin-Printable-printableDuringUpdate"];
    }

    set printableDuringUpdate(value: boolean) {
        this["_mixin-Printable-printableDuringUpdate"] = value;
    }

    get allowIndeterminate(): boolean {
        return this._allowIndeterminate;
    }

    set allowIndeterminate(value: boolean) {
        this._allowIndeterminate = value;
    }

    private _addChild() {
        if (this.printable === true) {
            if (this._image != null)
                this._element.removeChild(this._image._element);
            this._image = null;
            this._captionLabel.text = this.caption + ":";
            this._addPrintableLabel();
            this._displayValue();
        } else {
            let reattachListeners = false;
            this._captionLabel.text = this.caption;
            if (this._printLabel != null) {
                this._element.removeChild(this._printLabel._element);
                this._printLabel = null;
                reattachListeners = true;
            }
            this._addImage(reattachListeners);
        }
    }

    private _addPrintableLabel() {
        if (this._printLabel == null) {
            this._printLabel = new Label();
            this._element.appendChild(this._printLabel._element);
        }
    }

    public override getEventTarget(): HTMLElement {
        return this._image?._element;
    }

    private _addImage(reattachListeners: boolean = false) {
        if (this._image == null) {
            this._image = new Image({ themeKey: "radio.image", marginRight: 0 });
            if (this.radioColor != null)
                this._image.color = this.radioColor;
            this._image._element.tabIndex = 0;
            this._image._element.classList.add(RadioStyles.image);
            this._syncEnabled();

            if (reattachListeners === true)
                this.reattachListeners();
            this._image.addClickListener(event => {
                this.userClicked(event);
                if (this._designer != null)
                    event.shouldAutomaticallyStopPropagation = false;
            });
            this._image.addKeyDownListener(event => this._imageKeyPressed(event));
            this._displayValue();
            if (this._element.contains(this._captionLabel._element))
                this._element.insertBefore(this._image._element, this._captionLabel._element);
            else
                this._element.appendChild(this._image._element);
        }
    }

    private _imageKeyPressed(event: KeyEvent) {
        if (event.key === ' ' && this.enabled && this.__designer == null) {
            this.userToggled(event);
            event.consume();
        }
    }

    private _displayValue() {
        this.setElementStyleForAutomation();
        if (this._printLabel != null) {
            this._printLabel.text = this.selected ? this.caption : null;
        }
        else if (this._image != null) {
            this._image.name = this.selected ? "radioFilled" : "radioUnfilled";
        }
    }

    private setElementStyleForAutomation() {
        if (this.selected === undefined) {
            this._element.classList.remove(RadioStyles.selected);
            this._element.classList.remove(RadioStyles.unselected);
            this._element.classList.add(RadioStyles.indeterminate);
        }
        else if (this.selected) {
            this._element.classList.remove(RadioStyles.unselected);
            this._element.classList.remove(RadioStyles.indeterminate);
            this._element.classList.add(RadioStyles.selected);
        }
        else {
            this._element.classList.remove(RadioStyles.selected);
            this._element.classList.remove(RadioStyles.indeterminate);
            this._element.classList.add(RadioStyles.unselected);
        }
    }

    addChangeListener(value: ChangeListener): Radio {
        return this.addEventListener(_changeListenerDef, value) as Radio;
    }

    removeChangeListener(value: ChangeListener) {
        return this.removeEventListener(_changeListenerDef, value) as Radio;
    }

    public addPrintableListener(value: PrintableListener) {
        this.addEventListener(printableListenerDef, value);
    }

    public removePrintableListener(value: PrintableListener) {
        this.removeEventListener(printableListenerDef, value);
    }

    protected _getDefaultEventProp(): string {
        return "onChange";
    }

    private _internalUpdateBoundData() {
        const row = getRelevantModelRow(this);
        const mode = getCurrentDataSourceMode(this);
        this.updateBoundData(row, mode);
    }

    public getGroup(): Radio[] {
        const result = [] as Radio[];
        result.push(this);
        if (this.field != null) {
            this.addRadiosFromContainer(result, this.getParentLayout());
        }
        return result;
    }

    public getSelectedGroupMember(): Radio {
        return this.getGroup().find(radio => radio.selected);
    }

    private addRadiosFromContainer(result: Radio[], container: Component) {
        if (container == null)
            return;
        if (container instanceof Radio) {
            if (container.field == this.field && container !== this)
                result.push(container);
        }
        else if (container instanceof Container) {
            container.components.forEach(child => {
                this.addRadiosFromContainer(result, child);
            });
        }
    }

    override updateBoundData(row: ModelRow, mode: DataSourceMode) {
        if (this.field != null) {
            const selMember = this.getSelectedGroupMember();
            row?.set(this.field, selMember?.valueSelected, this);
        }
    }

    override dataSourceModeChanged(mode: DataSourceMode) {
        super.dataSourceModeChanged(mode);
        if (mode === DataSourceMode.SEARCH) {
            this._allowIndeterminate = true;
            this.selected = undefined;
        }
        else
            this._allowIndeterminate = false;
        this["_syncPrintable"]();
    }

    protected _applyPrintable(value: boolean) {
        this._addChild();
        this.fireListeners(printableListenerDef, new PrintableEvent(this, this._printLabel));
    }

    override getPropertyDefinitions() {
        return RadioPropDefinitions.getDefinitions();
    }

    override getSearchValues(): string[] {
        const result = [];
        result.push(this.selected ? this.valueSelected : null);
        return result;
    }

    removeLabel() {
        this._element.removeChild(this._captionLabel._element);
    }

    override get serializationName() {
        return "radio";
    }

    override get properName(): string {
        return "Radio";
    }

    override getListenerDefs(): Collection<ListenerListDef> {
        return {
            ...super.getListenerDefs(),
            "change": { ..._changeListenerDef },
            "printable": { ...printableListenerDef}
        };
    }

    override getBasicValue(): any {
        return this.selected;
    }
}

JSUtil.applyMixins(Radio, [Captioned, Printable]);
ComponentTypes.registerComponentType("radio", Radio.prototype.constructor);
