export const innerHTML = `<path fill-rule="evenodd" clip-rule="evenodd" d="M5.63085 4.61842C5.6776 4.55134 5.7542 4.51135 5.83596
4.51135H9.85889L9.55034 6.00354H4.93314L5.63085 4.61842ZM11.0819 6.00354L11.3905 4.51135H12.4817L12.7903 6.00354H11.0819ZM14.3219
6.00354H19.1273L18.529 4.63099C18.4835 4.55668 18.4027 4.51135 18.3156 4.51135H14.0133L14.3219 6.00354ZM3.00364 6.64703C3.01245
6.51571 3.05596 6.38502 3.13669 6.26917L4.40019 3.76082C4.72743 3.29124 5.26361 3.01135 5.83596 3.01135H18.3156C18.9253 3.01135
19.491 3.32862 19.8089 3.84882L20.8828 6.31213C20.9213 6.37517 20.9494 6.44166 20.9676 6.50957C20.9887 6.57546 21 6.64538 21
6.71783V20.2893C21 20.6837 20.6642 21.0035 20.25 21.0035H3.75C3.33579 21.0035 3 20.6837 3 20.2893V6.71783C3 6.69394 3.00123 6.67032
3.00364 6.64703ZM4.5 19.575V7.43211H9.53125V9.48792C9.53125 10.0402 9.97897 10.4879 10.5312 10.4879H13.4219C13.9742 10.4879 14.4219
10.0402 14.4219 9.48792V7.43211H19.5V19.575H4.5ZM17.0303 11.2839C17.3232 10.991 17.3232 10.5161 17.0303 10.2232C16.7374 9.93032
16.2626 9.93032 15.9697 10.2232L9.66401 16.5289L8.15119 13.8814C7.94568 13.5218 7.48754 13.3969 7.1279 13.6024C6.76826 13.8079 6.64332
14.266 6.84882 14.6256L8.68643 17.8415C9.01357 18.414 9.79553 18.5187 10.2618 18.0524L17.0303 11.2839Z"/>`;
