import { Collection, VerticalAlignment } from "@mcleod/core";
import { Panel, PanelProps } from "../..";
import { ListenerListDef } from "../../base/ListenerListDef";
import { DatePicker } from "./DatePicker";
import { DateTimePickerChangeEvent, DateTimePickerChangeListener } from "./DateTimePickerChangeEvent";
import { TimePicker } from "./TimePicker";

export interface DateTimePickerProps extends PanelProps {
    value: Date;
}

const _changeListenerDef: ListenerListDef = { listName: "_changeListeners" };

export class DateTimePicker extends Panel {
    datePicker: DatePicker;
    timePicker: TimePicker;
    _value: Date;

    constructor(props?: Partial<DateTimePickerProps>) {
        super({verticalAlign: VerticalAlignment.TOP, padding: 0, width: 655, ...props});
        this._value = props?.value || new Date();
        this.datePicker = new DatePicker({value: this._value, rowBreak: false, fillHeight: true });
        this.timePicker = new TimePicker({value: this._value});
        this.add(this.datePicker, this.timePicker);
        this.datePicker.addChangeListener(event => this.dateChanged(event));
        this.timePicker.addChangeListener(event => this.timeChanged(event));
    }

    dateChanged(event: DateTimePickerChangeEvent) {
        const oldValue = this._value;
        if (this._value == null)
            this._value = new Date();
        this._value.setFullYear(event.newValue.getFullYear(), event.newValue.getMonth(), event.newValue.getDate());
        this.fireListeners(_changeListenerDef, new DateTimePickerChangeEvent(this, oldValue, this._value, event.domEvent, event.changePart));
    }

    timeChanged(event: DateTimePickerChangeEvent) {
        const oldValue = this._value;
        if (this._value == null)
            this._value = new Date();
        this._value.setHours(event.newValue.getHours());
        this._value.setMinutes(event.newValue.getMinutes());
        this.fireListeners(_changeListenerDef, new DateTimePickerChangeEvent(this, oldValue, this._value, event.domEvent, event.changePart));
    }

    addChangeListener(value: DateTimePickerChangeListener): DateTimePicker {
        return <DateTimePicker>this.addEventListener(_changeListenerDef, value);
    }

    removeChangeListener(value: DateTimePickerChangeListener): DateTimePicker {
        return <DateTimePicker>this.removeEventListener(_changeListenerDef, value);
    }

    override getListenerDefs(): Collection<ListenerListDef> {
        return { ...super.getListenerDefs(), "change": { ..._changeListenerDef } };
    }
}
