import { DOMUtil, StringUtil } from "@mcleod/core";
import { Component } from "../../base/Component";
import { ComponentFactory } from "../../page/ComponentFactory";
import { Panel } from "../panel/Panel";
import { Layout } from "../layout/Layout";
import { Label } from "../label/Label";
import { Textbox } from "./Textbox";
import { PanelProps } from "../panel/PanelProps";

export class LookupModelMultiSelectTooltip {
    private textbox: Textbox;
    private quickInfoTimeoutHandle: number;
    private quickInfoLayoutComponents: Component[];
    private quickInfoLayoutComponentsAdded = false;

    constructor(textbox: Textbox) {
        this.textbox = textbox;
    }

    /**
     * Generates the tooltip for a multi-select type-ahead field (or its label when in printable mode).  When a
     * quick info layout is specified, the tooltip will contain a bulleted list of the selected items' display values
     * on the left-hand side of the tooltip, and the quick info layout on the right-hand side of the tooltip.
     * Hovering over a value in the bulleted list will display that value's information in the quick info layout.
     *
     * When no quick info layout is specified, the tooltip will contain only the bulleted list.  The tooltip have a
     * static/arbitrary max height; if the selected items do not fit in that height then the tooltip will scroll to
     * allow all of the bulleted list to be viewed.
     *
     * @param displayValues A string array containing the display value for each selected item
     * @param resultValues A string array containing the result value for each selected item
     * @returns A Panel containing the content of the tooltip to be rendered.
     */
    async create(displayValues: string[], resultValues: string[]): Promise<Component> {
        if (StringUtil.isEmptyString(this.textbox.quickInfoLayout) === true)
            return this.createListOnlyTooltip(displayValues);
        return this.createTooltipWithQuickInfo(displayValues, resultValues);
    }

    /**
     * Creates the more basic, list-only version of the tooltip (when no quick info layout is specified).
     *
     * @param displayValues A string array containing the display value for each selected item
     * @returns A Panel containing the content of the tooltip to be rendered.
     */
    private createListOnlyTooltip(displayValues: string[]): Panel {
        const panelProps: Partial<PanelProps> = {
            id: "multiSelectLookupModelTooltip",
            maxHeight: 400
        };
        return this.createBulletedListPanel(displayValues, panelProps);
    }

    /**
     * Creates the version of the tooltip that includes the bulleted list on the left-hand side, and the quick info
     * layout on the right-hand side.
     *
     * @param displayValues A string array containing the display value for each selected item
     * @param resultValues A string array containing the result value for each selected item
     * @returns A Panel containing the content of the tooltip to be rendered.
     */
    private async createTooltipWithQuickInfo(displayValues: string[], resultValues: string[]): Promise<Panel> {
        const bulletedListPanel = this.createBulletedListPanel(displayValues);
        bulletedListPanel.setProps({
            id: "multiSelectLookupModelTooltip-List",
            borderRightWidth: 1,
            borderRightColor: "subtle",
            paddingRight: 12
        });
        const outerPanel = Panel.createNoMarginNoPaddingPanel({ id: "multiSelectLookupModelTooltip" });
        outerPanel.add(bulletedListPanel);
        if (bulletedListPanel.components.length === resultValues.length) {
            const quickInfoLayout = await this.createQuickInfoLayout();
            outerPanel.add(quickInfoLayout);
            bulletedListPanel.maxHeight = quickInfoLayout.height;
            this.setupHoverLabelAndListeners(quickInfoLayout, bulletedListPanel.components, resultValues);
        }
        return outerPanel;
    }

    private async createQuickInfoLayout(): Promise<Layout> {
        const quickInfoLayout = await Layout.getLoadedLayout(this.textbox.quickInfoLayout);
        quickInfoLayout.marginLeft = 8;
        const prevFillHeight = quickInfoLayout.fillHeight;
        quickInfoLayout.fillHeight = false;
        quickInfoLayout.doWhileDimensionsComputed(() => {
            const quickInfoHeightWidth = DOMUtil.getElementHeightWidth(quickInfoLayout._element);
            quickInfoLayout.height = quickInfoHeightWidth.height;
            quickInfoLayout.width = quickInfoHeightWidth.width;
        });
        quickInfoLayout.fillHeight = prevFillHeight;
        return quickInfoLayout;
    }

    /**
     * When a quick info layout is present, remove its contents and display a label to indicate to the user that
     * hovering over a value in the bulleted list will cause that entry's quick info to be displayed.  Then add the
     * hover listener to each component in the bulleted list to invoke that data search/display.
     *
     * @param displayValues A string array containing the display value for each selected item
     * @param resultValues A string array containing the result value for each selected item
     * @returns A Panel containing the content of the tooltip to be rendered.
     */
    private setupHoverLabelAndListeners(quickInfoLayout: Layout, listComponents: Component[], resultValues: string[]) {
        this.quickInfoLayoutComponents = [ ...quickInfoLayout.components ];
        quickInfoLayout.removeAll();
        quickInfoLayout.add(new Label({ text: "Hover over an item in the list to see more information." }));
        for (let x=0 ; x<listComponents.length ; x++) {
            const resultValue = resultValues[x];
            if (resultValue != null) {
                const label = listComponents[x];
                this.addHoverListener(label, quickInfoLayout, resultValue);
            }
        }
    }

    private addHoverListener(label: Component, quickInfoLayout: Layout, resultValue: string) {
        label.addMouseEnterListener(() => {
            if (this.quickInfoTimeoutHandle != null)
                window.clearTimeout(this.quickInfoTimeoutHandle);
            this.quickInfoTimeoutHandle = window.setTimeout(() => {
                if (this.quickInfoLayoutComponentsAdded === false) {
                    quickInfoLayout.components = this.quickInfoLayoutComponents;
                    this.quickInfoLayoutComponentsAdded = true;
                }
                quickInfoLayout.mainDataSource?.search({ search: resultValue });
            }, 300);
        });
    }

    private createBulletedListPanel(displayValues: string[], panelProps?: Partial<PanelProps>): Panel {
        const result = Panel.createNoMarginNoPaddingPanel({
            rowBreak: false,
            scrollY: true,
            ...panelProps
        });
        const labels = ComponentFactory.createBulletedList(displayValues);
        result.add(...labels);
        return result;
    }
}
