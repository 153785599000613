import { Keys } from "@mcleod/core";
import { SelectionMode } from "../../base/SelectionMode";
import { DomEvent } from "../../events/DomEvent";
import { Event } from "../../events/Event";
import { KeyEvent } from "../../events/KeyEvent";
import { SelectionEvent } from "../../events/SelectionEvent";
import { Label } from "../label/Label";
import { List } from "../list/List";
import { ListItem } from "../list/ListItem";
import { ListProps } from "../list/ListProps";
import { DropdownItem } from "./DropdownItem";
import { Textbox } from "./Textbox";
import { DesignableObjectLogManager } from "../../logging/DesignableObjectLogManager";
import { DropdownItemListStyles } from "./DropdownItemListStyles";

const log = DesignableObjectLogManager.getLogger("components.list.DropdownItemList");
const checkboxImageProps = { themeKey: "checkbox.image", marginRight: 0, name: "checkboxUnchecked", color: "primary" };

export class DropdownItemList extends List {
    private textbox: Textbox;
    private onSelect: (event: SelectionEvent) => void;
    private textboxItems: DropdownItem[];

    constructor(textbox: Textbox, onSelect: (event: SelectionEvent) => void, props?: Partial<ListProps>) {
        super({ ...props });

        this.textbox = textbox;
        this.onSelect = onSelect || (() => {});
        this.selectionMode = textbox.allowDropdownMultiSelect ? SelectionMode.MULTI : SelectionMode.SINGLE;
        this.textboxItems = textbox.resolveItems(true);
        this.items = this.textboxItems
        this.setSelectedItemsFromSuppliedInputs(this.textbox.selectedItems);

        this.addSelectionListener((event: SelectionEvent) => this.itemSelected(event));

        if (this.isMultiSelect) {
            this.addMouseEnterListener(() => {
                const focusedIdx = this.getFocusedIndex();
                if (focusedIdx >= 0) {
                    this._displayedItems[focusedIdx].renderedComponent._element.blur();
                }
            });
        }
    }

    itemSelected(event: SelectionEvent) {
        this.onSelect?.(event);
        if (event?.domEvent?.defaultPrevented) {
            log.debug(event.target, "Selection event was prevented. Not updating selected items");
            this.selectedIndexes = event.oldSelection;
            return;
        }

        if (!this.isMultiSelect) {
            this.textbox.hideDropdown(true);
        }
    }

    protected override syncSelectedItemProps(item: ListItem, selected: boolean) {
        if (this.isMultiSelect) {
            if (item == null)
                return;
            const label = item.renderedComponent as Label;
            label.imageProps = { ...checkboxImageProps, name: selected ? "checkboxChecked" : "checkboxUnchecked" };
        } else {
            super.syncSelectedItemProps(item, selected);
        }
    }

    protected override createSelectionEvent(item: DropdownItem, index: number, domEvent: DomEvent): SelectionEvent {
        const isSelected = this.selectedIndexes.includes(index);
        const newIndex = isSelected ? index : -1;
        const oldIndexes = this.selectedIndexes.filter(i => i !== index);
        const selectedItems = this.selectedListItems?.map(item => item.suppliedInput) ?? [];
        return new SelectionEvent(this, selectedItems, newIndex, oldIndexes , null, domEvent);
    }

    protected override resolveSingleItem(value: DropdownItem): ListItem {
        const item = super.resolveSingleItem(value);
        if (this.isMultiSelect) {
            this.resolveSingleItemForMultiSelect(item);
        }
        return item;
    }

    resolveSingleItemForMultiSelect(item: ListItem) {
        const label = item.renderedComponent as Label;
        this.syncSelectedItemProps(item, false);
        if (item.suppliedInput === DropdownItem.BLANK_SEARCH_ITEM) {
            label.text = "--";
        }

        if (this.textboxItems?.includes(DropdownItem.BLANK_ITEM)) {
            label.addClickListener(() => {
                if (item.suppliedInput === DropdownItem.BLANK_ITEM) {
                    this.selectedIndexes = [];
                } else {
                    this.removeSelectedIndex(0);
                }
            });
        }

        label.addClass(DropdownItemListStyles.listItem);
        // Make the element focusable but not included in the tab order
        label._element.tabIndex = -1;

        // styles.listItem had a hover effect but it was clashing with the focus effect
        // if the user hovered over an item and then used the arrow keys to navigate
        label._element.addEventListener("mouseenter", () => label._element.focus());
        label._element.addEventListener("mouseleave", () => label._element.blur());
    }

    override sendKey(event: KeyEvent | KeyboardEvent) {
        const domEvent = Event.resolveDomEvent<KeyboardEvent>(event);
        const code = domEvent?.code;
        if (code == null) {
            log.debug(this.textbox, "No code found in event %o", event);
            return false;
        }

        if (Keys.TAB === code) {
            this.textbox.hideDropdown(true);
            return true;
        }

        if (this.isMultiSelect) {
            switch (code) {
                case Keys.ARROW_DOWN: {
                    this.relativeFocus(1);
                    return true;
                }
                case Keys.ARROW_UP: {
                    this.relativeFocus(-1);
                    return true;
                }
                case Keys.SPACE:
                case Keys.ENTER: {
                    log.debug(this.textbox, "Space key pressed in list of type %o", this.variant);
                    const index = this.getFocusedIndex();
                    if (index >= 0) {
                        this._displayedItems[index].renderedComponent.clicked(event);
                    }
                    return true;
                }
            }
            return false;
        } else {
            return super.sendKey(event);
        }
    }

    relativeFocus(offset: number) {
        const max = this._displayedItems.length - 1;
        const focusedIndex = this.getFocusedIndex();
        const index = focusedIndex + offset;
        log.debug(this.textbox, "Focused index: %d, offset: %d, new index: %d", focusedIndex, offset, index);
        if (index >= 0 && index <= max) {
            log.debug(this.textbox, "Focusing list item at index %d", index);
            this._displayedItems[index].renderedComponent._element.focus();
        }
    }

    private getFocusedIndex(): number {
        for (let i = 0; i < this._displayedItems.length; i++) {
            if (this._displayedItems[i].renderedComponent._element === document.activeElement)
                return i;
        }
        return -1;
    }
}
