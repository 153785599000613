import { DialogProps } from "@mcleod/components";
import { AbstractPanelOpenTree } from "./AbstractPanelOpenTree";

export class PanelOpenLayout extends AbstractPanelOpenTree {
    override getDialogProps(): Partial<DialogProps> {
        return {
            ...super.getDialogProps(),
            title: "Layout Selection",
            cursor: null
        };
    }

    override getApiName(): string {
        return "layout/list";
    }

    override getCustomizedTooltip(): string {
        return "This is a customized layout.";
    }
}
