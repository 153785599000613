import { DialogProps, TreeNode } from "@mcleod/components";
import { AbstractPanelOpenTree } from "./AbstractPanelOpenTree";

export class ActionPermissionSelector extends AbstractPanelOpenTree {
    override getApiName(): string {
        return "common/perms-action-tree";
    }

    override getCustomizedTooltip(): string {
        return "";
    }

    override setDataFromResponse(response: any) {
        this.data = response.actions;
    }

    override populateTree(): void {
        super.populateTree();
    }

    override getNodeTextField(): string {
        return "caption";
    }

    override getNodeChildField(): string {
        return "items";
    }

    override getDialogProps(): Partial<DialogProps> {
        return {
            ...super.getDialogProps(),
            okDefault: false,
            title: "Select Action Permission",
        }
    }

    getResultValueFromNode(node: TreeNode): string {
        return node?.data?.path;
    }
}
