import { Label, Layout, Panel, Table } from "@mcleod/components";

export abstract class AutogenLayoutThreadDumpAnalyzer extends Layout {
	labelDumpTakenDate: Label;
	labelHeading1: Label;
	labelHeading2: Label;
	labelHeading3: Label;
	labelProcessStartDate: Label;
	labelSelectThread: Label;
	labelTableDetail: Label;
	labelThreadInfo: Label;
	labelUptime: Label;
	panelAnalysis: Panel;
	panelDumpInfo: Panel;
	panelSummary: Panel;
	panelTableDetail: Panel;
	panelThreadTable: Panel;
	tableSummary: Table;
}
