import { Repeater } from "../components/repeater/Repeater";
import { RepeatItem } from "../components/repeater/RepeatItem";
import { DomEvent } from "./DomEvent";
import { Event, EventListener } from "./Event";

export interface RepeatItemDisplayListener extends EventListener {
    (event: RepeatItemDisplayEvent): void;
}

export class RepeatItemDisplayEvent extends Event {

    constructor(component: any, domEvent?: DomEvent) {
        super(component, domEvent);
    }

    public get target(): RepeatItem {
        return super.target as RepeatItem;
    }

    public getRepeatItem(): RepeatItem {
        return this.target as RepeatItem;
    }

    public getRepeater(): Repeater {
        return this.getRepeatItem().repeater;
    }
}
