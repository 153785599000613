import { StringUtil } from "@mcleod/core";

export class SourceLink {
    public static replaceSourceLink(stackTraceLine: string, commitId: string, isBranch: boolean = false): string {
        let className = StringUtil.stringBetween(stackTraceLine, "at ", "(", null);
        const fileAndLine = StringUtil.stringBetween(stackTraceLine, "(", ")", null);
        if (className == null || fileAndLine == null)
            return stackTraceLine;
        const fileName = StringUtil.stringBefore(fileAndLine, ":");
        className = StringUtil.stringBeforeLast(className, ".");
        const lineNumber = parseInt(StringUtil.stringAfter(fileAndLine, ":", false, null));
        const module = this.getModuleForClass(className);
        const packageName = StringUtil.stringBeforeLast(className, ".");
        const urlPath = packageName.replace(/\./g, "/") + "/" + fileName;
        let url = "https://dev.azure.com/mcleoddev/LoadMaster/_git/loadmaster?path=" + module + "/src/main/java/" + urlPath;
        if (commitId != null)
            url += "&version=G" + (isBranch ? "B" : "C") + commitId;
        if (lineNumber != null)
            url += "&line=" + lineNumber  + "&lineEnd=" + (lineNumber + 1) +
                "&lineStartColumn=1&lineEndColumn=1&lineStyle=plain&_a=contents"
        const openParen = stackTraceLine.indexOf("(");
        const closeParen = stackTraceLine.indexOf(")");
        const link = "<a href='" + url + "' target='_blank'>" + fileAndLine + "</a>";
        stackTraceLine = stackTraceLine.substring(0, openParen + 1) + link + stackTraceLine.substring(closeParen);
        return stackTraceLine;
    }

    private static getModuleForClass(className: string): string {
        if (className.indexOf(".client.lib.") >= 0)
            return "mcleod-lib/mcleod-lib-swing";
        else if (className.indexOf(".model.lme.") >= 0)
            return "mcleod-services/mcleod-api-lme";
        else if (className.indexOf(".model.lib.") >= 0)
            return "mcleod-services/mcleod-api-lib";
        else if (className.indexOf(".model.portal.") >= 0)
            return "mcleod-services/mcleod-api-portal";
        else if (className.indexOf(".api.lib.") >= 0)
            return "mcleod-lib/mcleod-lib-endpoint";
        else if (className.indexOf(".lib.log") >= 0)
            return "mcleod-lib/mcleod-logging";
        else if (className.indexOf(".lib.") >= 0)
            return "lme-lib"
        else
            return "lme";
    }
}
