import {Collection, HorizontalAlignment, MapSettings, ModelRow, VerticalAlignment} from "@mcleod/core";
import {Button, ButtonVariant, Component, DomEvent, Layout, PanelProps} from "../..";
import {ComponentTypes} from "../../base/ComponentTypes";
import {ListenerListDef} from "../../base/ListenerListDef";
import {DataSourceMode} from "../../databinding/DataSource";
import {ClickEvent} from "../../events/ClickEvent";
import {Overlay} from "../../page/Overlay";
import {Label} from "../label/Label";
import {Panel} from "../panel/Panel";
import {GoogleMap} from "./GoogleMap";
import {MapPin} from "./MapPin";
import {MapPropDefinitions, MapProps, MapVendor} from "./MapProps";
import {NoMap} from "./NoMap";
import {PinPlotEvent, PinPlotListener} from "./PinPlotEvent";
import {TrimbleMap} from "./TrimbleMap";
import {DesignableObjectLogManager} from "../../logging/DesignableObjectLogManager";

export interface VendorMap {
    removeAllPins: () => void;
    addPin: (pin: MapPin) => void;
    focusOnPin: (pin: MapPin, mapPinIndex: number) => void;
    defocusOnPin: (pin: MapPin, mapPinIndex: number) => void;
    fitPins: () => void;
    setZoom: (level: number) => void;
    createRoute: (routeData: any[], routetype?: any, hideMarkers?: boolean | false) => void;
    setStopZoomLevel: (isTrafficEnabled: string, routeData: any[]) => void;
    clearRoute: () => void;
    setCenter: (latitude: number, longitude: number) => void
}

const _pinPlotListenerDef: ListenerListDef = {listName: "_pinPlotListeners"};
const log = DesignableObjectLogManager.getLogger("components.Map");

export class Map extends Panel implements MapProps, VendorMap { // not sure if this should implement VendorMap or not
    map: Panel & VendorMap;
    private _clickToActivate: Label;
    private _immediatelyActivateMap: boolean;
    private _vendor: MapVendor;
    pins: MapPin[] = [];
    pinLayout: string;
    popupProps: Partial<PanelProps>;
    _disableDefaultUI: boolean;

    public latitudeField: string;
    public longitudeField: string;
    public singleRecord: boolean;
    private loadPromise: Promise<void>;

    constructor(props?: Partial<MapProps>) {
        super({padding: 0, borderRadius: 4, ...props});
        //The mapping vendor must be known at this point so that we can avoid creating the wrong
        //VendorMap in cases where the map is shown immediately (when click to activate isn't used).
        //To put it another way, we don't want to create a VendorMap for the default vendor (Google),
        //and then immediately afterward destroy that map and create a map for another vendor (i.e. Trimble).
        //That would be a poor use of an API call against the vendor.
        //As a result, the vendor cannot be changed after the Map is constructed.
        this.vendor = props?.vendor;
        this._disableDefaultUI = props?.disableDefaultUI;
    }

    public async ensureLoaded(): Promise<void> {
        if (this.loadPromise == null) {
            this.loadPromise = this.internalEnsureLoaded();
        }
        await this.loadPromise;
    }

    private async internalEnsureLoaded() {
        if (MapSettings.get()?.always_show_maps === true)
            await this._createMap();
        else
            this._createClickToActivate();
    }

    private async _createMap() {
        if (this.map != null)
            return;
        this.remove(this._clickToActivate);
        this._clickToActivate = null;

        const vendor = this.resolveVendor();
        if (vendor == null || vendor === MapVendor.GOOGLE) {
            this.map = new GoogleMap({ id: "map", parentMap: this, fillRow: true, fillHeight: true, disableDefaultUI: this._disableDefaultUI});
            //this is gross, but we have to wait on the google map script to load
            //(and I couldn't figure out how to make the GoogleMap constructor async/await)
            await this.map.ensureLoaded();
        }
        else if (vendor == MapVendor.TRIMBLE) {
            this.map = new TrimbleMap({ id: "map", parentMap: this, fillRow: true, fillHeight: true,disableDefaultUI: this._disableDefaultUI });
            await this.map.ensureLoaded();
        } else if (vendor == MapVendor.NONE) {
            this.map = new NoMap({id: "map", fillRow: true, fillHeight: true});
            // await (this.map as NoMap);
        } else
            throw new Error("Unknown map vendor " + vendor);
        this.add(this.map);
        this.dataSource?.displayDataInBoundComponent(this);
    }

    public get vendor(): MapVendor {
        return this._vendor || this.getPropertyDefinitions().vendor.defaultValue;
    }

    public set vendor(value: MapVendor) {
        this._vendor = value;
    }

    private resolveVendor(): MapVendor {
        if (this._vendor === MapVendor.DEFAULT_DISTANCE_VENDOR)
            return this.getDefaultDistanceVendor();
        else if (this._vendor === MapVendor.DEFAULT_FINDNEAR_VENDOR)
            return this.getDefaultFindNearVendor();
        else if (this._vendor === MapVendor.DEFAULT_SINGLE_POINT_VENDOR)
            return this.getDefaultSinglePointVendor();
        else if (this._vendor === MapVendor.DEFAULT_MOVEMENT_MAP_VENDOR)
            return this.getDefaultMovementMapVendor();
        else if (this._vendor === MapVendor.DEFAULT_REQUEST_LOCATION_MAP_VENDOR)
            return this.getDefaultRequestLocationMapVendor();
        else if (this._vendor === MapVendor.DEFAULT_VIEW_MOVEMENT_MAP_VENDOR)
            return this.getDefaultViewMovementMapVendor();
        return this.vendor;
    }

    private getDefaultDistanceVendor(): MapVendor {
        return this.getMapVendorPerSetting(MapSettings.get()?.distance_calc_vendor);
    }

    private getDefaultFindNearVendor(): MapVendor {
        return this.getMapVendorPerSetting(MapSettings.get()?.findnear_vendor);
    }

    private getDefaultSinglePointVendor(): MapVendor {
        return this.getMapVendorPerSetting(MapSettings.get()?.single_point_vendor);
    }

    private getDefaultMovementMapVendor(): MapVendor {
        return this.getMapVendorPerSetting(MapSettings.get()?.movement_map_vendor);
    }

    private getDefaultRequestLocationMapVendor(): MapVendor {
        return this.getMapVendorPerSetting(MapSettings.get()?.request_location_vendor);
    }

    private getDefaultViewMovementMapVendor(): MapVendor {
        return this.getMapVendorPerSetting(MapSettings.get()?.view_movement_vendor);
    }

    private getMapVendorPerSetting(mapVendor: string): MapVendor {
        switch (mapVendor) {
            case 'G':
                return MapVendor.GOOGLE;
            case 'W':
                return MapVendor.TRIMBLE;
            case 'N':
                return MapVendor.NONE;
            default:
                return null;
        }

    }

    public get immediatelyActivateMap(): boolean {
        return this._immediatelyActivateMap || this.getPropertyDefinitions().immediatelyActivateMap.defaultValue;
    }

    public set immediatelyActivateMap(value: boolean) {
        if (value === this._immediatelyActivateMap)
            return;
        this._immediatelyActivateMap = value;
        if (value === true && this.contains(this._clickToActivate))
            this._createMap();
    }

    public set disableDefaultUI(value: boolean) {
        this._disableDefaultUI = value;
    }

    private _createClickToActivate() {
        this.remove(this._clickToActivate);
        const inSearchMode = this.dataSource?.mode === DataSourceMode.SEARCH;
        this._clickToActivate = new Label({
            fillRow: true,
            fillHeight: true,
            imageProps: {
                name: "designer/map",
                height: 32,
                width: 32,
                color: "primary",
                align: HorizontalAlignment.LEFT
            },
            align: HorizontalAlignment.CENTER,
            verticalAlign: VerticalAlignment.CENTER,
            borderWidth: 1,
            borderRadius: 4,
            borderColor: "strokeSecondary",
            fontSize: "xxxlarge",
            fontBold: true,
            text: inSearchMode !== true ? "Click to activate map" : "Map not available while searching",
            color: "primary",
            onClick: inSearchMode !== true ? () => this._createMap() : null
        });
        this.add(this._clickToActivate);
    }

    override dataSourceModeChanged(mode: DataSourceMode): void {
        if (this._clickToActivate != null)
            this._createClickToActivate();
    }

    override displayComponentData(data: ModelRow, allData: ModelRow[], rowIndex: number) {
        if (this._clickToActivate != null)
            return;
        this.ensureLoaded().then(() => {
            this.removeAllPins();
            if (data != null && this.latitudeField != null && this.longitudeField != null) {
                const displayArray = this.singleRecord === true ? [data] : allData;
                for (const disp of displayArray) {
                    const lat = disp.get(this.latitudeField);
                    const lng = disp.get(this.longitudeField);
                    if (lat != null && lng != null) {
                        const pin = new MapPin(lat, lng);
                        pin.data = disp;
                        this.addPin(pin);
                    }
                }
                this.fitPins();
            }
        });
    }

    override _serializeNonProps(): string {
        return "";
    }

    addPin(pin: MapPin) {
        if (this.map == null)
            return;
        this.pins.push(pin);
        this.fireListeners(_pinPlotListenerDef, () => new PinPlotEvent(pin));
        this.map.addPin(pin);
    }

    focusOnPin(pin: MapPin, mapPinIndex: number) {
        this.map?.focusOnPin(pin, mapPinIndex);
    }

    defocusOnPin(pin: MapPin, mapPinIndex: number) {
        this.map?.defocusOnPin(pin, mapPinIndex);
    }

    removeAllPins() {
        if (this.map == null)
            return;
        this.pins = [];
        this.map.removeAllPins();
    }

    fitPins() {
        this.map?.fitPins();
    }

    setZoom(level: number) {
        this.map?.setZoom(level);
    }

    setCenter(latitude: number, longitude: number) {
        this.map?.setCenter(latitude, longitude);
    }

    createRoute(routeData: any[], routeType?: any, hideMarkers?: boolean | false) {
        this.map?.createRoute(routeData, routeType, hideMarkers);
    }

    setStopZoomLevel(isTrafficEnabled: string, routeData: any[]) {
        this.map?.setStopZoomLevel(isTrafficEnabled, routeData);
    }

    clearRoute() {
        this.map?.clearRoute();

    }

    addPinPlotListener(listener: PinPlotListener) {
        this.addEventListener(_pinPlotListenerDef, listener);
    }

    removePinPlotListener(listener: PinPlotListener) {
        this.removeEventListener(_pinPlotListenerDef, listener);
    }

    pinNeedsClickListener(pin: MapPin): boolean {
        return pin.onClick != null || pin.layoutName != null || this.pinLayout != null;
    }

    setPopupProps(popupProps: Partial<PanelProps>) {
        this.popupProps = popupProps;
    }

    pinClicked(pin: MapPin, event: DomEvent) {
        log.debug(this, () => ["Pin clicked", pin, event]);
        if (pin.onClick != null)
            pin.onClick(new ClickEvent(this, event));
        // note this is checking for pin.layoutName === undefined so that pin.layoutName can be null
        // to allow a Map to have a pinLayout defined but a given pin can still have no layout shown when clicked
        const layoutName = pin.layoutName === undefined ? this.pinLayout : pin.layoutName;
        if (layoutName != null) {
            if (event instanceof PointerEvent) {
                if (this.popupProps == null)
                    this.popupProps = {};
                if (this.popupProps.left == null)
                    this.popupProps.left = event.clientX;
                if (this.popupProps.top == null)
                    this.popupProps.top = event.clientY;
            }
            this.showPinDetail(pin, layoutName, this.popupProps);
        }
    }

    showPinDetail(pin: MapPin, layoutName: string, popupProps: Partial<PanelProps>): void {
        const panel = new Panel({
            backgroundColor: "background2",
            borderShadow: true,
            borderRadius: 4,
            padding: 12, ...popupProps
        });
        const buttonClose = new Button({
            variant: ButtonVariant.round,
            color: "subtle.light",
            imageWidth: 14,
            imageHeight: 14,
            imageName: "x"
        });
        buttonClose.style.position = "absolute";
        buttonClose.style.right = "0px";
        buttonClose.style.top = "0px";
        buttonClose.zIndex = 3;
        let overlay: Overlay;
        buttonClose.addClickListener(() => Overlay.hideOverlay(overlay));
        panel.add(buttonClose);
        const layout = Layout.getLayout(layoutName, {fillRow: true, fillHeight: true});

        layout.addLayoutLoadListener(() => {
            if (layout.mainDataSource != null && (pin.data != null || pin.rows?.length > 0)) {
                if (pin.rows?.length > 0)
                    layout.mainDataSource.data = pin.rows;
                else
                    layout.mainDataSource.data = [pin.data];
                layout.mainDataSource.rowIndex = 0;
                layout.mainDataSource.displayDataInBoundComponents();
            }
            panel.add(layout);
            overlay = Overlay.showInOverlay(panel, {});
        });
    }

    override getPropertyDefinitions() {
        return MapPropDefinitions.getDefinitions();
    }

    override get serializationName() {
        return "map";
    }

    override get properName(): string {
        return "Map";
    }

    override getListenerDefs(): Collection<ListenerListDef> {
        return {
            ...super.getListenerDefs(),
            "pinPlot": {..._pinPlotListenerDef}
        };
    }

    public override discoverIncludedComponents(): Component[] {
        //return null here so that the components that make up the map aren't included
        return null;
    }
}

ComponentTypes.registerComponentType("map", Map.prototype.constructor, false, ["vendor"]);
