import {
    Alignment, Color, CurrencySettings, CurrencyUtil, DateUtil, DisplayType, DisplayValue, DOMUtil, ExtendedDateFormat,
    GeneralSettings, getApiMetadata, getRelativeDateString, getThemeColor, HorizontalAlignment,
    isRightAlignedDisplayType, JSUtil, LeftOrRightAlignment, ModelRow, Navigation, StringUtil, VerticalAlignment
} from "@mcleod/core";
import { ImageName } from "@mcleod/images";
import { toDate } from "date-fns-tz";
import { Component } from "../../base/Component";
import { ComponentListenerDefs } from "../../base/ComponentListenerDefs";
import { ComponentPropDefinition } from "../../base/ComponentProps";
import { ComponentTypes } from "../../base/ComponentTypes";
import { ListenerListDef } from "../../base/ListenerListDef";
import { QuickInfo } from "../../base/QuickInfoComponent";
import { ReflectiveDialogs } from "../../base/ReflectiveDialogs";
import { Image } from "../../components/image/Image";
import { ClickListener } from "../../events/ClickEvent";
import { DomEvent } from "../../events/DomEvent";
import { ImageProps } from "../image/ImageProps";
import { LabelPropDefinitions, LabelProps } from "./LabelProps";
import { LabelStyles } from "./LabelStyles";
import { ReadMoreType } from "./ReadMoreType";

export class Label extends Component implements LabelProps {
    private _linkClickHandler: ClickListener;

    private _allowSelect: boolean;
    private _text: string;
    private _collapseVerticallyWhenEmpty: boolean;
    private _explicitDisplayType: DisplayType;
    private _format: string;
    private _image: Image;
    private _imageName: ImageName;
    private _imageWidth: number;
    private _imageHeight: number;
    private _imageRotation: number;
    private _imageProps: Partial<ImageProps>;
    private _imageAlign: LeftOrRightAlignment;
    private _imageMarginLeft: number
    private _imageMarginRight: number
    private _imageColor: Color;
    private _imageStroke: Color;
    private _imageStrokeWidth: number;
    private _link: string;
    private _linkHardRefresh: boolean;
    private _verticalAlign: VerticalAlignment;
    private _wrap: boolean;
    private _readMoreDiv: HTMLSpanElement;
    private _readMoreLabel: Label;
    private _readMoreType: ReadMoreType;
    private _preReadMoreMaxHeight: string | number;
    private _readMoreCallback: () => void;
    private _textNode: Text;
    private _nullDisplayValue: string;
    private _busy: boolean;
    private busySpinner: Image;
    private _busySpinnerAlignment: LeftOrRightAlignment
    private _busyWhenDataSourceBusy: boolean;
    private _scrollX: boolean;
    private _scrollY: boolean;

    constructor(props?: Partial<LabelProps> | string) {
        if (typeof props === "string")
            props = { text: props };
        super("div", props);
        this._textNode = document.createTextNode("");
        this._element.appendChild(this._textNode);
        this.addClass("cmp-base");
        this.addClass(LabelStyles.base);
        this.setProps(props);
    }

    override setProps(props: Partial<LabelProps>) {
        super.setProps(props);
    }

    override displayComponentData(data: ModelRow, allData: ModelRow[], rowIndex: number) {
        if (this.field != null) {
            const value = data?.get(this.field);
            if (value != null) {
                if (this.displayType === DisplayType.DATETIME && this.format === ExtendedDateFormat.RELATIVE) {
                    //https://github.com/marnusw/date-fns-tz#time-zone-helpers
                    const dateInClientTz = toDate(value + " " + GeneralSettings.get().server_tz_id);
                    this.text = getRelativeDateString(dateInClientTz, { object: this, propertyToSet: "text" });
                    this.tooltip = DateUtil.formatDateTime(dateInClientTz, DateUtil.getUserDateTimeFormat());
                }
                else if (this.displayType === DisplayType.STRING && this.format != null)
                    this.text = DisplayValue.getFormattedDataString(this.format, data);
                else if (this.displayType === DisplayType.CURRENCY) {
                    this.text = CurrencyUtil.formatCurrency(data.get(this.field))
                    if (CurrencySettings.getSingleton().colorNegatives() === true) {
                        if (parseFloat(CurrencyUtil.removeFormatting(this.text)) < 0)
                            this.addClass(LabelStyles.negativeCurrency)
                        else
                            this.removeClass(LabelStyles.negativeCurrency)
                    }
                }
                else if (this.displayType === DisplayType.TEMPERATURE) {
                    this.text = `${data.get(this.field)}\u00B0`
                }
                else {
                    const metadataField = this._boundField != null ? this._boundField : this.dataSource?.getMetadataFromCache()?.output[this.field];
                    const dbDisplay = metadataField?.getDisplayValue(value);
                    if (dbDisplay != null)
                        this.text = dbDisplay;
                    else
                        this.text = DisplayValue.getDisplayValue(value, this.displayType, this.format);
                }
            }
            else
                this.text = null; //null out hard-coded text since there was no value in the bound field
        }
        if (this._nullDisplayValue == "hide") {
            this.visible = !StringUtil.isEmptyString(this.text);
        }
        else if (this.text == null) {
            this.text = this._nullDisplayValue;
        }
    }

    _setDesigner(value) {
        this.setClassIncluded(LabelStyles.noSelect, true);
    }

    get text(): string {
        return this._text;
    }

    set text(value: string) {
        if (this._designer == null || this.field == null) {
            const oldValue = this._text;
            this._text = value;
            this.syncText(oldValue);
            this._setDefaultImageMargins();
        }
    }

    private syncText(oldValue: string) {
        const value = this._text;
        if (this.displayType === DisplayType.LINK) {
            if (StringUtil.isEmptyString(this.link) && !StringUtil.isEmptyString(this.text)) {
                this.link = this.text;
            }
        }
        else if (this.displayType === DisplayType.EMAIL && !StringUtil.isEmptyString(this.text)) {
            this.link = "mailto:" + this.text;

        } else if (this.displayType === DisplayType.PHONE && !StringUtil.isEmptyString(this.text)) {
            this.link = `tel:${this.text}`;
            this.tooltip = this.tooltip ? this.tooltip : "Click to dial";
        }

        this._setTextNodeValue(value);
        this._evaluateReadMore();

        this._showDesignerBorderIfEmpty();
        this._matchIdToValue(oldValue, value);
    }

    private _setTextNodeValue(value: string) {
        if (!this.collapseVerticallyWhenEmpty && StringUtil.isEmptyString(value)) {
            this._element.style.display = "block"; // may need to consider blanking this back out when text is non-empty
            value = " ";
        }
        else if (value == null)
            value = "";
        this._textNode.textContent = value;
    }

    override getFocusTarget(): HTMLElement {
        return undefined;
    }

    get maxHeight(): string | number {
        return super.maxHeight;
    }

    set maxHeight(value: string | number) {
        if (value != null) {
            this._preReadMoreMaxHeight = value;
        }
        super.maxHeight = value;
        if (value != null && ((typeof value === "string" && DOMUtil.convertStyleAttrToNumber(value) !== 0) || value !== 0)) {
            this._element.style.alignItems = "flex-start";
        }
        else {
            this._element.style.alignItems = "center";
        }
        this._evaluateReadMore();
    }

    get readMoreType(): ReadMoreType {
        return this._readMoreType == null ? ReadMoreType.NONE : this._readMoreType;
    }

    set readMoreType(value: ReadMoreType) {
        if (value === this._readMoreType)
            return;
        this._readMoreType = value;
        this.addMountListener(() => this._evaluateReadMore());
    }

    private _determineReadMoreAction(): (() => void) {
        switch (this.readMoreType) {
            case ReadMoreType.INCREASE_HEIGHT:
                this._toggleReadMore();
                break;
            case ReadMoreType.SHOW_IN_DIALOG:
                this._showReadMoreInDialog();
                break;
            default:
                return null;
        }
    }

    private _toggleReadMore() {
        const curr = this._element.style.maxHeight;
        if (curr == null || curr === "")
            this.maxHeight = this._preReadMoreMaxHeight;
        else
            this.maxHeight = null;

        if (this._readMoreCallback != null)
            this._readMoreCallback();
    }

    get readMoreCallback(): () => void {
        return this._readMoreCallback;
    }

    set readMoreCallback(fn: () => void) {
        this._readMoreCallback = fn;
    }

    private _showReadMoreInDialog() {
        ReflectiveDialogs.showDialog(this.text);
    }

    private _evaluateReadMore() {
        if (this.readMoreType === ReadMoreType.NONE)
            return;
        // NOTE: calling element.clientHeight and scrollHeight will cause the DOM to reflow,
        // so if this Label is mounted and this is called repeatedly, this could cause performance problems
        if (!StringUtil.isEmptyString(this.text) && this._element.clientHeight < this._element.scrollHeight) {
            this._element.style.display = "block";
            this._readMoreDiv = document.createElement("span");
            this._readMoreDiv.style.position = "absolute";
            this._readMoreDiv.style.bottom = "0px";
            this._readMoreDiv.style.margin = "0px";
            this._readMoreDiv.style.right = "0px";
            this._readMoreDiv.style.width = "100%";
            this._readMoreDiv.style.backgroundColor = DOMUtil.getStyleValueFromElementOrAncestor("backgroundColor", this._element, getThemeColor("defaultBackground"));
            if (this._readMoreLabel == null)
                this._readMoreLabel = new Label({
                    fontSize: this.fontSize,
                    color: "primary",
                    onClick: (event) => this._determineReadMoreAction(),
                    margin: 0,
                    padding: 0,
                    align: HorizontalAlignment.CENTER
                });
            this._readMoreLabel.text = "Read More";
            this._readMoreDiv.appendChild(this._readMoreLabel.element);
            this._element.appendChild(this._readMoreDiv);
        }
        else if (this._readMoreLabel != null) {
            if (this._readMoreDiv != null) {
                this._readMoreDiv.removeChild(this._readMoreLabel.element);
                this._element.removeChild(this._readMoreDiv);
                this._readMoreDiv = null;
            }
            this._readMoreLabel.text = "Read Less";
            // this._element.parentElement.insertBefore(this._readMoreLabel.element, this._element.nextSibling);
            this._element.appendChild(this._readMoreLabel.element);
        }
    }

    public get collapseVerticallyWhenEmpty() {
        return this._collapseVerticallyWhenEmpty;
    }

    public set collapseVerticallyWhenEmpty(value: boolean) {
        this._collapseVerticallyWhenEmpty = value;
        this.syncText(this.text);
    }

    get align(): HorizontalAlignment {
        if (super.align === undefined && isRightAlignedDisplayType(this.displayType))
            return HorizontalAlignment.RIGHT;
        return super.align || HorizontalAlignment.LEFT;
    }

    set align(value: HorizontalAlignment) {
        super.align = value;
        this._syncAlign();
    }

    get allowSelect() {
        return this._allowSelect == null ? true : this._allowSelect;
    }

    set allowSelect(value) {
        this._allowSelect = value;
        this.setClassIncluded(LabelStyles.noSelect, !value)
    }

    get verticalAlign(): VerticalAlignment {
        return this._verticalAlign;
    }

    set verticalAlign(value: VerticalAlignment) {
        this._verticalAlign = value;
        if (value === VerticalAlignment.TOP)
            this._element.style.alignSelf = "flex-start";
        else if (value === VerticalAlignment.BOTTOM)
            this._element.style.alignSelf = "flex-end";
        else
            this._element.style.alignSelf = "";
    }

    override get field(): string {
        return super.field;
    }

    override set field(value: string) {
        if (this._designer != null) {
            if (value != null && this.text != null)
                this.text = null;
            this._textNode.textContent = value;
        }
        super.field = value;
        this._showDesignerBorderIfEmpty();
    }

    get format(): string {
        return this._format;
    }

    set format(value: string) {
        this._format = value;
    }

    get displayType(): DisplayType {
        let result = this._explicitDisplayType;
        if (result === undefined && this._boundField != null)
            result = this._boundField.displayType;
        return result ?? DisplayType.STRING;
    }

    set displayType(value: DisplayType) {
        this._explicitDisplayType = value;

        if (this.displayType === DisplayType.LINK) {
            if (StringUtil.isEmptyString(this.text) && !StringUtil.isEmptyString(this.link)) {
                this.text = this.link;
            }
            if (StringUtil.isEmptyString(this.link) && !StringUtil.isEmptyString(this.text)) {
                this.link = this.text;
            }
        }
        if (this.displayType === DisplayType.EMAIL && !StringUtil.isEmptyString(this.text)) {
            this.link = "mailto:" + this.text;
        }
        if (this.displayType === DisplayType.PHONE && !StringUtil.isEmptyString(this.text)) {
            this.link = `tel:${this.text}`;
            this.tooltip = this.tooltip ? this.tooltip : "Click to dial";
        }
        this._syncAlign();
    }

    private _syncAlign() {
        const value = this.align;
        let styleValue = "";
        if (value === HorizontalAlignment.RIGHT)
            styleValue = "right";
        else if (value === HorizontalAlignment.CENTER)
            styleValue = "center";
        this.style.justifyContent = styleValue; // if display is flex, it will use this style
        this.style.textAlign = styleValue; // if display is block, it will use this style
    }

    protected override _fieldBindingChanged(): void {
        super._fieldBindingChanged();
        if (this._boundField?.lookupModel != null) {
            getApiMetadata(this._boundField?.lookupModel).then(() => {
                // if there are prop getters that rely on the lookup model, add code to make them sync up here
                this["_syncHoverCallback"]();
            });
        }
        this._syncAlign();
        this["_syncHoverCallback"]();
    }

    get link() {
        return this._link;
    }

    set link(value) {
        this._link = value;

        if (this.displayType === DisplayType.LINK) {
            if (StringUtil.isEmptyString(this.text) && !StringUtil.isEmptyString(this.link)) {
                this.text = this.link;
            }
        }

        if (this._linkClickHandler != null)
            this.removeClickListener(this._linkClickHandler);
        if (value != null) {
            this._linkClickHandler = (event) => this.handleLinkClick(event);
            this.addClickListener(this._linkClickHandler);
        }
        this.setClassIncluded(LabelStyles.link, value != null);
    }

    get linkHardRefresh(): boolean {
        return this._linkHardRefresh;
    }

    set linkHardRefresh(value: boolean) {
        this._linkHardRefresh = value;
    }

    public applyLinkStyle(included: boolean = true) {
        this.setClassIncluded(LabelStyles.link, included);
    }

    public applyLinkHoverStyle(included: boolean = true) {
        this.setClassIncluded(LabelStyles.linkHover, included);
    }

    handleLinkClick(event) {
        const row = this?.dataSource?.activeRow;
        const link = DisplayValue.getFormattedDataString(this.link, row);
        if (link.startsWith("mailto:") || link.startsWith("tel:")) {
            window.location.href = this.link;
        }
        else if (link.indexOf("://") >= 0)
            window.open(link, "_blank");
        else
            Navigation.navigateTo(link, { hardRefresh: this.linkHardRefresh });
    }

    get wrap() {
        return this._wrap == null ? true : this._wrap;
    }

    set wrap(value) {
        this._wrap = value;
        if (value) {
            this._element.style.wordBreak = "break-word";
            this._element.style.whiteSpace = "pre-wrap";
            this._element.style.display = "";
        }
        else {
            this._element.style.wordBreak = "";
            this._element.style.whiteSpace = "nowrap";
            this._element.style.display = "inline-block";
        }
    }

    get imageName(): ImageName {
        return this._imageName;
    }

    set imageName(value: ImageName) {
        this._imageName = value;
        this._imageUpdated();
    }

    get imageColor(): Color {
        return this._imageColor;
    }

    set imageColor(value: Color) {
        this._imageColor = value;
        this._imageUpdated();
    }

    get imageWidth(): number {
        return this._imageWidth;
    }

    set imageWidth(value: number) {
        this._imageWidth = value;
        this._imageUpdated();
    }

    get imageHeight(): number {
        return this._imageHeight;
    }

    set imageHeight(value: number) {
        this._imageHeight = value;
        this._imageUpdated();
    }

    get imageRotation(): number {
        return this._imageRotation;
    }

    set imageRotation(value: number) {
        this._imageRotation = value;
        this._imageUpdated();
    }

    get imageStroke(): Color {
        return this._imageStroke;
    }

    set imageStroke(value: Color) {
        this._imageStroke = value;
        this._imageUpdated();
    }

    get imageStrokeWidth(): number {
        return this._imageStrokeWidth;
    }

    set imageStrokeWidth(value: number) {
        this._imageStrokeWidth = value;
        this._imageUpdated();
    }

    get imageAlign() {
        return this._imageAlign ?? this.getPropertyDefinitions().imageAlign.defaultValue;
    }

    set imageAlign(value: LeftOrRightAlignment) {
        if (value === this._imageAlign)
            return;
        this._imageAlign = value;
        this._imageUpdated();
    }

    get imageProps(): Partial<ImageProps> {
        return this._imageProps;
    }

    set imageProps(value: Partial<ImageProps>) {
        this._imageProps = value;
        this._imageUpdated();
    }

    get imageMarginRight(): number {
        return this._imageMarginRight;
    }

    set imageMarginRight(value: number) {
        this._imageMarginRight = value;
        this._imageUpdated();
    }

    get imageMarginLeft(): number {
        return this._imageMarginLeft;
    }

    set imageMarginLeft(value: number) {
        this._imageMarginLeft = value;
        this._imageUpdated();
    }

    get nullDisplayValue(): string {
        return this._nullDisplayValue;
    }

    set nullDisplayValue(value: string) {
        this._nullDisplayValue = value;
    }

    _imageUpdated() {
        if (this._imageName == null && this._imageProps?.name == null) {
            if (this._image != null && this._element.contains(this._image._element))
                this._element.removeChild(this._image._element);
            this._image = null;
        }
        else {
            const props = {
                name: this._imageName,
                width: this._imageWidth,
                height: this._imageHeight,
                color: this._imageColor,
                rotation: this._imageRotation,
                ...this.imageProps
            };
            if (this._imageStroke !== undefined) {
                props.stroke = this._imageStroke;
            }
            if (this._imageStrokeWidth !== undefined) {
                props.strokeWidth = this._imageStrokeWidth;
            }
            if (this._image == null)
                this._image = ComponentTypes.createComponentOfType("image", props);
            else
                this._image.setProps(props);
            this.placeImageElement(this._image, this.imageAlign);
            this._setDefaultImageMargins();
        }
        this._showDesignerBorderIfEmpty();
    }

    private placeImageElement(image: Image, alignment: LeftOrRightAlignment) {
        if (image._element.parentElement === this._element)
            this._element.removeChild(image._element);
        if (alignment !== Alignment.RIGHT) //default to left-aligned
            this._element.prepend(image._element);
        else
            this._element.append(image._element);
    }

    private _setDefaultImageMargins() {
        if (this._image == null || this.text == null)
            return;
        if (this._image.marginLeft == null)
            this._image.marginLeft = this._imageMarginLeft == null ? 4 : this._imageMarginLeft;
        if (this._image.marginRight == null)
            this._image.marginRight = this._imageMarginRight == null ? 4 : this._imageMarginRight;
    }

    _showDesignerBorderIfEmpty() {
        if (this._designer != null) {
            if (this.isEmpty() && StringUtil.isEmptyString(this._textNode.textContent))
                this._element.classList.add(LabelStyles.designerEmpty);
            else
                this._element.classList.remove(LabelStyles.designerEmpty);
        }
    }

    get busyWhenDataSourceBusy(): boolean {
        return this._busyWhenDataSourceBusy;
    }

    set busyWhenDataSourceBusy(value: boolean) {
        this._busyWhenDataSourceBusy = value;
    }

    get busy(): boolean {
        return this._busy == null ? false : this._busy;
    }

    set busy(value: boolean) {
        if (this.busy === value) {
            return;
        }
        this._busy = value;
        value === true ? this.displayBusySpinner() : this.hideBusySpinner();
    }

    private displayBusySpinner() {
        this.busySpinner = this.createBusySpinner();

        // If the Label already displays an image, replace that image with the busy spinner (so that the Label doesn't
        // change size).  Otherwise, display the busy spinner based on the busySpinnerAlignment.
        if (this._image?._element.parentElement === this._element)
            this._element.replaceChild(this.busySpinner._element, this._image._element);
        else
            this.placeImageElement(this.busySpinner, this.busySpinnerAlignment);
    }

    private hideBusySpinner() {
        // If the Label has an image to display, replace the busy spinner with that image.
        // Otherwise, remove the busy spinner.
        if (this._image != null)
            this._element.replaceChild(this._image._element, this.busySpinner._element);
        else
            this._element.removeChild(this.busySpinner._element);
        this.busySpinner = null;
    }

    private createBusySpinner(): Image {
        const labelHeight = DOMUtil.getElementHeight(this._element, true, true);
        // Fit the busy spinner image to the current size of the label, so that the label doesn't
        // change height when busy.
        return new Image({
            id: "label" + this.id + "-busySpinner",
            name: "spinner",
            rotate: true,
            color: this.color,
            height: labelHeight - 8,
            width: labelHeight - 8,
            marginLeft: 4,
            marginRight: 4
        });
    }

    public get busySpinnerAlignment(): LeftOrRightAlignment {
        return this._busySpinnerAlignment || this.getPropertyDefinitions().busySpinnerAlignment.defaultValue;
    }

    public set busySpinnerAlignment(value: LeftOrRightAlignment) {
        this._busySpinnerAlignment = value;
    }

    protected _getDefaultEventProp(): string {
        return "onDataDisplay";
    }

    override getPropertyDefinitions() {
        return LabelPropDefinitions.getDefinitions();
    }

    protected override determinePropertyDefaultValue(prop: ComponentPropDefinition): any {
        if (prop.name === "displayType")
            return this._boundField?.displayType || DisplayType.STRING;
        else if (prop.name === "align") {
            if (isRightAlignedDisplayType(this.displayType))
                return HorizontalAlignment.RIGHT;
            else
                return HorizontalAlignment.LEFT;
        }
        else if (prop.name === "wrap")
            return true;
        else if (prop.name === "quickInfoLayout") {
            return this["getQuickInfoLayoutDefaultValue"]();
        }
        return super.determinePropertyDefaultValue(prop);
    }

    protected override isPropSet(propName: string): boolean {
        if (propName === "allowSelect")
            return this._allowSelect != null;
        return super.isPropSet(propName);
    }

    override getSearchValues(): string[] {
        const result = [];
        result.push(this.text);
        return result;
    }

    override get serializationName() {
        return "label";
    }

    override get properName(): string {
        return "Label";
    }

    override getBasicValue(): any {
        return this.text;
    }

    get quickInfoLayout(): string {
        return this["_mixin-QuickInfo-quickInfoLayout"];
    }

    set quickInfoLayout(value: string) {
        this["_mixin-QuickInfo-quickInfoLayout"] = value;
    }

    override domEventFiringListeners(event: DomEvent, eventDef: ListenerListDef): boolean {
        if (eventDef === ComponentListenerDefs.click && this.busy)
            return false;
        return super.domEventFiringListeners(event, eventDef)
    }

    public override isEmpty(): boolean {
        return StringUtil.isEmptyString(this.text) && this.imageName == null;
    }

    public hasText(): boolean {
        return StringUtil.isEmptyString(this.text) === false;
    }

    public get scrollX(): boolean {
        return this._scrollX;
    }

    public set scrollX(value: boolean) {
        this._scrollX = value;
        this.style.overflowX = value ? "auto" : "";
    }

    public get scrollY(): boolean {
        return this._scrollY;
    }

    public set scrollY(value: boolean) {
        this._scrollY = value;
        this.style.overflowY = value ? "auto" : "";
    }

    override _applyEnabled(value: boolean): void {
        this.commonApplyEnabled(value);
    }
}

JSUtil.applyMixins(Label, [QuickInfo]);
ComponentTypes.registerComponentType("label", Label.prototype.constructor);
