import { ArrayUtil, DynamicLoader } from "@mcleod/core";
import { ValidationResult } from "../../base/ValidationResult";
import { DataSourceMode } from "../../databinding/DataSource";
import { DropdownItem } from "./DropdownItem";
import { Textbox } from "./Textbox";

export abstract class AbstractInputParser {

    private _displayValue: string;
    private _dataValue: any;

    abstract getDisplayValue(): string;
    abstract getDataValue(): any;
    abstract validate(checkRequired: boolean, showErrors?: boolean): ValidationResult;

    constructor(public textbox: Textbox, displayValue?: string) {
        this._displayValue = displayValue;
    }

    public get displayValue(): string {
        if (this._displayValue == null) {
            if (this.textbox.hasLookupModel() || this.textbox.items != null) {
                this._displayValue = this.textbox.text;
            } else {
                this._displayValue = this.getDisplayValue();
            }
        }
        return this._displayValue ??= "";
    }

    public get dataValue(): any {
        if (this._dataValue == null) {
            if (this.textbox.hasLookupModel()) {
                this._dataValue = AbstractInputParser.getLookupModelResultData(this.textbox);
                if (this._dataValue == null && this.textbox.lookupModelAllowFreeform) {
                    this._dataValue = this.getDataValue();
                }
            } else if (this.textbox.items != null) {
                this._dataValue = AbstractInputParser.getDropdownResultData(this.textbox);
            } else {
                this._dataValue = this.getDataValue();
            }
        }
        return this._dataValue;
    }

    parseSelectedItems(input: any): DropdownItem[] {
        const stringValue = this.cleanInput(input);
        const { valueDelimiter, allowDropdownMultiSelect } = this.textbox;

        const values = allowDropdownMultiSelect && stringValue.includes(valueDelimiter)
            ? stringValue.split(valueDelimiter).map(v => v.trim())
            : [stringValue];

        return DropdownItem.findMatchingValues(values, this.textbox.resolveItems(true));
    }

    // We shouldn't need this method, but there was an instance where PropertiesTable
    // passed in a DataSource object  (Layout.mainDataSource prop) to parseSelectedItems.
    private cleanInput(value: any): string {
        if (typeof value === "number")
            return "" + value;
        else if (typeof value === "string")
            return value;
        else
            return "";
    }

    static getDropdownResultData(textbox: Textbox): string {
        const { selectedItems, valueDelimiter } = textbox;

        if (selectedItems == null || selectedItems?.length <= 1) {
            return ArrayUtil.getFirstElement(selectedItems)?.value;
        }

        return DropdownItem.getValuesAsString(selectedItems, valueDelimiter);
    }

    static getLookupModelResultData(textbox: Textbox): any {
        const { lookupModelData, lookupModelResultField } = textbox;
        if (ArrayUtil.isEmptyArray(lookupModelData))
            return null;
        else if (lookupModelData.length === 1)
            return lookupModelData[0].get(lookupModelResultField);
        let prefix = "";
        if (textbox.inDataSourceMode(DataSourceMode.SEARCH, DataSourceMode.NONE)) {
            prefix += "in ";
        }
        const resultString = lookupModelData.map(row => row.get(lookupModelResultField)).join(",");
        return prefix + resultString;
    }

    static createParser(textbox: Textbox, displayValue?: string): AbstractInputParser {
        if (textbox.inDataSourceMode(DataSourceMode.SEARCH)) {
            const parserClass = DynamicLoader.getClassForPath("components/components/textbox/SearchInputParser");
            return new parserClass(textbox);
        } else {
            const parserClass = DynamicLoader.getClassForPath("components/components/textbox/InputParser");
            return new parserClass(textbox, displayValue);
        }
    }
}
