import { Component, PanelProps } from "../..";
import { ComponentPropDefinitionUtil, ComponentPropDefinitions } from "../../base/ComponentProps";
import { PanelPropDefinitions } from "../panel/PanelProps";


export interface DropTargetPanelProps extends PanelProps {
    enclosingComponent: Component;
}

let dropTargetPanelPropDefs: ComponentPropDefinitions;

export class DropTargetPropDefinitions {
    public static getDefinitions(): ComponentPropDefinitions {
        if (dropTargetPanelPropDefs == null) {
            dropTargetPanelPropDefs = {
                ...PanelPropDefinitions.cloneDefs(),
                ...ComponentPropDefinitionUtil.getDataBoundPropertyDefinitions()
            };
            ComponentPropDefinitionUtil.populateComponentPropNames(dropTargetPanelPropDefs);
        }
        return dropTargetPanelPropDefs;
    }
}
