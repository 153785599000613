import { DOMUtil, LogManager } from "@mcleod/core";
import { Panel } from "../components/panel/Panel";
import { ScreenStack } from "../components/panel/ScreenStack";
import { Overlay } from "../page/Overlay";
import { KeyListener } from "./KeyEvent";
import { KeyHandler } from "./KeyHandler";
import { KeyHandlerGroup } from "./KeyHandlerGroup";

const log = LogManager.getLogger("components/events/GlobalKeyListener");

const globalKeyHandlers = new KeyHandlerGroup();
let docKeyListener: (event: KeyboardEvent) => void;
ScreenStack.addListener(() => syncDocumentKeyListener());

export function refreshKeyListeners(comp: Panel | HTMLElement) {
    const currentScreen = ScreenStack.getCurrentScreen();
    const innerElement = (comp instanceof Panel) ? comp._element : comp;
    if (currentScreen != null && DOMUtil.isOrContains(currentScreen._element, innerElement)) {
        currentScreen.fillKeyHandlerGroup(true);
        syncDocumentKeyListener();
        return;
    }
    const currentOverlay = ScreenStack.getNewestOverlay();
    if (currentOverlay != null && DOMUtil.isOrContains(currentOverlay.getOverlayContent()._element, innerElement)) {
        currentOverlay.fillKeyHandlerGroup(true);
        syncDocumentKeyListener();
        return;
    }
}

export function addGlobalKeyHandler(keyHandler: KeyHandler) {
    if (keyHandler.key == null)
        keyHandler.key = "ALL";
    globalKeyHandlers.addKeyHandler(keyHandler);
    syncDocumentKeyListener();
}

export function removeGlobalKeyHandler(listener: KeyListener) {
    globalKeyHandlers.removeKeyHandler(listener);
    syncDocumentKeyListener();
}

export function clearGlobalKeyHandlers() {
    globalKeyHandlers.clear();
    syncDocumentKeyListener();
}

export function syncDocumentKeyListener() {
    if (globalKeyHandlers.isEmpty() &&
        ScreenStack.getCurrentScreen()?.getKeyHandlerGroup().isEmpty() &&
        ScreenStack.getOldestSnackbar()?.getKeyHandlerGroup().isEmpty()) {
        if (docKeyListener != null) {
            document.removeEventListener("keydown", docKeyListener);
            docKeyListener = null;
        }
    }
    else if (docKeyListener == null) {
        docKeyListener = _handleKey;
        document.addEventListener("keydown", docKeyListener);
    }
}

function _handleOverlayKey(overlay: Overlay, event: KeyboardEvent) {
    //if the overlay's component has a Snackbar visible, let the oldest Snackbar handle the key if it can
    let handledBySnackbar = false;
    const oldestSnackbar = ScreenStack.getOldestSnackbar();
    if (oldestSnackbar != null) {
        handledBySnackbar = oldestSnackbar.getKeyHandlerGroup().handleKey(event);
        if (handledBySnackbar === true)
            return;
    }
    let handledByToast = false;
    const newestToast = ScreenStack.getNewestToast();
    if (newestToast != null) {
        handledByToast = newestToast.getKeyHandlerGroup().handleKey(event);
        if (handledByToast === true)
            return;
    }
    const handled = overlay.getKeyHandlerGroup().handleKey(event);
    if (handled === true)
        return;
    globalKeyHandlers.handleKey(event);
}

function _handleNonModalDialogKey(dialog: Panel, event: KeyboardEvent) {
    const handled = dialog.getKeyHandlerGroup().handleKey(event);
    if (handled === true)
        return;
    globalKeyHandlers.handleKey(event);
}

function _handleKey(event: KeyboardEvent) {
    //if an overlay is being displayed, that trumps everything
    //the key can be handled by that screen or by a global key listener
    const currentOverlay = ScreenStack.getNewestOverlay();
    if (currentOverlay != null) {
        _handleOverlayKey(currentOverlay, event);
        return;
    }

    //next, let the newest non-modal dialog handle the key
    //the key can be handled by that dialog or by a global key listener
    const currentNonModalDialog = ScreenStack.getNewestNonModalDialog();
    if (currentNonModalDialog != null) {
        _handleNonModalDialogKey(currentNonModalDialog, event);
        return;
    }

    //next, let the oldest snackbar handle the key
    let handledBySnackbar = false;
    const oldestSnackbar = ScreenStack.getOldestSnackbar();
    if (oldestSnackbar != null) {
        handledBySnackbar = oldestSnackbar.getKeyHandlerGroup().handleKey(event);
        if (handledBySnackbar === true)
            return;
    }

    //next, let the newest toast handle the key
    let handledByToast = false;
    const newestToast = ScreenStack.getNewestToast();
    if (newestToast != null) {
        handledByToast = newestToast.getKeyHandlerGroup().handleKey(event);
        if (handledByToast === true)
            return;
    }

    //if we made it this far, allow the current screen to handle the key,
    //unless there is a snackbar or toast present (in which case we want to go
    //straight to the global key handlers)
    if (oldestSnackbar == null && newestToast == null) {
        let handledByScreen = false;
        const currentScreen = ScreenStack.getCurrentScreen();
        if (currentScreen != null)
            handledByScreen = currentScreen.getKeyHandlerGroup().handleKey(event);
        if (handledByScreen === true)
            return;
    }

    //finally, let global key handlers try to handle the key press
    globalKeyHandlers.handleKey(event);
}
