import { Auth0Client, createAuth0Client } from '@auth0/auth0-spa-js';
import { CommonDialogs } from '@mcleod/common';
import { TenantGeneralSettings } from './settings/TenantGeneralSettings';

export class Auth0 {
    private static instance: Auth0Client;

    public static async login(): Promise<string> {
        const redirect = window.location.origin;
        const generalSettings = TenantGeneralSettings.get();
        const instance = await createAuth0Client({
            domain: generalSettings.auth0_domain,
            clientId: generalSettings.auth0_client_id,
            authorizationParams: {
                redirect_uri: redirect,
                audience: "https://" + generalSettings.auth0_domain + "/api/v2/",
            },
        });
        try {
            await instance.loginWithPopup(
                { authorizationParams: { prompt: "login" }}, 
                { timeoutInSeconds: 300 }
            );
            Auth0.instance = instance;
            return (await instance.getIdTokenClaims()).__raw;
        } catch (e) {
            if (e.message === "Timeout")
                CommonDialogs.showError("Login timed out");
            else if (e.message !== "Popup closed")
                CommonDialogs.showError("Login failed " + e.message);
            return null;
        }
    }

    public static async logout() {
        if (Auth0.instance != null)
            Auth0.instance.logout({ logoutParams: { returnTo: window.location.origin } });
    }
}