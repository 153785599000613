import { Button, Checkbox, HorizontalSpacer, Label, Layout, Panel, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutLogin extends Layout {
    buttonMFASubmit: Button;
    buttonSignIn: Button;
    checkKeepSignedIn: Checkbox;
    horizontalspacer1: HorizontalSpacer;
    labelAuthy1: Label;
    labelAuthy2: Label;
    labelAuthy: Label;
    labelComma1: Label;
    labelComma2: Label;
    labelComma3: Label;
    labelComma4: Label;
    labelDevices1: Label;
    labelDevices2: Label;
    labelDevices3: Label;
    labelDevices4: Label;
    labelGoogleAuthenticator2: Label;
    labelGoogleAuthenticator: Label;
    labelIntro: Label;
    labelMFAEnabled: Label;
    labelMFAInstructions: Label;
    labelMFASecret: Label;
    labelMicrosoftAuthenticator2: Label;
    labelMicrosoftAuthenticator: Label;
    labelOr2: Label;
    labelOr: Label;
    labelStep1: Label;
    labelStep2: Label;
    labelStep3: Label;
    labelWelcome: Label;
    panelContentLogin: Panel;
    panelContentMFA: Panel;
    panelHeader: Panel;
    panelLogo: Panel;
    panelMFASetup: Panel;
    panelMFAVerify: Panel;
    panelMain: Panel;
    panelPage: Panel;
    panelQRCode: Panel;
    textMFACode: Textbox;
    textPassword: Textbox;
    textUserId: Textbox;
}
