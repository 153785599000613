import { DesignableObject } from "../base/DesignableObject";
import { DropdownItem } from "../components/textbox/DropdownItem";
import { DomEvent } from "./DomEvent";
import { Event, EventListener } from "./Event";
import { TypedSelectionEvent } from "./TypedSelectionEvent";

export interface DropdownSelectionListener extends EventListener {
    (event: DropdownSelectionEvent): void;
}

export class DropdownSelectionEvent extends TypedSelectionEvent<DropdownItem> {

    constructor(component: DesignableObject, selectedItems?: DropdownItem | DropdownItem[],
        deselectedItems?: DropdownItem | DropdownItem[], domEvent?: Event | DomEvent) {
        super(component, selectedItems, deselectedItems, domEvent);
    }
}
