import { Image, Label, Layout, Panel, Switch } from "@mcleod/components";

export abstract class AutogenLayoutPanelIndividualSecure extends Layout {
    imageSwitchLeft: Image;
    imageSwitchRight: Image;
    labelDescription: Label;
    labelSecurityType: Label;
    panelAllowed: Panel;
    switchSecured: Switch;
}
