import { AuthToken, Navigation } from "@mcleod/core";
import { AutogenLayoutTenantConnect } from "./autogen/AutogenLayoutTenantConnect";
import { ClickEvent, TableSelectionEvent, DataSourceExecutionEvent } from "@mcleod/components";

export class TenantConnect extends AutogenLayoutTenantConnect {
    override onLoad() {
        this.sourceTenant.search();
    }

    connectToSelected() {
        const data = this.tableTenants.selectedRow?.data;
        if (data != null) {
            Navigation.navigateTo(data.get("home_url") + "?auth=" + AuthToken.get(), { newTab: true})
        }
    }

    /** This is an event handler for the onClick event of buttonConnect. */
    buttonConnectOnClick(event: ClickEvent) {
        this.connectToSelected();
    }

    /** This is an event handler for the onSelect event of tableTenants. */
    tableTenantsOnSelect(event: TableSelectionEvent) {
        this.buttonConnect.enabled = event.newRows.length > 0;
    }

    /** This is an event handler for the beforeExecution event of sourceTenant. */
    sourceTenantBeforeExecution(event: DataSourceExecutionEvent) {
        event.filter.connectable = true;
        event.filter.include_mcleod = this.switchIncludeMcLeod.checked;
    }

    /** This is an event handler for the onClick event of switchIncludeMcLeod. */
    switchIncludeMcLeodOnClick(event: ClickEvent) {
        this.sourceTenant.search();
    }

    /** This is an event handler for the onDblClick event of tableTenants. */
    tableTenantsOnDblClick(event: ClickEvent) {
        this.connectToSelected();
    }
}
