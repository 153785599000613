import { Layout, McLeodMainPageUtil, Panel, PanelStaticError } from "@mcleod/components";
import {
    Api, DynamicLoader, InstanceIdentifier, LogManager, ServiceAddresses, setDefaultErrorHandler, SettingsManager,
    Styles, UrlUtil
} from "@mcleod/core";
import { CommonDialogs } from "./CommonDialogs";
import { PageHeader } from "./PageHeader";
import { Router } from "./Router";
import { UnsavedDataListener } from "./UnsavedDataListener";

let mainPage: McLeodMainPage;
const log = LogManager.getLogger("common/McLeodMainPage");

Styles.make(null, {
    html: {
        boxSizing: "border-box",
        overflow: "hidden",
        height: "100%",
        minWidth: "100%"
    },
    body: {
        height: "100%",
        minWidth: "100%",
        fontSize: "14px",
        fontFamily: "Roboto",
        margin: "0px",
        color: "#000000DD"
    },
    "*": {
        boxSizing: "inherit"
    },
    "*:before": {
        boxSizing: "inherit"
    },
    "*:after": {
        boxSizing: "inherit"
    }
});

export interface McLeodMainPageModule {
    path: string;
    context: any;
}

export interface McLeodMainPageProps {
    apiContext: string;
    settingsRoot: string;
    unauthSettingsRoot: string;
    authSettingsRoot: string;
    modules: McLeodMainPageModule[];
    onError: any;
}

export class McLeodMainPage extends Panel {
    header: PageHeader;
    router: Router;
    doNotReportNextError: boolean;
    settingsRoot: string;
    unauthSettingsRoot: string;
    authSettingsRoot: string;

    constructor(props: Partial<McLeodMainPageProps>) {
        super({ padding: 0, fillRow: true, fillHeight: true, id: "main", ...props });
        mainPage = this;
        if (props?.apiContext != null)
            ServiceAddresses.setContextPath(props.apiContext);
        McLeodMainPageUtil.setMcLeodMainPage(mainPage);
        if (props == null)
            throw new Error("McLeodMainPage requires a properties object to be passed in.");
        if (props.modules == null)
            throw new Error("McLeodMainPage requires an array of routable modules.");
        for (const module of props.modules)
            DynamicLoader.mapPathsToClasses(module.path, module.context);
        DynamicLoader.mapPathsToClasses("components", require['context']("@mcleod/components/src", true));
        UnsavedDataListener.init();
        this._element.style.fontFamily = "Roboto, Helvetica, Arial, sans-serif";
        // this.element.style.color = "black";

        //clear client ID, in case it was copied to the new tab/window from an existing tab/window
        //this has to be done before the first api call is made from the new tab/window, which should
        //be the unauth-settings call below
        InstanceIdentifier._clear();

        const root = this.unauthSettingsRoot || this.settingsRoot || "lme/";
        Api.search(root + "unauth-settings", { origin: window.location.origin }, { sendAuth: false }, error => { }).then(async response => {
            SettingsManager.setSettings("general_settings", response, false);
            this.header = new PageHeader();
            this.router = new Router({ settingsRoot: this.authSettingsRoot || this.settingsRoot });
            this.add(this.router);
            this.router.displayRoute(window.location.pathname, UrlUtil.getPropsFromUrl(window.location.search));
        }).catch(err => {
            if (props.onError == null) {
                this.add(new PanelStaticError({
                    errorSummary: "Sorry, we can't connect you to the server.",
                    errorDetail: "The server may not be running or you may have lost network connectivity."
                }));
            } else {
                props.onError(this, err);
            }
            log.error(err);
        });

        setDefaultErrorHandler(error => {
            try {
                if (this.doNotReportNextError !== true)
                    CommonDialogs.showError(error);
            } finally {
                this.doNotReportNextError = false;
            }
        });
    }

    public static getInstance(): McLeodMainPage {
        return mainPage;
    }

    public static getMainLayout(): Layout {
        if (mainPage == null)
            return null;
        let result = mainPage.router?.components[0];
        if (!(result instanceof Layout) && result?.["layout"] != null)
            result = result["layout"];
        if (result instanceof Layout)
            return result;
        return null;
    }
}
