import { Color } from "@mcleod/core";
import { ImageName } from "@mcleod/images";
import { ComponentPropDefinitionUtil, ComponentPropDefinitions, ComponentProps } from "../../base/ComponentProps";
import { PropType } from "../../base/PropType";

export interface TreeProps extends ComponentProps {
    nodeLeafImageName: ImageName;
    nodeDefaultImageName: ImageName;
    nodeExpandedImageName: ImageName;
    nodeCollapsedImageName: ImageName;
    selectedNodeBackgroundColor: Color;
    selectedNodeColor: Color;
}

let treePropDefs: ComponentPropDefinitions;

export class TreePropDefinitions {
    public static getDefinitions(): ComponentPropDefinitions {
        if (treePropDefs == null) {
            treePropDefs = {
                ...ComponentPropDefinitionUtil.getComponentPropertyDefinitions(),
                nodeLeafImageName: { type: PropType.image, description: "This specifies the default image to use for leaf nodes in this Tree" },
                onChange: { type: PropType.event, category: "Events", addListenerMethod: "addChangeListener", removeListenerMethod: "removeChangeListener", eventSignature: "OnChange(event: ChangeEvent)", description: "This specifies a function to be called when the a new value is selected in the Tree." },
                selectedNodeBackgroundColor: { type: PropType.color, description: "This sets which color fills the background of Tree's selected node.", defaultValue: "primary.light" },
                selectedNodeColor: { type: PropType.color, description: "This sets which color will be used when rendering foreground of Tree's selected node.", defaultValue: "primary.reverse" },
            }
            ComponentPropDefinitionUtil.populateComponentPropNames(treePropDefs);
        }
        return treePropDefs;
    }
}
