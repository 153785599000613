import { DOMUtil, HorizontalAlignment, VerticalAlignment } from "@mcleod/core";
import { ClickEvent } from "../../events/ClickEvent";
import { LookupModelSearchEvent } from "../../events/LookupModelSearchEvent";
import { Overlay } from "../../page/Overlay";
import { Button } from "../button/Button";
import { ButtonVariant } from "../button/ButtonVariant";
import { Label } from "../label/Label";
import { Layout } from "../layout/Layout";
import { Panel } from "../panel/Panel";
import { Table } from "../table/Table";
import { Textbox } from "./Textbox";
import { DesignableObjectLogManager } from "../../logging/DesignableObjectLogManager";

enum TableMaxHeights {
    WITHOUT_SHOW_ALL_BUTTON = 320,
    WITH_SHOW_ALL_BUTTON = 280
}
const log = DesignableObjectLogManager.getLogger("components.LookupModelLayoutManager");

export class LookupModelLayoutManager {
    private textbox: Textbox;
    private layout: Layout;
    private layoutBottom: number;
    private table: Table;
    private _overlay: Overlay;
    private panelActions: Panel;
    private panelButtons: Panel;
    private buttonShowAllResults: Button;
    private buttonManualAdd: Button;
    private runningShowAllSearch = false;
    private emptyPanel: Panel;
    private emptyPanelLabel: Label;

    constructor(textbox: Textbox, layout: Layout, table: Table) {
        this.textbox = textbox;
        this.layout = layout;
        this.table = table;
        this.table.rowBreak = true;
        this.createPanelButtons();
        this.setDefaultSizing();
        this.createTableEmptyPanel();
    }

    public set overlay(value: Overlay) {
        this._overlay = value;
    }

    private createPanelButtons() {
        this.layout.setLastComponentRowBreak(true);
        this.panelButtons = Panel.createNoMarginNoPaddingPanel({ fillRow: true });
        this.textbox.dropdownAdditionalActions?.forEach(button => this.addDropdownAction(button));
        this.createManuallyAddButton();
        this.panelButtons.setLastComponentRowBreak(true);
        this.createButtonShowAllResults();
    }

    createManuallyAddButton() {
        if (this.textbox.manualAddLayout != null) {
            this.buttonManualAdd = new Button({
                caption: "Manually Add",
                color: "primary",
                variant: ButtonVariant.text,
                visible: false,
                rowBreak: false,
                onClick: () => this.textbox.showManualAddDropdown(null)
            });
            this.addDropdownAction(this.buttonManualAdd);
        }
    }

    private addDropdownAction(button: Button) {
        if (this.panelActions == null) {
            this.panelActions = Panel.createNoMarginNoPaddingPanel({ fillRow: true, align: HorizontalAlignment.RIGHT });
            this.layout.add(this.panelActions);
        }
        this.panelActions.add(button);
    }

    private createButtonShowAllResults() {
        this.buttonShowAllResults = new Button({
            caption: "Show All Results",
            color: "primary.reverse",
            backgroundColor: "primary",
            fillRow: true
        });
        this.buttonShowAllResults.addClickListener((event: ClickEvent) => {
            this.runningShowAllSearch = true;
            this.textbox.showAllLookupModelResults();
        });
        this.panelButtons.add(this.buttonShowAllResults);
    }

    checkButtonAvailability() {
        try {
            this.layoutBottom = this.layout.bounds.bottom;
            const showAllResultsVisible = this.textbox.lookupModelAllowShowAllResults === true &&
                this.table.dataSource.actualRowCount > this.table.dataSource.rowCount;
            showAllResultsVisible === true ? this.showButtons() : this.hideButtons();
            this.adjustLayoutPosition();
        }
        finally {
            this.runningShowAllSearch = false;
        }
    }

    private showButtons() {
        if (this.buttonManualAdd != null)
            this.buttonManualAdd.visible = this.table.dataSource.rowCount > 0;
        if (this.runningShowAllSearch === false) {
            this.layout.addIfNotPresent(this.panelButtons);
            this.adjustTableHeight(TableMaxHeights.WITH_SHOW_ALL_BUTTON);
        }
    }

    hideButtons() {
        this.layout.remove(this.panelButtons);
        this.adjustTableHeight(TableMaxHeights.WITHOUT_SHOW_ALL_BUTTON);
    }

    private setDefaultSizing() {
        this.configureTableSize();
        this.table.fillHeight = false;
        this.adjustTableHeight(TableMaxHeights.WITHOUT_SHOW_ALL_BUTTON);
    }

    private adjustTableHeight(tableMaxHeight: TableMaxHeights) {
        this.table.height = null;
        if (tableMaxHeight === TableMaxHeights.WITH_SHOW_ALL_BUTTON && this.dropdownAboveTextbox() === true) {
            const overlayContentHeight = DOMUtil.getElementHeight(this._overlay?.overlayContentFirstElement);
            if (overlayContentHeight > 0) {
                this.table.maxHeight = overlayContentHeight - 40;
                return;
            }
        }
        this.table.maxHeight = tableMaxHeight;
    }

    /**
     * This method allows the top of the layout (displayed in an overlay) to be moved down.  This is needed when the
     * lookup model dropdown is displayed above the textbox, and it gets smaller as a result of removing the buttons
     * panel.
     */
    private adjustLayoutPosition() {
        if (this.dropdownAboveTextbox() === true)
            this.layout.top = this.layoutBottom - this.layout.bounds.height;
    }

    private dropdownAboveTextbox(): boolean {
        const layoutRect = this.layout.bounds;
        const textboxRect = this.textbox.bounds;
        if (layoutRect == null || textboxRect == null)
            return false;
        return layoutRect.top < textboxRect.top;
    }

    private configureTableSize() {
        this.layout.doWhileOffScreen(() => {
            if (this.table.maxHeight != null)
                return;
            let panelEffectiveHeight = DOMUtil.getElementHeight(this.layout._element);
            panelEffectiveHeight = Math.max(panelEffectiveHeight, Number(this.layout.maxHeight));
            let nonTableOrListHeight = 0;
            for (const row of this.layout.rows) {
                if (DOMUtil.isOrContains(row, this.table._element) !== true)
                    nonTableOrListHeight += DOMUtil.getElementHeight(row);
            }
            if (panelEffectiveHeight !== 0 && nonTableOrListHeight !== 0)
                this.table.maxHeight = panelEffectiveHeight - nonTableOrListHeight;
        });
    }


    private createTableEmptyPanel() {
        this.emptyPanel = new Panel({ fillHeight: true, fillRow: true });
        this.emptyPanelLabel = new Label({
            fillRow: true,
            height: "100%",
            fontSize: "large",
            color: "subtle.light",
            align: HorizontalAlignment.CENTER,
            verticalAlign: VerticalAlignment.TOP,
            text: this.table.emptyCaption,
            paddingTop: 12,
            paddingBottom: 12
        });
        this.emptyPanel.add(this.emptyPanelLabel);

        if (this.textbox.manualAddLayout) {
            const manualAddButton = new Button({
                caption: "Manually Add", color: "primary", align: HorizontalAlignment.RIGHT, variant: ButtonVariant.text,
                onClick: (event: ClickEvent) => {
                    this.textbox.showManualAddDropdown("");
                }
            });
            this.emptyPanelLabel.align = HorizontalAlignment.LEFT;
            this.emptyPanelLabel.rowBreak = false;
            this.emptyPanel.add(manualAddButton);
        }
        if (this.textbox.lookupModelAllowSearchAll === true) {
            const searchAllLabel = new Label({
                text: "Show all records", color: "primary", fillRow: true,
                height: "100%",
                fontSize: "large",
                align: HorizontalAlignment.CENTER,
                verticalAlign: VerticalAlignment.TOP,
                paddingTop: 12,
                paddingBottom: 12
            });
            searchAllLabel.addClickListener((event: ClickEvent) => {
                this.textbox._input.value = "";
                this.textbox.hideDropdown(true);
                this.textbox.showLookupModelDropdown("");
            });
            this.emptyPanel.add(searchAllLabel);
        }
        this.table.emptyComponent = this.emptyPanel;
    }

    syncTableEmptyPanel(event: LookupModelSearchEvent) {
        this.emptyPanelLabel.text = event?.preventReason ?? this.table.emptyCaption;
        this.emptyPanel.components.forEach(comp => {
            if (comp !== this.emptyPanelLabel) {
                comp.visible = event?.defaultPrevented === false;
            }
        });
    }

   async search(event: LookupModelSearchEvent): Promise<any> {
        this.syncTableEmptyPanel(event)
        if (event?.defaultPrevented === true) {
            log.debug(event.target, "Lookup model search was prevented");
            return;
        }

        let loadStartTime = null;
        if (log.isDebugEnabled) {
            log.debug(event.target, "Lookup model load started");
            loadStartTime = new Date().getTime();
        }
        await this.table.dataSource.search(event.filter, null, this.textbox.lookupModelFieldListInfo)
        if (log.isDebugEnabled) {
            const loadTime = new Date().getTime() - loadStartTime;
            log.debug(event.target, "Lookup model load time: %o", loadTime);
        }
    }
}
