import { ComponentPropDefinitionUtil, ComponentPropDefinitions, ComponentProps } from "../../base/ComponentProps";
import { PropType } from "../../base/PropType";
import { SelectionMode } from "../../base/SelectionMode";
import { List, ListItemType } from "./List";
import { ListVariant } from "./ListVariant";

export interface ListProps extends ComponentProps {
    items: ListItemType[];
    itemsCreator: ((list: List) => ListItemType[]);
    scrollY: boolean;
    selectionMode: SelectionMode;
    variant: ListVariant;
}

let listPropDefs: ComponentPropDefinitions;

export class ListPropDefinitions {
    public static getDefinitions(): ComponentPropDefinitions {
        if (listPropDefs == null) {
            listPropDefs = {
                ...ComponentPropDefinitionUtil.getComponentPropertyDefinitions(),
                selectionMode: { type: PropType.string, defaultValue: SelectionMode.SINGLE, dropdownProps: { items: [...Object.values(SelectionMode)] }, description: "Specifies whether the user is allowed to select one or more rows in the List." },
                scrollY: { type: PropType.bool, defaultValue: true },
                onSelect: { type: PropType.event, category: "Events", addListenerMethod: "addSelectionListener", removeListenerMethod: "removeSelectionListener", description: "This specifies a function to be called when an item in the list is selected." }, // passes selectedItem, selectedIndex, and the actual component that was selected (Label created internally if items was just a list of strings)
                variant: { type: PropType.string, defaultValue: ListVariant.POPUP, dropdownProps: { items: [...Object.values(ListVariant)] }, description: "Specifies if the list is displayed in/as a popup, or if it will remain visible on the screen (persistent).  This impacts how items are highlighted/selected." }
            };
            ComponentPropDefinitionUtil.populateComponentPropNames(listPropDefs);
        }
        return listPropDefs;
    }
}
