import {
    Alignment, Api, HorizontalAlignment, Keys, LogManager, ModelRow, StringUtil, VerticalAlignment
} from "@mcleod/core";
import {
    Button, ButtonVariant, ChangeEvent, Checkbox, Label, List, Overlay, Panel, PanelProps, Snackbar, Table,
    TableColumn, Textbox
} from "@mcleod/components";
import { CommonDialogs } from "@mcleod/common";
import { AbstractModelDesigner } from "./AbstractModelDesigner";
import { ModelTableDefinition } from "./ModelDesignerTypes";
import { PanelModelTableConnector } from "./PanelModelTableConnector";

const log = LogManager.getLogger("designer.model.ModelTable");

const tableWidth = 420;
const tableSpacing = 16;

interface Field {
    master_field: string;
    master_table: string;
    field_name: string;
}

interface ModelTableProps extends PanelProps {
    parentTable: PanelModelTable;
}

export class PanelModelTable extends Panel {
    _def: Partial<ModelTableDefinition>;
    designer: AbstractModelDesigner;
    panelMain: Panel;
    panelTable: Panel;
    labelTable: Label;
    buttonEdit: Button;
    parentTable: PanelModelTable;
    textTable: Textbox;
    detailPanel: Panel;
    detailContentPanel: Panel;
    buttonExpandFields: Button;
    tableFields: Table;
    textField: Textbox;
    textAlias: Textbox;
    parentConnector: PanelModelTableConnector;
    childConnector: Panel;
    checkQualifyFields: Checkbox;
    checkEditable: Checkbox;
    checkAutoAddLookupModelJoins: Checkbox;
    respFilteringPanel: Panel;
    labelRespFilteringHeader: Label;
    textRecordType: Textbox;
    textRespFilteringControlType: Textbox;
    textRespFilteringContactType: Textbox;
    textRespFilteringIdField: Textbox;
    textNestField: Textbox;
    children: PanelModelTable[];
    availableFields: Field[];
    lastSearch: any;

    constructor(designer: AbstractModelDesigner, props?: Partial<ModelTableProps>) {
        super(props);
        this.designer = designer;
        this.children = [];
        this.setProps({ id: "ModelTable", align: HorizontalAlignment.CENTER, padding: 0, wrap: false, verticalAlign: VerticalAlignment.TOP });
        this._def = { fields: [], joinConditions: [], joins: [] };
        this.panelMain = this.createMainPanel();
        this.panelTable = new Panel({ fillRow: true, padding: 0, verticalAlign: VerticalAlignment.CENTER });
        const tableProps = { fillRow: true, fontBold: true, fontSize: "large", color: "primary", marginLeft: 4, rowBreak: false };
        this.textTable = this.createTextTable(tableProps);
        this.labelTable = new Label(tableProps);
        this.buttonEdit = new Button({ variant: ButtonVariant.round, tooltip: "Edit this table's properties", imageName: "pencil", color: "subtle.darker", rowBreak: false });
        this.buttonEdit.addClickListener(event => this.toggleExpand());
        if (this.parentTable != null)
            this.parentConnector = new PanelModelTableConnector(this.parentTable, this);
        this.panelMain.add(this.panelTable);
        this.add(this.panelMain);
        this.createDetailPanel();
        this.def = this._def;
    }

    get def(): Partial<ModelTableDefinition> {
        return this._def;
    }

    set def(value: Partial<ModelTableDefinition>) {
        this._def = value;
        if (value != null) {
            if (value.fields == null)
                value.fields = [];
            if (value.joins == null)
                value.joins = [];
            if (value.joinConditions == null)
                value.joinConditions = [];
            this.textTable.text = value.tableName;
            this.textAlias.text = value.alias;
            this.textNestField.text = value.nestField;
            this.lookupTable(false);
            if (this.parentConnector != null)
                this.parentConnector.refreshView();
            if (value.fields != null)
                for (let i = 0; i < value.fields.length; i++)
                    if (typeof value.fields[i] === "string")
                        value.fields[i] = { name: value.fields[i] as string };
            if (value.joins != null)
                for (const joinDef of value.joins)
                    this.addChild(false).def = joinDef;
            this.refreshView();
        }
        this.displayFields();
    }

    createMainPanel() {
        return new Panel({
            width: tableWidth,
            padding: 0,
            borderWidth: 1,
            borderColor: "#AAA",
            borderRadius: 6,
            borderShadow: true,
            borderLeftColor: "primary.darker",
            marginLeft: tableSpacing,
            marginRight: tableSpacing,
            borderLeftWidth: 10,
            backgroundColor: "defaultBackground"
        });
    }

    createTextTable(tableProps): Textbox {
        const result = new Textbox({ placeholder: "Set table name", captionVisible: false, borderWidth: 0, dropDownAnchor: this.panelTable, ...tableProps, items: () => this.designer.tableNames });
        result.addChangeListener(event => this.def.tableName = event.target.text);
        result.addBlurListener(event => this.lookupTable(false));
        result.addKeyUpListener(event => {
            if (event.key === Keys.ENTER && this.parentTable != null && this.def.joinConditions.length === 0) {
                this.lookupTable(true);
                this.parentConnector.addJoinConditionEditor(0);
                this.refreshView();
            }
            else if (event.key === '+')
                this.addChild(true);
        });
        return result;
    }

    createDetailPanel() {
        this.detailPanel = new Panel({ fillRow: true, borderTopWidth: 1, padding: 0, borderTopColor: "strokeSecondary", marginBottom: 16 });
        this.detailContentPanel = new Panel({ fillRow: true, rowBreak: false, padding: 0, verticalAlign: VerticalAlignment.CENTER });
        this.buttonExpandFields = new Button({ imageName: "chevron", tooltip: "Show the fields for this table", imageRotation: -90, borderWidth: 0, color: "subtle.darker", padding: 4, borderRadius: "50%" });
        this.buttonExpandFields.addClickListener(event => this.expandFields());
        this.tableFields = new Table({ headerVisible: false, height: 220, padding: 0, rowBorderBottomWidth: 0, marginBottom: 12 })
        this.tableFields.addColumn(new TableColumn({ headingDef: { caption: "Field" }, cell: { field: "name" } }), true, false);
        this.tableFields.addColumn(new TableColumn({ headingDef: { caption: "Required", width: 60 }, cell: { field: "required" } }), false, false);
        this.tableFields.addColumn(new TableColumn({ headingDef: { caption: "Alias" }, cell: { field: "alias" } }), false, true);
        this.textField = new Textbox({ placeholder: "Add field", captionVisible: false, fillRow: true, marginLeft: 4, marginRight: 8, rowBreak: false, items: () => this.getFieldNames() });
        this.textField.addKeyUpListener(event => this.fieldKeyUp(event));
        this.detailPanel.add(this.detailContentPanel);
        this.textAlias = new Textbox({ caption: "Table alias", fillRow: true, rowBreak: false });
        this.textAlias.addChangeListener(event => this.def.alias = event.target.text);
        this.textNestField = new Textbox({ caption: "Nest inside field", fillRow: true });
        this.textNestField.visible = this.parentTable != null;
        this.textNestField.addChangeListener(event => this.def.nestField = event.target.text);
        this.checkQualifyFields = new Checkbox({ caption: "Qualify fields with table name" });
        this.checkQualifyFields.addChangeListener(event => this.def.qualifyFields = event.newValue);
        this.checkEditable = new Checkbox({ caption: "This join is editable" });
        this.checkEditable.addChangeListener(event => this.def.editable = event.newValue);
        this.checkAutoAddLookupModelJoins = new Checkbox({ caption: "Automatically add lookup model joins" });
        this.checkAutoAddLookupModelJoins.addChangeListener(event => this.def.autoAddLookupModelJoins = event.newValue);
        this.detailContentPanel.add(this.textAlias);
        this.detailContentPanel.add(this.textNestField);
        this.detailContentPanel.add(this.tableFields);
        this.detailContentPanel.add(this.textField);
        this.detailContentPanel.add(this.buttonExpandFields);
        this.detailContentPanel.add(new Panel({ height: 1, padding: 0, marginTop: 8, marginBottom: 8, fillRow: true, backgroundColor: "subtle.light" }));
        this.detailContentPanel.add(this.checkQualifyFields);
        if (this.parentTable != null)
            this.detailContentPanel.add(this.checkEditable);
        this.detailContentPanel.add(this.checkAutoAddLookupModelJoins);
        this.setupRespFilteringPanel();
        this.detailContentPanel.add(this.respFilteringPanel);
    }

    private setupRespFilteringPanel() {
        this.respFilteringPanel = new Panel({ fillRow: true, padding: 0, paddingTop: 8, margin: 0, marginTop: 8, borderTopWidth: 1, borderTopColor: "strokeSecondary", verticalAlign: VerticalAlignment.CENTER });
        this.labelRespFilteringHeader = new Label({ text: "Responsibility filtering", fontBold: true, marginLeft: 4 });
        this.textRecordType = new Textbox({ caption: "Record type", fillRow: true, marginLeft: 4, marginRight: 8, items: () => this.designer.recordTypes });
        this.textRecordType.addChangeListener(event => this.handleRespSelection("recordType", event));
        this.textRespFilteringControlType = new Textbox({ caption: "Control type", fillRow: true, marginLeft: 4, marginRight: 8, items: () => this.designer.respFilteringControlTypes });
        this.textRespFilteringControlType.addChangeListener(event => this.handleRespSelection("respFilteringControlType", event));
        this.textRespFilteringContactType = new Textbox({ caption: "Type", fillRow: true, marginLeft: 4, marginRight: 8, items: () => this.designer.respFilteringContactTypes });
        this.textRespFilteringContactType.addChangeListener(event => this.handleRespSelection("respFilteringContactType", event));
        this.textRespFilteringIdField = new Textbox({ caption: "ID field", fillRow: true, marginLeft: 4, marginRight: 8 });
        this.textRespFilteringIdField.addChangeListener(event => this.def.respFilteringIdField = event.target.text);
        this.respFilteringPanel.add(this.labelRespFilteringHeader, this.textRecordType, this.textRespFilteringControlType, this.textRespFilteringContactType, this.textRespFilteringIdField);
    }

    private handleRespSelection(defField: string, event: ChangeEvent) {
        this.def[defField] = (event.target as Textbox).selectedItem?.value;
    }

    refreshView() {
        if (this.def.alias == null)
            this.labelTable.text = this.def.tableName;
        else
            this.labelTable.text = this.def.alias + " (" + this.def.tableName + ")";
        this.panelTable.removeAll();
        if (this.isEditing() || this.def.tableName == null || this.def.tableName.length === 0)
            this.panelTable.add(this.textTable);
        else
            this.panelTable.add(this.labelTable);
        if (this.isEditing())
            this.buttonEdit.setProps({ imageName: "noPencil", tooltip: "Hide this table's properties" });
        else
            this.buttonEdit.setProps({ imageName: "pencil", tooltip: "View/edit this table's properties" });
        this.panelTable.add(this.buttonEdit);
        this.checkQualifyFields.checked = this.def.qualifyFields === true;
        this.checkEditable.checked = this.def?.editable !== false;
        this.checkAutoAddLookupModelJoins.checked = this.def.autoAddLookupModelJoins === true;
        const recordType = this.designer.getRecordType(this.def.recordType);
        if (recordType != null) { this.textRecordType.selectedItem = recordType; }
        const respFilteringControlType = this.designer.getRespFilteringControlType(this.def.respFilteringControlType);
        if (respFilteringControlType != null) { this.textRespFilteringControlType.selectedItem = respFilteringControlType; }
        const respFilteringContactType = this.designer.getRespFilteringContactType(this.def.respFilteringContactType);
        if (respFilteringContactType != null) { this.textRespFilteringContactType.selectedItem = respFilteringContactType; }
        this.textRespFilteringIdField.text = this.def.respFilteringIdField;
        const buttonAddJoin = new Button({ variant: ButtonVariant.round, imageName: "add", tooltip: "Add a join to this table", color: "subtle.darker" });
        buttonAddJoin.addClickListener(event => this.addChild(true));
        this.panelTable.add(buttonAddJoin);
    }

    isEditing() {
        return this.panelMain.indexOf(this.detailPanel) >= 0;
    }

    focus() {
        this.textTable.focus();
    }

    expandFields() {
        const popup = new Panel({ width: 280 })
        popup.add(new Label({ text: "Available Fields", height: 40, fontBold: true, fillRow: true, align: HorizontalAlignment.CENTER }));
        const content = new List({
            borderTopWidth: 1, borderTopColor: "strokeSecondary", fillHeight: true, fillRow: true, itemsCreator: () => {
                const result = [];
                for (const field of this.getFieldNames()) {
                    const label = new Label({ text: field });
                    label.addDblClickListener(event => this.addField(field));
                    result.push(label);
                }
                return result;
            }
        });
        popup.add(content);
        Overlay.alignToAnchor(popup, this.panelMain, Alignment.LEFT, Alignment.RIGHT);
        Overlay.showInOverlay(popup);
    }

    addChild(fromUI: boolean) {
        if (this.def.tableName == null || this.def.tableName.length === 0) {
            Snackbar.showSnackbar("Enter a table name first.");
            return;
        }
        if (this.childConnector == null) {
            this.childConnector = new Panel({ padding: 0, color: "primary.darker" });
            const vertContainer = new Panel({ align: HorizontalAlignment.CENTER, padding: 0, fillRow: true });
            vertContainer.add(new Panel({ borderLeftWidth: 2, padding: 0, height: 24 }));
            this.childConnector.add(vertContainer);
            this.add(this.childConnector);
        }
        const child = new PanelModelTable(this.designer, { parentTable: this, rowBreak: false, fillHeight: true});
        child.parentConnector.hasLeftSibling = this.children.length > 0;
        for (const otherChild of this.children)
            otherChild.parentConnector.hasRightSibling = true;
        this.add(child)
        this.children.push(child);
        if (fromUI) {
            this.def.joins.push(child.def);
            child.focus();
        }
        return child;
    }

    toggleExpand() {
        if (this.def.tableName == null || this.def.tableName.length === 0)
            Snackbar.showSnackbar("Enter a table name first.");
        else if (this.isEditing())
            this.panelMain.remove(this.detailPanel);
        else {
            this.panelMain.add(this.detailPanel);
            this.textField.focus();
        }
        this.refreshView();
    }

    private addField(fieldName: string) {
        for (const field of this.def.fields) {
            if (typeof field === "string") {
                if (field === fieldName)
                    return;
            }
            else {
                if (field.name === fieldName)
                    return;
            }
        }
        this.def.fields.push({ name: fieldName });
        this.displayFields();
    }

    fieldKeyUp(event) {
        if (event.key === Keys.ENTER && event.target.text.length > 0) {
            this.addField(event.target.text);
            event.target.text = "";
        }
        else if (event.key === Keys.ARROW_RIGHT && event.ctrlKey)
            this.expandFields();
        else if (event.key === Keys.ARROW_UP) {
            this.toggleExpand();
            this.textTable.focus();
        }
    }

    removeField(fieldName: string) {
        const index = this.def.fields.indexOf(fieldName);
        if (index >= 0) {
            this.def.fields.splice(index, 1);
            this.displayFields();
        }
    }

    displayFields() {
        const rows: ModelRow[] = [];
        for (let field of this.def.fields) {
            if (typeof field === "string")
                field = { name: field };
            const row = new ModelRow(null);
            row.setValues(field);
            rows.push(row)
        }
        if (rows.length > 0)
            this.tableFields.displayData(rows[0], rows, 0);
        else
            this.tableFields.clearRows();
    }

    lookupTable(showPredefinedConditionsWhenLoaded: boolean) {
        if (StringUtil.isEmptyString(this.def.tableName))
            return;

        const filter = { table_name: this.def.tableName, database_key: this.def["databaseKey"] };
        this.lastSearch = filter;
        Api.search("metadata/table", filter).then(response => {
            // if (this.lastSearch !== filter) {
            //   log.info("Ignoring results from a previous search that were returned after the DataSource has been searched again.  Lagging search", filter, "Last search", this.lastSearch, "Lagging result", response);
            //   return;
            // }
            this.availableFields = response.data;
            if (showPredefinedConditionsWhenLoaded) {
                this.parentConnector.showPredefinedConditions();
                this.parentConnector.populateChildFields();
            }
        }).catch(reason => CommonDialogs.showError("Error", reason));
    }

    getTableNames(): string[] {
        return this.designer.tableNames;
    }

    getFieldNames(): string[] {
        const result = [];
        if (this.availableFields != null)
            for (const field of this.availableFields)
                result.push(field.field_name);
        return result.sort();
    }
}
