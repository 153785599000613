import { ObjectUtil } from "./ObjectUtil";

export class ServerError {
    errorTag: string;
    invalidField: string;
    messages: string[];

    constructor(serverMessage: string | object) {
        const obj = typeof serverMessage === "string" ? JSON.parse(serverMessage) : serverMessage;
        ObjectUtil.appendObject(this, obj);
    }

    public insertMessage(line: string, index: number) {
        if (this.messages == null)
            this.messages = [];
        this.messages.splice(index, 0, line);
    }

    public replaceMessage(line: string, index: number) {
        if (this.messages == null)
            this.messages = [];
        this.messages.splice(index, 1, line);
    }

    public toString(): string {
        return this.messages == null ? "" : this.messages.join("\n");
    }
}
