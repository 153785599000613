import { Component, Container, KeyHandler, Snackbar } from "@mcleod/components";
import { ArrayUtil, HorizontalAlignment, Keys, VerticalAlignment } from "@mcleod/core";
import { AbstractUIDesigner } from "./AbstractUIDesigner";
import { ActionPasteComponent } from "./actions/ActionPaste";
import { ActionSwitchAdjacentComponents } from "./actions/ActionSwitchAdjacentComponents";
import { McLeodTailor } from "./custom/McLeodTailor";

export class UIDesignerKeyHandlers {

    public static getDesignerKeyListeners(designer): KeyHandler[] {
        const result = [];
        result.push({ key: Keys.R, modifiers: { ctrlKey: true }, listener: () => UIDesignerKeyHandlers.toggleRowBreak(designer) });
        result.push({ key: Keys.C, modifiers: { ctrlKey: true }, listener: () => UIDesignerKeyHandlers.copySelected(designer, false) });
        result.push({ key: Keys.X, modifiers: { ctrlKey: true }, listener: () => UIDesignerKeyHandlers.copySelected(designer, true) });
        result.push({ key: Keys.V, modifiers: { ctrlKey: true }, listener: () => UIDesignerKeyHandlers.paste(designer) });
        result.push({ key: Keys.F, modifiers: { ctrlKey: true }, listener: () => UIDesignerKeyHandlers.toggleFillRow(designer) });
        result.push({ key: Keys.B, modifiers: { ctrlKey: true }, listener: () => UIDesignerKeyHandlers.toggleBold(designer) });
        result.push({ key: Keys.A, modifiers: { ctrlKey: true }, listener: () => UIDesignerKeyHandlers.toggleAlign(designer) });
        result.push({ key: Keys.C, modifiers: { ctrlKey: true, shiftKey: true }, listener: () => UIDesignerKeyHandlers.selectFirstChild(designer) });
        result.push({ key: Keys.EQUAL, modifiers: { shiftKey: true }, listener: () => UIDesignerKeyHandlers.changeFontSize(designer, 1) });
        result.push({ key: Keys.MINUS, listener: () => UIDesignerKeyHandlers.changeFontSize(designer, -1) });
        result.push(...UIDesignerKeyHandlers.undoRedoKeyListeners(designer));
        result.push(...UIDesignerKeyHandlers.getDesignerSelectSiblingKeyListeners(designer));
        result.push(...UIDesignerKeyHandlers.moveSelectedKeyListeners(designer));
        return result;
    }

    public static undoRedoKeyListeners(designer): KeyHandler[] {
        return [
            { key: Keys.Z, modifiers: { ctrlKey: true }, listener: () => designer.actionExecutor?.undoDesignerAction(designer) },
            { key: Keys.Y, modifiers: { ctrlKey: true }, listener: () => designer.actionExecutor?.redoDesignerAction(designer) }
        ]
    }

    public static moveSelectedKeyListeners(designer): KeyHandler[] {
        return [
            { key: Keys.ARROW_UP, modifiers: { ctrlKey: true }, listener: () => UIDesignerKeyHandlers.moveSelected(designer, -1) },
            { key: Keys.U, modifiers: { ctrlKey: true }, listener: () => UIDesignerKeyHandlers.moveSelected(designer, -1) },
            { key: Keys.ARROW_LEFT, modifiers: { ctrlKey: true }, listener: () => UIDesignerKeyHandlers.moveSelected(designer, -1) },
            { key: Keys.ARROW_RIGHT, modifiers: { ctrlKey: true }, listener: () => UIDesignerKeyHandlers.moveSelected(designer, 1) },
            { key: Keys.ARROW_DOWN, modifiers: { ctrlKey: true }, listener: () => UIDesignerKeyHandlers.moveSelected(designer, 1) },
            { key: Keys.J, modifiers: { ctrlKey: true }, listener: () => UIDesignerKeyHandlers.moveSelected(designer, 1) }
        ]
    }

    public static getDesignerSelectSiblingKeyListeners(designer): KeyHandler[] {
        const result = [];
        result.push({ key: Keys.ARROW_DOWN, listener: () => UIDesignerKeyHandlers.selectSibling(designer, 1) });
        result.push({ key: Keys.ARROW_RIGHT, listener: () => UIDesignerKeyHandlers.selectSibling(designer, 1) });
        result.push({ key: Keys.ARROW_UP, listener: () => UIDesignerKeyHandlers.selectSibling(designer, -1) });
        result.push({ key: Keys.ARROW_LEFT, listener: () => UIDesignerKeyHandlers.selectSibling(designer, -1) });
        return result;
    }



    static toggleRowBreak(designer) {
        if (designer.selectedComponents == null)
            return;
        for (const comp of designer.selectedComponents) {
            const props = comp.getPropertyDefinitions();
            if (props.rowBreak != null)
                UIDesignerKeyHandlers.changeComponentProp(designer, comp, "rowBreak", comp.rowBreak == null ? false : !comp.rowBreak);
        }
        designer.displayProperties();
    }

    static toggleFillRow(designer) {
        if (designer.selectedComponents == null)
            return;
        for (const comp of designer.selectedComponents) {
            const props = comp.getPropertyDefinitions();
            if (props.fillRow != null)
                UIDesignerKeyHandlers.changeComponentProp(designer, comp, "fillRow", !comp.fillRow);
        }
        designer.displayProperties()
    }

    static toggleVerticalAlign(designer) {
        if (designer.selectedComponents == null)
            return;
        for (const comp of designer.selectedComponents) {
            const props = comp.getPropertyDefinitions();
            if (props.verticalAlign != null) {
                if (comp.verticalAlign == null || comp.verticalAlign === VerticalAlignment.TOP)
                    UIDesignerKeyHandlers.changeComponentProp(designer, comp, "verticalAlign", VerticalAlignment.CENTER);
                else if (comp.verticalAlign === VerticalAlignment.CENTER)
                    UIDesignerKeyHandlers.changeComponentProp(designer, comp, "verticalAlign", VerticalAlignment.BOTTOM);
                else
                    UIDesignerKeyHandlers.changeComponentProp(designer, comp, "verticalAlign", VerticalAlignment.TOP);
            }
        }
        designer.displayProperties();
    }

    static toggleAlign(designer) {
        if (designer.selectedComponents == null)
            return;
        for (const comp of designer.selectedComponents) {
            const props = comp.getPropertyDefinitions();
            if (props.align != null) {
                if (comp.align == null || comp.align === HorizontalAlignment.LEFT)
                    UIDesignerKeyHandlers.changeComponentProp(designer, comp, "align", HorizontalAlignment.CENTER);
                else if (comp.align === HorizontalAlignment.CENTER)
                    UIDesignerKeyHandlers.changeComponentProp(designer, comp, "align", HorizontalAlignment.RIGHT);
                else
                    UIDesignerKeyHandlers.changeComponentProp(designer, comp, "align", HorizontalAlignment.LEFT);
            }
        }
        designer.displayProperties();
    }

    static toggleBold(designer) {
        if (designer.selectedComponents == null)
            return;
        for (const comp of designer.selectedComponents)
            UIDesignerKeyHandlers.changeComponentProp(designer, comp, "fontBold", comp.fontBold == null ? true : !comp.fontBold);
        designer.displayProperties();
    }

    static selectSibling(designer, by) {
        const comp = designer.selectedComponents[0];
        if (comp != null && comp.parent.indexOf != null) {
            const index = comp.parent.indexOf(comp);
            const switchIndex = index + by;
            if (switchIndex >= 0 && switchIndex < comp.parent.components.length)
                designer.selectComponent(comp.parent.components[switchIndex]);
        }
    }

    static selectFirstChild(designer) {
        const comp = designer.selectedComponents[0];
        if (comp != null && ArrayUtil.isEmptyArray(comp.components) !== true)
            designer.selectComponent(comp.components[0]);
    }

    static moveSelected(designer, by) {
        for (const comp of designer.selectedComponents)
            designer.executeAction(new ActionSwitchAdjacentComponents(comp, by));
    }

    static copySelected(designer, cut) {
        designer.copiedComponents = [];
        designer.cutting = false;
        // Need compare the copiedComponents to AbstractUIDesigner.designerTools
        // so we don't allow users to create components they don't have access to
        let allowedToCopyOrCut = true;
        if (designer instanceof McLeodTailor) {
            const allComponents: Component[] = [];
            for (const comp of designer.selectedLayoutComponents) {
                allComponents.push(comp);
                if (comp instanceof Container)
                    allComponents.push(...comp.getRecursiveChildren(false));
            }
            allowedToCopyOrCut = allComponents?.every(component => designer.hasDesignerToolAccessForComponent(component))
        }
        if (allowedToCopyOrCut) {
            designer.copiedComponents = [...designer.selectedLayoutComponents];
            designer.cutting = cut;
        }
    }

    static paste(designer) {
        if (designer.selectedLayoutComponents.length !== 1)
            Snackbar.showSnackbar("You need to select a single parent component to paste.");
        else if (designer.copiedComponents.length === 0)
            Snackbar.showSnackbar("No components have been copied to paste.");
        else {
            let parent = designer.selectedLayoutComponents[0];
            if (parent.add == null)
                parent = parent.parent;
            if (designer.canAddComponentToContainer(designer.copiedComponents[0], parent)) {
                designer.executeAction(new ActionPasteComponent(designer.getActiveTab(), designer.copiedComponents, parent, designer.cutting));
                if (designer.cutting) {
                    designer.cutting = false;
                    designer.copiedComponents = [];
                }
            } else {
                Snackbar.showSnackbar("Unable to paste into selected parent component.");
            }
        }
    }

    static changeFontSize(designer, by) {
        for (const comp of designer.selectedComponents) {
            let size = comp.fontSize;
            if (size == null)
                size = "medium";
            const sizes = ["xxsmall", "xsmall", "small", "medium", "large", "xlarge", "xxlarge", "xxxlarge", "skyline", "billboard"];
            let index = sizes.indexOf(size);
            if (index < 0)
                index = 2;
            index += by;
            if (index >= 0 && index < sizes.length) {
                UIDesignerKeyHandlers.changeComponentProp(designer, comp, "fontSize", sizes[index]);
            }
        }
        designer.displayProperties();
    }

    static changeComponentProp(designer: AbstractUIDesigner, comp: Component, propName: string, newValue: any) {
        designer.executeChangePropAction(comp, propName, newValue, comp[propName], true);
    }

}
