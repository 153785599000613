import {
   DynamicLoader, HorizontalAlignment, Keys, ModelRow, Navigation, NavOptions, PermissionsUtil, UrlUtil
} from "@mcleod/core";
import { Button } from "../button/Button";
import { ButtonProps } from "../button/ButtonProps";
import { ButtonVariant } from "../button/ButtonVariant";
import { Component } from "../../base/Component";
import { ComponentCreator, ComponentFactory } from "../../page/ComponentFactory";
import { ClickEvent } from "../../events/ClickEvent";
import { CrudDecorator } from "../../page/decorators/CrudDecorator";
import { CrudDecoratorProps } from "../../page/decorators/CrudDecoratorProps";
import { CrudDecoratorCloseEvent } from "../../events/CrudDecoratorCloseEvent";
import { DataHeaderProps } from "../dataheader/DataHeaderProps";
import { DataSourceMode } from "../../databinding/DataSource";
import { DesignableObjectLogManager } from "../../logging/DesignableObjectLogManager";
import { LabelProps } from "../label/LabelProps";
import { Layout } from "../layout/Layout";
import { List, ListItemType } from "../list/List";
import { Panel } from "../panel/Panel";
import { PanelProps } from "../panel/PanelProps";
import { PinnedSearch } from "./PinnedSearch";
import { ReflectiveDialogs } from "../../base/ReflectiveDialogs";
import { ReflectiveSnackbars } from "../../base/ReflectiveSnackbars";
import { Table } from "./Table";
import { TableRow } from "./TableRow";
import { TableToolsConfigOptions } from "./TableToolsConfigOptions";
import { TableToolsExportOptions } from "./TableToolsExportOptions";
import { ContextMenuLabelProps } from "../contextmenu/ContextMenuLabelProps";

export enum DetailMode {
    SAME_TAB,
    NEW_TAB_NO_SWITCH,
    NEW_TAB_WITH_SWITCH,
    NEW_WINDOW
}

const basicDropdownProps: Partial<LabelProps> = {
    borderWidth: 0,
    borderRadius: 0,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    minHeight: 34,
    align: HorizontalAlignment.LEFT,
    fontSize: "medium"
};

const log = DesignableObjectLogManager.getLogger("components.table.TableToolsPanel")

export class TableToolsPanel extends Panel {
    private table: Table;
    private _addCaption: string;
    private stdLeftTools: Panel;
    private stdRightTools: Panel;
    private postStdTools: Panel;
    leftTools: Panel;
    rightTools: Panel;
    private toolSpacer: Panel;
    private buttonAdvancedSearch: Button;
    private buttonDelete: Button;
    private buttonAdd: Button;
    private buttonShare: Button;
    private buttonDefaultDetail: Button;
    private buttonDetailDropdown: Button;
    private buttonEllipsis: Button;
    private crudPanel: Panel;
    public overrideUrlParams: (tableRow?: TableRow) => any;
    public determineDetailKeyValue: (tableRow: TableRow) => string;

    constructor(table: Table, props?: Partial<PanelProps>) {
        super({ padding: 0, fillRow: true, ...props });
        this.table = table;
        this.stdLeftTools = new Panel({ id: "stdLeftTools", rowBreak: false });
        this.leftTools = new Panel({ id: "leftTools", rowBreak: false, rowBreakDefault: false, _designer: table._designer });
        this.postStdTools = new Panel({ id: "postStdTools", rowBreak: false });
        this.toolSpacer = new Panel({ id: "toolSpacer", rowBreak: false, fillRow: true });
        this.rightTools = new Panel({ id: "rightTools", _designer: table._designer, rowBreak: false });
        this.stdRightTools = new Panel({ id: "stdRightTools" });
        this.createButtons();
        this.add(this.stdLeftTools, this.leftTools, this.postStdTools, this.toolSpacer, this.rightTools, this.stdRightTools);
        this.addMountListener(() => this.syncAddCaption());
    }

    addTool(tool: ComponentCreator, addToLeftSection: boolean = true) {
        const target = addToLeftSection ? this.leftTools : this.rightTools;
        return target.add(ComponentFactory.createCommon(tool));
    }

    removeTool(...tools: Component[]) {
        if (tools != null)
            for (const tool of tools) {
                this.leftTools.remove(tool);
                this.rightTools.remove(tool);
            }
    }

    private createButtons() {
        const buttonProps = { rowBreak: false, color: "primary", imageWidth: 20, imageHeight: 20 };
        this.buttonAdvancedSearch = new Button({
            ...buttonProps,
            variant: ButtonVariant.round,
            imageName: "magnifyingGlassPage",
            hotkey: "Alt-S",
            onClick: () => this.showSearch(),
            tooltip: "Show advanced search options (Alt-S)"
        });
        this.buttonAdvancedSearch.addHandlerForKey(Keys.S, { altKey: true });
        this.buttonDelete = new Button({
            ...buttonProps,
            variant: ButtonVariant.round,
            enabled: false,
            imageName: "delete",
            onClick: () => this.handleDelete(),
            tooltip: "Delete the selected rows"
        });
        this.buttonShare = new Button({
            ...buttonProps,
            variant: ButtonVariant.round,
            enabled: false,
            imageName: "share",
            onClick: () => this.showShare(),
            tooltip: "Share the selected row"
        });
        this.buttonDefaultDetail = new Button({
            ...buttonProps,
            enabled: false,
            hotkey: "Alt-D",
            padding: 4,
            borderWidth: 0,
            margin: 0,
            marginRight: 0,
            paddingRight: 0,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            onClick: () => this.showDetail(DetailMode.SAME_TAB),
            ...this.getDetailButtonProps(this.getDefaultDetailMode())
        });
        this.buttonDetailDropdown = new Button({
            ...buttonProps,
            enabled: false,
            imageName: "arrow",
            imageWidth: 12,
            imageHeight: 12,
            padding: 8,
            borderWidth: 0,
            margin: 0,
            paddingLeft: 0,
            marginLeft: 0,
            paddingRight: 2,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            contextMenuItems: () => this.getDetailDropdownItems(),
            tooltip: "Show other options for drilling down to the selected row in this table"
        });
        this.buttonEllipsis = new Button({
            ...buttonProps,
            enabled: true,
            variant: ButtonVariant.round,
            imageName: "ellipsis",
            tooltip: "Show more options",
            dropdownListProps: { maxHeight: 800 },
            dropdownItems: () => this.getEllipsisDropdownOptions()
        });
        this.buttonAdd = new Button({
            id: "buttonAdd",
            caption: "Add",
            imageName: "add",
            color: "primary",
            hotkey: "Alt-A",
            onClick: () => this.showAdd(),
            tooltip: "Add a new record",
        });
    }

    handleRowDblClick(event: ClickEvent) {
        if (event.hasModifiers({ ctrlKey: true, shiftKey: true }))
            this.showDetail(DetailMode.NEW_TAB_WITH_SWITCH);
        else if (event.hasModifiers({ shiftKey: true }))
            this.showDetail(DetailMode.NEW_WINDOW);
        else if (event.hasModifiers({ ctrlKey: true }))
            this.showDetail(DetailMode.NEW_TAB_NO_SWITCH);
        else
            this.showDetail(DetailMode.SAME_TAB);
    }

    public get addCaption(): string {
        return this._addCaption == null ? "Add" + this.getLayoutTitleSuffix() : this._addCaption;
    }

    public set addCaption(value: string) {
        this._addCaption = value;
        this.syncAddCaption();
    }

    private getDefaultDetailMode(): DetailMode {
        return DetailMode.SAME_TAB;
    }

    private getDetailButtonProps(mode: DetailMode): Partial<ButtonProps> {
        switch (mode) {
            case DetailMode.NEW_TAB_NO_SWITCH: // when clicking from the toolbar, there doesn't seem like a reason to not switch to it
            case DetailMode.NEW_TAB_WITH_SWITCH: return { imageName: "tab", tooltip: "Open the selected row in a new browser tab" };
            case DetailMode.NEW_WINDOW: return { imageName: "overlappingWindows", tooltip: "Open the selected row in a new browser window" };
            default: return { imageName: "detail", tooltip: "Open the selected row in this table in this browser tab" };
        }
    }

    private getDetailDropdownItems(): Partial<ContextMenuLabelProps>[] {
        const result: Partial<ContextMenuLabelProps>[] = [];
        this.addDetailDropdownItem(result, DetailMode.SAME_TAB, "Open");
        this.addDetailDropdownItem(result, DetailMode.NEW_TAB_WITH_SWITCH, "Open in New Tab");
        this.addDetailDropdownItem(result, DetailMode.NEW_WINDOW, "Open in New Window");
        return result;
    }

    private addDetailDropdownItem(array: Partial<ContextMenuLabelProps>[], mode: DetailMode, text: string) {
        const defaultMode = this.getDefaultDetailMode();
        if (mode != defaultMode) {
            array.push({text,  onClick: () => this.showDetail(mode)});
        }
    }

    public syncTools() {
        // ideally, we want to execute this only once during initialization of the Table from a .layout
        this.stdLeftTools.removeAll();
        this.stdRightTools.removeAll();

        this.internalAddTool(this.allowAdvancedSearch, this.stdLeftTools, this.buttonAdvancedSearch);
        this.internalAddTool(this.table.allowDelete, this.stdLeftTools, this.buttonDelete);
        this.internalAddTool(this.table.allowShare, this.stdLeftTools, this.buttonShare);

        if (this.allowDetail) {
            this.internalAddTool(true, this.stdLeftTools, this.buttonDefaultDetail);
            this.internalAddTool(true, this.stdLeftTools, this.buttonDetailDropdown);
        }

        this.internalAddTool(this.table.allowConfig || this.table.allowPin || this.table.allowExport, this.postStdTools, this.buttonEllipsis);
        this.internalAddTool(this.allowAdd, this.stdRightTools, this.buttonAdd);
    }

    private internalAddTool(condition: boolean, toolParent: Panel, tool: Component) {
        if (condition) {
            toolParent.add(tool);
            tool._designer = this.table._designer;
        }
    }

    private get allowAdvancedSearch(): boolean {
        return this.table.allowAdvancedSearch && this.getLayoutNameForMode(DataSourceMode.SEARCH) != null;
    }

    private get allowDetail(): boolean {
        return this.table.allowDetail && this.getLayoutNameForMode(DataSourceMode.UPDATE) != null;
    }

    private allowDetailForRow(row: TableRow): boolean {
        return this.table.allowDetailForRow == null || this.table.allowDetailForRow(row) === true;
    }

    private get allowAdd(): boolean {
        return this.table.allowAdd && this.getLayoutNameForMode(DataSourceMode.ADD) != null;
    }

    public showShare() {

    }

    public async showAdd() {
        const url = "/" + this.table.addLayout + "?mode=add";
        const dataHeaderProps: Partial<DataHeaderProps> = {
            onExecute: (addedRow: ModelRow) => {
                this.table.dataSource.addRow(addedRow, 0);
            }
        };
        this.showCrud(DataSourceMode.ADD, {pseudoNavigateUrl: url}, dataHeaderProps);
    }

    public getPopoutNavOptions(): Partial<NavOptions> {
        const windowSize = {
            width: 1400,
            height: 700,
        };
        const loc = {
            left: ((window.screen as any).availLeft + (window.screen.availWidth / 2)) - (windowSize.width / 2),
            top: ((window.screen as any).availTop + (window.screen.availHeight / 2)) - (windowSize.height / 2)
        };
        return { left: loc.left, top: loc.top, height: 700, width: 1400, newTab: true, windowDecorators: false };
    }

    public async showDetail(detailMode: DetailMode) {
        if (this.allowDetail === false) {
            log.debug(this.table, `Unable to show detail: allowDetail: ${this.allowDetail}, detailLayout: ${this.table.detailLayout}`);
            return;
        }
        const selectedRow = this.table?.selectedRow;
        const selectedRowData = selectedRow?.data as ModelRow;
        if (selectedRowData == null)
            return;
        if (this.allowDetailForRow(selectedRow) !== true) {
            log.debug(this.table, "Selected row not allowed to show detail");
            return;
        }

        let key = null;
        if (this.determineDetailKeyValue != null) {
            key = this.determineDetailKeyValue(selectedRow);
        } else {
            const keyData = selectedRowData.getKeyData();
            const keys = Object.keys(keyData);
            key = keyData[keys[0]] as string;
        }
        if (key == null) {
            log.debug(this.table, "Unable to determine key for selected row");
            return;
        }

        this.table.detailLayout = this.table.detailLayout;
        const urlParam = { mode: "update", ...(this.overrideUrlParams != null ? this.overrideUrlParams(this.table.selectedRow) : { key }) }
        const url = "/" + this.table.detailLayout + UrlUtil.buildQueryString(urlParam);
        if (detailMode === DetailMode.SAME_TAB) {
            const dataHeaderProps: Partial<DataHeaderProps> = {
                showDelete: selectedRow.canBeDeleted === true,
                onExecute: async (updatedRow: ModelRow) => {
                    this.table.resolveRowEdit(this.table.selectedRow.data, updatedRow);
                }
            };
            this.showCrud(DataSourceMode.UPDATE, { key: key, pseudoNavigateUrl: url }, dataHeaderProps);
        }
        else if (detailMode === DetailMode.NEW_WINDOW)
            Navigation.navigateTo(url, this.getPopoutNavOptions());
        else if (detailMode === DetailMode.NEW_TAB_NO_SWITCH) {
            Navigation.navigateTo(url, { newTab: true });
            window.focus();
        }
        else
            Navigation.navigateTo(url, { newTab: true });
    }

    public showSearch() {
        const dataHeaderProps: Partial<DataHeaderProps> = {
            onExecute: (filterValues: ModelRow) => {
                this.table.dataSource.search(filterValues);
            }
        };
        this.showCrud(DataSourceMode.SEARCH, null, dataHeaderProps);
    }

    public showCrud(mode: DataSourceMode, crudDecoratorProps: Partial<CrudDecoratorProps>,
        dataHeaderProps: Partial<DataHeaderProps>) {
        const layoutName = this.getLayoutNameForMode(mode);
        if (layoutName == null) {
            log.debug(this.table, "No layout found for mode " + mode);
            return;
        }
        const layout = Layout.getLayout(layoutName);
        this.crudPanel = new CrudDecorator({
            layout: layout,
            mode: mode,
            headerProps: {
                showClose: true,
                showSaveAndClose: true,
                doAfterClose: (event: CrudDecoratorCloseEvent) => this.doAfterCrudClose(event),
                ...dataHeaderProps
            },
            ...crudDecoratorProps
        });
        this.crudPanel.slideIn({ speed: 200 });
    }

    private doAfterCrudClose(event: CrudDecoratorCloseEvent) {
        if (event.userDeleted() === true) {
            const deletedRow = event.dataHeader.dataSource.activeRow;
            const keyData = deletedRow.getKeyData();
            log.debug("Attempting to remove table row with key data: %o", keyData);
            const rowIndexInDataSource = this.table.dataSource.findRowInData(keyData);
            if (rowIndexInDataSource >= 0) {
                const rowInDataSource = this.table.dataSource.data[rowIndexInDataSource];
                log.debug("Matching row found in DataSource: %o", rowInDataSource);
                const rowIndex = this.table.rows.findIndex(tableRow => {
                    return tableRow.data === rowInDataSource;
                });
                if (rowIndex >= 0) {
                    log.debug("Removing matching table row at index %o", rowIndex);
                    this.table.removeRow(rowIndex);
                }
                else
                    log.debug("Could not remove row from table: matching row was not found in the table");
            }
            else
                log.debug("Could not remove row from table; matching row was not found in the DataSource");
        }
        this.table.fireAfterCrudCloseListener(event);
    }

    private getLayoutNameForMode(mode: DataSourceMode): string {
        switch (mode) {
            case DataSourceMode.ADD: return this.table.addLayout;
            case DataSourceMode.UPDATE: return this.table.detailLayout;
            case DataSourceMode.SEARCH: return this.table.searchLayout;
            default: return this.table.generalLayout;
        }
    }

    selectionChanged() {
        const sel = this.table.selectedRows.length === 1;
        this.buttonDelete.enabled = sel;
        this.buttonDefaultDetail.enabled = sel;
        this.buttonDetailDropdown.enabled = sel;
        this.buttonShare.enabled = sel;
    }

    async getEllipsisDropdownOptions(): Promise<ListItemType[]> {
        const result: ListItemType[] = [];
        if (this.table.allowPin === true) {
            const pinnedSearch = new PinnedSearch(DynamicLoader.getRouteFromURL(), this.table.dataSource, basicDropdownProps);
            const pinnedSearchItem = await pinnedSearch.getDropdownItem();
            result.push(pinnedSearchItem);
        }
        if (this.table.allowConfig === true && PermissionsUtil.isUserDeniedAction("System.Update grid configurations") !== true) {
            this.addSeparator(result);
            const configOptions = new TableToolsConfigOptions(this.table, basicDropdownProps);
            const configItem = await configOptions.getConfigDropdownItem();
            result.push(configItem);
        }
        if (this.table.allowExport === true) {
            this.addSeparator(result);
            const exportItem = new TableToolsExportOptions(this.table, basicDropdownProps).getDropdownExportItem();
            result.push(exportItem);
        }
        return result;
    }

    private addSeparator(array: ListItemType[]) {
        if (array.length > 0) {
            const item = List.createSeparator();
            item.renderedComponent.marginTop = 0;
            item.renderedComponent.marginBottom = 0;
            array.push(item);
        }
    }

    private syncAddCaption() {
        this.buttonAdd.caption = this.addCaption;
    }

    private getLayoutTitleSuffix(): string {
        let parent = this.parent;
        while (parent != null && !(parent instanceof Layout))
            parent = parent.parent;
        const title = (parent as Layout)?.titleSingular?.toString();
        return title == null ? "" : " " + title;
    }

    async handleDelete() {
        const sel = this.table.selectedRows;
        if (sel.length === 0) {
            ReflectiveDialogs.showMessage("No records are selected to delete.");
            return;
        }
        const prompt = "Are you sure you want to delete the selected " + (sel.length === 1 ? "record" : "records") + "?";
        if (await ReflectiveDialogs.showYesNo(prompt, "Confirm Delete")) {
            for (let i = sel.length - 1; i >= 0; i--) {
                await sel[i].data.delete();
                this.table.removeRow(sel[i].index);
            }
            ReflectiveSnackbars.showSnackbar("Selected " + (sel.length === 1 ? "record has" : "records have") + " been deleted.");
        }
    }
}
