import { ArrayUtil } from "@mcleod/core";
import { ListItem } from "../list/ListItem";
import { ContextMenuProps } from "./ContextMenu";
import { ContextMenuLabel } from "./ContextMenuLabel";
import { ContextMenuItemsProvider, ContextMenuLabelProps } from "./ContextMenuLabelProps";


export class ContextMenuItemCreator {

    static createListItems(props: ContextMenuProps): ListItem[] {
        const { sourceComponent,  designer, itemProvider = props.sourceComponent.contextMenuItems } = props;

        const items = ContextMenuItemCreator.resolveContextMenuItemProps(itemProvider);
        if (items == null) {
            return null;
        }
        return items.map(itemProps => {
            const comp = new ContextMenuLabel(sourceComponent, {_designer: designer, ...itemProps });
            return new ListItem(comp);
        });
    }

    static resolveContextMenuItemProps(provider: ContextMenuItemsProvider): Partial<ContextMenuLabelProps>[] {
        const items = typeof provider === "function" ? provider() : provider;
        if (ArrayUtil.isEmptyArray(items)) {
            return null;
        }
        return items;
    }
}
