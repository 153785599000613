import { Alignment } from "./constants/Alignment";

export class AlignmentUtil {
    public static getOppositeAlignment(alignment: Alignment): Alignment {
        switch (alignment) {
            case Alignment.TOP: return Alignment.BOTTOM;
            case Alignment.BOTTOM: return Alignment.TOP;
            case Alignment.RIGHT: return Alignment.LEFT;
            case Alignment.LEFT: return Alignment.RIGHT;
            default: return alignment;
        }
    }
}
