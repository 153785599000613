import { Attachment, Button, DataSource, Label, Layout, Panel, Tab, Table, Tabset, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutTenant extends Layout {
	attachmentLogo: Attachment;
	buttonHeaderBackground: Button;
	buttonHeaderTextColor: Button;
	buttonInviteUser: Button;
	labelHeaderBackground: Label;
	labelHeaderLogo: Label;
	labelHeaderTextColor: Label;
	panelHeaderBackground: Panel;
	panelHeaderTextColor: Panel;
	panelLogo: Panel;
	sourceTenant: DataSource;
	sourceUsers: DataSource;
	tabBranding: Tab;
	tabUsers: Tab;
	tableUsers: Table;
	tabsetTenant: Tabset;
	textboxCompanyName: Textbox;
	textboxHomeUrl: Textbox;
}
