import { Label, LabelProps } from "@mcleod/components";
import { ExternalLink } from "@mcleod/core";

export class PageHeaderProvider {
    public static get(): PageHeaderProvider {
        if (_singleton == null)
            _singleton = new PageHeaderProvider();
        return _singleton;
    }

    public static set(provider: PageHeaderProvider) {
        _singleton = provider;
    }

    getUserDropdownMenuItems(sharedProps: Partial<LabelProps>): Label[] {
        const result: Label[] = [];
        return result;
    }

    public shouldDisplayHelpLinks(): boolean {
        return false;
    }

    public getAddlExternalLinks(): ExternalLink[] {
        return [];
    }
}

let _singleton: PageHeaderProvider;