import { DialogProps, PanelProps } from "@mcleod/components";
import { ArrayUtil } from "@mcleod/core";
import { AbstractValueDisplayValueEditor } from "./AbstractValueDisplayValueEditor";

export class HelpLinkEditor extends AbstractValueDisplayValueEditor {
    constructor(currentValueJson: string, props?: Partial<PanelProps>) {
        super(currentValueJson, props);
    }

    get valueCaption(): string {
        return "Text";
    }

    get valueName(): string {
        return "text";
    }

    get displayValueCaption(): string {
        return "URL";
    }

    get displayValueName(): string {
        return "url";
    }

    get emptyCaption(): string {
        return "Help links can be defined in this table";
    }

    override getResultValue(): any {
        const result: any[] = [];
        for (const row of this.tableRows) {
            result.push({ text: row.data.get("text", null), url: row.data.get("url", null) });
        }
        if (ArrayUtil.isEmptyArray(result) === false)
            return JSON.stringify(result);
        return null;
    }

    override getDialogProps(): Partial<DialogProps> {
        return {
            ...super.getDialogProps(),
            title: "Help Links Setup",
            okDefault: false,
            cursor: null
        }
    }
}
