import { Collection } from "../Collection";
import { Logger } from "./Logger";
import { DateTimeFormat } from "./DateTimeFormat";
import { ExecutionLog } from "./ExecutionLog";
import { LogConfig } from "./LogConfig";
import { LogLevel } from "./LogLevel";

export class CoreLogger implements Logger {
    private static readonly cssRegex = /%c/g;
    private static readonly modelRegex = /{[^{}]+}/;
    private _name: string;
    private _debug: (...args: any[]) => void = this.noop;
    private _info: (...args: any[]) => void = this.noop;

    constructor(name: string) {
        this._name = name;
        this.reset();
    }

    public get name(): string {
        return this._name;
    }

    public log(level: LogLevel, ...rest: any[]) {
        if (rest.length === 1 && typeof rest[0] === "function")
            rest = rest[0]();
        this.formatAndOutput(level, ...rest);
    }

    protected formatAndOutput(level: LogLevel, ...rest: any[]) {
        const config = LogConfig.get();
        if (rest.length > 0 && rest[0]) {
            if (config.logFormat == null) {
                rest[0] = this.getDateTimeStringWithMillis(new Date()) + " " + level + " [" + this.name + "] " +
                    this.getObjectString(rest[0]);
            }
            else
                this.applyLogFormat(level, rest);
        }
        if (config.enableConsoleLog)
            console.log(...rest);
        if (config.bufferSize > 0)
            ExecutionLog.get().append(rest[0]); // need to push args as well but don't feel like doing that right now
    }

    public get logLevel(): LogLevel {
        return LogConfig.get().logLevels[this.name] ?? LogConfig.get().defaultLogLevel;
    }

    public isDebugEnabled(): boolean {
        return this.logLevel === LogLevel.DEBUG;
    }

    public get debug(): (...args: any[]) => void {
        return this._debug;
    }

    protected set debug(value: (...args: any[]) => void) {
        this._debug = value;
    }

    public get info(): (...args: any[]) => void {
        return this._info;
    }

    protected set info(value: (...args: any[]) => void) {
        this._info = value;
    }

    public error(...args: any[]): void {
        this.log(LogLevel.ERROR, ...args);
    }

    public reset() {
        const logLevel = this.logLevel;
        this._debug = this.noop;
        this._info = this.noop;
        if (logLevel === LogLevel.DEBUG)
            this._debug = (...args: any[]) => this.log(LogLevel.DEBUG, ...args);
        if (logLevel === LogLevel.DEBUG || logLevel === LogLevel.INFO)
            this._info = (...args: any[]) => this.log(LogLevel.INFO, ...args);
    }

    protected noop() {
    }
    
    private getObjectString(obj: any): string {
        if (typeof obj === "object" && obj.message != null && obj.stack != null) {
            return obj.message + "\n" + obj.stack
        }
        return obj?.toString();
    }

    private applyLogFormat(level: LogLevel, args: string[]): void {
        const extraParams: string[] = [];
        const replacements = {
            level: level,
            timestamp: this.getDateTimeStringWithMillis(new Date()),
            name: this.name,
            message: args[0]
        }
        const logFormat = LogConfig.get().logFormat;
        this.addCSSArgs(logFormat, extraParams);
        args.splice(1, 0, ...extraParams);
        args[0] = this.getLogFormat(logFormat, replacements);
    }

    private getDateTimeStringWithMillis(date: Date): string {
        // fractional doesn't seem to be working, and would prefer in format of YYYY-MM-dd HH:mm:ss.sss
        // need to drop dtf (who comes up with these variable names???) and use date-fns
        return DateTimeFormat.get().format(date);
    }
    
    private getLogFormat(format: string, replacements: Collection<string>): string {
        if (format == null)
            return "";
        if (format.indexOf("{") < 0 || format.indexOf("}") < 0)
            return format;
        let result = format;
        let match = CoreLogger.modelRegex.exec(result);
        while (match != null) {
            const replacementKey = match[0].substring(1, match[0].length - 1);
            const replacement = replacements == null ? null : replacements[replacementKey];
            const evalValue = replacement || "";
            result = result.substring(0, match.index) + evalValue + result.substring(match.index + match[0].length);
            match = CoreLogger.modelRegex.exec(result);
        }
        return result;
    }
    
    private addCSSArgs(format: string, args: string[]) {
        const count = format.match(CoreLogger.cssRegex).length;
        for (let index = 1; index <= count; index++) {
            const css = LogConfig.get()["logFormat.css." + index];
            if (css == null)
                console.log("Error: log configuration format specifies CSS formatting but logFormat.css." + index + " was not specified.");
            args.push(css);
        }
    }
}
