import { ArrayUtil, ValueDisplayValue } from "@mcleod/core";


export class DropdownItem extends ValueDisplayValue {
    // Maintaining this (functionally) empty class in case we decide we need logic specific to DropdownItems later on
    public static readonly BLANK_SEARCH_ITEM: DropdownItem = new DropdownItem("=", "=");
    public static readonly BLANK_ITEM: DropdownItem =  new DropdownItem("<blank>", "--");

    public static createFromValueDisplayValue(sourceVDV: ValueDisplayValue): DropdownItem {
        const result = new DropdownItem(sourceVDV.value, sourceVDV.displayValue);
        result.copyAddlValues(sourceVDV);
        return result;
    }

    public static createFromValueDisplayValues(sourceVDVs: ValueDisplayValue[]): DropdownItem[] {
        const result: DropdownItem[] = [];
        if (sourceVDVs != null) {
            for (const sourceVDV of sourceVDVs) {
                result.push(this.createFromValueDisplayValue(sourceVDV));
            }
        }
        return result;
    }

    public static findDisplayItemsThatStartsWith(startsWith: string, items: DropdownItem[],  caseSensitive: boolean = true): DropdownItem | undefined {
        if (!caseSensitive)
            startsWith = startsWith.toLowerCase();

        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            const itemString = item.displayValue;

            if (itemString != null &&
                itemString.startsWith != null &&
                ((caseSensitive && itemString.startsWith(startsWith)) ||
                (!caseSensitive && itemString.toLowerCase().startsWith(startsWith)))) {
                return item;
            }
        }
        return undefined;
    }


    public static findByValue(value: string, array: ValueDisplayValue[]): DropdownItem {
        return ArrayUtil.findMatches(array, [value], (item, val) => item.value === val)[0] || null;
    }

    public static findMatchingValues(values: string[], array: ValueDisplayValue[]): ValueDisplayValue[] {
        return ArrayUtil.findMatches(array, values, (item, val) => item.value === val);
    }

    public static findByDisplayValue(displayValue: string, array: ValueDisplayValue[]): DropdownItem {
        return ArrayUtil.findMatches(array, [displayValue], (item, val) => item.displayValue === val)[0] || null;
    }

    public static findMatches(source: ValueDisplayValue[], input: ValueDisplayValue[]): DropdownItem[] {
        return ArrayUtil.findMatches(source, input, (item, val) => item.equals(val));
    }
}
