import { ArrayUtil, getThemeColor, ModelRow, ObjectUtil } from "@mcleod/core";
import { ClickEvent, ComponentCreator, MultiButton } from "../..";
import { ComponentListenerDefs } from "../../base/ComponentListenerDefs";
import { ComponentTypes } from "../../base/ComponentTypes";
import { DataSource, DataSourceMode } from "../../databinding/DataSource";
import { Label } from "../label/Label";
import { SaveButtonPropDefinitions, SaveButtonProps } from "./SaveButtonProps";

export enum SaveAction {
    SAVE = "save",
    SAVE_AND_CLOSE = "saveAndClose",
    SAVE_AND_ADD = "saveAndAdd"
}

/**
 * This class is an instance of a MultiButton that will post its associated DataSource(s).
 */
export class SaveButton extends MultiButton {
    private _allowSave: boolean = true;
    private _allowSaveAndClose: boolean = false;
    private _allowSaveAndAdd: boolean = false;
    private labelSave: Label;
    private labelSaveAndClose: Label;
    private labelSaveAndAdd: Label;
    public onClose: () => void;
    public onSave: (row: ModelRow<any>, mode: DataSourceMode) => void;
    private _addlActions: Label[] = [];
    private primaryButtonClickListener: (event: ClickEvent) => void;
    private _extraDataSources: DataSource[];
    private designerEnabledValue: boolean;
    private _enabledMap = new Map<DataSource, boolean>();
    private _saveAction: SaveAction;

    constructor(props?: Partial<SaveButtonProps>) {
        super({ id: props?.id }); // don't pass the full props because it could require labelSave/labelSaveAdd to have been initialized
        this.createStandardLabels();
        this.rebuildDropdownItems(); //call this here so that we build the button's options using default values (in case none of the 'allow' props were defined)
        this.busyWhenDataSourceBusy = true;
        this.setProps({
            color: SaveButtonPropDefinitions.getDefinitions().color.defaultValue,
            backgroundColor: SaveButtonPropDefinitions.getDefinitions().backgroundColor.defaultValue,
            minWidth: SaveButtonPropDefinitions.getDefinitions().minWidth.defaultValue,
            ...props
        });
        this.doOnDataSourceChanged = this.enableWhenChangesPresent;
    }

    get allowSave(): boolean {
        return this._allowSave;
    }

    set allowSave(value: boolean) {
        if (this._allowSave === value)
            return;
        this._allowSave = value;
        this.rebuildDropdownItems();
    }

    get allowSaveAndClose(): boolean {
        return this._allowSaveAndClose;
    }

    set allowSaveAndClose(value: boolean) {
        if (this._allowSaveAndClose === value)
            return;
        this._allowSaveAndClose = value;
        this.rebuildDropdownItems();
    }

    get allowSaveAndAdd(): boolean {
        return this._allowSaveAndAdd;
    }

    set allowSaveAndAdd(value: boolean) {
        if (this._allowSaveAndAdd === value)
            return;
        this._allowSaveAndAdd = value;
        this.rebuildDropdownItems();
    }

    get addlActions(): Label[] {
        return this._addlActions;
    }

    set addlActions(labels: Label[]) {
        this._addlActions = labels;
        this.rebuildDropdownItems();
    }

    get busy(): boolean {
        return super.busy;
    }

    set busy(value: boolean) {
        if (this.allowSaveAndClose === true && this.allowSave === true) {
            if (value !== true) {
                this.primaryButton.paddingTop = 10;
                this.primaryButton.paddingRight = 7;
                this.primaryButton.paddingBottom = 10;
                this.primaryButton.paddingLeft = 14;
                this.primaryButton.imageProps = { ...this.primaryButton.imageProps, marginRight: 4, marginLeft: 0 };
            }
            else {
                this.primaryButton.paddingTop = 4;
                this.primaryButton.paddingRight = 5;
                this.primaryButton.paddingBottom = 2;
                this.primaryButton.paddingLeft = 6;
                this.primaryButton.imageProps = { ...this.primaryButton.imageProps, marginRight: 0, marginLeft: 4 };
            }
        }
        if (this.primaryButton != null)
            super.busy = value;
    }

    private rebuildDropdownItems() {
        const existing: ComponentCreator[] = this.dropdownItems;
        const updatedItems: Label[] = [];
        this.addStandardActions(updatedItems);
        this.addAddlActions(updatedItems);
        if (ArrayUtil.isEmptyArray(updatedItems) === true) {
            //if we somehow ended up with no actions, default to just 'Save'
            updatedItems.push(this.labelSave);
        }
        const firstItem = updatedItems[0];
        this.updatePrimaryButton(firstItem);
        updatedItems.splice(0, 1);
        if (existing?.length !== updatedItems.length || !ObjectUtil.deepEqual(existing, updatedItems))
            this.dropdownItems = updatedItems;
    }

    private createStandardLabels() {
        this.labelSave = new Label({ text: SaveButtonPropDefinitions.getDefinitions().caption.defaultValue, onClick: () => this.save() });
        this.labelSaveAndClose = new Label({ text: "Save & Close", onClick: () => this.saveAndClose() });
        this.labelSaveAndAdd = new Label({ text: "Save & Add", onClick: () => this.saveAndAdd() });
    }

    private addStandardActions(items: Label[]) {
        if (this.allowSaveAndClose)
            items.push(this.labelSaveAndClose);
        if (this.allowSave)
            items.push(this.labelSave);
        if (this.allowSaveAndAdd)
            items.push(this.labelSaveAndAdd);
    }

    private addAddlActions(items: Label[]) {
        if (this.addlActions != null) {
            for (const addlAction of this.addlActions) {
                items.push(addlAction);
            }
        }
    }

    updatePrimaryButton(sourceLabel: Label) {
        this.primaryButton.caption = sourceLabel.text;
        this.primaryButton.removeClickListener(this.primaryButtonClickListener);
        this.primaryButtonClickListener = sourceLabel.getFirstListener(ComponentListenerDefs.click).wrapped;
        this.primaryButton.addClickListener(this.primaryButtonClickListener);
    }

    private save() {
        this.saveAction = SaveAction.SAVE;
        this.performPost();
    }

    private saveAndClose() {
        this.saveAction = SaveAction.SAVE_AND_CLOSE;
        this.performPost().then(row => {
            if (this.onClose != null)
                this.onClose();
        });
    }

    private saveAndAdd() {
        this.saveAction = SaveAction.SAVE_AND_ADD;
        this.performPost();
    }

    private async performPost(): Promise<ModelRow> {
        if (this.dataSource != null) {
            const mode = this.dataSource.mode;
            return this.dataSource.post().then(async postedRow => {
                if (this.onSave != null) {
                    // Pass DataSource mode as it was before posting, so that the onSave knows if we added or updated
                    // the row.  You can't check the mode within the onSave() callback, because by then the mode will
                    // have changed from ADD to UPDATE as part of the post.
                    this.onSave(postedRow, mode);
                }
                if (this.extraDataSources != null)
                    for (const source of this.extraDataSources)
                        await source.post();
                return postedRow;
            });
        }
    }

    public get extraDataSources(): DataSource[] {
        return this._extraDataSources;
    }

    /**
     * The extraDataSources prop allows the SaevButton to be tied to multiple DataSources.
     * This means that we can create a page with data that comes from different models and
     * post it all in one click (without the need for DataSource listeners).
     * Currently this prop isn't available in the UI Designer and must be set from code
     * (typically the onLoad() method).  At some point (with some UI Designer work), it's planned that
     * this prop is settable in the UI Designer.
     * Any change made to any of its DataSources will cause the SaveButton to be enabled.
     * Clicking the SaveButton will post the main dataSource and all its extraDataSources.
     */
    public set extraDataSources(value: DataSource[]) {
        if (this._extraDataSources != null) {
            for (const source of this._extraDataSources) {
                source.removeHasChangedComponent(this);
                this._enabledMap.delete(source);
            }
        }
        this._extraDataSources = value;
        if (this._extraDataSources != null) {
            for (const source of value)
                source.addHasChangedComponent(this);
        }
        this._setEnabledFromDataSources();
    }

    /*
    public addExtraDataSource(value: DataSource) {
      const tempExtra = [];

      if (this._extraDataSources != null) {
        for (const source of this._extraDataSources) {
          tempExtra.push(source);
        }
      }
      tempExtra.push(value);
      this.extraDataSources = tempExtra;
    }
    */

    private _setEnabledFromDataSources() {
        const allDataSources = [];
        if (this.dataSource != null)
            allDataSources.push(this.dataSource);
        if (ArrayUtil.isEmptyArray(this._extraDataSources) !== true)
            allDataSources.push(...this._extraDataSources);

        this._enabledMap.clear();
        let enabled = ArrayUtil.isEmptyArray(allDataSources) !== true;
        for (const source of allDataSources) {
            const dsChanged = source.hasChanged();
            this._enabledMap.set(source, dsChanged);
            enabled = enabled && dsChanged;
        }
        this.internalSetEnabled(enabled);
        this._setDisabledTooltip(this.enabled);
    }

    /**
     * Override get/set enabled methods to allow us to keep track of an enabled value that is valid in the designer.
     * We don't want the SaveButton to become disabled in the UI designer (which it can), and then have that enabled
     * value be serialized into the layout.  Instead, the designerEnabledValue, which should be the value of enabled
     * without any automated setting of the value based on DataSources, should be the value that is serialized.
     */
    public override get enabled(): boolean {
        if (this.__designer != null)
            return this.designerEnabledValue;
        return super.enabled;
    }

    public override set enabled(value: boolean) {
        this.internalSetEnabled(value);
        this.designerEnabledValue = value;
    }

    private internalSetEnabled(value: boolean) {
        super.enabled = value;
    }

    public get dataSource(): DataSource {
        return super.dataSource;
    }

    public set dataSource(value: DataSource) {
        if (this.dataSource === value)
            return;
        this.dataSource?.removeHasChangedComponent(this);
        this._enabledMap.delete(this.dataSource);
        super.dataSource = value;
        this.dataSource?.addHasChangedComponent(this);
        this._setEnabledFromDataSources();
    }

    public removeFromHasChangedComponents() {
        this.dataSource?.removeHasChangedComponent(this);
        if (this._extraDataSources != null) {
            for (const source of this._extraDataSources) {
                source.removeHasChangedComponent(this);
                this._enabledMap.delete(source);
            }
        }
    }

    private _setDisabledTooltip(value: boolean) {
        if (value === false)
            this.disabledTooltip = "You cannot save this record because nothing has changed.";
        else
            this.disabledTooltip = null;
    }

    enableWhenChangesPresent(dataSource: DataSource, value: boolean) {
        this._enabledMap.set(dataSource, value);
        let enabled = false;
        for (const value of this._enabledMap.values()) {
            enabled = enabled || value;
        }
        this.internalSetEnabled(enabled);
        this._setDisabledTooltip(this.enabled);
    }

    override getPropertyDefinitions() {
        return SaveButtonPropDefinitions.getDefinitions();
    }

    override get serializationName() {
        return "savebutton";
    }

    override get properName(): string {
        return "Save Button";
    }

    override _applyEnabled(value: boolean): void {
        if (value) {
            this._element.removeAttribute("disabled");
            this.primaryButton._element.removeAttribute("disabled");
            this.dropdownButton._element.removeAttribute("disabled");
            this.color = this.color;
            this.backgroundColor = this.backgroundColor;
            this.cursor = this.cursor;
        }
        else {
            if (this.__designer == null) {
                this._element.setAttribute("disabled", "true");
                this.primaryButton._element.setAttribute("disabled", "true");
                this.dropdownButton._element.setAttribute("disabled", "true");
            }
            this._element.style.backgroundColor = getThemeColor("primary.lightest");
            this._element.style.color = getThemeColor("subtle.light");
            this._element.style.cursor = "";
        }
    }

    set saveAction(value: SaveAction) {
        this._saveAction = value;
    }

    get saveAction(): SaveAction {
        return this._saveAction;
    }
}

ComponentTypes.registerComponentType("savebutton", SaveButton.prototype.constructor, false);
