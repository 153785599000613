import { ModelRow } from "@mcleod/core";
import { DesignableObject } from "../base/DesignableObject";
import { EventListener } from "./Event";
import { TypedSelectionEvent } from "./TypedSelectionEvent";

export interface LookupModelSelectionListener extends EventListener {
    (event: LookupModelSelectionEvent): void;
}

export class LookupModelSelectionEvent extends TypedSelectionEvent<ModelRow> {

    constructor(component: DesignableObject, selectedRows?: ModelRow | ModelRow[],
        deselectedRows?: ModelRow | ModelRow[]) {
        super(component, selectedRows, deselectedRows);
    }

    /**
     * Gets a value from a selected row.  If the selected row is not present or is null, then a null value is returned.
     * This can be useful as a one-line solution when a selection listener wants to fill in some associated field on
     * selection, but null it out on deselection.
     * @param field The field name for which data should be retrieved.
     * @param row The ModelRow containing lookup model data for the selected item.  If not provided, the first selected
     *            item's ModelRow will be used.
     * @returns The value from the lookup model data for the specified field.
     */
    public getValueFromSelectedRow(field: string, row: ModelRow = this.selection): any {
        return this.getValueFromRow(field, row);
    }

    public getSelectedDescr(fieldName: string): string {
        return this.getDescr(fieldName, this.selections);
    }

    /**
     * Gets a value from a deselected row.  If the deselected row is not present or is null, then a null value is
     * returned.
     * @param field The field name for which data should be retrieved.
     * @param row The ModelRow containing lookup model data for the deselected item.  If not provided, the first
     *            deselected item's ModelRow will be used.
     * @returns The value from the lookup model data for the specified field.
     */
    public getValueFromDeselectedRow(field: string, row: ModelRow = this.deselection): any {
        return this.getValueFromRow(field, row);
    }

    public getDeselectedDescr(fieldName: string): string {
        return this.getDescr(fieldName, this.deselections);
    }

    private getDescr(fieldName: string, rows: ModelRow[]) {
        const descrs: string[] = [];
        for (const row of rows) {
            descrs.push(row.get(fieldName, ""));
        }
        return descrs.join(", ");
    }

    public getValueFromRow(field: string, row: ModelRow): any {
        return row?.get(field, null) ?? null;
    }
}
