polyfillString();

function polyfillString() {
    if (String.prototype.startsWith === undefined)
        String.prototype.startsWith = function (searchString: string, position?: number): boolean {
            position = position || 0;
            return this.indexOf(searchString, position) === position;
        };

    if (String.prototype.endsWith === undefined)
        String.prototype.endsWith = function (search: string, this_len?: number): boolean {
            if (this_len === undefined || this_len > this.length)
                this_len = this.length;
            return this.substring(this_len - search.length, this_len) === search;
        };

    if (String.prototype.includes === undefined)
        String.prototype.includes = function (searchString: string): boolean {
            return this.indexOf(searchString) >= 0;
        };
}

export class StringUtil {
    public static replaceAll(str: string, find: string, replace: string): string {
        return str.replace(new RegExp(find, 'g'), replace);
    }

    public static toLowerCamelCase(str: string): string {
        if (str == null)
            return null;
        let result = "";
        for (let i = 0; i < str.length; i++) {
            const c = str.charAt(i);
            if ((c === "_" || c === ' ' || c === '-' || c === '.') && i < str.length - 1)
                result += str.charAt(i++ + 1).toUpperCase();
            else
                result += c;
        }
        return result;
    }

    public static toProperCase(str: string): string {
        return str.toLowerCase().replace(/^(.)|\s(.)/g,
            function ($1) { return $1.toUpperCase(); });
    }

    public static isEmptyString(obj: any): boolean {
        if (obj == null)
            return true;
        else if (obj.trim === undefined || obj.length === undefined)
            return false;
        else
            return obj.trim().length === 0;
    }

    public static rtrim(str: string): string {
        return str.replace(/\s+$/, "");
    }

    public static ltrim(str: string): string {
        return str.replace(/^\s+/, "");
    }

    public static stringAfter(searchIn: string, searchFor: string, includeSearchFor: boolean = false, resultIfNotFound?: string): string {
        if (resultIfNotFound === undefined)
            resultIfNotFound = searchIn;
        if (searchIn == null || searchFor == null)
            return resultIfNotFound;
        let index = searchIn.indexOf(searchFor);
        if (index < 0)
            return resultIfNotFound;
        if (includeSearchFor === false)
            index += searchFor.length;
        return searchIn.substring(index);
    }

    public static stringAfterLast(searchIn: string, searchFor: string, includeSearchFor: boolean = false, resultIfNotFound?: string): string {
        if (resultIfNotFound === undefined)
            resultIfNotFound = searchIn;
        if (searchIn == null || searchFor == null)
            return resultIfNotFound;
        let index = searchIn.lastIndexOf(searchFor);
        if (index < 0)
            return resultIfNotFound;
        if (includeSearchFor === false)
            index += searchFor.length;
        return searchIn.substring(index);
    }

    public static stringBefore(searchIn: string, searchFor: string, includeSearchFor: boolean = false, resultIfNotFound?: string): string {
        if (resultIfNotFound === undefined)
            resultIfNotFound = searchIn;
        if (searchIn == null || searchFor == null)
            return resultIfNotFound;
        let index = searchIn.indexOf(searchFor);
        if (index < 0)
            return resultIfNotFound;
        if (includeSearchFor === true)
            index += searchFor.length;
        return searchIn.substring(0, index);
    }

    public static stringBeforeLast(searchIn: string, searchFor: string, includeSearchFor: boolean = false, resultIfNotFound?: string): string {
        if (resultIfNotFound === undefined)
            resultIfNotFound = searchIn;
        if (searchIn == null || searchFor == null)
            return resultIfNotFound;
        let index = searchIn.lastIndexOf(searchFor);
        if (index < 0)
            return resultIfNotFound;
        if (includeSearchFor === true)
            index += searchFor.length;
        return searchIn.substring(0, index);
    }

    public static stringBetween(searchIn: string, searchStart: string, searchEnd: string, resultIfNotFound?: string): string {
        if (searchIn == null)
            return resultIfNotFound;
        let startIndex, endIndex;
        if (searchStart == null)
            startIndex = 0;
        else {
            startIndex = searchIn.indexOf(searchStart);
            if (startIndex < 0)
                return resultIfNotFound;
        }
        if (searchEnd == null)
            endIndex = searchIn.length;
        else {
            endIndex = searchIn.indexOf(searchEnd, startIndex + searchStart.length);
            if (endIndex < 0)
                return resultIfNotFound;
        }
        return searchIn.substring(startIndex + searchStart.length, endIndex);
    }

    /**
     * Removes a string from the end of the source string if it is present.  For example, this can be used to remove a trailing period from a sentence if a period is present as the last character in the source string.
     * If the trailer string is found, a new string is returned (from which the trailer string has been removed).
     * If the trailer string is not found, the original source string is returned.
     *
     * @param sourceString
     * The string that will be tested to see if it ends with the trailer string.
     * @param trailer
     * The string that will be removed from the source string if it is present.
     */
    public static removeTrailingString(sourceString: string, trailer: string): string {
        if (sourceString.endsWith(trailer))
            return sourceString.substring(0, sourceString.length - trailer.length);
        return sourceString;
    }

    /**
     * This function is used to quickly format strings that could be singular or plural based on the some number.  For example, we might have
     * some order count and want to display "6 orders", "1 order", or "No orders".
     *
     * @param count The number to use when deciding if we should use the singlular or plural suffix
     * @param singlularSuffix The string to append to the number if the number is singular
     * @param pluralSuffix The string to append to the number if the number is plural
     * @param zeroPrefix The string to use as the prefix if count is 0.  This defaults to "No" (e.g. "No orders") but may often be set to "0" (e.g. "0 orders")
     * @returns A string with the count followed by the appropriate suffix
     */

    public static pluralString(count: number, singlularSuffix: string, pluralSuffix: string, zeroPrefix: string = "No") {
        if (count === 0)
            return zeroPrefix + " " + pluralSuffix;
        else if (count === 1)
            return "1 " + singlularSuffix;
        else
            return count + " " + pluralSuffix;
    }

    /**
     * Performs a case insensitve comparison with strings.
     * @param value
     * @param compareTo
     * @returns
     */
    public static equalsIgnoreCase(value: string | undefined, compareTo: string | undefined): boolean {
        return StringUtil.compare(value, compareTo) === 0;
    }

    /**
     * Compares two strings using localeCompare in a null-safe manner.
     * @param a The first string (or null) to compare.
     * @param b The second string (or null) to compare.
     * @param ignoreCase Determines if the comparison should be case-insensitive.
     * @param nullsLast  Determines the comparative value of nulls, treating them as greater than non-null values if true.
     * @returns -1 if 'a' < 'b', 1 if 'a' > 'b', or 0 if they are considered equal.
     */
    public static compare(a: string | null, b: string | null, ignoreCase: boolean = true, nullsLast: boolean = false): number {
        if (a == null && b == null) return 0;
        if (a == null) return nullsLast ? 1 : -1;
        if (b == null) return nullsLast ? -1 : 1;
        const options: Intl.CollatorOptions = { sensitivity: ignoreCase ? 'accent' : 'variant' };
        // localeCompare doesn't guarantee -1 or 1, so we use Math.sign to convert to negative values to -1 and positive values to 1
        return Math.sign(a.localeCompare(b, undefined, options));
    }

    /**
     * This function does the same thing as Java's MessageFormat.format() function.
     * It replaces the {0}, {1}, etc. in the string with the values passed in.
     * @param str
     * @param args
     */
    public static stringFormat(str, ...args) {
        return str.replace(/{(\d+)}/g, function (match, number) {
            return typeof args[number] != 'undefined' ? args[number] : match;
        });
    }

    public static singleQuote(str: string): string {
        return "'" + str + "'";
    }

    public static capitalize(string: string): string {
        if (this.isEmptyString(string))
            return string;
        if (string.length === 1)
            return string.toUpperCase();
        return string.substring(0, 1).toUpperCase() + string.substring(1);
    }

    public static join(separator: string, strings: string[]): string {
        return strings?.filter(s => !StringUtil.isEmptyString(s)).join(separator);
    }

    public static removeSpecialChars(value: string, ...allowedChars: string[]): string {
        if (StringUtil.isEmptyString(value))
            return value;

        const escapedChars = allowedChars.map(char => `\\${char}`).join("");
        const regex = new RegExp(`[^a-zA-Z0-9${escapedChars}]`, "g");
        return value.replace(regex, "");
    }
}
