import { LogManager, StringUtil } from "@mcleod/core";

const log = LogManager.getLogger("components.table.ComponentSearchResult");

export class ComponentSearchResult {
    private _values: string[];
    private _fieldName: string;
    private _alias: string;

    constructor(values: string[], fieldName: string, alias?: string) {
        this.values = values;
        this.fieldName = fieldName;
        this.alias = alias;
    }

    get values(): string[] {
        return this._values;
    }

    private set values(values: string[]) {
        this._values = values;
    }

    get fieldName(): string {
        return this._fieldName;
    }

    private set fieldName(value: string) {
        this._fieldName = value
    }

    get alias(): string {
        return this._alias;
    }

    private set alias(value: string) {
        this._alias = this.alias;
    }

    addValue(value: string) {
        if (StringUtil.isEmptyString(value) === false && this.values.includes(value) === false) {
            this.values.push(value);
            log.debug("Added result for field %s: %s", this.fieldName, value);
        }
    }
}
