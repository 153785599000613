/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelCustomUiLayout.ts                *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "common/custom-ui-layout";

export class AutogenModelCustomUiLayout<RowType extends ModelRow> extends Endpoint<RowType, RowCustomUiLayoutFields> {
	constructor(ctor: RowConstructor) {
		super(endpointPath, ctor);
	}
}

export class AutogenRowCustomUiLayout extends ModelRow<RowCustomUiLayoutFields> {
	constructor(values?: Partial<RowCustomUiLayoutFields>) {
		super(endpointPath, true, values);
	}
}

export interface RowCustomUiLayoutFields {
	base_commit_id: string;
	create_datetime: Date;
	create_user_id: string;
	definition: string;
	descr: string;
	id: string;
	is_active: string;
	name: string;
	update_datetime: Date;
	upgrade_results: string;
}

