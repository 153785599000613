import { KeyListener } from "./KeyEvent";
import { ModifierKeys } from "./KeyModifiers";

export interface KeyHandler {
    key: string;
    modifiers?: ModifierKeys;
    listener: KeyListener;
    element?: HTMLElement;
    scope?: HTMLElement;
}

export function haveSameScope(handlerA: KeyHandler, handlerB: KeyHandler) {
    if (handlerA.scope == null && handlerB.scope == null)
        return true;
    return handlerA.scope === handlerB.scope;
}