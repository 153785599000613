import { DataSource } from "..";
import { Event, EventListener } from "./Event";

export interface DataSourceSubscriberErrorListener extends EventListener {
    (event: DataSourceSubscriberErrorEvent): void;
}

export class DataSourceSubscriberErrorEvent extends Event {
    private _error: string;

    constructor(dataSource: DataSource, error: string) {
        super(dataSource, null);
        this._error = error;
    }

    public get error(): string {
        return this._error;
    }
}
