import { DataSource, Layout, Tab, Table, Tabset, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutUser extends Layout {
    sourceTenantUser: DataSource;
    sourceUsers: DataSource;
    tabTenants: Tab;
    tableTenants: Table;
    tabsetSettings: Tabset;
    textboxDefaultOnPremUserId: Textbox;
    textboxEmailAddress: Textbox;
    textboxName: Textbox;
    textboxUserType: Textbox;
}
