import { IFrame, Layout } from "@mcleod/components";
import { AuthToken, AuthType, LogManager, Navigation } from "@mcleod/core";

const log = LogManager.getLogger("auth.Login");

export class Login extends Layout {
    forwardDest: string;
    private static debug: boolean;


    constructor(props?: any) {
        super({
            auth: AuthType.UNAUTH,
            fillRow: true,
            needsServerLayout: false,
            ...props
        });
        Login.debug = this["debug"] === "true"; // property can be set by adding &debug=true to the URL
        this.loadSSOLogin();
    }

    getRouterProps() {
        return { padding: 0 };
    }

    private loadSSOLogin() {
        this.removeAll();
        const iframe = new IFrame({ fillHeight: true, fillRow: true, isRow: true });
        iframe.src = Login.getSSOUrl("origin=" + window.location.origin);
        this.add(iframe);
        const eventListener = (event) => {
            log.debug("Received message", event);
            if (event.origin === "https://onemcleod.com" && typeof(event.data) === "object") {
                if (event.data.token != null) {
                    window.removeEventListener("message", eventListener);
                    log.debug("Received token from login iframe", event.data);
                    AuthToken.set(event.data.token, true);
                    if (this.forwardDest == null || this.isDisallowedForward(this.forwardDest))
                        Navigation.navigateTo("");
                    else
                        Navigation.navigateTo(this.forwardDest);
                }
            }
        };
        window.addEventListener("message", eventListener);
    }

    private static getSSOUrl(params: string) {
        let result = "https://onemcleod.com/login.html?";
        if (params != null)
            result += params;
        if (Login.debug === true)
            result += "&debug=true";
        return result;
    }

    isDisallowedForward(path: string) {
        return (path === "common/SessionExpired" ||
            path === "common/SignInNeeded" ||
            path === "common/Login" ||
            path === "common/NoLicensesAvailable");
    }

    // public static logout() {
    //     Api.post("logout").then(response => {
    //         Auth0.logout();
    //         log.debug("Logout response %o", response);
    //     }).catch(err => {
    //         log.error("Error", err)
    //     });
    //     AuthToken.clear();
    //     setTimeout(function () { Navigation.navigateTo("common/Login", { hardRefresh: true }) }, 750);
    // }
}
