import { DialogProps } from "@mcleod/components";
import { AbstractPanelOpenTree } from "../AbstractPanelOpenTree";
import { GeneralSettings, StringUtil } from "@mcleod/core";

export class PanelOpenPortalLayoutMenu extends AbstractPanelOpenTree {
    override getDialogProps(): Partial<DialogProps> {
        return {
            ...super.getDialogProps(),
            title: "Open Layout"
        };
    }

    override getResultValue(): string {
        const selectedNode = this.getSelectedNode();
        if (selectedNode == null)
            return null;
        
        let path = "";
        selectedNode.path.forEach((node) => {
            path += (node.data.unmodified_name == null ? node.text : node.data.unmodified_name) + "/";
        });

        // When running locally, we will have a root node that doesn't exist when running deployed.  (That root node
        // represents the service project folder of mcleod-api-portal.)  We don't need that included in the path, so
        // get rid of it.
        if (GeneralSettings.getSingleton().isRunningInIDE())
            path = StringUtil.stringAfter(path, "/");

        path = path.substring(0, path.length - 1); //remove trailing slash
        return path;
    }

    override getApiName(): string {
        return "tailor/portal-layout-list";
    }

    override getCustomizedTooltip(): string {
        return "This is a customized layout.";
    }
}
