import { CommonDialogs } from "@mcleod/common";
import { Layout, PanelProps, SaveButton, SlideoutDecorator, SlideoutDecoratorProps } from "@mcleod/components";
import { ArrayUtil } from "@mcleod/core";
import { SpecialtyPropertyEditor } from "./SpecialtyPropertyEditor";

export abstract class SlideoutPropertyEditor extends SpecialtyPropertyEditor {
    private saveClicked: boolean;
    protected errorMsg: string;

    constructor(props?: Partial<PanelProps>) {
        super(props);
    }

    getSlideoutProps(): Partial<SlideoutDecoratorProps> {
        return {};
    }

    public override get wasCancelled(): boolean {
        return this.saveClicked !== true;
    }

    public override async show(): Promise<any> {
        if (this.errorMsg) {
            return CommonDialogs.showError(this.errorMsg);
        }
        return this.showSlideout();
    }

    private showSlideout(): Promise<void> {
        const slideoutProps = this.getSlideoutProps();
        let layout = slideoutProps.layout;
        delete slideoutProps.layout;
        if (layout == null) {
            layout = new Layout({ fillHeight: true, fillRow: true, needsServerLayout: false });
            layout.add(this);
        }

        let slideoutDecorator: SlideoutDecorator;
        const buttonOk = new SaveButton({
            rowBreak: false,
            onClick: () => {
                if (this.internalValidate(true)) {
                    this.saveClicked = true;
                    slideoutDecorator.slideOut();
                }
            }
        });

        return new Promise<void>((resolve) => {
            slideoutDecorator = new SlideoutDecorator({
                width: "60%",
                ...this.getSlideoutProps(),
                overlayProps: {closeOnClickOff: false, greyedBackground: true},
                layout: layout,
                fillVerticalSpace: true,
                addlComponents: [buttonOk],
                onClose: async () => {
                    await slideoutDecorator.slideOut();
                 },
                 doAfterSlideOut: () => {
                    resolve();
                 }
            });
        });
    }


    internalValidate(checkRequired: boolean, showErrors: boolean = true) {
        const errors = this.validate(true)?.filter((r) => r.isValid === false);
        if (ArrayUtil.isEmptyArray(errors)) {
            return true;
        }
        const errorMessages = new Set(errors.map((r) => r.validationMessage));
        if (errorMessages.size === 0) {
            errorMessages.add("Unable to save due to validation errors.");
        }
        CommonDialogs.showError(Array.from(errorMessages).join("\n"));
        return false;
    }
}
