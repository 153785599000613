import { getThemeColor, Styles } from "@mcleod/core";

export const DropdownItemListStyles = Styles.make("dropdownItem", () => {
    const result = {
        listItem: {
            "&:focus": {
                background: getThemeColor("primary.lightest")
            },
            "&:focus-visible": {
                outline: "none",
                border: "none"
            }
        }
    };
    return result;
});
