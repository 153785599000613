
export class ValueDisplayValue {
    private _value: string;
    private _displayValue: string;
    private _addlValues: Map<string, any>;

    constructor(value: string, displayValue?: string) {
        this.value = value;
        this.displayValue = displayValue;
    }

    get value(): string {
        return this._value;
    }

    set value(value: string) {
        this._value = value;
    }

    get displayValue(): string {
        return this._displayValue ?? this.value;
    }

    set displayValue(value: string) {
        this._displayValue = value;
    }

    public equals(otherVDV: ValueDisplayValue): boolean {
        if (otherVDV == null)
            return false;
        return this.value === otherVDV.value;
    }

    private initAddlValues() {
        if (this._addlValues == null)
            this._addlValues = new Map();
    }

    public getAddlValue(key: string) {
        return this._addlValues?.get(key);
    }

    public getAddlValues(): Map<string, any> {
        return this._addlValues;
    }

    public setAddlValue(key: string, value: any) {
        this.initAddlValues();
        this._addlValues.set(key, value);
    }

    public copyAddlValues(sourceVDV: ValueDisplayValue) {
        sourceVDV?.getAddlValues()?.forEach((key: string, value: any) => this.setAddlValue(key, value));
    }

    public static getValuesAsString(array: ValueDisplayValue[], delimiter: string = "|"): string {
        return array?.map(vdv => vdv.value).join(delimiter) ?? "";
    }

    public static getDisplayValuesAsString(array: ValueDisplayValue[], delimiter: string = ", "): string {
        return array?.map(vdv => vdv.displayValue).join(delimiter) ?? "";
    }

    /**
     * Implement toJSON so that we only serialize the parts of the object that need to be saved in a layout file.
     * @returns any
     */
    public toJSON(): any {
        const result: any = {
            value: this.value,
            displayValue: this.displayValue
        };
        return result;
    }
}
