import { Label, Layout, Panel, Switch, Textbox, Tree } from "@mcleod/components";

export abstract class AutogenLayoutPermissionsMain extends Layout {
    labelPageTitle: Label;
    layoutPermsGrant: Layout;
    panel1: Panel;
    panel3: Panel;
    panelMain: Panel;
    switchPermsSet: Switch;
    textSearchMenu: Textbox;
    treeMenu: Tree;
}
