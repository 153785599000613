import { CloseEvent } from "./CloseEvent";
import { DataHeader } from "../components/dataheader/DataHeader";
import { DataHeaderCloseAction } from "../components/dataheader/DataHeaderCloseAction";
import { DataSourceMode } from "../databinding/DataSource";
import { DomEvent } from "./DomEvent";
import { EventListener } from "./Event";

export interface CrudDecoratorCloseListener extends EventListener {
    (event: CrudDecoratorCloseEvent): void;
}

export class CrudDecoratorCloseEvent extends CloseEvent {
    private _dataSourceMode: DataSourceMode;
    private _closeAction: DataHeaderCloseAction;

    constructor(dataHeader: DataHeader, dataSourceMode: DataSourceMode, closeAction: DataHeaderCloseAction,
        isBefore: boolean, domEvent?: DomEvent) {
        super(dataHeader, isBefore, domEvent);
        this._dataSourceMode = dataSourceMode;
        this._closeAction = closeAction;
    }

    get dataHeader(): DataHeader {
        return this.target as DataHeader;
    }

    get dataSourceMode(): DataSourceMode {
        return this._dataSourceMode;
    }

    get closeAction(): DataHeaderCloseAction {
        return this._closeAction;
    }

    public isExecute(): boolean {
        return DataHeaderCloseAction.EXECUTE === this.closeAction
    }

    public isCancel(): boolean {
        return DataHeaderCloseAction.CANCEL === this.closeAction;
    }

    public isDelete(): boolean {
        return DataHeaderCloseAction.DELETE === this.closeAction;
    }

    public isAdd(): boolean {
        return this.dataSourceMode === DataSourceMode.ADD;
    }

    public isUpdate(): boolean {
        return this.dataSourceMode === DataSourceMode.UPDATE;
    }

    public isAddOrUpdate(): boolean {
        return this.isAdd() === true || this.isUpdate() === true;
    }

    public isSearch(): boolean {
        return this.dataSourceMode === DataSourceMode.SEARCH;
    }

    public userExecutedAdd(): boolean {
        return this.isExecute() && this.isAdd();
    }

    public userExecutedUpdate(): boolean {
        return this.isExecute() && this.isUpdate();
    }

    public userExecutedAddOrUpdate(): boolean {
        return this.isExecute() && this.isAddOrUpdate();
    }

    public userExecutedSearch(): boolean {
        return this.isExecute() && this.isSearch();
    }

    public userCancelledAdd(): boolean {
        return this.isCancel() && this.isAdd();
    }

    public userCancelledUpdate(): boolean {
        return this.isCancel() && this.isUpdate();
    }

    public userCancelledAddOrUpdate(): boolean {
        return this.isCancel() && this.isAddOrUpdate();
    }

    public userCancelledSearch(): boolean {
        return this.isCancel() && this.isSearch();
    }

    public userDeleted(): boolean {
        return this.isDelete();
    }
}
