import { BlobUtil } from "./BlobUtil";
import { MimeTypes } from "./constants/MimeTypes";

export class FileUtil {
    public static showOpenFileDialog(): Promise<File> {
        const elem = document.createElement("input");
        elem.type = "file";
        elem.style.position = "fixed";
        elem.style.top = "-100em";
        elem.click();
        return new Promise(resolve => {
            elem.onchange = (event) => {
                resolve(elem.files[0]);
            }
        });
    }

    public static downloadBase64AsFile(base64String: string, fileName: string) {
        const blob = BlobUtil.toBlob(base64String, MimeTypes.OCTET_STREAM);
        const download = document.createElement("a");
        download.href = window.URL.createObjectURL(blob);
        download.download = fileName;
        download.click();
        download.remove();
    }

    public static getExtension(file: File): string {
        return FileUtil.getExtensionFromFileName(file.name);
    }

    public static getExtensionFromFileName(fileName: string): string {
        const fileNameLower = fileName.toLowerCase();
        return fileNameLower.substring(fileNameLower.lastIndexOf("."));
    }
}