import { DOMUtil, FileUtil } from "@mcleod/core";
import { Button } from "../button/Button";
import { ButtonVariant } from "../button/ButtonVariant";
import { HorizontalSpacer } from "../../page/HorizontalSpacer";
import { Image } from "../image/Image";
import { ImageNavControls } from "./ImageNavControls";
import { Label } from "../label/Label";
import { Overlay } from "../../page/Overlay";
import { OverlayProps } from "../../page/OverlayProps";
import { Panel } from "../panel/Panel";
import { PanelProps } from "../panel/PanelProps";
import { ImageProps } from "../image/ImageProps";
import { ReflectiveSnackbars } from "../../base/ReflectiveSnackbars";

export interface ImageViewerProps extends PanelProps {
    imageBase64: string;
    downloadFileName: string;
    title: string;
}

export class ImageViewer extends Panel {
    private panelHeaderCenter: Panel;
    private _imageBase64: string;
    private labelTitle: Label;
    private navControls: ImageNavControls;
    private buttonDownload: Button;
    private buttonClose: Button;
    private image: Image;
    public downloadFileName: string;
    private overlay: Overlay;

    constructor(props?: Partial<ImageViewerProps>) {
        super(props, false);
        const panelHeader = this.createHeaderPanel();
        this.createImage(props?.downloadFileName);
        this.add(panelHeader, this.image);
        this.setProps({ padding: 0, ...props });
    }

    private createHeaderPanel(): Panel {
        //create left portion of header
        this.createTitleLabel();
        const panelHeaderLeft = Panel.createNoMarginNoPaddingPanel({ fillRow: true, rowBreakDefault: false });
        panelHeaderLeft.add(this.labelTitle);

        //create center portion of header
        this.panelHeaderCenter = Panel.createNoMarginNoPaddingPanel({ fillRow: true, rowBreakDefault: false });

        //create right portion of header
        const panelHeaderRight = Panel.createNoMarginNoPaddingPanel({ fillRow: true, rowBreakDefault: false });
        this.createDownloadButton();
        this.createCloseButton();
        panelHeaderRight.add(new HorizontalSpacer(), this.buttonDownload, this.buttonClose);

        //create complete header
        const panelHeader = new Panel({
            fillRow: true,
            themeKey: "dialog.header",
            backgroundColor: "primary.darker",
            color: "primary.reverse",
            rowBreakDefault: false
        });
        panelHeader.add(panelHeaderLeft, this.panelHeaderCenter, panelHeaderRight);
        return panelHeader;
    }

    private createTitleLabel() {
        this.labelTitle = new Label({ paddingRight: 12, fillRow: true, rowBreak: false });
    }

    private createDownloadButton() {
        this.buttonDownload = new Button({ variant: ButtonVariant.round, tooltip: "Download this image", imageName: "download", rowBreak: false })
        this.buttonDownload.addClickListener(event => {
            FileUtil.downloadBase64AsFile(this.imageBase64, this.downloadFileName);
            ReflectiveSnackbars.showSnackbar("Downloaded " + this.downloadFileName);
        });
    }

    private createCloseButton() {
        this.buttonClose = new Button({
            variant: ButtonVariant.round,
            tooltip: "Close this image preview",
            imageName: "x",
            cancel: true
        })
        this.buttonClose.addClickListener(() => Overlay.hideOverlay(this.overlay));
    }

    private createImage(downloadFileName: string) {
        const imageProps: Partial<ImageProps> = { fillRow: true, fillHeight: true, preserveAspectRatio: true };
        this.image = Image.createFileBasedImage(imageProps, downloadFileName);
    }

    private syncMultiPageControls() {
        if (this.image?.isMultiPage === true) {
            this.initNavControls();
            if (this.panelHeaderCenter.contains(this.navControls) === false)
                this.panelHeaderCenter.add(this.navControls);
        }
        else if (this.navControls != null)
            this.panelHeaderCenter.remove(this.navControls);
    }

    private initNavControls() {
        if (this.navControls == null) {
            this.navControls = new ImageNavControls({ marginRight: 20, rowBreak: false });
            this.navControls.image = this.image;
        }
        else
            this.navControls.reset();
    }

    setProps(props: Partial<ImageViewerProps>) {
        super.setProps(props);
    }

    get imageBase64(): string {
        return this._imageBase64;
    }

    set imageBase64(value: string) {
        this._imageBase64 = value;
        this.image.imageBytes = value;
        this.syncMultiPageControls();
    }

    get title(): string {
        return this.labelTitle.text;
    }

    set title(value: string) {
        this.labelTitle.text = value;
    }

    public showAsOverlay(overlayProps: Partial<OverlayProps>, sizeImageToOverlay: boolean = true) {
        this.overlay = Overlay.showInOverlay(this, {
            style: {
                width: "94%",
                height: "94%",
                position: "absolute",
                left: "3%",
                top: "3%",
                backgroundColor: "#eee",
                borderRadius: "4px"
            },
            coverPageHeader: false,
            ...overlayProps
        });

        if (sizeImageToOverlay === true) {
            //ensure the image doesn't overrun the bounds of the overlay
            this.sizeImageToOverlay();
            this.overlay.getOverlayContent().addResizeListener(() => {
                this.sizeImageToOverlay();
            });
        }
    }

    private sizeImageToOverlay() {
        const overlayContentElement = this.overlay.getOverlayContent()._element;
        this.height = DOMUtil.getElementHeightString(overlayContentElement);
        this.width = DOMUtil.getElementWidthString(overlayContentElement);
    }

    public static eligibleToView(fileName: string) {
        const fileNameLower = fileName.toLowerCase();
        return fileNameLower.endsWith(".jpg") || fileNameLower.endsWith(".jpeg") || fileNameLower.endsWith(".png") ||
            fileNameLower.endsWith(".bmp") || fileNameLower.endsWith(".gif") || fileNameLower.endsWith(".tiff") ||
            fileNameLower.endsWith(".pdf");
    }

    public static viewImage(imageBase64: string, downloadFileName: string,
        overlayProps?: Partial<OverlayProps>, sizeImageToOverlay: boolean = true) {
        ImageViewer.getStandardViewer(imageBase64, downloadFileName).showAsOverlay(overlayProps, sizeImageToOverlay);
    }

    private static getStandardViewer(imageBase64: string, downloadFileName: string): ImageViewer {
        return new ImageViewer({
            width: "100%",
            height: "100%",
            imageBase64: imageBase64,
            borderShadow: true,
            downloadFileName: downloadFileName,
            title: downloadFileName
        });
    }
}
