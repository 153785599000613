import { ClickEvent, SlideoutDecorator, Toast } from "@mcleod/components";
import { AutogenLayoutUserList } from "./autogen/AutogenLayoutUserList";

export class UserList extends AutogenLayoutUserList {
    /** This is an event handler for the onClick event of buttonInvite.  */
    buttonInviteOnClick(_event: ClickEvent) {
        this.sendInvites("SendUserInvitation", 460);
    }

    /** This is an event handler for the onClick event of buttonMultiInvite. */
    buttonMultiInviteOnClick(_event: ClickEvent) {
        this.sendInvites("BatchInvite", 600);
    }

    private sendInvites(layoutName: string, width: number) {
        const decorator = SlideoutDecorator.decorateAndSlideIn("auth/" + layoutName,
            { fillVerticalSpace: true, width, title: "User Invite" });
        decorator.layout["afterInviteSent"] = () => {
            Toast.showSuccessToast("Sent successfully.");
            decorator.slideOut();
        };
    }
}
