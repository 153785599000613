import { Component, Table, TableCell, TableColumn } from "@mcleod/components";
import { DesignerAction } from "./DesignerAction";
import { DesignerActionResult } from "./DesignerActionResult";

export class ActionDeleteTableComponent implements DesignerAction {
    table: Table;
    designer: any;
    tableColumn: TableColumn;
    component: Component;
    deletedExpandComp: Component;
    deletedColumn: TableColumn;

    constructor(component: Component, table: Table) {
        this.component = component;
        this.table = table;
    }

    execute(): DesignerActionResult {
        if (this.component === this.table.expandComponent) {
            this.deletedExpandComp = this.component;
            this.table.expandComponent = null;
            return { success: true }
        } else if (this.component instanceof TableCell) {
            if (!this.component.isHeading)
                return { success: false, reason: "To delete a table column, the header cell must be selected." };
            for (let i = 0; i < this.table.columns.length; i++) {
                if (this.component === this.table.columns[i].headingCell) {
                    this.deletedColumn = this.table.removeColumn(i);
                    return { success: true };
                }
            }
        }
        return undefined;
    }

    undo() {
        if (this.deletedExpandComp) {
            this.table.expandComponent = this.deletedExpandComp;
        } else if (this.deletedColumn) {
            const deletedIndex = this.deletedColumn.index;
            this.table.addColumn(this.deletedColumn, false, true);
            for (let i = this.table.columns.length - 1; i > deletedIndex; i--) {
                this.table.switchColumns(i, i - 1);
            }
        }
    }

    toString() {
        const suffix = " from Table " + this.table.id;
        if (this.deletedExpandComp)
            return `Delete TableRow Expansion Area` + suffix;
        if (this.deletedColumn) {
            return `Delete Column ${this.deletedColumn.headingCell.caption}${suffix}`;
        }
        return "";
    }
}
