import { Component, DialogProps, Image, Label, PanelProps } from "@mcleod/components";
import { AbstractDisplayPanelSelector } from "./AbstractDisplayPanelSelector";

export class ImageSelector extends AbstractDisplayPanelSelector {
    constructor(props?: Partial<PanelProps>) {
        super(props);
    }

    override getDialogProps(): Partial<DialogProps> {
        return {
            ...super.getDialogProps(),
            title: "Select Image",
            localStorageKey: "imageSelectorDialog"
        };
    }

    protected override getValidationMessage(): string {
        return "Select an image.";
    }

    protected override discoverItemNames(): string[] {
        const result: string[] = [];
        const imageFileNames: string[] = require["context"]("@mcleod/images/src", true).keys();
        for (const name of imageFileNames) {
            if (name.startsWith("./images/") && !name.endsWith(".ts"))
                result.push(name.substring(9));
        }
        return result;
    }

    protected override getSingleItemPanelComponents(imageName: string): Component[] {
        const image = new Image({
            name: imageName,
            width: 64,
            height: 64,
            padding: 6
        });
        const label = new Label({ text: imageName, wrap: true });
        return [image, label];
    }
}
