import { Component, Label } from "..";
import { Event, EventListener } from "./Event";

export interface PrintableListener extends EventListener {
    (event: PrintableEvent): void;
}

export class PrintableEvent extends Event {
    private _printableLabel: Label = null;

    constructor(component: Component, printableLabel?: Label) {
        super(component);
        this._printableLabel = printableLabel;
    }

    public override get target(): Component {
        return super.target as Component;
    }

    public get printableLabel(): Label {
        return this._printableLabel;
    }

    public get printable(): boolean {
        return this.target["printable"];
    }
}
