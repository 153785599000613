import { Api, getThemeColor } from "@mcleod/core";
import { DialogProps } from "@mcleod/components";
import { ColorSelector } from "@mcleod/designer";

export interface SelectedColor {
    colorName?: string;
    hexColor?: string;
    numericColor?: string;
}

export class ColorSelectorUtil {
    public static async promptForColor(dialogProps?: Partial<DialogProps>): Promise<string> {
        const colorSelector = new ColorSelector();
        await colorSelector.showAsDialog(dialogProps);
        if (colorSelector.wasCancelled !== true)
            return colorSelector.getResultValue() ?? null;
        return null;
    }

    public static async promptForHexColor(dialogProps?: Partial<DialogProps>): Promise<SelectedColor> {
        const result: SelectedColor = {};
        result.colorName = await this.promptForColor(dialogProps);
        if (result.colorName != null)
            result.hexColor = getThemeColor(result.colorName);
        return result;
    }

    public static async promptForNumericColor(dialogProps?: Partial<DialogProps>): Promise<SelectedColor> {
        const result: SelectedColor = await this.promptForHexColor(dialogProps);
        if (result.hexColor != null) {
            const response = await Api.post("lme/general/color-code-conversion", {"hex_color_code": result.hexColor});
            result.numericColor = response.data[0]?.numeric_color_code;
        }
        return result;
    }
}
