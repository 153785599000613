import { Collection } from "@mcleod/core";

export interface SupportPromptAnswer {
    text: string;
    next: string;
}

export interface SupportPromptData {
    prompt: string;
    answers: SupportPromptAnswer[];
    notesPrompt?: string;
    notesHeight?: number;
    notesMonospace?: boolean;
    hasAttachments?: boolean;
    layout?: string;
    layoutProps?: any;
}

export const diagnosticData: Collection<SupportPromptData> = {
    "performance-problem/entry": {
        prompt: "How many users are affected?",
        answers: [
            { text: "One user", next: "performance-problem/scope" },
            { text: "More than one user", next: "performance-problem/scope" },
        ]
    },
    "performance-problem/scope": {
        prompt: "How widespead is the problem?",
        answers: [
            { text: "One screen/function", next: "performance-problem/reproducibility" },
            { text: "Two or more screens/functions", next: "performance-problem/reproducibility" },
        ]
    },
    "performance-problem/reproducibility": {
        prompt: "Are we able to reproduce the problem?",
        answers: [
            { text: "Yes, every time", next: "performance-problem/ask-exec-log" },
            { text: "Yes, but not every time", next: "performance-problem/ask-exec-log" },
            { text: "No", next: "performance-problem/ask-exec-log" },
        ]
    },
    "performance-problem/ask-exec-log": {
        prompt: "Do we have an execution log that includes an occurrence of the issue?",
        answers: [
            { text: "Yes", next: "performance-problem/gather-exec-log" },
            { text: "No", next: "performance-problem/thread-dump" },
        ]
    },

    "performance-problem/gather-exec-log": {
        prompt: "Paste or attach the execution log here.",
        layout: "auth/support/analyzer/Analyzer",
        hasAttachments: true,
        notesPrompt: "Execution log",
        notesHeight: 240,
        notesMonospace: true,
        answers: [
            { text: "Next", next: "performance-problem/gather-exec-log" },
        ]
    },


    "performance-problem/gather-thread-dump": {
        prompt: "Paste or attach the execution log here.",
        layout: "auth/support/analyzer/Analyzer",
        answers: [
            { text: "Next", next: "performance-problem/gather-thread-dump" },
        ]
    },

}