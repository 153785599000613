import { Client, IFrame } from "@stomp/stompjs";
import { Messaging } from "./Messaging";
import { LogManager } from "../logging/LogManager";

const log = LogManager.getLogger("core.messaging.MessageSender");

export class MessageSender {
    private static instance: MessageSender;

    private client: Client;
    private connected: boolean;

    public static getInstance() {
        if (MessageSender.instance == null)
            MessageSender.instance = new MessageSender(true);
        return MessageSender.instance;
    }

    constructor(isTopic: boolean) {
        this.createClient();
    }

    private createClient() {
        this.client = Messaging.getClient();
        this.client.onConnect = frame => this.handleConnect(frame);
        this.client.activate();
    }

    private handleConnect(frame: IFrame) {
        log.debug(() => ["Connected", frame]);
        this.connected = true;
    }

    private async recreateClient() {
        if (this.client != null)
            await this.client.deactivate();
        this.connected = false;
        this.createClient();
    }

    private async waitForConnection(timeout: number) {
        return new Promise<void>((resolve, reject) => {
            const startTime = Date.now();
            const thisSender = this;
            function checkConnected() {
                if (thisSender.connected === true)
                    resolve();
                else if (Date.now() > startTime + timeout)
                    reject();
                else
                    window.setTimeout(checkConnected, 1);
            }
            checkConnected();
        });
    }

    public async send(destination: string, isTopic: boolean, payload: object, javaClassName?: string) {
        if (this.client == null)
            return;
        if (!this.connected)
            await this.waitForConnection(10000);
        let body = JSON.stringify(payload);
        if (javaClassName != null)
            body = "__c=" + javaClassName + body;
        const headers = {};
        if (isTopic === true)
            headers["destination-type"] = "MULTICAST";
        try {
            this.publish(headers, destination, body, payload);
        }
        catch (error) {
            log.debug("An error occurred while sending stomp message: %o", error);
            await this.recreateClient();
            if (!this.connected)
                await this.waitForConnection(10000);
            this.publish(headers, destination, body, payload);
        }
    }

    private publish(headers: any, destination: string, body: any, payload: object) {
        this.client.publish({
            headers: headers,
            destination: destination,
            body: body
        });
        log.debug("Sent message on", destination, payload);
    }
}
