import { AutogenModelLayout, AutogenRowLayout } from "./autogen/AutogenModelLayout";

export class ModelLayout extends AutogenModelLayout<RowLayout> {
    constructor() {
        super(RowLayout);
    }
}

export class RowLayout extends AutogenRowLayout {
}
