import { DisplayType, ModelRow } from "@mcleod/core";
import { Button } from "../button/Button";
import { ChangeListener } from "../../events/ChangeEvent";
import { Component } from "../../base/Component";
import { ComponentPropDefinition } from "../../base/ComponentProps";
import { ComponentTypes } from "../../base/ComponentTypes";
import { DataSource, DataSourceMode } from "../../databinding/DataSource";
import { DesignerInterface } from "../../base/DesignerInterface";
import { getCurrentDataSourceMode, getRelevantModelRow } from "../../base/ComponentDataLink";
import { NumberEditorPropDefinitions, NumberEditorProps } from "./NumberEditorProps";
import { Panel } from "../panel/Panel";
import { Textbox } from "../textbox/Textbox";
import { ValidationResult } from "../../base/ValidationResult";

export class NumberEditor extends Panel implements NumberEditorProps {
    private minusButton: Button;
    private plusButton: Button;
    private editor: Textbox;
    private _buttonTheme: string;
    private _changeValue: number;
    private _maxValue: number;
    private _minValue: number;

    constructor(props?: Partial<NumberEditorProps>) {
        super(props, false);
        this._shouldAddDesignerContainerProperties = false;
        this.editor = new Textbox({
            displayType: DisplayType.INTEGER,
            padding: 0,
            fillRow: true,
            rowBreak: false,
            text: "0"
        });
        this.editor.addlValidationCallback = (value) => this._validateValue(value);
        this.minusButton = this.createIncrementButton("-", -1, "Right");
        this.plusButton = this.createIncrementButton("+", 1, "Left");
        this.add(this.minusButton, this.editor, this.plusButton);
        this.setProps(props);
        if (props.buttonTheme == null) {
            const defs = NumberEditorPropDefinitions.getDefinitions();
            this.minusButton.themeKey = defs.buttonTheme.defaultValue;
            this.plusButton.themeKey = defs.buttonTheme.defaultValue;
        }
        this.editor._captionLabel.marginLeft = -40;
        this.editor._inputDiv.style.borderLeft = "none";
        this.editor._inputDiv.style.borderRight = "none";
        this.editor._inputDiv.style.borderRadius = "0px";
        this.editor._inputDiv.style.padding = "0px";
        this.editor._inputDiv.style.paddingRight = "4px";
    }

    createIncrementButton(caption: string, multiplier: number, side: string): Button {
        const result = new Button({
            caption: caption,
            rowBreak: false,
            fontSize: "xxlarge",
            paddingTop: 4,
            paddingBottom: 4,
            height: 38,
            width: 38,
            marginLeft: 0,
            marginRight: 0,
            marginTop: 20
        });
        result["borderTop" + side + "Radius"] = 0;
        result["borderBottom" + side + "Radius"] = 0;

        result.addClickListener(event => this.increment(multiplier));
        return result;
    }

    increment(multiplier: number = 1) {
        let value = this.value == null ? 0 : this.value;
        value = value + (this.changeValue * multiplier);
        const validationResult = this._validateValue(value, true);
        if (validationResult?.isValid !== false)
            this.value = value;
    }

    private _validateValue(value: number | string, showTooltips: boolean = false): ValidationResult {
        let testValue: number;
        if (typeof value === "string")
            testValue = Number.parseInt(value);
        else
            testValue = value;
        if (testValue == null)
            return null;
        if (testValue > this.maxValue) {
            if (showTooltips)
                this.showTooltip("The value cannot be updated; the new value would be higher than the maximum allowed value.");
            return { component: this, isValid: false, validationMessage: "The value cannot be updated; the new value would be higher than the maximum allowed value." };
        }
        if (testValue < this.minValue) {
            if (showTooltips)
                this.showTooltip("The value cannot be updated; the new value would be lower than the minimum allowed value.");
            return { component: this, isValid: false, validationMessage: "The value cannot be updated; the new value would be lower than the minimum allowed value." };
        }
        return null;
    }

    get caption() {
        return this.editor.caption;
    }

    set caption(value: string) {
        this.editor.caption = value;
    }

    get captionVisible() {
        return this.editor.captionVisible;
    }

    set captionVisible(value: boolean) {
        this.editor.captionVisible = value;
        this.minusButton.marginTop = value ? 20 : 4;
        this.plusButton.marginTop = value ? 20 : 4;
    }

    get changeValue(): number {
        return this._changeValue == null ? 1 : this._changeValue;
    }

    set changeValue(value: number) {
        this._changeValue = value;
    }

    get maxValue(): number {
        return this._maxValue == null ? Number.MAX_SAFE_INTEGER : this._maxValue;
    }

    set maxValue(value: number) {
        this._maxValue = value;
    }

    get minValue(): number {
        return this._minValue == null ? Number.MIN_SAFE_INTEGER : this._minValue;
    }

    set minValue(value: number) {
        this._minValue = value;
    }

    get _designer(): DesignerInterface {
        return super._designer;
    }

    set _designer(value: DesignerInterface) {
        super._designer = value;
        const interactionEnabled = value == null;
        this.editor._interactionEnabled = interactionEnabled;
        this.minusButton._interactionEnabled = interactionEnabled;
        this.plusButton._interactionEnabled = interactionEnabled;
    }

    get value() {
        let result = parseInt(this.editor.text);
        if (isNaN(result))
            result = 0;
        return result;
    }

    set value(value: number) {
        this.editor.text = value == null ? "" : value.toString();
        //unfortunately, setting the Textbox's text value doesn't cause Textbox.updateBoundData() to fire
        //this is because, within Textbox, the text value isn't always the value that goes into the bound ModelRow
        if (this.editor.hasBoundField()) {
            const row = getRelevantModelRow(this.editor);
            if (row != null) {
                const mode = getCurrentDataSourceMode(this.editor);
                this.editor.updateBoundData(row, mode)
            }
        }
    }

    get buttonTheme() {
        return this._buttonTheme == null ? "" : this._buttonTheme;
    }

    set buttonTheme(value: string) {
        this.minusButton.themeKey = value;
        this.plusButton.themeKey = value;
    }

    get field() {
        return this.editor.field;
    }

    set field(value: string) {
        this.editor.field = value;
    }

    get dataSource() {
        return this.editor.dataSource;
    }

    set dataSource(value: DataSource) {
        this.editor.dataSource = value;
    }

    public override get searchOnly(): boolean {
        return this.editor.searchOnly;
    }

    public override set searchOnly(value: boolean) {
        this.editor.searchOnly = value;
    }

    addChangeListener(value: ChangeListener) {
        this.editor.addChangeListener(value);
    }

    removeChangeListener(value: ChangeListener) {
        this.editor.removeChangeListener(value);
    }

    override displayComponentData(data: ModelRow<any>, allData: ModelRow<any>[], rowIndex: number): void {
        this.editor.displayComponentData(data, allData, rowIndex);
    }

    private _internalUpdateBoundData() {
        const row = getRelevantModelRow(this);
        const mode = getCurrentDataSourceMode(this);
        this.updateBoundData(row, mode);
    }

    override updateBoundData(row: ModelRow<any>, mode: DataSourceMode): void {
        this.editor.updateBoundData(row, mode);
    }

    get required(): boolean {
        return super.required;
    }

    set required(value: boolean) {
        super.required = value;
        this.editor.required = this.required;
    }

    protected override getFocusTarget(): HTMLElement {
        return this.editor._input;
    }

    override getPropertyDefinitions() {
        return NumberEditorPropDefinitions.getDefinitions();
    }

    override _serializeNonProps(): string {
        return "";
    }

    override get serializationName() {
        return "numbereditor";
    }

    override get properName(): string {
        return "Number Editor";
    }

    public override discoverIncludedComponents(): Component[] {
        //return null here so that the components that make up the number editor aren't included
        return null;
    }

    protected override determinePropertyDefaultValue(prop: ComponentPropDefinition): any {
        if (prop.name === "value")
            return 0;
        return super.determinePropertyDefaultValue(prop);
    }
}
ComponentTypes.registerComponentType("numbereditor", NumberEditor.prototype.constructor, true);
