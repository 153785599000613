import { DialogProps } from "@mcleod/components";
import { AbstractPanelOpenTree } from "./AbstractPanelOpenTree";

export class PanelOpenModel extends AbstractPanelOpenTree {
    override getDialogProps(): Partial<DialogProps> {
        return {
            ...super.getDialogProps(),
            title: "Model Selection",
            cursor: null
        };
    }

    getApiName(): string {
        return "metadata/models";
    }

    getCustomizedTooltip(): string {
        return "This is a customized model.";
    }
}
