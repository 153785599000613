import { Button, ButtonVariant, DesignerInterface, Snackbar } from "@mcleod/components";
import { DesignerAction } from "./actions/DesignerAction";
import { DesignerActionResult } from "./actions/DesignerActionResult";

interface ActionListener {
    enabled: boolean;
    notifyAction: (result?: DesignerActionResult | Promise<DesignerActionResult>) => void;
}

const buttonProps = { variant: ButtonVariant.round, color: "subtle.darker", enabled: false, rowBreak: false};

export class DesignerActionExecutor {
    private designer:DesignerInterface;
    private undoableActions: DesignerAction[] = [];
    private redoableActions: DesignerAction[] = [];
    private _buttonUndo: Button;
    private _buttonRedo: Button;
    actionListener: ActionListener;

    constructor(designer:DesignerInterface) {
        this.designer = designer;
        if (this.designer.notifyAction) {
            this.actionListener = {
                enabled: true,
                notifyAction: this.designer.notifyAction.bind(this.designer)
            };
        }
    }

    get buttonUndo() {
        if (!this._buttonUndo) {
            this._buttonUndo = new Button({
                ...buttonProps,
                id: "buttonUndo",
                imageName: "undo",
                onClick: () => this.undoDesignerAction()
            });
        }
        return this._buttonUndo;
    }

    get buttonRedo() {
        if (!this._buttonRedo) {
            this._buttonRedo = new Button({
                ...buttonProps,
                id: "buttonRedo",
                imageName: "redo",
                onClick: () => this.redoDesignerAction() });
        }
        return this._buttonRedo;
    }

    async doDesignerAction(action: DesignerAction) {
        action["designer"] = this.designer;
        const result = await action.execute();
        if (result?.success === true && (this.actionListener == null || this.actionListener.enabled !== false)) {
            this.undoableActions.push(action);
            this.designer.getActiveTab().modified = true;
            this.redoableActions = [];
            this.syncButtons();
            this.actionListener?.notifyAction(result);
        } else if (result?.success === false && result.reason) {
            Snackbar.showWarningSnackbar(result.reason);
        }
    }

    syncButtons() {
        if (this._buttonUndo) {
            this._buttonUndo.enabled = this.getUndoAction() != null;
            this._buttonUndo.tooltip = this._buttonUndo.enabled ? "Undo change" : "Nothing to undo";
        }
        if (this._buttonRedo) {
            this._buttonRedo.enabled = this.getRedoAction() != null;
            this._buttonRedo.tooltip = this._buttonRedo.enabled ? "Redo change" : "Nothing to redo";
        }
    }

    undoDesignerAction() {
        if (this.undoableActions.length > 0) {
            const action = this.undoableActions.pop();
            action.undo();
            this.redoableActions.push(action);
            this.syncButtons();
            this.actionListener?.notifyAction();
        }
    }

    redoDesignerAction() {
        if (this.redoableActions.length > 0) {
            const action = this.redoableActions.pop();
            const result = action.execute();
            this.undoableActions.push(action);
            this.syncButtons();
            this.actionListener?.notifyAction(result);
        }
    }

    getUndoAction() {
        if (this.undoableActions.length > 0) {
            return this.undoableActions[this.undoableActions.length - 1];
        }
        return null;
    }

    getRedoAction() {
        if (this.redoableActions.length > 0) {
            return this.redoableActions[this.redoableActions.length - 1];
        }
        return null;
    }
}
