import { DesignableObject } from "../base/DesignableObject";
import { DomEvent, DomFocusEvent } from "./DomEvent";
import { Event, EventListener } from "./Event";

export interface FocusListener extends EventListener {
    (event: FocusEvent): void;
}

export class FocusEvent extends Event {
    constructor(component: DesignableObject, domEvent?: DomEvent) {
        super(component, domEvent);
    }

    public get domEvent(): DomFocusEvent {
        return super.domEvent as DomFocusEvent;
    }

    public get relatedTarget(): EventTarget {
        return this.domEvent.relatedTarget;
    }
}
