export const ThemeDefaultPalette = {
    palette: {
        default: {
            base: "#252525",
            light: "#5a5a5a",
            lighter: "#8c8c8c",
            lightest: "#bfbfbf",
            dark: "#4c4c4c",
            darker: "#333",
            darkest: "#000",
            reverse: "#fff"
        },
        primary: {
            base: "#137cdd",
            light: "#50a1eb",
            lighter: "#8bc0f1",
            lightest: "#ddeaf7",
            slight: "#f6faff",
            dark: "#0b60ad",
            darker: "#054075",
            darkest: "#073259",
            reverse: "#fff"
        },
        success: {
            base: "#458719",
            light: "#74a553",
            lighter: "#a2c38c",
            lightest: "#b8d1a8",
            dark: "#326412",
            darker: "#224809",
            darkest: "#162f04",
            reverse: "#fff",
        },
        warning: {
            base: "#ef7200",
            light: "#f2852d",
            lighter: "#eea66b",
            lightest: "#edccb1",
            dark: "#c35700",
            darker: "#843300",
            darkest: "#4f1900",
            reverse: "#fff",
        },
        error: {
            base: "#b60909",
            light: "#ce0909",
            lighter: "#dc0909",
            lightest: "#fe4848",
            dark: "#870505",
            darker: "#550202",
            darkest: "#400202",
            reverse: "#fff",
        },
        caution: {
            base: "#f7b500",
            light: "#ffce53",
            lighter: "#ffe091",
            lightest: "#fef2d1",
            dark: "#c68e00",
            darker: "#7a5000",
            darkest: "#271700",
            reverse: "#fff",
        },
        subtle: {
            base: "#979797",
            light: "#cdcdcd",
            lighter: "#dbdbdb",
            lightest: "#ededed",
            dark: "#888",
            darker: "#666",
            darkest: "#444",
            reverse: "#fff"
        }
    }
}
