import {
    Alignment, Collection, Color, GeneralSettings, HorizontalAlignment, LicenseSettings, ObjectUtil
} from "@mcleod/core";
import { BlurEvent, ClickEvent, Component, DataDisplayEvent, DataSource, FocusEvent, KeyEvent, Layout } from "..";
import { MouseEvent } from "../events/MouseEvent";
import { ComponentCreator } from "../page/ComponentFactory";
import { BorderType } from "./BorderType";
import { Cursor } from "./Cursor";
import { DesignableObjectPropDefinition, DesignableObjectProps } from "./DesignableObjectProps";
import { DesignerInterface } from "./DesignerInterface";
import { PropType } from "./PropType";
import { Company, CompanyType, LtlType } from "../Company";
import { ContextMenuItemsProvider } from "../components/contextmenu/ContextMenuLabelProps";

export type ComponentPropDefinitions = Collection<ComponentPropDefinition>;
export type ClickEventProp = string | ((event: ClickEvent) => void);
export type DataDisplayEventProp = string | ((event: DataDisplayEvent) => void);
export type DragEventProp = string | ((event: DragEvent) => void);
export type FocusEventProp = string | ((event: FocusEvent) => void);
export type BlurEventProp = string | ((event: BlurEvent) => void);
export type KeyEventProp = string | ((event: KeyEvent) => void);
export type TooltipCallback = (baseTooltip: Component, originatingEvent: MouseEvent) => Component | Promise<Component>;

export class ComponentPropDefinitionUtil {
    public static getComponentPropertyDefinitions(): ComponentPropDefinitions {
        return ObjectUtil.deepCopy(componentPropDefs);
    }

    public static getDataBoundPropertyDefinitions() {
        return ObjectUtil.deepCopy(dbProps);
    }

    public static populateComponentPropNames(props: ComponentPropDefinitions) {
        for (const key in props)
            props[key].name = key;
    }

    public static getDataSourceList(designer: DesignerInterface) {
        if (designer?.getActiveTab()?.dataSources == null)
            return [];
        return Object.keys(designer?.getActiveTab()?.dataSources);
    }
}

export interface ComponentProps extends IdProps {
    _designer: DesignerInterface;
    _interactionEnabled: boolean;
    align: HorizontalAlignment;
    backgroundColor: Color;
    borderColor: Color;
    borderType: BorderType;
    borderShadow: boolean;
    borderWidth: number;
    borderBottomColor: Color;
    borderBottomType: BorderType;
    borderBottomWidth: number;
    borderLeftColor: Color;
    borderLeftType: BorderType;
    borderLeftWidth: number;
    borderRadius: string | number;
    borderBottomLeftRadius: string | number;
    borderBottomRightRadius: string | number;
    borderTopLeftRadius: string | number;
    borderTopRightRadius: string | number;
    borderRightColor: Color;
    borderRightType: BorderType;
    borderRightWidth: number;
    borderTopColor: Color;
    borderTopType: BorderType;
    borderTopWidth: number;
    bottom: string | number;
    boundRow: any; //should be a ModelRow, except the PropertiesTable still deals in plain old objects
    className: string;
    color: Color;
    cursor: Cursor;
    companyType: CompanyType;
    dataSource: DataSource;
    disabledByServer: boolean;
    disabledTooltip: ComponentCreator;
    displayLabel: string;
    draggable: boolean;
    enabled: boolean;
    excludeFromSortFields: string[];
    field: string;
    fillHeight: boolean;
    fillRow: boolean;
    fontBold: boolean;
    fontFamily: string;
    fontSize: string | number;
    height: string | number;
    hideTooltipOnClick: boolean;
    isCustom: boolean;
    isRow: boolean;
    left: string | number;
    license?: string;
    ltlType: LtlType;
    margin: number | string;
    marginTop: number | string;
    marginBottom: number | string;
    marginLeft: number | string;
    marginRight: number | string;
    minHeight: number | string;
    maxHeight: number | string;
    minWidth: number | string;
    maxWidth: number | string;
    multiCurrency?: boolean;
    onBlur?: BlurEventProp,
    onClick?: ClickEventProp,
    onDataDisplay?: DataDisplayEventProp,
    onDblClick?: ClickEventProp,
    onDragDrop?: DragEventProp,
    onDragEnd?: DragEventProp,
    onDragOver?: DragEventProp,
    onDragStart?: DragEventProp,
    onFocus?: FocusEventProp,
    onKeyDown?: KeyEventProp,
    onKeyUp?: KeyEventProp,
    owner?: Layout,
    padding: number;
    paddingTop: number;
    paddingBottom: number;
    paddingLeft: number;
    paddingRight: number;
    rememberUserChoice: boolean;
    right: string | number;
    required: boolean;
    requiredDuringAdd: boolean;
    requiredDuringSearch: boolean;
    requiredDuringUpdate: boolean;
    rowBreak: boolean;
    searchOnly: boolean;
    sortField: string;
    style: Partial<CSSStyleDeclaration>;
    themeKey: string;
    tooltip: ComponentCreator;
    tooltipPosition: Alignment;
    top: string | number;
    visible: boolean;
    width: number | string;
    widthFillWeight: number;
    zIndex: number;
    preventCollapse: boolean;
    nextFocusable: string;
    removedByServer: boolean;
    contextMenuItems: ContextMenuItemsProvider;
}

export interface ComponentPropDefinition extends DesignableObjectPropDefinition {
    //nothing component-specific yet...
}

function isLtlTypeVisible() {
    return LicenseSettings.getSingleton().getLicensedPackages()?.some(pkg =>
        "com.tms.client.loadmaster.brltl" == pkg ||
        "com.tms.client.loadmaster.lmltl" == pkg
    );
}

const componentPropDefs: ComponentPropDefinitions = {
    actionPermission : {
        type: PropType.actionPermission,
        category: "Enabled / visible",
        description: "This specifies whether the component should removed from the page if the user is denied the Action Permission.",
        visibleInMcLeodTailor: true
    },
    align: { type: PropType.string, dropdownProps: { items: [HorizontalAlignment.LEFT, HorizontalAlignment.CENTER, HorizontalAlignment.RIGHT] }, defaultValue: HorizontalAlignment.LEFT, category: "Appearance", visibleInMcLeodTailor: true, description: "This controls the horizontal alignment of component's content within it." },
    borderShadow: { type: PropType.bool, category: "Border", visibleInMcLeodTailor: true, description: "This controls whether or not a shadowed border will appear behind the component.  Setting this affects the box-shadow CSS property." },
    border: { type: PropType.string, category: "Border", visibleInMcLeodTailor: true, description: "This is a shorthand for setting all the properties of the component's border with one property." },
    borderWidth: { type: PropType.number, category: "Border", visibleInMcLeodTailor: true, description: "This sets the width (in pixels) of the component's border.  By default, this is the only property that needs to be set for a component to have a solid border with the current color." },
    borderColor: { type: PropType.color, category: "Border", visibleInMcLeodTailor: true, description: "This sets the color of the component's border.  See the color demo page (provide link) for information about what values should be used.  This property will have no effect unless a borderWidth is also set." },
    borderType: { type: PropType.string, dropdownProps: { items: ["solid", "dashed", "dotted"] }, defaultValue: "solid", category: "Border", visibleInMcLeodTailor: true, description: "This controls the type of border that will shown around a component.  This property will have no effect unless a borderWidth is also set." },
    borderRadius: { type: PropType.number, category: "Border", visibleInMcLeodTailor: true, description: "Set this property to enable rounded borders.  It can be set to a number to specify the border radius in pixels or a string with a percent sign (e.g. 50%) to express the radius in terms of the component's size." },
    borderTop: { type: PropType.string, category: "Border", visibleInMcLeodTailor: true },
    borderTopWidth: { type: PropType.number, category: "Border", visibleInMcLeodTailor: true },
    borderTopColor: { type: PropType.color, category: "Border", visibleInMcLeodTailor: true },
    borderTopType: { type: PropType.string, dropdownProps: { items: ["solid", "dashed", "dotted"] }, defaultValue: "solid", category: "Border", visibleInMcLeodTailor: true },
    borderTopLeftRadius: { type: PropType.number, category: "Border", visibleInMcLeodTailor: true },
    borderTopRightRadius: { type: PropType.number, category: "Border", visibleInMcLeodTailor: true },
    borderRight: { type: PropType.string, category: "Border", visibleInMcLeodTailor: true },
    borderRightWidth: { type: PropType.number, category: "Border", visibleInMcLeodTailor: true },
    borderRightColor: { type: PropType.color, category: "Border", visibleInMcLeodTailor: true },
    borderRightType: { type: PropType.string, dropdownProps: { items: ["solid", "dashed", "dotted"] }, defaultValue: "solid", category: "Border", visibleInMcLeodTailor: true },
    borderBottom: { type: PropType.string, category: "Border", visibleInMcLeodTailor: true },
    borderBottomWidth: { type: PropType.number, category: "Border", visibleInMcLeodTailor: true },
    borderBottomColor: { type: PropType.color, category: "Border", visibleInMcLeodTailor: true },
    borderBottomType: { type: PropType.string, dropdownProps: { items: ["solid", "dashed", "dotted"] }, defaultValue: "solid", category: "Border", visibleInMcLeodTailor: true },
    borderBottomLeftRadius: { type: PropType.number, category: "Border", visibleInMcLeodTailor: true },
    borderBottomRightRadius: { type: PropType.number, category: "Border", visibleInMcLeodTailor: true },
    borderLeft: { type: PropType.string, category: "Border", visibleInMcLeodTailor: true },
    borderLeftWidth: { type: PropType.number, category: "Border", visibleInMcLeodTailor: true },
    borderLeftColor: { type: PropType.color, category: "Border", visibleInMcLeodTailor: true },
    borderLeftType: { type: PropType.string, dropdownProps: { items: ["solid", "dashed", "dotted"] }, defaultValue: "solid", category: "Border", visibleInMcLeodTailor: true },
    backgroundColor: { type: PropType.color, category: "Appearance", visibleInMcLeodTailor: true, description: "This sets which color fills the background of the component.  See the color demo page (provide link) for information about what values should be used." },
    color: { type: PropType.color, category: "Appearance", visibleInMcLeodTailor: true, description: "This sets which color will be used when rendering foreground elements (text, borders, images).  See the color demo page (provide link) for information about what values should be used." },
    companyType: {
        type: PropType.string,
        dropdownProps: { items: () => Company.COMPANY_TYPE_DROPDOWN_ITEMS },
        ...{ allowDropdownBlank: false },
        defaultValue: CompanyType.BOTH,
        category: "Enabled / visible",
        description: "This specifies whether the component should be shown on the page based on the company type.",
        visibleInMcLeodTailor: true,
        editableInMcLeodTailor: { baseComponent: false, customComponent: true }
    },
    contextMenuItems: {
        type: PropType.contextMenu,
        description: "This specifies the context menu items that will be shown when the user right-clicks on the component.  This is a JSON string that should be a valid context menu"
    },
    cursor: { type: PropType.string, dropdownProps: { items: ["pointer"] }, category: "Uncommon", description: "This sets which mouse cursor will be shown when the mouse is over this component." },
    // className: { type: PropType.string },
    dataSource: { type: PropType.string, dropdownProps: { items: ComponentPropDefinitionUtil.getDataSourceList }, category: "Data", source: "databound", visibleInMcLeodTailor: true, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "This specifies the DataSource that will provide this component with its data." },
    disabledByServer: { type: PropType.bool, defaultValue: false, visibleInDesigner: false, description: "Indicates whether the component has been disabled server-side because of user access restrictions." },
    displayLabel: { type: PropType.string, visibleInMcLeodTailor: true, description: "This defines the text that will be used to display this field in listings.  This is useful for giving the Component a description that is meaningful to the user for Components that do not have captions that the user already sees on the page." },
    draggable: { type: PropType.bool, category: "Uncommon", defaultValue: false, description: "This controls whether or not this component will fire drag events." },
    enabled: { type: PropType.bool, defaultValue: true, category: "Enabled / visible", visibleInMcLeodTailor: true, description: "This controls whether a user can interact with the component.  For example, a Button with enabled=false will not be clickable.  A Textbox with enabled=false will not be focusable." },
    enabledDuringAdd: { type: PropType.bool, defaultValue: true, category: "Enabled / visible", visibleInMcLeodTailor: true, description: "This controls whether a user can interact with the component when its DataSource is in ADD mode." },
    enabledDuringSearch: { type: PropType.bool, defaultValue: true, category: "Enabled / visible", visibleInMcLeodTailor: true, description: "This controls whether a user can interact with the component when its DataSource is in SEARCH mode." },
    enabledDuringUpdate: { type: PropType.bool, defaultValue: true, category: "Enabled / visible", visibleInMcLeodTailor: true, description: "This controls whether a user can interact with the component when its DataSource is in UPDATE mode." },
    excludeFromSortFields: { type: PropType.stringArray, category: "Data", visibleInMcLeodTailor: true, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "List field names here (that are used bound to this component) that should not be avialable when sorting within table.  By default, all fields are eligible for use in table sorting." },
    experiment: { type: PropType.string, category: "Enabled / visible", visibleInMcLeodTailor: false, editableInMcLeodTailor:false, dropdownProps: { items: () =>  Object.keys(GeneralSettings.get()?.experiments ?? {})}, description: "This specifies whether the component should be shown on the page based on access to the experiment." },
    field: { type: PropType.string, category: "Data", source: "databound", visibleInMcLeodTailor: true, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "This specifies which field from the DataSource that this component is bound to." },
    fillHeight: { type: PropType.bool, category: "Layout", visibleInMcLeodTailor: true, description: "This toggles whether the component will fill its container's height." },
    fillRow: { type: PropType.bool, category: "Layout", visibleInMcLeodTailor: true, description: "This toggles whether the component will fill its container's width.  If there are other fixed width components on the same row, this component will fill the container's width minus the fixed widths.  Multiple components on the same row can set fillRow=true and they will be given equal fill weight (1).  See widthFillWeight if you wish to make the components fill the row but not be equal widths." },
    fontBold: { type: PropType.bool, category: "Appearance", visibleInMcLeodTailor: true, description: "This toggles whether or not the component's text will be bold (font-weight: 500)." },
    fontFamily: { type: PropType.string, category: "Appearance", visibleInMcLeodTailor: true, description: "The sets the font family (e.g. courier, sans-serif) of the component's text." },
    fontSize: { type: PropType.string, dropdownProps: { items: ["xxsmall", "xsmall", "small", "medium", "large", "xlarge", "xxlarge", "xxxlarge", "skyline", "billboard"] }, category: "Appearance", visibleInMcLeodTailor: true, description: "This sets the size of the text of the component.  It can be set to one of the predefined themable values or a number to indicate the font size in pixels." },
    height: { type: PropType.number, category: "Layout", visibleInMcLeodTailor: true, description: "This specifies the a value for the height.  When no value is set, the component will be tall enough to fit its contents (plus any paddings and margins).  You can set height to a number for a height in pixels, or a string ending with a percent sign" },
    id: { type: PropType.string, visibleInMcLeodTailor: true, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "This specifies a unique identifier of a component within its layout.  This is used by permissions, among other things, to identify components by their qualified name.  It will also be used for the component's associated DOM element id attribute." },
    isCustom: { type: PropType.bool, description: "True when added to a custom layout by the user.", editableInDesigner: false, visibleInMcLeodTailor: true, editableInMcLeodTailor: false },
    isRow: { type: PropType.bool, category: "Layout", description: "If set to true, this Component will be added to its container directly instead of creating a row for it." },
    left: { type: PropType.number, category: "Uncommon" },
    license: { type: PropType.string, category: "Enabled / visible", description: "When specified, the component will only be visible when the specified license is present." },
    ltlType: {
        type: PropType.string,
        dropdownProps: { items: () => Company.LTL_TYPE_DROPDOWN_ITEMS },
        category: "Enabled / visible",
        description: "This specifies whether the component should be shown on the page based on the company LTL settings.",
        visibleInDesigner: () => { return isLtlTypeVisible(); },
        visibleInMcLeodTailor: () => { return isLtlTypeVisible(); },
        editableInMcLeodTailor: { baseComponent: false, customComponent: true }
    },
    multiCurrency: { type: PropType.bool, defaultValue: false,  category: "Enabled / visible", description: "If set to true, the component will only be visible if the Multicurrency module is licensed and enabled." },
    width: { type: PropType.number, category: "Layout", visibleInMcLeodTailor: true },
    minHeight: { type: PropType.number, category: "Layout", visibleInMcLeodTailor: true },
    minWidth: { type: PropType.number, category: "Layout", visibleInMcLeodTailor: true },
    margin: { type: PropType.string, category: "Margin / padding", visibleInMcLeodTailor: true },
    marginTop: { type: PropType.number, category: "Margin / padding", visibleInMcLeodTailor: true },
    marginRight: { type: PropType.number, category: "Margin / padding", visibleInMcLeodTailor: true },
    marginBottom: { type: PropType.number, category: "Margin / padding", visibleInMcLeodTailor: true },
    marginLeft: { type: PropType.number, category: "Margin / padding", visibleInMcLeodTailor: true },
    onBlur: {
        type: PropType.event,
        category: "Events",
        addListenerMethod: "addBlurListener",
        removeListenerMethod: "removeBlurListener",
        eventSignature: "OnBlur(event: BlurEvent)",
        description: "This event will fire when the component is the current focus of the page and then a different component becomes focused (i.e. the user tabbed to another field)."
    },
    onClick: {
        type: PropType.event,
        category: "Events",
        addListenerMethod: "addClickListener",
        removeListenerMethod: "removeClickListener",
        eventSignature: "OnClick(event: ClickEvent)",
        description: "This event will fire when a click is enacted on the component."
    },
    onContextMenuCreation: {
        type: PropType.event,
        category: "Events",
        addListenerMethod: "addContextMenuCreationListener",
        removeListenerMethod: "removeContextMenuCreationListener",
        eventSignature: "OnContextMenuCreation(event: ContextMenuCreationEvent)",
        description: "This event will fire when the component's context menu is created. To prevent the context menu from displaying, call event.preventDefault()."
    },
    onDataDisplay: {
        type: PropType.event,
        category: "Events",
        addListenerMethod: "addDataDisplayListener",
        removeListenerMethod: "removeDataDisplayListener",
        eventSignature: "OnDataDisplay(event: DataDisplayEvent)",
        source: "databound",
        description: "This is an event that will be triggered when the DataSource that this component is bound to is displaying a new record."
    },
    onDblClick: {
        type: PropType.event,
        category: "Events",
        addListenerMethod: "addDblClickListener",
        removeListenerMethod: "removeDblClickListener",
        eventSignature: "OnDblClick(event: ClickEvent)",
        description: "This event will fire when a double-click is enacted on the component."
    },
    onDragDrop: {
        type: PropType.event,
        category: "Events",
        addListenerMethod: "addDropListener",
        removeListenerMethod: "removeDragDropListener",
        eventSignature: "OnDragDrop(event: DragEvent)",
        description: "This event will fire when the component is being dragged and is then dropped."
    },
    onDragEnd: {
        type: PropType.event,
        category: "Events",
        addListenerMethod: "addDragEndListener",
        removeListenerMethod: "removeDragEndListener",
        eventSignature: "OnDragEnd(event: DragEvent)",
        description: "This event will fire when the component is being dragged and the drag action is completed."
    },
    onDragOver: {
        type: PropType.event,
        category: "Events",
        addListenerMethod: "addDragOverListener",
        removeListenerMethod: "removeDragOverListener",
        eventSignature: "OnDragOver(event: DragEvent)",
        description: "This event will fire when the component is being dragged over a valid drop target."
    },
    onDragStart: {
        type: PropType.event,
        category: "Events",
        addListenerMethod: "addDragStartListener",
        removeListenerMethod: "removeDragStartListener",
        eventSignature: "OnDragStart(event: DragEvent)",
        description: "This event will fire when the component first begins being dragged."
    },
    onFocus: {
        type: PropType.event,
        category: "Events",
        addListenerMethod: "addFocusListener",
        removeListenerMethod: "removeFocusListener",
        eventSignature: "OnFocus(event: FocusEvent)",
        description: "This event will fire when the component becomes the current focus target of the page."
    },
    onKeyDown: {
        type: PropType.event,
        category: "Events",
        addListenerMethod: "addKeyDownListener",
        removeListenerMethod: "removeKeyDownListener",
        eventSignature: "OnKeyDown(event: KeyEvent)",
        description: "This event will fire for the focused component when a key is pressed."
    },
    onKeyUp: {
        type: PropType.event,
        category: "Events",
        addListenerMethod: "addKeyUpListener",
        removeListenerMethod: "removeKeyUpListener",
        eventSignature: "OnKeyUp(event: KeyEvent)",
        description: "This event will fire for the focused component when a key is released."
    },
    padding: { type: PropType.number, category: "Margin / padding", visibleInMcLeodTailor: true },
    paddingTop: { type: PropType.number, category: "Margin / padding", visibleInMcLeodTailor: true },
    paddingRight: { type: PropType.number, category: "Margin / padding", visibleInMcLeodTailor: true },
    paddingBottom: { type: PropType.number, category: "Margin / padding", visibleInMcLeodTailor: true },
    paddingLeft: { type: PropType.number, category: "Margin / padding", visibleInMcLeodTailor: true },
    rememberUserChoice: { type: PropType.bool, category: "Data", visibleInMcLeodTailor: true, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "This controls whether or not this Component's value will be saved in local storage when changed and restored the next time the Component is loaded on a page." },
    required: { type: PropType.bool, category: "Data", visibleInMcLeodTailor: true, affectsProps: ["requiredDuringUpdate", "requiredDuringAdd", "requiredDuringSearch"]},
    requiredDuringAdd: { type: PropType.bool, category: "Data", visibleInMcLeodTailor: true, description: "This specifies whether the component should be required when its DataSource is in ADD mode." },
    requiredDuringSearch: { type: PropType.bool, defaultValue: false, category: "Data", visibleInMcLeodTailor: true, affectsProps: ["required"], description: "This specifies whether the component should be required when its DataSource is in SEARCH mode." },
    requiredDuringUpdate: { type: PropType.bool, category: "Data", visibleInMcLeodTailor: true, description: "This specifies whether the component should be required when its DataSource is in UPDATE mode." },
    rowBreak: { type: PropType.bool, defaultValue: true, category: "Layout", visibleInMcLeodTailor: true },
    maxHeight: { type: PropType.number, category: "Layout", visibleInMcLeodTailor: true },
    maxWidth: { type: PropType.number, category: "Layout", visibleInMcLeodTailor: true },
    removedByServer: {type: PropType.bool, defaultValue: false, visibleInDesigner: false, description: "Indicates whether the component has been removed server-side by LayoutSerializer.java because of user access restrictions." },
    searchOnly: { type: PropType.bool, category: "Data", defaultValue: false, source: "databound", visibleInMcLeodTailor: true, description: "This enables a component for searching a DataSource but not displaying the current values from the DataSource.", affectsProps: ["requiredDuringUpdate", "requiredDuringAdd", "requiredDuringSearch"] },
    sortDescendingByDefault: { type: PropType.bool, category: "Data", visibleInMcLeodTailor: true, description: "This controls whether or not the data for this Component should be sorted in descending order by default.  This is used when the Component is displayed in a Table and we don't want the user to have to sort twice to get the values to sort in descending order.  This is often used for date fields where it makes sense to show the most recent first, though there are other reasons to show this as well." },
    sortField: { type: PropType.string, category: "Data", visibleInMcLeodTailor: true, description: "This specifies a field name which can be used during table sorting.  This property is intended to be used when a component display data from one or more fields, but doesn't specify its field property value.  The default value for this property will be the value in the field property." },
    sortNullsAtEnd: { type: PropType.bool, category: "Data", visibleInMcLeodTailor: true, description: "This controls whether nulls (empty values) are put at the beginning or the end of the data when this Component is shown in a list." },
    widthFillWeight: { type: PropType.number, category: "Layout", visibleInMcLeodTailor: true },
    tooltip: { type: PropType.string, visibleInMcLeodTailor: true, description: "Defines a message to display to the user when they hover over the component.  It may be overridden by tooltips that are configured programmatically." },
    tooltipPosition: { type: PropType.string, defaultValue: Alignment.BOTTOM, dropdownProps: { items: [Alignment.LEFT, Alignment.RIGHT, Alignment.BOTTOM, Alignment.TOP] }, category: "Uncommon", description: "This specifies where the tooltip should appear relative to the component." },
    themeKey: { type: PropType.string, category: "Appearance", description: "Defines a key from the theme that will be applied to this component.  Properties from this theme element (usually colors) will be set in this component." },
    visible: { type: PropType.bool, defaultValue: true, category: "Enabled / visible", visibleInMcLeodTailor: true, description: "This specifies whether the component should be shown on the page." },
    visibleDuringAdd: { type: PropType.bool, defaultValue: true, category: "Enabled / visible", visibleInMcLeodTailor: true, description: "This specifies whether the component should be shown on the page when its DataSource is in ADD mode." },
    visibleDuringSearch: { type: PropType.bool, defaultValue: true, category: "Enabled / visible", visibleInMcLeodTailor: true, description: "This specifies whether the component should be shown on the page when its DataSource is in SEARCH mode." },
    visibleDuringUpdate: { type: PropType.bool, defaultValue: true, category: "Enabled / visible", visibleInMcLeodTailor: true, description: "This specifies whether the component should be shown on the page when its DataSource is in UPDATE mode." },
    preventCollapse: { type: PropType.bool, defaultValue: false, category: "Enabled / visible", visibleInMcLeodTailor: true, description: "When checked, this component will not collapse when its visibility is false." },
    zIndex: { type: PropType.number, category: "Uncommon" },
    nextFocusable: {
        type: PropType.string,
        defaultValue: "",
        visibleInMcLeodTailor: true,
        description: "This value is the id of the component that should gain focus in the next tab sequence."
    }
};

for (const key in componentPropDefs) {
    const prop = componentPropDefs[key];
    prop.name = key;
    prop.source = "component";
}

export interface IdProps extends DesignableObjectProps {
    id?: string;
}

const dbProps: ComponentPropDefinitions = {
    dataSource: { type: PropType.string, dropdownProps: { items: ComponentPropDefinitionUtil.getDataSourceList }, category: "Data", source: "databound", visibleInMcLeodTailor: true, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "This specifies the DataSource that will provide this component with its data." },
    field: { type: PropType.string, category: "Data", source: "databound", visibleInMcLeodTailor: true, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "This specifies which field from the DataSource that this component is bound to." },
    searchOnly: { type: PropType.bool, category: "Data", source: "databound", visibleInMcLeodTailor: true, description: "This enables a component for searching a DataSource but not displaying the current values from the DataSource." }
};
