import { Endpoint, ModelRow } from "@mcleod/core";

const LayoutListEndpoint = "layout/list";

export class ModelLayoutList extends Endpoint<RowLayoutList, RowLayoutListFields> {
    constructor() {
        super(LayoutListEndpoint, RowLayoutList);
    }
}

export class RowLayoutList extends ModelRow<RowLayoutListFields> {
    constructor() {
        super(LayoutListEndpoint);
    }
}

export interface RowLayoutListFields {
    name: string;
    definition: string;
    children: RowLayoutListFields[];
}

