import { Button, Label, Layout, Panel, Table } from "@mcleod/components";

export abstract class AutogenLayoutPanelPermsGrant extends Layout {
    buttonToggleUsers: Button;
    labelHeading: Label;
    labelSelectItem: Label;
    panelContent: Panel;
    panelGroupsTable: Panel;
    panelHeading: Panel;
    panelLeft: Panel;
    panelSecures: Panel;
    panelUserGroups: Panel;
    panelUsersTable: Panel;
    tableGroups: Table;
    tableUsers: Table;
}
