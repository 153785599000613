import { Button, DataSource, Label, Layout, Panel, SaveButton, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutNewTenantAccessToken extends Layout {
    buttonCopy: Button;
    buttonGenerate: SaveButton;
    labelA: Label;
    labelLabel1: Label;
    labelP: Label;
    labelT: Label;
    panelToken: Panel;
    sourceTenantAccessToken: DataSource;
    textboxIssuedToUserId: Textbox;
    textboxToken: Textbox;
}
