export class AnalyzerUtil {
    public static getSizePercentage(sizeInBytes: number, totalSize: number): string {
        if (totalSize == 0 || isNaN(sizeInBytes))
            return "--";
        const percentage = sizeInBytes / totalSize * 100;
        return percentage.toFixed(2) + "%";
    }

    public static getSizeString(sizeInBytes: number): string {
        if (isNaN(sizeInBytes))
            return "--";
        const ONE_KILOBYTE = 1024;
        const ONE_MEGABYTE = ONE_KILOBYTE * ONE_KILOBYTE;
        const ONE_GIGABYTE = ONE_MEGABYTE * ONE_KILOBYTE;
        let result = "";
        if (sizeInBytes > ONE_GIGABYTE)
            result = (sizeInBytes / ONE_GIGABYTE).toFixed(2) + " GB";
        else if (sizeInBytes > ONE_MEGABYTE)
            result = (sizeInBytes / ONE_MEGABYTE).toFixed(2) + " MB";
        else if (sizeInBytes > ONE_KILOBYTE)
            result = (sizeInBytes / ONE_KILOBYTE).toFixed(2) + " KB";
        else
            result = sizeInBytes + " B";
        return result;
    }

}