export const props = { viewBox: "0 0 48 48" };
export const innerHTML =
    '<path fill="none" stroke="#b0bec5" stroke-miterlimit="10" stroke-width="4" d="M26,34c0,0,0.397,2.347-2,4 c-3.783,2.609-0.832,6.603-0.832,6.603"/>' +
    '<path d="M28,35l-1.109-0.198l-1.231,0.696l-0.696-1.231L24,34l2.032-3.587l1.959,0.595L28,35z"/>' +
    '<path " d="M34.378,3.459c-5.724-1.587-11.721,1.545-14.122,7.052l1.003,2.007L22,14l-2.793,0.986L21,18 l-1.877,0.938c0.441,5.654,2.869,11.58,7.403,12.837c6.479,1.797,14.195-6.789,15.982-13.233C44.322,12,40.666,5.203,34.378,3.459z"/>' +
    '<path fill="#fbe9e7" d="M25,9c0,0,0.993-2.83,4.356-3.747C33.483,4.127,36,7,36,7C31.887,5.901,28.573,6.329,25,9z"/>' +
    '<polygon points="17.967,6.989 20.7,11.74 22,14 21.978,8.033"/>' +
    '<polygon points="17,20 21,18 20.967,21.027"/>' +
    '<polygon points="14.713,9.958 4.713,6.958 5,6 5.287,5.042 15.287,8.042"/>' +
    '<polygon points="15,14 5,14 5,13 5,12 15,12"/>' +
    '<polygon points="5.284,20.949 5,20 4.716,19.051 14.716,16.051 15.284,17.949"/>';
