import { VerticalAlignment } from "@mcleod/core";
import { Cursor } from "./base/Cursor";
import { Label } from "./components/label/Label";
import { LabelProps } from "./components/label/LabelProps";

export class PropsAccessLabelCreator {
    public static create(labelProps: Partial<LabelProps>) {
        return new Label({
            text: "Props",
            color: "primary",
            borderWidth: 0.5,
            borderColor: "primary",
            borderRadius: 4,
            cursor: Cursor.POINTER,
            fontFamily: "Georgia, serif",
            verticalAlign: VerticalAlignment.CENTER,
            tooltip: "Click to access the component's properties",
            ...labelProps
        });
    }
}
