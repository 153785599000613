import {
    Component, DecoratorRouter, IFrame, Layout, Overlay, Panel, PanelProps, PanelStaticError, ScreenStack,
    Spinner, syncDocumentKeyListener
} from "@mcleod/components";
import {
    Api, ArrayUtil, AuthToken, ClientRegistration, DynamicLoader, GeneralSettings, LogManager, McLeodContext,
    Navigation, StringUtil, UrlUtil, WindowTitle
} from "@mcleod/core";
import { DataLoadActionHandler } from "./DataLoadActionHandler";
import { McLeodMainPage } from "./McLeodMainPage";

const log = LogManager.getLogger("common.Router");

/* This call is how we inform the History module that the main page's Router is what will handle be used to display
 * new content as the user navigates the site */
Navigation.setNavigationHandler({
    displayRoute: (path: string, props: any) => {
        const mainPage = McLeodMainPage.getInstance();
        if (mainPage == null)
            window.location.pathname = path;
        else {
            // window.history.pushState(props, "McLeod", path);
            if (mainPage.router == null) {
                path = DynamicLoader.getRouteFromURL(path);
                const cls = DynamicLoader.getClassForPath(path);
                const inst = new cls(props);
                mainPage.removeAll();
                mainPage.add(inst);
            }
            else
                mainPage.router.displayRoute(path, props);
        }
    },
    getRoute: () => {
        const router = McLeodMainPage.getInstance().router;
        let result = router._route;
        if (!StringUtil.isEmptyString(router._routeSearchString))
            result += router._routeSearchString;
        return result;
    }
});

export interface RouterProps extends PanelProps {
    isMainDocumentRouter: boolean;
    settingsRoot: string;
}

/**
 * The Router class is responsible for figuring out which Layout to display based on the user's
 * current browser address.  It dynamically loads the class specified by the address and
 */
export class Router extends Panel {
    _isMainRouter: boolean;
    _route: string;
    _routeSearchString: string;
    settingsRoot: string;
    public static loginRoute: string = "common/Login";
    public static homePage = "common/home/Home";

    constructor(props: Partial<RouterProps>) {
        super({
            id: props?.id == null ? "mainRouter" : props.id,
            fillRow: true,
            fillHeight: true,
            padding: 24,
            paddingRight: 18,
            scrollX: true,
            scrollY: true,
            ...props
        });
        //do not add non-root Routers to the ScreenStack; they should be contained in some other panel/layout that
        //is being displayed within the root Router
        if (props?.isMainDocumentRouter !== false) {
            ScreenStack.push(this);
            this._isMainRouter = true;
            McLeodContext.setRouter(this);
        }
        else
            this._isMainRouter = false;
    }

    displayRoute(route: string, props?) {
        this.populateAuthSettingsThenDisplay(route, props);
    }

    populateAuthSettingsThenDisplay(route: string, props?) {
        if (props?._auth != null) {
            AuthToken.set(props._auth);
            delete props._auth;
            window.history.replaceState({ props }, "McLeod", window.location.pathname);
        }
        if (AuthToken.isAuthenticated() && GeneralSettings.getSingleton().authSettingsPopulated() === false) {
            ClientRegistration.addUnregisterBeforeUnloadListener();
            const root = this.settingsRoot || "lme/";
            const body = (root === "lme/") ? ClientRegistration.getAuthSettingsBody() : {};
            Api.search(root + "auth-settings", body).then(response => {
                const licensingResult = ClientRegistration.handleLicensingResult(response);
                if (licensingResult === true) {
                    this._displayRoute(route, props);
                }
            }).catch(err => {
                log.info("%cError loading page\n", "background: #222; color: white", err);
                this.setRouterContents(new PanelStaticError({ errorSummary: "Sorry, there was an error loading this page.", devDetail: err + "\n" }));
            });
        }
        else
            this._displayRoute(route, props);
    }

    _displayRoute(route: string, props?) {
        if (route?.indexOf(":") >= 0) {
            this.displayRemoteRoute(route, props);
            return;
        }

        Overlay.hideAllOverlays();
        this.getKeyHandlerGroup().clear();
        Navigation.removeAllNavigationListeners();
        props = { ...props, ...UrlUtil.getPropsFromUrl(route) };
        route = DynamicLoader.getRouteFromURL(route);
        this.removeAll();
        const auth = AuthToken.isAuthenticated();
        const base = GeneralSettings.get().base_url_offset;
        if (base != null && route.startsWith(base)) {
            route = route.substring(base.length);
            if (route.startsWith("/"))
                route = route.substring(1);
        }
        if (route.length === 0) {
            if (auth)
                route = this.getUserHomePage();
            else
                route = Router.loginRoute;
        }
        this._route = route;
        this._routeSearchString = window.location.search;
        if (props._undecorated !== "")
            this.updateHeader();
        let routeKey = route;
        if (route != null && typeof route === "string" && route.charAt(0) === "/")
            routeKey = routeKey.substring(1);
        this.add(new Spinner());
        const layout = Layout.getLayout(route, props);
        if (props._undecorated !== "")
            this.applyRouterPropsFromPage(layout);
        layout.addLayoutLoadListener(event => {
            const decorated: Component = DecoratorRouter.decoratePage(layout);
            this.setRouterContents(decorated);
            if (this._isMainRouter == true) {
                //if the layout in the main router is changing, the router needs to fill its key handler group
                //this pulls all the key handlers up into a flat collection within the router
                this.fillKeyHandlerGroup(true);
            }
            else {
                //if the layout in a nested router (such as the demo router) is changing, the main router needs
                //to fill its key hander group.  key handlers from the nested router should be pulled up
                //into the main router's flat collection
                McLeodMainPage.getInstance().router.fillKeyHandlerGroup(true);
            }
            syncDocumentKeyListener();
            WindowTitle.set(layout.windowTitle || "McLeod Portal");
        });
        layout.addLoadErrorListener(error => {
            WindowTitle.set("McLeod Portal");
            this.setRouterContents(layout);
        });
        if (props != null) {
            const dataLoadActionProps = DataLoadActionHandler.gatherFromUrlProps(props);
            if (ArrayUtil.isEmptyArray(dataLoadActionProps) === false) {
                layout.addLayoutDataLoadListener(event => DataLoadActionHandler.handleDataLoadActions(layout, dataLoadActionProps, props));
            }
        }
    }

    private displayRemoteRoute(route: string, props: any) {
        if (route.startsWith("/"))
            route = route.substring(1);
        const iframe = new IFrame({src: route, fillRow: true, fillHeight: true, ...props});
        this.setRouterContents(iframe);
    }

    setRouterContents(...components: Component[]) {
        this.removeAll();
        this.add(...components);
    }

    applyRouterPropsFromPage(owner): void {
        if (owner == null || owner.getRouterProps == null)
            this.setProps({ padding: 24 });
        else
            this.setProps(owner.getRouterProps());
    }

    updateHeader() {
        const mainPage = McLeodMainPage.getInstance();
        if (mainPage != null && mainPage.header != null) {
            const shouldShowHeader = this.isPageWithHeader(this._route);
            const headerAlreadyPresent = mainPage.contains(mainPage.header);
            if (shouldShowHeader && !headerAlreadyPresent)
                mainPage.insert(mainPage.header, 0);
            else if (!shouldShowHeader && headerAlreadyPresent)
                mainPage.remove(mainPage.header);
        }
    }

    private isPageWithHeader(page: string) {
        return (page !== Router.loginRoute 
            && page !== "common/ResetPassword" 
            && page !== "auth/UserInvitation");
    }

    private getUserHomePage(): string {
        return Router.homePage;
    }
}
