export const props = { viewBox: "0 0 24 24" };
export const innerHTML =
    '<path d="M16 8H20L22 10.1053V17.5H19.5" stroke="currentcolor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '<path d="M8 18H16V4H3V6.69231" stroke="currentcolor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '<circle cx="18" cy="18" r="1.25" stroke="currentcolor" stroke-width="1.5"/>\n' +
    '<circle cx="6" cy="18" r="1.25" stroke="currentcolor" stroke-width="1.5"/>\n' +
    '<path d="M3 9H10" stroke="currentcolor" stroke-width="1.5" stroke-linecap="round"/>\n' +
    '<path d="M2 11.5H9" stroke="currentcolor" stroke-width="1.5" stroke-linecap="round"/>\n' +
    '<path d="M1 14H8" stroke="currentcolor" stroke-width="1.5" stroke-linecap="round"/>\n' +
    '<path d="M4.65625 18H3V16.5" stroke="currentcolor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '<path d="M18 13V10H19.2L20 11.5V13H18Z" fill="currentcolor"/>';
