import { ComponentPropDefinitions, ComponentPropDefinitionUtil } from "../../base/ComponentProps";
import { ContainerProps } from "../../base/ContainerProps";
import { PropType } from "../../base/PropType";

export enum RepeaterVariant {
    horizontal = "horizontal",
    vertical = "vertical",
    grid = "grid"
}

export interface RepeaterProps extends ContainerProps {
    variant: RepeaterVariant;
    itemGap: string | number;
}

let repeaterPropDefs: ComponentPropDefinitions;

export class RepeaterPropDefinitions {
    public static getDefinitions(): ComponentPropDefinitions {
        if (repeaterPropDefs == null) {
            repeaterPropDefs = {
                ...ComponentPropDefinitionUtil.getComponentPropertyDefinitions(),
                itemGap: {
                    type: PropType.string,
                    defaultValue: "16",
                    description: "Specifies the amount of space (in pixels) between each item in the Repeater"
                },
                variant: {
                    type: PropType.string,
                    dropdownProps: { items: [...Object.keys(RepeaterVariant)] },
                    description: "Defines the layout orientation of the Repeater items. Choose 'horizontal' for a single row with horizontal scrolling, 'vertical' for a single column with vertical scrolling, or 'grid' for a responsive grid layout that adjusts based on the viewport size.",
                    defaultValue: RepeaterVariant.grid,
                    affectsProps: ["fillHeight", "fillRow"],
                },
                onItemDisplay: { type: PropType.event, category: "Events", addListenerMethod: "addItemDisplayListener", removeListenerMethod: "removeItemDisplayListener", eventSignature: "OnItemDisplay(event: RepeatItemDisplayEvent)", description: "This event will be fired when a RepeatItem is displayed." },
             }
            ComponentPropDefinitionUtil.populateComponentPropNames(repeaterPropDefs);
        }
        return repeaterPropDefs;
    }
}
