import { Button, HorizontalSpacer, Label, Layout, Panel, SearchButton } from "@mcleod/components";

export abstract class AutogenLayoutReportPreview2 extends Layout {
    buttonClear: Button;
    buttonDownloadExcel: Button;
    buttonDownloadPdf: Button;
    buttonIsBusy: Button;
    horizontalspacer1: HorizontalSpacer;
    labelPreview: Label;
    panelHeader: Panel;
    panelPdf: Panel;
    panelPdfPreview: Panel;
    searchbuttonGenerate: SearchButton;
}
