export class Timezone {
    public static EASTERN = new Timezone("America/New_York", "EST", "EDT", 300);
    public static CENTRAL = new Timezone("America/Chicago", "CST", "CDT", 360);
    public static MOUNTAIN = new Timezone("America/Denver", "MST", "MDT", 420);
    public static PACIFIC = new Timezone("America/Los_Angeles", "PST", "PDT", 480);
    private static zones = [Timezone.EASTERN, Timezone.CENTRAL, Timezone.MOUNTAIN, Timezone.PACIFIC];

    private _ianaIdentifier: string;
    private _standardAbbeviation: string;
    private _daylightAbbreviation: string;
    private _gmtOffsetMinutes: number;

    public static getFromAbbreviation(abbreviation: string): Timezone {
        if (abbreviation != null)
            for (const zone of Timezone.zones)
                if (abbreviation === zone.standardAbbreviation || abbreviation === zone.daylightAbbreviation)
                    return zone;
        return null;
    }

    public static getZones(): Timezone[] {
        return Timezone.zones;
    }

    constructor(ianaIdentifier: string, standardAbbeviation: string,
        daylightAbbreviation: string, standardGMTOffsetMinutes: number) {
        this._ianaIdentifier = ianaIdentifier;
        this._standardAbbeviation = standardAbbeviation;
        this._daylightAbbreviation = daylightAbbreviation;
        this._gmtOffsetMinutes = standardGMTOffsetMinutes;
    }

    public get ianaIdentifier(): string {
        return this._ianaIdentifier;
    }

    public get standardAbbreviation(): string {
        return this._standardAbbeviation;
    }

    public get daylightAbbreviation(): string {
        return this._daylightAbbreviation;
    }

    public get standardGMTOffsetMinutes(): number {
        return this._gmtOffsetMinutes;
    }

    public get daylightGMTOffsetMinutes(): number {
        return this._gmtOffsetMinutes - 60;
    }
}