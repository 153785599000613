import { Panel, PanelProps } from "@mcleod/components";
import { ArrayUtil, HorizontalAlignment } from "@mcleod/core";
import { KeyValuePair } from "./KeyValuePair";

export class PanelKeyValuePairs extends Panel {
    private _keyValuePairs: KeyValuePair[];
    private delimiter: string;
    private panelKeys: Panel;
    private panelValues: Panel;

    constructor(props?: Partial<PanelProps>, keyValuePairs?: KeyValuePair[], delimiter: string = ":") {
        super({ ...props });
        this._keyValuePairs = keyValuePairs;
        this.delimiter = delimiter;
        this.panelKeys = new Panel({ margin: 0, padding: 0, align: HorizontalAlignment.RIGHT, rowBreakDefault: true, rowBreak: false });
        this.panelValues = new Panel({ margin: 0, padding: 0, align: HorizontalAlignment.LEFT, rowBreakDefault: true });
        this.add(this.panelKeys, this.panelValues);
        this.loadComponents();
    }

    private loadComponents() {
        this.panelKeys.removeAll();
        this.panelValues.removeAll();
        if (this._keyValuePairs != null) {
            for (const pair of this._keyValuePairs) {
                this.panelKeys.add(pair.createKeyLabel(this.delimiter));
                this.panelValues.add(pair.createValueLabel());
            }
        }
    }

    public get keyValuePairs(): KeyValuePair[] {
        return this._keyValuePairs;
    }

    public set keyValuePairs(value: KeyValuePair[]) {
        this._keyValuePairs = value;
        this.loadComponents();
    }

    public addKeyValuePairs(...value: KeyValuePair[]) {
        this.keyValuePairs.push(...value);
        this.loadComponents();
    }

    public removeKeyValuePairs(...value: KeyValuePair[]) {
        ArrayUtil.removeAllFromArray(this._keyValuePairs, value);
        this.loadComponents();
    }

    public removeAllWithKey(value: string | KeyValuePair) {
        const testKey = (typeof value === "string") ? value : value.key;
        const valuesToRemove: KeyValuePair[] = [];
        for (const kvp of this._keyValuePairs) {
            if (kvp.key === testKey)
                valuesToRemove.push(kvp);
        }
        this.removeKeyValuePairs(...valuesToRemove);
    }
}