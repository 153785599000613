import { DataSourceExecutionEvent } from "@mcleod/components";
import { LogReaderFileDrop } from "./LogReaderFileDrop";
import { LogReaderLogFileTextbox } from "./LogReaderLogFileTextbox";
import { RequestList } from "./RequestList";
import { AutogenLayoutLogErrorSearch } from "./autogen/AutogenLayoutLogErrorSearch";

export class LogErrorSearch extends AutogenLayoutLogErrorSearch {
    private requestList: RequestList;

    override onLoad() {
        LogReaderFileDrop.intitializeDragDropArea(this.panelDragLog, this.textLogFile);
        LogReaderLogFileTextbox.initializeFileNameTextbox(this.textLogFile);
        this.requestList = this.layoutRequestList as RequestList;
        this.requestList.removeDBStatColumns();
    }

    /** This is an event handler for the afterExecution event of sourceLogReader.  */
    sourceLogReaderAfterExecution(event: DataSourceExecutionEvent) {
        const row = event.dataSource?.data?.[0];
        if (row != null) {
            this.requestList.highlightText = this.textErrorTag.text;
            this.requestList.requestWithError = row.get("request_with_error");
            const sel = this.textLogFile.selectedItem;
            if (sel?.["isDraggedFile"]) {
                this.requestList.logFileName = sel.displayValue;
                this.requestList.logFileContents = sel.value;
            } else {
                this.requestList.logFileName = this.textLogFile.selectedItem.value;
                this.requestList.logFileContents = undefined;
            }
            this.requestList.requestData = row.get("requests");
        }
    }

    /** This is an event handler for the beforeExecution event of sourceLogSearchError.  */
    sourceLogSearchErrorBeforeExecution(event: DataSourceExecutionEvent) {
        const sel = this.textLogFile.selectedItem;
        if (sel?.["isDraggedFile"]) {
            event.filter.set("log_name", sel.displayValue);
            event.filter.set("log_contents", sel.value);
        }
    }
}
