export const props = { viewBox: "0 0 24 24" };
export const innerHTML = `<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_105_2948)">
<path d="M4.47871 8.51119e-05C3.11233 8.51119e-05 2.00006 1.11315 2.00006 2.47948V21.5205C2.00006 22.8868 3.11233 23.9999 4.47871 23.9999H19.0388C20.4052 23.9999 21.5174 22.8869 21.5174 21.5205V5.86348C21.5174 5.86348 21.5564 5.40502 21.3574 4.95888C21.1708 4.54069 20.8668 4.24962 20.8668 4.24962C20.866 4.24859 20.8653 4.24757 20.8645 4.24655L17.2521 0.703256C17.2501 0.701235 17.2481 0.699234 17.2461 0.697255C17.2461 0.697255 16.9377 0.403574 16.4805 0.207386C15.9422 -0.0236163 15.3873 0.000768942 15.3873 0.000768942L15.3956 0L4.47871 8.51119e-05Z" fill="#8C8C8C"/>
<path d="M4.47872 0.814453H15.3956C15.3983 0.814481 15.4011 0.814481 15.4039 0.814453C15.4039 0.814453 15.8404 0.818684 16.1597 0.955704C16.467 1.08758 16.6849 1.28721 16.6864 1.28855C16.6864 1.28855 16.6879 1.29009 16.6879 1.29009L20.2913 4.8251C20.2913 4.8251 20.5084 5.05431 20.6136 5.29018C20.6985 5.48057 20.7038 5.82889 20.7038 5.82889C20.7034 5.83464 20.7031 5.8404 20.703 5.84616V21.5205C20.703 22.4498 19.9681 23.1854 19.0388 23.1854H4.47872C3.54936 23.1854 2.81451 22.4499 2.81451 21.5205V2.47944C2.81451 1.55003 3.54936 0.814453 4.47872 0.814453Z" fill="#F5F5F5"/>
<path d="M6.87341 14.2893C6.31305 13.7289 6.91932 12.9589 8.56466 12.1412L9.59993 11.6267L10.0033 10.7443C10.2251 10.2589 10.5562 9.46699 10.739 8.98443L11.0714 8.10704L10.8423 7.45772C10.5607 6.65932 10.4602 5.45954 10.6389 5.02812C10.881 4.44375 11.6738 4.50371 11.9877 5.13012C12.2329 5.61934 12.2078 6.50531 11.9172 7.62265L11.679 8.53867L11.8888 8.8949C12.0042 9.09083 12.3414 9.55605 12.638 9.92871L13.1955 10.6222L13.8892 10.5317C16.0931 10.2441 16.8479 10.7328 16.8479 11.4336C16.8479 12.3181 15.1174 12.3911 13.6642 11.3705C13.3373 11.1408 13.1127 10.9127 13.1127 10.9127C13.1127 10.9127 12.2024 11.098 11.7541 11.2188C11.2915 11.3435 11.0607 11.4216 10.3829 11.6504C10.3829 11.6504 10.1452 11.9957 9.99021 12.2468C9.41373 13.1807 8.74063 13.9547 8.25993 14.2364C7.72172 14.5519 7.15749 14.5734 6.87341 14.2893ZM7.75281 13.9751C8.0678 13.7804 8.70535 13.0264 9.14667 12.3265L9.32536 12.0432L8.51176 12.4523C7.25518 13.0841 6.68032 13.6795 6.97924 14.0397C7.14717 14.242 7.34805 14.2253 7.75281 13.9751ZM15.915 11.684C16.2231 11.4682 16.1784 11.0334 15.8301 10.858C15.559 10.7216 15.3405 10.6936 14.6364 10.7039C14.2036 10.7335 13.5078 10.8207 13.3899 10.8472C13.3899 10.8472 13.7721 11.1114 13.9418 11.2085C14.1678 11.3375 14.7168 11.5771 15.1176 11.6998C15.513 11.8208 15.7417 11.808 15.915 11.684ZM12.6331 10.32C12.4467 10.124 12.1296 9.71488 11.9286 9.41089C11.6656 9.06605 11.5338 8.82276 11.5338 8.82276C11.5338 8.82276 11.3416 9.44092 11.1839 9.81291L10.692 11.0288L10.5494 11.3046C10.5494 11.3046 11.3076 11.056 11.6934 10.9552C12.102 10.8485 12.9312 10.6854 12.9312 10.6854L12.6331 10.32ZM11.5755 6.07856C11.623 5.67945 11.6432 5.28094 11.515 5.07998C11.1593 4.69115 10.73 5.0154 10.8027 5.93981C10.8272 6.25078 10.9045 6.78239 11.0077 7.11005L11.1955 7.70578L11.3277 7.25709C11.4004 7.01031 11.5119 6.47997 11.5755 6.07856Z" fill="#8C8C8C"/>
<path d="M7.82635 16.8843H8.73612C9.02682 16.8843 9.26279 16.9121 9.44402 16.9677C9.62526 17.0215 9.77778 17.1427 9.9016 17.3311C10.0254 17.5177 10.0873 17.7429 10.0873 18.0067C10.0873 18.2489 10.0371 18.4571 9.93659 18.6311C9.8361 18.8052 9.70062 18.9308 9.53015 19.008C9.36148 19.0851 9.10129 19.1237 8.74958 19.1237H8.43466V20.5583H7.82635V16.8843ZM8.43466 17.3553V18.6365H8.73612C9.00529 18.6365 9.19101 18.5863 9.29329 18.4858C9.39737 18.3853 9.4494 18.222 9.4494 17.9959C9.4494 17.8272 9.41531 17.6909 9.34712 17.5868C9.27894 17.4809 9.20357 17.4163 9.12103 17.393C9.04028 17.3679 8.91198 17.3553 8.73612 17.3553L8.43466 17.3553ZM10.553 16.8843H11.3793C11.7795 16.8843 12.0989 16.9552 12.3375 17.0969C12.578 17.2387 12.7592 17.4495 12.8812 17.7294C13.005 18.0094 13.0669 18.3198 13.0669 18.6607C13.0669 19.0196 13.0113 19.3399 12.9001 19.6217C12.7906 19.9016 12.6219 20.1277 12.394 20.2999C12.1679 20.4722 11.845 20.5583 11.4251 20.5583H10.553V16.8843ZM11.1613 17.3715V20.0712H11.4143C11.7678 20.0712 12.0244 19.9491 12.1841 19.7051C12.3438 19.4593 12.4236 19.1318 12.4236 18.7227C12.4236 17.8219 12.0872 17.3715 11.4143 17.3715H11.1613ZM13.651 16.8843H15.6913V17.3715H14.2593V18.4696H15.406V18.9568H14.2593V20.5583H13.651V16.8843Z" fill="#8C8C8C"/>
</g>
<defs>
<clipPath id="clip0_105_2948">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>`