import { ContextMenu } from "../components/contextmenu/ContextMenu";
import { ContextMenuLabel } from "../components/contextmenu/ContextMenuLabel";
import { DomEvent } from "./DomEvent";
import { Event, EventListener } from "./Event";

export interface ContextMenuCreationListener extends EventListener {
    (event: ContextMenuCreationEvent): void;
}

export class ContextMenuCreationEvent extends Event {
    private _contextMenu: ContextMenu = null;

    constructor(component: any, contextMenu: ContextMenu, domEvent?: DomEvent) {
        super(component, domEvent);
        this._contextMenu = contextMenu;
    }

    get contextMenu(): ContextMenu {
        return this._contextMenu;
    }

    get menuLabels(): ContextMenuLabel[] {
        return this._contextMenu?.menuLabels ?? [];
    }
}
