import { Collection, CompanySettings, CurrencyUtil, DateUtil, getApiMetadataFromCache, ModelRow, StringUtil, UserSettings } from ".";
import { DisplayType } from "./constants/DisplayType";
import { Currency } from "./Currency";
import { DateFormat, ExtendedDateFormat } from "./Date";
import { DateRange } from "./DateRange";
import { NumberUtil } from "./NumberUtil";
import { getRelativeDateString } from "./RelativeDate";

export class DisplayValue {

    public static getDisplayValue(value, displayType: DisplayType, format?: string): string {
        let displayValue = value;
        if (value != null && displayType != null) {
            displayValue = DisplayValue._internalGetDisplayValue(value, displayType, format);
        }
        return typeof displayValue === "string" ? displayValue : displayValue?.toString();
    }

    private static _internalGetDisplayValue(value, displayType: DisplayType, format?: string): string {
        switch (displayType) {
            case DisplayType.CURRENCY:
                return DisplayValue.getCurrencyDisplayValue(value, format);

            case DisplayType.DATETIME:
            case DisplayType.DATE:
            case DisplayType.TIME:
            case DisplayType.DATERANGE:
                return DisplayValue.getDateDisplayValue(value, displayType, format);

            case DisplayType.DECIMAL:
            case DisplayType.WEIGHT:
            case DisplayType.TEMPERATURE:
                if (typeof value === "string")
                    value = parseFloat(NumberUtil.removeFormatting(value));
                return NumberUtil.formatDecimal(value, format);

            case DisplayType.INTEGER:
                if (typeof value === "string")
                    value = parseInt(NumberUtil.removeFormatting(value));
                return NumberUtil.formatInteger(value, format);

            case DisplayType.PHONE:
                return DisplayValue.getPhoneDisplayValue(value);

            default:
                return value;
        }
    }

    public static getCurrencyDisplayValue(value: Currency | string | number, format?: string): string {
        if (typeof value === "string" || typeof value === "number")
            value = Currency.createCurrencyWithDefaults(value)
        return CurrencyUtil.formatCurrency(value as Currency);
    }

    public static getDateDisplayValue(value, displayType: DisplayType, format?: string): string {
        if (format === ExtendedDateFormat.RELATIVE)
            return getRelativeDateString(DateUtil.parseDateTime(value));
        else if (DisplayValue.isExtDateFormat(format)) {
            const extDateFormat = format as ExtendedDateFormat;
            if (displayType === DisplayType.DATE)
                return DateUtil.formatDate(DateUtil.parseDate(value), DateUtil.getDateFormat(DateFormat.DATE, extDateFormat));
            else if (displayType === DisplayType.DATETIME)
                return DateUtil.formatDateTime(DateUtil.parseDateTime(value), DateUtil.getDateFormat(DateFormat.DATE_TIME, extDateFormat));
            else if (displayType === DisplayType.TIME)
                return DateUtil.formatTime(DateUtil.parseTime(value), DateUtil.getDateFormat(DateFormat.TIME, extDateFormat));
            else if (displayType === DisplayType.DATERANGE)
                return DateUtil.formatDateRange(DateRange.parseDateRange(value), DateUtil.getDateFormat(DateFormat.DATE, extDateFormat));
        } else {
            if (displayType === DisplayType.DATE)
                return DateUtil.formatDate(DateUtil.parseDate(value), format);
            else if (displayType === DisplayType.DATETIME)
                return DateUtil.formatDateTime(DateUtil.parseDateTime(value), format);
            else if (displayType === DisplayType.TIME)
                return DateUtil.formatTime(DateUtil.parseTime(value), format);
            else if (displayType === DisplayType.DATERANGE)
                return DateUtil.formatDateRange(DateRange.parseDateRange(value), format);
        }
    }

    private static isExtDateFormat(format: string): boolean {
        for (const fmt of Object.values(ExtendedDateFormat))
            if (fmt === format)
                return true;
        return false;
    }

    /**
     * Given a format specifier and a ModelRow, this will return a string that formats
     * the ModelRow's data.  Fields are delimited using curly braces.
     *
     * For example, assume you have a row from the customer model with basic address information.
     * If you pass this for the format:
     *
     * Customer: {id} - {name}\n{city}, {state} {zip}
     *
     * The return value will be something like:
     *
     * Customer: MCLBAL - McLeod Software
     * Birmingham, AL 35244
     *
     * *
     *
     * It is also possible to include select values in the output by using specified keywords.  The available values currently include:
     *  -> user_id, company_id, company_name, company_scac (all taken from UserSettings)
     *  -> <field>-table_name (taken from the provided ModelRow)
     *
     * To do this, enclose the requested 'field' in dollar signs.  For example, the following format:
     *
     * User: {$user_id$} - {$company_id$}
     *
     * Would return something like:
     *
     * User: jasonb - TMS
     *
     * @param format
     * @param row
     * @param replacements
     * @returns
     */
    public static getFormattedDataString(format: string, row: ModelRow, replacements?: Collection<string>): string {
        if (format == null)
            return "";
        if (format.indexOf("{") < 0 || format.indexOf("}") < 0)
            return format;
        let result = format;
        const modelRegex = /{[^{}]+}/;
        let match = modelRegex.exec(result);
        while (match != null) {
            let evalValue = "";
            let fieldName = match[0].substring(1, match[0].length - 1);
            const required = fieldName.startsWith("!");
            if (required)
                fieldName = fieldName.substring(1);
            const replacement = replacements == null ? null : replacements[fieldName];
            if (required && replacement == null)
                return "";
            if (replacement != null)
                evalValue = replacement;
            else if (fieldName.startsWith("$") && fieldName.endsWith("$")) {
                fieldName = fieldName.substring(1, fieldName.length - 1);
                if (fieldName.endsWith("-table_name")) {
                    if (row != null)
                        evalValue = getApiMetadataFromCache(row._modelPath)?.output[fieldName.substring(0, fieldName.indexOf("-"))]?.tableName;
                    else
                        evalValue = "";
                }
                else
                    evalValue = DisplayValue.getDisplayableUserSettingValue(fieldName);
            }
            else if (row != null) {
                evalValue = row.get(fieldName);
                if (evalValue == null)
                    evalValue = "";
                else {
                    const displayType = getApiMetadataFromCache(row._modelPath)?.output[fieldName]?.displayType;
                    DisplayValue.getDisplayValue(evalValue, displayType);
                }
            }
            else
                evalValue = "";
            result = result.substring(0, match.index) + evalValue + result.substring(match.index + match[0].length);
            match = modelRegex.exec(result);
        }
        return result;
    }

    private static getDisplayableUserSettingValue(key: string): string {
        switch (key) {
            case "user_id": return UserSettings.getUserId();
            case "company_id": return UserSettings.getSingleton().getCompanyId();
            case "company_name": return UserSettings.getSingleton().getCompanyName();
            case "company_scac": return UserSettings.getSingleton().getCompanySCAC();
            default: return "";
        }
    }

    public static getPhoneDisplayValue(value: string): string {
        const settings = CompanySettings.get();

        if (settings.enable_phone_format === false || value.startsWith("0")) {
            // International phone number or disabled formatting - return as is
            return value;
        }

        let result = value.toLowerCase().replace("ext", "x");
        const xIndex = result.indexOf("x");
        let extension: string = "";

        if (xIndex >= 0) {
            extension = result.substring(xIndex + 1).replace(" ", "");
            result = result.substring(0, xIndex);
        }

        const strippedValue = result.replace(new RegExp("([( )-\. ])", 'g'), "");
        return DisplayValue.parsePhoneNumber(strippedValue, extension, settings.phone_format);
    }

    private static parsePhoneNumber(value: string, extension: string, format: string): string {
        if (!isNaN(Number(value)) && value?.length === 10) {
            const areaCode = value.substring(0, 3);
            const prefix = value.substring(3, 6);
            const suffix = value.substring(6) + (!StringUtil.isEmptyString(extension) ? ` x${extension}` : "");


            switch (format) {
                case "-":
                    return `${areaCode}-${prefix}-${suffix}`;
                case ".":
                    return `${areaCode}.${prefix}.${suffix}`;
                default:
                    return `(${areaCode}) ${prefix}-${suffix}`;
            }
        }
        return value;
    }
}
