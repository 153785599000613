import { ModelRow } from "@mcleod/core";
import { ComponentSearcher } from "./ComponentSearcher"
import { ComponentSearchResult } from "./ComponentSearchResult";

/**
 * Searcher class that facilitates the searching of values related to labels.
 *
 * Always search the label's field in the modelRow.  Also, when possible, add the displayed value based on the label's
 * defined format.
 */
export class LabelSearcher extends ComponentSearcher {
    private labelDef: any;

    constructor(labelDef: any, alias: string) {
        super(labelDef.field, alias);
        this.labelDef = labelDef;
    }

    protected addValuesToResult(searchResult: ComponentSearchResult, row: ModelRow) {
        this.addDisplayValue(searchResult, row, this.labelDef.displayType, this.labelDef.format);
    }
}
