import { Client } from "@stomp/stompjs";
import { GeneralSettings, Logger, LogManager } from "..";

export class Messaging {
    private static stompLog: Logger;

    public static getClient(): Client {
        const result = new Client({ connectHeaders: { login: 'msg', passcode: GeneralSettings.get().messaging_passcode } });
        result.heartbeatOutgoing=65000
        result.brokerURL = GeneralSettings.get().messaging_url;
        if (this.getLog().isDebugEnabled)
            result.debug = msg => this.getLog().debug(msg);
        return result;
    }

    private static getLog(): Logger {
        if (this.stompLog == null)
            this.stompLog = LogManager.getLogger("core.messaging.StompLog");
        return this.stompLog;
    }
}
