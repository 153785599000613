import { Component } from "../../base/Component";
import { ComponentTypes } from "../../base/ComponentTypes";
import { ProgressBarPropDefinitions, ProgressBarProps } from "./ProgressBarProps";
import { ComponentPropDefinition, ComponentPropDefinitions } from "../../base/ComponentProps";
import { Color, getThemeColor } from "@mcleod/core";
import { ProgressBarStyles } from "./ProgressBarStyles";

/**
 * This component is a bar that is used to show progress of something - usually 
 * a task that is running asynchronously.  It contains a value and a maxValue property
 * that are used to inidcate the progress.
 * For example, assume we are posting billing records and we count 500 of them to start with.
 * We set the maxValue of the ProgressBar to 500 and then increment the value property as we
 * post each record.  The ProgressBar will show the progress of the posting.
 * The caption property can be set to values to indicate the progress and allows 
 * for the use of the following keywords:
 * $percent - the percent of the progress
 * $value - the current value
 * $maxValue - the maximum value
 * For example, we might set the caption to "Posting $value of $maxValue records ($percent%)"
 * The caption could also be set to something that doesn't use the keywords, such as 
 * "Posting invoice 0001234..."
 * Finally, the color of the bar can be set using the barColor property.  The barColorGradient
 * property can be set to make a gradient on the right side of the bar.
 */
export class ProgressBar extends Component implements ProgressBarProps {
    private _src: string;
    private bar: HTMLDivElement;
    private textElement: HTMLDivElement;
    private reflection: HTMLDivElement;
    _caption: string ;
    _maxValue: number;
    _barColor: Color;
    _barColorGradient: Color;
    _value: number;

    constructor(props?: Partial<ProgressBarProps>) {
        super("div", props);
        this.bar = document.createElement("div");
        this._element.className = ProgressBarStyles.base;   
        this.bar.className = ProgressBarStyles.bar;
        this.textElement = document.createElement("div");
        this.textElement.className = ProgressBarStyles.text;
        this.reflection = document.createElement("div");
        this.reflection.className = ProgressBarStyles.reflection;
        this._element.appendChild(this.textElement);
        this._element.appendChild(this.bar);
        this._element.appendChild(this.reflection);
        this.setProps({
            barColor: "primary", 
            borderWidth: 1,
            borderColor: "primary.darker",
            borderRadius: 4,
            minWidth: 180,
            minHeight: 32,
            maxValue: 100,
            fontBold: true,
            value: 0,
            caption: "$value%", 
            color: "default.dark", 
            backgroundColor: "subtle.lightest",
            barColorGradient: "primary.light",
            ...props
        });
    }

    get barColor() {
        return this._barColor;
    }

    set barColor(value: Color) {
        this._barColor = value;
        this.syncBackgroundColor();
    }

    get barColorGradient() {
        return this._barColorGradient;
    }

    set barColorGradient(value: Color) {
        this._barColorGradient = value;
        this.syncBackgroundColor();
    }
    
    private syncBackgroundColor() {
        if (this.barColorGradient == null) {
            this.bar.style.background = getThemeColor(this.barColor);
        } else {
            this.bar.style.background = "linear-gradient(90deg, " + getThemeColor(this.barColor) + 
            " 0%, " + getThemeColor(this.barColorGradient) + " 100%)";
        }
    }

    get caption() {
        return this._caption;
    }

    set caption(value: string) {
        this._caption = value;
        this.syncBar();
    }

    get maxValue() {
        return this._maxValue;
    }

    set maxValue(value: number) {
        if (value == null || isNaN(value))
            value = 0;
        this._maxValue = value;
        this.syncBar();
    }

    get value() {
        return this._value || 0;
    }

    set value(value: number) {
        if (value == null || isNaN(value))
            value = 0;
        this._value = value;
        this.syncBar();
    }

    private syncBar() {
        if (this._maxValue > 0 && this.value >= 0)
            this.bar.style.width = Math.min(100, this._value / this._maxValue * 100) + "%";
        else
            this.bar.style.width = "0%";
        this.textElement.innerText = this.formatString();
    }

    private formatString(): string {
        if (this.caption == null)
            return null;

        return this.caption
            .replace("$percent", (this.value / this.maxValue * 100).toFixed(0) + "%" )
            .replace("$value", this.value?.toString())
            .replace("$maxValue", this.maxValue?.toString());
    }

    protected override determinePropertyDefaultValue(prop: ComponentPropDefinition) {
        if (prop.name === "color") {
            return "default.dark";
        } else if (prop.name === "backgroundColor") {
            return "subtle.lightest";
        } else if (prop.name === "fontBold") {
            return true;
        } else if (prop.name === "borderWidth") {
            return 1;
        } else if (prop.name === "borderColor") {
            return "primary.darker";
        } else if (prop.name === "borderRadius") {
            return 4;
        } else if (prop.name === "minWidth") {
            return 180;
        } else if (prop.name === "minHeight") {
            return 32;
        } else {
            return super.determinePropertyDefaultValue(prop);
        }
    }

    override get serializationName() {
        return "progressbar";
    }

    override get properName(): string {
        return "Progress Bar";
    }

    override getPropertyDefinitions(): ComponentPropDefinitions {
        return ProgressBarPropDefinitions.getDefinitions();
    }
}

ComponentTypes.registerComponentType("progressbar", ProgressBar.prototype.constructor);
