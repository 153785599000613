import { Alignment, DOMUtil, DateUtil, DisplayType, DisplayValue, DynamicLoader, displaysDateOrTime } from "@mcleod/core";
import { ClickEvent } from "../../events/ClickEvent";
import { Event } from "../../events/Event";
import { Anchor } from "../../page/Anchor";
import { Button } from "../button/Button";
import { ButtonVariant } from "../button/ButtonVariant";
import { ClearButtonVisible } from "./ClearButtonVisible";
import { LookupModelPopulatedButton } from "./LookupModelPopulatedButton";
import { Textbox } from "./Textbox";

const buttonProps = {
    variant: ButtonVariant.round,
    focusable: false,
    color: "component.palette.textbox.button.color",
    margin: 3,
    padding: 0
}

export class TextboxButtonHandler {
    private _button: Button;
    private hasFocus: boolean = false;
    private _multilineExpandButton: Button;

    constructor(private textbox: Textbox) {
    }

    get button(): Button {
        return this._button;
    }

    public get multilineExpandButton(): Button {
        return this._multilineExpandButton;
    }

    private get focusedAndNotEmpty(): boolean {
        return this.hasFocus === true && this.textbox.hasText();
    }

    private _buttonVisibleFocusEvent(event: Event) {
        if (this.hasFocus === true)
            return;
        this.hasFocus = true;
        this.syncButton();
    }

    private _buttonVisibleBlurEvent(event: Event) {
        const textbox = event.target as Textbox;
        if (this.hasFocus === false || textbox._element.contains(event.domEvent["relatedTarget"]))
            return;
        this.hasFocus = false;
        this.syncButton();
    }

    public syncListeners() {
        this.textbox.removeFocusListener(this._buttonVisibleFocusEvent);
        this.textbox.removeBlurListener(this._buttonVisibleBlurEvent);

        if (this.textbox.clearButtonVisible !== ClearButtonVisible.NO || this.textbox.password === true) {
            this.textbox.addFocusListener(this._buttonVisibleFocusEvent.bind(this));
            this.textbox.addBlurListener(this._buttonVisibleBlurEvent.bind(this));
        }
    }

    private addInteriorButton(button: Button, addAsFirstButton: boolean = true) {
        if (this.textbox._inputDiv == null || this.textbox._inputDiv.contains(button._element) === true)
            return;
        if (addAsFirstButton === false || this.textbox._input == null)
            this.textbox._inputDiv.appendChild(button._element);
        else
            this.textbox._inputDiv.insertBefore(button._element, this.textbox._input.nextElementSibling);
    }

    private removeButton() {
        if (this._button != null) {
            this.removeInteriorButton(this._button);
            this._button = null;
        }
    }

    private removeInteriorButton(button: Button) {
        DOMUtil.removeChild(this.textbox._inputDiv, button?._element);
    }

    syncMultiLineExpandButton() {
        if (this.textbox.multiline !== true) {
            DOMUtil.removeChild(this.textbox?._inputDiv, this.multilineExpandButton?._element);
            return;
        }
        this.createMultilineExpandButton();
        if (this.textbox.enlargeScope != null)
            this.addInteriorButton(this.multilineExpandButton, false);
    }

    private createMultilineExpandButton() {
        if (this.multilineExpandButton != null)
            return;

            this._multilineExpandButton = new Button({
                ...buttonProps,
                imageName: "expand",
                tooltip: "Click to expand",
                onClick: () => this.textbox.toggleEnlarged()
            });
        }

    syncButtonProps() {
        if (this.textbox.buttonProps == null) {
            this.syncButton();
        } else if (!this.textbox.printable) {
            this.removeButton();
            this._button = new Button({ ...buttonProps, ...this.textbox.buttonProps });
            this.addInteriorButton(this._button);
        }
    }

    syncButton(): void {
        if (this.textbox?._inputDiv == null || this.textbox.isDropdownVisible() || this.textbox.printable === true || this.textbox.buttonProps != null)
            return;

        const displayType = this.textbox.displayType;
        if (this.shouldShowButton(displayType)) {
            if (this._button == null) {
                this._button = new Button({ ...buttonProps, onClick: event => this.buttonClicked(event) });
            }
            this._button.tooltip = null;
            if (this.textbox.password) {
                this.syncPasswordButton();
            } else if (this.textbox.items != null) {
                this._button.imageName = "chevron";
            } else if (this.textbox.hasLookupModel()) {
                this.syncLookupButton();
            } else if (displaysDateOrTime(displayType)) {
                this.syncDateButton(displayType);
            } else if (displayType === DisplayType.LINK) {
                this.setToProvidedImageOrX("link");
            }  else if (displayType === DisplayType.PHONE) {
                this._button.imageName = "phone";
                this._button.tooltip = this._button.tooltip ? this._button.tooltip : "Click to dial";
            }  else if (displayType === DisplayType.EMAIL) {
                this._button.imageName = "mail";
                this._button.tooltip = this._button.tooltip ? this._button.tooltip : "Click to email";
            } else if (this.textbox.clearButtonVisible !== ClearButtonVisible.NO) {
                if ((this.hasFocus === true || this.textbox.clearButtonVisible === ClearButtonVisible.YES) && this.textbox.hasText())
                    this._button.imageName = "x"
                else
                    this.removeButton();
            }
            if (this._button != null)
                this.addInteriorButton(this._button);
        } else {
            this.removeButton();
        }
    }

    private shouldShowButton(displayType: DisplayType): boolean {
        return this.textbox.password ||
            this.textbox.hasDropdown() ||
            this.textbox.clearButtonVisible !== ClearButtonVisible.NO ||
            displaysDateOrTime(displayType) ||
            DisplayType.LINK === displayType;
    }

    private syncDateButton(displayType: DisplayType): void {
        const imageName = displayType === DisplayType.TIME ? "clock": "calendar";
        if (displayType === DisplayType.DATERANGE) {
            this._button.imageName = "calendar";
        } else {
            this.setToProvidedImageOrX(imageName);
        }
    }

    private syncPasswordButton(): void {
        if (this.focusedAndNotEmpty) {
            this._button.imageName = "visibilityOn";
        } else {
            this.removeButton();
            this.textbox._input.setAttribute("type", "password");
        }
    }

    private syncLookupButton() {
        //Lookup model fields don't care if the field has focus when deciding if the X button should be displayed.
        //So only test against the 'NO' value here.
        if (this.textbox.clearButtonVisible === ClearButtonVisible.NO)
            this._button.imageName = LookupModelPopulatedButton.MAGNIFYING_GLASS;
        else if (this.textbox.hasText() && (this.textbox.lookupModelAllowFreeform === true || this.textbox.lookupModelPopulatedButton === LookupModelPopulatedButton.X))
            this._button.imageName = "x"
        else
            this._button.imageName = LookupModelPopulatedButton.MAGNIFYING_GLASS;
    }

    private setToProvidedImageOrX(imageName: string) {
        if (this.textbox.clearButtonVisible !== ClearButtonVisible.NO && this.focusedAndNotEmpty)
            imageName = "x";
        this._button.imageName = imageName;
    }

    private buttonClicked(event: ClickEvent): void {
        if (!this.textbox._interactionEnabled || !this.textbox.enabled)
            return;

        if (this._button.imageName === "x") {
            this.textbox._setText("", event);
        } else if (this.textbox.password) {
            this.togglePasswordVisible();
        } else if (this.textbox.hasDropdown()) {
            this.textbox.toggleDropdown();
        } else if (displaysDateOrTime(this.textbox.displayType)) {
            this.toggleDatePicker();
        } else if (this.textbox.displayType === DisplayType.LINK && this.textbox.hasText()) {
            window.open(this.textbox.text, "_blank");
        } else if (this.textbox.displayType === DisplayType.PHONE && this.textbox.hasText() && this.textbox.validateSimple(false, false)) {
            window.location.href = `tel:${this.textbox.text}`;
        } else if (this.textbox.displayType === DisplayType.EMAIL && this.textbox.hasText()) {
            window.location.href = `mailto:${this.textbox.text}`;
        }
        this.textbox.focus();
    }

    private togglePasswordVisible() {
        const isPwdType = this.textbox._input.getAttribute("type") === "password";
        this.textbox._input.setAttribute("type", isPwdType ? null : "password");
        this._button.imageName = isPwdType ? "visibilityOff" : "visibilityOn";
    }

    private toggleDatePicker(): void {
        const textbox = this.textbox;
        if (textbox.isDropdownVisible()) {
            textbox.hideDropdown(true);
        } else if (!textbox.validateSimple(false, false)) {
            textbox.showTooltip(textbox.tooltip);
        } else {
            const pickerClass = DynamicLoader.getClassForPath(`components/page/pickers/${this.getDatePickerClassName()}`);
            let value: Date = textbox.getDataValue();
            if (textbox.displayType && textbox.hasText()) {
                value = DateUtil.parseDateByDisplayType(textbox.text, textbox.displayType, textbox.timezone);
            }
            const picker = new pickerClass({ value });
            picker.addChangeListener(event => {
                textbox._setText(DisplayValue.getDisplayValue(event.newValue, textbox.displayType, textbox.format), event);
                if ((event.changePart === "date" && textbox.displayType === DisplayType.DATE) || event.changePart === "minute") {
                    textbox.hideDropdown(true);
                }
            });
            Anchor.sizeToAnchor(picker, { anchor: textbox, align: Alignment.RIGHT, position: Alignment.BOTTOM });
            textbox.showOverlay(picker);
        }
    }

    private getDatePickerClassName(): string {
        const displayType = this.textbox.displayType;
        switch (displayType) {
            case DisplayType.DATETIME:
                return "DateTimePicker";
            case DisplayType.DATE:
                return "DatePicker";
            case DisplayType.TIME:
                return "TimePicker";
            case DisplayType.DATERANGE:
                return "DateRangePicker";
            default: throw new Error("Can't create a date picker with displayType " + displayType);
        }
    }
}
