import { Button, ClickEvent, Component,  DataDisplayEvent, TableRowDisplayEvent, DataSource, DataSourceMode, Label, Layout, TableRow, Toast } from "@mcleod/components";
import { AutogenLayoutUserDevicesGridExpansion } from "./autogen/AutogenLayoutUserDevicesGridExpansion";

export class UserDevicesGridExpansion extends AutogenLayoutUserDevicesGridExpansion {

    buttonToggleDeviceApprovalOnClick(event: ClickEvent) {
        const tableRow = TableRow.getContainingTableRow(event.target as Component);
        tableRow.data.set("approved", tableRow.data.get("approved") === "N" ? "Y" : "N");
        tableRow.data.post().then(() => this.tableDevices.redisplaySingleRow(tableRow.index, tableRow.data, false, false));
    }

    buttonToggleDeviceApprovalOnDataDisplay(event: DataDisplayEvent) {
        const tableRow = TableRow.getContainingTableRow(event.target as Component);
        const label = event.target as Label;

        if (tableRow.data.get("approved") === "N")
            label.setProps({ text: "Unblock This Device", color: "success" });
        else
            label.setProps({ text: "Block This Device", color: "warning" });
    }


    labelDeviceApprovedOnDataDisplay(event: DataDisplayEvent) {
        const label = event.target as Label;
        const tableRow = TableRow.getContainingTableRow(label);
        const thisDeviceId = localStorage.getItem("deviceId");
        if (tableRow.data.get("approved") === "N")
            label.setProps({ text: "Blocked", backgroundColor: "warning", color: "warning.reverse" });
        else if (tableRow.data.get("device_id") === thisDeviceId)
            label.setProps({ text: "This Device", backgroundColor: "success", color: "success.reverse" });
        else
            label.setProps({ text: "Approved", backgroundColor: "success", color: "success.reverse" });
    }

    /** This is an event handler for the onClick event of buttonDeleteDevice.  */
    buttonDeleteDeviceOnClick(event: ClickEvent) {
        const tableRow = TableRow.getContainingTableRow(event.target as Button);
        tableRow.deleteRow();
    }

}
