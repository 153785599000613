import { DOMUtil, LogManager, ModelRow, Size } from "@mcleod/core";
import { Component } from "../../base/Component";
import { ComponentPropDefinition } from "../../base/ComponentProps";
import { ComponentTypes } from "../../base/ComponentTypes";
import { ComponentUtil } from "../../base/ComponentUtil";
import { Container } from "../../base/Container";
import { ListenerListDef } from "../../base/ListenerListDef";
import { RepeatItemDisplayEvent, RepeatItemDisplayListener } from "../../events/RepeatItemDisplayEvent";
import { ComponentDeserializer, DeserializeProps } from "../../serializer/ComponentDeserializer";
import { serializeComponents } from "../../serializer/ComponentSerializer";
import { Panel } from "../panel/Panel";
import { PanelProps } from "../panel/PanelProps";
import { RepeatItem } from "./RepeatItem";
import { RepeaterPropDefinitions, RepeaterProps, RepeaterVariant } from "./RepeaterProps";
import { RepeaterStyles } from "./RepeaterStyles";
import { DesignerInterface } from "../../base/DesignerInterface";

const log = LogManager.getLogger("components.Repeater");

const _itemDisplayListenerDef: ListenerListDef = { listName: "_itemDisplayListeners" };

const designerListItemProps: Partial<PanelProps> = {
    fillRow: true,
    fillHeight: true,
    padding: 5,
    margin: 0
};

const styleMap = new Map<string, any>([
    [RepeaterVariant.horizontal, RepeaterStyles.horizontal],
    [RepeaterVariant.vertical, RepeaterStyles.vertical],
    [RepeaterVariant.grid, RepeaterStyles.grid]
]);

export class Repeater extends Container implements RepeaterProps {
    private _itemGap: string | number;
    private _variant: RepeaterVariant;
    private designerPanel: Panel;
    private _data: ModelRow<any>[];
    private placeHolderItem: RepeatItem;
    private repeatItemComponentsDef: any;
    private _placeholderSize: Size;
    private sizeCalculationPromise: Promise<void>;
    private observer: IntersectionObserver;

    constructor(props: Partial<RepeaterProps>) {
        super("div", props);
        this._variant ??= this.getPropertyDefinitions().variant?.defaultValue;
        this.itemGap ??= this.getPropertyDefinitions().itemGap?.defaultValue;
    }

    override get components(): Component[] {
        return this._components;
    }

    override set components(value: Component[]) {
        this._components?.forEach((comp, i) => this.removeAt(i));
        value?.forEach(comp => this.add(comp));
    }

    override add(...components: Component[]): Component {
        for (const comp of components) {
            if (comp instanceof RepeatItem) {
                this.components.push(comp);
                comp.repeater = this;
            } else if (this._designer == null) {
                throw new Error("Repeater can only contain RepeatItem components.");
            }
            comp.parent = this;
            this._element.appendChild(comp._element);
        }

        return components.length > 0 ? components[0] : undefined;
    }

    override removeAll() {
        this._components = [];
        this.reLayout();
    }

    override reLayout() {
        this._element.innerHTML = "";
        const newComponents = [...this.components];
        this._components = [];
        newComponents.forEach(comp => this.add(comp));
    }

    override removeAt(index: number) {
        if (index >= 0 && index < this.components.length) {
            const component = this.components[index];
            if (component instanceof Panel)
                component.dismissAllPopups();
            this.components.splice(index, 1);
            this.reLayout();
        }
    }

    get placeholderSize(): Size {
        return this._placeholderSize;
    }

    set placeholderSize(value: Size) {
        this._placeholderSize = value;
        this.syncGridTemplateColumns();
    }

    private syncGridTemplateColumns() {
        if (this.variant === RepeaterVariant.grid)
            this.style.gridTemplateColumns = `repeat(auto-fill, minmax(${this.placeholderSize?.width ?? 300}px, 1fr))`;
        else
            this.style.gridTemplateColumns = "";
    }

    get itemGap(): string | number {
        return this._itemGap;
    }

    set itemGap(value: string | number) {
        this._itemGap = value;
        this.style.gap = DOMUtil.getSizeSpecifier(value);
    }

    get variant(): RepeaterVariant {
        return this._variant;
    }

    set variant(value: RepeaterVariant) {
        if (this._variant === value)
            return;
        const oldValue = this._variant;
        this._variant = value;
        this.syncVariant(oldValue);
    }

    private syncVariant(oldValue?: RepeaterVariant) {
        if (this._designer != null) {
            this.syncVariantInDesigner();
            return;
        }

        if (oldValue != null)
            this._element.classList.remove(styleMap.get(oldValue));

        this._element.classList.add(styleMap.get(this.variant));
        this.syncGridTemplateColumns();
        this.reLayout();
    }

    private syncVariantInDesigner() {
        if (this.isDeserializing())
            return;
        this.syncFillRowAndHeight();
    }

    override displayComponentData(data: ModelRow, allData: ModelRow[], rowIndex: number) {
        super.displayComponentData(data, allData, rowIndex);
        this.data = allData;
    }

    shouldDisplayData(data: ModelRow, allData: ModelRow[], rowIndex: number): boolean {
        return this.dataSource == null || allData == this.dataSource.data
    }

    get data(): ModelRow[] {
        return this._data;
    }

    set data(value: ModelRow[]) {
        this.removeAll();
        this._data = value;
        this.displayAllItems(this._data);
    }

    private async displayAllItems(rows: ModelRow[]) {
        if (!rows || !this.repeatItemComponentsDef)
            return;

        await this.calculateItemPreferredSize(rows[0], true);

        for (const row of rows)
            await this.displayItem(row);
    }

    private async displayItem(modelRow: ModelRow<any>) {
        const props = {
            id: `${this.id}-${this.getComponentCount()}`,
            owner: this.owner,
            boundRow: modelRow,
            minHeight: this.placeholderSize?.height,
            minWidth: this.placeholderSize?.width,
            componentsDef: this.repeatItemComponentsDef,
        };

        const listItem = new RepeatItem(props);
        this.add(listItem);
        this.observeItem(listItem);
    }

    private observeItem(item: RepeatItem) {
        if (this._designer)
            return;

        if (this.observer == null) {
            this.observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const listItem = ComponentUtil.getComponentForElement(entry.target) as RepeatItem;
                        listItem?.populateDom();
                        this.observer.unobserve(entry.target);
                    }
                });
            });
        }

        this.observer.observe(item._element);
    }

    get _designer(): DesignerInterface {
        return super._designer;
    }

    set _designer(value: DesignerInterface) {
        super._designer = value;
        value?.addDesignerContainerProperties(this, null, null, null, false);
    }

    protected override _initialDropInDesigner(): void {
        this.createDesignerPanel();
        this.syncVariantInDesigner();
    }

    override getPropertyDefinitions() {
        return RepeaterPropDefinitions.getDefinitions();
    }

    _serializeNonProps() {
        const comps = this.designerPanel.components;
        return comps?.length > 0 ? `"repeatItemComponents": ${serializeComponents(comps, null)},\n` : "";
    }

    override async _deserializeSpecialProps(props: DeserializeProps): Promise<string[]> {
        const compSpecial = await super._deserializeSpecialProps({...props});
        if (this._designer != null) {
            await this.createDesignerPanel()._deserializeSpecialProps({
                ...props,
                def: { components: props.def.repeatItemComponents },
                defaultPropValues: {},
            });
        } else {
            this.repeatItemComponentsDef = props.def.repeatItemComponents;
        }

        return [...compSpecial, "components", "repeatItemComponents"];
    }

    override doAfterDeserialize() {
        this.syncFillRowAndHeight();
        if (this._designer == null) {
            this.syncVariant();
            this.calculateItemPreferredSize();
        }
    }

    private createDesignerPanel(): Panel {
        if (this.designerPanel != null)
            this.remove(this.designerPanel);

        this.designerPanel = new Panel({ ...designerListItemProps, _designer: this._designer });
        this.designerPanel.alternateDesignerTarget = this;
        this.designerPanel.borderWidth = 0;
        this.add(this.designerPanel);
        return this.designerPanel;
    }

    private async calculateItemPreferredSize(modelRow?: ModelRow<any>, forceCalc = false): Promise<void> {
        if (this.sizeCalculationPromise != null)
            await this.sizeCalculationPromise;

        this.sizeCalculationPromise = new Promise<void>(async (resolve) => {
            try {
                if (!forceCalc && (this.repeatItemComponentsDef == null || this.placeholderSize != null)) {
                    return resolve();
                }

                if (this.placeHolderItem == null) {
                    const dataSources = {};
                    dataSources[this.dataSource.id] = this.dataSource;
                    this.placeHolderItem = new RepeatItem();
                    this.placeHolderItem.components = await new ComponentDeserializer({
                        owner: this.owner,
                        def: this.repeatItemComponentsDef,
                        dataSources: dataSources,
                        designer: null
                    }).deserialize();
                }

                if (modelRow != null)
                    this.placeHolderItem?.displayData(modelRow, null, 0);

                this.placeholderSize = this.placeHolderItem.getPreferredSize();
                resolve();
            } catch (error) {
                log.error("Error calculating item preferred size", error);
                resolve();
            }
        });
    }

    fireItemDisplayListeners(creationEvent: RepeatItemDisplayEvent) {
        this.fireListeners(_itemDisplayListenerDef, creationEvent);
    }

    addItemDisplayListener(value: RepeatItemDisplayListener) {
        this.addEventListener(_itemDisplayListenerDef, value);
    }

    removeItemDisplayListener(value: RepeatItemDisplayListener) {
        this.removeEventListener(_itemDisplayListenerDef, value);
    }

    override get serializationName() {
        return "repeater";
    }

    override get properName(): string {
        return "Repeater";
    }

    protected override determinePropertyDefaultValue(prop: ComponentPropDefinition): any {
        if (prop.name == "fillRow")
            return this.shouldFillRow();
        if (prop.name == "fillHeight")
            return this.shouldFillHeight();
        return super.determinePropertyDefaultValue(prop);
    }

    private syncFillRowAndHeight() {
        this.fillRow = this.shouldFillRow();
        this.fillHeight = this.shouldFillHeight();
    }

    private shouldFillRow(): boolean {
        return this.variant !== RepeaterVariant.vertical;
    }

    private shouldFillHeight(): boolean {
        return this.variant !== RepeaterVariant.horizontal;
    }
}

ComponentTypes.registerComponentType("repeater", Repeater.prototype.constructor);
