import { ComponentPropDefinitionUtil, ComponentPropDefinitions } from "../../base/ComponentProps";
import { DataHeaderCloseAction } from "./DataHeaderCloseAction";
import { CrudDecoratorCloseListener } from "../../events/CrudDecoratorCloseEvent";
import { Layout } from "../layout/Layout";
import { ModelRow } from "@mcleod/core";
import { PanelPropDefinitions, PanelProps } from "../panel/PanelProps";
import { PropType } from "../../base/PropType";

export interface DataHeaderProps extends PanelProps {
    layout: Layout;
    title: string;
    showClose: boolean;
    showDelete: boolean;
    showExecute: boolean;
    showSave: boolean;
    showSaveAndClose: boolean;
    onExecute: (row: ModelRow<any>) => void;
    doAfterClose: CrudDecoratorCloseListener;
    doBeforeClose: CrudDecoratorCloseListener;
    navigateOnClose: (closeAction: DataHeaderCloseAction) => void;
    afterClosePath: string;
}

let dataHeaderPropDefs: ComponentPropDefinitions;

export class DataHeaderPropDefinitions {
    public static getDefinitions(): ComponentPropDefinitions {
        if (dataHeaderPropDefs == null) {
            dataHeaderPropDefs = {
                ...PanelPropDefinitions.cloneDefs(),
                afterClosePath: { type: PropType.string, description: "Specifies a destination to which the user should be navigated when they close a record outside of a listing screen (when the screen is not rendered in the standard slide-up fashion).  This value should be a relative path." },
                afterClose: { type: PropType.event, category: "Events", addListenerMethod: "addAfterCloseListener", removeListenerMethod: "removeAfterCloseListener", eventSignature: "AfterClose(event: CrudDecoratorCloseEvent)", description: "This event will be fired after a DataHeader fires its close action." },
                beforeClose: { type: PropType.event, category: "Events", addListenerMethod: "addBeforeCloseListener", removeListenerMethod: "removeBeforeCloseListener", eventSignature: "BeforeClose(event: CrudDecoratorCloseEvent)", description: "This event will be fired before a DataHeader performs its close action.  It allows for the programmatic cancellation of the close." },
                showClose: { type: PropType.bool, defaultValue: false, description: "Determines whether the default close button appears in the tools of this DataHeader." },
                showDelete: { type: PropType.bool, defaultValue: false, description: "Determines whether the delete button appears in the tools of this DataHeader." },
                showExecute: { type: PropType.bool, defaultValue: true, description: "Determines whether the search or save button (depending on the DataSource mode) appears in the tools of this DataHeader." },
                showSave: { type: PropType.bool, defaultValue: true, description: "Determines whether the save button includes the 'Save' option." },
                showSaveAndClose: { type: PropType.bool, defaultValue: false, description: "Determines whether the save button includes the 'Save and Close' option." },
                title: { type: PropType.string, description: "This specifies the text that will appear in the DataHeader.  If it is left blank, the title of the layout that the DataHeader is on will be used." },
            };
            dataHeaderPropDefs.fillRow.defaultValue = true;
            dataHeaderPropDefs.marginBottom.defaultValue = 12;
            dataHeaderPropDefs.padding.defaultValue = 0;
            ComponentPropDefinitionUtil.populateComponentPropNames(dataHeaderPropDefs);
        }
        return dataHeaderPropDefs;
    }
}
