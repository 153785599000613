import { getThemeColor, Styles } from "@mcleod/core";
import { CHAR_WIDTH, LINE_HEIGHT } from "./LogAnalyzerConstants";

export const LogAnalyzerStyles = Styles.make("log", () => {
    return {
        pause: { borderTop: "3px solid " + getThemeColor("warning") },
        logLine: { height: LINE_HEIGHT + "px", position: "absolute", width: "100%", whiteSpace: "pre" },
        tab: { marginRight: (CHAR_WIDTH * 4) + "px" },
        date: { color: getThemeColor("default.lighter"), fontWeight: 600 },
        thread: { color: getThemeColor("primary.light"), fontWeight: 600 },
        level: { color: getThemeColor("default.lighter") },
        source: { color: getThemeColor("success.light"), fontWeight: 600 },
        actionStart: { backgroundColor: getThemeColor("success.light"), color: getThemeColor("success.reverse") },
        actionEnd: { backgroundColor: getThemeColor("success.dark"), color: getThemeColor("success.reverse") },
        error: { backgroundColor: getThemeColor("error.lightest") + "33"},
        warning: { backgroundColor: getThemeColor("warning.lighter") },
        caution: { backgroundColor: getThemeColor("caution.lightest") },
        lineNumber: { backgroundColor: "#cecece", paddingLeft: "6px", paddingRight: "6px", marginRight: "4px" },
        selectedHighlight: { backgroundColor: getThemeColor("primary.lightest") + "aa" },
        searchResult: { position: "absolute", height: "16px", backgroundColor: "#fafa33" }
    }
});
