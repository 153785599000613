import { ArrayUtil } from "./ArrayUtil";
import { ModelRow, ModelRowType } from "./ModelRow";
import { ObjectUtil } from "./ObjectUtil";

export class LookupModelData {
    public static readonly LOOKUP_MODEL_PREFIX: string = "_lookup_";
    private _data: Map<string, ModelRow[]> = new Map();
    private _originalData: Map<string, ModelRow[]>;
    private modelRow: ModelRow;

    constructor(modelRow: ModelRow) {
        this.modelRow = modelRow;
    }

    get originalData(): Map<string, ModelRow[]> {
        return this._originalData;
    }

    get(field: string): ModelRow[] {
        return this._data?.get(field);
    }

    getFirst(field: string): ModelRow {
        return ArrayUtil.getFirstElement(this.get(field));
    }

    delete(field: string) {
        this.set(field, null);
    }

    isEmpty(): boolean {
        return this._data.size === 0;
    }

    clear(field?: string) {
        if (field != null) {
            this.delete(field);
        } else {
            this.resetOriginalDataIfNeeded();
            this._data.clear();
        }
    }

    syncValues(lmData: LookupModelData, clear?: boolean) {
        if (!ObjectUtil.isEmptyObject(lmData)){
            if (clear)
                this.clear();
            lmData._data.forEach((value, key) => this.set(key, value, false));
            lmData.getRemovedLookupModelData()?.forEach(field => this.delete(field));
        }
    }

    set(field: string, value: any, updateOringalData = true) {
        let lmArray = null;
        if (value != null) {
            const valuArray = ArrayUtil.getAsArray(value)
                .filter(rowOrData => typeof rowOrData === "object")
                .map(lmData => this.convertLookupModelData(field, lmData))
                .filter(modelRow => ObjectUtil.isEmptyObject(modelRow.data) === false);

            if (ArrayUtil.isEmptyArray(valuArray) === false)
                lmArray = valuArray;
        }

        if (updateOringalData)
            this.resetOriginalDataIfNeeded();
        if (lmArray == null)
            this._data.delete(field);
        else
            this._data.set(field, lmArray);
    }

    getRemovedLookupModelData(): string[] {
        return this.originalData ? Array.from(this._originalData.keys()).filter(field => !this._data.has(field)) : null;
    }

    revertToOriginalData(field?: string) {
        if (field == null) {
            this._data.clear();
            this._originalData?.forEach((value, key) => {
                this._data.set(key, value)
            });
        } else {
            this.set(field, this._originalData?.get(field));
        }
    }

    resetOriginalDataIfNeeded() {
        if (!this.modelRow?._appending && this._originalData == null)
            this.resetOriginalData();
    }

    resetOriginalData(field?: string) {
        if (this.isEmpty() || this.modelRow.isLookupModelDataRow()) {
            this._originalData = undefined;
            return;
        }

        if (field == null) {
            this._originalData = new Map();
            this._data.forEach((_, key) => this.copyDataToOriginalData(key));
        }
        else if (this._originalData != null) {
            this.copyDataToOriginalData(field);
        }
    }

    private copyDataToOriginalData(field: string) {
        const copy = this._data.get(field)?.map(lmData => lmData.createBasicCopy())
        if (copy != null)
            this._originalData.set(field, copy);
    }

    private convertLookupModelData(field: string, lmData: any): ModelRow {
        if (lmData instanceof ModelRow) {
            if (lmData._modelPath == null) {
                lmData._modelPath = this.getLookupModelPath(field);
                lmData.type = ModelRowType.LOOKUP_MODEL_DATA;
            }
            return lmData;
        }
        // If the data was not extracted from the server-side query result and added manually
        // in QueryModelEndpoint.doAfterDataPopulated then the lookupModel isn't in the metadata
        const lookupModel = this.getLookupModelPath(field);
        return new ModelRow(lookupModel, false, lmData).withLookupModelDataType();
    }

    getLookupModelPath(field: string): string {
        return this.modelRow.getMetadata()?.getFieldFromOutput(field)?.lookupModel;
    }

    createBasicCopy(): LookupModelData {
        const copy = new LookupModelData(this.modelRow);
        this._data.forEach((value, key) => {
            if (!ArrayUtil.isEmptyArray(value))
                copy._data.set(key, value.map(lmData => lmData.createBasicCopy(false)));
        });
        return copy;
    }
}
