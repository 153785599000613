import { StringUtil } from "@mcleod/core";
import { DialogProps, PanelProps } from "@mcleod/components";
import { MultiLineEditor } from "./MultiLineEditor";

export class StringArrayEditor extends MultiLineEditor {
    constructor(text: string, panelProps?: Partial<PanelProps>) {
        super(StringArrayEditor.cleanText(text), panelProps);
    }

    private static cleanText(text: string): string {
        let result: string = null;
        if (typeof text === "string")
            result = text.trim().split(",").join("\n");
        // else
        //     result = value.join("\n");
        return result;
    }

    public override getResultValue(): any {
        return StringUtil.isEmptyString(this.textbox.text) !== true ? this.textbox.text.split("\n") : null;
    }

    override getDialogProps(): Partial<DialogProps> {
        return {
            ...super.getDialogProps(),
            okDefault: false,
            title: "Multiple Value Entry",
            cursor: null
        }
    }
}
