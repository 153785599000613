import { Api, FileUtil, LogManager } from "@mcleod/core";
import {
    ChangeEvent, ClickEvent, DropdownItem, Event, FileRemovalEvent, FileUploadEvent, Toast
} from "@mcleod/components";
import { AutogenLayoutUploadImages } from "./autogen/AutogenLayoutUploadImages";

const log = LogManager.getLogger("common.imaging.UploadImages");

interface DocumentType {
    id: string,
    description: string
}

export class UploadImages extends AutogenLayoutUploadImages {
    private validFileExtensions: string[] = [".tiff", ".pdf", ".png", ".jpg", ".gif", ".bmp"];
    private _addedAnImage = false;

    override onLoad() {
        this.addLayoutDataLoadListener((event: Event) => {
            this.attachmentImage.validationCallback = (file: File): string => { return this.validateFileType(file) };
            this.textboxImageTypes.addChangeListener((event: ChangeEvent) => {
                this.enableSaveButtons(this.attachmentImage.fileBase64 && this.imageTypeIsValid());
            });
            this.enableSaveButtons(false);
        });
    }

    private enableSaveButtons(value: boolean) {
        const backgroundColor = value === true ? "primary" : "primary.lightest";
        const color = value === true ? "primary.reverse" : "subtle.light";
        this.buttonSave.enabled = value;
        this.buttonSave.backgroundColor = backgroundColor;
        this.buttonSave.color = color;
        this.buttonSaveAndClose.enabled = value;
        this.buttonSaveAndClose.backgroundColor = backgroundColor;
        this.buttonSaveAndClose.color = color;
    }

    private validateFileType(file: File): string {
        const fileExtension = FileUtil.getExtension(file);
        return this.validFileExtensions.includes(fileExtension) === false ? "The file must be an image." : null;
    }

    private imageTypeIsValid(): boolean {
        return this.textboxImageTypes.visible === false || this.textboxImageTypes.selectedItem != null;
    }

    setup(rowType: string, rowId: string, onSave?: (rowId: string) => void,
        onClose?: (rowId: string) => void) {
        this.addLayoutDataLoadListener((event: Event) => {
            this.loadDocumentTypes(rowType, rowId);
            this.setupButtons(rowType, rowId, onSave, onClose);
        });
    }

    private loadDocumentTypes(rowType: string, rowId: string) {
        const body = this.createJsonBody(rowType, rowId, false);
        Api.search("common/imaging/get-types", body).then(response => {
            log.debug(response);
            const documentTypes = response.data?.[0]?.["document_types"];
            if (documentTypes != null)
                this.createDropdownItems(documentTypes);
            else
                Toast.showToast("Error: Could not obtain list of valid image types", { backgroundColor: "error" });
        });
    }

    private setupButtons(rowType: string, rowId: string, onSave?: (rowId: string) => void,
        onClose?: (rowId: string) => void) {
        this.buttonCancel.addClickListener((event: ClickEvent) => {
            if (onClose != null)
                onClose(rowId);
        });
        const uploadImageUrl = "common/imaging/upload-image";
        this.buttonSave.addClickListener((event: ClickEvent) => {
            this.buttonSave.busy = true;
            const body = this.createJsonBody(rowType, rowId, true);
            Api.post(uploadImageUrl, body).then(response => {
                try {
                    log.debug(response);
                    if (this.actionSuccessful(response) === true) {
                        this._addedAnImage = true;
                        if (onSave != null)
                            onSave(rowId);
                        this.clearInputFields();
                        Toast.showToast("Your image has been queued for uploading. This process could take several minutes.");
                    }
                    else
                        Toast.showToast("Error: Could not create image file", { backgroundColor: "error" });
                }
                finally {
                    this.buttonSave.busy = false;
                }
            });
        });
        this.buttonSaveAndClose.addClickListener((event: ClickEvent) => {
            this.buttonSaveAndClose.busy = true;
            const body = this.createJsonBody(rowType, rowId, true);
            Api.post(uploadImageUrl, body).then(response => {
                try {
                    log.debug(response);
                    if (this.actionSuccessful(response) === true) {
                        this._addedAnImage = true;
                        if (onSave != null)
                            onSave(rowId);
                        if (onClose != null)
                            onClose(rowId);
                        Toast.showToast("Your image has been queued for uploading. This process could take several minutes.");
                    }
                    else
                        Toast.showToast("Error: Could not create image file", { backgroundColor: "error" });
                }
                finally {
                    this.buttonSaveAndClose.busy = false;
                }
            });
        });
    }

    private actionSuccessful(response: any): boolean {
        return response?.data[0]?.["success"] === true;
    }

    private createDropdownItems(documentTypes: DocumentType[]) {
        const items: DropdownItem[] = [];
        for (const type of documentTypes) {
            items.push(new DropdownItem(type.id, type.description));
        }
        this.textboxImageTypes.items = items;
    }

    private clearInputFields() {
        this.attachmentImage.removeAttachment();
        this.textboxImageTypes.text = null;
        this.textboxImageTypes.selectedItem = null;
    }

    private createJsonBody(rowType: string, rowId: string, submittingImage: boolean = true): string {
        const filter: any = {
            row_type: rowType,
            row_id: rowId
        }
        if (submittingImage) {
            filter.image = this.attachmentImage?.fileBase64;
            filter.image_type_id = this.textboxImageTypes?.selectedItem?.value;
        }
        return JSON.stringify([filter]);
    }

    /** This is an event handler for the onFileRemoval event of attachmentImage.  */
    attachmentImageOnFileRemoval(event: FileRemovalEvent) {
        this.enableSaveButtons(false);
    }

    /** This is an event handler for the onFileUpload event of attachmentImage.  */
    attachmentImageOnFileUpload(event: FileUploadEvent) {
        this.enableSaveButtons(this.imageTypeIsValid());
    }

    public get addedAnImage(): boolean {
        return this._addedAnImage;
    }
}
