import { getThemeColor, Styles } from "@mcleod/core";

export const LayoutStyles = Styles.make("lyt", () => {
    return {
        nestedDesignerOverlay: {
            width: "100%", height: "100%", position: "absolute", zIndex: 25, display: "flex",
            justifyContent: "center", flexDirection: "row", alignItems: "center",
            backgroundColor: "#5555000B", color: getThemeColor("primary") + "28"
        }
    }
});
