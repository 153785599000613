import { Component } from "../../base/Component";
import { ChartDataPoint, ChartFillType /*, ChartValue*/ } from "./Chart";
import { ChartDatasetProps } from "./ChartDatasetProps";

export class ChartDataset extends Component implements ChartDatasetProps {
    private _label: string;
    private _xAxisField: string;
    private _yAxisField: string;
    private _fillType: ChartFillType;
    private _smooth: boolean;
    private _axisId: string;
    public chartData: ChartDataPoint[]/*|ChartValue[]*/ = [];

    constructor(props?: Partial<ChartDatasetProps>) {
        super("div", props);
    }

    public get label(): string {
        return this._label;
    }

    public set label(value: string) {
        this._label = value;
    }

    public get xAxisField(): string {
        return this._xAxisField;
    }

    public set xAxisField(value: string) {
        this._xAxisField = value;
    }

    public get yAxisField(): string {
        return this._yAxisField;
    }

    public set yAxisField(value: string) {
        this._yAxisField = value;
    }

    public get fillType(): ChartFillType {
        return this._fillType;
    }

    public set fillType(value: ChartFillType) {
        this._fillType = value;
    }

    public get smooth(): boolean {
        return this._smooth == null ? false : this._smooth;
    }

    public set smooth(value: boolean) {
        this._smooth = value;
    }

    public get axisId(): string {
        return this._axisId;
    }

    public set axisId(value: string) {
        this._axisId = value;
    }
}
