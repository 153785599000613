import { Checkbox } from "../checkbox/Checkbox";
import { CheckboxProps } from "../checkbox/CheckboxProps";
import { PanelProps } from "../panel/PanelProps";
import { Textbox } from "../textbox/Textbox";
import { TextboxProps } from "../textbox/TextboxProps";
import { ExcludableTextbox } from "./ExcludableTextbox";

export class ExcludableTextboxFactory {
    create(panelProps: Partial<PanelProps>, textboxSource: Textbox|Partial<TextboxProps>,
        checkboxSource: Checkbox|Partial<CheckboxProps>) {
        const textbox = textboxSource instanceof Textbox ? textboxSource : new Textbox(textboxSource);
        const checkbox = checkboxSource instanceof Checkbox ? checkboxSource : new Checkbox(checkboxSource);
        return new ExcludableTextbox(panelProps, textbox, checkbox);
    }
}
