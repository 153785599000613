import { DesignerTabDescriptor } from "../ui/DesignerTabDescriptor";
import { ResourceDesignerTab } from "./ResourceDesignerTab";

export class LocalStorageManager {

    private openTabsKey: string;
    private selectedTabKey: string;

    constructor(openTabsKey: string, selectedTabKey: string) {
        this.openTabsKey = openTabsKey;
        this.selectedTabKey = selectedTabKey;
    }

    addLastOpenTab(tab: ResourceDesignerTab, setLastSelected: boolean = false) {
        if (tab?.path != null)
            this.addLastOpen(tab.descriptor, setLastSelected);
    }

    private addLastOpen(descriptor: DesignerTabDescriptor, setLastSelected: boolean = false) {
        const open = this.getLastOpen();
        if (!open.some(o => descriptor.equals(o))) {
            open.push(descriptor);
            localStorage.setItem(this.openTabsKey, JSON.stringify(open));
        }
        if (setLastSelected === true) {
            this.storeLastSelected(descriptor);
        }
    }

    getLastSelected(): DesignerTabDescriptor {
        try {
            const object = JSON.parse(localStorage.getItem(this.selectedTabKey));
            return DesignerTabDescriptor.createFromObject(object);
        } catch {
            localStorage.removeItem(this.selectedTabKey);
            return null;
        }
    }

    storeLastSelectedTab(tab: ResourceDesignerTab) {
        if (tab?.path != null)
            this.storeLastSelected(tab?.descriptor);
    }

    private storeLastSelected(descriptor: DesignerTabDescriptor) {
        if (descriptor?.path == null)
            localStorage.setItem(this.selectedTabKey, null);
        else
            localStorage.setItem(this.selectedTabKey, JSON.stringify(descriptor));
    }

    removeTabFromLastOpen(tab: ResourceDesignerTab) {
        this.removeFromLastOpen(tab?.descriptor)
    }

    removeFromLastOpen(descriptor: DesignerTabDescriptor) {
        if (descriptor?.path != null) {
            const lastOpen = this.getLastOpen().filter(o => o.equals(descriptor) === false);
            this.updateLastOpen(lastOpen);
        }
    }

    getLastOpen(): DesignerTabDescriptor[] {
        const open = localStorage.getItem(this.openTabsKey);
        if (open == null || open.length === 0)
            return [];
        else
            try {
                const result = [];
                const array: [] = JSON.parse(open);
                if (array != null) {
                    for (const object of array) {
                        result.push(DesignerTabDescriptor.createFromObject(object));
                    }
                }
                return result;
            } catch {
                localStorage.removeItem(this.openTabsKey);
                return [];
            }
    }

    updateTabInLastOpen(oldEntry: DesignerTabDescriptor, newEntry: DesignerTabDescriptor, setLastSelected: boolean = false) {
        this.removeFromLastOpen(oldEntry);
        this.addLastOpen(newEntry, setLastSelected);
    }

    private updateLastOpen(lastOpen: DesignerTabDescriptor[]) {
        localStorage.setItem(this.openTabsKey, JSON.stringify(lastOpen));
    }
}
