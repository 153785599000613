import { Button, ClickEvent } from "@mcleod/components";
import { Api, LogManager, Navigation, StringUtil } from "@mcleod/core";
import { Auth0 } from "./Auth0";
import { DecoratedUnauthPage } from "./DecoratedUnauthPage";

const log = LogManager.getLogger("auth.UserInvitation");

export class UserInvitation extends DecoratedUnauthPage {
    private invite: string;  // set from URL
    private buttonAcceptInvite: Button;

    constructor(props?: any) {
        super(props);
        this.buttonAcceptInvite = new Button({
            caption: "Accept Invitation",
            backgroundColor: "success",
            color: "success.reverse",
            fillRow: true,
            height: 40,
            fontSize: "large",
            imageName: "check",
            onClick: (event) => this.buttonAcceptInviteOnClick(event)
        });
        if (StringUtil.isEmptyString(this.invite)) {
            this.labelInstructions.text = "Invitation not specified.";
            return;
        }
        this.labelInstructions.text = "Loading invitation...";
        this.labelInstructions.busy = true;
        Api.search("accept-user-invite", { invite_id: this.invite }).then(response => {
            this.labelInstructions.busy = false;
            this.labelInstructions.text = response.data[0].accept_verbiage;
            this.panelContentLogin.add(this.buttonAcceptInvite);
        }).catch(() => {
            this.labelInstructions.text = "Error loading invitation.";
            this.labelInstructions.busy = false;
        });
    }

    async buttonAcceptInviteOnClick(event: ClickEvent) {
        const token = await Auth0.login();
        if (token != null) {
            this.buttonAcceptInvite.busy = true;
            try {
                const response = await Api.update("accept-user-invite", { invite_id: this.invite, token: token });
                log.info("Accept invite response %o", response);
                const data = response?.data?.[0];
                Navigation.navigateTo(data.home_url + "?_auth=" + data.token, { hardRefresh: true });
            } finally {
                this.buttonAcceptInvite.busy = false;
            }
        }
    }
}
