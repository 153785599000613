import { ArrayUtil } from "@mcleod/core";
import { DesignableObject } from "../base/DesignableObject";
import { Textbox } from "../components/textbox/Textbox";
import { DomEvent } from "./DomEvent";
import { Event } from "./Event";

export class TypedSelectionEvent<T> extends Event {
    private _selections: T[] = [];
    private _deselections: T[] = [];

    constructor(component: DesignableObject, selections?: T | T[], deselections?: T | T[], domEvent?: Event | DomEvent) {
        super(component, domEvent);
        ArrayUtil.addAllNoDuplicates(this._selections, selections);
        ArrayUtil.addAllNoDuplicates(this._deselections, deselections);
    }

    public override get target(): Textbox {
        return super.target as Textbox;
    }

    public get selection(): T {
        return ArrayUtil.getFirstElement(this._selections);
    }

    public get selections(): T[] {
        return this._selections;
    }

    public get deselection(): T {
        return ArrayUtil.getFirstElement(this._deselections);
    }

    public get deselections(): T[] {
        return this._deselections;
    }

    public addSelection(item: T) {
        this._selections.push(item);
    }

    public addDeselection(item: T) {
        this._deselections.push(item);
    }

    public hasSelection(): boolean {
        return ArrayUtil.isEmptyArray(this._selections) === false;
    }

    public hasDeselection(): boolean {
        return ArrayUtil.isEmptyArray(this._deselections) === false;
    }

    override validate(): boolean {
        return this.hasSelection() || this.hasDeselection();
    }

    get userInitiatedChange(): boolean {
        return this.domEvent != null;
    }

    get userInitiatedSelection(): boolean {
        return this.userInitiatedChange && this.hasSelection();
    }

    get userInitiatedDeselection(): boolean {
        return this.userInitiatedChange && this.hasDeselection();
    }
}
