import { ArrayUtil, DisplayValue, ModelRow, StringUtil, UrlUtil } from "@mcleod/core";
import { AutogenLayoutUILayoutUpgradeResults } from "./autogen/AutogenLayoutUILayoutUpgradeResults";
import { Image, Label, Panel, TableRow, TableRowDisplayEvent } from "@mcleod/components";
import { TableBaseProps } from "./custom/TableBaseProps";

export class UILayoutUpgradeResults extends AutogenLayoutUILayoutUpgradeResults {
    override onLoad(): void | Promise<void> {
        const layoutId = UrlUtil.getSearchParamFromUrl("id");
        if (StringUtil.isEmptyString(layoutId) !== true)
            this.mainDataSource.search({ id: layoutId }).then((response: any) => this.populateSummaryFields());
    }

    private populateSummaryFields() {
        const summaryRow = this.mainDataSource.summaryData;
        this.textboxLayoutDescr.text = summaryRow.get("descr");
        this.textboxCreateUser.text = summaryRow.get("create_user_id");
        const createDate: Date = summaryRow.get("create_datetime");
        this.textboxCreateDate.text = DisplayValue.getDisplayValue(createDate, this.textboxCreateDate.displayType)
        const updateDate: Date = summaryRow.get("create_datetime");
        this.textboxUpdateDate.text = DisplayValue.getDisplayValue(updateDate, this.textboxCreateDate.displayType)
    }

    /** This is an event handler for the onRowDisplay event of tableResults. */
    tableResultsOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        this.setImageSuccessful(tableRow);
        this.setLabelCustomOrBase(tableRow);
        this.addNotes(tableRow);
        this.setupBasePropsTable(tableRow);
    }

    private setImageSuccessful(tableRow: TableRow) {
        if (tableRow.data.get("successful") !== true) {
            const imageSuccessful = tableRow.findComponentById("imageSuccessful") as Image;
            if (imageSuccessful != null) {
                imageSuccessful.name = "circleX";
                imageSuccessful.color = "error";
            }
        }
    }

    private setLabelCustomOrBase(tableRow: TableRow) {
        const labelCustomOrBase = tableRow.findComponentById("labelCustomOrBase") as Label;
        if (labelCustomOrBase != null)
            labelCustomOrBase.text = (tableRow.data.get("component_is_custom") == true) ? "Custom" : "Base";
    }

    private addNotes(tableRow: TableRow) {
        const panelNotes = tableRow.findComponentById("panelNotes") as Panel;
        if (panelNotes == null)
            return;
        const notes: string[] = tableRow.data.get("notes");
        if (notes == null) {
            panelNotes.visible = false;
            return;
        }
        for (const note of notes) {
            const label = new Label({
                text: "- " + note,
                fillRow: true,
                wrap: true
            });
            panelNotes.add(label);
        }
    }

    private setupBasePropsTable(tableRow: TableRow) {
        if (StringUtil.isEmptyString(tableRow.data.get("base_version_props", null)) === true)
            return;
        const basePropsTable = new TableBaseProps({ marginTop: 6, marginBottom: 14 });
        this.addBasePropsTableRows(basePropsTable, tableRow.data);
        const lastCell = ArrayUtil.getLastElement(tableRow.cells);
        lastCell.add(basePropsTable);
    }

    private addBasePropsTableRows(basePropsTable: TableBaseProps, upgradeResultsRow: ModelRow) {
        const baseVersionProps = upgradeResultsRow.get("base_version_props", null) ?? {};
        const componentObject = upgradeResultsRow.get("component_object", null) ?? {};
        for (const key in baseVersionProps) {
            const data = new ModelRow(null, false, {
                prop_name: key,
                old_value: baseVersionProps[key],
                old_value_display: baseVersionProps[key],
                new_value: componentObject[key],
                new_value_display: componentObject[key]
            });
            basePropsTable.addRow(data);
        }
    }
}
