import { DataSourceExecutionEvent, DataSourceMode } from "@mcleod/components";
import { AutogenLayoutSendAccountInvitation } from "./autogen/AutogenLayoutSendAccountInvitation";

export class SendAccountInvitation extends AutogenLayoutSendAccountInvitation {
    afterInviteSent: () => void;

    onLoad() {
        this.sourceAccountInvite.mode = DataSourceMode.ADD;
    }

    /** This is an event handler for the afterExecution event of sourceAccountInvite.  */
    sourceAccountInviteAfterExecution(event: DataSourceExecutionEvent) {
        this.afterInviteSent?.()
    }
}