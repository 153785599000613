import {
    Alignment, ArrayUtil, Block, DOMUtil, DynamicLoader, FieldUpdateEvent, getThemeColor, getThemeForKey,
    Keys, ModelRow, ScrollOptions, VerticalAlignment
} from "@mcleod/core";
import { AddType } from "./AddType";
import { Button } from "../button/Button";
import { ButtonVariant } from "../button/ButtonVariant";
import { ClickEvent } from "../../events/ClickEvent";
import { CloneComponent } from "../../base/CloneComponent";
import { Component } from "../../base/Component";
import { DesignableObjectLogManager } from "../../logging/DesignableObjectLogManager";
import { ComponentProps } from "../../base/ComponentProps";
import { ComponentTypes } from "../../base/ComponentTypes";
import { Container } from "../../base/Container";
import { ComponentDeserializer, DeserializeProps } from "../../serializer/ComponentDeserializer";
import { DataSourceMode } from "../../databinding/DataSource";
import { DesignableObjectTempState } from "../../base/DesignableObjectTempState";
import { DomMouseEvent } from "../../events/DomEvent";
import { EditRowDecorator } from "../../page/decorators/EditRowDecorator";
import { Event } from "../../events/Event";
import { KeyEvent } from "../../events/KeyEvent";
import { KeyHandler } from "../../events/KeyHandler";
import { KeyHandlerGroup } from "../../events/KeyHandlerGroup";
import { Layout } from "../layout/Layout";
import { MouseEvent } from "../../events/MouseEvent";
import { Panel } from "../panel/Panel";
import { ReflectiveDialogs } from "../../base/ReflectiveDialogs";
import { RowModeControlType } from "./RowModeControlType";
import { SelectionMode, SelectionType } from "../../base/SelectionMode";
import { Table } from "./Table";
import { TableAction, TableContentsChangedEvent } from "../../events/TableContentsChangedEvent";
import { TableCell } from "./TableCell";
import { TableColumn } from "./TableColumn";
import { TableRowBeforeSaveEvent } from "../../events/TableRowBeforeSaveEvent";
import { TableRowCreationEvent } from "../../events/TableRowCreationEvent";
import { TableRowDisplayEvent } from "../../events/TableRowDisplayEvent";
import { TableRowMode } from "./TableRowMode";
import { TableRowModeChangeEvent } from "../../events/TableRowModeChangeEvent";
import { TableRowPropDefinitions, TableRowProps } from "./TableRowProps";
import { TableRowStyles } from "./TableRowStyles";
import { TableRowToolsCell } from "./TableRowToolsCell";
import { Textbox } from "../textbox/Textbox";
import { ValidationResult } from "../../base/ValidationResult";
import { ResizeListener } from "../../events/ResizeEvent";
import { PropsAccessLabelCreator } from "../../PropsAccessLabelCreator";

const log = DesignableObjectLogManager.getLogger("components.table.TableRow");

export class TableRow extends Component implements TableRowProps {
    public table: Table;
    private _populatedDOM: boolean;
    private _expanded: boolean;
    private _cells: TableCell[];
    private _placeholderHeight: number;
    private _expanderButton: Button;
    private _placeholder: HTMLTableCellElement;
    private _expandable: boolean;
    private _data: any;
    private _originalData: any;
    private _index: number;
    private _selected: boolean;
    private expanderAlignment: Alignment;
    private expanderPanel: Panel;
    private _mode: TableRowMode;
    private _canBeEdited: boolean;
    private _canBeDeleted: boolean;
    private _toolsCell: TableRowToolsCell;
    private _expanderCellIndex: number = -1;
    public virtualized: boolean;
    private _unexpandedBorderBottomWidth: string;
    private _dragSession: DragSession;
    private lastSelClick: number;
    private _keyHandlerGroup: KeyHandlerGroup;
    private _defaultState: DesignableObjectTempState;
    //The preselectionState variable contains the state of the row before any selected row coloring has been applied.
    //Its value would reflect any even/odd row coloring that has been applied.
    private preselectionState: DesignableObjectTempState;
    //The preselectionExpandCompState variable contains the state of the expansion component before any selected row
    //coloring has been applied.  Its value would reflect any even/odd row coloring that has been applied.
    private preselectionExpandCompState: DesignableObjectTempState;
    private hiddenCells: TableCell[];
    private _selectColor: string;
    private _populatePromise: Promise<void> | null = null;
    private _expandPromise: Promise<Component | null> = Promise.resolve(null);
    private _expansionComponent: Component;
    private expansionResizeListenerRef: ResizeListener = () => this.resizeExpansionComponent();

    constructor(table: Table, props: Partial<TableRowProps>) {
        super("div", props);
        this.table = table;
        this.populatedDOM = false;
        this._expanded = false;
        this._cells = [];
        this._element.classList.add(TableRowStyles.base);
        this.addClickListener(event => this.rowClicked(event), true);
        if (props.virtualized !== false)
            this.addVirtualizedPlaceholder();
        (this._element as any).__row = this; // give a reference to the row in its element so we can easily access it from the observer
        this.canBeEdited = this.table.allowEdit;
        this.canBeDeleted = this.table.allowEdit;
        if (this.table.selectionMode !== SelectionMode.NONE || this.table.addType === AddType.QUICK)
            this._element.classList.add(TableRowStyles.hoverShade);
        this._mode = TableRowMode.NONE;
        this.setProps(props);
    }

    override setProps(props: Partial<TableRowProps>) {
        super.setProps(props);
    }

    public get data(): any {
        return this._data;
    }

    public set data(value: any) {
        if (this._data != null && this._data !== value && this.populatedDOM) {
            this.forEveryChildComponent((comp: Component) => {
                if (comp.boundRow === this._data) {
                    comp.boundRow = value;
                }
            });
        }
        this._data = value;
    }

    get placeholderHeight(): number {
        return this._placeholderHeight;
    }

    set placeholderHeight(value: number) {
        this._placeholderHeight = value;
        if (this._placeholder != null) {
            if (value == null)
                this._placeholder.style.height = "";
            else
                this._placeholder.style.height = value + "px";
        }
    }

    get expanded(): boolean {
        return this._expanded;
    }

    set expanded(value: boolean) {
        this.setExpandedAsync(value);
    }

    public async setExpandedAsync(value: boolean): Promise<Component | null> {
        if (value == null)
            value = false;

        if (this._expanded === value) {
            return this._expandPromise;
        }

        this._expanded = value;
        await this.syncExpanded();

        if (value) {
            await this.showExpansionRow();
            return this._expansionComponent;
        } else {
            await this.hideExpansionRow();
            return null;
        }
    }

    private showExpansionRow(): Promise<Component> {
        if (this._expanded && this._expansionComponent) {
            return Promise.resolve(this._expansionComponent);
        }

        this.expanderButton?.setProps({ busy: true });
        this._expandPromise = this._expandPromise.then(async () => {
            const comp = await this.table._createExpansionComponent(this);
            this._setExpansionComponent(comp);
            return comp;
        }).finally(() => {
            this.expanderButton?.setProps({ busy: false });
        });
        return this._expandPromise;
    }

    private async hideExpansionRow(): Promise<void> {
        this._expanded = false;
        this._expandPromise = this._expandPromise.then(async () => {
            this.table._removeExpansionRowElement(this);
            return null;
        });
    }

    private async syncExpanded(): Promise<void> {
        await this.populateDOMIfNeeded();
        if (this.expanderButton != null) {
            if (this.expanderAlignment === Alignment.LEFT)
                this.expanderButton.caption = this.expanded ? "-" : "+";
            else
                this.expanderButton.imageRotation = this.expanded ? 0 : 90;
        }
    }

    public getExpansionComponent(): Component {
        return this._expansionComponent;
    }

    _setExpansionComponent(value: Component) {
        if (value != null)
            value.id += "-" + this.index;
        this._expansionComponent = value;
        this.addResizeListener(this.expansionResizeListenerRef);
        this.setPreselectionExpandCompState(value);
        if (this.selected) {
            this.applySelectColor(value);
        } else if (value) {
            value.backgroundColor ??= this.backgroundColor;
        }
    }

    private resizeExpansionComponent() {
        const expansionRow = this.getExpansionElement();
        if (expansionRow != null)
            expansionRow.style.width = DOMUtil.getElementWidthString(this._element);
    }

    public async getExpansionComponentLoaded(): Promise<Component | null> {
        if (this._expansionComponent) {
            return this._expansionComponent;
        }
        await this._expandPromise;
        return this._expansionComponent;
    }

    get expandable(): boolean {
        if (this._expandable === false)
            return false;
        return this.expandComponentDefined();
    }

    set expandable(value: boolean) {
        this._expandable = value;
        this.setExpanderButtonVisibility();
    }

    private expandComponentDefined(): boolean {
        //TableRow.expandComponent is only used in PropertiesTable, get rid of that someday
        return this.table.expandComponent != null || this["expandComponent"] != null;
    }

    addVirtualizedPlaceholder() {
        this._placeholder = document.createElement("td");
        this._element.appendChild(this._placeholder);
    }

    addCell(cell: TableCell) {
        this.setCellPropsFromRow(cell);
        this._cells.push(cell);
    }

    private setCellPropsFromRow(cell: TableCell) {
        cell.table = this.table;
        cell.row = this;
        cell._designer = this._designer;
    }

    get cells(): TableCell[] {
        return this._cells;
    }

    async populateDOM(): Promise<void> {
        if (this._populatePromise == null) {
            this._populatePromise = this.internalPopulateDOM();
        }
        return this._populatePromise;
    }

    private async internalPopulateDOM() {
        delete this._placeholder;
        this._element.innerHTML = "";
        const creationEvent = new TableRowCreationEvent(this, this.table);
        this.table.fireRowCreationListeners(creationEvent);
        if (this.table.expanderAlignment === Alignment.LEFT && this.expandComponentDefined() === true)
            this.addExpanderCell();
        if (this.table._designer == null &&
            this.table.displayToolsCell === true &&
            this.table.toolsCellAlignment === Alignment.LEFT) {
            this.addToolsCell();
        }
        for (let i = 0; i < this.table._columns.length; i++)
            await this.populateDOMCell(this.table._columns[i]);
        if (this.table.expanderAlignment !== Alignment.LEFT && this.expandComponentDefined() === true)
            this.addExpanderCell();
        if (this.table._designer == null &&
            this.table.displayToolsCell === true &&
            this.table.toolsCellAlignment !== Alignment.LEFT) {
            this.addToolsCell();
        }
        this.setDefaultFirstLastCellPadding();
        if (this.table._designer != null && this.table._designer.allowsDrop) {
            const designerGutter = document.createElement("td");
            designerGutter.id = "designerGutter";
            designerGutter.style.width = "24px";
            designerGutter.style.verticalAlign = "bottom";
            this._element.appendChild(designerGutter);
            const rowPropsLabel = PropsAccessLabelCreator.create({
                fontSize: 8,
                marginLeft: -3,
                marginBottom: 19,
                wrap: false,
                _designer: this.table._designer,
                style: { transform: "rotate(-90deg)" },
                verticalAlign: VerticalAlignment.CENTER,
                tooltip: "Click to access the properties for the table row"
            });
            rowPropsLabel.alternateDesignerTarget = this;
            designerGutter.appendChild(rowPropsLabel._element);
        }
        this.populatedDOM = true;
        this._updateCellsBasedOnSelection();
        const displayEvent = new TableRowDisplayEvent(this, this.table);
        this.table.fireRowDisplayListeners(displayEvent);
        //This seems redundant, but this set of the last scroll left is necessary to make the scroll set work when
        //displaying data.  I think it's because the table body doesn't have any rendered rows (that have populated
        //their DOM elements) when displayData() finishes.
        this.table.applyLastScrollLeft();
    }

    /**
     * Calls methods to default the first/last cell in the row to have the default paddingLeft/paddingRight values
     */
    private setDefaultFirstLastCellPadding() {
        const firstCell: TableCell = ArrayUtil.getFirstElement(this.cells);
        firstCell?.applyAutoFirstColumnPadding();
        const lastCell: TableCell = ArrayUtil.getLastElement(this.cells);
        lastCell.applyAutoLastColumnPadding();
    }

    /**
     * Resets default padding for all cells in the row.  This is used when columns have been moved/swapped or removed.
     */
    resetFirstLastColumnPadding() {
        const lastCellIndex = this.cells.length - 1;
        for (let x = 0; x < this.cells.length; x++) {
            const cell = this.cells[x];
            cell.removeAutoColumnPadding();
            if (x === 0)
                cell.applyAutoFirstColumnPadding();
            else if (x === lastCellIndex)
                cell.applyAutoLastColumnPadding();
        }
    }

    private addToolsCell(): void {
        this.createToolsCell();
        if (this.toolsCell != null) {
            this.addCell(this.toolsCell);
            this._element.appendChild(this.toolsCell._element);
        }
    }

    private addExpanderCell(): void {
        const expanderCell = this.getExpanderCell();
        if (expanderCell != null) {
            this._element.appendChild(expanderCell._element);
        }
    }

    clear() {
        this._cells = [];
        this._element.innerHTML = "";
        this.populatedDOM = false;
    }

    columnsChanged() {
        if (this.populatedDOM === true) {
            this._cells = [];
            this._element.innerHTML = "";
            this.populateDOM();
        }
    }

    async populateDOMIfNeeded() {
        if (this.populatedDOM === true)
            return;
        await this.populateDOM();
    }

    async createCell(col: TableColumn): Promise<TableCell> {
        let cell: TableCell;
        if (col.cellDef != null) {
            const def = col.cellDef;
            const id = col.cellDef?.def?.id || this.table.id + "-" + this._index + "-" + col.index;
            const props = {
                id: id,
                ...def.cellProps,
                table: this.table,
                row: this,
                col: col,
                boundRow: this.data
            };
            const deserializeProps: DeserializeProps = {
                owner: def.owner,
                def: def.def,
                designer: this._designer,
                defaultPropValues: props
            };
            //do not pass dataSources to deserialization; we don't want components in the row to be bound directly to the DataSource
            cell = await new ComponentDeserializer(deserializeProps).deserializeSingleComponent() as TableCell;
        } else {
            cell = new TableCell({ col: col, boundRow: this.data });
            if (typeof col.cell === "function") {
                const props = col.cell(this, cell);
                if (Array.isArray(props)) { //props is an array of components
                    cell.add(...props);
                    // establishOwnership(props, this);
                } else if (props._element != null) { //props is a single component
                    cell.add(props);
                } else {
                    cell.setProps(props);
                    if (props.id != null)
                        this[props.id] = cell;
                }
            } else {
                if (col.cell instanceof Component) {
                    CloneComponent.clone({
                        component: col.cell,
                        targetComponent: cell,
                        appendComponentId: this._designer == null
                    });
                    if (col.cell.id != null)
                        this[col.cell.id] = col.cell;
                } else {
                    cell.setProps(col.cell);
                    if (col.cell.id != null)
                        this[col.cell.id] = cell;
                }
            }

            await cell.ensureLoaded();
        }
        if (this.table._designer == null) {
            cell.displayData(this.data, this.table.data, this._index);
            if (this.table.printableToggleEnabled && cell.printableToggleEnabled)
                cell.printable = this.rowIsPrintable();
        }
        return cell;
    }

    async populateDOMCell(col: TableColumn) {
        const cell = await this.createCell(col);
        this.addCell(cell);
        this._element.appendChild(cell._element);
    }

    removeColumn(index: number) {
        if (this.populatedDOM === true) {
            const cell = this.cells[index];
            this._element.removeChild(cell._element);
            this.cells.splice(index, 1);
        }
    }

    moveColumn(oldIndex: number, newIndex: number) {
        if (oldIndex === newIndex || oldIndex == null || newIndex == null)
            return;
        if (this.populatedDOM === true) {
            ArrayUtil.moveArrayElement(this.cells, oldIndex, newIndex);
            if (newIndex < oldIndex)
                DOMUtil.moveDOMElementBefore(this._element.childNodes[oldIndex], this._element.childNodes[newIndex]);
            else
                DOMUtil.moveDOMElementAfter(this._element.childNodes[oldIndex], this._element.childNodes[newIndex]);
        }
    }

    switchColumns(index1: number, index2: number) {
        if (index1 === index2 || index1 == null || index2 == null)
            return;
        if (this.populatedDOM === true) {
            ArrayUtil.switchArrayElements(this.cells, index1, index2);
            DOMUtil.switchDOMElements(this._element.childNodes[index1], this._element.childNodes[index2]);
        }
    }

    get mode(): TableRowMode {
        return this._mode;
    }

    set mode(value: TableRowMode) {
        if (value !== this._mode) {
            const oldMode = this._mode;
            this._mode = value;

            if (this._mode !== TableRowMode.SEARCH) {
                this.toolsCell?.fill();
                this.setCellsPrintable(this.rowIsPrintable())
            } else {
                this.setCellsPrintable(false);
            }
            const modeChangeEvent = new TableRowModeChangeEvent(this, this.table, oldMode, this._mode);
            this.table.fireRowModeChangeListeners(modeChangeEvent);
        }
    }

    private setCellsPrintable(printable: boolean) {
        if (this.table.printableToggleEnabled) {
            for (const cell of this.cells) {
                if (cell.printableToggleEnabled) {
                    cell.printable = printable;
                }
            }
        }
    }

    rowBeingEdited(): boolean {
        return this._mode === TableRowMode.ADD || this._mode === TableRowMode.UPDATE;
    }

    rowIsPrintable(): boolean {
        return !this.table.printableToggleEnabled || this._mode === TableRowMode.NONE;
    }

    public enableAllComponents(value: boolean) {
        this.cells.forEach(cell => cell.setPropOnChildren("enabled", value, false));
    }

    public get toolsCell(): TableRowToolsCell {
        return this._toolsCell;
    }

    private createToolsCell() {
        if (this.canBeEdited !== true && this.canBeDeleted !== true)
            return null;
        //avoid circular dependency...cannot use TableRowToolsCell before TableCell
        const cls = DynamicLoader.getModuleByName("components/components/table/TableRowToolsCell");
        this._toolsCell = cls.TableRowToolsCell.create(this);
        this.setCellPropsFromRow(this.toolsCell); //need cell variables to be available when we call fill() below
        this.table._applyCellProps(this.toolsCell);
        this.toolsCell.fill();
    }

    /**
     * Begins the drag-to-resequence action (when the drag handler image is clicked)
     *
     * @param event: the MouseEvent from the onmousedown action
     */
    _dragHandlerMouseDownListener(event: MouseEvent) {
        log.debug("Start row drag, moving row at index %o", this._index);
        event.preventDefault();
        this._createDragSession(event);
        // log.debug("Current row top: %o, next move up at %o, next move down at %o", this._element.getBoundingClientRect().top, this._dragSession.nextMoveUpAt, this._dragSession.nextMoveDownAt);
        this._element.style.zIndex = (Number.parseInt(this._dragSession.previousZIndex) + 1) + "";
        this._element.draggable = true;
        this.table.setTableBodyOnMouseMove(event => this._dragHandlerMouseMoveListener(event));
        document.onmouseup = (event => this._dragHandlerMouseUpListener(event));
    }

    /**
     * Creates a DragSession object to hold various values that are useful during the drag-to-resequence action
     *
     * @param event: the MouseEvent from the onmousedown action
     */
    private _createDragSession(event: MouseEvent) {
        this._dragSession = new DragSession();
        this._dragSession.oldRowIndex = this.index;
        this._dragSession.newRowIndex = this.index;
        this._dragSession.rowHeight = DOMUtil.getElementHeight(this._element);
        this._dragSession.initialRowY = this._element.getBoundingClientRect().top;
        this._dragSession.nextMoveUpAt = this._element.getBoundingClientRect().top - (this._dragSession.rowHeight / 2);
        this._dragSession.nextMoveDownAt = this._element.getBoundingClientRect().bottom + (this._dragSession.rowHeight / 2);
        this._dragSession.previousZIndex = this._element.style.zIndex;
        this._dragSession.dragStartY = event.domEvent.clientY;
    }

    /**
     * During the drag-to-resequence action:
     *    - move the row to its new position (by setting its top property relative to its starting position)
     *    - if the row being dragged moves high/low enough to displace another row, set the top property on that other row
     *    - keep track of:
     *         - how high/low the row must go before it displaces another row
     *         - the new index of the row being dragged
     *
     * @param event: the MouseEvent from the onmousemove action
     */
    private _dragHandlerMouseMoveListener(event) {
        event.preventDefault();
        // log.debug(this, "Drag mouse move dragStartY: %o, event.clientY: %o", this._dragStartY, event.clientY);
        const topValue = event.clientY - this._dragSession.dragStartY;
        // log.debug(this, "New top value for row: %o, clientY: %o, dragStartY: %o", topValue, event.clientY, this._dragStartY);
        if ((topValue + this._dragSession.initialRowY) < this._dragSession.nextMoveUpAt) {
            this._dragSession.newRowIndex--;
            const otherRowToMove = this._getRowContainingY(this._dragSession.nextMoveUpAt);
            if (otherRowToMove != null) {
                const otherRowTop = DOMUtil.getStyleAttrAsNumber(DOMUtil.getComputedStyle("top", otherRowToMove));
                otherRowToMove.style.top = (otherRowTop + this._dragSession.rowHeight) + "px";
            }
            log.debug(this, "Row moved up, new row index: %o", this._dragSession.newRowIndex);
            this._dragSession.nextMoveUpAt -= this._dragSession.rowHeight;
            this._dragSession.nextMoveDownAt -= this._dragSession.rowHeight
            // log.debug("Current row top: %o, next move up at %o, next move down at %o", this._element.getBoundingClientRect().top, this._nextMoveUpAt, this._nextMoveDownAt);
        } else if ((topValue + this._dragSession.rowHeight + this._dragSession.initialRowY) > this._dragSession.nextMoveDownAt) {
            this._dragSession.newRowIndex++
            const otherRowToMove = this._getRowContainingY(this._dragSession.nextMoveDownAt);
            if (otherRowToMove != null) {
                const otherRowTop = DOMUtil.getStyleAttrAsNumber(DOMUtil.getComputedStyle("top", otherRowToMove));
                otherRowToMove.style.top = (otherRowTop - this._dragSession.rowHeight) + "px";
            }
            log.debug(this, "Row moved down, new row index: %o", this._dragSession.newRowIndex);
            this._dragSession.nextMoveUpAt += this._dragSession.rowHeight;
            this._dragSession.nextMoveDownAt += this._dragSession.rowHeight
            // log.debug(this, "Current row top: %o, next move up at %o, next move down at %o", this._element.getBoundingClientRect().top, this._nextMoveUpAt, this._nextMoveDownAt);
        }
        this._element.style.top = topValue + "px";
        // log.debug(this, "Drag mouse move new top value: %o", this._element.style.top);
    }

    /**
     * Find whatever row in the table contains the specified y position.  This helps to identify a row being displaced, so it can be moved.
     *
     * @param y: the y position value at which to look for a row
     */
    private _getRowContainingY(y: number): HTMLElement {
        for (const r of this.table.rows) {
            if (r._element === this._element)
                continue;
            const rect = r._element.getBoundingClientRect();
            if (rect.top < y && rect.bottom > y)
                return r._element;
        }
        return null;
    }

    /**
     * Ends the drag-to-resequence action (when the mouse button is released).  Removes temporary settings/values, including the DragSession object.
     *
     * @param event: the MouseEvent from the onmouseup action
     */
    private _dragHandlerMouseUpListener(event) {
        log.debug(this, "End row drag")
        this._element.style.zIndex = this._dragSession.previousZIndex;
        this._element.draggable = false;
        this.table.moveRow(this, this._dragSession.newRowIndex, this._dragSession);
        this._dragSession = null;
        this.table.setTableBodyOnMouseMove(null);
        document.onmouseup = null;
    }

    get canBeEdited(): boolean {
        return this._canBeEdited;
    }

    set canBeEdited(value: boolean) {
        this._canBeEdited = value;
    }

    get canBeDeleted(): boolean {
        return this._canBeDeleted
    }

    set canBeDeleted(value: boolean) {
        if (value !== this._canBeDeleted) {
            this._canBeDeleted = value;
            this.toolsCell?.fill();
        }
    }

    editRow() {
        if (this.canBeEdited === false) {
            return;
        }
        if (this.table.addType === AddType.QUICK)
            this.selected = true;
        if (this.mode === TableRowMode.QUICK_ADD || this.mode === TableRowMode.SEARCH) {
            return;
        }
        this.mode = TableRowMode.UPDATE;
        if (this.data instanceof ModelRow) {
            this._originalData = this.data.createBasicCopy();
        } else {
            this._originalData = { ...this.data };
        }

        this.toolsCell?.hideOverlay();
    }

    cancelEdit() {
        if (this.mode === TableRowMode.UPDATE) {
            this.data = this._originalData.createBasicCopy();
            this._originalData = null;
            this.displayComponentValues();
            this.mode = TableRowMode.NONE;
        } else if (this.mode === TableRowMode.ADD) {
            if (this.table.data?.[this.index] === this.data)
                this.deleteRow();
            else
                this.table.removeRow(this.index);
        }
    }

    showEditLayout(addingRowInSlideout: boolean = false) {
        if (this.mode === TableRowMode.ADD || this.mode === TableRowMode.UPDATE) {
            const changesValid = this.saveChanges();
            if (changesValid !== true)
                return;
        }

        const erd = new EditRowDecorator({
            layout: Layout.getLayout(this.table.editLayout),
            layoutLoadListeners: this.table.onEditLayoutLoaded != null ? (event: Event) => this.table.onEditLayoutLoaded(erd, this) : null,
            width: this.table.editLayoutWidth,
            overlayProps: { closeOnClickOff: false, greyedBackground: true },
            data: this._copyDataForSlideout(),
            dataSourceMode: this.mode === TableRowMode.SEARCH ? DataSourceMode.SEARCH : DataSourceMode.UPDATE,
            onDelete: this.mode !== TableRowMode.QUICK_ADD ? () => {
                this.deleteRow(() => erd.slideOut());
            } : null,
            onSave: (updatedData: ModelRow | any) => this.saveChangesFromEdit(updatedData),
            onAddAnother: () => this.editClearNewRow(erd),
            onClose: (cancelled: boolean) => this.onSlideoutClose(erd, cancelled, addingRowInSlideout)
        });
    }

    private _copyDataForSlideout(): ModelRow | any {
        if (this.data instanceof ModelRow)
            return this.data.createBasicCopy();
        return this.data;
    }

    public persistQuickAddRow() {
        if (this.mode !== TableRowMode.QUICK_ADD)
            return;
        this.saveChanges();
        this.updateDataFromComponents();
        this.table.saveQuickAddData();
    }

    public displayComponentValues(): void {
        for (const cell of this.cells)
            if (cell.components != null)
                for (const comp of cell.components)
                    comp.displayData(this.data, this.table.data, this.index);
    }

    public hasChanges(): boolean {
        return this._originalData != null;
    }

    saveChanges(afterSave?: () => any): boolean {
        if (this.table.addType === AddType.QUICK)
            this.selected = false;
        this.updateDataFromComponents();
        const event = new TableRowBeforeSaveEvent(this, this.table, this.table.getParentDataSource());
        this.table.fireRowBeforeSaveListeners(event);
        if (this.validateSimple(true) !== true) {
            return false;
        }
        if (this.data instanceof ModelRow && this.table.persistChangesImmediately) {
            const rowToSave = this.data as ModelRow;
            rowToSave.post().then(() => {
                if (afterSave) afterSave();
            });
        }
        this._originalData = null;

        if (this.mode !== TableRowMode.QUICK_ADD)
            this.table.fireContentsChangedListener(new TableContentsChangedEvent(this.table, TableAction.UPDATE));

        if (this.table.rowModeControlType !== RowModeControlType.ALWAYS_EDIT)
            this.mode = TableRowMode.NONE;
        return true;
    }

    editClearNewRow(erd: EditRowDecorator) {
        this.table._createNewRowData().then(newRowData => {
            const addRowResult = this.table.addRow(newRowData, { mode: TableRowMode.ADD }, {
                display: false,
                addToData: true
            });
            addRowResult.row.showEditLayout(true);
            this.onSlideoutClose(erd, false, true);
        });
    }

    saveChangesFromEdit(updatedData: ModelRow | any) {
        if (this.mode === TableRowMode.SEARCH) {
            this.data.setValuesAndLookupModelData(updatedData);
            this.displayComponentValues();
        } else if (this.mode === TableRowMode.QUICK_ADD) {
            this.data.setValuesAndLookupModelData(updatedData);
            const addRowResult = this.table.addRow(this.data, { mode: TableRowMode.ADD }, {
                display: true,
                addToData: true,
                save: false
            }); //save will happen afterward
            if (addRowResult.row.saveChanges()) {
                this.table._removeQuickAddRow();
                this.table._addQuickAddRow();
            }
        } else {
            this.data.setValuesAndLookupModelData(updatedData);
            this.saveChanges();
            if (this.mode === TableRowMode.ADD)
                this.table.displayRow(this);
        }
        this.table.resetFilter();
    }

    onSlideoutClose(erd: EditRowDecorator, cancelled: boolean, addingRowInSlideout: boolean) {
        const slideout = () => erd.slideOut();
        if (cancelled === true && addingRowInSlideout === true)
            this.deleteRow(slideout);
        else
            slideout();
    }

    public updateDataFromComponents(): void {
        for (const cell of this.cells)
            if (cell.components != null)
                for (const comp of cell.components)
                    comp.updateBoundData(this.data, DataSourceMode.UPDATE);
    }

    private updateDataToComponents(addedRow: TableRow): void {
        let cells: TableCell[];
        let data: any;
        if (addedRow != null) {
            cells = addedRow.cells;
            data = addedRow.data;
        } else {
            cells = this.cells;
            data = this.data;
        }

        for (const cell of cells)
            if (cell.components != null)
                for (const comp of cell.components)
                    comp.displayData(data, data, 0);
    }

    deleteRow(afterDelete?: () => {}) {
        const event = new TableContentsChangedEvent(this.table, TableAction.DELETE);
        if (this.table.dataSource?.parentDataSource != null && !this.table.persistChangesImmediately) {
            this.toolsCell?.hideOverlay();
            this.table.deleteRow(this.index);
            this.table.fireContentsChangedListener(event);
            if (afterDelete) afterDelete();
        } else {
            ReflectiveDialogs.showYesNo("Are you sure you want to delete this record?", "Confirm Deletion").then(clickedYes => {
                if (clickedYes) {
                    this.toolsCell?.hideOverlay();
                    const modelRow: ModelRow = this.data;
                    this.table.deleteRow(this.index);
                    if (this.table.persistChangesImmediately)
                        modelRow.delete().then(() => this.table.fireContentsChangedListener(event));
                    if (afterDelete)
                        afterDelete();
                }
            });
        }
    }

    private getExpanderCell(): TableCell {
        const cell = new TableCell({ id: "expander" + this._index });
        this.setCellPropsFromRow(cell);
        this.table._applyCellProps(cell);
        cell.width = "40px";
        cell.verticalAlign = VerticalAlignment.CENTER;
        this.cells.push(cell);
        this._expanderCellIndex = this.cells.length - 1;
        this.expanderPanel = new Panel({ padding: 0 });
        this._expanderButton = new Button({
            variant: ButtonVariant.round,
            margin: 0,
            focusable: false,
            themeKey: this.expanderAlignment === Alignment.LEFT ? "tableRow.expanderButtonLeft" : "tableRow.expanderButtonRight"
        });
        this.expanderButton.addClickListener((event: ClickEvent) => this.expanded = !this.expanded);
        this.setExpanderButtonVisibility();
        this.expanderPanel.add(this.expanderButton);
        cell.add(this.expanderPanel);
        //will probably need a method equivalent to table.toolsCellAdded(number) if we ever allow the expanderAlignment to be set to 'LEFT'
        cell._element.style.position = "sticky";
        cell._element.style.right = "0px";
        return cell;
    }

    private setExpanderButtonVisibility() {
        if (this.expanderButton != null)
            this.expanderButton.visible = this.expandable;
    }

    rowClicked(event: DomMouseEvent) {
        if (this.table != null) {
            if (this.table.allowEdit && this.table.rowModeControlType === RowModeControlType.AUTO) {
                if (this.mode !== TableRowMode.UPDATE && this.mode !== TableRowMode.ADD) {
                    if (this.table.completeEditedRows() === true)
                        this.editRow();
                }
                this.table.activateValidateOnOutsideClick(event as PointerEvent);
            } else if (this.table.rowModeControlType !== RowModeControlType.ALWAYS_EDIT) {
                const now = new Date().getTime(); // don't toggle the selection when the user is trying to Ctrl-dbl-click
                if (now - this.lastSelClick < 500)
                    return;
                this.lastSelClick = now;
                const clickEvent = new ClickEvent(this, event);
                const isCtrl = clickEvent.hasModifiers({ ctrlKey: true });
                const isShift = clickEvent.hasModifiers({ shiftKey: true });
                let selType = SelectionType.SINGLE;
                if (isShift)
                    selType = SelectionType.RANGE;
                else if (isCtrl)
                    selType = SelectionType.DISTINCT;
                this.table.selectRow(this, selType, event);
            }
        }
    }

    validateSimple(checkRequired: boolean = true, showErrors: boolean = true): boolean {
        const validationResults: ValidationResult[] = this.validate(checkRequired, showErrors);
        if (validationResults == null) {
            return true;
        }
        for (const validationResult of validationResults) {
            if (validationResult.isValid !== true) {
                return false;
            }
        }
        return true;
    }

    validate(checkRequired: boolean, showErrors: boolean = true): ValidationResult[] {
        let result: ValidationResult[] = null;
        for (let i = 0; i < this.cells.length; i++) {
            const cell = this.cells[i];
            if (cell === this.toolsCell || i === this._expanderCellIndex) {
                continue;
            }
            const thisResult = cell.validate(checkRequired, showErrors);
            if (thisResult !== null) {
                if (result == null)
                    result = thisResult;
                else
                    result = result.concat(thisResult);
            }
        }
        return result;
    }

    get index(): number {
        return this._index;
    }

    set index(value: number) {
        this._index = value;
    }

    private get selectColor(): string {
        if (this._selectColor == null) {
            this._selectColor = getThemeForKey("table.selectionBackground");
        }
        return this._selectColor;
    }

    applySelectColor(component: Component) {
        if (component != null)
            component.backgroundColor = this.selectColor;
    }

    get selected(): boolean {
        return this._selected;
    }

    set selected(value: boolean) {
        if (value === this._selected)
            return;
        this._selected = value;
        if (value === true) {
            this.preselectionState = new DesignableObjectTempState({ backgroundColor: this.backgroundColor });
            this.getExpansionComponentLoaded().then(expandComponent => {
                this.setPreselectionExpandCompState(expandComponent);
                this.applySelectColor(expandComponent);
            });
            this.applySelectColor(this);
        } else {
            this.setProps(this.preselectionState?.originalProps);
            this.preselectionState = undefined;
            this.getExpansionComponentLoaded().then(expandComponent => {
                expandComponent?.setProps(this.preselectionExpandCompState?.originalProps);
                this.preselectionExpandCompState = undefined;
            });
        }
        if (this.populatedDOM === true)
            this._updateCellsBasedOnSelection();
    }

    private setPreselectionExpandCompState(expandComponent: Component) {
        if (expandComponent == null || this.preselectionState == null)
            return;
        let backgroundColor = expandComponent.backgroundColor;
        if (backgroundColor == null)
            backgroundColor = (this.preselectionState.originalProps as ComponentProps).backgroundColor;
        const stateProps: Partial<TableRowProps> = { backgroundColor };
        this.preselectionExpandCompState = new DesignableObjectTempState(stateProps);
    }

    private _updateCellsBasedOnSelection() {
        if (this.selected == null)
            return;
        for (const cell of this.cells)
            this._updateCellSelection(cell);
    }

    private _updateCellSelection(cell: TableCell) { // Move along.  Nothing to see here.
        if (!(cell instanceof Container))
            return;
        const comps: Component[] = cell.getRecursiveChildren();
        for (const comp of comps) {
            if (!(comp instanceof Button)) {
                if (this.selected) {
                    if (this.table.addType === AddType.QUICK && comp instanceof Textbox && comp._inputDiv != null) {
                        let color = getThemeForKey("component.palette.table.editRow.textbox.backgroundColor");
                        color = getThemeColor(color) || color;
                        comp._inputDiv.style.backgroundColor = color;
                    }
                }
            }
        }
    }

    override getPropertyDefinitions() {
        return TableRowPropDefinitions.getDefinitions();
    }

    public get populatedDOM() {
        return this._populatedDOM;
    }

    private set populatedDOM(value: boolean) {
        this._populatedDOM = value;
        if (this._populatedDOM === false) {
            this._populatePromise = null;
        }
    }

    override getSearchValues(): string[] {
        const result = [];
        for (const cell of this.cells) {
            result.push(...cell.getSearchValues());
        }
        return result;
    }

    setPropOnChildren(propName: string, value: any) {
        for (const cell of this.cells)
            if (cell instanceof TableCell)
                cell.setPropOnChildren(propName, value);
    }

    setBorderBottom(isExpanding: boolean) {
        if (isExpanding === true) {
            this._unexpandedBorderBottomWidth = DOMUtil.getComputedStyle("border-bottom-width", this._element);
            this._element.style.borderBottomWidth = "0px";
        } else
            this._element.style.borderBottomWidth = this._unexpandedBorderBottomWidth;
    }

    override get serializationName() {
        return "tablerow";
    }

    override get properName(): string {
        return "Table Row";
    }

    override get draggable(): boolean {
        return this.table.rowsAreDraggable();
    }

    override set draggable(value: boolean) {
        this.toolsCell?.fill();
    }

    public static getContainingTableRow(component: Component): TableRow {
        if (component == null)
            return null;
        if (component instanceof TableCell)
            return component.row;
        return this.getContainingTableRow(component.parent);
    }

    public findComponentById(id: String | Function): Component {
        let result: Component;
        for (const cell of this.cells) {
            result = cell.findComponentById(id);
            if (result != null)
                break;
        }
        return result;
    }

    public findComponentsByField(field: string): Component[] {
        const components: Component[] = [];
        for (const cell of this.cells) {
            const cellComponents = cell.findComponentByField(field);
            for (const cellComponent of cellComponents) {
                components.push(cellComponent);
            }
        }
        return components;
    }

    public forEveryChildComponent(callback: (component: Component) => void) {
        for (const cell of this.cells)
            cell.forEveryChildComponent(callback);
    }

    displayDataForField(event: FieldUpdateEvent, data: ModelRow, allData: ModelRow[], rowIndex: number) {
        const components = this.findComponentsByField(event.fieldName);
        for (const component of components) {
            if (component !== event.originator)
                component.displayData(data, allData, rowIndex);
        }
    }

    getEffectiveDataSourceMode(): DataSourceMode {
        if (this.mode === TableRowMode.ADD || this.mode === TableRowMode.QUICK_ADD)
            return DataSourceMode.ADD;
        if (this.mode === TableRowMode.UPDATE)
            return DataSourceMode.UPDATE;
        if (this.mode === TableRowMode.SEARCH)
            return DataSourceMode.SEARCH;
        return DataSourceMode.NONE;
    }

    isFirstCellInRow(cell: TableCell): boolean {
        if (cell == null || this.cells == null)
            return false;
        const cellIndex = this.getIndexOfCell(cell);
        return cellIndex === 0;
    }

    isLastCellInRow(cell: TableCell): boolean {
        if (cell == null || this.cells == null)
            return false;
        const cellIndex = this.getIndexOfCell(cell);
        return cellIndex === this.cells.length - 1;
    }

    private getIndexOfCell(cell: TableCell): number {
        if (cell == null || this.cells == null)
            return -1;
        for (let index = 0; index < this.cells.length; index++) {
            if (cell === this.cells[index])
                return index;
        }
        return -1;
    }

    public override scrollIntoView(options?: Partial<ScrollOptions>, startingElement?: HTMLElement) {
        if (this.expanded !== true)
            DOMUtil.scrollElementIntoView(this._element, options);
        else {
            if (options == null)
                options = {};
            if (options.block == null) {
                if (this.table.getBodyHeight() < this.getExpansionElementHeight())
                    options.block = Block.START;
                else
                    options.block = Block.END;
            }
            DOMUtil.scrollElementIntoView(this.getExpansionElement(), options);
        }
    }

    public get expanderButton(): Button {
        return this._expanderButton;
    }

    public getRowElementHeight(): number {
        return DOMUtil.getElementHeight(this._element);
    }

    public getExpansionElement(): HTMLTableRowElement {
        return this["expansionRow"] as HTMLTableRowElement;
    }

    _setExpansionElement(value: HTMLTableRowElement) {
        this["expansionRow"] = value;
        if (value != null)
            value.style.width = DOMUtil.getElementWidthString(this._element);
    }

    public getExpansionElementHeight(): number {
        const expansionRow = this.getExpansionElement();
        if (expansionRow == null)
            return 0;
        return DOMUtil.getElementHeight(expansionRow);
    }

    public hideDragHandle() {
        this.toolsCell?.hideDragHandle();
    }

    /**
     * This method will collect key handlers from this row and its child components.
     *
     * @returns void
     */
    fillKeyHandlerGroup(recreate: boolean = false) {
        if (this._keyHandlerGroup != null && recreate !== true)
            return;
        this._initKeyHandlerGroup(recreate);
        this._addKeyHandlersToGroup(this.getKeyHandlers());
        for (const cell of this.cells) {
            this._addKeyHandlersToGroup(cell.getKeyHandlers());
            this._addKeyHandlersToGroup(cell.getChildrenKeyHandlersRecursive());
        }
        // When first rendered, we fill keyHandlerGroup after the toolsCell is created, but before the tools cell is
        // added to the cells array.  In that case gather its keys directly.
        if (this.toolsCell != null && this.cells.includes(this.toolsCell) === false)
            this._addKeyHandlersToGroup(this.toolsCell.getKeyHandlers());
        this._keyHandlerGroup.sort();
    }

    override getKeyHandlers(): KeyHandler[] {
        if (this.mode === TableRowMode.QUICK_ADD) {
            const listener = () => this.persistQuickAddRow();
            const enterKeyHandler = this.createKeyHandler(Keys.ENTER, listener, null, this._element);
            const ctrlEnterKeyHandler = this.createKeyHandler(Keys.ENTER, listener, { ctrlKey: true }, this._element);
            this.addKeyHandler(enterKeyHandler);
            this.addKeyHandler(ctrlEnterKeyHandler);
        }
        return super.getKeyHandlers();
    }

    private _addKeyHandlersToGroup(compKeyHandlers: KeyHandler[]) {
        if (ArrayUtil.isEmptyArray(compKeyHandlers) === true)
            return;
        for (const compKeyHandler of compKeyHandlers) {
            this._keyHandlerGroup.addKeyHandler(compKeyHandler);
        }
    }

    private _initKeyHandlerGroup(recreate: boolean = false) {
        if (this._keyHandlerGroup == null || recreate === true)
            this._keyHandlerGroup = new KeyHandlerGroup();
    }

    handleKey(event: KeyEvent): boolean {
        this.fillKeyHandlerGroup();
        return this._keyHandlerGroup.handleKey(event);
    }

    public forEveryTableCell(callback: (cell: TableCell) => void) {
        for (const cell of this.cells) {
            callback(cell);
        }
    }

    public override doBeforeComponentEnlarge(cellsToIgnore: TableCell[]) {
        this.hiddenCells = [];
        this.forEveryTableCell((cell: TableCell) => {
            if (cell.visible === true && cellsToIgnore.includes(cell) !== true) {
                this.hiddenCells.push(cell);
                cell.visible = false;
            }
        });
        this.enlarged = true;
    }

    public override doAfterComponentsShrink() {
        this.enlarged = false;
        for (const hiddenCell of this.hiddenCells) {
            hiddenCell.visible = true;
        }
        this.hiddenCells = undefined;
    }

    public shrinkAllComponents() {
        for (const cell of this.cells) {
            cell.enlarged = false;
        }
    }

    override handleEnlargeOrShrink() {
        if (this.enlarged === true) {
            //allow the one row to fill the table body
            this.applyTempState({ minHeight: this.table.getBodyHeight() });
        } else {
            //shrink the row
            this.revertTempState();
        }
    }

    override get enlargeScope(): Component {
        if (super.enlargeScope != null)
            return super.enlargeScope;
        super.enlargeScope = this.table; //this.table.getEnlargeScope();
        return super.enlargeScope;
    }

    public set enlargeScope(value: Component) {
        super.enlargeScope = value;
    }

    /**
     * _defaultState contains the state of the row before any even/odd coloring, or selected row coloring, is applied
     */
    public get defaultState(): DesignableObjectTempState {
        return this._defaultState;
    }

    public initDefaultState() {
        if (this._defaultState != null)
            return;
        this._defaultState = new DesignableObjectTempState({ backgroundColor: this.backgroundColor ?? null });
    }
}

export class DragSession {
    private _dragStartY: number;
    private _rowHeight: number;
    private _nextMoveUpAt: number;
    private _nextMoveDownAt: number;
    private _initialRowY: number;
    private _newRowIndex: number;
    private _oldRowIndex: number;
    private _previousZIndex: string;

    get dragStartY(): number {
        return this._dragStartY;
    }

    set dragStartY(value: number) {
        this._dragStartY = value;
    }

    get rowHeight(): number {
        return this._rowHeight;
    }

    set rowHeight(value: number) {
        this._rowHeight = value;
    }

    get nextMoveUpAt(): number {
        return this._nextMoveUpAt;
    }

    set nextMoveUpAt(value: number) {
        this._nextMoveUpAt = value;
    }

    get nextMoveDownAt(): number {
        return this._nextMoveDownAt;
    }

    set nextMoveDownAt(value: number) {
        this._nextMoveDownAt = value;
    }

    get initialRowY(): number {
        return this._initialRowY;
    }

    set initialRowY(value: number) {
        this._initialRowY = value;
    }

    get newRowIndex(): number {
        return this._newRowIndex;
    }

    set newRowIndex(value: number) {
        this._newRowIndex = value;
    }

    get oldRowIndex(): number {
        return this._oldRowIndex;
    }

    set oldRowIndex(value: number) {
        this._oldRowIndex = value;
    }

    get previousZIndex(): string {
        return this._previousZIndex;
    }

    set previousZIndex(value: string) {
        this._previousZIndex = value;
    }
}

ComponentTypes.registerComponentType("tablerow", TableRow.prototype.constructor);
