import { DataSource, DataSourceMode } from "..";
import { Event, EventListener } from "./Event";

export interface DataSourceModeChangeListener extends EventListener {
    (event: DataSourceModeChangeEvent): void;
}

export class DataSourceModeChangeEvent extends Event {
    private _oldMode: DataSourceMode;
    private _newMode: DataSourceMode;
    private _preventable: boolean;

    constructor(dataSource: DataSource, oldMode: DataSourceMode, newMode: DataSourceMode, preventable: boolean) {
        super(dataSource, null);
        this._oldMode = oldMode;
        this._newMode = newMode;
        this._preventable = preventable;
    }

    public get preventable() {
        return this._preventable;
    }

    public get oldMode() {
        return this._oldMode;
    }

    public get newMode() {
        return this._newMode;
    }

    public get dataSource(): DataSource {
        return super.target as DataSource;
    }
}
