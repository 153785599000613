import { Color } from "@mcleod/core";
import { ComponentCreator, PropType } from "../..";
import { ComponentPropDefinitionUtil, ComponentPropDefinitions, ComponentProps } from "../../base/ComponentProps";

export interface MultiSwitchProps extends ComponentProps {
    caption: string;
    items: ComponentCreator[];
    selectedIndex: number;
    backgroundColorSelected: Color;
    backgroundColorUnselected: Color;
    colorSelected: Color;
    colorUnselected: Color;
}

let multiSwitchPropsDefs: ComponentPropDefinitions;

export class MultiSwitchPropDefinitions {
    public static getDefinitions(): ComponentPropDefinitions {
        if (multiSwitchPropsDefs == null) {
            multiSwitchPropsDefs = {
                ...ComponentPropDefinitionUtil.getComponentPropertyDefinitions(),
                ...ComponentPropDefinitionUtil.getDataBoundPropertyDefinitions(),
                caption: { type: PropType.string, visibleInMcLeodTailor: true, description: "This specifies the text that will appear above the MultiSwitch." },
                onChange: { type: PropType.event, category: "Events", addListenerMethod: "addChangeListener", removeListenerMethod: "removeChangeListener", eventSignature: "OnChange(event: ChangeEvent)", description: "This specifies a function to be called when the MultiSwitch value changes." },
                items: { type: PropType.stringArray, visibleInMcLeodTailor: true, description: "This specifies a list of items that the user can select from.  Setting items to an array or a function that returns an array will cause the Textbox to render a dropdown button." },
                printable: {
                    type: PropType.bool,
                    defaultValue: false,
                    category: "Appearance",
                    visibleInMcLeodTailor: true,
                    description: "This toggles whether the MultiSwitch appears as user-editable MultiSwitch (printable = false) or as a caption that indicates the current state of the MultiSwitch (printable = true).  This is often changed at runtime.",
                },
                printableDuringAdd: {
                    type: PropType.bool,
                    defaultValue: false,
                    category: "Appearance",
                    visibleInMcLeodTailor: true,
                    description: "This toggles whether the MultiSwitch should appear in its printable state during add mode.",
                },
                printableDuringSearch: {
                    type: PropType.bool,
                    defaultValue: false,
                    category: "Appearance",
                    visibleInMcLeodTailor: true,
                    description: "This toggles whether the MultiSwitch should appear in its printable state during search mode.",
                },
                printableDuringUpdate: {
                    type: PropType.bool,
                    defaultValue: false,
                    category: "Appearance",
                    visibleInMcLeodTailor: true,
                    description: "This toggles whether the MultiSwitch should appear in its printable state during update mode.",
                },
                defaultDataValue: { type: PropType.bool, visibleInMcLeodTailor: true, category: "Data", source: "databound", description: "This specifies the default value when entering add mode." },
                onPrintable: { type: PropType.event, category: "Events", addListenerMethod: "addPrintableListener", removeListenerMethod: "removePrintableListener", eventSignature: "OnPrintable(event: PrintableEvent)", description: "This specifies a function to be called when the component's printable property has changed."}
            };
            ComponentPropDefinitionUtil.populateComponentPropNames(multiSwitchPropsDefs);
        }
        return multiSwitchPropsDefs;
    }
}
