import { Button, Label, Layout, Panel, Splitter, Textbox, Tree } from "@mcleod/components";

export abstract class AutogenLayoutApiExplorer extends Layout {
	buttonButton1: Textbox;
	buttonCopy: Button;
	buttonCopyResponse: Button;
	buttonSendRequest: Button;
	buttonServerSelector: Textbox;
	labelApiHeader: Label;
	labelBodyHeader: Label;
	labelCodeSample: Label;
	labelDetailStatus: Label;
	labelEndpointDoc: Label;
	labelEndpointName: Label;
	labelEndpointOrigin: Label;
	labelEndpointPath: Label;
	labelLabel1: Label;
	labelLabel2: Label;
	labelResponse: Label;
	panel3: Panel;
	panel4: Panel;
	panel5: Panel;
	panel6: Panel;
	panel7: Panel;
	panelApis: Panel;
	panelBody: Panel;
	panelContent: Panel;
	panelEndpointAddress: Panel;
	panelEndpointContent: Panel;
	panelEndpointDetail: Panel;
	panelEndpointHeader: Panel;
	panelEndpointMethods: Panel;
	panelHeader: Panel;
	panelLiveRequestContent: Panel;
	panelResponse: Panel;
	panelSamples: Panel;
	panelSourceLinks: Panel;
	splitEndpointDetail: Splitter;
	splitterMain: Splitter;
	textboxSearch: Textbox;
	treeApis: Tree;
}
