import { ArrayUtil, SortUtil } from "@mcleod/core";
import { Container, Layout } from "../..";
import { Overlay } from "../../page/Overlay";
import { AddedSnackBarInfo } from "../../page/Snackbar";
import { Panel } from "./Panel";

export type ScreenStackChangeListener = () => void;
export type CurrentLayoutsListener = (currentContainer: Container, layouts: Layout[]) => void;

export class ScreenStack {
    private static listeners: ScreenStackChangeListener[] = [];
    private static currentLayoutListeners: CurrentLayoutsListener[] = [];
    private static screenStack: Panel[] = [];
    private static overlayStack: Overlay[] = [];
    private static tooltipStack: Panel[] = [];

    public static push(panel: Panel): void {
        ScreenStack.screenStack.push(panel);
        ScreenStack.fireListeners();
        ScreenStack.fireCurrentLayoutListeners();
    }

    public static pop(panel: Panel) {
        ArrayUtil.removeFromArray(ScreenStack.screenStack, panel);
        ScreenStack.fireListeners();
        ScreenStack.fireCurrentLayoutListeners();
    }

    public static getCurrentScreen(): Panel {
        return ArrayUtil.getLastElement(ScreenStack.screenStack);
    }

    private static getDefaultTargetPanel(): Panel {
        const currentOverlay = ScreenStack.getNewestOverlay();
        if (ScreenStack.overlayContainsValidTargetPanel(currentOverlay) === true)
            return currentOverlay.component as Panel;
        return ScreenStack.getCurrentScreen();
    }

    /**
     * Returns the target that would be used if the current/default target wasn't present.  This can be helpful when a
     * snackbar/toast need to be displayed at a time when an Overlay is present but will be closed by the time it can
     * become visible.
     *
     * - If multiple Overlays are present, return the next to last (valid) Overlay (the one below the top-most Overlay).
     * - If one Overlay is present, return the 'current screen' (top-most layout in the screen stack).
     * - If no Overlays are present, and the screen stack contains > 1 entry, return the next to last entry.
     * - If no Overlays are present, and the screen stack contains a single entry, return that entry.
     */
    private static getPreviousTargetPanel(): Panel {
        for (let x=ScreenStack.overlayStack.length-2 ; x>=0; x--) {
            const overlayInStack = ScreenStack.overlayStack[x];
            if (ScreenStack.overlayContainsValidTargetPanel(overlayInStack) === true)
                return overlayInStack.component as Panel;
        }
        if (ArrayUtil.isEmptyArray(ScreenStack.overlayStack) === false)
            return ScreenStack.getCurrentScreen();
        if (ArrayUtil.getLength(ScreenStack.screenStack) > 1)
            return ScreenStack.screenStack[ScreenStack.screenStack.length - 2];
        return ScreenStack.getCurrentScreen();
    }

    /**
     * Determines if an Overlay contains a component that can be assigned a Snackbar/Toast.
     */
    private static overlayContainsValidTargetPanel(overlay: Overlay): boolean {
        return overlay != null && overlay.component instanceof Panel;
    }

    public static pushSnackbar(snack: Panel, targetPanel?: Panel): AddedSnackBarInfo {
        const standardTarget = ScreenStack.getSnackbarTarget();
        const targetToUse = targetPanel == null ? standardTarget : targetPanel;
        //if the new snackbar is going to be added to a panel that's not the 'current' panel, it will have a lower zindex
        //than if it was added to the 'current' panel.  this means that everything with a higher zindex needs to be incremented
        //before we display the snackbar.  do this before we add the new snack to the panel so that it's not incremented too.
        if (targetPanel != null && targetPanel != standardTarget)
            ScreenStack.incrementZIndexes(targetToUse);
        const result = targetToUse.pushSnackbar(snack);
        ScreenStack.moveTopNonModalDialogsToFront();
        ScreenStack.fireListeners();
        return result;
    }

    public static popSnackbar(snack: Panel) {
        //find the snackbar's parent...may no longer be the same as the result of getSnackbarTarget() as new panels may have opened
        const snackbarParent = ScreenStack.findSnackbarParent(snack);
        if (snackbarParent == null)
            return;
        snackbarParent.popSnackbar(snack);
        ScreenStack.fireListeners();
    }

    private static findSnackbarParent(snack: Panel): Panel {
        for (let x = ScreenStack.overlayStack.length - 1; x >= 0; x--) {
            const overlay = ScreenStack.overlayStack[x];
            if (overlay.component instanceof Panel) {
                if (overlay.component.containsSnackbar(snack) === true)
                    return overlay.component;
            }
        }
        for (let x = ScreenStack.screenStack.length - 1; x >= 0; x--) {
            const panel = ScreenStack.screenStack[x];
            if (panel.containsSnackbar(snack) === true)
                return panel;
        }
        return null;
    }

    public static getSnackbarTarget(): Panel {
        return ScreenStack.getDefaultTargetPanel();
    }

    public static getPreviousSnackbarTarget(): Panel {
        return ScreenStack.getPreviousTargetPanel();
    }

    public static getOldestSnackbar(id?: string): Panel {
        return ScreenStack.getSnackbarTarget()?.getOldestSnackbar(id);
    }

    public static pushToast(toast: Panel, targetPanel?: Panel) {
        const standardTarget = ScreenStack.getToastTarget();
        const targetToUse = targetPanel == null ? standardTarget : targetPanel;
        //if the new toast is going to be added to a panel that's not the 'current' panel, it will have a lower zindex
        //than if it was added to the 'current' panel.  this means that everything with a higher zindex needs to be incremented
        //before we display the snackbar.  do this before we add the new snack to the panel so that it's not incremented too.
        if (targetPanel != null && targetPanel != standardTarget)
            ScreenStack.incrementZIndexes(targetToUse);
        targetToUse.pushToast(toast);
        ScreenStack.moveTopNonModalDialogsToFront();
        ScreenStack.fireListeners();
    }

    public static popToast(toast: Panel) {
        //find the toast's parent...may no longer be the same as the result of getToastTarget() as new panels may have opened
        const toastParent = ScreenStack.findToastParent(toast);
        if (toastParent == null)
            return;
        toastParent.popToast(toast);
        ScreenStack.fireListeners();
    }

    private static findToastParent(toast: Panel): Panel {
        for (let x = ScreenStack.overlayStack.length - 1; x >= 0; x--) {
            const overlay = ScreenStack.overlayStack[x];
            if (overlay.component instanceof Panel) {
                if (overlay.component.containsToast(toast) === true)
                    return overlay.component;
            }
        }
        for (let x = ScreenStack.screenStack.length - 1; x >= 0; x--) {
            const panel = ScreenStack.screenStack[x];
            if (panel.containsToast(toast) === true)
                return panel;
        }
        return null;
    }

    public static getToastTarget(): Panel {
        return ScreenStack.getDefaultTargetPanel();
    }

    public static getPreviousToastTarget(): Panel {
        return ScreenStack.getPreviousTargetPanel();
    }

    public static getNewestToast(id?: string): Panel {
        return ScreenStack.getToastTarget()?.getNewestToast(id);
    }

    public static pushOverlay(overlay: Overlay) {
        ScreenStack.overlayStack.push(overlay);
        ScreenStack.fireListeners();
        ScreenStack.fireCurrentLayoutListeners();
    }

    public static popOverlay(overlay: Overlay) {
        ArrayUtil.removeFromArray(ScreenStack.overlayStack, overlay);
        ScreenStack.fireListeners();
        ScreenStack.fireCurrentLayoutListeners();
    }

    public static getNewestOverlay(): Overlay {
        return ArrayUtil.getLastElement(ScreenStack.overlayStack);
    }

    public static overlayInStack(overlay: Overlay): boolean {
        return ScreenStack.overlayStack.includes(overlay);
    }

    public static getAllOverlays(): Overlay[] {
        return ScreenStack.overlayStack;
    }

    public static getNewestNonModalDialog(): Panel {
        for (let x = ScreenStack.screenStack.length - 1; x >= 0; x--) {
            const panel = ScreenStack.screenStack[x];
            if (panel["modal"] === false)
                return panel;
        }
        return null;
    }

    public static pushTooltip(tooltip: Panel) {
        ScreenStack.tooltipStack.push(tooltip);
        ScreenStack.fireListeners();
    }

    public static popTooltip(tooltip: Panel) {
        ArrayUtil.removeFromArray(ScreenStack.tooltipStack, tooltip);
        ScreenStack.fireListeners();
    }

    public static getNewestTooltip(): Panel {
        return ArrayUtil.getLastElement(ScreenStack.tooltipStack);
    }

    public static getOldestTooltip(): Panel {
        return ArrayUtil.getFirstElement(ScreenStack.tooltipStack);
    }

    public static getAllTooltips(): Panel[] {
        return ScreenStack.tooltipStack;
    }

    /**
     * This method will return the root panel in the screen stack, which should be the Router panel
     * @returns the Panel that is at the root of the screen stack
     */
    public static getRootScreen(): Panel {
        if (ScreenStack.screenStack.length > 0)
            return ScreenStack.screenStack[0];
        return null;
    }

    public static getHighestZIndex(): number {
        let result: number;
        for (const screen of ScreenStack.screenStack) {
            const screenZIndex = screen.getHighestZIndex();
            if (screenZIndex != null && (result == null || result < screenZIndex))
                result = screenZIndex;
        }
        for (const tooltip of ScreenStack.tooltipStack) {
            if (tooltip.zIndex != null && (result == null || result < tooltip.zIndex))
                result = tooltip.zIndex;
        }
        for (const overlay of ScreenStack.overlayStack) {
            const zIndex = overlay.zIndex;
            if (zIndex != null && (result == null || result < zIndex))
                result = zIndex;
        }
        return result;
    }

    public static getNewHighestZIndex(): number {
        let result = ScreenStack.getHighestZIndex();
        if (result == null)
            result = 45; //picked a random number so that we can tell it's the default value when inspecting elements
        else
            result += 1;
        return result;
    }

    static getZIndexComponents(sortDesc?: boolean): any[] {
        const result = [];
        for (const screen of ScreenStack.screenStack) {
            result.push(...screen.getZIndexComponents());
        }
        for (const tooltip of ScreenStack.tooltipStack) {
            if (tooltip.zIndex != null)
                result.push(tooltip);
        }
        for (const overlay of ScreenStack.overlayStack) {
            const zIndex = overlay.zIndex;
            if (zIndex != null)
                result.push(overlay);
        }
        if (sortDesc != null)
            ScreenStack._sortByZIndex(result, sortDesc);
        return result;
    }

    private static _sortByZIndex(panels: Panel[], sortDesc: boolean = true): Panel[] {
        if (panels != null) {
            panels.sort((a, b) => {
                const sortMethod = sortDesc === true ? "desc" : "asc";
                return SortUtil.compareTwoValues(a.zIndex, b.zIndex, sortMethod);
            });
        }
        return panels;
    }

    /**
     * Starting with the provided panel, increment any panel's zIndex value.  This is done so we can insert a new panel somewhere in the middle of the zIndex stack.
     * @param fromPanel
     */
    private static incrementZIndexes(fromPanel: Panel) {
        const targetsHighestZIndex = fromPanel.getHighestZIndex();
        if (targetsHighestZIndex != null) {
            const zIndexComponentsDesc = ScreenStack.getZIndexComponents(true);
            if (ArrayUtil.isEmptyArray(zIndexComponentsDesc) !== true) {
                for (const compToUpdate of zIndexComponentsDesc) {
                    if (compToUpdate.zIndex > targetsHighestZIndex)
                        compToUpdate.zIndex = compToUpdate.zIndex + 1;
                    else
                        break;
                }
            }
        }
    }

    /**
     * This method is intended to update the z-index value of non-modal Dialog boxes that are the most
     * recent things on the screen stack.  The intent is to make sure that those Dialogs would stay in front
     * of toasts/snacks, in case they are moved around the screen.
     *
     * We don't have to deal with modal Dialogs because toasts/snacks are covered up by the Overlay.
     *
     * Once we get to something other than a non-modal Dialog in the stack, quit.
     */
    public static moveTopNonModalDialogsToFront() {
        const dialogsToUpdate: Panel[] = [];
        for (let x = ScreenStack.screenStack.length - 1; x >= 0; x--) {
            const panel = ScreenStack.screenStack[x];
            if (panel["modal"] === false)
                dialogsToUpdate.splice(0, 0, panel);
            else
                break;
        }
        for (const panel of dialogsToUpdate) {
            panel.zIndex = this.getNewHighestZIndex();
        }
    }

    private static fireListeners() {
        for (const listener of ScreenStack.listeners)
            listener();
    }

    public static addListener(listener: ScreenStackChangeListener) {
        ScreenStack.listeners.push(listener);
    }

    public static removeListener(listener: ScreenStackChangeListener) {
        ArrayUtil.removeFromArray(ScreenStack.listeners, listener);
    }

    public static fireCurrentLayoutListeners() {
        const currentContainer = ScreenStack.getCurrentLayoutContainer();
        const currentLayouts = ScreenStack.getCurrentLayouts(currentContainer);
        for (const listener of ScreenStack.currentLayoutListeners)
            listener(currentContainer, currentLayouts);
    }

    public static addCurrentLayoutsListener(listener: CurrentLayoutsListener) {
        ScreenStack.currentLayoutListeners.push(listener);
    }

    public static removeCurrentLayoutsListener(listener: CurrentLayoutsListener) {
        ArrayUtil.removeFromArray(ScreenStack.currentLayoutListeners, listener);
    }

    public static getCurrentLayout(): Layout {
        return ScreenStack.getCurrentLayouts(ScreenStack.getCurrentLayoutContainer())?.[0];
    }

    public static getCurrentLayoutContainer(): Container {
        const overlay = ScreenStack.getNewestOverlay();
        const screen = ScreenStack.getCurrentScreen();
        if ((overlay?.zIndex ?? 0) > (screen?.zIndex ?? 0)) {
            return overlay.getOverlayContent();
        }
        return screen;
    }

    public static getCurrentLayouts(currentContainer: Container): Layout[] {
        const layouts = [];
        currentContainer?.forEveryChildComponent(comp => {
            if (comp instanceof Layout)
                layouts.push(comp);
        })
        return layouts;
    }
}
