import { Styles } from "@mcleod/core";

export const TreeStyles = Styles.make("tree", {
    treeBase: {
        overflowY: "auto", minWidth: 100, minHeight: 40, outline: "none", listStyle: "none",
        padding: "0px",
        margin: "0px",
    },
    treeDropdown: { display: "inline-block", cursor: "pointer" }
});
