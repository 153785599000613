import { ObjectUtil, VerticalAlignment } from "@mcleod/core";
import { ComponentPropDefinitionUtil, ComponentPropDefinitions } from "../../base/ComponentProps";
import { ContainerProps } from "../../base/ContainerProps";
import { PropType } from "../../base/PropType";

export interface PanelProps extends ContainerProps {
    busy: boolean;
    rowBreakDefault: boolean;
    verticalAlign: VerticalAlignment;
    wrap: boolean;
    allowSelect: boolean;
    scrollX: boolean;
    scrollY: boolean;
}

let panelPropDefs: ComponentPropDefinitions;

export class PanelPropDefinitions {
    public static getDefinitions(): ComponentPropDefinitions {
        if (panelPropDefs == null) {
            panelPropDefs = {
                ...ComponentPropDefinitionUtil.getComponentPropertyDefinitions(),
                ...ComponentPropDefinitionUtil.getDataBoundPropertyDefinitions(),
                busy: { type: PropType.bool, defaultValue: false, visibleInDesigner: false, description: "This toggles whether or not the panel displays the spinner animation." },
                rowBreakDefault: { type: PropType.bool, category: "Layout", defaultValue: true, visibleInMcLeodTailor: true, description: "Specifies whether the components in this Panel will rowBreak when not overridden by the invidual component's rowBreak property." },
                verticalAlign: { type: PropType.string, category: "Appearance", dropdownProps: { items: ["top", "center", "bottom", "fillRow"] }, defaultValue: "center", visibleInMcLeodTailor: true, description: "This specifies the vertical alignment of each of the rows created in this Panel." },
                wrap: { type: PropType.bool, category: "Layout", defaultValue: true, visibleInMcLeodTailor: true, description: "Toggles whether or not components on this Panel can wrap when the Panel is not wide enough to fit all of them." },
                scrollX: { type: PropType.bool, category: "Layout", visibleInMcLeodTailor: true },
                scrollY: { type: PropType.bool, category: "Layout", visibleInMcLeodTailor: true },
            };
            ComponentPropDefinitionUtil.populateComponentPropNames(panelPropDefs);
        }
        return panelPropDefs;
    }

    public static cloneDefs(): ComponentPropDefinitions {
        return ObjectUtil.deepCopy(PanelPropDefinitions.getDefinitions());
    }
}
