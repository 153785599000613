import { Styles } from "@mcleod/core";

export const TableCellStyles = Styles.make("td", () => {
    return {
        base: {
            verticalAlign: "top",
            paddingTop: "4px",
            paddingBottom: "4px",
            display: "table-cell",
            overflow: "hidden"
        }
    }
});
