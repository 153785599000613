import { Clipboard } from "@mcleod/core";
import { AutogenLayoutNewTenantAccessToken } from "./autogen/AutogenLayoutNewTenantAccessToken";
import { ClickEvent, Snackbar, DataSourceExecutionEvent } from "@mcleod/components";

export class NewTenantAccessToken extends AutogenLayoutNewTenantAccessToken {
    /** This is an event handler for the onClick event of buttonCopy. */
    buttonCopyOnClick(event: ClickEvent) {
        Clipboard.copyText(this.textboxToken.text);
        Snackbar.showSnackbar("The access token has been copied to clipboard!");
    }

    /** This is an event handler for the afterExecution event of sourceTenantAccessToken. */
    sourceTenantAccessTokenAfterExecution(event: DataSourceExecutionEvent) {
        this.panelToken.visible = true;
    }
}