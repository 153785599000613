import { ArrayUtil, ModelRow, OrderByInfo, UserSettings } from "@mcleod/core";

export interface ColumnDescriptor {
    headingCellId: string;
}

export class TableConfig {
    private _modelRow: ModelRow;
    private _active: boolean = false;
    private _columnDescriptors: ColumnDescriptor[] = [];
    private _orderByInfo: OrderByInfo[] = [];

    constructor(row?: ModelRow) {
        if (row != null) {
            this._modelRow = row;
            this.setDefinition(row.get("definition", ""));
        }
        else
            this._modelRow = new ModelRow("common/table-config");
    }

    get id(): string {
        return this._modelRow.get("id", null);
    }

    set id(value: string) {
        this._modelRow.set("id", value);
    }

    get layoutPath(): string {
        return this._modelRow.get("layout_path", null);
    }

    set layoutPath(value: string) {
        this._modelRow.set("layout_path", value);
    }

    get tableId(): string {
        return this._modelRow.get("table_id", null);
    }

    set tableId(value: string) {
        this._modelRow.set("table_id", value);
    }

    get name(): string {
        return this._modelRow.get("name", null);
    }

    set name(value: string) {
        this._modelRow.set("name", value);
    }

    get ownerId(): string {
        return this._modelRow.get("owner_id", null);
    }

    set ownerId(value: string) {
        this._modelRow.set("owner_id", value);
    }

    get ownerName(): string {
        return this._modelRow.get("owner_name", null);
    }

    get isDefault(): string {
        return this._modelRow.get("is_default", null);
    }

    set isDefault(value: string) {
        this._modelRow.set("is_default", value);
    }

    get isActive(): boolean {
        return this._active;
    }

    set isActive(value: boolean) {
        this._active = value;
    }

    get columnDescriptors(): ColumnDescriptor[] {
        return this._columnDescriptors;
    }

    set columnDescriptors(value: ColumnDescriptor[]) {
        this._columnDescriptors = value;
        this._modelRow.set("definition", this.getDefinition());
    }

    addColumn(columnDetails: ColumnDescriptor) {
        this.columnDescriptors.push(columnDetails);
        this._modelRow.set("definition", this.getDefinition());
    }

    get orderByInfo(): OrderByInfo[] {
        return this._orderByInfo;
    }

    set orderByInfo(value: OrderByInfo[]) {
        this._orderByInfo = value;
        this._modelRow.set("definition", this.getDefinition());
    }

    addOrderByInfo(orderByInfo: OrderByInfo) {
        this._orderByInfo.push(orderByInfo);
        this._modelRow.set("definition", this.getDefinition());
    }

    public hasOrderByInfo(): boolean {
        return ArrayUtil.isEmptyArray(this.orderByInfo) === false;
    }

    private getDefinition(): string {
        const definition = {
            columns: this.columnDescriptors,
            order_by_info: this.orderByInfo
        };
        return JSON.stringify(definition);
    }

    public setDefinition(json: string) {
        const definition = JSON.parse(json);
        this._columnDescriptors = [];
        for (const column of definition.columns) {
            this.addColumn({ headingCellId: column.headingCellId });
        }
        this._orderByInfo = [];
        for (const orderByInfo of definition.order_by_info) {
            this.addOrderByInfo({ field: orderByInfo.field, sort: orderByInfo.sort });
        }
    }

    public isCurrentUsersConfig(): boolean {
        return this._modelRow.get("owner_id", null) === UserSettings.getUserId();
    }

    get modelRow(): ModelRow {
        return this._modelRow;
    }

    async post(): Promise<ModelRow> {
        return this._modelRow.post();
    }

    async delete(): Promise<any> {
        const result = await this._modelRow.delete();
        if (this.isDefault === "Y")
            UserSettings.getSingleton().removeDefaultTableConfig(this.modelRow);
        return result;
    }
}
