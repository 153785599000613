interface DateTimeFormatOptions extends Intl.DateTimeFormatOptions {
    fractionalSecondDigits: 0 | 1 | 2 | 3;
}

export class DateTimeFormat {
    private static readonly formatOptions: DateTimeFormatOptions = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: false,
        fractionalSecondDigits: 3
    };
    private static readonly format = new Intl.DateTimeFormat("en-US", this.formatOptions);

    public static get(): Intl.DateTimeFormat {
        return this.format;
    }
}
