/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelStates.ts                        *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "common/states";

export class AutogenModelStates<RowType extends ModelRow> extends Endpoint<RowType, RowStatesFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowStates extends ModelRow<RowStatesFields> {
    constructor() {
        super(AutogenUserSavedSearchEndpoint);
    }
}

export interface RowStatesFields {
}

