import { Collection, LogManager, ModelRow } from "@mcleod/core";
import { ModelLayout } from "../../models/ModelLayout";
import { RowLayoutFields } from "../../models/autogen/AutogenModelLayout";
import { Layout } from "./Layout";

const cachedLayouts: Collection<string> = {};
const inFlightApiCalls: Collection<Promise<ModelRow<any>>> = {};
const log = LogManager.getLogger("components.layout.LayoutDefinitionRetriever");

export class LayoutDefinitionRetriever {

    public static clearCachedLayout(name: string): void {
        delete cachedLayouts[name];
    }

    public async getLayoutDefinition(layout: Layout): Promise<string | ModelRow> {
        const layoutName = layout.layoutName;
        const def = (layout._designer == null && !layout.testingSpecificLayoutVersion()) ? cachedLayouts[layoutName] : null;
        if (def != null) {
            log.debug("Returning cached layout:", layoutName);
            return def;
        }
        const rowLayout = await this.getLayoutDefinitionFromServer(layout);
        if (layout._designer == null) {
            if (layout.testingSpecificLayoutVersion()) {
                return rowLayout;
            }
            this.cacheLayoutDefinition(layoutName, rowLayout);
        }
        return rowLayout.get("definition");
    }

    private async getLayoutDefinitionFromServer(layout: Layout): Promise<ModelRow<any>> {
        const searchFilter = this.createSearchFilter(layout);
        const searchKey = JSON.stringify(searchFilter);

        if (!inFlightApiCalls[searchKey]) {
            log.debug("Requesting layout definition from server", layout.layoutName);
            inFlightApiCalls[searchKey] = new ModelLayout().searchSingle(searchFilter).finally(() => {
                delete inFlightApiCalls[searchKey];
            });
        } else {
            log.debug(`A request has already been made for ${layout.layoutName}, waiting for response.`);
        }

        return inFlightApiCalls[searchKey];
    }

    private cacheLayoutDefinition(layoutName:string, serverResponse: ModelRow){
        cachedLayouts[layoutName] = serverResponse.get("definition");
        const nestedLayoutDefs = serverResponse.get("nested_layouts", {});
        Object.entries(nestedLayoutDefs).forEach(([layoutName, layoutDef]) => {
            if (typeof layoutDef === "string") {
                cachedLayouts[layoutName] = layoutDef;
            }
        });
    }

    private createSearchFilter(layout: Layout): Partial<RowLayoutFields> {
        const filter: Partial<RowLayoutFields> = {
            path: layout.layoutName,
            removed_by_server: layout.removedByServer,
            apply_field_level_perms: layout.applyFieldLevelPermissions,
            apply_field_level_licensing: layout.applyFieldLevelLicensing,
            apply_field_level_company_type: layout.applyFieldLevelCompanyType,
            apply_field_level_ltl_type: layout.applyFieldLevelLtlType,
            apply_field_level_experiment: layout.applyFieldLevelExperiment,
            apply_field_level_multi_currency: layout.applyFieldLevelMultiCurrency,
            serialize_nested_layouts: true,
         };
        if (layout.testingSpecificLayoutVersion()) {
            if (layout.layoutId !== "base")
                filter.id = layout.layoutId;
            else
                filter.require_base_version = true;
        }
        return filter;
    }

    public static isCached(layoutName: string): boolean {
        return cachedLayouts[layoutName] != null;
    }
}
