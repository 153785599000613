import { CurrencyUtil, Logger, LogManager, ModelRow, StringUtil } from ".";

export type SortDirection = "asc" | "desc";

export class SortUtil {
    private static log: Logger;

    public static getSortTestValue(data: ModelRow | any, fieldName: string): any {
        return data instanceof ModelRow ? data.get(fieldName) : data.data[fieldName];
    }

    public static compareTwoValues(valueA: any, valueB: any, sortMethod: SortDirection = "asc",
        sortNullsAtEnd = false): number {
        const log = this.getLog();
        log.debug(() => ["sort: %o  test value A: [%o]  test value B: [%o]", sortMethod, valueA, valueB]);
        let result = 0;
        if (valueA == valueB) {
            result = 0;
        } else if (valueA == null) {
            result = sortNullsAtEnd ? 1 : -1;
        } else if (valueB == null) {
            result = sortNullsAtEnd ? -1 : 1;
        } else if (CurrencyUtil.isCurrency(valueA) && CurrencyUtil.isCurrency(valueB)) {
            //hope we can change this currency business when we start using the real Currency class
            result = CurrencyUtil.compareCurrencys(valueA, valueB, sortNullsAtEnd);
        } else if (typeof valueA === 'string' && typeof valueB === 'string') {
            result = StringUtil.compare(valueA, valueB);
        } else {
            result = valueA > valueB ? 1 : -1;
        }

        if (result != 0 && sortMethod === "desc")
                result *= -1;
        log.debug(() => ["sort result: %d", result]);
        return result;
    }

    private static getLog(): Logger {
        if (this.log == null)
            this.log = LogManager.getLogger("core.SortUtil");
        return this.log;
    }
}
