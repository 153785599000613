import { ModelRow } from "@mcleod/core";
import { Component, DataSourceMode, TableRow } from "..";

export function getRelevantModelRow(component: Component): ModelRow {
    if (component.hasBoundField() !== true)
        return null;

    if (component.boundRow instanceof ModelRow)
        return component.boundRow;

    if (component.dataSource != null) {
        return component.dataSource.activeRow;
    }

    return TableRow.getContainingTableRow(component)?.data as ModelRow;
}

export function getCurrentDataSourceMode(component: Component): DataSourceMode {

    if (component.searchOnly === true)
        return DataSourceMode.SEARCH;
    if (component.hasBoundField() !== true)
        return null;

    // if the component's datasource has an activeRow, or the dataSource._boundComponents includes this component,
    // then it's not inside a table row, and we can use the DataSource's mode.
    if (component.dataSource && (component.dataSource.activeRow || component.dataSource.isBoundComponent?.(component))) {
        return component.dataSource.mode;
    }

    return TableRow.getContainingTableRow(component)?.getEffectiveDataSourceMode();
}

export function isBoundToPrimaryKeyField(component: Component, includeCompositeKeys: boolean = false): boolean {
    if (component.field == null)
        return false;
    const keyFields = getRelevantModelRow(component)?.getMetadata()?.keyFields;
    if (keyFields == null)
        return false;

    if (includeCompositeKeys) {
        return keyFields.includes(component.field);
    } else {
        return keyFields.length === 1 && keyFields[0] === component.field;
    }
}
