export const innerHTML = `<svg viewBox="0 0 108 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1692_1184)">
<path d="M34.2977 7H37.3876V16.9438H34.2977V13.0674H30.5899V16.9438H27.5V7H30.5899V10.8764H34.2977V7Z" fill="black"/>
<path d="M39.8595 7H43.0617V16.9438H39.8595V7Z" fill="black"/>
<path d="M54.6347 9.92134H51.5449V9.24718C51.5449 9.02247 51.4887 8.91011 51.4325 8.85393C51.3763 8.79775 51.2078 8.74157 51.0392 8.74157H49.0729C48.8482 8.74157 48.7359 8.79775 48.6797 8.85393C48.6235 8.91011 48.5673 9.02247 48.5673 9.24718V14.809C48.5673 15.0337 48.6235 15.146 48.6797 15.2022C48.7359 15.2584 48.8482 15.3146 49.0729 15.3146H51.0392C51.264 15.3146 51.3763 15.2584 51.4325 15.2022C51.4887 15.146 51.5449 15.0337 51.5449 14.809V13.1236H49.9156V10.9326H54.6347V15.7079C54.6347 16.5506 54.1853 17 53.3426 17H46.7696C45.9269 17 45.4774 16.6068 45.4774 15.7079V8.29214C45.4774 7.44944 45.8707 7 46.7696 7H53.3988C54.2415 7 54.6909 7.44944 54.6909 8.29214V9.92134H54.6347Z" fill="black"/>
<path d="M63.8484 7H66.9383V16.9438H63.8484V13.0674H60.1405V16.9438H57.0507V7H60.1405V10.8764H63.8484V7Z" fill="black"/>
<path d="M82.837 7H85.8145L82.612 16.9438H79.0165L77.1066 10.1461L75.1965 16.9438H71.601L68.3988 7H71.5448L73.3988 14.191L75.3089 7H78.848L80.7585 14.191L82.837 7Z" fill="black"/>
<path d="M93.511 17L92.9495 15.2022L88.792 15.1461L88.174 16.9438H85.1965L88.9605 7H92.781L96.601 16.9438H93.511V17ZM89.4665 12.955H92.163L90.8145 8.85393L89.4665 12.955Z" fill="black"/>
<path d="M102.444 16.998H99.354V13.7396L95.309 7.0542H98.3425L100.871 12.0542L103.286 7.0542H106.32L102.444 13.7396V16.998Z" fill="black"/>
</g>
<path d="M7.25 5L1.5 19H9L9.64062 13.875H15.3594L16 19H23.5L17.75 5H14.25L14.8906 10.125H10.1094L10.75 5H7.25Z" fill="#F8C617"/>
<defs>
<clipPath id="clip0_1692_1184">
<rect width="79" height="10" fill="white" transform="translate(27.5 7)"/>
</clipPath>
</defs>
</svg>`;
