import { Button, ButtonVariant, ClickEvent, Component, ComponentFactory, Cursor, Image, Label, LabelProps, Panel, TreeNode, TreeNodeProps } from "@mcleod/components";
import { Alignment, VerticalAlignment } from "@mcleod/core";
import { RowPermsSecureItem } from "../models/ModelPermsSecureItem";
import { PermissionsDesigner } from "./PermissionsDesigner";
import { PermissionsMain } from "./PermissionsMain";

export interface PermsMenuTreeNodeProps extends TreeNodeProps {
    title: string;
    page_identifier: string;
    isAction: boolean;
    nestedLayoutPaths: string[];
    permissionsMain: PermissionsMain;
}

export class PermsMenuTreeNode extends TreeNode {
    labelTitle: Label;
    page_identifier: string;
    secureImage: Image;
    groupImage: Image;
    isAction: boolean;
    nestedLayoutPaths: string[];
    permissionsMain: PermissionsMain;
    title: string;

    constructor(props: Partial<PermsMenuTreeNodeProps>) {
        super({ component: new Panel({ fillRow: false, verticalAlign: VerticalAlignment.CENTER }), ...props });
        this.buildUI(this.component as Panel);
    }

    private buildUI(panel: Panel) {
        this.secureImage = new Image({ rowBreak: false, color: "primary", paddingRight: 8 });
        this.groupImage = new Image({ rowBreak: false, color: "primary", paddingRight: 4, height: 20, width: 26 });
        const labelPanel = Panel.createNoMarginNoPaddingPanel({ rowBreak: false, minWidth: 280});
        this.labelTitle = new Label({ id: "labelMenuCaption", text: this.title, cursor: Cursor.POINTER, allowSelect: false });
        labelPanel.add(this.labelTitle);
        panel.add(this.secureImage, this.groupImage, labelPanel);
        this.syncUI(this.rowPermsSecureItem?.get("allow_count"));
        if (this.isAction != true) {
            panel.add(this.createFieldLevelPermsButton(this.page_identifier));
        }
    }

    createFieldLevelPermsButton(path: string) {
        const button = new Button({
            id: "buttonDetail",
            imageName: "detail",
            tooltip: "Open for editing field-level permissions",
            variant: ButtonVariant.round,
            rowBreak: false
        });
        button.addClickListener((event: ClickEvent) => {
            this.getTree().selectedNode = this;
            this.showFieldLevelDesigner(path, event);
        });
        return button;
    }

    syncUI(allowCount: number = this.rowPermsSecureItem?.get("allow_count")) {
        if (this.groupImage == null)
            return;
        this.groupImage.name = "threePeople";
        if (this.rowPermsSecureItem == null) {
            this.secureImage.name = "visibilityOn";
        } else {
            if (allowCount > 0)
                this.groupImage.name = "threePeopleBlueMiddle";
            this.secureImage.name = "visibilityOff";
        }

        if (this.isAction != true) {
            this.labelTitle.color = this.hasFieldLevelPerms ? "error.light" : null;
            this.labelTitle.tooltipCallback = () => {
                const tooltipProps =  {  pointerColor: "error.light", position: Alignment.RIGHT };
                const panelProps = { themeKey: "quickInfo", borderLeftColor: "error.light", color: null };
                return this.labelTitle.showTooltip(this.createTitleTooltipPanel(), tooltipProps, panelProps);
            };
        }
    }

    private createTitleTooltipPanel(): Panel {
        const nestedLayoutsWithFieldLevelPerms = this.nestedLayoutsWithFieldLevelPerms;
        const hasFieldLevelPerms = nestedLayoutsWithFieldLevelPerms?.length > 0 || this.hasDirectFieldLevelPerms;
        if (hasFieldLevelPerms != true)
            return null;
        const panel = Panel.createNoMarginNoPaddingPanel();
        if (nestedLayoutsWithFieldLevelPerms?.length > 0) {
            let suffix = "nested layouts";
            if (this.hasDirectFieldLevelPerms)
                suffix = "this layout and " + suffix;
            panel.add(new Label({ text: `Field-level permissions are set on ${suffix}`, fontBold: true }));

            const labelProps: Partial<LabelProps>[] = [];
            for (const layout of nestedLayoutsWithFieldLevelPerms) {
                labelProps.push({
                    text: layout,
                    tooltip: "Open for editing field-level permissions",
                    color: "primary",
                    onClick: (event:ClickEvent) => this.showFieldLevelDesigner(layout, event)
                });
            }

            panel.add(...ComponentFactory.createBulletedList(labelProps));
        }
        else {
            panel.add(new Label({ text: "Field-level permissions are set on this layout"}));
        }
        return panel;
    }

    get rowPermsSecureItem(): RowPermsSecureItem {
        return this.permissionsMain?.permsById?.[this.page_identifier];
    }

    get hasFieldLevelPerms(): boolean {
        return this.hasDirectFieldLevelPerms || this.nestedLayoutsWithFieldLevelPerms?.length > 0;
    }

    get hasDirectFieldLevelPerms() {
        return this.permissionsMain?.fieldLevelPermsById?.[this.page_identifier] != null
    }

    get nestedLayoutsWithFieldLevelPerms(): string[] {
        return this.nestedLayoutPaths?.filter(layout => this.permissionsMain?.fieldLevelPermsById?.[layout] != null) ?? [];
    }

    showFieldLevelDesigner(path: string, event: ClickEvent) {
        const comp = event.target as Component;
        let labelOrButton: Label | Button;
        if (comp instanceof Button || comp instanceof Label) {
            labelOrButton = comp;
            labelOrButton.busy = true;
        }
        PermissionsDesigner.slideUp(path, () => {
            this.permissionsMain.fieldLevelPermsChanged(path);
        }).finally(() => {
            if (labelOrButton != null)
                labelOrButton.busy = false;
        });
    }
}
