
import { ChangeEvent, ClickEvent, DataSourceExecutionEvent, Layout, Snackbar, TableRowCreationEvent, TableRowDisplayEvent } from "@mcleod/components";
import { CustomVersionInterface, CustomVersionUtil } from "../common/CustomVersionUtil";
import { AbstractModelDesigner } from "./AbstractModelDesigner";
import { AutogenLayoutModelVersions } from "./autogen/AutogenLayoutModelVersions";

export class ModelVersions extends AutogenLayoutModelVersions implements CustomVersionInterface {
    public switchListenersActive = false;
    public enclosingDesigner: AbstractModelDesigner;
    public positionedBaseComponentsFromRow = false;

    override onLoad(): void | Promise<void> {
        CustomVersionUtil.onLoad(this);
    }

    positionBaseComponents() {
        CustomVersionUtil.positionBaseComponents(this);
    }

    /** This is an event handler for the onChange event of switchIsActive.  */
    switchIsActiveOnChange(event: ChangeEvent) {
        CustomVersionUtil.switchIsActiveOnChange(event, this);
    }

    /** This is an event handler for the afterExecution event of sourceCustomUiLayout.  */
    sourceCustomModelDefinitionAfterExecution(event: DataSourceExecutionEvent) {
        CustomVersionUtil.doAfterExecute(event, this);
    }

    /** This is an event handler for the onClick event of imageDelete.  */
    async imageDeleteOnClick(event: ClickEvent) {
        CustomVersionUtil.imageDeleteOnClick(event, this);
    }

    /** This is an event handler for the onRowDisplay event of tableVersions.  */
    tableVersionsOnRowDisplay(event: TableRowDisplayEvent) {
        CustomVersionUtil.tableVersionsOnRowDisplay(event, this);
    }

    /** This is an event handler for the onRowCreate event of tableVersions.  */
    tableVersionsOnRowCreate(event: TableRowCreationEvent) {
        this.switchListenersActive = false;
    }

    /** This is an event handler for the onClick event of imageEdit.  */
    imageEditOnClick(event: ClickEvent) {
       CustomVersionUtil.imageEditOnClick(event, this);
    }

    /** This is an event handler for the onClick event of buttonOpenBaseVersion.  */
    buttonOpenBaseVersionOnClick(event: ClickEvent) {
        CustomVersionUtil.buttonOpenBaseVersionOnClick(event, this);
    }

    /** This is an event handler for the onChange event of switchBaseVersionActive.  */
    switchBaseVersionActiveOnChange(event: ChangeEvent) {
        CustomVersionUtil.switchBaseVersionActiveOnChange(event, this);
    }

    /** This is an event handler for the onClick event of imageUpgradeResults. */
    async imageUpgradeResultsOnClick(event: ClickEvent) {
        Snackbar.showSnackbar("This feature is not yet implemented.");
    }

    public static showSlideout(designer: AbstractModelDesigner) {
        CustomVersionUtil.showSlideout(designer, Layout.getLayout("designer/model/ModelVersions") as ModelVersions);
    }
}
