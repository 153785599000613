import { ArrayUtil } from "@mcleod/core";
import { DropdownItem } from "./DropdownItem";
import { Textbox } from "./Textbox";
import { DataSourceMode } from "../../databinding/DataSource";
import { DesignableObjectLogManager } from "../../logging/DesignableObjectLogManager";

type DropdownItemUpdate = {
    selections: DropdownItem[],
    newItems: DropdownItem[],
    removedItems: DropdownItem[]
 };

const log = DesignableObjectLogManager.getLogger("components.Textbox.DropdownItemResolver");

export class DropdownItemResolver {

    public static createDropdownItems(items: any[]): DropdownItem[] {
        if (Array.isArray(items) === false) {
            return null;
        }

        let result = [];
        const firstItem = ArrayUtil.getFirstElement(items);
        if (firstItem instanceof DropdownItem) {
            result = items as DropdownItem[];
        }
        if (typeof firstItem === "string") {
            result = items.map(stringValue => new DropdownItem(stringValue))
        }
        if (firstItem?.value != null && firstItem?.displayValue != null) {
            result = items.map(item => new DropdownItem(item.value, item.displayValue));
        }
        if (result == null) {
            return null;
        }

        const seenValues = new Set();
        return result.filter(item => {
            if (item.value == null || seenValues.has(item.value)) {
                return false;
            }
            seenValues.add(item.value);
            return true;
        });
    }


    public static resolveItems(textbox: Textbox, includeBlankIfNeeded = false): DropdownItem[] {
        let result = [];
        if (typeof textbox.items === "function") {
            result = DropdownItemResolver.createDropdownItems(textbox.items());
        } else {
            result = textbox.items;
        }
        if (includeBlankIfNeeded && DropdownItemResolver.includeBlankItem(textbox)) {
            const item = textbox.inDataSourceMode(DataSourceMode.SEARCH)
                ? DropdownItem.BLANK_SEARCH_ITEM
                : DropdownItem.BLANK_ITEM;

            result = [item, ...result];
        }
        return result;
    }

    public static includeBlankItem(textbox: Textbox): boolean {
        return textbox.allowDropdownBlank && (!textbox.required || textbox.inDataSourceMode(DataSourceMode.SEARCH));
    }


    public static determineUpdatedItems(textbox: Textbox, value: DropdownItem[]): DropdownItemUpdate {
        const resolvedItems = DropdownItemResolver.resolveItems(textbox, textbox.inDataSourceMode(DataSourceMode.SEARCH));
        const selections = DropdownItem.findMatches(resolvedItems, value ?? []);
        if (textbox.allowDropdownMultiSelect !== true && selections.length > 1) {
            log.debug(textbox, "Multiple items selected in single-select dropdown.  Only the first item will be selected.");
            selections.splice(1);
        }

        const newItems = selections?.filter(item => !textbox.selectedItems?.includes(item));
        const removedItems = textbox.selectedItems?.filter(item => !selections.includes(item));

        if (ArrayUtil.areEmptyArrays(newItems, removedItems)) {
            return undefined;
        }

        return { selections, newItems, removedItems };
    }
}
