import { AutogenModelPermsAllowDeny, AutogenRowPermsAllowDeny } from "./autogen/AutogenModelPermsAllowDeny";

export class ModelPermsAllowDeny extends AutogenModelPermsAllowDeny<RowPermsAllowDeny> {
    constructor() {
        super(RowPermsAllowDeny);
    }
}

export class RowPermsAllowDeny extends AutogenRowPermsAllowDeny {
}
