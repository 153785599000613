import {
    ChangeEvent, ChartDataPoint, ChartDataset, ClickEvent, Component, ComponentCreator, Container, Image, Label,
    Layout, List, MouseEvent, Panel
} from "@mcleod/components";
import { ModelRow, Navigation, StringUtil } from "@mcleod/core";
import { RequestList } from "./RequestList";
import { AutogenLayoutChartLogReaderStat } from "./autogen/AutogenLayoutChartLogReaderStat";

interface ReaderStat {
    name: string;
    value: number;
}

export class ChartLogReaderStat extends AutogenLayoutChartLogReaderStat {
    private stats = {
        stats_by_endpoint: "Activity by Endpoint",
        stats_by_user: "Activity by User",
        stats_by_thread: "Activity by Thread"
    };

    private statTypes = {
        dbTime: { caption: "Database time", selected: true },
        dbCount: { caption: "Database statements", selected: false },
        time: { caption: "Total time", selected: false },
        count: { caption: "Total count", selected: false },
    };

    private _data: ModelRow;
    private _selectedStat: string = "stats_by_endpoint";
    private _topX = 10;
    public statClickListener: (statName: string) => void;
    public detailContainer: Container;
    private requestList: RequestList;
    public logFileName: string;

    override onLoad() {
        this.buttonTopXDropdown.dropdownItems = () => this.getConfigOptions();
        this.buttonTopXDropdown.dropdownListProps = { maxHeight: null }; // let the list be as tall as it wants to
    }

    private getConfigOptions(): ComponentCreator[] {
        const result: ComponentCreator[] = [];
        Object.values(this.statTypes).forEach(type => {
            result.push(this.createCheckable(type.caption, type.selected, () => {
                type.selected = !type.selected;
                this.displayLogData();
            }));
        });
        result.push(List.SEPARATOR);
        Object.entries(this.stats).map(([statName, caption]) => {
            result.push(this.createCheckable(caption, this.selectedStat === statName, () => this.selectedStat = statName));
        });
        result.push(List.SEPARATOR);
        for (const top of [5, 10, 25, 5000])
            result.push(this.createCheckable(top === 5000 ? "All" : "Top " + top, this.topX === top, () => this.topX = top))
        result.push(List.SEPARATOR);
        result.push(this.createCheckable("New window", false, () => this.popout()));
        return result;
    }

    async popout() {
        const screen = window.screen as any;
        const left = screen.availLeft + (screen.availWidth / 2) - 500;
        const top = screen.availTop + (screen.availHeight / 2) - 350;
        const openedWindow = await Navigation.navigateTo("common/logreader/LogReaderPopout", { newTab: true, left, top, height: 700, width: 1000, windowDecorators: false });
        openedWindow["passedLogReaderData"] = this._data;
    }

    private createCheckable(text: string, checked: boolean, onClick: () => void) {
        const result = new Panel();
        result.add(new Image({ name: checked ? "check" : null, rowBreak: false, color: "subtle.dark", strokeWidth: 3 }), new Label({ text }));
        result.addClickListener(onClick);
        return result;
    }

    private createDropdown(text: string, clickHandler: (event: MouseEvent) => void): Component {
        return new Label({ text, onClick: (event: MouseEvent) => clickHandler(event) });
    }

    private get topX(): number {
        return this._topX;
    }

    private set topX(value: number) {
        this._topX = value;
        this.displayLogData();
    }

    get data(): ModelRow {
        return this._data;
    }

    set data(data: ModelRow) {
        this._data = data;
        this.displayLogData();
    }

    get selectedStat(): string {
        return this._selectedStat;
    }

    set selectedStat(value: string) {
        this._selectedStat = value;
        this.displayLogData();
    }

    get statData(): ReaderStat[] {
        return this.data.get(this.selectedStat);
    }

    public displayLogData() {
        this.chart.removeAllDatasets();
        if (this.data == null) {
            this.chart.renderChart();
            return;
        }
        if (this.requestList != null && this.detailContainer != null) {
            this.detailContainer.remove(this.requestList);
            this.requestList = null;
        }
        this.labelHeading.text = this.stats[this.selectedStat];
        this.chart.yAxisProps.title = { text: "Time (seconds)", display: true };
        this.chart.scaleProps = {
            ...this.chart.scaleProps,
            countAxis: {
                id: "countAxis",
                position: "right",
                grid: { drawOnChartArea: false },
                title: { text: "Count", display: true }
            }
        };
        for (const [statType, statTypeInfo] of Object.entries(this.statTypes))
            if (statTypeInfo.selected)
                this.displayLogStatType(statType);
        const hasTimes = this.statTypes.dbTime.selected || this.statTypes.time.selected;
        const hasCounts = this.statTypes.dbCount.selected || this.statTypes.count.selected;
        this.chart.scaleProps.y.display = hasTimes;
        this.chart.scaleProps.countAxis.display = hasCounts;
        this.chart.scaleProps.countAxis.position = hasTimes && hasCounts ? "right" : "left";
        this.chart.renderDefaultData = false; // this prop will go away once we take the default data out of Chart
        this.chart.renderChart();
    }

    private displayLogStatType(statType: string) {
        const data: ChartDataPoint[] = [];
        const isTime = statType === "dbTime" || statType === "time";
        for (const item of this.statData) {
            const label = this.selectedStat === "stats_by_endpoint" ? StringUtil.stringAfterLast(item.name, "/") : item.name;
            data.push({ x: label, y: isTime ? Math.trunc(item[statType] / 1000) : item[statType] });
            if (data.length >= this.topX)
                break;
        }
        const dataset = new ChartDataset();
        dataset.chartData = data;
        if (!isTime)
            dataset.axisId = "countAxis";
        dataset.label = this.statTypes[statType].caption;
        this.chart.addDataset(dataset);
    }

    /** This is an event handler for the onChange event of all four checkboxes that toggle what should be displayed.  */
    optionsChanged(_event: ChangeEvent) {
        this.displayLogData();
    }

    /** This is an event handler for the onClick event of chart.  */
    chartOnClick(event: ClickEvent) {
        const points = this.chart.getDatasetPointsForEvent(event);
        if (points?.length > 0) {
            const index = points[0].index;
            const statName = this.statData[index].name;
            if (this.statClickListener != null)
                this.statClickListener(statName);
            this.displayDetail(statName);
        }
    }

    private displayDetail(statName: string) {
        if (this.detailContainer == null)
            return;
        let filterKey = "endpoint"
        if (this.selectedStat === "stats_by_thread")
            filterKey = "thread";
        else if (this.selectedStat === "stats_by_user")
            filterKey = "user";
        const filter = { [filterKey]: statName }; // won't always be endpoint
        if (this.requestList == null) {
            this.requestList = Layout.getLayout("common/logreader/RequestList", { filter } as any) as RequestList;
            this.requestList.logFileName = this.logFileName;
            this.detailContainer.add(this.requestList);
        } else
            this.requestList.filter = filter;
    }
}
