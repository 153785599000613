import { ObjectUtil } from "../../ObjectUtil";
import { ThemeCommonComponents } from "../common/ThemeCommonComponents";
import { ThemeCommonFont } from "../common/ThemeCommonFont";
import { ThemeCommonPage } from "../common/ThemeCommonPage";
import { ThemeDarkPalette } from "./ThemeDarkPalette";

/**
 * First, we establish the colors that are available for this theme (light/dark/etc) to use and then we use those
 * colors in higher level components
*/
export const ThemeDark = {
    // first add the default palette (the standard colors that have darkest, darker, dark)
    ...ThemeDarkPalette,

    // then add other colors that don't have the standard darkest -> lightest members
    defaultColor: "#D4D4D4",
    defaultBackground: "#1E1E1E",
    backgroundSubtle: "#181818",
    background2: "#e6e9e0",  // used by main menu (and presumably other things later where we need the background to stand apart from the main background)
    "background2-reverse": "#111",
    background3: "#666", // used by tooltips
    background4: "#dcefff88", //used by component demo
    background5: "#333", //used by tooltips
    background6: "#2a2a2a",  //used by some panels
    headerBackground: "#3E3E3E",
    headerColor: "#D4D4D4",
    buttonHoverColor: "#ababab",
    buttonFocusColor: "#ababab",
    strokePrimary: "#979797",
    strokeSecondary: "#cdcdcd",

    // members defined in component.palette are used by specific components in ThemeCommonComponents
    component: {
        palette: {
            checkbox: { // this came from ThemeDefault. It should be adjusted for dark theme
                color: "primary",
                hover: {
                    backgroundColor: "#DEDEDE"
                }
            },
            clock: {
                backgroundColor: "#000000AA",
                hand: {
                    backgroundColor: "primary.light",
                },
                selection: {
                    backgroundColor: "primary.light"
                },
                numbers: {
                    color: "#eee"
                }
            },
            overlay: {
                backgroundColor: "#ffffff44",
            },
            snackbar: {
                color: "#fff",
                backgroundColor: "#555",
            },
            table: {
                editRow: {
                    textbox: {
                        backgroundColor: "#444",
                    }
                },
                headingRow: {
                    color: "#b5cea8",
                    backgroundColor: "#3E3E3E",
                },
                hover: {
                    backgroundColor: "#55555520"
                },
                selection: {
                    backgroundColor: "#555555",
                }
            },
            textbox: {
                button: {
                    color: "#666"
                },
                caption: {
                    color: "#999"
                },
                disabled: {
                    color: "#999",
                    backgroundColor: "#444",
                },
                enabled: {
                    color: "#eee",
                    backgroundColor: "#5e5e5e"
                }
            }
        }
    },

    // Now that those colors are defined, they are usable in higher level components
    ...ObjectUtil.deepCopy(ThemeCommonFont),
    ...ObjectUtil.deepCopy(ThemeCommonComponents),
    ...ObjectUtil.deepCopy(ThemeCommonPage),
};
