import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenPermsAllowDenyEndpoint = "common/perms-allow-deny";

export class AutogenModelPermsAllowDeny<RowType extends ModelRow> extends Endpoint<RowType, RowPermsAllowDenyFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenPermsAllowDenyEndpoint, ctor);
    }
}

export class AutogenRowPermsAllowDeny extends ModelRow<RowPermsAllowDenyFields> {
    constructor() {
        super(AutogenPermsAllowDenyEndpoint);
    }
}

export interface RowPermsAllowDenyFields {
    item_name: string;
    screen_class: string;
    security_type: string;
    user_or_group_flag: string;
    user_or_group_id: string;
    grant_deny_flag: string;
    name: string;
    top_level_only: boolean;
}
