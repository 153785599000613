import { StringUtil, UserSettings } from "@mcleod/core";

export class SupportEmailProvider {
    public static get(): SupportEmailProvider {
        return _singleton;
    }

    public static set(provider: SupportEmailProvider) {
        _singleton = provider;
    }

    public shouldDisplayPageHeaderSupportEmailLink(): boolean {
        return false;
    }

    public getMailToLink(): string {
        return null;
    }

    doOnSupportEmailClick() {
        const mailToLink = SupportEmailProvider.get().getMailToLink();
        if (StringUtil.isEmptyString(mailToLink) !== true)
            window.location.href = mailToLink;
    }

    getSupportEmailSubject(): string {
        const userSettings = UserSettings.get();
        let company = userSettings.company_scac;
        if (StringUtil.isEmptyString(company) === true)
            company = userSettings.company_name;
        return encodeURIComponent("Support Request" + (StringUtil.isEmptyString(company) !== true ? (": " + company) : ""));
    }

    getSupportEmailBody(): string {
        //User id, login id, Name, phone, extension
        const userSettings = UserSettings.get();
        let body = "Please tell us how we can help you by entering your message here:\n\n\n";
        body += "\nUser ID: " + userSettings.user_id;
        if (StringUtil.isEmptyString(userSettings.user_login_id) !== true && userSettings.user_id !== userSettings.user_login_id)
            body += "\nLogin ID: " + userSettings.user_login_id;
        if (StringUtil.isEmptyString(userSettings.user_name) !== true)
            body += "\nName: " + userSettings.user_name;
        if (StringUtil.isEmptyString(userSettings.user_phone) !== true)
            body += "\nPhone: " + userSettings.user_phone;
        if (StringUtil.isEmptyString(userSettings.user_extension) !== true)
            body += "\nExtension: " + userSettings.user_extension;
        body += "\n\nCurrent Screen:\n" + window.location.href + "\n"
        return encodeURIComponent(body);
    }
}

let _singleton: SupportEmailProvider;