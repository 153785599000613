import { DecoratorType } from "./DecoratorType";
import { Layout } from "../../components/layout/Layout";
import { Panel } from "../../components/panel/Panel";
import { DataSourceMode } from "../../databinding/DataSource";
import { AdvancedDecorator } from "./AdvancedDecorator";
import { CrudDecorator } from "./CrudDecorator";
import { ReportDecorator } from "./ReportDecorator";

export class DecoratorRouter {
    /**
     * This determines which decorator should be used for a page given the page's properties and the current browser URL
     * @param {Layout} layout
     * @returns
     */
    public static decoratePage(layout: Layout, searchParams?: string | URLSearchParams): Panel {
        if (searchParams === undefined) // check for undefined - allow passing null to indicate "I don't want any searchParams"
            searchParams = window.location.search;
        if (typeof searchParams === "string")
            searchParams = new URLSearchParams(searchParams);
        const props = { layout: layout };
        if (searchParams != null)
            searchParams.forEach((value: string, key: string, parent: URLSearchParams) => props[key] = value);

        let decorator: string;
        if (layout.allowDecoratorOverride !== false)
            decorator = searchParams?.get("mode");
        if (decorator == null)
            decorator = layout.defaultDecorator;

        return DecoratorRouter.createDecorator(decorator, layout, props);
    }

    private static createDecorator(decorator: string, layout: Layout, props: any): Panel {
        if (decorator == null)
            return layout;
        switch (decorator) {
            case DecoratorType.ADVANCED:
                return new AdvancedDecorator(props);
            case DecoratorType.SEARCH:
                return new CrudDecorator({ mode: DataSourceMode.SEARCH, ...props });
            case DecoratorType.ADD:
                return new CrudDecorator({ mode: DataSourceMode.ADD, ...props });
            case DecoratorType.UPDATE:
                return new CrudDecorator({ mode: DataSourceMode.UPDATE, ...props });
            case DecoratorType.REPORT:
                return new ReportDecorator({ ...props });
            default:
                throw new Error("Invalid page decorator " + decorator);
        }
    }
}
