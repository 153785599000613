import { Alignment } from "@mcleod/core";
import { ComponentPropDefinitionUtil, ComponentPropDefinitions } from "../../base/ComponentProps";
import { PanelPropDefinitions, PanelProps } from "../panel/PanelProps";
import { PropType } from "../../base/PropType";

export interface ExcludableTextboxProps extends PanelProps {
    excludeAlign: Alignment;
}

export class ExcludableTextboxPropDefinitions {
    private static excludableTextboxPropDefs: ComponentPropDefinitions;

    public static getDefinitions(): ComponentPropDefinitions {
        if (this.excludableTextboxPropDefs == null) {
            this.excludableTextboxPropDefs = {
                ...PanelPropDefinitions.cloneDefs(),
                excludeAlign: {
                    type: PropType.string,
                    dropdownProps: { items: [Alignment.RIGHT, Alignment.BOTTOM] },
                    defaultValue: Alignment.BOTTOM,
                    visibleInMcLeodTailor: true,
                    description: "This controls the position of the 'Exclude' checkbox relative to the filter textbox'."
                }
            };
            ComponentPropDefinitionUtil.populateComponentPropNames(this.excludableTextboxPropDefs);
            this.excludableTextboxPropDefs.searchOnly.defaultValue = true;
        }
        return this.excludableTextboxPropDefs;
    }
}
