import { Color, Keys, LogManager, getThemeColor } from "@mcleod/core";
import { ImageName } from "@mcleod/images";
import { Component } from "../../base/Component";
import { ComponentTypes } from "../../base/ComponentTypes";
import { ChangeEvent, ChangeListener } from "../../events/ChangeEvent";
import { DomEvent } from "../../events/DomEvent";
import { KeyEvent } from "../../events/KeyEvent";
import { KeyModifiers } from "../../events/KeyModifiers";
import { TreeNode } from "./TreeNode";
import { TreePropDefinitions, TreeProps } from "./TreeProps";
import { TreeStyles } from "./TreeStyles";

const log = LogManager.getLogger("components.tree.Tree");
const treeHandledKeys = [Keys.ARROW_DOWN, Keys.ARROW_UP, Keys.ARROW_LEFT, Keys.ARROW_RIGHT];
const _changeListenerDef = { listName: "_changeListeners", domEventName: "input", eventCreatorFunction: (component, event) => new ChangeEvent(component, component.text, component.text, event) };

export class Tree<NodeType extends TreeNode = TreeNode> extends Component implements TreeProps {
    private rootNode: TreeNode;
    private _selectedNode: NodeType;
    private _selOriginalBackground: Color;
    private _selOriginalColor: Color;
    private _selectedNodeBackgroundColor: Color;
    private _selectedNodeColor: Color;
    public adHocKeyHandler: (event: KeyEvent) => void;
    public nodeLeafImageName: ImageName;
    public nodeDefaultImageName: ImageName;
    public nodeExpandedImageName: ImageName;
    public nodeCollapsedImageName: ImageName;

    constructor(props?: Partial<TreeProps>) {
        super("ul", props);
        this._element.tabIndex = 0;
        this._element.className += " " + TreeStyles.treeBase;
        if (props == null)
            props = {};
        if (props.borderColor === undefined)
            props.borderColor = "strokeSecondary";
        if (props.borderWidth === undefined)
            props.borderWidth = 1;
        this.rootNode = new TreeNode({ id: "rootNode", parent: this, tree: this });
        this.addKeyDownListener((event: KeyEvent) => this.handleKey(event));
        this._element.appendChild(this.rootNode._element);
        this.setProps(props);
    }

    setProps(props: Partial<TreeProps>) {
        super.setProps(props);
    }

    public get selectedNodeBackgroundColor(): Color {
        return this._selectedNodeBackgroundColor || this.getPropertyDefinitions().selectedNodeBackgroundColor.defaultValue;
    }

    public set selectedNodeBackgroundColor(value: Color) {
        this._selectedNodeBackgroundColor = value;
    }

    public get selectedNodeColor(): Color {
        return this._selectedNodeColor || this.getPropertyDefinitions().selectedNodeColor.defaultValue;
    }

    public set selectedNodeColor(value: Color) {
        this._selectedNodeColor = value;
    }

    get selectedNode(): NodeType {
        return this._selectedNode;
    }

    set selectedNode(node: NodeType) {
        this._setSelectedInternal(node, null);
    }

    public _setSelectedInternal(node: NodeType, domEvent: DomEvent) {
        const oldValue = this._selectedNode;
        if (oldValue === node || node?.isRoot())
            return;
        if (this._selectedNode != null) {
            this._selectedNode._component.backgroundColor = this._selOriginalBackground;
            this._selectedNode._component.color = this._selOriginalColor;
        }
        this._selectedNode = node;
        if (node != null) {
            this._selOriginalBackground = node._component.backgroundColor;
            this._selOriginalColor = node._component.color;
            node._component.backgroundColor = getThemeColor(this.selectedNodeBackgroundColor);
            node._component.color = getThemeColor(this.selectedNodeColor);
            const event = new ChangeEvent(this, oldValue, node, domEvent);
            this.fireListeners(_changeListenerDef, event);
        }
    }

    /**
     *
     * @param {KeyEvent} event
     */
    handleKey(event: KeyEvent) {
        if (this.adHocKeyHandler != null) {
            this.adHocKeyHandler(event);
            if (event.defaultPrevented)
                return;
        }
        if (event.altKey || event.ctrlKey)
            return;
        if (treeHandledKeys.includes(event.key)) {
            const root = this.getRootNode();
            const sel = this.selectedNode;
            if (root.getChildCount() > 0) {
                if (sel == null)
                    this._setSelectedInternal(root.getChild(0) as NodeType, event.domEvent);
                else {
                    const parent = sel.parent as NodeType;
                    const parentIndex = sel.parent.indexOfChild(sel);
                    const noModifiers: KeyModifiers = { ctrlKey: false, shiftKey: false, altKey: false };
                    if (event.isKey(Keys.ARROW_DOWN, noModifiers) === true)
                        this.handleArrowDown(sel, parent, parentIndex, event.domEvent);
                    else if (event.isKey(Keys.ARROW_UP, noModifiers) === true)
                        this.handleArrowUp(sel, parent, parentIndex, event.domEvent);
                    else if (event.isKey(Keys.ARROW_RIGHT, noModifiers) === true)
                        this.setNodeExpanded(sel, true, event.domEvent);
                    else if (event.isKey(Keys.ARROW_LEFT, noModifiers) === true)
                        this.setNodeExpanded(sel, false, event.domEvent);
                }
            }
            event.preventDefault();
            event.stopPropagation();
        }
    }

    handleArrowDown(sel: NodeType, parent: NodeType, parentIndex: number, event: KeyboardEvent) {
        if (sel.expanded && sel.getChildCount() > 0)
            this._setSelectedInternal(sel.getChild(0) as NodeType, event);
        else if (parentIndex < parent.getChildCount() - 1)
            this._setSelectedInternal(parent.getChild(parentIndex + 1) as NodeType, event);
        else if (!(parent.isRoot()) && parent.parentIndex < parent.parent.getChildCount() - 1)
            this._setSelectedInternal(parent.parent.getChild(parent.parentIndex + 1) as NodeType, event);
        this.scrollToSelection();
    }

    handleArrowUp(sel: NodeType, parent: NodeType, parentIndex: number, event: KeyboardEvent) {
        if (parentIndex === 0) {
            if (!parent.isRoot())
                this._setSelectedInternal(parent, event);
        }
        else {
            const nextSib = parent.getChild(parentIndex - 1) as NodeType;
            if (nextSib.expanded && nextSib.getChildCount() > 0)
                this._setSelectedInternal(nextSib.getChild(nextSib.getChildCount() - 1) as NodeType, event);
            else
                this._setSelectedInternal(nextSib, event);
        }
        this.scrollToSelection();
    }

    scrollToSelection() {
        if (this.selectedNode != null)
            this.selectedNode.scrollIntoView();
    }

    /**
     *
     * @param {NodeType} node
     * @param {bool} value
     * @returns
     */
    setNodeExpanded(node: NodeType, value: boolean, event: KeyboardEvent) {
        if (value && node.getChildCount() > 0)
            node.expanded = true;
        else if (!value) {
            while (node.isRoot() !== true) {
                if (node.expanded && node.getChildCount() > 0) {
                    node.expanded = false;
                    this._setSelectedInternal(node, event);
                    break;
                }
                node = node.parent as NodeType;
            }
        }
    }

    /**
     *
     * @returns {NodeType}
     */
    getRootNode(): TreeNode {
        return this.rootNode;
    }

    /**
     *
     * @param {bool} isLeaf
     * @param {bool} isExpanded
     * @returns {string}
     */
    getDefaultNodeImageName(isLeaf: boolean, isExpanded: boolean): string {
        if (isLeaf)
            return this.nodeLeafImageName || this.nodeDefaultImageName;
        else if (isExpanded)
            return this.nodeExpandedImageName || this.nodeDefaultImageName || "minusInBox";
        else
            return this.nodeCollapsedImageName || this.nodeDefaultImageName || "addInBox";
    }

    /**
     *
     * @param {*} object
     * @param {string} textField
     * @param {string} childField
     * @returns
     */
    makeTreeNodesFromObject(object: any, textField: string, childField: string, nodeCallback?: (node, data) => void): TreeNode {
        if (object == null)
            return null;
        const text = object[textField];
        const children = object[childField];
        if (text != null || (children != null && children.length > 0)) {
            const result = new TreeNode({ tree: this });
            if (text != null)
                result.text = text;
            result.data = object;
            if (nodeCallback != null)
                nodeCallback(result, object);
            for (let i = 0; children != null && i < children.length; i++) {
                const child = this.makeTreeNodesFromObject(children[i], textField, childField, nodeCallback);
                if (child != null)
                    result.addChild(child);
            }
            return result;
        }
        return null;
    }

    /**
     * We can definitely do a better job with performance of this.  Going with brute force for now.
     * This really just doesn't work well.  Maybe remove it?
     *
     * @param {string} filterValue
     */
    filter(filterValue: string, fillFunction: (filterValue: string) => NodeType) {
        this.getRootNode().removeAllChildren();
        const root = fillFunction(filterValue);
        this.filterNode(filterValue, root);
        this.getRootNode().setChildren(root.getChildren());
    }

    /**
      *
      * @param {string} filterValue
      * @param {NodeType} node
    */
    filterNode(filterValue: string, node: NodeType) {
        filterValue = filterValue.toLowerCase();
        for (let i = node.getChildCount() - 1; i >= 0; i--)
            if (node.getChildCount() === 0 && node.getChild(i).text.toLowerCase().indexOf(filterValue) < 0)
                node.removeChild(i);
        if (node.getChildCount() === 0 && node.parent != null)
            node.parent.removeChild(node.parentIndex);
    }

    public addChangeListener(value: ChangeListener) {
        this.addEventListener(_changeListenerDef, value);
    }

    public removeChangeListener(value: ChangeListener) {
        this.removeEventListener(_changeListenerDef, value);
    }

    override getPropertyDefinitions() {
        return TreePropDefinitions.getDefinitions();
    }

    override get serializationName() {
        return "tree";
    }

    override get properName(): string {
        return "Tree";
    }
}

ComponentTypes.registerComponentType("tree", Tree.prototype.constructor);
