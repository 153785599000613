import { CommonDialogs } from "@mcleod/common";
import { Label, Panel } from "@mcleod/components";
import { Api, LogManager, Navigation, StringUtil } from "@mcleod/core";

const log = LogManager.getLogger("common.designer.McLeodTailorAgreement");
const TAILOR_AGREE_STORAGE_KEY = "tailor-agree-date";

export class McLeodTailorAgreementManager {
    public async handleAgreement() {
        if (StringUtil.isEmptyString(sessionStorage.getItem(TAILOR_AGREE_STORAGE_KEY)) !== true)
            return;
        const result = await this.showAgreement();
        if (result !== true)
            Navigation.navigateTo("");
        else
            this.updateTailorAgreementDate();
    }

    private async showAgreement(): Promise<boolean> {
        const content = this.createAgreementContent();
        return CommonDialogs.showYesNo(content, "McLeod Software Tailor Usage Agreement", { yesButtonCaption: "Accept", noButtonCaption: "Refuse" });
    }

    private createAgreementContent(): Panel {
        const result = new Panel({ margin: 0, padding: 0, scrollY: true });
        const labelPreamble = new Label({ wrap: true });
        labelPreamble.text = "The McLeod Tailor functionality provides limited functionality " +
            "through custom configuration of McLeod Software //web products " +
            "for specific business requirements.   Use of McLeod Tailor " +
            "can cause unintended results in the software's designed and tested usage.  " +
            "By your use of McLeod Tailor, you acknowledge the following:";
        result.add(labelPreamble);
        result.add(this.createAgreementSectionHeader("I.  Limited Availability"));
        result.add(this.createAgreementSectionText("The McLeod Tailor feature will not work in every " +
            "screen or in every situation."));
        result.add(this.createAgreementSectionHeader("II. Disruption of Normal Functionality"));
        result.add(this.createAgreementSectionText("Changes made via McLeod Tailor may disrupt " +
            "the base functionality of a screen as provided in the base version of " +
            "McLeod Software //web products.  Any time spent by McLeod diagnosing, assisting, or correcting those " +
            "issues will be subject to standard hourly billing."));
        result.add(this.createAgreementSectionHeader("III. Upgrades"));
        result.add(this.createAgreementSectionText("Upon installation of a new version of McLeod Software //web products, changes made via " +
            "McLeod Tailor may or may not be carried forward to the new version.  Some manual application of changes from " +
            "the previous version into the new version may be required.  Should changes either disrupt the base " +
            "functionality of the new version, or if manual application of changes into the new version is needed, " +
            "adjustment of the fields in McLeod Tailor will be the responsibility of the customer.  " +
            "Assistance provided by McLeod will be subject to standard hourly billing."));
        return result;
    }

    private createAgreementSectionHeader(text: string): Label {
        return new Label({ text, wrap: true, fontBold: true, marginTop: 10, marginBottom: 2 });
    }

    private createAgreementSectionText(text: string): Label {
        return new Label({ text: text, wrap: true });
    }

    private async updateTailorAgreementDate() {
        Api.post("tailor/agreement-date").then(response => {
            const agreeDate = response?.data?.[0].agree_date;
            log.debug("The Tailor agreement date was updated: %o", agreeDate);
            sessionStorage.setItem(TAILOR_AGREE_STORAGE_KEY, agreeDate);
        }).catch(error => {
            log.debug("An error occurred while updating the Tailor agreement date %o", error);
            sessionStorage.removeItem(TAILOR_AGREE_STORAGE_KEY);
        });
    }
}
