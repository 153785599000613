import { ModelRow } from "@mcleod/core";
import { ValidationResult } from "../base/ValidationResult";
import { DataSource } from "../databinding/DataSource";
import { Event, EventListener } from "./Event";

export interface DataSourceValidationListener extends EventListener {
    (event: DataSourceValidationEvent): void;
}

export class DataSourceValidationEvent extends Event {
    private _row: ModelRow;
    private _childDataSources: DataSource[];
    private _validationResults: ValidationResult[];

    constructor(dataSource: DataSource, row: ModelRow, childDataSources: DataSource[], validationResults: ValidationResult[]) {
        super(dataSource, null);
        this._row = row;
        this._childDataSources = childDataSources;
        this._validationResults = validationResults;
    }

    public get dataSource(): DataSource {
        return super.target as DataSource;
    }

    public get row() {
        return this._row;
    }

    public get childDataSources(): DataSource[] {
        return this._childDataSources;
    }

    public get validationResults(): ValidationResult[] {
        return this._validationResults; //add adhoc validation results to this array
    }
}
