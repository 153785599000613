import { Button, Chart, Label, Layout, Panel, } from "@mcleod/components";

export abstract class AutogenLayoutChartLogReaderStat extends Layout {
    buttonTopXDropdown: Button;
    chart: Chart;
    labelHeading: Label;
    panel2: Panel;
    panelContent: Panel;
    panelHeader: Panel;
}
