import { Component, Stepper } from "..";
import { DomEvent } from "./DomEvent";
import { Event, EventListener } from "./Event";

export interface StepListener extends EventListener {
    (event: StepEvent): void;
}

export class StepEvent extends Event {
    public oldIndex: number;
    public oldStep: Component;
    public newIndex: number;
    public newStep: Component;

    constructor(target: Stepper, oldIndex: number, oldStep: Component, newIndex: number, newStep: Component, domEvent?: DomEvent) {
        super(target, domEvent);
        this.oldIndex = oldIndex;
        this.oldStep = oldStep;
        this.newIndex = newIndex;
        this.newStep = newStep;
    }

    isSubmit() {
        return this.newStep == null;
    }

    get userInitiatedChange() {
        return this.domEvent != null;
    }
}
