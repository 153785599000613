import { fil } from "date-fns/locale";
import { Table } from "../components/table/Table";
import { DataSource } from "../databinding/DataSource";
import { DomEvent } from "./DomEvent";
import { Event, EventListener } from "./Event";

export interface TableFilterChangedListener extends EventListener {
    (event: TableFilterChangedEvent): void;
}

export class TableFilterChangedEvent extends Event {
    private _filter: string;

    constructor(table: Table, filter: string, domEvent?: DomEvent) {
        super(table, domEvent);
        this._filter = filter;
    }

    public getFilter() {
        return this._filter;
    }

    public getTable(): Table {
        return this.target as Table;
    }

    public getDataSource(): DataSource {
        return this.getTable().dataSource;
    }
}
