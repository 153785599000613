import { Button, ClickEvent, Layout, PanelProps, PermissionsDefinition, Table, TableRow } from "@mcleod/components";
import { Collection, GeneralSettings, Model } from "@mcleod/core";
import { PermsSecurityType, RowPermsSecureItem } from "../models/ModelPermsSecureItem";
import { ModelUserGroup, RowUserGroup } from "../models/ModelUserGroup";
import { ModelUsers, RowUsers } from "../models/ModelUsers";
import { PanelIndividualSecure, PanelIndividualSecureProps } from "./PanelIndividualSecure";
import { PermsContainer } from "./PermsContainer";
import { AutogenLayoutPanelPermsGrant } from "./autogen/AutogenLayoutPanelPermsGrant";

export interface PanelPermsGrantProps extends PanelProps {
    permsContainer: PermsContainer;
}

export class PanelPermsGrant extends AutogenLayoutPanelPermsGrant implements PanelPermsGrantProps {
    permsContainer: PermsContainer;
    pageIdentifier: string;
    itemName: string;
    securityType: PermsSecurityType;
    users: Collection<RowUsers>;
    userGroups: Collection<RowUserGroup>;
    private _selectedChild: PanelIndividualSecure;

    async onLoad() {
        this.style.transition = "width 200ms";
        this.setProps({ width: 320, fillHeight: true, padding: 0, fillRow: false });
        if (GeneralSettings.get()?.onPremise === false)
            this.users = (await Model.search("auth/auth-users")).mapRowsByField("id");
        else
            this.users = (await new ModelUsers().search({}, "common/UserLookup")).mapRowsByField("id");
        this.userGroups = (await new ModelUserGroup().search()).mapRowsByField("id");
        this.clear();
        this.tableGroups.filterTextbox?.setProps({width: 165, placeholder: "Filter groups"});
        this.tableUsers.filterTextbox?.setProps({width: 165, placeholder: "Filter users"});
    }

    public clear() {
        this.setItemBeingEdited(null, null, null, undefined);
        this.childChanged(null, false);
    }

    public setItemBeingEdited(pageIdentifier: string, itemName: string, permsDefs: PermissionsDefinition[], existingRows: RowPermsSecureItem[]) {
        this._selectedChild = null;
        this.pageIdentifier = pageIdentifier;
        this.itemName = itemName;
        this.panelSecures.removeAll();
        this.labelSelectItem.visible = existingRows === undefined;
        this.panelUserGroups.visible = false;
        if (permsDefs === null || permsDefs.length === 0)
            this.panelSecures.add(this.labelSelectItem);
        else {
            for (const def of permsDefs) {
                const itemName = this.itemName;
                const props: Partial<PanelIndividualSecureProps> = {
                    pageIdentifier: this.pageIdentifier,
                    itemName: itemName,
                    permsGrant: this,
                    secureItem: this.findRowForItem(itemName, def.permsType, existingRows),
                    def,
                    padding: 0,
                    margin: 0
                };

                Layout.getLoadedLayout("common/permissions/PanelIndividualSecure", props).then(panelIndividualSecure => {
                    this.panelSecures.add(panelIndividualSecure);
                    if (this.panelSecures.components.length === permsDefs.length) {
                        this.selectedChild = this.panelSecures.components[0] as PanelIndividualSecure;
                    }
                });
            }
        }
        this.syncHeadingLabel();
    }

    syncHeadingLabel() {
        let suffix = "";
        if (this.itemName != null) {
            suffix = " for " + (RowPermsSecureItem.ENTIRE_SCREEN == this.itemName ? "Entire Page" : this.itemName);
        }
        this.labelHeading.text = "Permissions" + suffix;
    }

    childChanged(row: RowPermsSecureItem, deleted: boolean) {
        const childSecured = this._selectedChild?.switchSecured?.checked ?? false;
        this.buttonToggleUsers.visible = childSecured;
        if (!childSecured) {
            this.panelUserGroups.visible = false;
            this.updateToggleUsersButton();
        }
        if (!childSecured && this.panelUserGroups.visible)
            this.panelUserGroups.visible = false;
        this.permsContainer?.permsChanged(row, deleted);
    }


    findRowForItem(itemName: string, permsType: string, rows: RowPermsSecureItem[]): RowPermsSecureItem {
        for (const row of rows)
            if (row.get("item_name") === itemName && row.get("security_type") === permsType)
                return row;
        return null;
    }

    buttonToggleUsersOnClick(_event) {
        this.panelUserGroups.visible = !this.panelUserGroups.visible;
        this.updateToggleUsersButton();
    }

    private updateToggleUsersButton() {
        if (this.panelUserGroups.visible) {
            this.width = 680;
            this.buttonToggleUsers.setProps({ caption: "Hide users and groups", imageRotation: 90 });
        } else {
            this.width = 348;
            this.buttonToggleUsers.setProps({ caption: "Add allowed users or groups", imageRotation: 270 });
        }
    }

    set selectedChild(child: PanelIndividualSecure) {
        if (this._selectedChild === child)
            return;
        if (this._selectedChild != null)
            this._selectedChild.backgroundColor = "defaultBackground";
        this._selectedChild = child;
        child.backgroundColor = "primary.lightest";
        child.fillUserList();
        this.childChanged(child.secureItem, false);
    }

    get selectedChild(): PanelIndividualSecure {
        return this._selectedChild;
    }

    buttonAddUserOnClick(event: ClickEvent) {
        this.addButtonClicked(event, "U");
    }

    buttonAddGroupOnClick(event: ClickEvent) {
        this.addButtonClicked(event, "G");
    }

    async addButtonClicked(event: ClickEvent, userOrGroup: "U" | "G") {
        if (this.selectedChild == null)
            return;

        const targetButton = event.target as Button;
        const tableRow = TableRow.getContainingTableRow(event.target as Button);
        const table = tableRow.table;

        if (!table.selectedIndexes.includes(tableRow.index)) {
            targetButton.busy = true;
            await this.selectedChild.addAllowed(userOrGroup, tableRow.data.get("id")).finally(() => targetButton.busy = false);
        } else {
            const buttons = [];
            const ids = [];
            for (const tableRow of table.selectedRows) {
                buttons.push(tableRow.findComponentById(targetButton.id) as Button);
                ids.push(tableRow.data.get("id"));
            }
            buttons.forEach(b => b.busy = true);
            await this.selectedChild.addAllowed(userOrGroup, ...ids).finally(() => buttons.forEach(b => b.busy = false));
        }
    }
}
