import { getThemeForKey, Styles } from "@mcleod/core";

export const RadioStyles = Styles.make("chk", () => {
    return {
        radioBase: { display: "flex", alignItems: "center", userSelect: "none" },
        selected: {}, //exists solely so we can tell if the box is selected from the CSS styles (for test automation)
        image: {
            display: "flex", alignItems: "center", outline: "none",
            "&:focus-within": getThemeForKey("radio.styleHoverCircle"),
            "&:hover": getThemeForKey("radio.styleHoverCircle")
        },
        imageDisabled: {
            display: "flex", alignItems: "center", outline: "none",
        },
        indeterminate: {}, //exists solely so we can tell if the box is selected from the CSS styles (for test automation)
    };
});
