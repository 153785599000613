export function setForwardDestination(url: string): void {
    // temporary hack - usually there is no sub URL but there is while we are still coexisting with react app
    // thre might be production environments where we are deployed in a sub url as well?
    if (url?.startsWith("/mcleod-ui/"))
        url = url.substring("/mcleod-ui/".length);
    if (url?.startsWith("mcleod-ui/"))
        url = url.substring("mcleod-ui/".length);
    if (url?.startsWith("/mcleod-ui"))
        url = url.substring("/mcleod-ui".length);
    sessionStorage.setItem("forward-destination", url);
}

export function getForwardDestination(): string {
    const result = sessionStorage.getItem("forward-destination");
    sessionStorage.removeItem("forward-destination");
    return result;
}
