import { SelectionEvent } from "../../events/SelectionEvent";
import { Label } from "../label/Label";
import { LabelProps } from "../label/LabelProps";
import { List } from "../list/List";
import { ListItem } from "../list/ListItem";
import { Table } from "./Table";
import { ExportType, TableExport } from "./TableExport";

export class TableToolsExportOptions {
    private table: Table;
    private commonLabelProps: Partial<LabelProps>;
    private labelExcel: Label;
    private labelCSV: Label;

    constructor(table: Table, commonProps: Partial<LabelProps>) {
        this.table = table;
        this.commonLabelProps = commonProps;
        this.setupExportExcelLabel();
        this.setupExportCSVLabel();
    }

    getDropdownExportItem(): ListItem {
        const labelExport = new Label({
            text: "Export Listing",
            fillRow: true,
            rowBreak: false,
            imageName: "download",
            ...this.commonLabelProps
        });
        const children = [this.labelExcel, this.labelCSV];
        const exportItem = new ListItem(labelExport, children, (event: SelectionEvent) => {
            this.handleExportItemSelect(event);
        });
        return exportItem;
    }

    private handleExportItemSelect(event: SelectionEvent) {
        if (event.domEvent.defaultPrevented === true)
            return;
        const selectedItem = (event.target as List).selectedItem as Label;
        if (selectedItem === this.labelExcel)
            this.export(ExportType.Excel);
        else if (selectedItem === this.labelCSV)
            this.export(ExportType.CSV);
    }

    private setupExportExcelLabel() {
        this.labelExcel = new Label({
            ...this.commonLabelProps,
            id: "labelExportExcel",
            text: "Export as Excel",
            imageName: "exportExcel",
            paddingRight: 8
        });
    }

    private setupExportCSVLabel() {
        this.labelCSV = new Label({
            ...this.commonLabelProps,
            id: "labelExportCSV",
            text: "Export as CSV",
            imageName: "exportCSV",
            paddingRight: 8
        });
    }

    private export(exportType: ExportType) {
        new TableExport(this.table).exportTable(true, exportType);
    }
}