import { LogManager, StringUtil } from "@mcleod/core";
import { Button } from "../../components/button/Button";
import { Panel } from "../../components/panel/Panel";
import { HorizontalSpacer } from "../HorizontalSpacer";
import { SearchButton } from "../SearchButton";
import { Decorator } from "./Decorator";
import { DecoratorProps } from "./DecoratorProps";
import { ReportPreview } from "./ReportPreview";
import { DataSourceExecutionEvent } from "../../events/DataSourceExecutionEvent";
import { DataSourceAction } from "../../databinding/DataSource";
import { Component } from "../../base/Component";
import { DesignableObjectTempState } from "../../base/DesignableObjectTempState";
import { Textbox } from "../../components/textbox/Textbox";

const log = LogManager.getLogger("components.page.ReportDecorator");

/**
 * Decorator whose layout property contains report parameters.  That layout will be nested inside the decorator, which
 * constructs/includes Clear/Search buttons, as well as the ReportPreview panel.
 */
export class ReportDecorator extends Decorator implements DecoratorProps{
    private panelLeft: Panel;
    private panelPreview: ReportPreview;
    private buttonGenerateReport: SearchButton;
    private defaultStateMap: Map<Component, DesignableObjectTempState>;
    private beforeExecutionListenerRef = (event: DataSourceExecutionEvent) => this.mainDataSourceDoBeforeExecution(event);
    private afterExecutionListenerRef = (event: DataSourceExecutionEvent) => this.mainDataSourceDoAfterExecution(event);

    constructor(props?: Partial<DecoratorProps>) {
        super({ fillRow: true, fillHeight: true, ...props });
    }

    override setupPanels(props: Partial<DecoratorProps>) {
        this.createLeftPanel();
        this.createPreviewPanel();
        this.add(this.panelLeft, this.panelPreview);
    }

    private createLeftPanel() {
        this.panelLeft = new Panel({
            id: "reportPanelLeft",
            widthFillWeight: 25,
            fillHeight: true,
            marginRight: 20,
            rowBreak: false
        });
        this.panelLeft.add(this.createButtonPanel());
    }

    private createButtonPanel(): Panel {
        const panelButtons = new Panel({
            id: "reportButtonPanel",
            fillRow: true,
            borderTopWidth: 1,
            paddingTop: 20,
            marginTop: 10,
            borderTopColor: "subtle.light"
        });
        this.createGenerateReportButton();
        panelButtons.add(
            this.createClearButton(),
            new HorizontalSpacer(),
            this.buttonGenerateReport
        );
        return panelButtons;
    }

    private createClearButton(): Button {
        return new Button({
            id: "buttonClearForm",
            caption: "Clear Form",
            rowBreak: false,
            width: 150,
            color: "primary",
            borderWidth: 0,
            onClick: () => this.clearForm()
        });
    }

    private clearForm() {
        if (this.defaultStateMap == null)
            return;
        for (const entry of this.defaultStateMap.entries()) {
            const searchComponent = entry[0];
            const defaultState = entry[1];
            if (searchComponent instanceof Textbox)
                searchComponent.clear();
            searchComponent.setProps(defaultState?.originalProps);
        }
    }

    private loadDefaultStateMap() {
        this.defaultStateMap = new Map<Component, DesignableObjectTempState>();
        const searchComponents = this.buttonGenerateReport?.dataSource?.boundSearchComponents ?? [];
        for (const searchComponent of searchComponents) {
            const defaultStateProperties = this.getDefaultStateProperties(searchComponent);
            const tempState = searchComponent.createStateObject({}, defaultStateProperties);
            this.defaultStateMap.set(searchComponent, tempState);
        }
    }

    private getDefaultStateProperties(component: Component): string[] {
        const result = [ "enabled", "visible" ];
        switch (component.serializationName) {
            case "textbox":
                result.push("text", "dateDefault");
                if ((component as Textbox).items != null)
                    result.push("selectedItem");
                break;
            case "checkbox":
            case "switch":
                result.push("checked");
                break;
            default:
                break;
        }
        return result;
    }

    private createGenerateReportButton() {
        this.buttonGenerateReport = new SearchButton({
            id: "buttonGenerateReport",
            caption: "Generate Report",
            rowBreak: false,
            backgroundColor: "primary",
            width: 150,
            color: "default.reverse",
            default: true
        });
    }

    private createPreviewPanel() {
        this.panelPreview = new ReportPreview(this.getLayoutReportName(), { widthFillWeight: 75 });
    }

    override addLayout() {
        this.layout.setProps({
            fillHeight: true,
            fillRow: true,
            scrollY: true
        });
        this.panelLeft.insert(this.layout, 0);
    }

    layoutLoaded() {
        if (StringUtil.isEmptyString(this.layout.id) === true)
            this.layout.id = "layoutReportParams";
        if (this.layout.padding === 0)
            this.layout.padding = 4;
        this.validateLayoutDataSource();
        this.buttonGenerateReport.dataSource = this.layout.mainDataSource;
        this.layout.mainDataSource.addBeforeExecutionListener(this.beforeExecutionListenerRef);
        this.layout.mainDataSource.addAfterExecutionListener(this.afterExecutionListenerRef);
        this.panelPreview.reportName = this.getLayoutReportName();
        this.loadDefaultStateMap();
    }

    private getLayoutReportName(): string {
        const layoutReportName = this.layout["reportName"];
        if (typeof layoutReportName !== "string" || StringUtil.isEmptyString(layoutReportName) === true)
            return "";
        return layoutReportName;
    }

    private validateLayoutDataSource() {
        if (this.layout.mainDataSource == null) {
            log.debug("The report parameter layout needs to specify its main DataSource (that will be used when searching).");
            throw new Error("The report parameter layout is not properly configured for report generation.")
        }
    }

    private mainDataSourceDoBeforeExecution(event: DataSourceExecutionEvent) {
        if (event.getAction() === DataSourceAction.SEARCH)
            this.panelPreview.beginSearch();
    }

    private mainDataSourceDoAfterExecution(event: DataSourceExecutionEvent) {
        if (event.getAction() === DataSourceAction.SEARCH)
            this.panelPreview.endSearch(event.dataSource.data?.[0]);
    }
}
