export interface PermissionsDefinition {
    permsType: string;
    description: string;
    availableToAllDescription: string;
    availableToNoneDescription: string;
    availableToSomeDescription?: string;
}

type PermConstantKey = "VIEW_SECURITY"| "EDIT_SECURITY" | "ENTIRE_SCREEN" | "ACTION";

export const PermDefinitionConstants: Record<PermConstantKey, PermissionsDefinition> = {
    VIEW_SECURITY: {
        permsType: "V",
        description: "View security",
        availableToAllDescription: "This item is visible to everyone",
        availableToNoneDescription: "This item is hidden from everyone"
    },
    EDIT_SECURITY: {
        permsType: "E",
        description: "Edit security",
        availableToAllDescription: "Everyone can edit this item",
        availableToNoneDescription: "This item is read-only to everyone"
    },
    ENTIRE_SCREEN: {
        permsType: "V",
        description: "View security",
        availableToAllDescription: "Everyone can see this page",
        availableToNoneDescription: "No one can see this page"
    },
    ACTION:{
        description: "Execute security",
        permsType: "V",
        availableToAllDescription: "Everyone can execute this action",
        availableToNoneDescription: "No one can execute this action"
    }
}
