/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelState.ts                         *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "common/state";

export class AutogenModelState<RowType extends ModelRow> extends Endpoint<RowType, RowStateFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowState extends ModelRow<RowStateFields> {
    constructor() {
        super(AutogenUserSavedSearchEndpoint);
    }
}

export interface RowStateFields {
    company_id: string;
    country_id: string;
    id: string;
    manh_road_rate: number;
    manh_toll_rate: number;
    name: string;
}

