import { Table } from "../components/table/Table";
import { TableRow } from "../components/table/TableRow";
import { TableRowMode } from "../components/table/TableRowMode";
import { DomEvent } from "./DomEvent";
import { Event, EventListener } from "./Event";

export interface TableRowModeChangeListener extends EventListener {
    (event: TableRowModeChangeEvent): void;
}

export class TableRowModeChangeEvent extends Event {
    private table: Table = null;
    private _oldMode: TableRowMode;
    private _newMode: TableRowMode;

    constructor(component: any, table: Table, oldMode: TableRowMode, newMode: TableRowMode, domEvent?: DomEvent) {
        super(component, domEvent);
        this.table = table;
        this._oldMode = oldMode;
        this._newMode = newMode;
    }

    public get target(): TableRow {
        return super.target as TableRow;
    }

    public getTableRow(): TableRow {
        return this.target as TableRow;
    }

    public getTable(): Table {
        return this.table;
    }

    public get oldMode() {
        return this._oldMode;
    }

    public get newMode() {
        return this._newMode;
    }
}
