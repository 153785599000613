import { DisplayType, HorizontalAlignment, StringUtil, getThemeColor } from "@mcleod/core";
import { Button } from "../button/Button";
import { ButtonProps } from "../button/ButtonProps";
import { ButtonVariant } from "../button/ButtonVariant";
import { ClearButtonVisible } from "../textbox/ClearButtonVisible";
import { ChangeEvent } from "../../events/ChangeEvent";
import { HorizontalSpacer } from "../../page/HorizontalSpacer";
import { Image } from "../image/Image";
import { Label } from "../label/Label";
import { Panel } from "../panel/Panel";
import { PanelProps } from "../panel/PanelProps";
import { ReflectiveDialogs } from "../../base/ReflectiveDialogs";
import { Textbox } from "../textbox/Textbox";

export class ImageNavControls extends Panel {
    private buttonPrevPage: Button;
    private buttonNextPage: Button;
    private currentPageNumber: number;
    private _image: Image;
    private labelPage: Label;
    private labelOfPages: Label;
    private pageEntryTimeoutHandle: number;
    private textboxCurrentPage: Textbox;

    constructor(props?: Partial<PanelProps>) {
        super({ margin: 0, padding: 0, fillRow: true, rowBreakDefault: false, ...props });
        this.createLabelPage();
        this.createTextboxCurrentPage();
        this.createLabelOfPages();
        this.createButtonPrevPage();
        this.createButtonNextPage();
        this.add(new HorizontalSpacer(), this.buttonPrevPage, this.labelPage, this.textboxCurrentPage,
            this.labelOfPages, this.buttonNextPage, new HorizontalSpacer());
    }

    private createLabelPage() {
        this.labelPage = new Label({ text: "Page" });
    }

    private createTextboxCurrentPage() {
        this.textboxCurrentPage = new Textbox({
            captionVisible: false,
            width: 50,
            displayType: DisplayType.INTEGER,
            clearButtonVisible: ClearButtonVisible.NO,
            fontSize: "large",
            fontBold: true,
            align: HorizontalAlignment.CENTER
        });
        this.textboxCurrentPage._inputDiv.style.backgroundColor = getThemeColor("primary.reverse");
        this.textboxCurrentPage._inputDiv.style.color = getThemeColor("default");
        this.textboxCurrentPage.addChangeListener((event: ChangeEvent) => this.doOnCurrentPageChange(event));
        this.textboxCurrentPage.addBlurListener(() => {
            //if the user leaves the textbox and they had cleared its value, reset the value to the current page
            const pageNumber = this.textboxCurrentPage.text;
            if (StringUtil.isEmptyString(pageNumber) === true)
                this.update(this.image.pageIndex);
        });
    }

    /**
     * When the user types in a page number, navigate to that page.  But first wait a few milliseconds to make sure
     * they finished typing.
     */
    private doOnCurrentPageChange(event: ChangeEvent) {
        if (event.userInitiatedChange !== true)
            return;
        if (this.pageEntryTimeoutHandle != null)
            window.clearTimeout(this.pageEntryTimeoutHandle);
        this.pageEntryTimeoutHandle = window.setTimeout(async() => {
            const pageNumber = this.textboxCurrentPage.text;
            if (StringUtil.isEmptyString(pageNumber) !== true) {
                const oldPageIndex = this.image.pageIndex;
                const pageIndex = Number.parseInt(pageNumber) - 1;
                if (oldPageIndex !== pageIndex) {
                    if (this.image.isValidPageIndex(pageIndex) === 0) {
                        const newPageIndex = this.image?.goToPage(pageIndex);
                        this.update(newPageIndex);
                    }
                    else {
                        const message = "There is no page numbered " + pageNumber + " in this image.";
                        await ReflectiveDialogs.showDialog(message, { title: "Invalid Page Number" });
                        this.update(oldPageIndex);
                        this.textboxCurrentPage.focus();
                    }
                }
            }
        }, 300);
    }

    private createLabelOfPages() {
        this.labelOfPages = new Label({ text: "of ?" });
    }

    private createButtonPrevPage() {
        this.buttonPrevPage = new Button({
            ...this.getSharedButtonProps(),
            tooltip: "Display the previous page",
            imageName: "chevron",
            imageRotation: 90,
            onClick: () => {
                const newPageIndex = this.image?.advancePage(-1);
                this.update(newPageIndex);
            }
        });
    }

    private createButtonNextPage() {
        this.buttonNextPage = new Button({
            ...this.getSharedButtonProps(),
            tooltip: "Display the next page",
            imageName: "chevron",
            imageRotation: 270,
            onClick: () => {
                const newPageIndex = this.image?.advancePage(1);
                this.update(newPageIndex);
            }
        });
    }

    get image(): Image {
        return this._image;
    }

    set image(value: Image) {
        this._image = value;
        this.reset();
    }

    reset() {
        const newPageIndex = 0;
        this.update(newPageIndex)
    }

    private update(pageIndex: number) {
        this.enableButtons(pageIndex);
        this.currentPageNumber = (pageIndex + 1);
        this.textboxCurrentPage.text = this.currentPageNumber.toString();
        this.labelOfPages.text = "of " + this.image.numPages;
    }

    private enableButtons(pageIndex: number) {
        const notOnFirstPage = pageIndex !== 0;
        this.buttonPrevPage.enabled = notOnFirstPage;
        const notOnLastPage = pageIndex !== this.image.getLastPageIndex();
        this.buttonNextPage.enabled = notOnLastPage;
    }

    private getSharedButtonProps(): Partial<ButtonProps> {
        return {
            variant: ButtonVariant.round,
            marginLeft: 0,
            marginRight: 0,
            paddingLeft: 0,
            paddingRight: 0
        };
    }
}