import { Event, EventListener } from "../../events/Event";
import { MapPin } from "./MapPin";

export interface PinPlotListener extends EventListener {
    (event: PinPlotEvent): void;
}

export class PinPlotEvent extends Event {
    private _pin: MapPin;

    constructor(pin: MapPin) {
        super(null);
        this._pin = pin;
    }

    get pin(): MapPin {
        return this._pin;
    }
}
