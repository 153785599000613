import { Collection } from "../Collection";
import { AbstractSettings, AbstractSettingsDef } from "./AbstractSettings";
import { SettingsManager } from "./SettingsManager";

export interface CurrencySettingsDef extends AbstractSettingsDef {
    multicurrency_enabled: boolean;
    color_negatives: boolean;
    effective_currency_code: string;
    override_conversion_date: Date;
    currency_symbols: Collection<string>;
    functional_currency_code: string;
    transactional_currency_code: string;
    conversion_by: string;
}

export class CurrencySettings extends AbstractSettings {
    constructor() {
        super("currency_settings");
    }

    public static getSingleton(): CurrencySettings {
        return SettingsManager.getSingleton("currency_settings") as CurrencySettings;
    }

    public static get(): CurrencySettingsDef {
        return CurrencySettings.getSingleton().values as CurrencySettingsDef;
    }

    public static getFunctionalCurrency(): string {
        return this.getSingleton().getFunctionalCurrency();
    }

    public static getTransactionalCurrency(): string {
        return this.getSingleton().getTransactionalCurrency();
    }

    public static getConversionBy(): string {
        return this.getSingleton().getConversionBy();
    }

    public override get values(): CurrencySettingsDef {
        return super.values as CurrencySettingsDef;
    }

    protected getValuesInternal(): CurrencySettingsDef {
        return this._values as CurrencySettingsDef;
    }

    public multiCurrencyEnabled(): boolean {
        return this.getValuesInternal()?.multicurrency_enabled === true;
    }

    public colorNegatives(): boolean {
        return this.getValuesInternal()?.color_negatives === true;
    }

    public getEffectiveCurrencyCode(): string {
        return this.values?.effective_currency_code;
    }

    public getOverrideConversionDate(): Date {
        return this.values?.override_conversion_date;
    }

    public getFunctionalCurrency(): string {
        return this.getValuesInternal()?.functional_currency_code;
    }

    public getTransactionalCurrency(): string {
        return this.getValuesInternal()?.transactional_currency_code;
    }

    public getConversionBy(): string {
        return this.getValuesInternal()?.conversion_by;
    }
}
