import { DataDisplayEvent, Label, Snackbar } from "@mcleod/components";
import { Api,  InstanceIdentifier, LogManager, ModelRow } from "@mcleod/core";
import { AutogenLayoutClientAdmin } from "./autogen/AutogenLayoutClientAdmin";

const log = LogManager.getLogger("common.ClientAdmin");

export class ClientAdmin extends AutogenLayoutClientAdmin {
    tableClientAdminOnSelect() {
        this.buttonCloseClient.enabled = true;
    }

    buttonCloseClientOnClick() {
        try {
            const row = this.tableClientAdmin?.selectedRow?.data as ModelRow;
            if (row == null)
                return;
            Api.post("lib/terminate-client", { client_id: row.data["id"], user_id: row.data["user_id"] }).then((response) => {
                Snackbar.showSnackbar("Client closed successfully");
            }).catch(error => {
                Snackbar.showWarningSnackbar("Client close failed");
            });
        } catch (error) {
            log.error("Error closing client: " + error);
        }
    }

    labelUserIdDataDisplay(event: DataDisplayEvent) {
        const data = event.rowData;
        const label = event.target as Label;
        if (data.get("id") === InstanceIdentifier.get())
            label.setProps({ color: "primary", fontBold: true, tooltip: "This is your current session" });
        else if (data.get("user_id") == null)
            label.text = "Logging in...";
    }
}
