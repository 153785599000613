import { Tab, Tabset } from "..";
import { DomEvent } from "./DomEvent";
import { Event, EventListener } from "./Event";

export interface TabCloseListener extends EventListener {
    (event: TabCloseEvent): void;
}

export class TabCloseEvent extends Event {
    public tab: Tab;
    public tabset: Tabset;
    public afterEventPromise: Promise<any>;

    constructor(target: Tab, domEvent?: Event | DomEvent) {
        super(target, domEvent);
        this.tab = target;
        this.tabset = target.tabset;
    }

    get userInitiatedChange() {
        return this.domEvent != null;
    }
}
