import { Api, ArrayUtil, LogManager, StringUtil } from "@mcleod/core";
import {
    DialogProps, DropdownItem, DropdownSelectionEvent, Label, Panel, PanelProps, TailorExtensionParamInputCreator,
    Textbox
} from "@mcleod/components";
import { SpecialtyPropertyEditor } from "./SpecialtyPropertyEditor";

const log = LogManager.getLogger("common.designer.ui.TailorExtensionEditor");

export class TailorExtensionEditor extends SpecialtyPropertyEditor {
    private extensionsMetadata: any[];
    private textboxId: Textbox;
    private paramPanel: Panel;
    private selectedId: string;

    constructor(currentExtensionJson: string, props?: Partial<PanelProps>) {
        super({ fillRow: true, fillHeight: true, minWidth: 500, minHeight: 400, ...props });
        this.scrollY = true;
        this.loadExtensionMetadata().then(() => {
            if (ArrayUtil.isEmptyArray(this.extensionsMetadata) === true)
                this.configNoExtensionsPanel();
            else
                this.configPanel(currentExtensionJson);
        });
    }

    private async loadExtensionMetadata() {
        const response = await Api.search("tailor/extension-metadata");
        this.extensionsMetadata = response?.data?.[0]?.extensions;
    }

    private configNoExtensionsPanel() {
        const label = new Label({ text: "No extensions have been configured for McLeod Tailor." });
        this.add(label);
    }

    private configPanel(currentExtensionJson: string) {
        this.createIdTextbox();
        this.paramPanel = new Panel({ margin: 0, padding: 0, fillRow: true, rowBreakDefault: true });
        this.add(this.paramPanel);
        if (StringUtil.isEmptyString(currentExtensionJson) === false) {
            const currentValues: any = JSON.parse(currentExtensionJson);
            if (Object.keys(currentValues).length > 0) {
                this.textboxId.setSelectedItemFromValue(currentValues.id);
                this.reloadFromId(currentValues.id);
                this.populateFromData(currentValues);
            }
        }
    }

    private createIdTextbox() {
        const idDropdownItems = this.buildExtensionIdItems();
        this.textboxId = new Textbox({ caption: "Extension", field: "id", id: "textboxId", items: idDropdownItems, fillRow: true, rowBreak: true, required: true });
        this.textboxId.addAfterDropdownSelectionListener((event: DropdownSelectionEvent) => {
            if (event.hasSelection()) {
                this.reloadFromId(event.selection?.value);
            }
        });
        this.add(this.textboxId);
    }

    private buildExtensionIdItems(): DropdownItem[] {
        const result: DropdownItem[] = [];
        if (this.extensionsMetadata == null)
            return;
        for (const extensionMetadata of this.extensionsMetadata) {
            result.push(new DropdownItem(extensionMetadata.id, extensionMetadata.descr));
        }
        return result;
    }

    private getMetadataForId(id: string): any {
        for (const extensionMetadata of this.extensionsMetadata) {
            if (id === extensionMetadata.id)
                return extensionMetadata;
        }
        return null;
    }

    private reloadFromId(id: string) {
        if (id === this.selectedId)
            return;
        this.selectedId = id;
        for (const comp of [...this.paramPanel.components]) {
            this.paramPanel.remove(comp);
        }
        const metadata = this.getMetadataForId(id);
        if (metadata?.params != null) {
            for (const param of metadata.params) {
                const comp = TailorExtensionParamInputCreator.create(param.id,
                    param.descr, param.required, param.allow_manual_input, true);
                this.paramPanel.add(comp);
            }
        }
    }

    private populateFromData(currentValues: any) {
        if (currentValues == null)
            return;
        const metadataForId = this.getMetadataForId(currentValues.id);
        if (metadataForId == null)
            return;
        const metadataParams: any[] = metadataForId.params;
        if (metadataParams != null) {
            const paramValues: any[] = currentValues.params;
            if (paramValues != null) {
                OUTER:
                for (const metadataParam of metadataParams) {
                    for (const paramValue of paramValues) {
                        if (metadataParam.id === paramValue.id) {
                            const comp = this.paramPanel.findComponentByField(paramValue.id)?.[0] as Textbox;
                            if (comp != null)
                                comp.text = paramValue.value;
                            continue OUTER;
                        }
                    }
                }
            }
        }
    }

    getResultValue(): string {
        if (this.textboxId?.selectedItem == null)
            return null;
        const resultObject: any = { id: this.textboxId.selectedItem.value };
        if (ArrayUtil.isEmptyArray(this.paramPanel.components) === false) {
            const params: any[] = [];
            resultObject.params = params;
            for (const paramComp of this.paramPanel.components) {
                const paramTextbox = paramComp as Textbox;
                params.push({ id: paramTextbox.field, value: paramTextbox.text });
            }
        }
        const result = JSON.stringify(resultObject);
        log.debug("TailorExtensionEditor result object: %o, string: %s", resultObject, result);
        return result;
    }

    override getDialogProps(): Partial<DialogProps> {
        return {
            ...super.getDialogProps(),
            title: "Tailor Extension Setup",
            okDefault: false,
            scrollY: true,
            cursor: null
        }
    }
}
