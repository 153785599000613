import { Alignment, ArrayUtil, DOMUtil, getThemeColor, getThemeForKey, Logger, LogManager } from "@mcleod/core";
import { ComponentCreator, List, OverlayedList, ScreenStack, SelectionListener } from "..";
import { Component } from "../base/Component";
import { ComponentUtil } from "../base/ComponentUtil";
import { McLeodMainPageUtil } from "../base/McLeodMainPageUtil";
import { ListProps } from "../components/list/ListProps";
import { Panel } from "../components/panel/Panel";
import { KeyHandlerGroup } from "../events/KeyHandlerGroup";
import { Anchor, AnchorMutation, AnchorProps } from "./Anchor";
import { OnScreen } from "./OnScreen";
import { OverlayProps } from "./OverlayProps";
import { OverlayStyles } from "./OverlayStyles";

export interface DropdownProps extends OverlayProps {
    align: Alignment;
    position: Alignment;
}

export class Overlay {
    private _component: Component;
    private _sourceComponent: Component | HTMLElement;
    private _overlayDiv: HTMLDivElement;
    private _overlayContent: Panel;
    private _props: Partial<OverlayProps>;
    private static _log: Logger;

    constructor(component: Component, props?: Partial<OverlayProps>) {
        this._component = component;
        this._props = props;
        this._sourceComponent = props?.sourceComponent;
        this._overlayDiv = document.createElement("div");
        this._applyOverlayDivCSS(props);
        if (props?.closeOnClickOff !== false) {
            let closeOverlayFunction: (event: Event) => void;
            let clickInProgress = false;
            if (props?.closeHandler == null)
                closeOverlayFunction = (event) => Overlay.hideOverlay(this);
            else
                closeOverlayFunction = (event) => props.closeHandler();

            //ensure that both starting a click (onmousedown) and completing a click (onmouseup) has to be outside the component
            //before we use that click to close the overlay
            this._overlayDiv.onmousedown = (event) => {
                if (event.target === this._overlayDiv)
                    clickInProgress = true;
            };
            this._component._element.onmouseenter = (event) => {
                clickInProgress = false;
            };
            this._overlayDiv.onmouseup = (event) => {
                try {
                    if (clickInProgress === true)
                        closeOverlayFunction(event);
                }
                finally {
                    clickInProgress = false;
                }
            };
        }
        this._overlayContent = new Panel();
        this._overlayContent.className = OverlayStyles.overlayContent;
        this._overlayContent.addClickListener(event => event.stopPropagation());
        this._overlayContent._element.style.cursor = null;
        this._overlayContent.add(this._component);
        this._overlayDiv.appendChild(this._overlayContent._element);

        this._overlayDiv.style.display = "block";
        this._overlayDiv.style.backgroundColor = props?.greyedBackground === true ?
            getThemeColor(getThemeForKey("component.palette.overlay.backgroundColor")) : "";
        if (props?.centered === true)
            component.setClassIncluded(OverlayStyles.centered);
        if (props?.width != null)
            this._overlayContent.style.width = DOMUtil.getSizeSpecifier(props.width);
        if (props?.height != null)
            this._overlayContent.style.height = DOMUtil.getSizeSpecifier(props.height);
        if (props?.style != null) {
            for (const key in props.style) {
                this._overlayContent.style[key] = props.style[key];
            }
        }
    }

    private _applyOverlayDivCSS(props: Partial<OverlayProps>) {
        if (props?.coverPageHeader !== false)
            this._overlayDiv.className = OverlayStyles.overlay;
        else {
            this._overlayDiv.className = OverlayStyles.overlayBelowPageHeader;
            //have to override the top/height styles here...
            //can't calculate height of page header when OverlayStyles is created...the page header has no height at that time
            const pageHeaderHeight = McLeodMainPageUtil.getPageHeaderHeight();
            this._overlayDiv.style.top = pageHeaderHeight + "px";
            this._overlayDiv.style.height = "calc(100% - " + pageHeaderHeight + "px)";
        }
    }

    get component(): Component {
        return this._component;
    }

    get overlayDiv(): HTMLDivElement {
        return this._overlayDiv;
    }

    get sourceComponent(): Component | HTMLElement {
        return this._sourceComponent || this._props.anchor?.anchor;
    }

    get componentToFocusOnClose(): Component {
        return this._props?.componentToFocusOnClose;
    }

    fillKeyHandlerGroup(recreate: boolean = false) {
        this._overlayContent.fillKeyHandlerGroup(recreate);
    }

    getKeyHandlerGroup(): KeyHandlerGroup {
        return this._overlayContent.getKeyHandlerGroup();
    }

    getOverlayContent(): Panel {
        return this._overlayContent;
    }

    get overlayContentFirstElement(): HTMLElement {
        //this._overlayContent._element.firstChild is (maybe) the best guess we can make when the component is not known
        //but in most cases the component should be known, and we should just use the component's element
        return this.component?._element || (this._overlayContent._element.firstChild as HTMLElement);
    }

    get zIndex(): number {
        return DOMUtil.convertStyleAttrToNumber(this.overlayDiv.style.zIndex);
    }

    set zIndex(value: number) {
        this._overlayDiv.style.zIndex = value.toString();
    }

    alignAndObserve() {
        const overlayContentElement = this.overlayContentFirstElement;
        let compRect = ComponentUtil.getRect(overlayContentElement);
        if (this._props?.anchor != null) {
            compRect = Anchor.alignRect(compRect, this._props.anchor);
            let mutation = Overlay.ensureOnScreen(overlayContentElement, this._props?.anchor, this._component);
            const obs = new MutationObserver((mutationList, observer) => {
                // if (this.mutationInsideTable(mutationList) === true) {
                //   Overlay.log.debug("Disregarding mutations inside table row: %o", mutationList);
                //   return;
                // }
                const startTime = new Date().getTime();
                mutation = Overlay.ensureOnScreen(overlayContentElement, mutation.newAnchor, this._component);
                this._props?.anchor?.onMutate?.(overlayContentElement, mutation);
                const elapsedTime = new Date().getTime() - startTime;
                Overlay.log.debug("Mutations %o took %o", mutationList, elapsedTime);
            });
            obs.observe(this._overlayContent._element, { childList: true, subtree: true });
        }
        // overlayContent.addEventListener("resize", () => Overlay.ensureOnScreen(this._overlayContent) );
    }
    /*
      private mutationInsideTable(mutationList: any[]) {
        if (mutationList != null && mutationList.length > 0) {
          const firstMutation = mutationList[0];
          if (firstMutation.target instanceof HTMLTableRowElement || firstMutation.target instanceof HTMLTableElement)
            return true;
          let parent = firstMutation.target.parentElement;
          while (parent != null) {
            if (parent instanceof HTMLTableRowElement || parent instanceof HTMLTableElement)
              return true;
            parent = parent.parentElement;
          }
        }
      }
     */
    remove(componentToFocusOnClose?: Component) {
        ScreenStack.popOverlay(this);
        if (this._component instanceof Panel)
            this._component.dismissAllPopups();
        document.body.removeChild(this._overlayDiv);
        //give the component that the mouse was over before the overlay was displayed enough time to consume (and ignore)
        //its mouseenter event.  then null out that component so that future mouseenter events work.
        setTimeout(() => Component.setPreOverlayMouseOverComponent(null), 500);
        if (this._props != null && this._props.onClose != null)
            this._props.onClose();
        if (componentToFocusOnClose != null)
            componentToFocusOnClose.focus();
    }

    /**
     * This function displays a component over the top of the rest of the page.  Another div completely covers the screen so that the user can't interact with
     * any of the existing page's components.
     *
     * @param component The component that will be the overlay.
     * @param props OverlayProps
     * @returns Returns a HTMLDivElement that can be passed to hideOverlay to make sure the proper overlay is removed.
     */
    public static showInOverlay(component: Component, props?: Partial<OverlayProps>): Overlay {
        const overlay = new Overlay(component, props);
        overlay.zIndex = ScreenStack.getNewHighestZIndex();
        overlay.fillKeyHandlerGroup();
        ScreenStack.pushOverlay(overlay);
        document.body.appendChild(overlay.overlayDiv);
        overlay.alignAndObserve();
        return overlay;
    }

    /**
     * Hides all overlays.  If you are trying to hide just one overlay, use Overlay.hideOverlay(Overlay, boolean?).
     *
     * @returns void
     */
    public static hideAllOverlays() {
        const overlays = ScreenStack.getAllOverlays();
        for (let i = overlays.length - 1; i >= 0; i--)
            overlays[i].remove();
    }

    /**
     * Hides a previously shown overlay.  The showOverlay function returns a value that can be passed to the overlayToClose argument.
     * This will ensure the correct overlay is closed in the case that there are multiple overlays on top of each other.
     *
     * @param overlayToClose the Overlay that you wish to close.
     * @param componentToFocusOnClose When provided, a Component that should get focus when the overlay is closed.
     *      When it is not provided, the component to focus on close that was provided when the overlay was created
     *      will be used.
     */
    public static hideOverlay(overlayToClose: Overlay, componentToFocusOnClose?: Component) {
        if (ScreenStack.overlayInStack(overlayToClose) === true) {
            const compFocusOnClose = componentToFocusOnClose != null ? componentToFocusOnClose :
                overlayToClose.componentToFocusOnClose;
            overlayToClose.remove(compFocusOnClose);
        }
    }

    /**
     * A dropdown is just a special case of an overlay that shows a list with the specified items.
     *
     * @param anchorComponent
     * @param items
     * @param onSelect
     * @param listProps
     * @param dropdownProps
     * @param selectedItem
     * @returns
     */
    public static showDropdown(anchorComponent: HTMLElement | Component, items: any, onSelect?: SelectionListener,
        listProps?: Partial<ListProps>, dropdownProps?: Partial<DropdownProps>,
        selectedItem?: ComponentCreator, parentList?: List): OverlayedList {
        if (typeof items === "function")
            items = items();
        const list = new OverlayedList({
            className: OverlayStyles.popup,
            items: items,
            ...listProps
        }, onSelect, listProps, dropdownProps);
        list.setSelectedItemsFromSuppliedInputs(ArrayUtil.getAsArray(selectedItem, true));
        if (parentList instanceof OverlayedList)
            list.parentList = parentList;
        const anchor = dropdownProps?.anchor || {
            anchor: anchorComponent,
            align: dropdownProps?.align,
            position: dropdownProps?.position,
        };
        list.display(anchor, selectedItem);
        return list;
    }

    public static findOverlay(component: Component): Overlay {
        for (const overlay of ScreenStack.getAllOverlays()) {
            if (overlay.component === component)
                return overlay;
        }
        return null;
    }

    /**
     * This sets the absolute position of 'component' relative to 'anchor.'  It will also adjust the relevant sizing of component to match that of anchor.  For example,
     * if position = Alignment.BOTTOM or position = Alignment.TOP, component's width will be set to the same width as anchor.  If this is not the desired behavior,
     * component needs to have an explicit width (or null if you want to component maintain its width:auto)
     *
     * @deprecated Use Anchor instead
     * @param component The Component that you are trying to position
     * @param anchor The Component to which you are trying to position 'component' relative to
     * @param alignment Specifies which side of the anchor we should align the dropdown with.  For example, align=Alignment.RIGHT would specify that the right side of component
     *   will be aligned to the right side of anchor (that would typically only be used with position: TOP or position.BOTTOM)
     * @param position Specifes the position of the dropdown relative to the anchor.  For example, position=Alignment.BOTTOM specifies that the component should be
     *   positioned under the anchor.
     */
    public static alignToAnchor(component: Component, anchor: Component | Element, alignment: Alignment = Alignment.LEFT, position: Alignment = Alignment.BOTTOM) {
        component._element.classList.add(OverlayStyles.popup);
        const anchorElement: Element = anchor instanceof Component ? anchor._element : anchor;
        const anchorRect = anchorElement.getBoundingClientRect == null ? anchorElement.getBoundingClientRect() : anchorElement.getBoundingClientRect();
        if (position === Alignment.TOP) {
            if (component.width === undefined)
                component.width = anchorRect.width;
            else if (typeof component.width === "number")
                component.top = anchorRect.top - component.width;
            component.left = anchorRect.left;
        } else if (position === Alignment.RIGHT) {
            component.top = anchorRect.top;
            component.left = anchorRect.right;
            component.height = anchorRect.height;
            if (component.borderTopRightRadius === undefined && component.borderBottomLeftRadius === undefined && component.borderRadius === undefined) {
                component.borderTopRightRadius = 8;
                component.borderBottomRightRadius = 8;
            }
        } else if (position === Alignment.LEFT) {
            component.top = anchorRect.top;
            if (typeof component.width === "number")
                component.left = anchorRect.left - component.width;
            component.height = anchorRect.height;
        }
        else {
            component.top = anchorRect.bottom;
            if (component.width === undefined)
                component.width = anchorRect.width;
            if (alignment === Alignment.LEFT)
                component.left = anchorRect.left;
            else if (typeof component.width === "number")
                component.left = anchorRect.right - component.width;
            else if (component.width === null)
                component.right = document.body.clientWidth - anchorRect.right;
            if (component.borderBottomRightRadius === undefined && component.borderBottomLeftRadius === undefined && component.borderRadius === undefined) {
                component.borderBottomRightRadius = 4;
                component.borderBottomLeftRadius = 4;
            }
        }
    }

    public static ensureOnScreen(elem: HTMLElement, anchor: AnchorProps, component: Component): AnchorMutation {
        const minWidth = anchor?.minWidth || DOMUtil.convertSizeStyleToPixels(component.minWidth, document.body.offsetWidth);
        const minHeight = anchor?.minHeight || DOMUtil.convertSizeStyleToPixels(component.minHeight, document.body.offsetHeight);
        return OnScreen.ensureOnScreen(elem, { ...anchor, minWidth: minWidth, minHeight: minHeight });
    }

    public static getPopupBorderRadiusProps(anchor: AnchorProps): Partial<CSSStyleDeclaration> {
        const pos = anchor?.position || Alignment.BOTTOM;
        const props = { borderTopLeftRadius: "", borderTopRightRadius: "", borderBottomLeftRadius: "", borderBottomRightRadius: "" };
        if (pos === Alignment.RIGHT)
            return { ...props, borderTopRightRadius: "4px", borderBottomRightRadius: "4px" };
        else if (pos === Alignment.TOP)
            return { ...props, borderTopLeftRadius: "4px", borderTopRightRadius: "4px" };
        else if (pos === Alignment.LEFT)
            return { ...props, borderTopLeftRadius: "4px", borderTopRightRadius: "4px" };
        else
            return { ...props, borderBottomLeftRadius: "4px", borderBottomRightRadius: "4px" };
    }

    private static get log() {
        if (this._log == null)
            this._log = LogManager.getLogger("components.page.Overlay");
        return this._log;
    }
}
