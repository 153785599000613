import { ObjectUtil } from "@mcleod/core";
import { ComponentPropDefinitions } from "../../base/ComponentProps";
import { PropType } from "../../base/PropType";
import { MultiButtonPropDefinitions, MultiButtonProps } from "../multibutton/MultiButtonProps";

let saveButtonPropDefs: ComponentPropDefinitions;

export interface SaveButtonProps extends MultiButtonProps {
    allowSave: boolean;
    allowSaveAndAdd: boolean;
    allowSaveAndClose: boolean;
}

export class SaveButtonPropDefinitions {
    public static getDefinitions(): ComponentPropDefinitions {
        if (saveButtonPropDefs == null) {
            saveButtonPropDefs = {
                ...ObjectUtil.deepCopy(MultiButtonPropDefinitions.getDefinitions()),
                allowSave: { type: PropType.bool, defaultValue: true, description: "Determines whether the 'Save' option is presented as part of the button." },
                allowSaveAndAdd: { type: PropType.bool, defaultValue: false, description: "Determines whether the 'Save & Add' option is presented as part of the button." },
                allowSaveAndClose: { type: PropType.bool, defaultValue: false, description: "Determines whether the 'Save & Close' option is presented as part of the button." }
            };
            saveButtonPropDefs.busyWhenDataSourceBusy.defaultValue = true;
            saveButtonPropDefs.caption.defaultValue = "Save";
            saveButtonPropDefs.backgroundColor.defaultValue = "primary";
            saveButtonPropDefs.color.defaultValue = "primary.reverse";
            saveButtonPropDefs.minWidth.defaultValue = 100;
        }
        return saveButtonPropDefs;
    }
}
