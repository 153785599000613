import { DataSource, HorizontalSpacer, Label, Layout, Panel, SaveButton, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutSendAccountInvitation extends Layout {
    buttonSendInvite: SaveButton;
    horizontalspacer1: HorizontalSpacer;
    labelLabel1: Label;
    panelButtons: Panel;
    panelContent: Panel;
    sourceAccountInvite: DataSource;
    textboxAccountName: Textbox;
    textboxEmailAddress: Textbox;
    textboxHomePage: Textbox;
    textboxUserId: Textbox;
}
