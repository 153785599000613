import { ResourceFileProps } from "@mcleod/components";
import { ObjectUtil } from "@mcleod/core";
import { ResourceDesignerTab } from "../common/ResourceDesignerTab";

export class DesignerTabDescriptor implements Required<Pick<ResourceFileProps, 'path' | 'baseVersion' | 'customId'>> {
    path: string;
    baseVersion: boolean;
    customId: string;

    constructor(path: string, baseVersion: boolean, customId?: string) {
        this.path = path;
        this.baseVersion = baseVersion;
        this.customId = customId;
    }

    public equals(otherDescriptor: DesignerTabDescriptor): boolean {
        if (otherDescriptor == null)
            return false;
        return ObjectUtil.deepEqual(this, otherDescriptor);
    }

    public static createFromObject(o: any): DesignerTabDescriptor {
        if (!(o instanceof ResourceDesignerTab) && o.path == null)
            throw new Error("path is required");
        return new DesignerTabDescriptor(o.path, o.baseVersion, o.customId);
    }
}
