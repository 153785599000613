import { CoreLogger } from "@mcleod/core/src/logging/CoreLogger";
import { LogLevel } from "@mcleod/core/src/logging/LogLevel";
import { Logger, LogManager } from "@mcleod/core";
import { DesignableObject } from "../base/DesignableObject";

export class DesignableObjectLogger extends CoreLogger {
    private static log: Logger;

    public override log(level: LogLevel, ...rest: any[]) {
        if (rest == null || rest.length < 2 || !(rest[0] instanceof DesignableObject)) {
            DesignableObjectLogger.getLog().debug("Unable to output DesignableObject log message");
            return;
        }
        const designableObject = rest.shift() as DesignableObject;
        const prefix = designableObject.logPrefix;
        // Creating updatedArgs defeats 'never' warning when calling function below.
        // If we call rest[0](), the code will think rest[0] is a DesignableObject and not callable.
        const updatedArgs = rest;
        if (updatedArgs.length === 1 && typeof updatedArgs[0] === "function")
            updatedArgs[0] = updatedArgs[0]();
        const message = updatedArgs[0];
        if (typeof message !== "object")
            updatedArgs[0] = prefix + " " + message;
        else
            updatedArgs.splice(0, 0, prefix);
        this.formatAndOutput(level, ...updatedArgs);
    }

    private static getLog(): Logger {
        if (this.log == null)
            this.log = LogManager.getLogger("components.logging.DesignableObjectLogger");
        return this.log;
    }
}
