import { ApiMetadata, CityUtil, Collection, JSUtil, MetadataField, ModelRow, StringUtil } from "@mcleod/core";
import { ChangeEvent, ChangeListener, Component, DataDisplayEvent, DataSourceMode, ForcedCase, Label, Layout, LookupModelSelectionEvent, PrintableEvent, PrintableListener, ValidationResult } from "../..";
import { Captioned } from "../../base/CaptionedComponent";
import { getCurrentDataSourceMode, getRelevantModelRow } from "../../base/ComponentDataLink";
import { ComponentPropDefinitions } from "../../base/ComponentProps";
import { ComponentTypes } from "../../base/ComponentTypes";
import { ListenerListDef } from "../../base/ListenerListDef";
import { Printable, printableListenerDef } from "../../base/PrintableComponent";
import { CompoundComponent } from "../compound/CompoundComponent";
import { Textbox } from "../textbox/Textbox";
import { CityStatePropDefintions, CityStateProps } from "./CityStateProps";

const _changeListenerDef: ListenerListDef = { listName: "_changeListeners" };

enum LookupFields {
    id = "id",
    name = "name",
    state_id = "state_id",
    zip_code = "zip_code",
    latitude = "latitude",
    longitude = "longitude"
}

export class CityState extends CompoundComponent implements CityStateProps {
    _cityField: string;
    _stateField: string;
    _zipField: string;
    _cityIdField: string;
    _latitudeField: string
    _longtiudeField: string;
    private _boundCityField: MetadataField;
    private _boundStateField: MetadataField;
    private _boundZipField: MetadataField;

    textCombined: Textbox;
    textCity: Textbox;
    textState: Textbox;
    textZip: Textbox;

    private _quickInfoLayout: string;
    protected _lookupModelDataForUpdate: ModelRow;

    constructor(props?: Partial<CityStateProps>) {
        super(props, false);
        this._shouldAddDesignerContainerProperties = false;
        this.createTextsIfNeeded();
        this.dataSourceModeChanged(DataSourceMode.NONE);
        this.setProps({ rowBreakDefault: false, ...props });
        this._syncEnabled();
    }

    protected override getComponentsToLoad(): Component[] {
        return this.getAllTextboxes();
    }

    public get lookupModelDataForUpdate(): ModelRow {
        return this._lookupModelDataForUpdate;
    }

    private createTextsIfNeeded() {
        if (this.textCombined == null) {
            this.textCombined = new Textbox({
                fillRow: true,
                lookupModel: "common/city-suggestion",
                lookupModelLayout: "common/CityLookup",
                lookupModelResultField: "id",
                lookupModelAllowSearchAll: false,
                lookupModelMaxResults: 10,
                buttonProps: { imageName:  "pinOutlined"},
                padding: 0,
                id: "cityStateCombined"
            });

            this.textCombined.lookupModelDisplayCallback = (row: ModelRow) => {
                if (row == null)
                    return "";

                return CityUtil.formatCityStateZip(
                    row?.get(LookupFields.name),
                    row?.get(LookupFields.state_id),
                    row?.get(LookupFields.zip_code)
                );
            };

            this.textCombined.tooltipCallback = this.makeTooltipCallbackFunction();

            this.textCombined.addLookupModelSelectionListener((event: LookupModelSelectionEvent) => {
                this._lookupModelDataForUpdate = event.selection ?? new ModelRow(this.textCombined.lookupModel, false);
                const modelRow = getRelevantModelRow(this);
                if (modelRow != null)
                    this.updateBoundData(modelRow, getCurrentDataSourceMode(this));
            });

            this.textCity = new Textbox({ fillRow: true, padding: 0 });
            this.textState = new Textbox({
                width: 100,
                padding: 0,
                marginLeft: 8,
                lookupModel: "common/states",
                lookupModelLayout: "common/States",
                lookupModelResultField: "id",
                lookupModelDisplayField: "id",
                lookupModelMinChars: 1,
                lookupModelLayoutWidth: 250,
                forcedCase: ForcedCase.UPPER
            });
            this.textZip = new Textbox({ width: 100, padding: 0, marginLeft: 8 });
        }
    }

    private getAllTextboxes(): Textbox[] {
        this.createTextsIfNeeded();
        return [this.textCombined, this.textCity, this.textState, this.textZip];
    }

    private getSearchModeTextboxes(): Textbox[] {
        return this.getAllTextboxes().filter(textbox => textbox != this.textCombined);
    }

    public override updateBoundData(data: ModelRow, mode: DataSourceMode) {
        if (this.printable === true)
            return;
        if (mode === DataSourceMode.SEARCH && this.contains(this.textCity)) {
            this.getSearchModeTextboxes().forEach(textbox => textbox.updateBoundData(data, mode));
        }
        else if (data != null) {
            let oldData = null;
            if (this._lookupModelDataForUpdate != null) {
                oldData = { ...data };
                if (this.cityField != null)
                    data.set(this.cityField, this._lookupModelDataForUpdate?.get(LookupFields.name), this);
                if (this.stateField != null)
                    data.set(this.stateField, this._lookupModelDataForUpdate?.get(LookupFields.state_id), this);
                if (this.zipField != null)
                    data.set(this.zipField, this._lookupModelDataForUpdate?.get(LookupFields.zip_code), this);
                if (this.cityIdField != null)
                    data.set(this.cityIdField, this._lookupModelDataForUpdate?.get(LookupFields.id), this);
                const event = new ChangeEvent(this, oldData, { ...data });
                this.fireListeners(_changeListenerDef, event)
            }
        }
    }

    override _serializeNonProps(): string {
        return "";
    }

    override displayComponentData(row: ModelRow, allData: ModelRow[], rowIndex: number) {
        this.setRowLookupModelData(row);
        super.displayComponentData(row, allData, rowIndex);
    }

    private setRowLookupModelData(row: ModelRow, resetOriginalData = false) {
        if (row == null)
            return;

        if (this.contains(this.textCombined)) {
            this.textCombined.field = this.resolveTextCombinedField(row);
            const lmData = this.resolveLookupModelData(row);
            row.setLookupModelData(this.textCombined.field, lmData);
        } else if (this.textCombined.field != null) {
            row.removeLookupModelData(this.textCombined.field);
        }

        if (resetOriginalData && this.textCombined.field != null) {
            row.resetOriginalLookupModelData(this.textCombined.field, true);
        }
    }

    resolveTextCombinedField(row: ModelRow): string {
        const fields = [this.cityIdField, this.cityField, this.stateField, this.zipField];
        return fields.find(field => row?.get(field) != null);
    }

    private resolveLookupModelData(row: ModelRow): ModelRow{
        if (this._lookupModelDataForUpdate != null && !this._lookupModelDataForUpdate.isEmpty())
            return this._lookupModelDataForUpdate;

        return this.textCombined.createLookupModelRow({
            [LookupFields.id]: row.get(this.cityIdField),
            [LookupFields.name]: row.get(this.cityField),
            [LookupFields.zip_code]: row.get(this.zipField),
            [LookupFields.state_id]: row.get(this.stateField),
            [LookupFields.latitude]: row.get(this._latitudeField),
            [LookupFields.longitude]: row.get(this._longtiudeField)
        });
    }

    override dataSourceModeChanged(mode: DataSourceMode) {
        super.dataSourceModeChanged(mode);
        this.removeAll();
        if (mode === DataSourceMode.SEARCH) {
            this.add(this.textCity, this.textState);
            if (this.zipField != null)
                this.add(this.textZip);
        }
        else {
            this.add(this.textCombined);
            if (mode === DataSourceMode.UPDATE) {
                this.setRowLookupModelData(this.dataSource?.activeRow, true);
            }
        }
        this["_syncPrintable"]();
    }

    protected _applyPrintable(value: boolean) {
        this.createTextsIfNeeded();
        const oldPrintableValue = this.textCombined.printable;
        this.getAllTextboxes().forEach(textbox => textbox.printable = value);
        if (oldPrintableValue === true && value === false)
            this.reattachListeners();
        this._syncDesignerView();
        this._syncEnabled();
        this.fireListeners(printableListenerDef, new PrintableEvent(this));
    }

    override validate(checkRequired: boolean = true, showErrors: boolean = true): ValidationResult[] {
        let result: ValidationResult = null;
        for (const component of this.components) {
            const compResult = component.validate(checkRequired, showErrors);
            result ??= compResult?.length > 0 ? compResult[0] : null;
        }
        return result ? [{ ...result, component: this }] : null;
    }

    override resetValidation() {
        this.getAllTextboxes().forEach(textbox => textbox.resetValidation());
    }

    get caption(): string {
        return this["_mixin-Captioned-caption"];
    }

    set caption(value: string) {
        if (this["captionValueMatches"](value) === true) {
            return;
        }
        this["_mixin-Captioned-caption"] = value;
        this.syncCaption();
    }

    syncCaption() {
        [this.textCity, this.textCombined].forEach(textbox => textbox.caption = this.caption);
    }

    get captionVisible() {
        this.createTextsIfNeeded();
        return this.textCombined.captionVisible;
    }

    set captionVisible(value: boolean) {
        this.getAllTextboxes().forEach((textbox) => textbox.captionVisible = value);
    }

    override _applyEnabled(value: boolean): void {
        this.getAllTextboxes().forEach((textbox) => textbox._applyEnabled(value));
    }

    override _syncRequired(): void {
        this.textCombined.required = this.required;
    }

    get printable(): boolean {
        this.createTextsIfNeeded();
        return this["_mixin-Printable-printable"];
    }

    set printable(value: boolean) {
        this["_mixin-Printable-printable"] = value;
    }

    get printableDuringAdd(): boolean {
        return this["_mixin-Printable-printableDuringAdd"];
    }

    set printableDuringAdd(value: boolean) {
        this["_mixin-Printable-printableDuringAdd"] = value;
    }

    get printableDuringSearch(): boolean {
        return this["_mixin-Printable-printableDuringSearch"];
    }

    set printableDuringSearch(value: boolean) {
        this["_mixin-Printable-printableDuringSearch"] = value;
    }

    get printableDuringUpdate(): boolean {
        return this["_mixin-Printable-printableDuringUpdate"];
    }

    set printableDuringUpdate(value: boolean) {
        this["_mixin-Printable-printableDuringUpdate"] = value;
    }

    _syncDesignerView() {
        if (this._designer != null && this._cityField != null) {
            const text = CityUtil.formatCityStateZip(this._cityField, this._stateField, this._zipField);
            if (this.printable)
                this.textCombined["_printableLabel"].text = text;
            else
                this.textCombined.placeholder = text;
        }
    }

    override getFieldNames(): string[] {
        const result = [];
        if (this.cityField != null)
            result.push(this.cityField);
        if (this.stateField != null)
            result.push(this.stateField);
        if (this.zipField != null)
            result.push(this.zipField);
        return result;
    }

    get cityField(): string {
        return this._cityField;
    }

    set cityField(value: string) {
        this._cityField = value;
        this.textCity.field = value;
        this._syncDesignerView();
    }

    get stateField(): string {
        return this._stateField;
    }

    set stateField(value: string) {
        this._stateField = value;
        this.textState.field = value;
        this._syncDesignerView();
    }

    get zipField(): string {
        return this._zipField;
    }

    set zipField(value: string) {
        this._zipField = value;
        this.textZip.field = value;
        this._syncDesignerView();
    }

    get cityIdField(): string {
        return this._cityIdField;
    }


    set cityIdField(value: string) {
        this._cityIdField = value;
    }

    override getPropertyDefinitions(): ComponentPropDefinitions {
        return CityStatePropDefintions.getDefinitions();
    }

    addChangeListener(value: ChangeListener) {
        this.addEventListener(_changeListenerDef, value);
    }

    removeChangeListener(value: ChangeListener) {
        this.removeEventListener(_changeListenerDef, value);
    }

    public addPrintableListener(value: PrintableListener) {
        this.addEventListener(printableListenerDef, value);
    }

    public removePrintableListener(value: PrintableListener) {
        this.removeEventListener(printableListenerDef, value);
    }

    override get serializationName() {
        return "citystate";
    }

    override get properName(): string {
        return "City/State";
    }

    public override getEventTarget(): HTMLElement {
        return this.textCombined.getEventTarget();
    }

    getListenerDefs(): Collection<ListenerListDef> {
        return { ...this.textCombined.getListenerDefs() };
    }

    public getDisplayLabel(field: string, context: string): string {
        let suffix: string;
        let metaField: MetadataField;
        switch (field) {
            case this.cityField: suffix = "City"; metaField = this._boundCityField; break;
            case this.stateField: suffix = "State"; metaField = this._boundStateField; break;
            case this.zipField: suffix = "Zip Code"; metaField = this._boundZipField; break;
        }
        // we might want to allow setting a displayLabelCity, displayLabelState, displayLabelZip property to allow setting these instead of just appending hard-coded text
        return StringUtil.join(" ", [context, this._displayLabel ?? metaField?.caption, suffix]);
    }

    protected override _metadataChanged(metadata: ApiMetadata) {
        super._metadataChanged(metadata);
        if (metadata != null) {
            if (this.cityField != null)
                this._boundCityField = metadata.output[this.cityField] || metadata.input[this.cityField];
            if (this.stateField != null)
                this._boundStateField = metadata.output[this.stateField] || metadata.input[this.stateField];
            if (this.zipField != null)
                this._boundZipField = metadata.output[this.zipField] || metadata.input[this.zipField];
        }
        this.createTextsIfNeeded();
    }

    override getBasicValue(): any {
        return this.textCombined.getBasicValue();
    }

    makeCityStateTooltip(id: string, component: Component) {
        return (baseTooltip: Component, originatingEvent): Component => {
            const tooltip = this.getQuickInfoLayout(baseTooltip, id);
            if (component instanceof Textbox && (component as Textbox).printable) {
                const label: Label = (component as Textbox)["_printableLabel"];
                return label["_internalShowTooltip"](tooltip, originatingEvent);
            } else if (component instanceof CityState) {
                return component.textCombined["_internalShowTooltip"](
                    tooltip,
                    originatingEvent
                );
            } else
                return component["_internalShowTooltip"](tooltip, originatingEvent);
        };
    }

    makeTooltipCallbackFunction() {
        return (baseTooltip: Component, originatingEvent): Component => {
            const tooltip = this.getQuickInfoLayout(baseTooltip, getRelevantModelRow(this)?.get(this.field));
            if (this.textCombined instanceof Textbox && (this.textCombined as Textbox).printable) {
                const label: Label = (this.textCombined as Textbox)["_printableLabel"];
                return label["_internalShowTooltip"](tooltip, originatingEvent);
            }
            else
                return this.textCombined["_internalShowTooltip"](tooltip, originatingEvent);
        };
    }

    getQuickInfoLayout(baseTooltip: Component, id: string): Layout | null {
        if (StringUtil.isEmptyString(id) === true)
            return null;
        const layout = Layout.getLayout(this.quickInfoLayout);
        layout.onLoad = () => {
            if (layout.mainDataSource != null)
                layout.mainDataSource.search({ "id": id });
        };
        return layout;
    }

    public get quickInfoLayout(): string {
        return this._quickInfoLayout || this.getPropertyDefinitions().quickInfoLayout.defaultValue;
    }

    public set quickInfoLayout(value: string) {
        this._quickInfoLayout = value;
    }

    public clearAllFields() {
        this.getAllTextboxes().forEach((textbox) => textbox.clear());
    }
}

JSUtil.applyMixins(CityState, [Captioned, Printable]);
ComponentTypes.registerComponentType("citystate", CityState.prototype.constructor, true);
