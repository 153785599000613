import { DesignerInterface, Panel, Tab } from "@mcleod/components";
import { PropertiesTablePanel } from "./PropertiesTablePanel";
import { PropertiesTable } from "./PropertiesTable";

export class DesignerContent {
    private designer: DesignerInterface;
    private _panelProps: PropertiesTablePanel;
    private _designerPanel: Panel;

    constructor(designerPanel: Panel, designer: DesignerInterface) {
        this.designer = designer;
        this._designerPanel = designerPanel;
    }

    get designerPanel(): Panel {
        return this._designerPanel;
    }

    get tableProps(): PropertiesTable {
        return this.propsPanel.tableProps;
    }

    get propsPanel(): PropertiesTablePanel {
        if (!this._panelProps)
            this._panelProps = new PropertiesTablePanel(this.designer);
        return this._panelProps;
    }
}
