import { Component } from "../base/Component";
import { DesignableObject } from "../base/DesignableObject";
import { DomEvent, DomFocusEvent } from "./DomEvent";
import { Event, EventListener } from "./Event";
import { Textbox } from "../components/textbox/Textbox";

export interface BlurListener extends EventListener {
    (event: BlurEvent): void;
}

export class BlurEvent extends Event {
    private _changedWhileFocused: boolean = false;

    constructor(component: DesignableObject, domEvent?: DomEvent) {
        super(component, domEvent);
        this._setChangedWhileFocused()
    }

    public get domEvent(): DomFocusEvent {
        return super.domEvent as DomFocusEvent;
    }

    public get relatedTarget(): EventTarget {
        return this.domEvent.relatedTarget;
    }

    private _setChangedWhileFocused() {
        if (!(this.target instanceof Component))
            return;
        this._changedWhileFocused = (this.target as Component).changedWhileFocused();
    }

    public get changedWhileFocused(): boolean {
        return this._changedWhileFocused;
    }

    public set changedWhileFocused(value: boolean) {
        this._changedWhileFocused = value;
    }

    override validate() {
        // Avoid circular dependency by not using type checking
        if (this.target["validateBlur"] != null && this.target["validateBlur"](this) !== true)
            return false;
        return super.validate();
    }
}
