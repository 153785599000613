/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelUserRecognizedDevice.ts          *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "common/user-recognized-device";

export class AutogenModelUserRecognizedDevice<RowType extends ModelRow> extends Endpoint<RowType, RowUserRecognizedDeviceFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowUserRecognizedDevice extends ModelRow<RowUserRecognizedDeviceFields> {
    constructor(values?: Partial<RowUserRecognizedDeviceFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowUserRecognizedDeviceFields {
    approved: string;
    browser: string;
    company_id: string;
    device_id: string;
    id: string;
    last_ip_address: string;
    last_used_date: Date;
    platform: string;
    tenant_id: string;
    user_id: string;
}

