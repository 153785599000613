import { Label, Layout, Panel, Splitter, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutAnalyzer extends Layout {
    labelLabel1: Label;
    panelAnalysis: Panel;
    panelInput: Panel;
    splitBottom: Panel;
    splitterMain: Splitter;
    textInput: Textbox;
}
