import { ModelRow } from "@mcleod/core";
import { DesignableObject } from "../base/DesignableObject";
import { Event, EventListener } from "./Event";

export interface DataDisplayListener extends EventListener {
    (event: DataDisplayEvent): void;
}

export class DataDisplayEvent extends Event {
    rowData: ModelRow = null;
    allData: ModelRow[] = null;
    rowIndex: number = -1;

    constructor(component: DesignableObject, rowData: ModelRow, allData: ModelRow[], rowIndex: number) {
        super(component);
        this.rowData = rowData;
        this.allData = allData;
        this.rowIndex = rowIndex;
    }
}
