import { Textbox } from "../components/textbox/Textbox";
import { AllowManualInput } from "./TailorExtensionParam";

export class TailorExtensionParamInputCreator {
    public static create(id: string, descr: string, required: boolean, allowManualInput: AllowManualInput, forSetupScreen: boolean = false): Textbox {
        const result = new Textbox({
            caption: descr,
            field: id,
            id: ("textboxParam_" + id),
            fillRow: true
        });
                if (forSetupScreen === true) {
            //allow the field's caption to explain specified manual input rule
            if (allowManualInput === AllowManualInput.WHEN_BLANK)
                result.caption += " (manual input allowed when value not found automatically)"
            else if (allowManualInput === AllowManualInput.ALWAYS)
                result.caption += " (manual input allowed)"

            //don't want to require a value to be entered on the setup screen if the field allows manual input
            if (allowManualInput === AllowManualInput.NO)
                result.required = required;
        }
        else
            result.required = required;

        if (result.required === true)
            result.placeholder = "Required";
        return result;
    }
}
