import { Api, Clipboard } from "@mcleod/core";
import { AutogenLayoutUserInviteList } from "./autogen/AutogenLayoutUserInviteList";
import { DataDisplayEvent, Label, ClickEvent, TableSelectionEvent, Snackbar } from "@mcleod/components";
import { CommonDialogs } from "@mcleod/common";

export class UserInviteList extends AutogenLayoutUserInviteList {
    /** This is an event handler for the onDataDisplay event of labelStatus. */
    labelStatusOnDataDisplay(event: DataDisplayEvent) {
        const row = event.rowData;
        const status = row.get("status");
        const label = event.target as Label;
        if (status === "A") {
            label.setProps({
                text: "Accepted", backgroundColor: "success",
                tooltip: "This invitation was accepted at " + row.get("accept_date") + "."
            });
        } else if (status === "R") {
            label.setProps({
                text: "Request", backgroundColor: "caution",
                tooltip: "This is a request for an account that was sent on " + row.get("invite_date") + "."
            });
        } else if (status === "P") {
            if (row.getDateTime("expiration_date") < new Date()) {
                label.setProps({
                    text: "Expired", backgroundColor: "subtle",
                    tooltip: "This invitation expired at " + row.get("expiration_date") + "."
                });
            } else {
                label.setProps({
                    text: "Pending", backgroundColor: "primary",
                    tooltip: "This invitation was sent on " + row.get("invite_date") + "."
                });
            }
        } else if (status === "E") {
            label.setProps({
                text: "Error", backgroundColor: "error",
                tooltip: "The following error occurred while sending this invitation: " +
                    row.get("status_message")
            });
        }

        label.color = label.backgroundColor + ".reverse";
    }

    /** This is an event handler for the onClick event of buttonCopyLink. */
    async buttonCopyLinkOnClick(event: ClickEvent) {
        this.buttonCopyLink.busy = true;
        try {
            const inviteId = this.tableUserInvites.selectedRow.data.get("id");
            const response = await Api.post("invitation-link", { invite_id: inviteId });
            Clipboard.copyText(response?.data[0].link);
            Snackbar.showSnackbar("Link copied to clipboard.");
        } finally {
            this.buttonCopyLink.busy = false;
        }
    }

    /** This is an event handler for the onClick event of buttonResend. */
    async buttonResendOnClick(event: ClickEvent) {
        this.buttonResend.busy = true;
        try {
            const inviteId = this.tableUserInvites.selectedRow.data.get("id");
            await Api.post("resend-invitation", { invite_id: inviteId });
            Snackbar.showSnackbar("Invitation resent.");
        } finally {
            this.buttonResend.busy = false;
        }
    }

    /** This is an event handler for the onSelect event of tableUserInvites. */
    tableUserInvitesOnSelect(event: TableSelectionEvent) {
        const row = this.tableUserInvites.selectedRow;
        const status = row?.data.get("status");
        this.buttonResend.enabled = status === "P" || status === "E";
        this.buttonCopyLink.enabled = status === "P" && row.data.getDateTime("expiration_date") > new Date();
        this.buttonAccept.enabled = status === "R";
    }

    /** This is an event handler for the onClick event of buttonAccept. */
    async buttonAcceptOnClick(event: ClickEvent) {
        CommonDialogs.showInputDialog("Enter the user id to assign to this user", "Assign User ID").then(async(userId) => {
            this.buttonAccept.busy = true;
            try {
                const data = this.tableUserInvites.selectedRow.data;
                const inviteId = data.get("id");
                await Api.post("accept-request", { invite_id: inviteId, user_id: userId});
                data.set("status", "A");
                data.set("accept_date", new Date());
                this.tableUserInvites.redisplaySingleRow(this.tableUserInvites.selectedIndex, data, false, false);
                Snackbar.showSnackbar("Request has been accepted.");
            } finally {
                this.buttonAccept.busy = false;
            }
        });
    }
}
