import { DbDisplayValue, LogManager, ValueDisplayValue } from "@mcleod/core";
import { Component } from "../base/Component";
import { Label } from "..";
import { LabelProps } from "../components/label/LabelProps";
import { DropdownItem } from "../components/textbox/DropdownItem";

const log = LogManager.getLogger("component.page.ComponentFactory");

export type LabelSource = Label | string | number | Partial<LabelProps> |
    DropdownItem | DbDisplayValue | ValueDisplayValue;
export type ComponentSource = Component | LabelSource;
export type ComponentCreator = ComponentSource | ((args?: any) => ComponentSource);

export class ComponentFactory {
    /**
     * This function accepts a ComponentCreator (which defines many types of arguments that can be used to
     * create a Component) and uses that parameter to create a component, which is then returned.
     *
     * When the provided ComponentCreator is a function, that function is called before evaluating the other
     * types.  The function should return a type defined in the ComponentSource type. If that function needs arguments, they can be passed using the argsIfFunction parameter.
     *
     * @param creator A ComponentCreator that will be used to create the resulting component.
     * @param defaultLabelProps When a Label is created, these properties (a partial set of LabelProps) are applied to
     *                          the resulting Label.
     * @param argsIfFunction When ComponentCreator is a function, use this varargs parameter to pass arguments to
     *                       that function when it is invoked.
     */
    public static createCommon(creator: ComponentCreator, defaultLabelProps?: Partial<LabelProps>,
        ...argsIfFunction: any[]): Component {
        let value = creator;
        if (typeof value === "function")
            value = value(...argsIfFunction);
        if (value == null)
            return null;
        if (value instanceof Component)
            return value;
        return this.createLabel(value as LabelSource, defaultLabelProps);
    }

    /** Creates a Label from any of the types defined by ComponentSource.
     *
     * @param source A ComponentSource that will be used to create the resulting Label.
     * @param defaultLabelProps These properties (a partial set of LabelProps) are applied to the resulting Label.
    */
    public static createLabel(source: LabelSource, defaultLabelProps?: Partial<LabelProps>): Label {
        if (source instanceof Label)
            return source;
        if (source == null)
            return null;

        let labelText = null;
        if (typeof source === "string")
            labelText = source;
        else if (typeof source === "number")
            labelText = source.toString();
        else if (source instanceof ValueDisplayValue || source instanceof DbDisplayValue || source instanceof DropdownItem)
            labelText = source.displayValue;
        if (labelText != null)
            return new Label({ ...defaultLabelProps, text: labelText });
        
        if (typeof source === "object") { // Should be Partial<LabelProps>
            return new Label({ ...defaultLabelProps, ...source });
        }

        log.info("Could not create label from provided source", source);
        throw new Error("Could not create label from provided source.");
    }

    public static createMultipleCommon(creators: ComponentCreator[], defaultLabelProps?: Partial<LabelProps>,
        ...argsIfFunction: any[]): Component[] {
        const result: Component[] = [];
        if (creators != null) {
            for (const creator of creators) {
                result.push(this.createCommon(creator, defaultLabelProps, ...argsIfFunction));
            }
        }
        return result;
    }

    public static createBulletedList(labelProps: Partial<LabelProps>[] | string[]): Label[] {
        const result: Label[] = [];
        for (const singleLabelProps of labelProps) {
            const label = this.createCommon(singleLabelProps) as Label;
            label.text = "\u2022 " + label.text;
            result.push(label);
        }
        return result;
    }
}
