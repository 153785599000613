export class CircularArray<T> {
    array: T[] = [];
    size: number;
    head: number = 0;
    tail: number = 0;

    constructor(size: number) {
        this.size = size;
    }

    push(value: T) {
        if (this.size > 0) {
            if (this.array.length < this.size) {
                this.array.push(value);
                this.tail++;
            }
            else {
                this.array[this.tail] = value;
                this.head++;
                this.tail++;
                if (this.tail > this.size)
                    this.tail = 0;
                if (this.head > this.size)
                    this.head = 0;
            }
        }
    }

    getFlattened(): T[] {
        if (this.head <= this.tail)
            return this.array.slice(this.head, this.tail);
        else {
            const start = this.array.slice(this.head);
            const end = this.array.slice(0, this.tail);
            return start.concat(end);
        }
    }
}
