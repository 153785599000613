import { DataSource, HorizontalSpacer, Layout, Panel, SearchButton, Switch, Table, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutPermissionsSearch extends Layout {
    horizontalspacer1: HorizontalSpacer;
    panel1: Panel;
    searchbuttonSearch: SearchButton;
    sourceUserPermsSearch: DataSource;
    switchUserOrGroup: Switch;
    tablePerms: Table;
    textboxGroup: Textbox;
    textboxUser: Textbox;
}
