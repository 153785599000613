import JSZip from "jszip";

export class ZipUtil {
    public static async getSingleFileContents(zipContents:string): Promise<string> {
        const jszip = new JSZip();
        await jszip.loadAsync(zipContents);
        const keys = Object.keys(jszip.files);
        if (keys.length !== 1)
            throw new Error("Expected 1 file in zip, found " + keys.length);
        const file = jszip.files[keys[0]];
        const result = await file.async("string");
        return result;
    }
}