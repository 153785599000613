export const ThemeDarkPalette = {
    palette: {
        default: {
            base: "#ccc",
            light: "#5a5a5a",
            lighter: "#8c8c8c",
            lightest: "#bfbfbf",
            dark: "#4c4c4c",
            darker: "#333",
            darkest: "#fff",
            reverse: "#000"
        },
        primary: {
            base: "#4FC1FF",
            light: "#52C3FF",
            lighter: "#52C3FF",
            lightest: "#52C3FF",
            slight: "#52C3FF",
            dark: "#054075",
            darker: "#054075",
            darkest: "#054075",
            reverse: "#fff"
        },
        success: {
            base: "#458719",
            light: "#74a553",
            lighter: "#a2c38c",
            lightest: "#b8d1a8",
            dark: "#326412",
            darker: "#224809",
            darkest: "#162f04",
            reverse: "#fff",
        },
        warning: {
            base: "#ef7200",
            light: "#f2852d",
            lighter: "#eea66b",
            lightest: "#edccb1",
            dark: "#c35700",
            darker: "#843300",
            darkest: "#4f1900",
            reverse: "#fff",
        },
        error: {
            base: "#b60909",
            light: "#ce0909",
            lighter: "#dc0909",
            lightest: "#fe4848",
            dark: "#870505",
            darker: "#550202",
            darkest: "#400202",
            reverse: "#fff",
        },
        caution: {
            base: "#b60909",
            light: "#ffce53",
            lighter: "#ffe091",
            lightest: "#fef2d1",
            dark: "#c68e00",
            darker: "#7a5000",
            darkest: "#271700",
            reverse: "#fff",
        },
        subtle: {
            base: "#979797",
            light: "#333",
            lighter: "#333",
            lightest: "#333",
            dark: "#888",
            darker: "#666",
            darkest: "#444",
            reverse: "#fff"
        }
    }
}
