import { ClearButtonVisible, Dialog, DialogProps, PanelProps, Textbox, Tree, TreeNode, ValidationResult } from "@mcleod/components";
import { Alignment, Api, LogManager, StringUtil } from "@mcleod/core";
import { SpecialtyPropertyEditor } from "./SpecialtyPropertyEditor";

const log = LogManager.getLogger("designer.ui.AbstractPanelOpenTree");

export abstract class AbstractPanelOpenTree extends SpecialtyPropertyEditor {
    abstract getApiName(): string;
    abstract getCustomizedTooltip(): string;

    tree: Tree;
    search: Textbox;
    data: any;

    constructor(currentSelection?: string, props?: Partial<PanelProps>) {
        super({ fillRow: true, fillHeight: true, minWidth: 400, minHeight: 400, ...props });
        this.tree = new Tree({ fillHeight: true, fillRow: true, borderWidth: 1, borderRadius: 4, borderColor: "strokeSecondary", nodeLeafImageName: "form" });
        this.search = new Textbox({ placeholder: "Search", captionVisible: false, padding: 0, fillRow: true, marginBottom: 8, clearButtonVisible: ClearButtonVisible.YES, height: 32 });
        this.search.addChangeListener(() => this.populateTree());
        this.search.addKeyDownListener(event => this.tree.handleKey(event));
        this.add(this.search, this.tree);
        Api.search(this.getApiName(), this.getSearchFilter()).then(response => {
            this.setDataFromResponse(response);
            this.populateTree();
            const nodeToSelect = this.findCurrentlySelected(currentSelection);
            if (nodeToSelect != null) {
                log.debug("found node matching current selection of:", currentSelection, ", nodeToSelect:", nodeToSelect);
                nodeToSelect.path?.forEach(node => node.expanded = true);
                this.tree.selectedNode = nodeToSelect;
                nodeToSelect.scrollIntoView();
            }
        });
    }

    findCurrentlySelected(currentSelection: string): TreeNode {
        let nodeToSelect = null;
        if (!StringUtil.isEmptyString(currentSelection)) {
           this.tree.getRootNode().recurseChildren(node => {
                if (this.getResultValueFromNode(node) === currentSelection) {
                    nodeToSelect = node;
                    return;
                }
           });
        }
        return nodeToSelect;
    }

    override getDialogProps(): Partial<DialogProps> {
        return {
            ...super.getDialogProps(),
            minHeight: 600,
            minWidth: 500
        };
    }

    setDataFromResponse(response: any) {
        this.data = response.data[0];
    }

    override async showAsDialog(adhocDialogProps?: Partial<DialogProps>): Promise<Dialog> {
        let additionalDialogProps = adhocDialogProps;
        const localStorageKey = this.getLocalStorageKey();
        if (localStorageKey != null) {
            additionalDialogProps = {
                resizable: true,
                localStorageKey,
                ...additionalDialogProps
            };
        }

        const dialog = await super.showAsDialog(additionalDialogProps);
        if (dialog == null || dialog.wasCancelled === true)
            this.tree.selectedNode = null;
        return dialog;
    }

    getLocalStorageKey(): string {
        let result = null;
        const title = this.getDialogProps()?.title;
        if (!StringUtil.isEmptyString(title)) {
            result = "PanelOpenTree-" +  StringUtil.toLowerCamelCase(title);
        }
        return result;
    }

    /**
     * Override this to return a filter that should be used when getting the items to open.
     * @returns
     */
    getSearchFilter() {
        return undefined;
    }

    getNodeTextField(): string {
        return "name";
    }

    getNodeChildField(): string {
        return "children";
    }

    populateTree() {
        const root = this.tree.makeTreeNodesFromObject(this.data, this.getNodeTextField(), this.getNodeChildField(), (node: TreeNode, data) => {
            this.doAfterTreeNodesCreated(node, data)
        });

        if (this.search.text.length > 0) {
            const filter = this.search.text.toLowerCase();
            root.filterChildren((node: TreeNode) => {
                return node.getChildCount() > 0 || node.text.toLowerCase().includes(filter);
            });
            root.expandAll();
        }
        this.tree.getRootNode().setChildren(root.getChildren());
    }

    doAfterTreeNodesCreated(node: TreeNode, data: any) {
        if (data.base_version === false)
            node.setProps({ imageName: "formPencil", imageProps: { color: "primary", tooltip: this.getCustomizedTooltip() } });
    }

    override validate(checkRequired: boolean, showErrors: boolean = true): ValidationResult[] {
        const result = super.validate(checkRequired, showErrors);
        if (this.tree.selectedNode == null) {
            this.tree.showTooltip("Select an item.", { position: Alignment.RIGHT, shaking: true });
            result.push({ component: this.tree, isValid: false, validationMessage: "An item must be selected." });
        }
        // Don't allow a user to select a directory entry in the tree.
        else if (this.tree.selectedNode.hasChildren() === true) {
            this.tree.showTooltip("Please select a single item.", { position: Alignment.RIGHT, shaking: true });
            result.push({ component: this.tree, isValid: false, validationMessage: "Please select a single item." });
        }
        return result;
    }

    getResultValue(): string {
        return this.getResultValueFromNode(this.getSelectedNode());
    }

    getResultValueFromNode(node: TreeNode): string {
        return node?.getPathAsString(1); //param removes service project from front of path
    }

    getSelectedNode(): TreeNode {
        return this.tree?.selectedNode;
    }
}
