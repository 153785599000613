import { Component, Container, DesignableObject, DesignerInterface, Layout, LayoutProps, MouseEvent, ResourceFileProps, Table, TableColumn } from "@mcleod/components";
import { DesignerToolAcceptor } from "@mcleod/components/src/base/DesignerInterface";

export abstract class DesignerAdapter extends Layout implements DesignerInterface {

    constructor(props?: Partial<LayoutProps>) {
        super(props);
    }

    addEventHandlerFunction(component: DesignableObject, eventPropName: string): void {}
    selectComponent(component: DesignableObject, addToSelection?: boolean): void {}
    redisplayProp(propName: string, propValue: any): void {}
    addDesignerContainerProperties(component: Component, minWidth: number, minHeight: number, width?: number, allowDropAcceptor?: DesignerToolAcceptor): void {}
    modified(): void {}
    componentDropped(comp: Component): void {}
    getActiveTab(): any {}
    applyChangeToSelectedComponents(data: any, newValue: any): void {}
    selectedComponents: Component[] = [];
    displayDataSourceTools(): void {}
    allowsDrop: boolean = false;
    addDragAndDropListeners(component: Component): void {}
    openTab(props: ResourceFileProps, selectTab?: boolean): any {}
    filterProps(props: any, selectedComponent: Component): void {}
    disablePropertyEditors(prop: any, editorComponents: Component[], selectedComponent: Component): void {}
    addTableColumn(table: Table): TableColumn { return null as any; }
    canModifyProp(propName: string, component: Component): boolean { return false; }
    setPropsForDeserialization(componentType: string, props: any): any {}
    executeAction(action: any): void {}
    doAfterPropChanged(component: Component, propName: string, oldValue: any, newValue: any, redisplayProp?: boolean): void {}
    doBeforePropChanged(component: Component, propName: string): void {}
    onRightClick(component: Component, event: MouseEvent): void {}
    syncPropChanged(component: Component, propName: string, oldValue: any, newValue: any, redisplayProp?: boolean) { }
    addTool(serializationName: string, displayName: string, container: Container):Component { return null}
}
