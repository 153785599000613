import { StringUtil } from "./StringUtil";

export class ConversionUtil {
    private static trueValues: string[] = ["y", "yes", "true", "1", "on"];
    private static falseValues: string[] = ["n", "no", "false", "0", "off"];

    /**
     * This method accepts a string that will be parsed to see if it matches
     * any of our "accepted" true or false values.
     *
     * Accepted true values are "y", "yes", "true", "1", "on"
     * Accepted false values are"n", "no", "false", "0", "off"
     *
     * @param value The string value to parse
     * @param valueIfNoMatch The value to return if the passed in value is null or does not match either the accepted true or false values
     * @returns the result of the parse according to the logic described above
     */
    public static parseBoolean(value: string, valueIfNoMatch: boolean = false): boolean {
        if (StringUtil.isEmptyString(value))
            return valueIfNoMatch;
        const compValues = valueIfNoMatch ? this.falseValues : this.trueValues;
        if (compValues.includes(value.toLowerCase()))
            return !valueIfNoMatch;
        else
            return valueIfNoMatch;
    }
}
