import { ComponentPropDefinitionUtil, ComponentPropDefinitions, ComponentProps } from "../../base/ComponentProps";
import { PropType } from "../../base/PropType";

export interface CheckboxProps extends ComponentProps {
    caption: string;
    checked: boolean;
    checkSize: number;
    defaultDataValue: boolean;
    printable: boolean;
    printableDuringAdd: boolean;
    printableDuringSearch: boolean;
    printableDuringUpdate: boolean;
    valueChecked: any;
    valueUnchecked: any;
}

let checkboxPropsDefs: ComponentPropDefinitions;

export class CheckboxPropDefinitions {
    public static getDefinitions(): ComponentPropDefinitions {
        if (checkboxPropsDefs == null) {
            checkboxPropsDefs = {
                ...ComponentPropDefinitionUtil.getComponentPropertyDefinitions(),
                ...ComponentPropDefinitionUtil.getDataBoundPropertyDefinitions(),
                caption: { type: PropType.string, visibleInMcLeodTailor: true, description: "This specifies the text that will appear next to the Checkbox." },
                checked: { type: PropType.bool, defaultValue: false, visibleInMcLeodTailor: true, description: "This toggles whether or not the checkbox is currently selected.  When the user clicks the Checkbox, this value is toggled." },
                checkColor: { type: PropType.string, defaultValue: "primary", visibleInMcLeodTailor: true, description: "This sets the color of the checkbox (not including the caption).  The default is the current theme's primary color." },
                checkSize: { type: PropType.number, defaultValue: 28, visibleInMcLeodTailor: true, description: "This sets this pixel height and width of the checkbox image" },
                valueChecked: { type: PropType.string, defaultValue: "Y", visibleInMcLeodTailor: true, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "This is the data value that will be used when the Checkbox is checked.  The default is 'Y'." },
                valueUnchecked: { type: PropType.string, defaultValue: "N", visibleInMcLeodTailor: true, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "This is the data value that will be used when the Checkbox is unchecked.  The default is 'N'." },
                onChange: { type: PropType.event, category: "Events", addListenerMethod: "addChangeListener", removeListenerMethod: "removeChangeListener", eventSignature: "OnChange(event: ChangeEvent)", description: "This specifies a function to be called when the checkbox value changes." },
                focusable: { type: PropType.bool, defaultValue: true, visibleInMcLeodTailor: true, description: "This toggles whether or not this Checkbox can receive focus." },
                printable: {
                    type: PropType.bool,
                    defaultValue: false,
                    category: "Appearance",
                    visibleInMcLeodTailor: true,
                    description: "This toggles whether the Checkbox appears as user-editable checkbox (printable = false) or as a caption that indicates the current state of the checkbox by printing 'Yes' or 'No' (printable = true).  This is often changed at runtime.",
                },
                printableDuringAdd: {
                    type: PropType.bool,
                    defaultValue: false,
                    category: "Appearance",
                    visibleInMcLeodTailor: true,
                    description: "This toggles whether the Checkbox should appear in its printable state during add mode.",
                },
                printableDuringSearch: {
                    type: PropType.bool,
                    defaultValue: false,
                    category: "Appearance",
                    visibleInMcLeodTailor: true,
                    description: "This toggles whether the Checkbox should appear in its printable state during search mode.",
                },
                printableDuringUpdate: {
                    type: PropType.bool,
                    defaultValue: false,
                    category: "Appearance",
                    visibleInMcLeodTailor: true,
                    description: "This toggles whether the Checkbox should appear in its printable state during update mode.",
                },
                defaultDataValue: { type: PropType.bool, visibleInMcLeodTailor: true, category: "Data", source: "databound", description: "This specifies the default value when entering add mode." },
                onPrintable: { type: PropType.event, category: "Events", addListenerMethod: "addPrintableListener", removeListenerMethod: "removePrintableListener", eventSignature: "OnPrintable(event: PrintableEvent)", description: "This specifies a function to be called when the component's printable property has changed."}
            };
            ComponentPropDefinitionUtil.populateComponentPropNames(checkboxPropsDefs);
        }
        return checkboxPropsDefs;
    }
}
