import { CommonDialogs } from "@mcleod/common";
import { Dialog, DialogProps, Panel } from "@mcleod/components";

/**
 * This class exists to define the basic structure to which all specialty editors must conform.
 */
export abstract class SpecialtyPropertyEditor extends Panel {
    private dialog: Dialog;

    getDialogProps(): Partial<DialogProps> {
        return { height: 600, width: 620 };
    }

    public async show(): Promise<void> {
        await this.showAsDialog();
    }

    public async showAsDialog(adhocDialogProps?: Partial<DialogProps>): Promise<Dialog> {
        this.dialog = await CommonDialogs.showDialog(this, { ...this.getDialogProps(), ...adhocDialogProps});
        return this.dialog;
    }

    public get wasCancelled(): boolean {
        return this.dialog?.wasCancelled === true;
    }

    public abstract getResultValue(): any;

    public getDisplayValue(): any {
        return this.getResultValue();
    }

    public doAfterResultApplied(): void {
    }
}
