import { DOMUtil } from "@mcleod/core";
import { Panel } from "../components/panel/Panel";

/**
 * This class holds a reference to the McLeodMainPage object within the components package so that components can access the object
 * and its children (the PageHeader & Router objects). The static _mcleodMainPage value is set from within McLeodMainPage.ts.
 */
export class McLeodMainPageUtil {
    private static _mcleodMainPage: Panel; //really this is a McLeodMainPage object, but we don't want to cast as that since the components package doesn't know about the common package
    private static _pageHeaderZIndexResetTime: number = new Date().getTime();

    public static getMcLeodMainPage(): Panel {
        return this._mcleodMainPage;
    }

    public static setMcLeodMainPage(mainPage: Panel) {
        this._mcleodMainPage = mainPage;
    }

    public static getRouterPanel(): Panel {
        return this._mcleodMainPage["router"];
    }

    public static getMainPageHeight(): number {
        return this._validateReturnValue(DOMUtil.getStyleAttrAsNumber(DOMUtil.getComputedStyle("height", this._mcleodMainPage._element)));
    }

    public static getMainPageWidth(): number {
        return this._validateReturnValue(DOMUtil.getStyleAttrAsNumber(DOMUtil.getComputedStyle("width", this._mcleodMainPage._element)));
    }

    public static getPageHeader(): Panel {
        return this._mcleodMainPage["header"];
    }

    public static overridePageHeaderZIndex(millis: number) {
        this._pageHeaderZIndexResetTime = new Date().getTime() + millis + 1000;
        this.getPageHeader().style.zIndex = "5000";
    }

    public static resetPageHeaderZIndex() {
        if (this._pageHeaderZIndexResetTime <= new Date().getTime())
            this.getPageHeader().zIndex = this.getPageHeader().zIndex;
    }

    public static getPageHeaderHeight(): number {
        return this._validateReturnValue(DOMUtil.getStyleAttrAsNumber(DOMUtil.getComputedStyle("height", this.getPageHeader()._element)));
    }

    public static getPageHeaderWidth(): number {
        return this._validateReturnValue(DOMUtil.getStyleAttrAsNumber(DOMUtil.getComputedStyle("width", this.getPageHeader()._element)));
    }

    public static getRoutableAreaHeight(): number {
        return this._validateReturnValue(DOMUtil.getStyleAttrAsNumber(DOMUtil.getComputedStyle("height", this.getRouterPanel()._element)));
    }

    public static getRoutableAreaWidth(): number {
        return this._validateReturnValue(DOMUtil.getStyleAttrAsNumber(DOMUtil.getComputedStyle("width", this.getRouterPanel()._element)));
    }

    private static _validateReturnValue(value: number) {
        return (value != null && value >= 0) ? value : 0;
    }

    public static doNotReportNextError(value: boolean) {
        this._mcleodMainPage["doNotReportNextError"] = value;
    }
}
