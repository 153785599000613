import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "common/user-saved-search";

export class AutogenModelUserSavedSearch<RowType extends ModelRow> extends Endpoint<RowType, RowUserSavedSearchFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowUserSavedSearch extends ModelRow<RowUserSavedSearchFields> {
    constructor() {
        super(AutogenUserSavedSearchEndpoint);
    }
}

export interface RowUserSavedSearchFields {
    screen_class_name: string;
    group_id: string;
    search_name: string;
    user_id: string;
    where_clause: string;
}
