import {
    Button, ButtonVariant, ClickEvent, Component,
    Layout, LayoutProps,
    ResourceFileProps, Tabset
} from "@mcleod/components";
import { AuthType, UrlUtil } from "@mcleod/core";
import { AbstractPanelOpenTree } from "../ui/AbstractPanelOpenTree";
import { DesignerTabDescriptor } from "../ui/DesignerTabDescriptor";
import { LocalStorageManager } from "./LocalStorageManager";
import { ResourceDesignerTab } from "./ResourceDesignerTab";

export interface DesignerProps extends Partial<LayoutProps> {
    localStorageManager: LocalStorageManager;
    endpointPath: string;
    open?: string;
}

export abstract class ResourceDesigner<T extends ResourceDesignerTab> extends Layout {
    localStorageManager: LocalStorageManager;
    open: string; // passed as props from URL
    openBase: string; // passed as props from URL
    finishedLoading = false;
    private _endpointPath: string;

    abstract tabset: Tabset;
    abstract createTab(props: ResourceFileProps): T;
    abstract tabsetTools: Button[];
    abstract doAfterTabDefintionLoaded(tab: T, definition: any): Promise<void>;
    abstract doAfterTabAdded(tab: T): void;

    constructor(props: DesignerProps) {
        super({ auth: AuthType.LME, fillHeight: true, scrollX: true, needsServerLayout: false, ...props });
    }

    get selectedComponents(): Component[] {
        return this.getActiveTab()?.selectedComponents ?? [];
    }

    override async onLoad() {
        await this.loadInitialTabs();
        this.tabset?.addAfterTabCloseListener(() => this.afterTabClosed());
    }

    afterTabClosed() {
        if (this.tabset.getComponentCount() === 0)
            this.addNewTab();
    }

    // function called by router that can affect the properties of the router
    getRouterProps() {
        return { padding: 0 };
    }

    protected createTabButton(tooltip: string, imageName: string, onClick: (event: ClickEvent) => void): Button {
        return new Button({ variant: ButtonVariant.round, color: "subtle.darker", tooltip, imageName, onClick});
    }

    async loadInitialTabs() {
        await this.loadFromLocalStorage();
        if (this.open != null) {
            await this.openTab({ path: this.open, baseVersion: this.openBase === "true" }, true);
            this.removeOpenPropsFromURL();
        }

        if (this.tabset.isEmpty()) {
            this.addNewTab();
        }
    }

    private removeOpenPropsFromURL() {
        const adjustedProps = UrlUtil.removePropsFromUrl(window.location.search, ["open", "openBase"]);
        const adjustedPath = window.location.pathname + adjustedProps;
        window.history.replaceState(window.history.state, "McLeod", adjustedPath);
    }

    async loadFromLocalStorage(){
        let tabToSelect = null;
        if (this.localStorageManager != null) {
            const open = this.localStorageManager?.getLastOpen();
            if (open.length > 0)  {
                const lastSel = this.localStorageManager.getLastSelected();
                for (let i = 0; i < open.length; i++) {
                    const tab = await this.openTab(open[i], false);
                    // only select the from local storage if a path wasn't passed as props from the URL
                    if (this.open == null && open[i].equals(lastSel))
                        tabToSelect = tab;
                }
            }
        }

        this.finishedLoading = true;
        if (tabToSelect != null)
            tabToSelect.select();
    }

    get firstSelected(): Component {
        if (this.selectedComponents.length === 0)
            return null;
        return this.selectedComponents[0];
    }

    public get endpointPath(): string {
        return this._endpointPath;
    }

    public set endpointPath(value: string) {
        this._endpointPath = value;
    }

    getActiveTab(): T {
        return this.tabset.getActiveTab() as T;
    }

    findOpenTab(predicate: (tab: T) => boolean): T {
        return [...this.tabset].find(predicate);
    }

    findOpenTabs(predicate: (tab: T) => boolean): T[] {
        return [...this.tabset].filter(predicate);
    }

    modified() {
        this.getActiveTab().modified = true;
    }

    isModified(): boolean {
        return [...this.tabset].some(tab => tab.modified);
    }

    findOpenTabByDescriptor(descriptor: DesignerTabDescriptor, select: boolean = false): T {
        if (descriptor?.path == null)
            return null;
        const openTab = this.findOpenTab(openTab => descriptor.equals(openTab.descriptor));
        if (select && openTab != null)
            openTab.select();
        return openTab;
    }

    addNewTab(): T {
        return this.addTab(this.createTab({ path: null }), true);
    }

    async openTab(props: ResourceFileProps, selectTab: boolean = true): Promise<T> {
        props ??= { path: null };
        if (props.path == null) {
            return this.addNewTab();
        }
        const tab = this.createTab(props);
        const definition = await tab.initilaizeAndGetDefinition();
        const existingTab = this.findOpenTabByDescriptor(tab.descriptor, selectTab);
        if (existingTab != null) {
            if (selectTab === true)
                existingTab.select();
            return existingTab;
        }
        this.addTab(tab, selectTab);
        if (tab.definition != null)
            await this.doAfterTabDefintionLoaded(tab, definition);
        return tab;
    }

    protected addTab(tab: T, selectTab: boolean = true): T {
        this.tabset.add(tab);
        if (this.doAfterTabAdded != null)
            this.doAfterTabAdded(tab);
        if (selectTab === true)
            this.tabset.selectedIndex = this.tabset.getComponentCount() - 1;
        return tab;
    }

    async showOpenDialog(openPanel: AbstractPanelOpenTree, title: string) {
        await openPanel.showAsDialog({ title });
        const selectedNode = openPanel.getSelectedNode();
        if (selectedNode != null) {
            const path = openPanel.getResultValue();
            const baseVersion = selectedNode.data?.base_version ?? true;
            this.openTab({path, baseVersion}, true);
        }
    }
}
