import { Attachment } from "../components/attachment/Attachment";
import { DomEvent } from "./DomEvent";
import { Event, EventListener } from "./Event";

export interface FileRemovalListener extends EventListener {
    (event: FileRemovalEvent): void;
}

export class FileRemovalEvent extends Event {
    private fileName: string = null;

    constructor(component: any, fileName: string, domEvent?: DomEvent) {
        super(component, domEvent);
        this.fileName = fileName;
    }

    public get target(): Attachment {
        return super.target as Attachment;
    }

    public getFileName(): string {
        return this.fileName;
    }
}
