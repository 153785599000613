import { DialogProps, Label, PanelProps, Textbox } from "@mcleod/components";
import { StringUtil } from "@mcleod/core";
import { SpecialtyPropertyEditor } from "./SpecialtyPropertyEditor";

export class MultiLineEditor extends SpecialtyPropertyEditor {
    textbox: Textbox;

    constructor(text: string, panelProps?: Partial<PanelProps>) {
        super({ fillRow: true, fillHeight: true, minWidth: 500, minHeight: 400, ...panelProps });
        const label = new Label({ text: "Enter one value on each line:", fontBold: true });
        this.textbox = new Textbox({ text, multiline: true, captionVisible: false, fillRow: true, fillHeight: true });
        this.add(label, this.textbox)
    }

    override getDialogProps(): Partial<DialogProps> {
        return {
            ...super.getDialogProps(),
            okDefault: false
        }
    }

    public getResultValue(): string {
        return StringUtil.isEmptyString(this.textbox.text) !== true ? this.textbox.text : null;
    }
}
