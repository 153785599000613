import { Component } from "..";
import { DomEvent } from "./DomEvent";
import { Event, EventListener } from "./Event";

export interface CloseListener extends EventListener {
    (event: CloseEvent): void;
}

export class CloseEvent extends Event {
    private _isBefore: boolean;
    
    constructor(component: Component, isBefore: boolean, domEvent?: DomEvent) {
        super(component, domEvent);
        this._isBefore = isBefore;
    }

    public isBefore() {
        return this._isBefore;
    }

    public isAfter() {
        return !this.isBefore();
    }
}
