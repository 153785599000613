import { ArrayUtil, ModelRow, StringUtil } from "@mcleod/core";
import { ComponentSearcher } from "./ComponentSearcher"
import { ComponentSearchResult } from "./ComponentSearchResult";
import { DropdownItem } from "../textbox/DropdownItem";
import { DropdownItemResolver } from "../textbox/DropdownItemResolver";
import { Textbox } from "../textbox/Textbox";
import { TableRow } from "./TableRow";
import { Table } from "./Table";

/**
 * Searcher class that facilitates the searching of values related to textboxes.
 *
 * Always search the textbox's field in the modelRow.  Conditionally include:
 * - The displayed value based on the label's defined format
 * - The lookup model display field
 * - The selected item's display value
 */
export class TextboxSearcher extends ComponentSearcher {
    private textboxDef: any;
    private dropdownItems: DropdownItem[];
    private table: Table;

    constructor(textboxDef: any, alias: string, table: Table) {
        super(textboxDef.field, alias);
        this.textboxDef = textboxDef;
        this.table = table;
    }

    protected addValuesToResult(searchResult: ComponentSearchResult, row: ModelRow) {
        this.addDisplayValue(searchResult, row, this.textboxDef.displayType, this.textboxDef.format);
        this.addLookupModelValues(searchResult, row);
        this.addDropdownItemValues(searchResult, row);
    }

    private addLookupModelValues(searchResult: ComponentSearchResult, row: ModelRow) {
        // If the textbox's def specifies lookup model display/result fields, look in those fields in the ModelRow
        row.getLookupModelData(this.fieldName)?.forEach((lmData: ModelRow) => {
            if (lmData != null) {
                let displayValue: any;
                let resultValue: any;
                if (lmData instanceof ModelRow) {
                    if (this.textboxDef.lookupModelDisplayField != null)
                        displayValue = lmData.get(this.textboxDef.lookupModelDisplayField);
                    if (this.textboxDef.lookupModelResultField != null)
                        resultValue = lmData.get(this.textboxDef.lookupModelResultField);
                }
                else {
                    if (this.textboxDef.lookupModelDisplayField != null)
                        displayValue = lmData[this.textboxDef.lookupModelDisplayField];
                    if (this.textboxDef.lookupModelResultField != null)
                        resultValue = lmData[this.textboxDef.lookupModelResultField];
                }
                searchResult.addValue(displayValue);
                searchResult.addValue(resultValue);
            }
        });
    }

    private addDropdownItemValues(searchResult: ComponentSearchResult, row: ModelRow) {
        this.initDropdownItems();
        const dbValue = row.get(this.fieldName, null);
        const displayValue = this.dropdownItems.find(item => item.value === dbValue)?.displayValue;
        if (StringUtil.isEmptyString(displayValue) === false)
            searchResult.addValue(displayValue);
    }

    private initDropdownItems() {
        if (this.dropdownItems == null) {
            // Do our best to find the available dropdown items (which has limitations)
            let items: DropdownItem[];
            if (this.textboxDef.items != null)
                this.dropdownItems = DropdownItemResolver.createDropdownItems(this.textboxDef.items as []);
            else {
                // If we have a row already deserialized, see if the textbox in the first row of the table has items.  If it
                // does, use those.  This isn't perfect, and assumes that the textbox has the same items in every row, but
                // it at least has a chance of working.
                let textbox: Textbox;
                const firstRow = ArrayUtil.getFirstElement(this.table.allRows) as TableRow;
                if (firstRow != null)
                    textbox = firstRow.findComponentById(this.textboxDef.id) as Textbox;
                if (textbox?.items != null)
                    this.dropdownItems = DropdownItemResolver.resolveItems(textbox);
                else {
                    // We didn't find any items, populate with an empty array so we don't init items again
                    this.dropdownItems = [];
                }
            }
        }
    }
}
