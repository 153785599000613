import { CoreLogger } from "./CoreLogger";
import { Logger } from "./Logger";

export class LogManager {
    private static log: Logger;
    private static readonly loggers = new Map<string, Logger>();

    static resetAllLoggers() {
        for (const logger of this.loggers.values())
            logger.reset();
        this.getLog().debug("All loggers updated from config");
    }

    public static getLogger(name: string, create: boolean = true): Logger {
        let result = this.loggers.get(name);
        if (result == null && create === true) {
            result = new CoreLogger(name);
            this.loggers.set(name, result);
        }
        return result;
    }

    public static registerLogger(logger: Logger) {
        this.loggers.set(logger.name, logger);
    }

    public static loggerExists(name: string): boolean {
        return this.loggers[name] != null;
    }

    private static getLog(): Logger {
        if (this.log == null)
            this.log = LogManager.getLogger("core.logging.LogManager");
        return this.log;
    }
}
