import { Button, HorizontalSpacer, Label, Layout, Panel } from "@mcleod/components";

export abstract class AutogenLayoutReportPreview extends Layout {
    buttonDownloadExcel: Button;
    buttonDownloadPdf: Button;
    buttonIsBusy: Button;
    horizontalspacer1: HorizontalSpacer;
    labelExport: Label;
    labelPreview: Label;
    labelTitle: Label;
    panelHeader: Panel;
    panelPdf: Panel;
    panelPdfPreview: Panel;
}
