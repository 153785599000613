import { Styles } from "@mcleod/core";

Styles.createClass("@keyframes rippleEffect", "to { transform: scale(4); opacity: 0; }", true);

export const ComponentStyles = Styles.make("cmp", {
    base: { padding: "4px", position: "relative" },
    permsEditor: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        position: "absolute",
        paddingRight: "8px"
    },
    hidden: { display: "none !important" },
    hiddenNoCollapse: { visibility: "hidden" },
    borderShadow: { boxShadow: "0px 5px 5px 0px rgb(0 0 0 / 20%)" },
    ripple: {
        position: "absolute",
        transform: "scale(0)",
        left: "0px",
        top: "0px",
        width: "100%",
        height: "100%"
    },
    rippleWrapper: {
        position: "absolute",
        left: "0px",
        top: "0px",
        width: "100%",
        height: "100%",
        overflow: "hidden"
    },
    fill: {
        flex: "1",
        maxWidth: "100%"
    },
    designerCustomComponent: { boxShadow: "inset 0px 0px 2px 2px #ffbb58" },
    designerModifiedBaseComponent: { boxShadow: "inset 0px 0px 2px 2px #ad12b0" },
    designerHasContextMenu: {
        position: "relative",
        border: "1px dotted #999"
    },
    "designerHasContextMenu::after": {
        content: '""',
        position: "absolute",
        top: "0",
        right: "0",
        height: "100%",
        width: "4px",
        background: "#40e3e3",
        pointerEvents: "none"
    }

});
