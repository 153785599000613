import { ChangeEvent, Image, PermDefinitionConstants, Tree, TreeNode } from "@mcleod/components";
import { Collection, MenuItem, ObjectUtil } from "@mcleod/core";
import { ObjectFilterFunction } from "@mcleod/core/src/ObjectUtil";
import { ModelPermsSecureItem, PermsSecurityType, RowPermsSecureItem } from "../models/ModelPermsSecureItem";
import { ModelPermsTree } from "../models/ModelPermsTree";
import { AutogenLayoutPermissionsMain } from "./autogen/AutogenLayoutPermissionsMain";
import { PanelPermsGrant } from "./PanelPermsGrant";
import { PermsContainer } from "./PermsContainer";
import { PermsMenuTreeNode } from "./PermsMenuTreeNode";

interface PermMenuItem extends MenuItem {
    nestedLayoutPaths?: string[];
}

export class PermissionsMain extends AutogenLayoutPermissionsMain implements PermsContainer {
    panelGrant: PanelPermsGrant;
    permsById: Collection<RowPermsSecureItem> = {};
    fieldLevelPermsById: Collection<RowPermsSecureItem> = {};
    nodesById: Collection<PermsMenuTreeNode> = {};
    menuData: PermMenuItem;
    nonMenuData: PermMenuItem;
    actionPerms: PermMenuItem;
    apiPerms: PermMenuItem;
    treeMenuCasted: Tree<PermsMenuTreeNode>;

    onLoad() {
        this.textSearchMenu.visible = false;
        this.switchPermsSet.visible = false;
        this.treeMenuCasted = this.treeMenu as Tree<PermsMenuTreeNode>;
        this.treeMenuCasted.selectedNodeBackgroundColor = "primary.lightest";
        this.treeMenuCasted.selectedNodeColor = "default"
        this.panelGrant = (this.layoutPermsGrant as PanelPermsGrant);
        this.panelGrant.securityType = PermsSecurityType.VIEW;
        this.panelGrant.permsContainer = this;
        this.panelGrant.scrollY = true;
        this.switchPermsSet.allowIndeterminate = true;
        this.switchPermsSet.checked = undefined;
        this.switchPermsSet.addChangeListener(() => this.populateTree());
        this.textSearchMenu.imagePre = new Image({ name: "funnel", color: "#9E9E9E", paddingLeft: 4, height: 28, width: 28 });
        this.loadPerms();
        this.addMountListener(() => {
            this.textSearchMenu.focus();
        });
    }

    loadPerms() {
        this.populatePermCollections().then(response => {
            new ModelPermsTree().search().then(menuData => {
                const response = menuData.modelRows[0];
                this.menuData = response.get("menu");
                this.nonMenuData = response.get("non_menu");
                this.actionPerms = response.get("actions").actions;
                this.apiPerms = response.get("api").api;
                this.populateTree();
                this.textSearchMenu.visible = true;
                this.switchPermsSet.visible = true;
            });
        });
    }

    syncMenuTreeNodes() {
        Object.values(this.nodesById).forEach(node => node.syncUI());
    }

    async populatePermCollections() {
        this.permsById = {};
        this.fieldLevelPermsById = {};
        const rows = await new ModelPermsSecureItem().search();
        rows?.modelRows?.forEach((row: RowPermsSecureItem) => {
            if (row.isViewSecurity() && (row.isEntireScreen() || row.isActionPerm())) {
                this.permsById[this.getPermsKey(row)] = row;
            } else if (row.get("screen_class") != null) {
                this.fieldLevelPermsById[row.get("screen_class")] = row;
            }
        });
    }

    textSearchMenuOnChange(_event: any) {
        this.populateTree();
    }

    treeMenuOnChange(event: ChangeEvent) {
        if (event.oldValue instanceof PermsMenuTreeNode) {
            event.oldValue.syncUI();
        }
        const sel = this.treeMenuCasted.selectedNode;
        if (sel?.page_identifier == null)
            this.panelGrant.setItemBeingEdited(null, null, null, undefined);
        else {
            this.menuItemSelected(sel.page_identifier, sel.isAction);
        }
    }

    menuItemSelected(pageIdentifier: string, isAction: boolean = false) {
        const perms = [];
        if (this.permsById[pageIdentifier] != null)
            perms.push(this.permsById[pageIdentifier]);

        if (isAction)
            this.panelGrant.setItemBeingEdited(RowPermsSecureItem.ACTION, pageIdentifier, [PermDefinitionConstants.ACTION], perms);
        else
            this.panelGrant.setItemBeingEdited(pageIdentifier, RowPermsSecureItem.ENTIRE_SCREEN, [PermDefinitionConstants.ENTIRE_SCREEN], perms);
    }

    private getPermsKey(row: RowPermsSecureItem) {
        if (row.isActionPerm())
            return row.get("item_name");
        return row.get("screen_class");
    }

    permsChanged(row: RowPermsSecureItem, deleted: boolean) {
        if (row != null) {
            const key = this.getPermsKey(row);
            this.permsById[key] = deleted ? null : row;
            const node = this.nodesById[key];
            node?.syncUI(row.get("allow_count"));
        }
    }

    populateTree() {
        this.panelGrant.clear();
        if (this.menuData == null)
            return;

        const rootChildren = [
            this.createHeaderNode("Menu items", this.menuData.items, false),
            this.createHeaderNode("Non-menu items", this.nonMenuData.items, false),
            this.createHeaderNode("Actions", this.actionPerms.items, true),
            this.createHeaderNode("API", this.apiPerms.items, true),
        ];
        for (let i = rootChildren.length - 1; i >= 0; i--)
            if (rootChildren[i].getChildCount() === 0)
                rootChildren.splice(i, 1);
        this.treeMenu.getRootNode().setChildren(rootChildren);
        if (this.filter != null)
            this.treeMenu.getRootNode().expandAll();
        this.treeMenu.selectedNode = null;
    }

    private createHeaderNode(text: string, items: PermMenuItem[], isAction: boolean) {
        let filtered = items;
        const filter = this.filter;
        if (filter != null) {
            filtered = ObjectUtil.deepCopy(filtered);
            filtered = ObjectUtil.filterRecursiveArrayWithFunction<PermMenuItem>(filtered, "items", filter);
        }
        const result = new TreeNode({ text });
        for (const item of filtered)
                this.addTreeNode(result, item, isAction);
        result.removeChildrenMatching(node => node.expandedImageName != null && node.getChildCount() === 0);
        return result;
    }

    private get filter(): ObjectFilterFunction<PermMenuItem>  {
        if (this.textSearchMenu.isEmpty() && this.switchPermsSet.checked == undefined) {
            return null;
        }
        return (item: PermMenuItem) => {
            let result = true;
            if (this.textSearchMenu.text.length > 0)
                result = item.caption?.toLowerCase().includes(this.textSearchMenu.text.toLowerCase());
            if (this.switchPermsSet.checked === false && result) {
                result = result && this.permsById[item.path] != null;
            } else if (this.switchPermsSet.checked === true && result) {
                const layouts = [item.path, ...item.nestedLayoutPaths ?? []];
                result = result && layouts.some(layout => this.fieldLevelPermsById[layout] != null);
            }
            return result;
        }
    }

    getSoleLeaf(node) {
        if (node.getChildCount() > 1)
            return null;
        else if (node.getChildCount() === 0)
            return node;
        else
            return this.getSoleLeaf(node.getChild(0));
    }

    private addTreeNode(parent: TreeNode, item: PermMenuItem, isAction: boolean) {
        if (item.items == null) {
            const node = this.createMenuNode(item, isAction);
            parent.addChild(node);
        }
        else {
            const node = new TreeNode({ text: item.caption, expandedImageName: "minusInBox", collapsedImageName: "addInBox", allowSelect: false });
            parent.addChild(node);
            for (const child of item.items)
                this.addTreeNode(node, child, isAction);
        }
    }

    private createMenuNode(item: PermMenuItem, isAction: boolean): PermsMenuTreeNode {
        const result  = new PermsMenuTreeNode({
            title: item.caption,
            page_identifier: item.path,
            isAction: isAction,
            nestedLayoutPaths: item.nestedLayoutPaths,
            permissionsMain: this
        });
        this.nodesById[item.path] = result;
        return result;
    }

    fieldLevelPermsChanged(path: string) {
        this.populatePermCollections().then(() => {
            this.syncMenuTreeNodes();
            this.menuItemSelected(path);
        });
    }
}
