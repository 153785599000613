import { HorizontalAlignment, ModelRow, VerticalAlignment } from "@mcleod/core";
import { Button, DataSource, DataSourceMode, Label, Panel } from "../..";
import { ButtonProps } from "../../components/button/ButtonProps";
import { ButtonVariant } from "../../components/button/ButtonVariant";
import { PanelProps } from "../../components/panel/PanelProps";
import { ClickListener } from "../../events/ClickEvent";

export interface AdvancedToolbarProps extends PanelProps {

}

export class AdvancedToolbar extends Panel {
    buttonSearch: Button;
    buttonPrevious: Button;
    buttonNext: Button;
    buttonAdd: Button;
    buttonUpdate: Button;
    buttonDelete: Button;
    buttonCancel: Button;
    buttonExecute: Button;
    labelStatus: Label;

    constructor(props: Partial<AdvancedToolbarProps>) {
        super(props, false);
        this.setProps({ verticalAlign: VerticalAlignment.CENTER });
        this.buttonSearch = this.createButton("Search", { imageName: "magnifyingGlass", hotkey: "Alt-S" }, () => this.dataSource.mode = DataSourceMode.SEARCH);
        this.buttonPrevious = this.createButton("Previous", { imageName: "arrow", imageRotation: 90, hotkey: "Alt-P" }, () => this.dataSource.previous());
        this.buttonNext = this.createButton("Next", { imageName: "arrow", imageRotation: 270, hotkey: "Alt-N" }, () => this.dataSource.next());
        this.buttonAdd = this.createButton("Add", { imageName: "add", hotkey: "Alt-A" }, () => this.dataSource.mode = DataSourceMode.ADD);
        this.buttonUpdate = this.createButton("Update", { imageName: "pencil", hotkey: "Alt-U" }, () => this.dataSource.mode = DataSourceMode.UPDATE);
        this.buttonDelete = this.createButton("Delete", { imageName: "delete", hotkey: "Alt-D" }, () => this.dataSource.delete());
        this.buttonCancel = this.createButton("Cancel", { imageName: "circleX", cancel: true }, () => this.dataSource.cancel());
        this.buttonExecute = this.createButton("Execute", { imageName: "circleCheckEmpty", color: "primary", default: true }, () => this.dataSource.execute());
        this.labelStatus = new Label({ text: "No records to display", rowBreak: false, allowSelect: false, align: HorizontalAlignment.CENTER, borderWidth: 1, borderRadius: 4, borderColor: "strokeSecondary", minWidth: 200 });
        this.add(this.buttonSearch);
        this.add(this.buttonAdd);
        this.add(this.buttonUpdate);
        this.add(this.buttonDelete);
        this.add(this.buttonCancel);
        this.add(this.buttonExecute);
        this.add(this.buttonPrevious);
        this.add(this.labelStatus);
        this.add(this.buttonNext);
        this.setProps({ marginBottom: 12, ...props });
    }

    override displayComponentData(data: ModelRow, allData: ModelRow[], rowIndex: number) {
        const mode = this.dataSource?.mode;
        const noMode = mode === DataSourceMode.NONE;
        const atFirst = rowIndex === 0;
        const atLast = this.dataSource == null || rowIndex >= this.dataSource.data.length - 1;
        const onRecord = noMode && rowIndex >= 0;
        this.buttonSearch.enabled = noMode;
        this.buttonPrevious.enabled = onRecord && !atFirst;
        this.buttonNext.enabled = onRecord && !atLast;
        this.buttonDelete.enabled = onRecord;
        this.buttonUpdate.enabled = onRecord;
        this.buttonAdd.enabled = noMode;
        this.buttonExecute.enabled = !noMode;
        this.buttonCancel.enabled = !noMode;
        if (onRecord)
            this.labelStatus.text = "Record " + (rowIndex + 1) + " of " + this.dataSource.data.length;
        else if (this.dataSource?.mode === DataSourceMode.ADD)
            this.labelStatus.text = "Adding a record";
        else if (this.dataSource?.mode === DataSourceMode.UPDATE)
            this.labelStatus.text = "Updating a record";
        else if (this.dataSource?.mode === DataSourceMode.SEARCH)
            this.labelStatus.text = "Entering search filter";
        else
            this.labelStatus.text = "No records to display";
    }

    override get dataSource(): DataSource {
        return super.dataSource;
    }

    override set dataSource(value: DataSource) {
        super.dataSource = value;
        this.displayData(this.dataSource?.activeRow, this.dataSource?.data, this.dataSource?.rowIndex);
    }

    createButton(caption: string, props: Partial<ButtonProps>, clickListener: ClickListener): Button {
        if (props.color === undefined)
            props.color = "subtle.darker";
        const result = new Button({ variant: ButtonVariant.round, tooltip: caption, padding: 6, ...props, imageWidth: 18, imageHeight: 18, rowBreak: false });
        result.addClickListener(clickListener);
        return result;
    }
}
