import { Button, ButtonVariant, ChangeEvent, Label, LayoutProps, Panel, PermissionsDefinition, Table } from "@mcleod/components";
import { ArrayUtil, Collection, ModelRow } from "@mcleod/core";
import { ModelPermsAllowDeny, RowPermsAllowDeny } from "../models/ModelPermsAllowDeny";
import { RowPermsSecureItem } from "../models/ModelPermsSecureItem";
import { PanelPermsGrant } from "./PanelPermsGrant";
import { AutogenLayoutPanelIndividualSecure } from "./autogen/AutogenLayoutPanelIndividualSecure";

export interface PanelIndividualSecureProps extends LayoutProps {
    permsGrant: PanelPermsGrant;
    secureItem: RowPermsSecureItem;
    def: PermissionsDefinition;
    pageIdentifier: string;
    itemName: string;
}

type UserOrGroup = "U" | "G";

interface AllowListItem {
    row: RowPermsAllowDeny;
}

export class PanelIndividualSecure extends AutogenLayoutPanelIndividualSecure implements PanelIndividualSecureProps {
    secureItem: RowPermsSecureItem;
    permsGrant: PanelPermsGrant;
    def: PermissionsDefinition;
    pageIdentifier: string;
    itemName: string;
    private allows: RowPermsAllowDeny[];

    async onLoad() {
        await this.loadAllows(true);
        this.labelSecurityType.text = this.def.description;
        this.addClickListener(() => {
            if (this.permsGrant)
                this.permsGrant.selectedChild = this
        });
    }

    updateUI(duringLoad: boolean = false, deleting: boolean = false) {
        const secured = this.secureItem != null && !deleting;
        const someAllowed = this.allows?.length > 0;
        const availableToSomeDescription = this.def.availableToSomeDescription || (this.def.availableToNoneDescription + " except:");
        this.switchSecured.checked = secured;
        this.panelAllowed.visible = secured;
        this.imageSwitchLeft.name = secured ? null : "threePeople";
        this.imageSwitchRight.name = secured ? (someAllowed ? "threePeopleBlueMiddle" : "threePeople") : null;
        this.labelDescription.text = secured ? (someAllowed ? availableToSomeDescription : this.def.availableToNoneDescription) : this.def.availableToAllDescription;
        if (this.secureItem != null)
            this.secureItem.set({ allow_count: this.allows?.length || 0 });
        if (!duringLoad)
            this.permsGrant.childChanged(this.secureItem, deleting);
        this.fillUserList();
    }

    fillUserList() {
        if (this.permsGrant.userGroups == null)
            return;
        this.syncTable(this.permsGrant.tableGroups, this.permsGrant.userGroups, "G");
        this.syncTable(this.permsGrant.tableUsers, this.permsGrant.users, "U");
    }

    private syncTable(table: Table, modelRows: Collection<ModelRow>, userOrGroup: UserOrGroup) {
        table.clearRows();
        for (const row of Object.values(modelRows)) {
            const id = row.get("id");
            if (!this.alreadyAllowed(id, userOrGroup)) {
                const descr = row.get("descr") || id;
                table.addRow(new ModelRow(null, false, { descr, id }), null, { display: true });
            }
        }
    }

    private alreadyAllowed(id: string, userOrGroup: UserOrGroup): boolean {
        if (this.allows != null)
            for (const item of this.allows)
                if (item.get("user_or_group_id") === id && item.get("user_or_group_flag") === userOrGroup)
                    return true;
        return false;
    }

    async addAllowed(userOrGroup: UserOrGroup, ...ids: string[]) {
        for (const id of ids) {
            const row = new RowPermsAllowDeny();
            row.set({
                screen_class: this.pageIdentifier,
                item_name: this.itemName,
                security_type: this.def.permsType,
                user_or_group_id: id,
                user_or_group_flag: userOrGroup,
                grant_deny_flag: "G"
            });
            await row.post();
            this.allows.push(row);
            this.panelAllowed.add(this.createAllowItem(row));
        }
        this.updateUI();
    }

    switchSecuredOnChange(event: ChangeEvent) {
        if (!event.userInitiatedChange)
            return;
        this.permsGrant.selectedChild = this;
        if (event.newValue) {
            if (this.secureItem == null)
                this.secureItem = new RowPermsSecureItem();
            this.secureItem.set({
                screen_class: this.pageIdentifier,
                item_name: this.itemName,
                security_type: this.def.permsType,
            });
            this.secureItem.post();
            this.loadAllows();
        }
        else if (this.secureItem != null && !event.newValue) {
            this.secureItem.delete();
            this.updateUI(false, true);
            this.secureItem = null;
            this.width = 348;
        }
    }

    async loadAllows(duringLoad: boolean = false): Promise<void> {
        return new ModelPermsAllowDeny().search({ item_name: this.itemName, security_type: this.def.permsType, screen_class: this.pageIdentifier }).then(rows => {
            this.panelAllowed.removeAll();
            for (const row of rows.modelRows) {
                if (row.get("grant_deny_flag") === "G")
                    this.panelAllowed.add(this.createAllowItem(row));
            }
            this.allows = rows.modelRows;
            this.updateUI(duringLoad);
        });
    }

    private getDisplayName(row: RowPermsAllowDeny): string {
        let result: string;
        if (row.get("user_or_group_flag") === "G")
            result = this.permsGrant.userGroups[row.get("user_or_group_id")]?.get("descr");
        else
            result = this.permsGrant.users[row.get("user_or_group_id")]?.get("name");
        return result || row.get("user_or_group_id");
    }

    createAllowItem(row: RowPermsAllowDeny) {
        const result = new Panel({ fillRow: true });
        const label = new Label({ text: this.getDisplayName(row), rowBreak: false, fillRow: true, imageColor: "primary" });
        const buttonDelete = new Button({ imageName: "x", variant: ButtonVariant.round, color: "subtle.darker" });
        label.imageName = row.get("user_or_group_flag") === "U" ? "personSolid" : "threePeople";
        buttonDelete.addClickListener(() => {
            row.delete();
            ArrayUtil.removeFromArray(this.allows, row);
            this.panelAllowed.remove(result);
            this.updateUI();
            this.permsGrant.selectedChild = this;
        });
        result.add(label, buttonDelete);
        (result as unknown as AllowListItem).row = row;
        return result;
    }
}
