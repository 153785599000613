import { AutogenModelUserSavedSearch, AutogenRowUserSavedSearch } from "./autogen/AutogenModelUserSavedSearch";

export class ModelUserSavedSearch extends AutogenModelUserSavedSearch<RowUserSavedSearch> {
    constructor() {
        super(RowUserSavedSearch);
    }
}

export class RowUserSavedSearch extends AutogenRowUserSavedSearch {
}
