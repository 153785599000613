import { Button, Chart, HorizontalSpacer, Label, Layout, Panel, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutLogAnalyzer extends Layout {
	buttonMatchCase: Button;
	buttonMinimizeSummary: Button;
	buttonNextHighlight: Button;
	buttonNextSearch: Button;
	buttonPopout: Button;
	buttonPreviousHightligh: Button;
	buttonPreviousSearch: Button;
	buttonRegex: Button;
	chartDBExecByTime: Chart;
	horizontalspacer1: HorizontalSpacer;
	labelHighlightHeader: Label;
	labelHighlights: Label;
	labelLog: Label;
	labelSearchResultHeader: Label;
	labelSearchResults: Label;
	labelSummaryHeader: Label;
	panelHeader: Panel;
	panelHighlightDetail: Panel;
	panelHighlights: Panel;
	panelLog: Panel;
	panelOptions: Panel;
	panelSearchActions: Panel;
	panelSearchDetail: Panel;
	panelSummary: Panel;
	panelSummaryContent: Panel;
	panelSummaryHeader: Panel;
	panelSummaryLabels: Panel;
	textDatabaseCount: Textbox;
	textDatabaseTime: Textbox;
	textErrorCount: Textbox;
	textFindResultIndex: Textbox;
	textHighlightIndex: Textbox;
	textLogCount: Textbox;
	textLogSpan: Textbox;
	textSearch: Textbox;
}
