export const props = { viewBox: "0 0 24 24", fill : "none"};
export const innerHTML =
'<path d="M14.8205 2.88245L15.4282 2.71961L15.4718 2.88245H17.0248L16.8771 2.33138C16.6627 1.53118 15.8402 1.05631 15.04 1.27072L9.02492 2.88245H14.8205Z" fill="#137CDD"/>' +
'<path d="M5.99999 5.2459L3.8371 5.82544L5.99999 13.8974V19.693L2.38821 6.21367C2.1738 5.41347 2.64868 4.59096 3.44887 4.37655L6.19607 3.64044C6.07128 3.85926 5.99999 4.11253 5.99999 4.38245V5.2459Z" fill="#137CDD"/>' +
'<path d="M6.47924 21.4816L6.52932 21.6685C6.74373 22.4687 7.56624 22.9436 8.36644 22.7291L11.5263 21.8825H7.49999C7.10571 21.8825 6.74697 21.7303 6.47924 21.4816Z" fill="#137CDD"/>' +
'<path d="M9 7.13232C9 6.99425 9.11193 6.88232 9.25 6.88232H10.75C10.8881 6.88232 11 6.99425 11 7.13232V8.63232C11 8.7704 10.8881 8.88232 10.75 8.88232H9.25C9.11193 8.88232 9 8.7704 9 8.63232V7.13232Z" fill="#137CDD"/>' +
'<path d="M13 7.33232C13 7.19425 13.1119 7.08232 13.25 7.08232H17.75C17.8881 7.08232 18 7.19425 18 7.33232V8.43232C18 8.5704 17.8881 8.68232 17.75 8.68232H13.25C13.1119 8.68232 13 8.5704 13 8.43232V7.33232Z" fill="#137CDD"/>' +
'<path d="M13 11.8323C13 11.6943 13.1119 11.5823 13.25 11.5823H17.75C17.8881 11.5823 18 11.6943 18 11.8323V12.9323C18 13.0704 17.8881 13.1823 17.75 13.1823H13.25C13.1119 13.1823 13 13.0704 13 12.9323V11.8323Z" fill="#137CDD"/>' +
'<path d="M9 11.6323C9 11.4943 9.11193 11.3823 9.25 11.3823H10.75C10.8881 11.3823 11 11.4943 11 11.6323V13.1323C11 13.2704 10.8881 13.3823 10.75 13.3823H9.25C9.11193 13.3823 9 13.2704 9 13.1323V11.6323Z" fill="#137CDD"/>' +
'<path d="M13 16.3323C13 16.1943 13.1119 16.0823 13.25 16.0823H17.75C17.8881 16.0823 18 16.1943 18 16.3323V17.4323C18 17.5704 17.8881 17.6823 17.75 17.6823H13.25C13.1119 17.6823 13 17.5704 13 17.4323V16.3323Z" fill="#137CDD"/>' +
'<path d="M9 16.1323C9 15.9943 9.11193 15.8823 9.25 15.8823H10.75C10.8881 15.8823 11 15.9943 11 16.1323V17.6323C11 17.7704 10.8881 17.8823 10.75 17.8823H9.25C9.11193 17.8823 9 17.7704 9 17.6323V16.1323Z" fill="#137CDD"/>' +
'<path fill-rule="evenodd" clip-rule="evenodd" d="M6 4.38232C6 3.5539 6.67157 2.88232 7.5 2.88232H19.5C20.3284 2.88232 21 3.5539 21 4.38232V20.3823C21 21.2108 20.3284 21.8823 19.5 21.8823H7.5C6.67157 21.8823 6 21.2108 6 20.3823V4.38232ZM7.5 4.38232H19.5V20.3823H7.5V4.38232Z" fill="#137CDD"/>';