import { DataSourceExecutionEvent } from "@mcleod/components";
import { AutogenLayoutSendUserInvitation } from "../autogen/AutogenLayoutSendUserInvitation";

export class SendUserInvitation extends AutogenLayoutSendUserInvitation {
    afterInviteSent: () => void;

    /** This is an event handler for the afterExecution event of sourceUserInvite.  */
    sourceUserInviteAfterExecution(event: DataSourceExecutionEvent) {
        this.afterInviteSent?.()
    }
}