import { ImageName } from "@mcleod/images";
import { ComponentPropDefinitionUtil, ComponentPropDefinitions, ComponentProps } from "../../base/ComponentProps";
import { PropType } from "../../base/PropType";
import { PanelPropDefinitions } from "../panel/PanelProps";

export interface StarRatingProps extends ComponentProps {
    imageName: ImageName;
    maxStars: number;
}

let starRatingPropDefs: ComponentPropDefinitions;

export class StarRatingPropDefinitions {
    public static getDefinitions(): ComponentPropDefinitions {
        if (starRatingPropDefs == null) {
            starRatingPropDefs = {
                ...PanelPropDefinitions.cloneDefs(),
                imageName: { type: PropType.image, defaultValue: "star", description: "This specifies the image name that will be used for this rating." },
                maxStars: { type: PropType.number, defaultValue: 5, description: "This specifies the number of stars (filled and unfilled) that will be displayed." },
                onChange: { type: PropType.event, category: "Events", addListenerMethod: "addChangeListener", removeListenerMethod: "removeChangeListener", eventSignature: "OnChange(event: ChangeEvent)", description: "This specifies a function to be called when the value changes." },
            };
            ComponentPropDefinitionUtil.populateComponentPropNames(starRatingPropDefs);
        }
        return starRatingPropDefs;
    }
}
