export class EmailUtil {
    public static isValidEmail(email: string): boolean {
        return /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.(([0-9]{1,3})|([a-zA-Z]{2,3})|(aero|coop|info|museum|name))$/.test(email);
    }

    public static getMailToLink(recipient: string, subject?: string, body?: string) {
        let result = "mailto:" + encodeURIComponent(recipient);
        if (subject != null)
            result += "?subject=" + encodeURIComponent(subject);
        if (body != null)
            result += "&body=" + encodeURIComponent(body);
        return result;
    }

    public static sendEmail(recipient: string, subject?: string, body?: string) {
        window.location.href = EmailUtil.getMailToLink(recipient, subject, body);
    }
}
