export const props = { viewBox: "0 0 22 22"};

export const innerHTML = `<g id="activate-slideout">
<g id="Group 3">
<path id="Combined Shape" fill-rule="evenodd" clip-rule="evenodd" d="M1.6875 0H19.3359C20.2664 0 21.0234 0.754687 21.0258 1.6875V19.3383C21.0258 20.2711 20.2711 21.0258 19.3383 21.0258H1.6875C0.754687 21.0258 0 20.2711 0 19.3383V1.6875C0 0.754687 0.754687 0 1.6875 0ZM1.6875 19.3383H19.3359V1.6875H1.6875V19.3383Z"/>
</g>
<g id="Group">
<g id="path-4-link">
<path id="path-4" d="M11.3702 17.6299L12.6039 16.3961L7.72143 11.5049H17.6299V9.75488H7.72143L12.6039 4.86363L11.3702 3.62988L4.37018 10.6299L11.3702 17.6299Z"/>
</g>
</g>
</g>`;