import { ConfigSettings, LicenseSettings, SettingsManager } from "@mcleod/core";
import { TenantGeneralSettings } from "./TenantGeneralSettings";
import { TenantUserSettings } from "./TenantUserSettings";
import { TenantCompanySettings } from "./TenantCompanySettings";

export class TenantSettingsManager {
    static initializeSettings() {
        SettingsManager.register(new ConfigSettings());
        SettingsManager.register(new LicenseSettings());
        SettingsManager.register(new TenantCompanySettings());
        SettingsManager.register(new TenantGeneralSettings());
        SettingsManager.register(new TenantUserSettings());
    }
}