import { Alignment, JSUtil } from "@mcleod/core";
import { Component } from "../base/Component";
import { ComponentUtil } from "../base/ComponentUtil";

export interface AnchorProps {
    anchor: Component | HTMLElement;
    align: Alignment;
    position: Alignment;
    minHeight?: number;
    minWidth?: number;
    minOnScreen?: number;
    onMutate?: (elem: HTMLElement, mutation: AnchorMutation) => void;
}

export interface AnchorMutation {
    newRect: DOMRect;
    newAnchor: AnchorProps;
}

export class Anchor {
    public static sizeToAnchor(component: Component, anchor: AnchorProps) {
        const anchorElement: HTMLElement = anchor.anchor instanceof Component ? anchor.anchor._element : anchor.anchor;
        const anchorRect = anchorElement.getBoundingClientRect == null ? anchorElement.getBoundingClientRect() : anchorElement.getBoundingClientRect();

        if (anchor.position === Alignment.TOP) {
            if (component.width === undefined)
                component.width = anchorRect.width;
            else if (typeof component.width === "number")
                component.top = anchorRect.top - component.width;
            component.left = anchorRect.left;
        } else if (anchor.position === Alignment.RIGHT) {
            component.top = anchorRect.top;
            component.left = anchorRect.right;
            if (component.height === undefined)
                component.height = anchorRect.height;
        } else if (anchor.position === Alignment.LEFT) {
            component.top = anchorRect.top;
            if (typeof component.width === "number")
                component.left = anchorRect.left - component.width;
            if (component.height === undefined)
                component.height = anchorRect.height;
        }
        else {
            component.top = anchorRect.bottom;
            if (component.width === undefined)
                component.width = anchorRect.width;
            if (anchor.align === Alignment.LEFT)
                component.left = anchorRect.left;
            else if (typeof component.width === "number")
                component.left = anchorRect.right - component.width;
            else if (component.width === null)
                component.right = document.body.clientWidth - anchorRect.right;
        }
    }

    public static getAnchorRect(anchor: AnchorProps) {
        const anchorElement: HTMLElement = anchor.anchor instanceof Component ? anchor.anchor._element : anchor.anchor;
        return anchorElement.getBoundingClientRect();
    }

    public static alignToRect(compOrElem: Component | HTMLElement, rect: DOMRect) {
        const elem = compOrElem instanceof Component ? compOrElem._element : compOrElem;
        elem.style.left = rect.left + "px";
        elem.style.top = rect.top + "px";
        elem.style.height = rect.height + "px";
        elem.style.width = rect.width + "px";
        return true;
    }

    public static alignRect(rect: DOMRect, anchor: AnchorProps): DOMRect {
        const anchorRect = ComponentUtil.getRect(anchor.anchor);
        if (anchorRect == null)
            return rect;
        let x = anchorRect.x;
        let y = anchorRect.y;
        if (anchor.position === Alignment.TOP)
            y = anchorRect.top - rect.height;
        else if (anchor.position === Alignment.RIGHT)
            x = anchorRect.right;
        else if (anchor.position === Alignment.LEFT)
            x = anchorRect.left - rect.width;
        else
            y = anchorRect.bottom;

        if (anchor.align === Alignment.RIGHT)
            x = anchorRect.right - rect.width;
        else if (anchor.align === Alignment.TOP)
            y = anchorRect.y - rect.height;
        else if (anchor.align === Alignment.BOTTOM)
            y = anchorRect.bottom;
        else
            x = anchorRect.left;
        const width = JSUtil.max(rect.width, anchor?.minWidth);
        const height = JSUtil.max(rect.height, anchor?.minHeight);
        return new DOMRect(x, y, width, height);
    }
}
