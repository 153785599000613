/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelProhibited.ts                    *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "common/prohibited";

export class AutogenModelProhibited<RowType extends ModelRow> extends Endpoint<RowType, RowProhibitedFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowProhibited extends ModelRow<RowProhibitedFields> {
    constructor() {
        super(AutogenUserSavedSearchEndpoint);
    }
}

export interface RowProhibitedFields {
    bidirectional_lane: string;
    comments: string;
    company_id: string;
    entered_date: Date;
    entered_user_id: string;
    from_region: string;
    from_region_uid: number;
    id: string;
    parent_row_id: string;
    parent_row_type: string;
    prohibited_driver_id: string;
    prohibited_row_id: string;
    prohibited_row_type: string;
    to_region: string;
    to_region_uid: number;
}

