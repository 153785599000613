import { Button, Chart, DataSource, HorizontalSpacer, Label, Layout, Panel, Splitter, Table, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutHeapAnalyzer extends Layout {
	buttonSysProps: Button;
	buttonThreads: Button;
	buttonToggleHistogram: Button;
	buttonToggleLargeObjects: Button;
	buttonToggleSummary: Button;
	chartHeap: Chart;
	horizontalspacer1: HorizontalSpacer;
	labelCommitIdCaption: Label;
	labelDupStringCaption: Label;
	labelHeapSummary: Label;
	labelHistorgramHeader: Label;
	labelLargeObjects: Label;
	panelHeapSummaryHeader: Panel;
	panelHeapUsageHeader: Panel;
	panelHistogram: Panel;
	panelLargeObjectContent: Panel;
	panelLargeObjectHeader: Panel;
	panelLargeObjects: Panel;
	panelSummary1: Panel;
	panelSummary2: Panel;
	panelSummary4: Panel;
	panelSummary6: Panel;
	panelSummary: Panel;
	panelSummaryButtons: Panel;
	panelSummaryContent: Panel;
	sourceSummary: DataSource;
	splitHistogram: Splitter;
	tableUsageByClass: Table;
	textApplication: Textbox;
	textClassCount: Textbox;
	textCommitId: Label;
	textDupStrings: Label;
	textHeapDate: Textbox;
	textHeapFileName: Textbox;
	textHeapSize: Textbox;
	textHost: Textbox;
	textInstance: Textbox;
	textInstances: Textbox;
	textJavaVersion: Textbox;
	textLMEVersion: Textbox;
	textSCAC: Textbox;
	textSummaryInstances: Textbox;
	textUser: Textbox;
}
