import { Logger, LogManager } from "@mcleod/core";
import { DesignableObjectLogger } from "./DesignableObjectLogger";

export class DesignableObjectLogManager {
    public static getLogger(name: string): Logger {
        let result = LogManager.getLogger(name, false);
        if (result == null) {
            result = new DesignableObjectLogger(name);
            LogManager.registerLogger(result);
        }
        return result;
    }
}
