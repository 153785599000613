import { LogManager } from "@mcleod/core";
import { ReflectiveDialogs } from "../base/ReflectiveDialogs";
import { ValidationResult } from "../base/ValidationResult";
import { DataSource } from "./DataSource";
import { LabelProps } from "../components/label/LabelProps";
import { Component } from "../base/Component";
import { TableCell } from "../components/table/TableCell";

const log = LogManager.getLogger("components.databinding.ValidationUtil");

export class ValidationUtil {
    static showFailedValidationDialog(results: ValidationResult[], useBoundSearchComponents: boolean = false, dataSource?: DataSource) {
        if (results.length > 0) {
            let message = "Unable to save this record because data is missing or invalid.";
            if (useBoundSearchComponents === true)
                message = "Unable to perform search because data is missing or invalid.";
            const lineProps: Partial<LabelProps>[] = [];
            lineProps.push({ text: message, paddingBottom: 12 });
            let componentToFocus = null;
            const uniqueResults = new Set<string>();
            results.forEach(result => {
                const component: Component = result.component;
                if (component) {
                    if (result.caption == null)
                        result.caption = component["caption"] ?? component.displayLabel ?? component.field;
                    if (dataSource != null && dataSource.componentValidationFailedCallback)
                        dataSource.componentValidationFailedCallback(result);
                    const effectiveDataSource = ValidationUtil.getEffectiveDataSource(component);
                    const resultKey = [ effectiveDataSource.id, component.field, result.caption ?? "" ]
                        .join("|");
                    const alreadyListed = uniqueResults.has(resultKey);
                    uniqueResults.add(resultKey);
                    if (result.caption) {
                        componentToFocus ??= result.componentToFocus ?? component;
                        if (alreadyListed !== true)
                            lineProps.push({ text: result.caption, fontBold: true });
                        else
                            log.debug("Not adding duplicate validation message to the dialog: %o", resultKey);
                    }
                }
            });
            ReflectiveDialogs.showDialog(lineProps, { title: "Invalid Data", panelContentProps: { maxHeight: 400, scrollY: true } }).then(() => {
                if (componentToFocus != null)
                    componentToFocus.focus()
            });
        }
    }

    private static getEffectiveDataSource(component: Component) {
        if (component.dataSource != null)
            return component.dataSource;
        if (component.insideTableCell === true)
            return (component.managingComponent as TableCell).getEnclosingTableDataSource();
        return null;
    }

    static checkValidationResults(results: ValidationResult[]): boolean {
        if (results != null){
            for (const result of results){
                if (!result.isValid){
                    return false;
                }
            }
        }
        return true;
    }
}
