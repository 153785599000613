export enum AttachmentFileType {
    ANY = "any",
    PDF = "pdf",
    PNG = "png",
    GIF = "gif",
    JPG = "jpg",
    ANY_IMAGE = "image",
    WORD_DOC = "word",
    ANY_DOCUMENT = "doc"
}
