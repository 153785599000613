import { DataSource, Panel, ResourceFileProps } from "@mcleod/components";
import { Collection } from "@mcleod/core";
import { ResourceDesignerTab } from "../common/ResourceDesignerTab";
import { AbstractModelDesigner } from "./AbstractModelDesigner";
import { ModelDesignerPanel } from "./ModelDesignerPanel";

/**
 * This represents a single tab on the Model Designer.  A tab holds the information needs to display and edit a single .model file.
*/

export class ModelDesignerTab extends ResourceDesignerTab implements ResourceFileProps {
    dataSources: Collection<DataSource>;
    designerPanel: ModelDesignerPanel;

    constructor(designer: AbstractModelDesigner, props: ResourceFileProps) {
        super("model", props);
        this.dataSources = {};
        this.designerPanel = new ModelDesignerPanel(designer);
        this.add(this.designerPanel);
        if (this.path == null) {
            this.designerPanel.def = {};
        }
    }

    getSearchRequestEndpointAndBody(): [string, any] {
        return ["metadata/model", this.getSearchRequestBody()];
    }

    override async createLayoutReferencesPanel(addOnClick: boolean = true): Promise<Panel> {
        return super.createLayoutReferencesPanel(false);
    }
}
