import { DateUtil, FileUtil, LogManager, ModelRow } from "@mcleod/core";
import {
    Button, DataSource, DataSourceAction, DataSourceExecutionEvent, HorizontalSpacer, Image, ImageType, Panel,
    SearchButton
} from "@mcleod/components";
import { ReportUtil } from "./ReportUtil";
import { AutogenLayoutReportPreview } from "./autogen/AutogenLayoutReportPreview";

const log = LogManager.getLogger("common/ReportPreview2");

export class ReportPreview2 extends AutogenLayoutReportPreview {
    private paramPanel: Panel = null;
    private _defaultState = {};
    private reportName = "";
    private buttonGenerateReport = null;
    private imageBytes: string = null;
    private excelBytes: string = null;
    private beforeExecutionListenerRef = (event: DataSourceExecutionEvent) => this.mainDataSourceDoBeforeExecution(event);
    private afterExecutionListenerRef = (event: DataSourceExecutionEvent) => this.mainDataSourceDoAfterExecution(event);

    configure(dataSource: DataSource, sourceParamPanel: Panel, reportName: string) {
        this.labelTitle.text = reportName;
        this.validateProvidedDataSource(dataSource);
        this._defaultState = ReportUtil.getDefaultState(sourceParamPanel);
        this.wrapParameterLayout(sourceParamPanel);
        this.addButtons(sourceParamPanel, dataSource);
        this.attachParameterLayoutDataSource(dataSource);
    }

    private validateProvidedDataSource(dataSource: DataSource) {
        if (dataSource == null) {
            log.debug("The parameter layout needs to provide its DataSource (that will be used when searching).");
            throw new Error("The parameter layout is not properly configured for report generation.")
        }
    }

    private addButtons(sourceParamPanel: Panel, dataSource: DataSource) {
        const buttonPanel = this.createButtonPanel();
        buttonPanel.add(
            this.createClearButton(),
            new HorizontalSpacer(),
            this.createGenerateReportButton(dataSource)
        );
        sourceParamPanel.add(buttonPanel);
    }

    private wrapParameterLayout(sourceParamPanel: Panel) {
        sourceParamPanel.widthFillWeight = 25;
        sourceParamPanel.marginRight = 20;
        this.widthFillWeight = 75;
        const wrapperPanel = new Panel({ fillHeight: true, fillRow: true, scrollY: true });
        wrapperPanel.add(...sourceParamPanel.components);
        sourceParamPanel.removeAll();
        sourceParamPanel.add(wrapperPanel);
        this.paramPanel = wrapperPanel;
    }

    private createButtonPanel(): Panel {
        return new Panel({
            id: "buttonPanel",
            fillRow: true,
            borderTopWidth: 1,
            paddingTop: 20,
            marginTop: 10,
            borderTopColor: "subtle.light"
        });
    }

    private createClearButton(): Button {
        return new Button({
            caption: "Clear Form",
            rowBreak: false,
            color: "primary",
            borderWidth: 0,
            onClick: this.buttonClearOnClick.bind(this)
        });
    }

    private createGenerateReportButton(dataSource: DataSource): Button {
        this.buttonGenerateReport = new SearchButton({
            caption: "Generate Report",
            dataSource: dataSource,
            rowBreak: false,
            backgroundColor: "primary",
            width: 130,
            color: "default.reverse"
        });
        return this.buttonGenerateReport;
    }

    private attachParameterLayoutDataSource(dataSource: DataSource) {
        dataSource.addBeforeExecutionListener(this.beforeExecutionListenerRef);
        dataSource.addAfterExecutionListener(this.afterExecutionListenerRef);
    }

    mainDataSourceDoBeforeExecution(event: DataSourceExecutionEvent) {
        if (event.getAction() === DataSourceAction.SEARCH)
            this.setBusy(true);
    }

    mainDataSourceDoAfterExecution(event: DataSourceExecutionEvent) {
        if (event.getAction() === DataSourceAction.SEARCH)
            this.setData(event.dataSource.data?.[0])
    }

    public setData(row: ModelRow) {
        try {
            if (row != null) {
                this.displayPdf(row.get("pdf_output"));
                this.reportName = row.get("report_name");
                this.imageBytes = row.get("pdf_output");
                this.excelBytes = row.get("excel_output");
            }
            else
                this.clearData();
        }
        finally {
            this.setBusy(false);
        }
    }

    public clearData() {
        this.panelPdfPreview.removeAll();
        this.panelPdfPreview.add(this.labelPreview);

        this.reportName = "";
        this.imageBytes = null;
        this.excelBytes = null;
    }

    private displayPdf(pdfData: string) {
        const imagePdf = new Image({ imageType: ImageType.PDF, fillHeight: true, fillRow: true, id: "reportPdf" });
        imagePdf.imageBytes = pdfData;
        this.panelPdfPreview.removeAll();
        this.panelPdfPreview.add(imagePdf);
    }

    buttonDownloadPdfOnClick() {
        const todaysDate = DateUtil.formatDate(new Date(), "MM/dd/yyyy");
        FileUtil.downloadBase64AsFile(this.imageBytes, `Report ${this.reportName} ${todaysDate}.pdf`);
    }

    buttonDownloadExcelOnClick() {
        const todaysDate = DateUtil.formatDate(new Date(), "MM/dd/yyyy");
        FileUtil.downloadBase64AsFile(this.excelBytes, `Report ${this.reportName} ${todaysDate}.xlsx`);
    }

    buttonClearOnClick() {
        ReportUtil.applyLayoutDefaultState(this.paramPanel, this._defaultState);
    }

    public setBusy(value: boolean) {
        this.buttonGenerateReport.busy = value;
        this.buttonGenerateReport.caption = this.buttonGenerateReport.busy ? "" : "Generate Report"

        this.buttonIsBusy.visible = value;
        this.panelPdfPreview.visible = !value;

        this.buttonDownloadPdf.enabled = !value;
        this.buttonDownloadPdf.imageName = !value ? "pdf" : "pdfDisabled";

        this.buttonDownloadExcel.enabled = !value;
        this.buttonDownloadExcel.imageName = !value ? "excel" : "excelDisabled";
    }
}
