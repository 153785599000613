import { ArrayUtil, Collection, LogManager } from "@mcleod/core";
import { Component } from "../base/Component";
import { PropType } from "../base/PropType";
import { DataSource } from "../databinding/DataSource";
import { ContextMenuLabel } from "../components/contextmenu/ContextMenuLabel";
import { ContextMenuItemsProvider } from "../components/contextmenu/ContextMenuLabelProps";

const log = LogManager.getLogger("components/serializer/ComponentSerializer");

export function serializeComponents(component: Component | Component[], dataSources: Collection<DataSource>) {
    if (Array.isArray(component)) {
        let result = "[\n";
        for (let i = 0; i < component.length; i++) {
            result += serializeComponents(component[i], dataSources);
            if (i < component.length - 1)
                result += ",";
            result += "\n";
        }
        result += "]"
        return result;
    }
    if (component == null) {
        log.info("Attempt to serialize null component %o", component);
        throw new Error("Could not serialize null component.");
    }
    let result = "{\n\"type\": \"" + component.typeName + "\",\n";
    result += serializeProps(component);
    if ((component as any)._serializeNonProps != null)
        result += (component as any)._serializeNonProps();
    result = result.substring(0, result.length - 2);

    if (component.baseVersionProps != null)
        result += serializeBaseProps(component);

    if (dataSources != null && Object.keys(dataSources).length > 0) {
        result += ",\n\"dataSources\": [\n";
        for (const ds of Object.values(dataSources)) {
            result += "{\n";
            result += serializeProps(ds);
            result = result.substring(0, result.length - 2);
            result += "\n},\n";
        }
        result = result.substring(0, result.length - 2) + "\n";
        result += "]\n";
    }
    result += "\n}";
    return result;
}

export function serializeProps(component: Component | DataSource) {
    let result = "";
    for (const key in component.getPropertyDefinitions()) {
        const serializedValue = serializePropValue(component, component[key], key);
        if (serializedValue != null)
            result += "\"" + key + "\": " + serializedValue + ",\n";
    }
    return result;
}

export function serializePropValue(component: Component | DataSource, value: any, propName: any): string {
    const componentProps = component.getPropertyDefinitions();
    const prop = componentProps[propName];
    if ((component as any)._serializeProp != null)
        value = (component as any)._serializeProp(propName, value);
    let defaultValue = component.getPropertyDefaultValue(prop);
    if (defaultValue === undefined && prop.type === PropType.bool)
        defaultValue = false;

    if (value instanceof DataSource) {
        value = value.id;
    } else if (component instanceof Component && prop == componentProps.contextMenuItems) {
        return serializeContextMenuItems(component);
    }

    if (defaultValue instanceof DataSource)
        defaultValue = defaultValue.id;
    if (value != null && value !== defaultValue && (typeof value !== "string" || value.length > 0) && (Array.isArray(value) || typeof value !== "object"))
        return JSON.stringify(value);
}

export function serializeContextMenuItems(component: Component, items: ContextMenuItemsProvider = component.contextMenuItems): string {
    let result = "";

    if (typeof items === "function" || ArrayUtil.isEmptyArray(items))
        return null;

    const serializedItems = items.map(itemDef => {
        const item = new ContextMenuLabel(component, {_designer: component._designer, ...itemDef });
        return `{\n${serializeProps(item).slice(0, -2)}\n}`;
    }).join(",\n");

    result = `[\n${serializedItems}\n]`;
    return result;
}

export function serializeBaseProps(component: Component | DataSource): string {
    const baseProps = { ...component.baseVersionProps };
    for (const key in baseProps) {
        /// null is serialized as an empty string because null values aren't serialized on the server
        if (baseProps[key] == null)
            baseProps[key] = "";
    }
    return `,\n\"baseVersionProps\": ${JSON.stringify(baseProps)}`;
}
