export const props = { viewBox: "0 0 27 26"};
export const innerHTML = '<g clip-path="url(#clip0_454_386)">' +
'<path d="M3.42396 1.25256L3.42396 24.7474C3.42396 25.4211 2.87891 25.9661 2.20521 25.9661C1.53151 25.9661 0.986458 25.4211 0.986458 24.7474L0.986458 1.25256C0.986458 0.578865 1.53151 0.0338135 2.20521 0.0338135C2.87891 0.0338135 3.42396 0.578865 3.42396 1.25256Z"/>' +
'<path d="M21.9925 10.9442L6.91796 10.9442C6.10938 10.9442 5.45521 10.3992 5.45521 9.72546C5.45521 9.05177 6.10938 8.50671 6.91796 8.50671L21.9925 8.50671C22.801 8.50671 23.4552 9.05177 23.4552 9.72546C23.4552 10.3992 22.801 10.9442 21.9925 10.9442Z"/>' +
'<path d="M19.2339 4.94421L6.67396 4.94421C6.00026 4.94421 5.45521 4.39916 5.45521 3.72546C5.45521 3.05177 6.00026 2.50671 6.67396 2.50671L19.2339 2.50671C19.9076 2.50671 20.4526 3.05177 20.4526 3.72546C20.4526 4.39916 19.9076 4.94421 19.2339 4.94421Z"/>' +
'<path d="M25.1358 23.9272L6.77458 23.9272C6.04526 23.9272 5.45521 23.3822 5.45521 22.7085C5.45521 22.0348 6.04526 21.4897 6.77458 21.4897L25.1358 21.4897C25.8652 21.4897 26.4552 22.0348 26.4552 22.7085C26.4552 23.3822 25.8652 23.9272 25.1358 23.9272Z"/>' +
'<path d="M16.9789 17.4036L6.93155 17.4036C6.11546 17.4036 5.45521 16.8585 5.45521 16.1848C5.45521 15.5111 6.11546 14.9661 6.93155 14.9661L16.9789 14.9661C17.795 14.9661 18.4552 15.5111 18.4552 16.1848C18.4552 16.8551 17.795 17.4036 16.9789 17.4036Z"/>' +
'</g>' +
'<defs>' +
'<clipPath id="clip0_454_386">' +
'<rect width="27" height="26" fill="white"/>' +
'</clipPath>' +
'</defs>'