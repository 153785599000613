/**
 * This defines the theme properties for higher level page elements.
 *
 * Since this is a common theme constant, nothing here should reference colors directly and should
 * only refer to palette members.
*/

const _badgeBorderRadius = 18;

export const ThemeCommonPage = {
    dialog: {
        window: {
            backgroundColor: "defaultBackground"
        },
        header: {
            color: "palette.default.base",
            backgroundColor: "headerBackground",
            fontSize: "xlarge",
            fontBold: true
        },
    },
    cta: {
        color: "primary.reverse",
        backgroundColor: "primary"
    },
    tooltip: {
        backgroundColor: "background3",
        color: "palette.default.reverse"
    },
    quickInfo: {
        backgroundColor: "background5",
        borderRadius: 8,
        borderLeftColor: "warning.dark",
        borderLeftWidth: 12,
        padding: 12
    },
    badge: {
        borderRadius: _badgeBorderRadius
    },
    defaultBadge: {
        backgroundColor: "default",
        color: "default.reverse",
        borderRadius: _badgeBorderRadius
    },
    primaryBadge: {
        backgroundColor: "primary",
        color: "primary.reverse",
        borderRadius: _badgeBorderRadius
    },
    successBadge: {
        backgroundColor: "success",
        color: "success.reverse",
        borderRadius: _badgeBorderRadius
    },
    warningBadge: {
        backgroundColor: "warning",
        color: "warning.reverse",
        borderRadius: _badgeBorderRadius
    },
    errorBadge: {
        backgroundColor: "error",
        color: "error.reverse",
        borderRadius: _badgeBorderRadius
    },
    cautionBadge: {
        backgroundColor: "caution",
        color: "caution.reverse",
        borderRadius: _badgeBorderRadius
    },
    subtleBadge: {
        backgroundColor: "subtle",
        color: "subtle.reverse",
        borderRadius: _badgeBorderRadius
    }
}
