import { LogManager } from "./logging/LogManager";
import { GeneralSettings } from "./settings/GeneralSettings";

const log = LogManager.getLogger("core.ServerFilter");

export class ServerFilter {
    public static evaluate(values: string[]): boolean {
        const server = GeneralSettings.get()?.server;
        log.debug("Filtering server (if applicable) for server %o given values %o", server, values);
        if (server == null) {
            log.debug("Server filtering is not active")
            return true;
        }
        const matches = values?.includes(server);
        if (!matches)
            log.debug("Server filtering is active but the server provided [%o] does not match any of the values %o", server, values);
        return matches;
    }
}
