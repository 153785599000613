import { AutogenModelPermsSecureItem, AutogenRowPermsSecureItem } from "./autogen/AutogenModelPermsSecureItem";

export enum PermsSecurityType {
    VIEW = "V",
    EDIT = "E",
}

export class ModelPermsSecureItem extends AutogenModelPermsSecureItem<RowPermsSecureItem> {
    constructor() {
        super(RowPermsSecureItem);
    }
}

export class RowPermsSecureItem extends AutogenRowPermsSecureItem {
    public static readonly ENTIRE_SCREEN = "Entire screen";
    public static readonly ACTION = "Action";

    isViewSecurity(): boolean {
        return this.get("security_type") === PermsSecurityType.VIEW;
    }

    isEditSecurity(): boolean {
        return this.get("security_type") === PermsSecurityType.EDIT;
    }

    isActionPerm(): boolean {
        return this.get("screen_class") === RowPermsSecureItem.ACTION;
    }

    isEntireScreen(): boolean {
        return this.isViewSecurity() && this.get("item_name") === RowPermsSecureItem.ENTIRE_SCREEN;
    }
}
