import { GeneralSettings } from ".";

export class WindowTitle {
    private static _title: string;

    public static get(): string {
        return WindowTitle._title;
    }

    public static set(value: string) {
        WindowTitle._title = value;
        document.title = WindowTitle.addEnvironmentMessage(value);
    }

    private static addEnvironmentMessage(title: string): string {
        const envMessage = GeneralSettings.get()?.environment_message;
        if (envMessage?.length > 0)
            return envMessage + " " + title;
        return title;
    }
}