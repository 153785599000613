import {
    Button, ButtonVariant, ChangeEvent, Checkbox, Component, DataDisplayEvent, DesignableObjectPropDefinition,
    DropdownItem,
    KeyEvent, Panel, PanelProps, PropType, TableRow, Textbox
} from "@mcleod/components";
import { Alignment, StringUtil, VerticalAlignment } from "@mcleod/core";
import { DefaultValuePropertyEditor } from "./DefaultValuePropertyEditor";
import { PropertiesTable } from "./PropertiesTable";
import { SpecialtyEditorFactory } from "./SpecialtyEditorFactory";
import { McLeodTailor } from "./custom/McLeodTailor";
import { UIDesignerUtil } from "./UIDesignerUtil";


export class PropertyValuePanel extends Panel {
    private propName: string;
    private propDef: DesignableObjectPropDefinition;
    private filterCategory: string;
    private row: TableRow;
    private table: PropertiesTable;
    private _primaryComponent: Component;
    private _specialtyEditorButton: Button;

    constructor(propName: string, propDef: DesignableObjectPropDefinition, filterCategory: string,
        propTable: PropertiesTable, row: TableRow, props?: Partial<PanelProps>) {
        super({
            id: "editor-" + propName + row.index,
            fillRow: true,
            padding: 0,
            borderLeftWidth: 1,
            borderLeftColor: "strokeSecondary",
            verticalAlign: VerticalAlignment.CENTER,
            ...props
        });
        this.propName = propName;
        this.propDef = propDef;
        this.filterCategory = filterCategory;
        this.table = propTable;
        this.row = row;
        this.populate();
    }

    get primaryComponent(): Component {
        return this._primaryComponent;
    }

    private set primaryComponent(value: Component) {
        this._primaryComponent = value;
    }

    get specialtyEditorButton(): Button {
        return this._specialtyEditorButton;
    }

    private populate() {
        if (this.filterCategory != null && (this.propDef == null || this.propDef.category !== this.filterCategory))
            return;
        const editorComponents: Component[] = [];
        if ("defaultDataValue" == this.propName) {
            const defaultValueComponent = new DefaultValuePropertyEditor(this.table, this.row).component;
            if (defaultValueComponent != null) {
                this.add(defaultValueComponent)
                this.primaryComponent = defaultValueComponent;
            }
            return;
        } else if (this.propDef.type === PropType.bool)
            this.primaryComponent = this.createPropertyCheckbox();
        else
            this.primaryComponent = this.createPropertyTextbox();
        this.primaryComponent.rowBreak = false;
        editorComponents.push(this.primaryComponent);
        this.add(this.primaryComponent);
        this._specialtyEditorButton = this.createSpecialtyEditorButton();
        if (this._specialtyEditorButton != null) {
            editorComponents.push(this._specialtyEditorButton);
            this.add(this._specialtyEditorButton);
        }
        this.table.designer.disablePropertyEditors(this.propDef, editorComponents, this.table.selectedComponents[0]);
        (this.primaryComponent as any).index = this.row.index; //why are we doing this?
        this.primaryComponent.field = "value";
        this.primaryComponent.addFocusListener(() => this.table.selectedTableRow = this.row);
        this.primaryComponent.addKeyUpListener((event: KeyEvent) => this.table.keyUp(event));
        return;
    }

    private createPropertyCheckbox(): Checkbox {
        const checkbox = new Checkbox({ paddingLeft: 8, rowBreak: false, fillRow: true });
        checkbox.addChangeListener((event: ChangeEvent) => {
            if (event.userInitiatedChange) {
                this.table.designer.applyChangeToSelectedComponents(this.row.data, checkbox.checked);
                const oldValue = this.row.data.value || false;
                const newValue = checkbox.checked;
                UIDesignerUtil.designerCheckForPropChange(this.table.designer, this.row.data.prop_name, newValue, oldValue);
            }
        });
        return checkbox;
    }

    private createPropertyTextbox(): Textbox {
        const textbox = new Textbox({
            padding: 0,
            captionVisible: false,
            borderWidth: 0,
            borderRadius: 0,
            height: "unset",
            fillRow: true,
            rowBreak: false,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 4
        });
        textbox.addDataDisplayListener((event: DataDisplayEvent) => {
            const value = event.rowData?.["value"];
            // These should be ModelRows, but they aren't.  This was created before we standardized ModelRow.
            // So we reference the "value" directly.
            if (value?.id != null)
                (event.target as Textbox).text = value.id;
        });

        const items = this.propDef.dropdownProps?.items;

        if (items) {
            if (typeof items === "function")
                textbox.items = () => items(this.table.designer);
            else
                textbox.items = items;
            if (this.propDef.dropdownProps.allowDropdownBlank)
                textbox.allowDropdownBlank = this.propDef.dropdownProps.allowDropdownBlank;
        }

        if (textbox.items == null) {
            textbox.addBlurListener(() => {
                this.submitTextboxCheckForPropChange(textbox, this.row);
            });
        }

        textbox.addChangeListener((event: ChangeEvent) => {
            if (event.userInitiatedChange && event.newValue !== event.oldValue) {
                const newValue = this.getPropertyTextboxValue(textbox);
                this.table.designer.applyChangeToSelectedComponents(this.row.data, newValue);

                if (textbox.items)
                    this.submitTextboxCheckForPropChange(textbox, this.row);
                else if (this.table.designer instanceof McLeodTailor) {
                    //if the user is typing and the value is changed back to the original value, then changes to baseVersionProps need to be reverted
                    this.table.designer.checkForPropChange(event, this.row.data);
                }
            }
        });
        if (this.propDef.type === PropType.event)
            textbox.addDblClickListener(() => this.table.designer.addEventHandlerFunction(this.table.designer.selectedComponents[0], this.row.data.prop_name));
        else if (this.propDef.type == PropType.layout)
            textbox.addChangeListener(() => this.syncOpenLayoutButton(textbox, this.row));
        textbox.enabled = new SpecialtyEditorFactory().allowsEnabledTextbox(this.propDef.type);
        return textbox;
    }

    processKey(key: string) {
        // This didn't work when copying the table row. Each object is copied by reference.
        // Would need to deep clone. Could do that using JSON.parse/stringify, but doesn't work in this situation.
        // Object assign is a shallow copy.
        const updRowOrigData = Object.assign({}, this.row.data); // Why do we do this?

        const textbox = this.primaryComponent as Textbox;
        textbox.focus();
        if (key != null) {
            textbox.text = key;
            this.row.data.value = key;
            this.table.designer.applyChangeToSelectedComponents(this.row.data, this.row.data.value);
            this.submitTextboxCheckForPropChange(textbox, this.row);
        }
    }

    submitTextboxCheckForPropChange(textbox: Textbox, row: TableRow) {
        const newValue = this.getPropertyTextboxValue(textbox);
        const oldValue = StringUtil.isEmptyString(row.data.value) ? null : row.data.value;
        UIDesignerUtil.designerCheckForPropChange(this.table.designer, row.data.prop_name, newValue, oldValue);
    }

    private getPropertyTextboxValue(textbox: Textbox): string {
        const value = textbox.selectedItem?.value ?? textbox.text;
        return StringUtil.isEmptyString(value) ? null : value;
    }

    private createSpecialtyEditorButton(): Button {
        const imageName = new SpecialtyEditorFactory().getButtonImage(this.propDef.type);
        if (StringUtil.isEmptyString(imageName) === true)
            return;
        const button = new Button({
            imageName: imageName,
            variant: ButtonVariant.round,
            color: "subtle.light",
            margin: 0,
            rowBreak: false,
            id: "buttonSpecialtyEditor"
        });
        const textbox = this.primaryComponent as Textbox;
        button.addClickListener(async () => {
            const specialtyEditor = new SpecialtyEditorFactory().getEditor(this.propDef.type, textbox, this.table.designer);
            await specialtyEditor.show();
            if (specialtyEditor.wasCancelled !== true) {
                const newValue = specialtyEditor.getResultValue();
                if (newValue !== undefined) {
                    const oldValue = this.row.data.value;
                    this.table.designer.applyChangeToSelectedComponents(this.row.data, newValue);
                    if (Array.isArray(newValue) && newValue.every(item => item instanceof DropdownItem)) {
                        textbox.text = JSON.stringify(
                            newValue.map(item => ({
                                displayValue: item.displayValue,
                                value: item.value
                            }))
                        );
                    } else {
                        textbox.text = newValue;
                    }
                    this.table.designer.displayDataSourceTools();
                    UIDesignerUtil.designerCheckForPropChange(this.table.designer, this.row.data.prop_name, newValue, oldValue);
                }
                specialtyEditor.doAfterResultApplied();
            }
        });
        return button;
    }

    private syncOpenLayoutButton(textbox: Textbox, row: TableRow): void {
        const cell = textbox.parent;
        if (cell == null) {
            return;
        }

        const buttonOpenLayoutExists = !!row.data.buttonOpenLayout;
        const textboxIsEmpty = textbox.isEmpty();

        if (!textboxIsEmpty && !buttonOpenLayoutExists) {
            row.data.buttonOpenLayout = new Button({
                imageName: "detail",
                color: "primary",
                variant: ButtonVariant.round,
                tooltip: "Open layout in new tab.",
                tooltipPosition: Alignment.LEFT,
                onClick: () => this.table.designer.openTab({ path: textbox.text })
            });
            cell.add(row.data.buttonOpenLayout);
        } else if (textboxIsEmpty && buttonOpenLayoutExists) {
            cell.remove(row.data.buttonOpenLayout);
            row.data.buttonOpenLayout = null;
        }
    }
}
