import { Button, ButtonVariant, Component, Panel, TableRow, TableRowDisplayEvent } from "@mcleod/components";
import { ArrayUtil, HorizontalAlignment, ModelRow } from "@mcleod/core";
import { ActionApplyBaseProps } from "../actions/ActionApplyBaseProps";
import { McLeodTailor } from "./McLeodTailor";
import { TableBaseProps } from "./TableBaseProps";
import { UIDesignerUtil } from "../UIDesignerUtil";

export class PanelApplyBaseProps extends Panel {
    private tablePanel: TableBaseProps;
    private uiDesigner: McLeodTailor;
    private _onClose: () => void;
    private component: Component;

    constructor(uiDesigner: McLeodTailor) {
        super({ fillHeight: true, fillRow: true, padding: 0 });
        this.uiDesigner = uiDesigner;
        this.component = this.uiDesigner.firstSelectedLayoutComponent;
        this.setupTable();
        this.addButtonPanel();
    }

    public set onClose(value: () => void) {
        this._onClose = value;
    }

    public close() {
        if (this._onClose)
            this._onClose();
    }

    private setupTable() {
        const extraColumnDef = { headingDef: { width: 40 }, cell: { width: 40 } };
        this.tablePanel = new TableBaseProps(null, [ extraColumnDef ]);
        this.tablePanel.addOnTableRowDisplayCallback((event: TableRowDisplayEvent) => this.onTableRowDisplayCallback(event));
        this.addTableRows();
        this.add(this.tablePanel);
    }

    private addTableRows() {
        const baseProps = this.component.baseVersionProps ?? {};
        for (const key in baseProps) {
            const oldValueDisplay = UIDesignerUtil.getPropDisplayValue(this.uiDesigner, this.component, key, baseProps[key]);
            const currentValueDisplay = UIDesignerUtil.getPropDisplayValue(this.uiDesigner, this.component, key, this.component[key]);
            const data = new ModelRow(null, false, {
                prop_name: key,
                old_value: baseProps[key],
                old_value_display: oldValueDisplay,
                new_value: this.component[key],
                new_value_display: currentValueDisplay
            });
            this.tablePanel.addRow(data);
        }
    }

    private onTableRowDisplayCallback(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        const button = new Button({
            variant: ButtonVariant.round,
            imageName: "circleX2", color: "error",
            onClick: () => this.revertProp(tableRow)
        });
        const lastCell = ArrayUtil.getLastElement(tableRow.cells);
        lastCell.add(button);
    }

    private addButtonPanel() {
        const panelButtons = new Panel({ fillRow: true, align: HorizontalAlignment.CENTER })
        panelButtons.add(new Button({ caption: "Close", rowBreak: false, width: 120, onClick: () => this.close() }))
        panelButtons.add(new Button({
            caption: "Remove All", width: 120, backgroundColor: "error", color: "error.reverse",
            onClick: () => this.revertAll()
        }));
        this.add(panelButtons);
    }

    revertProp(tableRow: TableRow) {
        const data = tableRow.data;
        this.uiDesigner.executeChangePropAction(this.component, data.get("prop_name"), data.get("old_value", null), data.get("new_value", null), true);
        this.tablePanel.clear();
        this.addTableRows();
        if (this.tablePanel.isEmpty() === true)
            this.close();
    }

    private revertAll() {
        this.uiDesigner.executeAction(new ActionApplyBaseProps(this.uiDesigner, this.component));
        this.close();
    }
}
