import { Component } from "../..";
import { DropTargetPanelProps } from "./DropTargetPanelProps";
import { Panel } from "./Panel";

export class DropTargetPanel extends Panel implements DropTargetPanelProps {
    private _enclosingComponent: Component;

    constructor(props: Partial<DropTargetPanelProps>) {
        super(props);
        this.setProps(props);
    }

    override setProps(props: Partial<DropTargetPanelProps>) {
        super.setProps(props);
    }

    get enclosingComponent(): Component {
        return this._enclosingComponent;
    }

    set enclosingComponent(value: Component) {
        this._enclosingComponent = value;
    }

    getSpecialDesignerDropTarget(): Panel {
        if (this.enclosingComponent?.["getSpecialDesignerDropTarget"] != null) {
            const specialContainer = this.enclosingComponent["getSpecialDesignerDropTarget"](this);
            if (specialContainer !== undefined)
                return specialContainer;
        }
        return this;
    }
}
