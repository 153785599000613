import { DatePart, DateRange, DateUtil, DisplayType, ModelRow } from "@mcleod/core";
import { Checkbox, Component, DataSourceMode, DropdownItem, Panel, Switch, Textbox } from "@mcleod/components";
import { AbstractInputParser } from "@mcleod/components/src/components/textbox/AbstractInputParser";

export interface ReportFilterInfo {
    field: string;
    value: string;
    displayName: string;
    type: string;
    exclude: boolean;
    dataPriority: string;
}

interface DateObjectProperty {
    objectPropertyType: "D";
    key: string;
    date: Date;
}

interface FilterObjectProperty {
    objectPropertyType: "F";
    key: string;
    filterField: string;
    nullifyFilterString: boolean;
}

interface ControlDefaults {
    enabled?: boolean;
    visible?: boolean;
    text?: string;
    checked?: boolean;
    selectedItem?: DropdownItem;
    dateDefault?: string;
}

export class ReportUtil {
    //This function should typically be the first binding function called, and will handle most of the necessary bindings
    //Default bindings can be overridden with the other binding functions
    static bindDefaultFilterInfos(row: ModelRow, panel: Panel) {
        panel.findComponentsByType(Textbox).forEach((tb) => {
            if (!tb.field) return

            if (tb.displayType == DisplayType.DATE || tb.displayType == DisplayType.DATERANGE) {
                this.bindTextboxFilterInfo(row, tb, tb.displayType, tb.caption + ": " + tb.text);
            } else if (tb.items) {
                this.bindDropdownFilterInfo(row, tb);
            } else {
                this.bindTextboxWithoutParsing(row, tb);
            }
        })
        panel.findComponentsByType(Checkbox).forEach((cb) => {
            if (cb.field)
                this.bindCheckboxFilterInfo(row, cb);
        })
        panel.findComponentsByType(Switch).forEach((s) => {
            if (s.field)
                this.bindSwitchFilterInfo(row, s);
        })
    }

    //This function will unbind filter info by key
    //This is useful for when a control is disabled and should not be included in the filter info, but is bound by bindDefaultFilterInfos
    static unbindFilterInfo(row: ModelRow, key: string): void {
        row.set(key, null);
    }

    //This function allows for full control in what filter info is bound, for specialized cases
    //Should be used sparingly and requires understanding of how ReportEndpoint works
    static bindFilterInfo(row: ModelRow, key: string, filterInfo: ReportFilterInfo): void {
        const k = key ?? filterInfo.field;
        row.set(k, filterInfo);
    }

    //This is to bind date objects as property values
    //updateBoundData must be called on the row before the data is passed to ReportEndpoint, these objects rely on fields that it generates
    static bindDateObjectProperties(row: ModelRow, tb: Textbox, startKey: string, endKey: string) {
        const dateObjectProperty: DateObjectProperty = {
            objectPropertyType: "D",
            key: startKey,
            date: tb.getDateRange().beginningDate ?? new Date(1900, 0, 0)
        }
        row.set(startKey, dateObjectProperty)

        const dateObjectPropertyTwo: DateObjectProperty = {
            objectPropertyType: "D",
            key: endKey,
            date: tb.getDateRange().endDate ?? new Date(3000, 0, 0)
        }
        row.set(endKey, dateObjectPropertyTwo)
    }

    //This is to bind a date object as a property value for a date textbox
    static bindDateObjectProperty(row: ModelRow, tb: Textbox, key: string) {
        const dateObjectProperty: DateObjectProperty = {
            objectPropertyType: "D",
            key: key,
            date: new Date(Date.parse(tb.valueAsString))
        }
        row.set(key, dateObjectProperty)
    }

    //This is to bind ReportFilter objects as property values, the filterField is compared with the paramName of autogenerated filters to find the object to be used
    //nullifyFilterString is used to nullify the filter string of the original ReportFilter (in the ReportFilter[])
    static bindFilterObjectProperty(row: ModelRow, key: string, filterField: string, nullifyFilterString: boolean): void {
        const filterObjectProperty: FilterObjectProperty = {
            objectPropertyType: "F",
            key: key,
            filterField: filterField,
            nullifyFilterString: nullifyFilterString
        }
        row.set(key, filterObjectProperty);
    }

    //This function binds filter info for a textbox, and has parsing built in
    //It is mostly only used manually for binding dates/dateranges
    //The displayName prop can be misleading, if the textbox is not for a lookup model (filterType "L") it should contain the entire description string
    static bindTextboxFilterInfo(row: ModelRow, textbox: Textbox, filterType: string, displayName?: string, filterFieldName?: string, excludeCheckbox?: Checkbox, keyFieldName?: string): void {
        const parser = AbstractInputParser.createParser(textbox, textbox.text);

        const filterInfo: ReportFilterInfo = {
            field: filterFieldName ?? textbox.field,
            value: parser.dataValue,
            displayName: displayName ?? textbox.caption,
            type: filterType,
            exclude: excludeCheckbox ? excludeCheckbox.checked : false,
            dataPriority: ReportUtil.createDataPriority(textbox)
        };

        row.set(keyFieldName ?? textbox.field, filterInfo);
    }

    //This function doesn't parse the textbox value and can be used for simple textboxes
    static bindTextboxWithoutParsing(row: ModelRow, textbox: Textbox, description?: string, fieldName?: string, dataType?: string): void {
        const filterInfo: ReportFilterInfo = {
            field: fieldName ?? textbox.field,
            value: textbox.valueAsString,
            displayName: description ?? textbox.caption + ": " + textbox.text,
            type: "T",
            exclude: false,
            dataPriority: dataType ?? ReportUtil.createDataPriority(textbox)
        };

        row.set(fieldName ?? textbox.field, filterInfo);
    }

    //This function binds filter info for a dropdown textbox
    static bindDropdownFilterInfo(row: ModelRow, textbox: Textbox, description?: string, fieldName?: string) {
        const filterInfo: ReportFilterInfo = {
            field: fieldName ?? textbox.field,
            value: textbox.selectedItem.value,
            displayName: description ?? textbox.caption + ": " + textbox.selectedItem.displayValue,
            type: "D",
            exclude: false,
            dataPriority: ReportUtil.createDataPriority(textbox)
        }

        row.set(fieldName ?? textbox.field, filterInfo);
    }

    //This function binds filter info for a checkbox
    static bindCheckboxFilterInfo(row: ModelRow, checkbox: Checkbox, description?: string, fieldName?: string) {
        const filterInfo: ReportFilterInfo = {
            field: fieldName ?? checkbox.field,
            value: checkbox.checked ? checkbox.valueChecked : checkbox.valueUnchecked,
            displayName: description ?? checkbox.caption + ": " + (checkbox.checked ? checkbox.valueChecked : checkbox.valueUnchecked),
            type: "C",
            exclude: false,
            dataPriority: ReportUtil.createDataPriority(checkbox)
        }

        row.set(fieldName ?? checkbox.field, filterInfo);
    }

    //This function binds filter info for a switch
    static bindSwitchFilterInfo(row: ModelRow, s: Switch, description?: string, fieldName?: string) {
        const caption = s.checked ? s.rightCaption : s.leftCaption

        const filterInfo: ReportFilterInfo = {
            field: fieldName ?? s.field,
            value: s.checked ? s.rightValue.toString() : s.leftValue.toString(),
            displayName: description ?? s.caption + ": " + caption,
            type: "S",
            exclude: false,
            dataPriority: ReportUtil.createDataPriority(s)
        }

        row.set(fieldName ?? s.field, filterInfo);
    }

    //This function assumes that "Pairs" of textboxes and checkboxes are in the same order in the component tree
    //It is used to bind lookup filter infos for all of these pairs within a panel
    static bindLookupFilterInfos(row: ModelRow, panel: Panel) {
        const lookupTbs = panel.findComponentsByType(Textbox)
        const exceptCbs = panel.findComponentsByType(Checkbox)

        for (let i = 0; i < lookupTbs.length; i++) {
            this.bindTextboxFilterInfo(row, lookupTbs[i], "L", lookupTbs[i].caption, null, exceptCbs[i]);
        }
    }

    static createDataPriority(component: Component) {
        if (component instanceof Textbox) {
            if (component.hasLookupModel()) {
                return "textbox_lookupmodel";
            }
            return `textbox_${component.displayType}`;
        }

        return component.typeName;
    }

    /**
     * Validates a field using its `validateSimple` method and optionally focuses on the field if validation fails.
     *
     * This function checks if the field's `validateSimple` method returns `false`. If it does, the field is focused and the
     * function returns `false`. Otherwise, the function returns `true`.
     *
     * @param {any} field - The field to be validated.
     * @param {boolean} [isRequired=true] - Indicates whether the field is required.
     * @returns {boolean} - Returns `true` if the field is valid, otherwise `false`.
     *
     * @example
     * import { isFieldValid } from "@mcleod/general/src/ReportUtil";
     *
     * // Assuming `someField` is an object with a `validateSimple` method
     * const isValid = isFieldValid(someField);
     * if (isValid) {
     *     //Field is valid
     * } else {
     *     //Field is invalid
     * }
     */
    static isFieldValid(field: any, isRequired: boolean = true): boolean {
        if (field instanceof Textbox && field.hasLookupModel()) {
            if (field.lookupModelAllowMultiSelect && !field.lookupModelAllowFreeform && field.text.trim() !== "") {
                field.validationWarning = "Freeform text isn't allowed in this field.";
                field.focus();
                return false;
            }
        }

        if (field.validateSimple(true, isRequired) == false) {
            field.focus();
            return false;
        }
        return true;
    }

    /**
     * Validates all component fields in the given context object.
     *
     * This function iterates over all properties of the provided context object and checks if each property
     * is an instance of `Component` or extends from `Component`. If it is, it calls `isFieldValid` to validate the field.
     *
     * @param {any} context - The object containing fields to be validated.
     * @returns {boolean} - Returns `true` if all fields are valid, otherwise `false`.
     *
     * @example
     * import { isUserInputValid } from "@mcleod/general/src/ReportUtil";
     *
     * // Assuming `someContext` is an object with fields that are instances of `Component`
     * const isValid = isUserInputValid(someContext);
     * if (isValid) {
     *     //All fields are valid
     * } else {
     *     //Some fields are invalid
     * }
     */
    static isUserInputValid(context: any): boolean {

        // Iterate over all properties of the context object
        for (const key in context) {
            if (!context.hasOwnProperty(key)) continue;

            // Check if the field is an instance of Component or extends from Component
            const field = context[key];
            if (!(field instanceof Component)) continue;

            if (!this.isFieldValid(field)) return false;
        }

        return true;
    }

    //This function should be used to get the default state of controls for a report screen
    //The value returned should be stored as a local variable to be passed into applyDefaultState
    static getDefaultState(panel: Panel) {
        const defaultState = {}
        panel.findComponentsByType(Textbox).forEach((tb) => {
            defaultState[tb.id] = {
                enabled: tb.enabled,
                visible: tb.visible,
                text: tb.text,
                selectedItem: tb.selectedItem,
                dateDefault: tb.dateDefault
            }
        })
        panel.findComponentsByType(Checkbox).forEach((cb) => {
            defaultState[cb.id] = {
                enabled: cb.enabled,
                visible: cb.visible,
                checked: cb.checked
            }
        })
        panel.findComponentsByType(Switch).forEach((s) => {
            defaultState[s.id] = {
                enabled: s.enabled,
                visible: s.visible,
                checked: s.checked
            }
        })

        return defaultState
    }

    //This function should be used to apply the default state of controls for a report screen
    static applyDefaultState(panel: Panel, defaultState: { [key: string]: ControlDefaults }) {
        panel.findComponentsByType(Textbox).forEach((tb) => {

            const state = defaultState[tb.id]

            if (tb.items) {
                tb.selectedItem = state.selectedItem
            } else {
                tb.clear()
            }

            tb.enabled = state.enabled
            tb.visible = state.visible
            tb.text = state.text
            tb.dateDefault = state.dateDefault
        })
        panel.findComponentsByType(Checkbox).forEach((cb) => {
            const state = defaultState[cb.id]
            cb.enabled = state.enabled
            cb.visible = state.visible
            cb.checked = state.checked
        })
        panel.findComponentsByType(Switch).forEach((s) => {
            const state = defaultState[s.id]
            s.enabled = state.enabled
            s.visible = state.visible
            s.checked = state.checked
        })
    }

    //This function should be used to apply the default state of controls for a report layout
    static applyLayoutDefaultState(layout: Panel, defaultState: { [key: string]: ControlDefaults }) {
        layout.findComponentsByType(Textbox).forEach((tb) => {

            const state = defaultState[tb.id]

            if (tb.items) {
                tb.selectedItem = state.selectedItem
            } else {
                tb.clear()
            }

            tb.enabled = state.enabled
            tb.visible = state.visible

            tb.text = state.text
            tb.dateDefault = state.dateDefault
        })
        layout.findComponentsByType(Checkbox).forEach((cb) => {
            const state = defaultState[cb.id]
            cb.enabled = state.enabled
            cb.visible = state.visible
            cb.checked = state.checked
        })
        layout.findComponentsByType(Switch).forEach((s) => {
            const state = defaultState[s.id]
            s.enabled = state.enabled
            s.visible = state.visible
            s.checked = state.checked
        })
    }

    /**
     * Refreshes the exclude checkbox based on the state of the textbox.
     *
     * - If the textbox is empty or contains a wildcard ("*"), the checkbox is unchecked and disabled.
     * - If the textbox contains valid data, the checkbox is enabled.
     *
     * @param textbox - The Textbox component to check.
     * @param checkbox - The Checkbox component to refresh.
     */
    static refreshExclude(textbox: Textbox, checkbox: Checkbox) {
        const isTextEmptyOrWildcard = !(textbox.text.trim() !== "*" && textbox.lookupModelData?.length > 0);
        if (isTextEmptyOrWildcard) checkbox.checked = false;
        checkbox.enabled = !isTextEmptyOrWildcard;
    }
}
