import { Component } from "../../base/Component";
import { ComponentTypes } from "../../base/ComponentTypes";
import { RepeatItemDisplayEvent } from "../../events/RepeatItemDisplayEvent";
import { ComponentDeserializer } from "../../serializer/ComponentDeserializer";
import { Layout } from "../layout/Layout";
import { Panel } from "../panel/Panel";
import { PanelProps } from "../panel/PanelProps";
import { Spinner } from "../spinner/Spinner";
import { Repeater } from "./Repeater";

export class RepeatItem extends Panel {
    private _repeater: Repeater;
    private _domPopulated: boolean = false;
    private componentsDef: any;

    constructor(props?: Partial<PanelProps>) {
        super(props);
    }

    override allowDropInDesigner(_component: Component): boolean {
        return false;
    }

    get repeater(): Repeater {
        return this._repeater;
    }

    set repeater(value: Repeater) {
        this._repeater = value;
    }

    get data(): any {
        return this.boundRow;
    }

    async populateDom() {
        if (this._domPopulated) return;
        this.removeAll();
        this.add(new Spinner());

        this.components = await new ComponentDeserializer({
            owner: this.owner,
            def: this.componentsDef,
            designer: this._designer,
            defaultPropValues: {boundRow: this.boundRow},
            componentCreationCallback: this.createComponent.bind(this)
        }).deserialize();

        if (this.boundRow != null)
            this.displayData(this.boundRow, null, null);
        this._domPopulated = true;
        const event = new RepeatItemDisplayEvent(this);
        this.repeater?.fireItemDisplayListeners(event);
    }

    private createComponent(type: string, props: any): Component {
        const comp = ComponentTypes.createComponentOfType(type, props);

        if (this.boundRow && comp instanceof Layout) {
            comp.addLayoutLoadListener(() => {
                comp.forEveryChildComponent(child => child.boundRow = this.boundRow);
            });
        }

        return comp;
    }
}
