import { Alignment, Color } from "@mcleod/core";
import { ImageName } from "@mcleod/images";
import { ComponentPropDefinitions, ComponentPropDefinitionUtil, ComponentProps } from "../../base/ComponentProps";
import { PropType } from "../../base/PropType";
import { ImageProps } from "../image/ImageProps";

export interface SwitchProps extends ComponentProps {
    caption: string;
    captionAlignment: Alignment.LEFT | Alignment.TOP;
    captionVisible: boolean;
    checked: boolean;
    leftCaption: string;
    leftImageName: ImageName;
    leftImageProps: Partial<ImageProps>;
    leftValue: any;
    printable: boolean;
    printableDuringAdd: boolean;
    printableDuringSearch: boolean;
    printableDuringUpdate: boolean;
    rightCaption: string;
    rightImageName: ImageName;
    rightImageProps: Partial<ImageProps>;
    rightValue: any;
    trackColor: Color;
}

let switchPropDefs: ComponentPropDefinitions;

export class SwitchPropDefinitions {
    public static getDefinitions(): ComponentPropDefinitions {
        if (switchPropDefs == null) {
            switchPropDefs = {
                ...ComponentPropDefinitionUtil.getComponentPropertyDefinitions(),
                ...ComponentPropDefinitionUtil.getDataBoundPropertyDefinitions(),
                caption: { type: PropType.string, visibleInMcLeodTailor: true },
                captionAlignment: {
                    type: PropType.string,
                    dropdownProps: { items: [Alignment.LEFT, Alignment.TOP] },
                    defaultValue: Alignment.TOP,
                    visibleInMcLeodTailor: true,
                    description: "This controls whether the Switch's caption appears on top of the Switch or to the left of it."
                },
                captionVisible: { type: PropType.bool, defaultValue: true, visibleInMcLeodTailor: true },
                checked: { type: PropType.bool, visibleInMcLeodTailor: true },
                leftCaption: { type: PropType.string, visibleInMcLeodTailor: true },
                rightCaption: { type: PropType.string, visibleInMcLeodTailor: true },
                leftImageName: { type: PropType.image, visibleInMcLeodTailor: true },
                leftValue: { type: PropType.string, visibleInMcLeodTailor: true, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "This is the data value that will be used when the Switch is unchecked (toggled to the left).  If not, provided, 'N', 'false' and a boolean value of false will all be considered to be a checked value." },
                rightImageName: { type: PropType.image, visibleInMcLeodTailor: true },
                rightValue: { type: PropType.string, visibleInMcLeodTailor: true, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "This is the data value that will be used when the Switch is checked (toggled to the right).  If not, provided, 'Y', 'true' and a boolean value of true will all be considered to be an unchecked value." },
                trackColor: { type: PropType.color, visibleInMcLeodTailor: true, description: "The color of the track that the switch moves along." },
                onChange: { type: PropType.event, category: "Events", addListenerMethod: "addChangeListener", removeListenerMethod: "removeChangeListener", eventSignature: "OnChange(event: ChangeEvent)", description: "This specifies a function to be called when the switch value changes." },
                printable: {
                    type: PropType.bool,
                    defaultValue: false,
                    category: "Appearance",
                    visibleInMcLeodTailor: true,
                    description: "This toggles whether the Switch appears as a toggable switch component, or a read-only output of the selected value. This is often changed at runtime.",
                },
                printableDuringAdd: {
                    type: PropType.bool,
                    defaultValue: false,
                    category: "Appearance",
                    visibleInMcLeodTailor: true,
                    description: "This toggles whether the Switch should appear in its printable state during add mode.",
                },
                printableDuringSearch: {
                    type: PropType.bool,
                    defaultValue: false,
                    category: "Appearance",
                    visibleInMcLeodTailor: true,
                    description: "This toggles whether the Switch should appear in its printable state during search mode.",
                },
                printableDuringUpdate: {
                    type: PropType.bool,
                    defaultValue: false,
                    category: "Appearance",
                    visibleInMcLeodTailor: true,
                    description: "This toggles whether the Switch should appear in its printable state during update mode.",
                },
                defaultDataValue: { type: PropType.bool, visibleInMcLeodTailor: true, category: "Data", source: "databound", description: "This specifies the default value when entering add mode." },
                onPrintable: { type: PropType.event, category: "Events", addListenerMethod: "addPrintableListener", removeListenerMethod: "removePrintableListener", eventSignature: "OnPrintable(event: PrintableEvent)", description: "This specifies a function to be called when the component's printable property has changed."}
            };
            ComponentPropDefinitionUtil.populateComponentPropNames(switchPropDefs);
        }
        return switchPropDefs;
    }
}
