import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenPermsSecureItemEndpoint = "common/perms-secure-item";

export class AutogenModelPermsSecureItem<RowType extends ModelRow> extends Endpoint<RowType, RowPermsSecureItemFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenPermsSecureItemEndpoint, ctor);
    }
}

export class AutogenRowPermsSecureItem extends ModelRow<RowPermsSecureItemFields> {
    constructor() {
        super(AutogenPermsSecureItemEndpoint);
    }
}

export interface RowPermsSecureItemFields {
    screen_class: string;
    item_name: string;
    security_type: string;
    top_level_only: boolean;
    allow_count: number;
}
