import { Styles } from "@mcleod/core";

export const RepeaterStyles = Styles.make("repeater", () => {
    return {
        horizontal: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            overflowX: "auto",
            overflowY: "hidden"
        },
        vertical: {
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            overflowY: "auto",
            overflowX: "hidden"
        },
        grid: {
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(256px, 1fr))",
            gap: "16px",
            alignItems: "stretch",
            justifyItems: "stretch",
            overflowY: "auto"
        },
    };
});
