import { Table, TableColumn } from "@mcleod/components";
import { ActionAddToContainer } from "./ActionAddToContainer";
import { ActionAddToContainerResult } from "./ActionAddToContainerResult";

export class ActionAddTableColumn implements ActionAddToContainer {
    table: Table;
    designer: any;
    tableColumn: TableColumn;

    constructor(table: Table) {
        this.table = table;
    }

    async execute(): Promise<ActionAddToContainerResult> {
        const col = new TableColumn();
        col.headingDef = { caption: "Column " + (this.table.columns.length + 1), _designer: this.designer };
        const dataSources = {};
        if (this.table.dataSource != null)
            dataSources[this.table.dataSource.id] = this.table.dataSource;
        col.cellDef = {
            cellProps: { table: this.table },
            dataSources: dataSources,
            def: { type: "cell" }
        }

        this.tableColumn = this.table.addColumn(col, false, false);
        const headerCell = this.tableColumn.headingCell;

        await this.table.rows[0].populateDOM();
        const rowCell = this.table.rows[0].cells[this.tableColumn.index];
        return { success: true, componentsAdded: [headerCell, rowCell] };
    }

    undo() {
        this.table.removeColumn(this.tableColumn.index);
    }

    toString() {
        return "Add Table Column";
    }
}
