import { DbDisplayValue, DisplayType, DisplayValue, LogManager, ModelRow, StringUtil } from "@mcleod/core";
import { TableRowSearchUtil } from "./TableRowSearchUtil";
import { ComponentSearchResult } from "./ComponentSearchResult";

export type ModelRowSearcher = (row: ModelRow) => ComponentSearchResult;

const log = LogManager.getLogger("components.table.ComponentSearcher");

export class ComponentSearcher {
    private _searcher: ModelRowSearcher
    private _fieldName: string;
    private alias?: string;

    constructor(fieldName: string, alias?: string, searcher?: ModelRowSearcher) {
        this.fieldName = fieldName;
        this.alias = alias;
        this.searcher = searcher;
    }

    private get searcher(): ModelRowSearcher {
        if (this._searcher == null)
            this._searcher = this.basicSearcher;
        return this._searcher;
    }

    private set searcher(value: ModelRowSearcher) {
        this._searcher = value;
    }

    protected get fieldName(): string {
        return this._fieldName;
    }

    private set fieldName(value: string) {
        this._fieldName = value;
    }

    search(row: ModelRow): ComponentSearchResult {
        return this.searcher(row);
    }

    private basicSearcher(row: ModelRow): ComponentSearchResult {
        log.debug("Getting table search values for field %s, alias %s", this.fieldName, this.alias);
        const values = this.getValuesFromRow(row);
        const result = this.createComponentSearchResult(values, row);
        this.addValuesToResult(result, row);
        log.debug("Search values for field %s: %o", this.fieldName, result);
        return result;
    }

    protected getValuesFromRow(row: ModelRow): string[] {
        const fieldValue = row.get(this.fieldName)?.toString();
        const result: string[] = [];
        if (StringUtil.isEmptyString(fieldValue) === false) {
            result.push(fieldValue);
            const metadataField = row.getMetadata()?.getFieldFromOutput(this.fieldName)
            metadataField?.dbDisplayValues?.forEach((dbDisplayValue: DbDisplayValue) => {
                if (dbDisplayValue.value.toLowerCase() === fieldValue.toLowerCase())
                    result.push(dbDisplayValue.displayValue);
            });
        }
        return result;
    }

    /**
     * Override this method in subclasses to provide additional search values in the result.
     */
    protected addValuesToResult(_searchResult: ComponentSearchResult, _row: ModelRow) {
    }

    protected createComponentSearchResult(values: string[], row: ModelRow): ComponentSearchResult {
        const fieldName = TableRowSearchUtil.getSimpleFieldName(this.fieldName);
        return new ComponentSearchResult(values, fieldName, this.alias);
    }

    /**
     * Attempt to add the display value for a component to the searchable values.  This should also result in any date
     * values being made searchable in the user's preferred format.
     */
    protected addDisplayValue(searchResult: ComponentSearchResult, row: ModelRow, displayType: DisplayType,
        format: string) {
        const dbValue = row.get(this.fieldName, null);
        const displayTypeToUse = displayType ?? row.getMetadata()?.getFieldFromOutput(this.fieldName)?.displayType;
        if (StringUtil.isEmptyString(dbValue) === false && StringUtil.isEmptyString(displayTypeToUse) === false) {
            const formattedValue = DisplayValue.getDisplayValue(dbValue, displayTypeToUse, format);
            searchResult.addValue(formattedValue);
        }
    }
}
