import { GeneralSettings, GeneralSettingsDef } from "@mcleod/core";

export interface TenantGeneralSettingsDef extends GeneralSettingsDef {
    //unauth
    auth0_domain?: string;
    auth0_client_id?: string;
}

export class TenantGeneralSettings extends GeneralSettings {
    public static getSingleton(): TenantGeneralSettings {
        return GeneralSettings.getSingleton() as TenantGeneralSettings;
    }

    public static get(): TenantGeneralSettingsDef {
        return TenantGeneralSettings.getSingleton().values as TenantGeneralSettingsDef;
    }

    public override get values(): TenantGeneralSettingsDef {
        return super.values as TenantGeneralSettingsDef;
    }

    protected getValuesInternal(): TenantGeneralSettingsDef {
        return this._values as TenantGeneralSettingsDef;
    }
}
