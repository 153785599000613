import { ComponentPropDefinitionUtil, ComponentPropDefinitions } from "../base/ComponentProps";
import { PropType } from "../base/PropType";
import { ButtonPropDefinitions, ButtonProps } from "../components/button/ButtonProps";

export interface TailorExtensionButtonProps extends ButtonProps {
    extension: string;
}

let tailorExtensionButtonProps: ComponentPropDefinitions;

export class TailorExtensionButtonPropDefinitions {
    public static getDefinitions(): ComponentPropDefinitions {
        if (tailorExtensionButtonProps == null) {
            tailorExtensionButtonProps = {
                ...ButtonPropDefinitions.getDefinitions(),
                extension: { type: PropType.tailorExtension, visibleInMcLeodTailor: { baseComponent: false, customComponent: true }, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "Defines the server-side extension that will be invoked when the button is clicked." }
            };
            ComponentPropDefinitionUtil.populateComponentPropNames(tailorExtensionButtonProps);
        }
        tailorExtensionButtonProps.caption.defaultValue = "Invoke Extension";
        tailorExtensionButtonProps.color.defaultValue = "primary";
        tailorExtensionButtonProps.minWidth.defaultValue = 100;
        return tailorExtensionButtonProps;
    }
}
