import { Label, LabelProps } from "@mcleod/components";
import { StringUtil } from "@mcleod/core";

export class KeyValuePair {
    public key: string | Partial<LabelProps>;
    public value: string | Partial<LabelProps>;

    constructor(key: string | Partial<LabelProps>, value: string | Partial<LabelProps>) {
        this.key = key;
        this.value = value;
    }

    public createKeyLabel(suffix: string): Label {
        const props: Partial<LabelProps> = { fontBold: true, ...this.createProps(this.key) };
        this.handleSuffix(suffix, props);
        return new Label(props);
    }

    public createValueLabel(): Label {
        const props: Partial<LabelProps> = { wrap: true, ...this.createProps(this.value) };
        return new Label(props);
    }

    private createProps(value: string | Partial<LabelProps>): Partial<LabelProps> {
        return (typeof value === "string") ? { text: value } : value;
    }

    private handleSuffix(suffix: string, props: Partial<LabelProps>) {
        if (StringUtil.isEmptyString(suffix) !== true)
            props.text = (props.text != null ? props.text : "") + suffix;
    }

    public equals(otherKeyValuePair: KeyValuePair): boolean {
        if (otherKeyValuePair == null)
            return false;
        return this.testKey === otherKeyValuePair.testKey;
    }

    private get testKey(): string {
        if (typeof this.key === "string")
            return this.key;
        return this.key.text;
    }
}