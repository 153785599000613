import { UserSettings } from "@mcleod/core";
import { DropdownItem } from "./components/textbox/DropdownItem";

export enum CompanyType {
    ASSET = "A",
    BROKER = "B",
    BOTH = "L"
}

export enum LtlType {
    ANY = "L",
    ASSET = "A",
    BROKERAGE = "B",
    NON_LTL = "N"
}

export class Company {
    public static testCompanyId(companyIds: string[]): boolean {
        const currentCompanyId = UserSettings.getSingleton().getCompanyId();
        if (currentCompanyId == null)
            return false;
        return companyIds?.includes(currentCompanyId);
    }

    public static readonly COMPANY_TYPE_DROPDOWN_ITEMS: DropdownItem[] = [
        new DropdownItem(CompanyType.ASSET, "Asset"),
        new DropdownItem(CompanyType.BROKER, "Brokerage"),
        new DropdownItem(CompanyType.BOTH, "Both")
    ];

    public static readonly LTL_TYPE_DROPDOWN_ITEMS: DropdownItem[] = [
        new DropdownItem(LtlType.ASSET, "Asset"),
        new DropdownItem(LtlType.BROKERAGE, "Brokerage"),
        new DropdownItem(LtlType.ANY, "Both"),
        new DropdownItem(LtlType.NON_LTL, "Non-LTL")
        ];
}
