export const innerHTML = '<path fill-rule="evenodd" clip-rule="evenodd" d="M12.112 0C14.8737 0 17.112 2.23828 17.112 5C17.112 7.76172 14.8737 10 12.112 10C9.35025 10 7.11197 7.76172 7.11197 5C7.11197 2.23828 9.35025 0 12.112 0Z" fill="#B60909"/>' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M14.9191 2.19257C14.7262 1.99999 14.4143 1.99999 14.2228 2.19394L12.112 4.32187L10.0012 2.19394C9.80966 1.99999 9.49776 1.99999 9.30487 2.19257C9.11198 2.38514 9.11198 2.69876 9.3035 2.8927L11.417 5.02338L9.3035 7.15405C9.11198 7.348 9.11198 7.66162 9.30487 7.85419C9.40063 7.95048 9.52649 7.99862 9.65234 7.99862C9.7782 7.99862 9.90542 7.95048 10.0012 7.85281L12.112 5.72489L14.2228 7.85419C14.3185 7.95185 14.4458 7.99999 14.5716 7.99999C14.6975 7.99999 14.8233 7.95185 14.9191 7.85557C15.112 7.66299 15.112 7.34938 14.9205 7.15543L12.8069 5.02338L14.9191 2.8927C15.112 2.69876 15.112 2.38514 14.9191 2.19257Z" fill="white"/>' +
    '<mask id="mask0_47_318" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="26">' +
    '<path d="M24.224 0H0V25.517H24.224V0Z" fill="#252525"/>' +
    '<path d="M24.224 7H0V25H24.224V7Z" fill="white"/>' +
    '</mask>' +
    '<g mask="url(#mask0_47_318)">' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M17.9852 6.99999H21.8529C22.1438 6.95508 22.4176 7.15001 22.4704 7.43962L24.1054 11.8853C24.1783 12.1168 24.2117 12.3589 24.2042 12.6015V24.343C24.2226 24.5205 24.1607 24.6969 24.0355 24.824C23.9103 24.9512 23.7348 25.0157 23.5571 25H0.612506C0.442948 25.0062 0.278839 24.9395 0.161729 24.8168C0.0446192 24.694 -0.0142627 24.5269 -7.95722e-06 24.3578V12.725C-0.00368376 12.3891 0.049767 12.0551 0.15806 11.7371C0.28649 11.3617 0.523592 10.4577 1.53128 7.40998C1.58815 7.14392 1.83931 6.96575 2.10921 6.99999H6.06091V8.17068H2.88967L2.19813 10.7097H9.05433C9.1201 10.7047 9.18614 10.7047 9.25191 10.7097C9.50135 10.7398 9.68842 10.9524 9.6866 11.2036C9.68975 11.3591 9.70295 11.5142 9.72612 11.6679C10.0077 13.2289 10.8227 14.2415 12.4034 14.1081C13.663 14.0044 14.2508 13.0165 14.478 11.7717C14.5094 11.6087 14.5275 11.4436 14.5324 11.2777C14.5324 10.8825 14.7201 10.6948 15.1202 10.6948H21.9665L21.927 10.5615L21.2108 8.17068H18.0099L17.9852 6.99999Z" fill="#252525"/>' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M1.88693 23.3255V12.6954H8.30845C8.36089 12.9506 8.42685 13.2029 8.50603 13.4512C8.96894 14.8964 10.2467 15.9282 11.757 16.0763C13.2673 16.2245 14.7212 15.4606 15.4561 14.1328C15.6908 13.7167 15.8481 13.2615 15.9204 12.7892C15.9204 12.7152 15.955 12.6855 16.034 12.6855H22.332V23.3255H1.88693Z" fill="white"/>' +
    '</g>'
