import { ComponentPropDefinitionUtil, ComponentPropDefinitions, ComponentProps } from "../../base/ComponentProps";
import { PropType } from "../../base/PropType";
import { ImagePropDefinitions } from "../image/ImageProps";

export interface StepperProps extends ComponentProps {
    enforceStepSequence: boolean;
    footerVisible: boolean;
    enforceForwardValidation: boolean;
    enforceBackwardValidation: boolean;
}

let stepperPropDefs: ComponentPropDefinitions;

export class StepperPropDefinitions {
    public static getDefinitions(): ComponentPropDefinitions {
        if (stepperPropDefs == null) {
            stepperPropDefs = {
                ...ComponentPropDefinitionUtil.getComponentPropertyDefinitions(),
                ...ImagePropDefinitions.getContainedImagePropDefs(),
                enforceStepSequence: { type: PropType.bool, defaultValue: true },
                footerVisible: { type: PropType.bool, defaultValue: true },
                enforceForwardValidation: { type: PropType.bool, defaultValue: true },
                enforceBackwardValidation: { type: PropType.bool, defaultValue: true },
                beforeStepListener: { type: PropType.event, category: "Events", addListenerMethod: "addBeforeStepListener", removeListenerMethod: "removeBeforeStepListener", description: "This fires before moving to the next step or when submitting the form(s)." },
            };
        }
        return stepperPropDefs;
    }
}
