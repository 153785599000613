import { Component, Panel, PanelProps } from "@mcleod/components";
import { ArrayUtil } from "@mcleod/core";
import { HomePageEditor } from "./HomePageEditor";
import { HomeWidget } from "./HomeWidget";

export const numRows = 4;
export const numCols = 5;
export const rowHeight = 170;
export const colWidth = 257.5;
export const gap = 24;

export const gridTemplateColumns = `repeat(${numCols}, ${colWidth}px)`;
export const gridTemplateRows = `repeat(${numRows}, ${rowHeight}px)`;

export const gridStyle: Partial<CSSStyleDeclaration> = {
    display: "grid",
    gridTemplateColumns: gridTemplateColumns,
    gap: `${gap}px`,
    justifyItems: "stretch",
    gridArea: "1 / 1 / 1 / 1",
    gridAutoFlow: "dense"
}

export class WidgetGrid extends Panel {
    private _droppingWidget = false;
    constructor(props?: Partial<PanelProps>) {
        super({ ...props, isRow: false, style: { ...gridStyle } });
    }

    override add(...components: Component[]): Component {
        for (const comp of components) {
            if (this._droppingWidget)
                comp._element.style.order = this.components.length.toString()
            this._element.appendChild(comp._element);
            this._components.push(comp);
            comp.parent = this;
        }
        return components?.length > 0 ? components[0] : null;
    }

    async displayLayout(layoutDefString: string, homeEditor?: HomePageEditor) {
        const layoutDef = JSON.parse(layoutDefString);
        const components = layoutDef?.components;
        for (let i = 0; i < components?.length; i++) {
            const compDef = components[i];
            if (compDef.type = "layout" && compDef.layoutName != null) {
                const layout = await this._getWidgetLayout(compDef.layoutName, i, homeEditor);
                this.add(layout);
            }
        }
    }

    private _getWidgetLayout(layoutName: string, styleOrder: number, homeEditor?: HomePageEditor): Promise<HomeWidget> {
        return new Promise((resolve) => {
            const layout = new HomeWidget({ layoutName: layoutName, homeEditor: homeEditor, style: { order: styleOrder.toString() } });
            layout.onWidgetLoaded = () => {
                resolve(layout);
            }
        })
    }

    dropped(dropped: HomeWidget, target: HomeWidget) {
        const fromIndex = this.components.indexOf(dropped);
        const toIndex = this.components.indexOf(target);
        ArrayUtil.moveArrayElement(this.components, fromIndex, toIndex);
        this._droppingWidget = true;
        this.layoutRows();
        this._droppingWidget = false;
    }

    /**
     * Calculates the width of the grid excluding empty columns
     */
    getExplicitGridWidth(): number {
        if (this.bounds == null)
            return null;
        let right = 0;
        this.components.forEach((comp) => {
            const rightBounds = comp.bounds.right;
            if (rightBounds > right)
                right = rightBounds;
        })
        return right - this.bounds.left;
    }
}
