import {
    Currency, CurrencyUtil, DateRange, DateUtil, displaysDateOrTime, DisplayType, DisplayValue, StringUtil
} from "@mcleod/core";
import { isBoundToPrimaryKeyField } from "../../base/ComponentDataLink";
import { DataSourceMode } from "../../databinding/DataSource";
import { DropdownItem } from "./DropdownItem";
import { ForcedCase } from "./ForcedCase";
import { Textbox } from "./Textbox";

export class InputFormatter {

    constructor(private textbox: Textbox) {}

    private get displayType(): DisplayType {
        return this.textbox.displayType;
    }

    private hasItemsOrLookupModel(): boolean {
        return this.textbox.items != null || this.textbox.hasLookupModel();
    }

    public cleanText(value: any): string {
        value = (typeof value !== "string" ? value?.toString() : value) ?? "";
        if (this.hasItemsOrLookupModel())
            return value;
        value = this.replaceFancyChars(value);
        if(this.shouldReplaceInvalidChars())
            value = this.replaceInvalidChars(value);
        return this.applyForcedCase(value);
    }

    /* REPLACE SMART QUOTES (etc...) FROM EMAIL CUT-N-PASTE, WITH THEIR REGULAR COUNTERPARTS
    * 2018 Left single quotation mark
    * 2019 Right single quotation mark
    * 201C Left double quotation mark
    * 201D Right double quotation mark
    * 2013, 2014, 2014 En dash, Em dash, Horizontal bar
    */
    public replaceFancyChars(value: string): string {
        return value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"').replace(/[\u2013\u2014\u2015]/g, '-');
    }

    /*
    * Replace special characters that should not be allowed in fields bound to a primary key.
    *   These characters will be replaced on the fly as the user types.
    * % 0025
    * * 002A
    * < 003C
    * = 003D
    * > 003E
    * : 003A
    * & 0026
    * | 007C
    * ; 003B
    * , 002C
    */
    public replaceInvalidChars(value: string): string {
        return value.replace(/[\u0025\u002A\u003C\u003D\u003E\u003A\u0026\u007C\u003B\u002C]/g, '');
    }

    private shouldReplaceInvalidChars(): boolean {
        return isBoundToPrimaryKeyField(this.textbox) &&
                (this.textbox.inDataSourceMode(DataSourceMode.ADD) ||
                    this.textbox.inDataSourceMode(DataSourceMode.UPDATE)) &&
                    this.textbox.enabled !== false;
    }

    public applyForcedCase(value: string): string {
        switch(this.textbox.forcedCase) {
            case ForcedCase.UPPER:
                return value.toUpperCase();
            case ForcedCase.LOWER:
                return value.toLowerCase();
            default:
                return value;
        }
    }

    public formatText(): string  {
        const text = this.textbox.text;
        if (this.shouldFormat(text)) {
            return this.getDisplayValue(text) ?? text;
        }
        return text;
    }

    getDisplayValue(value: string, displayType: DisplayType = this.displayType): string {
        switch (displayType) {
            case DisplayType.DATE:
                return this.getDateDisplayValue(value, true, false);
            case DisplayType.DATETIME:
                return this.getDateTimeDisplayValue(value, true, true);
            case DisplayType.TIME:
                return this.getDateDisplayValue(value, false, true);
            case DisplayType.DATERANGE:
                return this.getDateRangeDisplayValue(value);
            case DisplayType.CURRENCY: {
                return this.getCurrencyDisplayValue(value);
            }
            default:
                return DisplayValue.getDisplayValue(value, displayType, this.textbox.format);
        }
    }

    shouldFormat(text: string): boolean {
        return typeof text == "string"
        && !StringUtil.isEmptyString(text)
        && this.displayType != null
        && this.hasItemsOrLookupModel() !== true
        && this.textbox.printable !== true;
    }

    private includeTimeInSearch(value: string): boolean {
        return ("n" === value.toLowerCase() || value.indexOf(" ") > 0)
    }

    private getDateTimeDisplayValue(value: string, hasDate: boolean, hasTime: boolean, displayType: DisplayType = this.displayType): string {
        if (this.textbox.inDataSourceMode(DataSourceMode.SEARCH) && !this.includeTimeInSearch(value)) {
            hasTime = false;
            displayType = DisplayType.DATE;
        }
        return this.getDateDisplayValue(value, hasDate, hasTime, displayType);
    }

    public getCurrencyDisplayValue(value: string): string {
        // When the textbox gains focus, the currency symbol is removed from the text
        // This makes sure we include proper currency symbol in the text
        const currency = Currency.createCurrencyWithDefaults(value);
        if (currency == null) {
            return value;
        }
        const dataValue = this.textbox.getDataValue();
        if (CurrencyUtil.isCurrency(dataValue)) {
            currency.currency_code = dataValue.currency_code ?? currency.currency_code;
            currency.symbol = dataValue.symbol ?? currency.symbol;
        }
        return DisplayValue.getCurrencyDisplayValue(currency);
    }

    private getDateDisplayValue(value: string, hasDate: boolean, hasTime: boolean, displayType: DisplayType = this.displayType): string {
        let dateString = null;
        const parsed = DateUtil.parseDateWithKeywords(value, hasDate, hasTime, this.textbox.timezone);
        if (DateUtil.isDateValid(parsed)) {
            dateString = DisplayValue.getDateDisplayValue(parsed, displayType, this.textbox.format);
        }
        return dateString ?? value;
    }

    private getDateRangeDisplayValue(value: string): string {
        const dateRange = DateRange.parseDateRange(value);
        if (dateRange.hasValidDates()) {
            return DisplayValue.getDisplayValue(value, DisplayType.DATERANGE, this.textbox.format);
        }
        return value;
    }

    public formatDefaultDateString(dateDefault: any): string {
        let result = null;
        if (!StringUtil.isEmptyString(dateDefault) && displaysDateOrTime(this.displayType, true)) {
            if (this.displayType == DisplayType.DATERANGE) {
                const dateRange = DateRange.parseDateRangeWithKeywords(dateDefault);
                result = DateUtil.formatDateRange(dateRange, this.textbox.format);
            } else {
                result = this.getDisplayValue(dateDefault.split(',')[0]);
            }
        }
        return result;
    }

    formatSelectedItems(printable = false): string {
        const selectedItems = this.textbox.selectedItems;

        if (!selectedItems || selectedItems.length === 0) {
            return "";
        }

        if (selectedItems.length === 1) {
            return selectedItems[0].displayValue;
        }

        if (printable === true) {
           return DropdownItem.getDisplayValuesAsString(selectedItems);
        }

        const selectedItem = this.textbox.resolveItems().find(item => selectedItems.includes(item));

        return `${selectedItem.displayValue} (+${selectedItems.length - 1})`;
    }

}
