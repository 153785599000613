import { Api, CurrencySettings, DateUtil } from ".";
import { Currency } from "./Currency";
import { NumberUtil } from "./NumberUtil";

export class CurrencyUtil {
    
    public static formatCurrency(currency: Currency): string {
        if (currency == null)
            return null;

        const symbol = currency.symbol ?? CurrencyUtil.getCurrencySymbol(currency.currency_code);
        const code = currency.currency_code;
        // can't use Intl.NumberFormat.style: 'currency' because it uses standard currency symbols
        const value = new Intl.NumberFormat('en-US', {
            maximumFractionDigits: 2, minimumFractionDigits: 2
        }).format(currency.amount);
        return CurrencySettings.getSingleton().multiCurrencyEnabled() ? `${code} ${value}` : `${symbol}${value}`;
    }

    public static getCurrencySymbol(currencyCode: string): string {
        return CurrencySettings.get().currency_symbols?.[currencyCode] ?? "";
    }


    public static isCurrency(value: any): boolean {
        if (value == null)
            return false;
        return value.currency_code != null && (value.base_amount != null || value.amount != null);
    }

    public static removeFormatting(value: string): string {
        const firstChar = value.charAt(0);
        if (!firstChar.match(/^\d/) && firstChar !== "-") {
            value = value.substring(1);
            if (CurrencySettings.getSingleton().multiCurrencyEnabled() &&
                !value.charAt(0).match(/^\d/))
            {
                const values: string[] = value.split(" ");
                if (value.length != 0)
                {
                    value = values[values.length-1];
                }
            }
        }
        return NumberUtil.removeFormatting(value);
    }

    /**
     * Returns the exchange rate for the given conversion date and currency code pairing.
     * Will find the most recent exchange rate asynchronously based on the given date if no rate exists for the given date unless
     * useExactDate is passed in as true.
     * @param conversionDate date for the conversion
     * @param currencyTo currency to convert to
     * @param currencyFrom currency to convert from
     * @param useExactDate whether to only look for rates on a given day
     * @returns a double type exchange rate
     */
    public static async getConversionRate(conversionDate: Date, currencyTo: string, currencyFrom: string, useExactDate: boolean = false) : Promise<number>
    {
        if (!currencyTo || !currencyFrom || currencyTo === currencyFrom)
        {
            return 1.0;
        }
        return Api.post("lme/general/multicurrency/get-exchange-rate", {
            conversion_date: conversionDate,
            currency_to: currencyTo,
            currency_from: currencyFrom,
            use_exact_date: useExactDate
        }).then(response => {
            const rate = response?.data[0].rate;
            return rate ?? 1.0;
        });
    }

    /**
     * Converts currency to given currency code and returns new currency. Asynchronously gets exchange rate for
     * calculating converted amount.
     * @param originalCurrency currency to be converted
     * @param convertToCurrencyCode currency code to convert to
     * @returns new converted currency object
     */
    public static async convertTo(originalCurrency: Currency, convertToCurrencyCode: string) : Promise<Currency>
    {
        let result: Currency;
        if (!convertToCurrencyCode || !originalCurrency || originalCurrency.getCurrencyCode() === convertToCurrencyCode) {
            return originalCurrency;
        }
        else if (originalCurrency.getBaseAmount() != null && CurrencySettings.getFunctionalCurrency() === convertToCurrencyCode) {
            return originalCurrency.getBaseAmount().then(baseAmount => {
                result = new Currency(baseAmount);
                result.setConversionDate(originalCurrency.getConversionDate());
                result.setCurrencyCode(convertToCurrencyCode);
                return result;
            });
        }
        else {
            return this.calculateConvertedAmount(originalCurrency.amount, originalCurrency.getConversionDate(), originalCurrency.currency_code,
                convertToCurrencyCode, false).then(convertedAmount => {
                    result = new Currency(convertedAmount);
                    result.setConversionDate(originalCurrency.getConversionDate());
                    result.setCurrencyCode(convertToCurrencyCode);
                    return result;
            });
        }
    }

    /**
     * Calculates a converted amount based on the currency codes provided. If no rate is given, then it will asynchronously
     * search for an exchange rate using the given conversion date.
     * @param amount
     * @param conversionDate
     * @param currencyFrom
     * @param currencyTo
     * @param useExactDate
     * @param rate
     * @returns a double type amount
     */
    public static async calculateConvertedAmount(amount: number, conversionDate: Date, currencyFrom: string, currencyTo: string, useExactDate: boolean = false,
        rate? : number): Promise<number> {
        if (currencyFrom === currencyTo) {
            rate = 1.0;
        }
        else if (amount == null) {
            throw new Error("Could not calculate base amount without a native amount.");
        }
        else if (rate == null) {
            return this.getConversionRate(conversionDate, currencyTo, currencyFrom, useExactDate).then(rate => {
                if (rate == null) {
                    throw new Error("Could not calculate base amount without a conversion rate.");
                }
                return Number.parseFloat((rate * amount).toFixed(2));
            });
        }
        else {
            return Number.parseFloat((rate * amount).toFixed(2));
        }
    }

    /**
     * Compare two currency values (objects in the structure of the Currency interface)
     *
     * @param currency1 a currency value that makes up one side of the equals
     * @param currency2 a currency value that makes up one side of the equals
     * @returns a boolean indicating if the currency values are equal to each other
     */
    public static currencysAreEqual(currency1: Currency, currency2: Currency): boolean {
        if (currency1 == null && currency2 == null)
            return true;
        else if (currency1 == null || currency2 == null)
            return false;

        //wish we could get this list from the Currency interface, but it's an interface...
        const keys = ["amount", "base_amount", "currency_code", "conversion_rate", "conversion_date", "symbol"];
        for (const key of keys) {
            if ((currency1[key] == null && currency2[key] == null) ||
                this.valuesAreEqual(currency1[key], currency2[key])) {
                continue;
            }
            return false;
        }
        return true;
    }

    public static valuesAreEqual(value1: any, value2: any) {
        if (value1 instanceof Date && value2 instanceof Date) {
            return DateUtil.dateTimesEqual(value1, value2);
        }
        return value1 === value2;
    }

    /**
     * Compare two currency values (objects in the structure of the Currency interface)
     *
     * @param value1 a currency value
     * @param value2 a currency value
     * @returns 0 if the currency values are equal, -1 if value 1 is less than value 2, or 1 if value 1 is greater than value 2
     */
    public static compareCurrencys(value1: Currency, value2: Currency, sortNullsAtEnd = false): number {
        if (value1 == null && value2 == null)
            return 0;
        else if (value1 == null)
            return -1;
        else if (value2 == null)
            return 1;

        //first try comparing using the base_amount value
        const value1BaseAmount = value1["base_amount"];
        const value2BaseAmount = value2["base_amount"];
        if (value1BaseAmount != null || value2BaseAmount != null) {
            if (value1BaseAmount === value2BaseAmount)
                return 0;
            if (value1BaseAmount == null)
                return -1;
            if (value2BaseAmount == null)
                return 1;
            if (value1BaseAmount < value2BaseAmount)
                return -1;
            if (value1BaseAmount > value2BaseAmount)
                return 1;
        }
        //in case base_amount is not available, we can check using currency_code + amount,
        //but only if both currencys have the same currency_code
        if (value1["currency_code"] === value2["currency_code"]) {
            const value1Amount = value1["amount"];
            const value2Amount = value2["amount"];
            if (value1Amount != null || value2Amount != null) {
                if (value1Amount === value2Amount)
                    return 0;
                if (value1Amount == null)
                    return -1;
                if (value2Amount == null)
                    return 1;
                if (value1Amount < value2Amount)
                    return -1;
                if (value1Amount > value2Amount)
                    return 1;
            }
        }
        return 0;
    }

    public static getCurrencyObjectFromDataValue(data: any): Currency {
        if (data instanceof Currency) {
            return data;
        }
        else if (typeof data === "string") {
            if (CurrencySettings.getSingleton().multiCurrencyEnabled()) {
                const split = data.split(" ", 2);
                if (split.length == 2) {
                    return new Currency({ currency_code: split[0], amount: Number.parseFloat(split[1].replace(",", "")) });
                }
            }
            else if (data.length > 1) {
                return new Currency(Number.parseFloat(data.substring(1)));
            }
        }
        else if (data instanceof Object) {
            try {
                return new Currency(data);
            }
            catch (error) {}
        }
        return new Currency(0.0);
    }
}
