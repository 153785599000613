import { CommonTooltips, KeyValuePair } from "@mcleod/common";
import { ComponentCreator, Label, Panel, ResourceFileProps } from "@mcleod/components";
import { DateUtil, StringUtil } from "@mcleod/core";

export class ResourceFileTooltip {

    public static createPanel(type: "layout" | "model", props: ResourceFileProps, footer?: ComponentCreator): Panel {
        let headerLabel: Label;
        const keyValuePairs: KeyValuePair[] = [];
        if (StringUtil.isEmptyString(props.customId))
            headerLabel = new Label({ text: `This is the base version of this ${type}.` });
        else {
            keyValuePairs.push(new KeyValuePair("Description", props.descr));
            keyValuePairs.push(new KeyValuePair("Create Date/Time", props.createDateTime != null ? DateUtil.formatDateTime(props.createDateTime) : "--"));
            keyValuePairs.push(new KeyValuePair("Update Date/Time", props.updateDateTime != null ? DateUtil.formatDateTime(props.updateDateTime) : "--"));
        }
        return CommonTooltips.createKeyValueTooltip(keyValuePairs, headerLabel, footer);
    }
}
