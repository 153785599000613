import { DataSourceExecutionEvent } from "@mcleod/components";
import { AutogenLayoutSendUserInvitation } from "./autogen/AutogenLayoutSendUserInvitation";
import { Clipboard } from "@mcleod/core";

export class SendUserInvitation extends AutogenLayoutSendUserInvitation {
    /** This is an event handler for the afterExecution event of sourceUserInvitation.  */
    sourceSendUserInvitationAfterExecution(event: DataSourceExecutionEvent) {
        if (!this.switchSendFrom.checked) {
            const row = this.sourceSendUserInvitation.data[0];
            const mailSubject = row.get("mail_subject");
            const mailBody = row.get("mail_body");
            if (mailBody != null) {
                const mailto = "mailto:" + row.get("email_address") + "?subject=" + encodeURIComponent(mailSubject);
                Clipboard.copyText(mailBody, true);
                location.href = mailto;
            }
        }
    }
}